<template src="./template.html"></template>

<script>
export default {
    data() {
        return {
            component:'',
            props:{},
            display : false
        }
    },
    methods: {
        closePanel(){
           
            console.log(this.component);
            this.component ='',
            this.display = !this.display;
            document.getElementsByTagName("BODY")[0].classList.remove('panelOn')
            
        },
        dimClose (){
            let list = ['panel-2', 'panel-9']
            if( list.indexOf(this.component) < 0 ){
                this.component ='',
                this.display = !this.display;
                document.getElementsByTagName("BODY")[0].classList.remove('panelOn')
            }
        },
        albumMore (){
            this.$EventBus.$emit('albumList',{eventName:'album_more'})
        },
        beforeEnter: function (el) {
            el.style.transition = 'all .2s'
            //el.style.right = '-740px'
        },
        enter: function (el, done) {
        },
        leave: function (el, done) {
        }
    },
    created (){
            this.$EventBus.$on('openPanel', (params) => {
                document.getElementsByTagName("BODY")[0].classList.add('panelOn');
                this.display = true
                this.component = params.component
                this.props = params.props
                this.$forceUpdate();
            })
            this.$EventBus.$on('closePanel', (params) => {
                
                this.display = false
                
            })
    },
    mounted(){
        // this.$EventBus.$on('closePanel', (params) => {
        //     console.log('asdf asdf');
        //     this.closePanel();
        // })
    },
    destroyed (){
        document.getElementsByTagName("BODY")[0].classList.remove('panelOn')
    }
}

</script>