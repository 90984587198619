<template src="./template.html"></template>

<script>
import i18n from "../../../library/i18n"    // 다국어지원

export default {
    name: 'panel-18',
    props: ['userInfo'],
    data() {
        return {
            balance: 0,                // 출금 가능 금액
            currency: 'krw',           // 출금 가능 금액 통화 단위 ( krw : 원, usd : 달러, eur : 유로 )
            error: [],                 // Error Message
            payment_method: null,      // 지급 방법
            division: null,            // 구분
            payoneer_state: "ready",   // ready, waiting, activation
            payoneer_payee_id: null,   // payoneer ID
            typingBool: false,         // typing
            home:{
                title: 'Home',
                img: require('@/assets/images/toplogo.png'),
                description: 'Sound Republica Logo',
                locale: this.$store.state.languageCode
            }, // 로고 이미지
            getMessage: null,
            userData : null
        }
    },
    mounted(){
        this.$nextTick(function(data){
            const user = this.$attrs.props.userInfo
            this.userData = user
            //console.log(this.props)
            console.log(this.userData)
            if(this.userData){
                const currency = this.userData.licensor.currency   // 출금 가능 금액 통화 단위 ( krw : 원, usd : 달러, eur : 유로 )
                let balance = this.userData.licensor.balance === null ? 0 : this.userData.licensor.balance
                if(currency == 'krw') {
                    balance = Math.floor(balance) 
                }
                else {
                    console.log('balance',balance)
                    balance = Math.floor(balance * 100) / 100
                    console.log('balance',balance)
                }

                this.balance = balance.toLocaleString('en-US', {minimumFractionDigits: 2})
                console.log('this.balance',this.balance)
                this.currency = currency
                this.payment_method = this.userData.licensor.bankaccount.payment_method
                // if(this.payment_method == 'payoneer') {
                //     setTimeout(function() {this.popupOpen01('panel18_guide01', 'payoneer_checking')}.bind(this), 500)
                //     this.payoneer_payee_id = this.userInfo.licensor.bankaccount.payoneer_payee_id
                //     if(this.payoneer_payee_id) {
                //         this.$store.dispatch('PAYONEER_STATUS', {payee_id : this.payoneer_payee_id})
                //         .then(result =>{
                //             switch(result.status) {
                //                 case 'ACTIVE':
                //                     this.payoneer_state = 'activation'
                //                     break;
                //                 case 'INACTIVE':
                //                     this.payoneer_state = 'waiting'
                //                     break;
                //                 default:
                //                     this.payoneer_state = "ready"
                //                     break;
                //             }
                //             setTimeout(function() {this.popupClose01('panel18_guide01')}.bind(this), 3000)
                //         }).catch(error =>{
                //             let errorData = error.response.data
                //             this.payoneer_state = "ready"
                //             switch(errorData.code) {
                //                 case 10005:
                //                     setTimeout(function() {this.popupClose01('panel18_guide01')}.bind(this), 3000)
                //                     break;
                //                 default: 
                //                     this.popupClose01('panel18_guide01')
                //                     break;
                //             }
                //         })
                //     }else{
                //         setTimeout(function() {this.popupClose01('panel18_guide01')}.bind(this), 2500)
                //         let popup = document.getElementById('panel18_guide01')
                //         let contains = popup.firstChild.classList.contains('type2')
                //         if(!contains) {
                //             this.popupOpen02('panel18_guide02', 'payoneer_id')
                //         }
                //     }
                // }
                this.division = this.userData.licensor.entity    // individual: "개인" , corporation: "사업자( 개인/법인 )"
            }
        
        })
    },
    computed: {
        
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const payoneer_checking = this.$i18n.t("components.panel.panel18.msg.payoneer_checking")
                const payoneer_fail = this.$i18n.t("components.panel.panel18.fail.payoneer_fail")
                const payoneer_id = this.$i18n.t("components.panel.panel18.fail.payoneer_id")
                let lack_balance = this.$i18n.t("accounting.msg.payment.alert_insufficient_1")
                + this.$i18n.t("accounting.props.reference_amount")[this.currency] + this.$i18n.t("accounting.msg.payment.alert_insufficient_2")

                const messageCollection = { 
                    payoneer_checking: payoneer_checking,
                    payoneer_fail: payoneer_fail,
                    payoneer_id: payoneer_id,
                    lack_balance: lack_balance
                }
                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        }
    },
    methods: {
        closePanel(){
            this.$emit('closePanel')
        },
        getAmount(val){
            let value = 0
            if(val){
                value = val
            }
            //console.log('before:'+value)
            if(this.currency == 'krw'){               
                //value = Math.floor(value * 100) / 100
                value = value? value.toLocaleString() : 0
            }else{
                
                value = Math.floor(value * 100) / 100
                value = value ? value.toLocaleString('en-US', {minimumFractionDigits: 2}) : 0
                
            }
            //console.log('after:'+value)
            return value
        },
        onPayoneer_connectBtn(){
            this.$store.dispatch("PAYONEER_REGISTRATION_LINK", { payee_id: this.payoneer_payee_id })
            .then(result => {
                if(result.hasOwnProperty('registration_link')) 
                    if(result.registration_link) {
                        window.open(result.registration_link)
                        this.payoneer_state = 'waiting'
                    }
            })
        },
        onSubmit(){
            const amount = this.numberFilter(this.balance);
            if(amount == 0 ){
                this.guideMessage = 'lack_balance'
                this.alert({msg: this.guideMessage})
                return false
            }
            this. loadingShow()
            this.$store.dispatch("REQUEST_WITHDRAWAL", {amount})
                .then(result =>{
                    this. loadingHide()
                    this.$EventBus.$emit("withdrawal_complete", null)
                    this.showPanel19()
                    // this.closePanel()
                })
                .catch(error =>{
                    this. loadingHide()
                    //this.$EventBus.$emit("withdrawal_complete", null)
                    if (error.response && error.response.data ) {
                        const data = error.response.data
                        switch(data.code) {
                            case 352:
                                this.guideMessage = 'lack_balance'
                                this.alert({msg: this.guideMessage})
                                break;
                        }
                        if(error.response.data.message == 'INSUFFICIENT_FUNDS: INSUFFICIENT_FUNDS'){
                            this.alert({msg: this.iText('','accounting.msg.payment.alert_insufficient')})
                            this.closePanel()

                        }else if(error.response.data.message == 'LICENSOR_WITHDRAWAL_PENDING: LICENSOR_WITHDRAWAL_PENDING'){
                            
                            this.alert({msg: this.iText('진행중인 출금신청이 있습니다. 이 지급이 완료된 후에 새롭게 신청해주세요.','accounting.msg.payment.alert_pending')})
                            
                            this.closePanel()
                        }else{
                            console.log(error.response)
                        }
                    }else{
                        console.log(error)
                    }
                })
        },
        showPanel19(){
            // 출금 신청 완료
            this.$EventBus.$emit('openPanel',{
                component: "panel-19",
                props: {
                    payment_method: this.payment_method,
                    entity: this.division
                }
            });
            // const panel = this.$showPanel({
            //     component: "panel-19",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {
            //         payment_method: this.payment_method,
            //         entity: this.division
            //     }
            // })
        },
        typing(){
            if(this.typingBool){
                this.typingImpl()
            }else {
                clearInterval(this.typingImpl()); //끝나면 반복종료
                return
            }
            
        },
        typingImpl(){
            let typingIdx = 0
            let typingTxt = this.getMessage.split("")        // 타이핑될 텍스트를 가져온다 한글자씩 자른다.
            document.getElementById("typing").innerText = "" //초기화

            return setInterval(function(){
                if(typingIdx < typingTxt.length){ // 타이핑될 텍스트 길이만큼 반복
                    document.getElementById("typing").innerText += typingTxt[typingIdx] == ' '? '\u00A0': typingTxt[typingIdx] // 한글자씩 이어준다.
                    typingIdx++
                } 
            },100); // 반복동작 
        },
        popupOpen01(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            this.guideMessage = title
            this.typingBool = true
            this.typing()
         
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupOpen02(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            this.guideMessage = title
            popup.firstChild.classList.add('type2')
        },
        popupClose01(id){
            // comleted popup close
            let popup = document.getElementById(id)
            popup.firstChild.classList.remove('type2')
            this.typingBool = false
            setTimeout(function(){
                popup.classList.remove('type2')
            }, 300)
        },
        popupClose02(event){
            // licensor popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')

             popup.classList.remove('type2')
        }
    }
}
</script>

<style scoped>
.linkStyle01{
    text-decoration: underline;
    color: #ff1e43;
}
</style>