<template src="./template.html"></template>

<script>
import i18n from '../../../library/i18n' // 다국어지원

export default {
    name: 'panel-2',
    props: ['props'],
    data() {
        return {
            id: {
                artist01: "artist01",
                artist02: "artist02",
                artist03: "artist03"
            },
            pwChangeForm: {
                prevPassword: "",
                newPassword: "",
                keepAlive: false
            },
            image: [],
            none_images: false,                         // 이미지 첨부 여부 체크
            snsCount: 1,
            snsAddress: null,                             // sns 주소
            snsType: null,                                // sns 종류
            sns: [],                                    // sns   [{종류 : 주소}]
            file2_use: false,                           // 서브 이미지1 사용 여부
            file3_use: false,                           // 서브 이미지2 사용 여부
            file1: null,                                // file1
            file2: null,                                // file2
            file3: null,                                // file3
            params: {
                default: {
                    profile_id: 0,                      // 필수 이미지 아이디
                    image1_id: 0,                       // sub 이미지 아이디1
                    image2_id: 0,                       // sub 이미지 아이디2
                    display_name: null,                 // artist Name
                    //display_name_language: null,        // artist Name language
                    display_name_english: null,         // artist Name english
                    nationality: null,                  // 국적
                    type: null,                         // 아티스트 유형 예) 남성 솔로, 여성 솔로 등등  solo_male, solo_female, group_male, group_female, group_combined
                },
                select: {
                    debut_date: null,                   // 데뷔날짜
                    biography: null,                    // 아티스트 소개
                    biography_language: null,           // 아티스트 소개 언어
                    biography_english: null,            // 아티스트 소개 영문
                    sns: []                             // { type: null, // SNS 종류 url: null   // SNS 주소 }
                }
            },
            artistType : [
            { value: 'solo_male', label: i18n.t('artistType.solo_male') },
            { value: 'solo_female', label: i18n.t('artistType.solo_female') },
            { value: 'group_male', label: i18n.t('artistType.group_male') },
            { value: 'group_female', label: i18n.t('artistType.group_female') },
            { value: 'group_combined', label: i18n.t('artistType.group_combined') },
            ],
            snsOption : [
                { value: 'youtube', label:"YouTube"  },
                { value: 'facebook', label:"Facebook"  },
                { value: 'twitter', label:"Twitter"  },
                { value: 'website', label:"Website"  },
                { value: 'instagram', label:"Instagram"  },
            ],
            fileUpload: [],                             // 파일업로드 결과
            error: [],                                  // Error
            requireFilter: ["image1_id","image2_id"],   //default 중 필수 제외
            getMessage: null,                           // popup guide 메세지
            home:{
                title: 'Home',
                img: require('@/assets/images/toplogo.png'),
                description: 'Sound Republica Logo',
                locale: this.$store.state.languageCode
            }, // 로고 이미지
            loader:{
                img: require('@/assets/images/bx_loader.gif'),
                description: 'Loading Image'
            }, // 로고 이미지
            typingBool: false,                          // typing
            biography: null,                            // 아티스트 소개
            biography_english: null,                    //영문 아티스트소개
            display_name_english_generate: false,        // 영문 아티스트 이름 체크
            name_multilang : {
                "ko" : null,
                'en' : null,
            },
            id_external : [],
            externalId : null,
            selectExternal : null ,
            externalOption : [
                {label : 'Apple', value: 'apple',},
                {label : 'Melon', value: 'melon',},
                {label : 'Spotify', value: 'spotify',},
            ],
        }
    },
    watch:{
        biography(){
            let param = this.biography
            this.$store.dispatch("TEXTAREAIMPL", param).then(result =>{
                this.params.select.biography = result
            })
            return this.biography
        },
        biography_english(){
            let param = this.biography_english
            this.$store.dispatch("TEXTAREAIMPL", param).then(result =>{
                this.params.select.biography_english = result
            })
            return this.biography
        }
    },
    computed: {
        nowYear(){
            return new Date().getFullYear()
        },
        imageUploadOption(){
            return {
                type: "artist",
                eventName : "imageUpload_main",
                minWidth: 600,
                minHeight: 600
            }
        },
        imageUploadOption_etc1(){
            return {
                type: "artist",
                eventName : "imageUpload_etc1",
                minWidth: 600,
                minHeight: 600
            }
        },
        imageUploadOption_etc2(){
            return {
                type: "artist",
                eventName : "imageUpload_etc2",
                minWidth: 600,
                minHeight: 600
            }
        },
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const none_sns = i18n.t("components.panel.panel2.msg.none_sns")
                const not_delete_sns = i18n.t("components.panel.panel2.msg.not_delete_sns")
                const artist_image = i18n.t('components.panel.panel2.msg.artist_image')
                const artist = i18n.t('components.panel.panel2.msg.artist_image')
                const arist_exists = i18n.t('components.panel.panel2.msg.artist_exists')
                const registering = i18n.t('distributionMusic.msg.registering')

                const messageCollection = {
                    none_sns: none_sns,
                    not_delete_sns: not_delete_sns,
                    artist_image: artist_image,
                    registering: registering,
                    arist_exists: arist_exists
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        }
    },
    created(){
        this.$EventBus.$on(this.imageUploadOption.eventName, (file) => {
            // 메인 이미지
            if(file){
                this.file2_use = true
                this.file1 = file
            }else{
                this.file2_use = false
                this.file1 = file
            }
        })

        this.$EventBus.$on(this.imageUploadOption_etc1.eventName, (file) => {
            // 서브1 이미지
            if(file){
                this.file3_use = true
                this.file2 = file
            }else{
                this.file3_use = false
                this.file2 = file
            }
        })

        this.$EventBus.$on(this.imageUploadOption_etc2.eventName, (file) => {
            // 서브2 이미지
            this.file3 = file
        })
    },
    methods: {
        dragover_handler(event){
            event.dataTransfer.dropEffect = "none"
        },
        params_sns() {
            this.params.select.sns = []
            this.snsType.map((type,i) => {
                this.params.select.sns.push({
                    type: this.snsType[i],
                    url: this.snsAddress[i]
                })
            })
        },
        i18_length(msg){
            return i18n.t(msg).length
        },
        closePanel(boolean){
            this.$emit('closePanel', {
                result : boolean
            });
        },
        add_li() {
            if(this.valCheck(this.snsType)){this.alert({msg:i18n.t("components.panel.panel2.msg.none_sns_type")}); return false}
            if(this.valCheck(this.snsAddress)){this.alert({msg:i18n.t("components.panel.panel2.msg.none_sns")}); return false}
            console.log(this.snsType)
            this.params.select.sns.push({
                type: this.snsType,
                url: this.snsAddress
            })
            this.snsAddress = null
            this.snsType = null
        },
        delete_li(index){
            this.params.select.sns.splice(index,1)
        },
        confirmBtn(){
            //this.params_sns() // SNS 값 
            // 아티스트 이미지 첨부 여부 확인
            // if (!this.none_images){
            //    if(!this.file1) {
            //         //this.popupOpen('panel02_guide','artist_image')
            //         //this.alert({msg : i18n.t('components.panel.panel2.fail.fileUpload')})
            //         let text = i18n.t('enter.require') 
            //         this.alert({msg : text})
            //         return false
            //     }
            // }
            this.emptyCheck().then(result=> {
                if (!this.none_images){
                    if(!this.file1) {
                        
                        //result.push('image')
                    }
                }
                if(result.length ===  0) {
                    // 이미지1
                    this.loadingShow()
                    const promise1 = new Promise(resolve=> {
                        let file1 = this.file1
                        if(file1) {
                            //this.popupOpen01('panel02_guide03',"registering") // loading open
                            let upload_success = this.uploadfileAPi(file1,'profile_id')
                            .then(result =>{
                                resolve(upload_success)
                            }).catch(error =>{
                                resolve(false)
                            })
                        }else{
                            resolve(true)
                        }
                    })

                    // 이미지2
                    const promise2 = new Promise(resolve=> {
                        let file2 = this.file2
                        if(file2) {
                            let upload_success = this.uploadfileAPi(file2, 'image1_id')
                            .then(result =>{
                                resolve(upload_success)
                            }).catch(error =>{
                                resolve(false)
                            })
                        }else{
                            resolve(true)
                        }
                    })

                    // 이미지3
                    const promise3 = new Promise(resolve=> {
                        let file3 = this.file3
                        if(file3) {
                            let upload_success = this.uploadfileAPi(file3, 'image2_id')
                            .then(result =>{
                                resolve(upload_success)
                            }).catch(error =>{
                                resolve(false)
                            })
                        }else{
                            resolve(true)
                        }
                    })

                    Promise.all([promise1, promise2, promise3]).then(values => {
                        this.fileUpload = values
                        if(this.fileUpload.indexOf(false) == -1){
                            this.artist_register()
                        }else{
                            alert(i18n.t('components.panel.panel2.fail.fileUpload'))
                        }
                    })

                }else{
                    let text = i18n.t('enter.require')
                    text += `<br/><br/>`
                    
                    for(let i in result){
                        if(i == 0){
                            text += `<p class="warn_textp textCenter">`
                        }
                        // 0: "display_name"
                        //1: "nationality"
                        //2: "type"
                        if(i != 0){
                            text += " / "
                        }
                        if(result[i] == 'image') text += i18n.t('components.panel.panel2.label.artistImg')
                        if(result[i] == 'display_name') text += i18n.t('components.panel.panel2.label.name')
                        if(result[i] == 'nationality') text += i18n.t('components.panel.panel2.label.nationality')
                        if(result[i] == 'type') text += i18n.t('components.panel.panel2.label.type')
                        if(i == result.length-1){
                            text += `<p/>`
                        }
                        
                        
                    }
                    this.alert({msg : text,width:600})
                }
            })
        },
        artist_register() {
            let defaultInfo = this.params['default']
            let select = this.params['select']
            let params = Object.assign({}, defaultInfo, select)
            let keys = Object.keys(params)
            keys.map(key =>{
                if(params[key] === null || params[key] === 0){
                    delete params[key]
                }
            })
            params.name_multilang = this.name_multilang
            for (const key in params.name_multilang) {
                if(this.valCheck(params.name_multilang[key]) ){
                    delete params.name_multilang[key]
                }
            }
            params['id_external'] = this.id_external
            
            this.$store.dispatch("ARTIST_REGISTER", params)
                .then(result => {
                    console.log("re",result)
                    console.log(this.props.eventName)
                    this.$EventBus.$emit(this.props.eventName,'registe');
                    if(this.props.eventName == 'album_artist_update'){
                        //this.closePanel(true)    
                    }else if(this.props.eventName == 'track_artist_update'){
                        console.log('dd')
                      
                      
                    }
                    this.loadingHide()
                    
                    
                }).catch(error =>{
                    this. loadingHide()
                    //this.closePanel(true)
                    console.log(error)
                    if(error){
                        const errorCode = error.response.data.code
                        switch(errorCode) {
                            case 300:
                                this.alert({msg: i18n.t('components.panel.panel2.msg.artist_exists')})
                                this.error.push('display_name')
                                break;
                        }
                    }
                })
        },
        emptyCheck(){
            this.error = [] // Error 초기화
            // Track 정보 중 필수 입력 체크
            let defaultInfo = this.params.default
            let keys = Object.keys(defaultInfo)
            let filterKeys = keys.filter(key =>{
                return this.requireFilter.indexOf(key) === -1
            })
            let emptyType = null   // 체크할 값 타입
            return new Promise(resolve =>{
                filterKeys.map(key =>{
                    let params = defaultInfo[key]
                    this.$store.dispatch('EMPTYCHECK', { emptyType, params })
                    .then((result) => {
                        if(result) {
                            if(key == 'display_name_language') {
                                if(this.error.indexOf('display_name') == -1) this.error.push('display_name')
                            }else if(key == 'display_name_english'){
                                //if(!this.display_name_english_generate) this.error.push(key)
                            }else if(key != "profile_id"){
                                this.error.push(key)
                            }
                            resolve(this.error)
                        }else{
                            resolve(this.error)
                        }
                    }).catch((message) => console.log("require result Check fail:::"))
                })
            })
        },
        uploadfileAPi(file, key) {
            return this.$store.dispatch('FILEUPLOAD', file)
            .then(result => {
                this.params.default[key] = result[0].id
            }).catch(error =>{
                this.error.push(key)
            })
        },
        sbcPopupTogFunc(event){
            let id = document.getElementById('artistInfo_etc')
            switch(id.style.display){
                case "none":
                    id.style.display = "block"
                    break;
                default :
                    id.style.display = "none";
                    break;
            }
        },
        popupOpen(id, title){
            // popup Open
            let popup = document.getElementById(id)
            
            // if(popup) {
            //     popup.classList.add('type2')
            //     this.guideMessage = title
            //     setTimeout(function(){
            //         popup.firstChild.classList.add('type2')
            //     }, 300)
            // }
            this.alert({msg:this.guideMessage})
        },
        popupOpen01(id, title){
            // popup Open
            let popup = document.getElementById(id)
            if(popup) {
                popup.classList.add('type2')
                this.guideMessage = title
                this.typingBool = true
                this.typing()
            
                setTimeout(function(){
                    popup.firstChild.classList.add('type2')
                }, 300)
            }
        },
        popupClose(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          if(popup) {
            popup.firstChild.classList.remove('type2')
            document.getElementById("panel2_typing").innerText = ""
            setTimeout(function(){
                popup.classList.remove('type2')
            }, 300)
          }
        },
        popupClose02(id){
            // comleted popup close
            let popup = document.getElementById(id)
            if(popup) {
                popup.firstChild.classList.remove('type2')
                this.typingBool = false
                document.getElementById("panel2_typing").innerText = ""
                setTimeout(function(){
                    popup.classList.remove('type2')
                }, 300)
            }
        },
        typing(){
            if(this.typingBool){
                this.typingImpl()
            }else {
                clearInterval(this.typingImpl()); //끝나면 반복종료
                return
            }
            
        },
        typingImpl(){
            let typingIdx = 0
            let typingTxt = this.getMessage.split("")           // 타이핑될 텍스트를 가져온다 한글자씩 자른다.

            return setInterval(function(){
                if(typingIdx < typingTxt.length){               // 타이핑될 텍스트 길이만큼 반복
                    document.getElementById("panel2_typing").innerText += typingTxt[typingIdx] // 한글자씩 이어준다.
                    typingIdx++
                } 
            },200); // 반복동작 
        },
        checkboxImpl(event) {
            let idName = event.target.id
            let id = idName.substring(0, event.target.id.lastIndexOf('_generate'))
            let el = document.getElementById(id)
            let checkbox = this.display_name_english_generate  // v-model

            if(checkbox) {
                el.readOnly = false
                el.classList.remove('input_fitem_disabled')
            }else {
                el.readOnly = true
                el.classList.add('input_fitem_disabled')
            }
        },
        errorMessage(event){
            const id = event.target.id
            const error = this.error.indexOf(id)
            if(error > -1) this.error.splice(error, 1);
        },
        updateLang(data){
            this.name_multilang = data
            this.$forceUpdate()
        },
        addExternal(){
            let dd = {
            type : this.selectExternal,
            value : this.externalId
            }
            if(!this.selectExternal){
                this.alert({msg : i18n.t("components.panel.panel2.msg.none_sns_type")})
                return false;
            }
            if(this.valCheck(this.externalId)){
                this.alert({msg : i18n.t("artist.idetifier_msg")})
                return false;
            }
            for( let i in this.id_external){
                if(this.id_external[i].type == this.selectExternal){
                    this.alert({msg : i18n.t('error.already.data')})
                    return false
                }
            }
            
            this.id_external.push(dd);
            this.externalId = null
        },
        removeExternal(index){
            const array = this.id_external;
            array.splice(index,1)
            this.id_external = array
        },
    }
}
</script>

<style scoped>
.aristImg{
    width: 100%;
}

.add {
    cursor: pointer;
}

p.typing-txt{display: none;}
  
.typing {  
    display: inline-block; 
    animation-name: cursor; 
    animation-duration: 0.3s; 
    animation-iteration-count: infinite; 
} 

@keyframes cursor{ 
    0%{border-right: 1px solid #fff} 
    50%{border-right: 1px solid #000} 
    100%{border-right: 1px solid #fff} 
}
</style>