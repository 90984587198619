<template src="./template.html"></template>

<script>
export default {
    name: "panel-14",
    data() {
        return {
            ko : [
                {"name":"MELON"},
                {"name":"FLO"},
                {"name":"VIBE"},
                {"name":"Bell 365"},
                
                {"name":"BUGS"},
                {"name":"WAVVE"},
                {"name":"V Coloring"},
                {"name":"Genie Music"},
                {"name":"KT"},
                {"name":"Olleh Music"},
                {"name":"ONE STORE"},
                {"name":"SAMSUNG MUSIC"},
                {"name":"SKT"},
                {"name":"T STORE"},
                {"name":"MOMO"},
                
            ],
            global : [
                {"name":"YouTube"},
                {"name":"Spotify"},
                {"name":"Resso"},
                {"name":"TikTok"},
                {"name":"Acfun"},
                {"name":"Aldi Life"},
                {"name":"Amazon"},
                {"name":"Amazon Ad-Funded Streaming Service"},
                {"name":"Amazon Prime"},
                {"name":"Amazon Unlimited"},
                {"name":"Anghami"},
                {"name":"Apple Music"},
                {"name":"Audiomack"},
                {"name":"AWA"},
                {"name":"B612"},
                {"name":"beat.no"},
                {"name":"Beatport"},
                {"name":"Bilibili"},
                {"name":"Bitel Peru"},
                {"name":"BMAT"},
                {"name":"Boom"},
                {"name":"Boomplay"},
                {"name":"Buecher"},
                {"name":"Carrier Billing"},
                {"name":"Celcom"},
                {"name":"Changba"},
                {"name":"China Unicom (Wo Music)"},
                {"name":"Chunghwa Mobile"},
                {"name":"ClicknClear"},
                {"name":"Coolvox"},
                {"name":"DaPian"},
                {"name":"Deezer"},
                {"name":"DiGi"},
                {"name":"Digicel"},
                {"name":"Douban Music"},
                {"name":"Douyin"},
                {"name":"Dragonfly FM"},
                {"name":"Duitang"},
                {"name":"Echo"},
                {"name":"Ex Libris"},
                {"name":"Facebook Audio Library"},
                {"name":"Facebook Fingerprinting"},
                {"name":"Facebook Video"},
                {"name":"FETnet"},
                {"name":"Fizy"},
                {"name":"Friday Music (Omusic)"},
                {"name":"Future TV"},
                {"name":"Gaopin"},
                {"name":"Gionee"},
                {"name":"Global Radio"},
                {"name":"Grandpad"},
                {"name":"GTL Music"},
                {"name":"Heiyo"},
                {"name":"HighResAudio"},
                {"name":"Himalayan FM"},
                {"name":"HMV japan"},
                {"name":"Hofer Life"},
                {"name":"Huawei"},
                {"name":"HUNGAMAMUSIC"},
                {"name":"IAM+"},
                {"name":"iFeng"},
                {"name":"iHeartRadio US"},
                {"name":"iMusica"},
                {"name":"Instagram"},
                {"name":"IPTV"},
                {"name":"iQiyi"},
                {"name":"iTunes"},
                {"name":"J Pay"},
                {"name":"J:COM"},
                {"name":"Jaxsta"},
                {"name":"Jaxsta Music"},
                {"name":"Jeou Tai"},
                {"name":"Jinshan"},
                {"name":"Jio Saavn"},
                {"name":"JOOX"},
                {"name":"Juke"},
                {"name":"Juno Download"},
                {"name":"Kanjian Music"},
                {"name":"Kanlixiang"},
                {"name":"KKBOX"},
                {"name":"Koala FM"},
                {"name":"Kolbi Music"},
                {"name":"KTV"},
                {"name":"Kuaishou"},
                {"name":"Kugou"},
                {"name":"Kuwo"},
                {"name":"Kuyin Ringtone"},
                {"name":"Lanren Tingshu"},
                {"name":"Layabox"},
                {"name":"Lenovo"},
                {"name":"Lequ Music"},
                {"name":"LETV"},
                {"name":"LINE Music"},
                {"name":"Lizhi FM"},
                {"name":"Lola"},
                {"name":"Love Music (China Telecom)"},
                {"name":"Maowang"},
                {"name":"Maxis"},
                {"name":"Meitu"},
                {"name":"MGTV"},
                {"name":"Migu Music (China Mobile)"},
                {"name":"Migu Video (China Mobile)"},
                {"name":"Moodagent"},
                {"name":"MOOV"},
                {"name":"Mora Qualitas"},
                {"name":"Movistar Colombia"},
                {"name":"Movistar Musica Colombia"},
                {"name":"Movistar Musica Peru"},
                {"name":"MTN Uganda"},
                {"name":"MyMelo"},
                {"name":"MyMusic"},
                {"name":"Napster"},
                {"name":"NEC"},
                {"name":"NetEase"},
                {"name":"Odnoklassniki"},
                {"name":"Onkyo"},
                {"name":"OPPO"},
                {"name":"Orange Dominican Republic"},
                {"name":"Pandora"},
                {"name":"Peloton"},
                {"name":"Play Network"},
                {"name":"PLAY Poland"},
                {"name":"Plex"},
                {"name":"PPTV"},
                {"name":"Pretzel Rocks"},
                {"name":"Pyro Music"},
                {"name":"Qianqian Music"},
                {"name":"Qobuz"},
                {"name":"QQ Music"},
                {"name":"Raku"},
                {"name":"Rakuten"},
                {"name":"Rhapsody"},
                {"name":"ROXI"},
                {"name":"Samsung Milk Music (China only)"},
                {"name":"SberZvuk"},
                {"name":"SECURUS"},
                {"name":"Shazam"},
                {"name":"Simfy Africa/MTN"},
                {"name":"Sina Video"},
                {"name":"Singtel"},
                {"name":"SiriusXM"},
                {"name":"Slacker"},
                {"name":"Sohu"},
                {"name":"SoundCloud"},
                {"name":"SoundMachine"},
                {"name":"Soundtrack Your Brand"},
                {"name":"Sprint US"},
                {"name":"Stellar Entertainment"},
                {"name":"T Star"},
                {"name":"TaiHua"},
                {"name":"Taiwan Mobile"},
                {"name":"TDC Play Musik"},
                {"name":"Telefonica Brazil"},
                {"name":"Telenor DK"},
                {"name":"Telmore Affiliate"},
                {"name":"Tencent Video"},
                {"name":"TIDAL"},
                {"name":"TIM Italia"},
                {"name":"TingTing FM"},
                {"name":"TMobilePL"},
                {"name":"TouchTunes"},
                {"name":"Toutiao"},
                {"name":"TrackDrip"},
                {"name":"Traxsource"},
                {"name":"Triller"},
                {"name":"Tudou"},
                {"name":"Tuzhan"},
                {"name":"U-Mobile"},
                {"name":"Ultimate Music China Limited"},
                {"name":"Unlimited Music China Limited"},
                {"name":"UTAPASS"},
                {"name":"Virgin Mobile Colombia"},
                {"name":"Viva Musica"},
                {"name":"VIVO hires"},
                {"name":"VKontakte"},
                {"name":"Vodafone"},
                {"name":"Vodafone Zambia"},
                {"name":"VUE"},
                {"name":"Walkgame"},
                {"name":"Wasu TV"},
                {"name":"We Sing"},
                {"name":"Weibo Music"},
                {"name":"Weltbilt"},
                {"name":"WOM Chile"},
                {"name":"Wotu"},
                {"name":"Wynk"},
                {"name":"Xiami"},
                {"name":"Xiaomi Music"},
                {"name":"Xiaoying"},
                {"name":"Xite"},
                {"name":"Xiumi"},
                {"name":"Xiutang"},
                {"name":"Yandex Music"},
                {"name":"Yinyue Tai"},
                {"name":"Yiqixiu"},
                {"name":"Youku"},
                {"name":"YouSee (TDC)"},
                {"name":"Youting"},
                {"name":"Youtube Ad Supported"},
                {"name":"Yunjian"},
                {"name":"Yunting"},
                {"name":"Zhuote"},
                {"name":"A1 Xplore Music"},
                
            ],
        }
    },
    methods: {
        closePanel(){
            this.$emit('closePanel', {
            });
        }
    }
}
</script>

<style scoped>
.overflowStyle {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.overflowStyle::-webkit-scrollbar {
    display: none; /*chrome, safari, opera*/
}

</style>