<script>
import { Doughnut, mixins } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: ["chartOptions"],
  data(){
      return {
          chartData : this.chartOptions,
          legend_position: "right",
          legend_padding: 40
      }
  },
  computed: {
      chartDatasets(){
        this.chartData.datasets.filter(data =>{
            if(data.hasOwnProperty('is_all')){
                if(!data.is_all) {
                   return data
                }
            }else{
                return data
            }
        })
        return this.chartData
      }

  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    if(this.chartData.options.hasOwnProperty('legend_position')){
        this.legend_position = this.chartData.options.legend_position
        if(this.legend_position == 'top') {
            this.legend_padding = 10
        }
    }

    this.renderChart(this.chartDatasets, {
        legend: {
            display: this.chartData.options.legend,
            position: this.legend_position,
            labels: {
                boxWidth: 30,
                fontColor: 'black',
                padding: this.legend_padding
            }
        }
    })
  }
}

</script>