<template>
<div>{{$t('aboutUs.msg.content')}}</div>
</template>

<script>
export default {
    name: 'AboutUs',
    data(){
        return {
            description: ' 회사소개 페이지 입니다.'
        }
    }
}
</script>