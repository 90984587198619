<template src="./template.html"></template>

<script>

import i18n from '../../../library/i18n' // 다국어지원

export default {
    name: "imageUpload",
    props: ['props',"type", "id", "eventName", "minWidth", "minHeight", "returnKey", "imageFileInfoData"],
    data(){
        return {
            imageDate: "",
            imgWidth: 0,
            imgHeight: 0,
            classType: null,
            getMessage: null,         // 안내 메세지
            inputFileData : null
        }
    },
    mounted(){
        let type = this.type
        console.log(this.minWidth)
        this.$nextTick(function () {
        // 모든 화면이 렌더링된 후 실행합니다.
            const id = this.eventName
            const filelabel = document.getElementById(this.id);
            const dropbox = document.getElementById(this.dropbox);
            const inputFile = document.getElementById(this.inputFile);
            let previewImage = document.getElementById(this.previewImage)
            /* 20200610 임시보류 if(dropbox) dropbox.classList.add(this.previewStyle + "_dropbox") */
            if(filelabel) filelabel.classList.add(this.previewStyle + "_filelabel")
            if(inputFile) inputFile.classList.add(this.previewStyle + "_inputFile")
            if(previewImage) previewImage.classList.add(this.previewStyle + "_previewImage")
            if(this.imageFileInfoData){
                this.imageDate = this.imageFileInfoData.remote_path
            }
            
        })
    },
    watch: {
        imageFileInfoData(){
            if(this.imageFileInfoData){
                // 로딩바 생성
               // const dropbox = this.loading()
                
                let img = new Image();
                img.src = this.imageFileInfoData.remote_path
                img.onload = function (event) {
                    if(img.complete){
                        this.imageDate = this.imageFileInfoData.remote_path
                        //this.loading_close(dropbox)
                    }
                }.bind(this)
            }
        },
        dropbox() {
            return 'dropbox_'+ this.id
        },
        inputFile() {
            return 'inputFile_'+ this.id
        },
        // previewImage(){
        //     return 'previewImage_'+ this.id
        // },
        previewStyle(){
            return  this.classType = this.type 
        }
    },
    computed: {
        dropbox() {
            return 'dropbox_'+ this.id
        },
        inputFile() {
            return 'inputFile_'+ this.id
        },
        previewImage(){
            return 'previewImage_'+ this.id
        },
        previewStyle(){
            return  this.classType = this.type 
        },
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const limitResolution = i18n.t('components.imageUpload.artist.msg.limitResolution1') + this.minWidth + "x" + this.minHeight + i18n.t('components.imageUpload.artist.msg.limitResolution2')
                const limitFormat = i18n.t('components.imageUpload.artist.msg.limitFormat')

                const messageCollection = {
                    limitResolution: limitResolution,
                    limitFormat: limitFormat
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        }

    },
    
    methods: {
        dragUnOver(){
            let el = document.getElementById(this.id);
            el.style.opacity = 0.8;
            el.style.visibility = "visible";
            el.style.backgroundColor = "#f2f2f2"
            //el.childNodes[0].getElementsByClassName('sctup_sp')[0].style.color = "#6d6c6c"
        },
        dragOver(){
            let el = document.getElementById(this.id);
            //el.childNodes[0].getElementsByClassName('sctup_sp')[0].style.color = "#fff"
            el.style.backgroundColor = "#2547c9"
        },
        fileDrop(event){
            console.log(event);
            event.stopPropagation();
            const file = event.dataTransfer.files[0];
            const fileName = file.name;

            // 파일 포멧 검색 jpg, png, jpeg
            let reg = new RegExp('jpg|jpeg|png','i');
    
            if(!reg.test(fileName)) {
                this.popupOpen('dacLay01','limitFormat')
                this.dragUnOver();
                return;
            }

            // 로딩바 생성
            //const dropbox = this.loading()  

            // coverImg
            const reader = new FileReader();
            this.loadingShow()
            reader.onload = (e) => {
                let img = new Image();
                img.src = e.target.result;
                this.imageDate = e.target.result
                
                img.onload = () =>{
                    this.imgWidth = img.width;
                    this.imgHeight = img.height;

                    // Minimum image resolution limit (1400 x 1400)
                    if(this.imgWidth < this.minWidth && this.imgHeight < this.minHeight || this.imgWidth < 3001 && this.imgHeight < 3001 ||this.imgWidth != this.imgHeight ){
                        let text = i18n.t('components.imageUpload.artist.msg.limitResolution1') + this.minWidth + "x" + this.minHeight + i18n.t('components.imageUpload.artist.msg.limitResolution2')
                        console.log(text);
                        this.alert({msg : text})
                        //this.popupOpen('dacLay01', 'limitResolution')
                        this.imageDate = ''
                        this.$refs.artistImg.value = null;
                        //this.loading_close(dropbox)
                        this.dragUnOver()
                    }else{
                        this.$EventBus.$emit(this.eventName, file, this.returnKey)  // File 전송
                        if(img.complete) { // Image loading Complete
                            let previewImage = document.getElementById("previewImage_"+ this.id)
                            previewImage.classList.add(this.previewStyle+"_previewImage")
                            //this.loading_close(dropbox) 
                            this.dragUnOver()
                        }
                    }
                    this.loadingHide()
                }
            }
    
            if (file) {
                setTimeout(reader.readAsDataURL(file))
            }
        },
        uploadfileAPi(file) {
            this.$store.dispatch("FILEUPLOAD", {file})
                .then(result => {
                    this.dragUnOver()
                }).catch(Error =>{
                    console.log("파일 업로드 실패 ::::!!!", error)
                })
        },
        filechange(event){
            event.stopPropagation();
            const file = event.target.files[0];
            const fileName = file.name;

            // 파일 포멧 검색 jpg, png, jpeg
            let reg = new RegExp('jpg|jpeg|png','i');
            if(!event.target.files){
                return false
            }
            if(!reg.test(fileName)) {
                this.popupOpen('dacLay02','limitFormat')
                this.dragUnOver();
                return false;
            }

            // 로딩바 생성
            //const dropbox = this.loading()
            this.loadingShow()
            const reader = new FileReader()
            reader.onload = (e) => {
                let img = new Image()
                img.src = e.target.result
                this.imageDate = e.target.result
            
                img.onload = () =>{
                    this.imgWidth = img.width
                    this.imgHeight = img.height

                    // 최소 이미지 해상도 제한 1400 x 1400
                    console.log(e);
                    if(this.imgWidth < this.minWidth && this.imgHeight < this.minHeight){
                        console.log(this.minWidth,this.minHeight)
                        let text = i18n.t('components.imageUpload.artist.msg.limitResolution1') + this.minWidth + "x" + this.minHeight + i18n.t('components.imageUpload.artist.msg.limitResolution2')
                        console.log(text);
                        this.alert({msg : text})
                        this.$refs.artistImg.value = null;
                        this.imageDate = ''
                        //this.loading_close(dropbox)
                        this.dragUnOver()
                        return false
                    }else{
                        this.$EventBus.$emit(this.eventName, file, this.returnKey)  // File 전송

                        if(img.complete) { // 이미지 로딩 완료 후
                            let previewImage = document.getElementById("previewImage_"+ this.id)
                            previewImage.classList.add(this.previewStyle +"_previewImage")
                            //this.loading_close(dropbox)
                            this.dragUnOver()
                        }
                    }
                    this.loadingHide()
                }
            }
    
            if (file) {
                reader.readAsDataURL(file)
                this.loadingHide()
            }
        },
        
        previewImageFile(files){
            let file = files[0];
            let fileName = file.name;
    
            const formData = new FormData();
            formData.append(fileName, files[0], files[0].name)
            let reader = new FileReader();
            
            reader.onload = (e) => {
                let img = new Image();
                img.src = e.target.result;
                img.onload =function(){
                    this.imgWidth = this.width
                    this.imgHeight = this.height
                }
                this.imageDate = e.target.result
            }

            // 파일 포멧 검색 jpg, png, jpeg
            let reg = new RegExp('/(.*?)\.(jpg|jpeg|png)$/')
    
            if(!'artist2.png'.match(reg)) {
                this.popupOpen('dacLay02','limitFormat')
                this.dragUnOver();
                return false;
            }
        
            reader.readAsDataURL(file)
        },
        imageUpload(event){
            this.dragOver()
            this.loadingShow()
            let file = files[0]
            let fileName = file.name
    
            const formData = new FormData()
            formData.append(fileName, files[0], files[0].name)
            let reader = new FileReader()
            reader.onload = (e) => {
                this.loadingHide()
                this.imageDate = e.target.result;
            }

            reader.readAsDataURL(file) 
        },
        file_delete(){
            this.imageDate = ""
            const filelabel = document.getElementById(this.id)
            filelabel.style.opacity = 0.8;

            let file = null
            this.$EventBus.$emit(this.eventName, file, this.returnKey)  // File 전송
        },
        loading(){
            // 로딩바 생성
            const dropbox = document.getElementById(this.dropbox) // dropbox Select
            const loader_bg = document.createElement("div")
            const loader = document.createElement("div")

            loader_bg.style.width = "100%"
            loader_bg.style.height = "100%"
            loader_bg.style.zIndex = "2000"
            loader_bg.style.position = "absolute"
            loader_bg.addEventListener("dragover", this.dragover_handler);

            loader.setAttribute("id", this.previewStyle + "_loader")
            loader.classList.add('loader')
            loader.classList.add('type3') 
            loader_bg.append(loader)  
            dropbox.prepend(loader_bg)
            return dropbox
        },
        loading_close(node){
            // 로딩바 생성
            node.firstChild.childNodes[0].classList.add('type4')
            setTimeout(function(){
                node.removeChild(node.firstChild)
            },3000)
        },
        popupOpen(id, title){
            // popup Open
            let popup = document.getElementById(id)
            if(popup) {
                popup.classList.add('type2')
                this.guideMessage = title
                setTimeout(function(){
                    popup.firstChild.classList.add('type2')
                }, 300)
            }
        },
        popupClose(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          if(popup){
                popup.firstChild.classList.remove('type2')
                setTimeout(function(){
                    popup.classList.remove('type2')
                }, 300)
            }
        },
        dragover_handler(event){
            event.preventDefault()
            event.dataTransfer.dropEffect = "none"
        }
    }
}
</script>

<style>
    .filelabel {
        position: absolute;
        width: 8rem;
        height: 8rem;
        top:0;
        left:0;
        z-index: 20;
        cursor: pointer;
        padding: 2rem;
        background: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .inputFile{

    }
    #imgfile{
        opacity: 0;
    }

    /* [S] cover Style ==========================================*/

    
    /* [S] artistStyle ==========================================*/

    .artist_filelabel {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top:0;
        left:0;
        background: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }


    .artist_inputFile {
        position: absolute;
        opacity: 0;
        width: 100% !important;
        height: 100%;
        bottom: 0;
        left: 0;
        z-index: 70;
        cursor: pointer;
        color:transparent; 
    }

    .artist_previewImage {
        position: absolute;
        width: 100%;
        /* height: 100%; */
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        z-index: 10;
    }

    /* [E] artistStyle ==========================================*/

</style>