<template src="./template.html"></template>

<script>
export default {
    name: 'panel-1',
    props: {
        name: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            pwChangeForm: {
                old_password: "",
                new_password: ""
                // keepAlive: false
            },
            passwordCheck: true,
            type: null,
            error: []
        }
    },
    methods: {
        onSubmit(){
            let params = this.pwChangeForm.new_password
            let pwChangeForm = this.pwChangeForm
            this.error = []
            let emptyType = this.type

            
            if(this.valCheck(this.pwChangeForm.old_password)){
                this.alert({msg : this.iText('현재 비밀번호를 확인해주세요.','pwChangeError1')})    
                return false
            }
            if(this.valCheck(this.pwChangeForm.new_password)){
                this.alert({msg : this.iText('새 비밀번호를 확인해주세요.','pwChangeError2')})    
                return false
            }
            const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&+_~=<>.,-^])[A-Za-z\d$@$!%*#?&+_~=<>.,-^]{8,50}$/; //최소 8 자, 최소 하나의 문자, 하나의 숫자 및 하나의 특수 문자 :
            if (this.pwChangeForm.new_password.length < 8) {
                this.alert({msg : this.iText('새 비밀번호를 확인해주세요.','pwChangeError2')})
                return false
            } else if (!re.test(this.pwChangeForm.new_password)) {
                this.alert({msg : this.iText('새 비밀번호를 확인해주세요.','pwChangeError2')})
                return false
            }
            this. loadingShow()
            this.$store.dispatch("PASSWORDCHANGE", pwChangeForm)
                .then(() =>{
                    this.alert({msg : this.$i18n.t('components.panel.panel1.msg.success')})
                    this.closePanel()
                    this. loadingHide()
                }).catch((error) => {
                    this. loadingHide()
                    const errorData = error.response.data
                    const code = errorData.code
                    if (code == 52) {
                        // Error Message Show
                        const error = this.error.indexOf('old_password')
                        if(error === -1){
                            this.alert({msg : this.iText('현재 비밀번호를 확인해주세요.','pwChangeError1')})
                        }
                    }
            })
        },
        closePanel(){
            this.$emit('closePanel', {
            });
        },
        validPassword(){
            let password = this.pwChangeForm.new_password
            this.$store.dispatch("VALIDPASSWORD", password).then((result) => {
                
                return result
            }).catch((message) => {return message})
        },
        errorMessage(id){
            // Error Message Hide
            const error = this.error.indexOf(id)
            if(error > -1){
                this.error.splice(error, 1);
            }
        },
        pwd_openBtn(e){
            const target = e.target.previousSibling
            console.log(e.target.previousSibling)
            e.target.classList.toggle("active")
            if(target.type == 'password') {
                target.type = 'text'
            }else if(target.type == 'text') {
                target.type = 'password'
            }
        }
    }
}
</script>