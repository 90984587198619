<template src="./template.html"></template>

<script>
export default {
    name: 'panel-20',
    methods: {
        closePanel(){
            this.$emit('closePanel')
        }
    }
}
</script>