
<template>
    <div >
        <p v-for="(item, key) in langList">
            <span style="display:inline-block; font-size: inherit; ">
                <v-select class="" v-if="$i18n.locale == 'ko'" style="width:100%; height:auto;"
                name='name'
                label="name"
                v-model="key"
                :options="languageOption"
                :reduce="languageOption => languageOption.code" 
                :clearable="false"
                disabled 
                >
                
                </v-select>
                <v-select class="" v-else style="width:100%; height:auto;"
                name='name'
                label="en_name"
                v-model="key"
                :options="languageOption"
                :reduce="languageOption => languageOption.code" 
                :clearable="false"
                disabled 
                >
                </v-select>
            </span>
            &nbsp;
            <span style="padding:0 1px; font-size: 15px;">{{langList[key]}}</span>
        </p>
    </div>
</template>

<script>
import {language} from '../store/data'
export default{
  name: 'langBox',
  props: ['langList','title'],
  data () {
      return{
          name : null,
          lang : null,
          languageOption : language,
      }
  },
  watch: {
    'langList'(){
        
    }
  },
  mounted () {
      
  },
  methods:{
      update(name,val){
        console.log(name,val.target.value)
        this.langList[name] = val.target.value
        this.$forceUpdate()
        this.$emit('changeUpdate', this.langList)
      },
      add(){
        if(this.lang && this.name){
            if(!this.langList[this.lang] ){
                this.langList[this.lang] = this.name
                this.lang = null    
                this.name = null
                 this.$forceUpdate()
                this.$emit('changeUpdate', this.langList)
            }else{
                //this.noti('이미 등록된 언어 입니다.')    
                this.alert({msg : this.$i18n.t('error.language')})
            }
        }else{
            //this.noti('언어와 이름을 입력하세요.')
            this.alert({msg : this.$i18n.t('enter.languagename')})
        } 
       
      },
      del(data){
        delete this.langList[data]
        this.$forceUpdate()
        this.$emit('changeUpdate', this.langList)
      }

  }
}
</script>