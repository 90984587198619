<template src="./template.html"></template>

<script>
export default {
    name: "panel-16",
    props: ["props",],
    data() {
        return {
            search: null,
            genres: [],
            eventName: "genreList",
            click: true,  // 더블 클릭 방지용
            currentData : [],
            returnName : null
        }
    },
    created(){
        this.currentData = this.props.currentData  
        this.returnName = this.props.returnName  
        
        this.genres = this.currentData  
        
        this.$EventBus.$on(this.eventName, (params) => {
            this.genres = params
        })
    },
    computed: {
        gridScrollListOption (){
            return {
                listCount : 20,
                listType: "normal",
                uiType: "checkbox",
                eventName: this.eventName,
                all_check: false,
                filterView: false,
                currentData: this.currentData
            }
        }
    },
    methods: {
        onSubmit(){
            console.log('this.genres');
                console.log(this.returnNam);
            if(this.overClick()){
                
                this.$EventBus.$emit('genre', this.genres)
                const params = {
                    name: 'genres',
                    type: []
                }
                this.closePanel()
            }
        },
        closePanel(){
            this.$emit('closePanel', {
            });
        },
        overClick() {
            // over click 방지용
            if (this.click) {
                this.click = !this.click;
                // 타이밍 추가
                setTimeout(function () {
                    this.click = true;
                }, 1000)
                return true
            }else return false
        }
    }
}
</script>

<style scoped>
.style02 {
    background-color: blue;
}

.showPanel {
    cursor: pointer;
}
</style>