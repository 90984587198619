import { render, staticRenderFns } from "./template.html?vue&type=template&id=b9741828&scoped=true&external"
import script from "./index_modify.vue?vue&type=script&lang=js"
export * from "./index_modify.vue?vue&type=script&lang=js"
import style0 from "./index_modify.vue?vue&type=style&index=0&id=b9741828&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9741828",
  null
  
)

export default component.exports