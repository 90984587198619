<template src="./template.html" ></template>

<script>
import i18n from '../../../library/i18n' // 다국어지원
import 'es6-promise/auto'

export default{
    data(){
        return {
            error: [],
            email : null,
            password: null,
            reference: null,
            privacy: false,
            marketing: false,
            language : "ko",
            getMessage: null // popup message
        }
    },
    computed: {
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const email_exists = i18n.t("signup.msg.email_exists")
                const successfully = i18n.t("signup.msg.successfully")
                const locale = this.$i18n.locale
                const privacy = this.$store.state.privacy[locale]
                const terms = this.$store.state.terms[locale]

                const messageCollection = {
                    email_exists: email_exists,
                    privacy: privacy,
                    terms: terms,
                    successfully: successfully
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title) this.getMessage = messageCollection[key]  
                })
            } 
        }
    },
    methods: {
        signUpForm(e){
            // Email
            if(!this.email){
                this.error.push('email');
                return false;
            }else if(!this.validEmail(this.email)){
                this.error.push('email')
                return false;
            }
            let formCheck = new Promise(resolve =>{
                // 개인정보취급 동의
                if(!this.privacy) {
                    this.error.push("privacy")
                    return;
                };

                // Password Check
                this.$store.dispatch("VALIDPASSWORD", this.password)
                .then((result) => {
                    if(!result) this.error.push('password');
                    // 실행중지
                    if(this.error.length) resolve(false);
                    else resolve(true)

                    this.errors.splice(0, this.error.length) //초기화
                })
                .catch((message) => console.log("VALIDPASSWORD FAIL:::"))
            });

            formCheck.then(value=>{
                if(value){
                    // 실행중지
                    // 회원가입 실행
                    let email = this.email
                    let password = this.password
                    let reference = this.reference
                    let privacy = this.privacy
                    let marketing = this.marketing
                    let language = this.language

                    let params = {
                        email: email,
                        password: password,
                        marketing: marketing,
                        language:language
                    }

                    if(reference){ // 가입경로(선택) null아닐때 paramter 추가
                        Object.assign(params, {reference: reference})
                    }
                    
                    this.$store.dispatch('SIGNUP', params)
                        .then((data) => {
                            this.popupOpen01("signup_guide03", "successfully")
                        })
                        .catch( (error) => {
                            if(error) {
                               const code = error.response.data.code
                               switch(code) {
                                   case 56:
                                    this.popupOpen01('signup_guide01','email_exists')
                                    break;
                               }
                            }
                        })
                }
            });
            e.preventDefault();
        },
        validEmail(email){
            const re= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        errorMessage(event){
            // Error Message Hide
            const id = event.target.id
            const error = this.error.indexOf(id)
            while(true){
                let search = this.error.indexOf(id)
                if(search!=-1){
                     this.error.splice(search, 1); // "A"를 찾아서 삭제한다.
                }else{
                    break;
                }
            }
        },
        popupOpen01(id, title){
            // popup Open
            let popup = document.getElementById(id)
            if(popup) {
                popup.classList.add('type2')
                this.guideMessage = title
                setTimeout(function(){
                    popup.firstChild.classList.add('type2')
                }, 100)
            }
        },
        popupOpen02(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type3')
            this.guideMessage = title
            
        },
        popupClose01(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          if(popup) {
            popup.firstChild.classList.remove('type2')
            setTimeout(function(){
                popup.classList.remove('type2')
            }, 100)
          }
        },
        popupClose02(event){
            // popup close 보안관련 팝업 전용
          const parent = event.target.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('active')
          setTimeout(function(){
              popup.classList.remove('type3')
          }, 300)
        },
        popupClose03(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          if(popup) {
            popup.firstChild.classList.remove('type2')
            setTimeout(function(){
                popup.classList.remove('type2')
            }, 300)
            this.$router.push('/login')
          }
        },
        pwd_openBtn(e){
            const target = e.target.previousSibling
            e.target.classList.toggle("active")
            if(target.type == 'password') {
                target.type = 'text'
            }else if(target.type == 'text') {
                target.type = 'password'
            }
        }
    }
}
</script>

<style scoped>
#signUp {
    text-align: center;
    margin-top: 90px;
}

/* [S] document Style */
.doc_title{
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -1px;
}

.doc_contents{
    word-break: keep-all;
    margin-bottom: 20px;
}
/* [E] document Style */
</style>