export default {
    'confirm' : 'Confirm',
    'cancel' : 'Cancel',
    'save' : 'Save',
    'select' : 'Select',
    'next' : 'Next',
    'prev' : 'Prev',
    'add' : 'Add',
    'delete' : 'Delete',
    'commingSoon' : 'COMING SOON!',
    'accessWarning':'You do not have access rights.',
    'accessWrong' : "The wrong approach.",//
    'english' : 'English',
    'language':'Language',
    'remarks': 'Remarks',
    'remarksText' : 'If there is anything else we need to know regarding your release, please enter your message here.',
    'status' : 'Status',
    'name' : 'Name', //
    'type' : 'Type',//
    'releaseLink' :'Release Link',// 
    'releases' : 'Releases',  //
    'tracks' : 'Tracks', // Tracks
    'artists' : 'Artists', //
    'salesTrend' : 'Monthly Trend', // Monthly Trend,
    'salesTotal' : 'Sales Ratio', // Sales Ratio
    'download':'Download',//
    'streaming':'Streaming',//
    'other':'Other',//

    'musicstore': 'Music Store',//Music Store
    'country' : 'Country',//
    'menu.album' : 'Releases',// 
    'menu.artist' : 'Artist',// 
    'menu.withdraw' : 'Withdraw',// 

    'albumName' : 'Album',//  
    'artistName': 'Artist',// 
    

    'distribution.distribution':'Distribution',//
    'distribution.albumInfo':'Release',//
    'distribution.trackInfo':'Tracks',//
    'distribution.submit' : 'Distribution',//
    'distribution.submit.msg' : `
        1. The release materials you have submitted will be released exactly as you have entered them.<br/><br/>

        2. Please check carefully for any missing data and typing errors. <br/><br/>

        3. Some special characters, symbols, foreign language translations, etc. may not be displayed due to platform’s policy. <br/>

        <span class=redpoint>*</span> After submission, you cannot modify the data.  
        
        <br/><br/>

        <p style="text-align:center;">Are you sure you want to submit?</p>
    `,//
    'distribution.cancel' : 'Distribution Cancel',//
    'distribution.cancel.msg' : `Are you sure you want to cancel? <br/>
    If you cancel, you cannot modify what you wrote. Also the administrator can't see the contents when the registration is canceled.<br/> (If you need to modify it, please contact us by email.)`,//
    
    
    
    
    
    // 앨범
    'album.name' : 'Title',
    'album.add' : 'Add Single/EP/Album',
    'album.name.msg' : 'Some stores only use names with language values. Please write down the translation for each language.',
    'album.name.msg2' : "Please enter the title here.",// 
    'album.validationTitle' : 'Validation Errors',// 
    'album.validationText' : 'Some of the essential items for your application have not been entered. <br>Will you fix it later?',//
    'album.fixnow' : 'Fix Now.', 
    'album.fixlater' : 'Save & Next',
    'album.distributor' :'Distributor' ,//
    'album.releaseType' : 'Release Type',//
    'album.releaseDate' : 'Release Date',//
    'album.has_mv' :"Music Video",//

    //트랙명
    'track.title' : 'Focus Track',
    'track.titleStar1' : 'Main Focus',
    'track.titleStar2' : '2nd Focus',
    'track.titleStar3' : '3rd Focus',
    'track.titleStar4' : '4th Focus',
    'track.noTitle' : 'Need Title Track',//
    'track.name' : 'Track Title',//
    'track.add' : 'Add track',//
    'track.artist' : 'Artist',// 
    'track.contributor' : 'Contributor',//
    'track.contributer.name':'Name', // 
    'track.contributer.company':'Publishing Company', // 
    'track.composer' : 'Composer',//
    'track.lyricist' : 'Lyricist',//
    'track.arranger' : 'Arranger',//
    'track.genre' : 'Genre',
    'track.file' : 'File',
    'track.fileType' : 'FLAC or WAV',
    "track.type" : 'Track Version',
    "track.copy" :'Copy data from', //
    "track.select" :"Select track", //
    "track.contributor.copy" : 'Copy contributor',// 
    "track.copy.msg" : 'Creates a duplicate of the selected track (Track Artist/Track Genre/Contributor/Featuring Artist)', //
    "track.copy.error.select" : 'Please select a track.',//
    "track.copy.error.length" : 'The selected track has no contributor.',//
    "track.mp3.error" : "Please upload the MP3 file that is over 320kbps.", //
    "track.nameAndType.error" : 'There is already a track with the same track title and version.', //
    "track.volume" : 'Volume',
    "track.vol" : "Vol",
    contributorsOption:{
        composer : 'Composer',//
        lyricist : 'Lyricist',//
        arranger : 'Arranger',//  
    },
    //아티스트
    'artistType.solo_female': 'Solo Female',
    'artistType.solo_male': 'Solo Male',
    'artistType.group_female': 'Group Female',
    'artistType.group_male': 'Group Male',
    'artistType.group_combined': 'Group Combined',

    'genre.msg.more' : 'If you choose Indie and K-Pop genres, please choose one more detailed genre.',//

    //
    'account.period.tooltip' : `
    “Service Month” is the month when the sales were generated from the music stores.<br>
    “Report Month” is the month when Sound Republica received the money from the music stores.<br>
    On average, “Report Month” is two months after “Service Month”, but it varies by each music store.<br>
    (For the sales amount generated on the “Service Month” of January, Sound Republica receives the money from the music stores in March, in which case March is the “Report Month”)
    `,
    
    //입력
    'pwChangeError1' : 'Please check your password',
    'pwChangeError2' : 'Confirm your new password',
    'inputValueCheck' : 'Please check the input information.',
    'regSuccess' : 'You`re registered.',//

    'error.track.name'  : 'Please enter the track title',// 
    'error.track.name_language'  : 'Please select a language and enter a track title',//
    'error.applyAlbum' : 'Please check the album information or track information',//앨범정보 또는 트랙정보를 확인해주세요.
    'error.trackLength' : 'At least 1 track is required', // 
    'error.track.title' : 'Please select main focus track', // no track title
    'error.language' : 'The language is already registered', //
    'enter.languagename' : 'Please select a language and enter a title.' , // 
    'enter.require' : 'There are required items that have not been entered. ',//
    'enter.album.name' : 'Please enter the album title',//
    'enter.language' : 'Please enter the album name',
    'enter.track.name' : 'Please enter the track title',
    'enter.track.composerName':'Please enter the composer name.',//
    'enter.track.lyricistName':'Please enter the lyricist name.',//
    'enter.track.arrangerName':'Please enter the arranger name.',//
    'enter.track.contributorRole': 'Please select the contributor role.',//
    'enter.track.contributorName': 'Please enter the contributor name.',//
    'enter.track.contributorOne' : 'Please register at least 1 composer.',//
    'enter.musicStore':'Please select a music store',//
    'enter.translation' : "Please enter if there is a translation.",//
    'enter.selectEnter' : '선택 입력', // opti

    'confirm.deleteAlbum' : 'Are you sure you want to delete this album?',//
    'confirm.deleteTrack' : 'Are you sure you want to delete this track?', //
    'confirm.leavePage' : "Are you sure you want to leave this page? \n Changes may not be saved.",//
    
    'tooltip.uci': `Universal Content Identifier (UCI) is the national standard identification system in Korea to identify music contents. `,
    'tooltip.original_release_date' : `If your album has already been released, please select the original release date.\n If this album is being released for the first time, leave it blank.`,
    'tooltip.releaseType' : `Single : 1 to 3 songs that are less than 10 minutes each <br/>EP : 4 to 6 songs with a total running time of 30 minutes or less<br/>Album : 7 or more Tracks and total  duration over 30 minutes <br/>*1 to 3 songs with one song at least 10 minutes long and a total running time of 30 minutes or less : EP<br/>* under 6 songs and total running time over 30 minutes or more : Album<br/>If not selected, it is selected according to the number of tracks.`,
    'tooltip.sns': 'Please tell the artist channel that communicates with people such as YouTube, Facebook, Twitter, Instagram, and Website.', //
    'tooltip.indetifier' : `
        Finding Your Artist URL <br/>
        The value that appears after the last slash(/) that is the Artist ID <br/>
        *Please make sure you're in the right artist page. Be careful especially if there are many artists of the same name. <br/>
        [Apple Music] Search and Copy the artist page link at https://music.apple.com/us/browse<br/>
        <img style="max-style:100%; margin:20px 0 ;" src="https://storage.googleapis.com/srd-static/web-common/tooltip/Artist_ID_apple.jpg" /><br/>
        [Melon] Search and Copy the artist page link at https://www.melon.com/<br/>
        <img style="max-style:100%; margin:20px 0 ;" src="https://storage.googleapis.com/srd-static/web-common/tooltip/Artist_ID_melon.jpg" /><br/>
        [Spotify] Search and Copy the artist page link at http://open.spotify.com <br/>
        <img style="max-style:100%; margin:20px 0 ;" src="https://storage.googleapis.com/srd-static/web-common/tooltip/Artist_ID_spotify.jpg" /><br/>
        If the main artist, featured artist, or participant has a history of release, an artist will be created on each of these platforms. If you deliver the artist identifier of the created platform in advance, matching will be easier when it is newly released.<br/>
        (If there is no artist ID delivered at the time of release, it can be created as a new artist)
    `,
    'tooltip.trackTitle' : 'Every release needs a main focus track. Please select main focus track even it is a one track single.',//
    'tooltip.artistName' : `
        If you write several languages side by side in the representative name, please make sure to enter separate the title by each language in the translation field.<br/>
        e.g.)<br/>
        Title : 사운드리퍼블리카(Sound Republica)<br/>
        Translation :<br/>
        [Korean] 사운드리퍼블리카<br/>
        [English] Sound Reublica<br/>
        
        - Content must be accurate and formatted correctly. Check spelling, grammar and punctuation.<br/>
    `,//
    'mobile' : 'This site does not provide a mobile-optimized screen.<br>Access from a PC is recommended.',
    

    'password.linkError' : 'This link has expired. Please reset the password in the latest mail.', 
    
    'marketing.msg.change' : 'Your marketing settings have been updated.', // 

    albumErrorList : {
        'territory': "Music store",
        'name':"Title",
        'name_language':"Title Language",
        'artist':"Artist",
        'genre':"Genre",
        'coverimage_id':"Cover",
        'label':"Label",
        'label_language':"Label Language",
    },

    'withdrawal.balance': `Balance`, // 
    'withdrawal.transaction': `Transaction History`, //
    'withdrawal.minimum' : 'min withdrawal amount',//
    'withdrawal.limit' : 'If there is a withdrawal request in progress, additional requests cannot be made.',//


    'artist.idetifier_msg':`Please enter your Artist ID.`,// 


    'apply.title' : 'New Release Application',//
    'apply.apply' : 'Apply',// 
    'apply.desired_date' : 'Desired Release Date',// 
    'apply.desired_date.msg' : `
     The requested release date and time are subject to change after confirmation by the staff.<br>
     If it is necessary to change the release date (time), we will notify the applicant via email or phone in advance about the possible release date (time).
    `,//

    'apply.ready_date' : 'Release materials completion date',// 
    'apply.album_title' : 'Album Title',// Titleㅔ
    'apply.album_title.msg' : 'Please enter a title',// 
    'apply.album_artist' : 'Main Artist(s)',//
    'apply.album_artist.msg' : 'Please separate the main artist from the featured artist (if there are multiple participating artists, please separate them with a comma (,)) \n ex : Artist A, Artist B, (featured) Artist C, Artist D',//Artist
    'apply.url_artist' : `Artist's streaming site URL`,//
    'apply.url_artist.msg' : 'Please enter a link to your publicly available artist page on Melon / VIBE / FLO / Bugs / Spotify, etc.  \n ex : https://www.melon.com/artist/timeline.htm?artistId=261143',//
    'apply.url_file' : 'Attachments (URL)',//
    'apply.url_file.msg' : 'Please provide URLs for demos and other attachments (Google Drive, Dropbox, etc...).',//
    'apply.has_mv' : 'Music Video',//
    'apply.remark' : 'Remarks',//
    'apply.remark.msg' : 'If you have any questions about the release or other requests, please enter them.',//
    'apply.error.enter' : 'Please enter all required values.',
    'apply.complete' : `
        Your music publishing application has been successfully submitted. <br>
        After checking with the person in charge, we will reply to the email you entered in the application. <br>
        *It may take about 3-5 business days to review and reply.
    `,

    common: {
        popup: {
            message: {
                complete: "",
                save: "saved successfully"
            }
        },
        label: {
        },
        msg: {
            imgDrag: "Click here or Drag files to upload",
            coverImgRule: [
                "File format : JPG    Color Mode : RGB",
                "Size : 3000 x 3000 pixels (min. 1400 x 1400), 1:1 ratio (square), 72 DPI recommended.<br><br>",
                "Music Stores will reject Cover Art that contains logo, url, references to other services,<br>poor quality, pornographic imagery or nazi propaganda. Don’t re-use the same cover art.",
                "Other important criteria concerning the content of the cover art:",
                "- No blurry and/or pixelated images",
                "- Cut-off images, especially cut-off text",
                "- No rotated images",
                "- No references to physical releases ('CD' or 'compact disc' logo for example)"
            ],
            agencyImgRule: [
                " Used for release on Beatport.",
                "File type: JPG",
                "Size: Min. 1000 x 1000px, 72 DPI or higher"
            ],
            loading: "Loading ......",
            none_licensor: "There is currently no distribution information, tax information or payment information.<br>Please use after registering on my page."
        },
        props: {
            all: "All",
            language: {
                ko: "한국어",
                en: "English",         // 영어
                ja: "日本語",           // 일본어
                es: "Espanol",         // 스페인어
                de: "Deutsch",         // 독일어
                nl: "Nederlands",      // 네덜란드어
                zhCn: "简体中文",       // 중국어 (간체)
                zhTw: "繁體中文"        // 중국어 (번체)
            },
            nationality : {
                kr: "대한민국",
                us: "United States",    // 미국
                jp: "日本",             // 일본
                es: "España",           // 스페인
                de: "Deutschland",      // 독일
                nl: "Nederland",        // 네덜란드
                cn: "中国"              // 중국
            },
            artist: {
                type: {
                    solo_male: "Solo / Male",
                    solo_female: "Solo / Female",
                    group_male: "Group / Male",
                    group_female: "Group / Female",
                    group_combined: "Group / combined"
                }
            },
            state: {
                // temporary, registration_complete, inspection_complete, distribution_complete, release_complete, suspended, rejected
                temporary: "Draft",
                registration_complete: "Submit",
                inspection_complete: "In Review",
                registration_canceled: "Cancelled",
                suspended: "Suspended",
                rejected: "Rejected",
                distribution_complete: "Delivered",
                release_complete: "Released",
                /* 보류 distribution_complete: "Delivered", */
                // 발매완료 문구는 권리자가 알 필요성이 없어서 유통완료 표기만 사용 
            },
            musicStoreList: [
                "MELON",
                "YouTube",
                "Genie Music",
                "FLO",
                "NAVER MUSIC",
                "VIBE",
                "BUGS",
                "Apple Music",
                "Spotify",
                "KAKAO MUSIC",
                "Acfun",
                "Advertising Agency",
                "Akazoo",
                "Aldi Life",
                "ALSONG",
                "Amazon",
                "Amazon Ad-Funded Streaming Service",
                "Amazon Prime",
                "Amazon Unlimited",
                "Anghami",
                "Audiomack",
                "AWA",
                "B612",
                "beat.no",
                "Beatport",
                "Bell 365",
                "Best Friends",
                "Bilibili",
                "Bitel Peru",
                "BMAT",
                "Boom",
                "Boomplay",
                "Buecher",
                "Carrier Billing",
                "CCMLOVE",
                "CCMPIA",
                "Celcom",
                "Changba",
                "China Unicom (Wo Music)",
                "Chunghwa Mobile",
                "CJ Hello Vision",
                "Coolvox",
                "DaPian",
                "Deezer",
                "DiGi",
                "Digicel",
                "Douban Music",
                "Douyin",
                "Dragonfly FM",
                "Dubset Media",
                "Duitang",
                "Echo",
                "Electric Jukebox",
                "Ex Libris",
                "EZWelfare",
                "FETnet",
                "Fizy",
                "Friday Music (Omusic)",
                "Future TV",
                "Gaopin",
                "Gionee",
                "Global Radio",
                "GodPeople",
                "Google Play",
                "Grandpad",
                "Groovers",
                "GTL Music",
                "Heiyo",
                "HighResAudio",
                "Himalayan FM",
                "HMV japan",
                "Hofer Life",
                "Huawei",
                "IAM+",
                "iFeng",
                "iHeartRadio US",
                "iMusica",
                "Incruit",
                "IPTV",
                "iQiyi",
                "iTunes",
                "J Pay",
                "J:COM",
                "Jaxsta",
                "Jaxsta Music",
                "Jeou Tai",
                "Jinshan",
                "Jio Saavn",
                "Joins Prime",
                "JOOX",
                "Juke",
                "Juno Download",
                "Kanjian Music",
                "Kanlixiang",
                "KKBOX",
                "Koala FM",
                "Kolbi Music",
                "KT",
                "KTV",
                "Kuaishou",
                "Kugou",
                "Kuwo",
                "Kuyin Ringtone",
                "Lanren Tingshu",
                "Layabox",
                "Lenovo",
                "Lequ Music",
                "LETV",
                "LGU+",
                "LINE Music",
                "Lizhi FM",
                "Lola",
                "Love Music (China Telecom)",
                "Maowang",
                "Maxis",
                "Meitu",
                "MGTV",
                "Migu Music (China Mobile)",
                "Migu Video (China Mobile)",
                "Milk",
                "MNET",
                "MOMO",
                "Moodagent",
                "MOOV",
                "Mora Qualitas",
                "Movistar Colombia",
                "Movistar Musica Colombia",
                "Movistar Musica Peru",
                "MTN Uganda",
                "MUSIC MATE",
                "MyMelo",
                "MyMusic",
                "Napster",
                "Naver Samsung Smart TV",
                "Naver Search",
                "NEC",
                "NetEase",
                "Odnoklassniki",
                "Olleh Music",
                "ONE STORE",
                "Onkyo",
                "OPPO",
                "Orange Dominican Republic",
                "Pandora",
                "Peloton",
                "PLAY Poland",
                "Playnetwork",
                "Plex",
                "PPTV",
                "Pyro Music",
                "Qianqian Music",
                "Qobuz",
                "QQ Music",
                "Raku",
                "Rakuten",
                "Resso",
                "Rhapsody",
                "ROXI",
                "Samsung Milk Music (China only)",
                "SAMSUNG MUSIC",
                "Santa Toeic",
                "SECURUS",
                "Shazam",
                "Simfy Africa/MTN",
                "Sina Video",
                "Singtel",
                "Sinhan Card",
                "SiriusXM",
                "SKT",
                "Slacker",
                "Sohu",
                "SoundCloud",
                "SoundMachine",
                "Soundtrack Your Brand",
                "Sprint US",
                "Stellar Entertainment",
                "T Star",
                "T STORE",
                "TaiHua",
                "Taiwan Mobile",
                "TDC Play Musik",
                "Telefonica Brazil",
                "Telenor DK",
                "Telmore Affiliate",
                "Tencent Video",
                "TIDAL",
                "TikTok",
                "TIM Italia",
                "TingTing FM",
                "TJ Media",
                "TouchTunes",
                "Toutiao",
                "TrackDrip",
                "Traxsource",
                "Tudou",
                "Tuzhan",
                "U-Mobile",
                "Ultimate Music China Limited",
                "UMA",
                "UTAPASS",
                "Virgin Mobile Colombia",
                "Viva Musica",
                "VIVO hires",
                "VKontakte",
                "Vodafone",
                "Vodafone Zambia",
                "VUE",
                "Walkgame",
                "Wasu TV",
                "WAVVE",
                "We Sing",
                "Weibo Music",
                "Weltbilt",
                "WOM Chile",
                "Wotu",
                "Wynk",
                "Xiami",
                "Xiaomi Music",
                "Xiaoying",
                "Xite",
                "Xiumi",
                "Xiutang",
                "Yandex Music",
                "Yinyue Tai",
                "Yiqixiu",
                "Youku",
                "YouSee (TDC)",
                "Youting",
                "Yunjian",
                "Yunting",
                "Zhuote",
            ],
            sns: {
                instagram: "Instagram",
                facebook: "Facebook",
                youtube: "YouTube",
                tiktok: "TikTok",
                weibo: "Weibo",
                website: "Website",
                blog:"Blog"
            }
        },
        pageNavi: {
            first: "처음",
            prev: "이전",
            last: "마지막",
            next: "다음"
        },
        error: {
            code404: {
                title: "404",
                msg: "The page you requested could not be found.",
                button: "Go to previous page"
            }
        },
        no_result : 'No Result'
    },
    mainView: {
        description: "Home 페이지",
        label: {
            footer: {
                introduce: "Sound Republica",
                albumRegister: "Music Distribution",
                cost: "Pricing",
                albumArtist: "My Music",
                calcAndWithdraw: "Royalties",
                statistics: "Statistics",
                my: "My Info",
                serviceCenter: "Support"
            },
            main_content: {
                block1: {
                }
            }

        },
        msg: {
            footer: {
                follow: "Follow Us!",
                address: "",
                privacy: "Privacy Policy",
                terms: "Terms of Service",
                copyright: "Copyright \u00A9 2020 Sound Republica All Rights Reserved."
            },
            main_content: {
                block_1: {
                    position_1: "Distribute Your Music to",
                    position_2: "Over 200 Global Music Stores",
                    position_3: '',
                    position_4: 'With Just One Click!',
                    position_5: '',
                    position_6: [
                        {content1: "GLOBAL", content2: "Global Music Distribution"},
                        {content1: "200+", content2: "Select Music Stores"},
                        {content1: "FREE", content2: "Free Registration"},
                        {content1: "15%", content2: "Flat Distribution Fee"},
                        {content1: "EASY", content2: "User-friendly Sales Report"},

                    ],
                    position_7: 'Music Distribution',
                },
                block_2: {
                    position_1: "Want to distribute your music all over the world?",
                    position_2: "To Over 200 Music Stores Worldwide",
                    position_3: 'Register Your Music! We distribute your music to more than 200 music stores',
                    position_4: 'around the world, including Spotify, Apple Music, Youtube Music, and Melon.',
                    position_5: 'prev',
                    position_6: 'next',
                },
                block_3: {
                    position_1: "Looking For a Global Distribution Partner?",
                    position_2: "Register For Free",
                    position_3: 'And Get Insights Into Your Sales.',
                    position_4: 'Register For Free',
                    position_5: 'No hidden registration costs',
                    position_6: 'Keep 100% rights and ownership',
                    position_7: "User-friendly Report",
                    position_8: "We give you the best insights through our",
                    position_9: 'transparent and accurate royalty reports',
                    position_10: 'Marketing Support',
                    position_11: "We offer customized marketing plans",
                    position_12: "created by our marketing expert",
                },
                block_4: {
                    position_1: "More and more artists are choosing Sound Republica!",
                    position_2: "Some of our latest releases",
                    position_3: 'Currently distributing over 1,000,000 tracks!',
                    position_4: '',
                    position_5: "prev",

                    position_6: 'J-BLACK',
                    position_7: '넘사벽 인간',
                    position_8: "MoMo",
                    position_9: "안녕, 모모 OST Part. 7",
                    position_10: "Raphan",

                    position_11: "Me&amp;U",
                    position_12: "MoMo",
                    position_13: "안녕, 모모 OST Part. 5",
                    position_14: "YUZION",
                    position_15: "Look At Me!! Remix",
                    position_16: "미도",
                    position_17: "Sink",
                    position_18: "MoMo",
                    position_19: "안녕, 모모 OST Part. 4",
                    position_20: "Scarlet",
                    position_21: "Fishes",
                    position_22: "This site is best viewed using Google Chrome (https://www.google.com/chrome)",
                },
                block_5: {
                    position_1: "Rare Americans",
                    position_2: "Rare Americans",
                    position_3: 'MoMo',
                    position_4: '안녕, 모모 OST Part. 3',
                    position_5: 'ba.',
                    position_6: 'H8',
                    position_7: "MoMo",
                    position_8: "안녕, 모모 OST Part. 2",
                    position_9: "러브엑스테레오",
                    position_10: "37X",
                    position_11: "Rachael Sage",
                    position_12: "Bravery's On Fire",
                    position_13: "Jim Wolf",
                    position_14: "First Kiss",
                    position_15: "Gabe Klavun",
                    position_16: "La Mejor Bessador",
                },
                block_6: {
                    position_1: "LUST",
                    position_2: "Naga-naga",
                    position_3: 'The Majestic G',
                    position_4: 'Wild Runner',
                    position_5: 'Gabe Klavun',
                    position_6: "Don't Ever Call Me",
                    position_7: "Wolfman",
                    position_8: "Mad Woman",
                    position_9: "illvibe",
                    position_10: "Moneytree",
                    position_11: "ISSORED",
                    position_12: "Broke",
                    position_13: "BULGOGIDISCO",
                    position_14: "가을이 왔어",
                    position_15: "Gabe Klavun",
                    position_16: "About To Kiss Another Girl",
                },
                block_7: {
                    position_1: "illvibe",
                    position_2: "Can You Hear Me",
                    position_3: 'MUSHVENOM',
                    position_4: '왜 이리 시끄러운 것이냐',
                    position_5: 'Raphan',
                    position_6: "Season of",
                    position_7: "윤토벤",
                    position_8: "서툰 이별을 하려해",
                    position_9: "Legacy Keys",
                    position_10: "Bass Drop",
                    position_11: "Aris",
                    position_12: "Streets of Paris",
                    position_13: "JAHR",
                    position_14: "Similar",
                    position_15: "Semi Nocturnal",
                    position_16: "Can't get you back",
                },
                block_8: {
                    position_1: "LUKA 120",
                    position_2: "Bayethe",
                    position_3: 'ROYAL',
                    position_4: '가능태공간2',
                    position_5: 'MOONKOCK',
                    position_6: "Bloom",
                    position_7: "H a lot",
                    position_8: "H a coustic",
                    position_9: "Kacey Musgraves",
                    position_10: "Acoustic Remixed",
                    position_11: "ENOi",
                    position_12: "Bloom",
                    position_13: "KELLA",
                    position_14: "Trainwreck",
                    position_15: "좋아서하는 밴드",
                    position_16: "들숲날숨 vol.9 도도",
                },
                block_9: {
                    position_1: "다방",
                    position_2: "복학생",
                    position_3: '만쥬한봉지',
                    position_4: '들숲날숨 vol.7 stop',
                    position_5: 'Onyxstar',
                    position_6: "I Hate This Job",
                    position_7: "러브엑스테레오",
                    position_8: "Zero One",
                    position_9: "Core.Low",
                    position_10: "Netflix&amp;Chillin'",
                    position_11: "The Barberetttes",
                    position_12: "Santa is busy",
                    position_13: "Wildberry",
                    position_14: "Youniverse",
                    position_15: "Manda",
                    position_16: "Keep Dancing",
                },
                block_10: {
                    position_1: "JAHR",
                    position_2: "Magic",
                    position_3: 'Jim Wolf',
                    position_4: 'Strange, Weird, And Romantic (Part 1)',
                    position_5: 'Buzzard Buzzard Buzzard',
                    position_6: "Love Forever",
                    position_7: "Top Sigrid",
                    position_8: "Don`t lie to me",
                    position_9: "Remy",
                    position_10: "Water",
                    position_11: "CLAZZIC",
                    position_12: "Debbusy &amp; Christmas",
                    position_13: "ENOi",
                    position_14: "For RAYS, Realize All Your Star",
                    position_15: "LEO",
                    position_16: "Manhattan",
                    position_17: "다음"
                }
            }
        },
        props: {
        
        },
        api:{

        }
    },
    aboutUs: {
        description: '회사소개 페이지',
        label: {

        },
        msg:{
            content: '회사소개 페이지 입니다'
        },
        props: {
            
        },
        api:{

        }
    },
    cost: {
        description: "비용 페이지",
        label: {
        },
        msg: {
            block_1: {
                position_1: "You Get Your Fair Share",
                position_2: "",
                position_3: 'Registration Fee',
                position_4: '&#36;0',
                position_5: 'No upfront costs and no hidden fees.',
                position_6: "Learn More",
                position_7: 'Royalty Share',
                position_8: '85%',
                position_9: 'You get a 85% share of the total revenue.',
                position_10: 'Learn More',
                position_11: 'Ownership',
                position_12: '100%',
                position_13: 'Keep 100% rights and ownership of your music.',
                position_14: 'Learn More'
            },
            block_2: {
                position_1: "You can distribute your music with no upfront fees.",
                position_2: "Over 200 Global Music Stores",
                position_3: 'Registration Fees $0!',
                position_4: 'Did you pay additional fees',
                position_5: 'to your previous distributors?',
                position_6: '',
                position_7: '',
                position_8: '',
                position_9: "With Sound Republica, there are",
                position_10: 'no upfront costs and hidden fees!',
                position_11: '',
                position_12: '',
                position_13: '',
                position_14: 'Registration Fee Comparison',
                position_15: 'Company A',
                position_16: 'Company B',
                position_17: 'Company C',
                position_18: '$9.99 per year',
                position_19: '$19.99 per year',
                position_20: '$19.99 per year',
                position_21: 'FREE',
                position_22: 'Last update : 2021.11.25',
                position_23: 'Price based on single album registration with a 0% distribution fee of some global distribution company'
            },
            block_3: {
                position_1: '',
                position_2: "We are doing our best to provide",
                position_3: 'YOU with a fair share of your revenue!',
                position_4: 'As your music sales grow',
                position_5: 'you get to keep more.',
                position_6: 'Keep 85% of your sales.',
                position_7: "We only succeed when you do.",
                position_8: "Win-Win!",
                position_9: 'The more your music grows, the more we get to grow as well.'
            },
            block_4: {
                position_1: "Distibute you music with Sound Republica!",
                position_2: "Focus on making good music",
                position_3: 'without worrying about distribution!',
                position_4: 'Save time on music distribution.',
                position_5: "Focus on Producing and Promoting your music.",
                position_6: "It's a challenge for artists to register their music on more than 100 music stores around the world. But don't worry! With Sound Republica, it's just a click away.",
                position_7: 'Make use of our detailed',
                position_8: "and user-friendly sales reports.",
                position_9: "It's difficult for artists to understand and analyze music sales data. That's why we provide a transparent sales report and give you detailed insights into your sales."
            },
            block_5: {
                position_1: "Distribute your music",
                position_2: "with Sound Republica!",
                position_3: 'Start Music Distribution'
            }
        },
        props: {
        
        },
        api:{

        }
    },
    customerService: {
        title: "Support",
        description: '고객센터 페이지',
        label: {
            tab_menu: [
                "Notice",
                "Contact us",
                "FAQ"
            ],
            notice: {
                title: "Notice"
            },
            qna: {
                title: "Contact us"
            },
            faq: {
                title: "FAQ",
                sub_menu: {
                    faq : "FAQ",
                    distribution: "Music Distribution",
                    accounting: "Royalties",
                    statistics: "Statistics",
                    other: "Other"
                }
            }
        },
        msg:{
            title: "Follow Us!",
            qna: {
                message: [
                    "How can we help you?",
                    "If you have any questions about distribution, please contact us by e-mail.",
                    "music@soundrepublica.com"
                ]
            }
        },
        props: {
            
        },
        api:{
            
        }
    },
    selling: {
        description: '음악판매하기 페이지',
        label: {

        },
        msg:{
            content: '음악판매하기 페이지 입니다'
        },
        props: {
            
        },
        api:{
            
        }
    },
    accounting: {
        title:"Royalties",
        description: '정산/출금 페이지입니다.',
        label: {
            sales: {
                title: "Sales",
                tab_title: "Sales",
                search: {
                    term: "Period",
                    musicStore: "Music Store",
                    month: "This Month",
                    months_3: "3 Months",
                    months_6: "6 Months",
                    territory: "Country",
                    salesType: "Type Of Sales",
                    searchType: "Search Type",
                    searchBtn: "Search",
                },
                streaming: "Streaming",
                download: "Download",
                other: "Other",
                owner_settlement_amount: "Your Share",
                case: "Counts",
                sales_segment: "Your share by sales type",
                sales_history: "Report Details",
                excel: "Excel Download"

            },
            payment: {
                title: "Payment",
                tab_title: "Payment",
                cumulative_earnings: "Total Earnings",
                cumulative_payment: "Total Withdrawings",
                balance: "Current Balance",
                apply_withdrawal: "Request Withdrawal",
                recent_application_history: "Requested Withdrawings",
                withdrawal: "Request",
                withdrawal_complete: "Completion",
                rejected: "Rejected",
                excel: "Excel Download",
                payment_history: "Payment History",
                sort: "Sort",
                view: "Type"
            },
            moveBtn: "Move!"
        },
        msg: {
            title: "Follow up!",
            sales: {
                distribution_fee_exclusion: "Excluding distribution fees",
                search: {
                    keyword: "Please enter a Keyword"
                }
            },
            payment: {
                payment_history: "If you have any questions about taxes, please contact support",
                alert_insufficient_1: "A minimum balance of ",
                alert_insufficient_2: " is required in order to apply for a withdrawal.<br/>Please check your balance.",
                alert_pending: "There is an ongoing withdrawal request. You can apply again after the ongoing request is completed." 
            }
        },
        props: {
            reference_amount: {
                krw: "&#8361;50,000",
                usd: "$100",
                eur: "€100"
            },
            sales_history_column: [
                "Report Date",
                "Service <br>Start Date",
                "Track/Artist",
                // "Track",
                // "Album",
                // "ISRC",
                // "UPC/EAN",
                "Store",
                "Country",
                "Type",
                "Count",
                // "Currency",
                'Settlement Amount',
                "Distribution<br>Share",
                "Your<br>Share"
            ],
            recent_application_history: [
                "Request Date",
                "W-No",
                "Amount",
                "Status",
            ],
            payment_history: [
                "Processing Date",
                "T-No",
                "Type",
                "Amount",
                "Balance"
            ],
            each: {
                30:  "Show 30 entries",
                50:  "Show 50 entries",
                100:  "Show 100 entries",
            },
            sortorder: {
                desc: "Date (newest)",
                asc: "Date (oldest)"
            },
            filterType: {
                all:"All",
                advance_payment: "Prepayment",
                withdrawal: "Withdrawal",
                settlement: "Royalties",
                admin_payment: "Payment Adjustment (+)",
                admin_deduction: "Payment Adjustment (-)"
            },
            sales_filter: {
                term: {
                    settlement_month:"Report Date",
                    service_month: "Service Start Date"
                },
                sales_type: {
                    all: "All",
                    streaming: "Streaming",
                    download: "Download",
                    other: "Other"
                },
                searchType: {
                    artist: "Artist",
                    album: "Album",
                    track: "Tracks"
                }
            }
        },
        api:{
            
        }
    },
    statistics: {
        title:"Statistics",
        description: '통계페이지입니다.',
        label: {
            summary: {
                title: "Summary",
                monthly_sales_details: {
                    title: "Sales Breakdown",
                    sales_history_column: [
                        "Report Month",
                        "Service Month",
                        "Streaming",
                        "Download",
                        "Other",
                        "Total Amount"
                    ],
                    sales_history_sub_column: [
                        "Count",
                        "Amount",
                        "Count",
                        "Amount",
                        "Count",
                        "Amount"
                    ]
                },      
                sales_trend: {
                    title: "Sales Trend",
                    graph_item: {
                        streaming: "Streaming",
                        download: "Download",
                        other: "Other",
                        all: "All"
                    }
                },
                musicStoreTop5: {
                    title: "Music Store TOP5"
                },
                more: "See details",
                sales_territory_Top5: {
                    title: "Country TOP5"
                },
                sales_Top10: {
                    title: "Sales TOP10",
                    filter: {
                        track: "Track",
                        album: "Album",
                        artist: "Artist"
                    },
                    list_column: {
                        no: "No.",
                        title:{
                            track: {
                                main: "Track name",
                                sub: "Artist name"
                            },
                            album: {
                                main: "Album name",
                                sub: "Artist name"
                            },
                            artist: {
                                main: "Artist name",
                                sub: "Popular track"
                            }
                        },
                        streaming: "Streaming",
                        download: "Download",
                        other: "Other",
                        total: "Total"
                    }
                },
            },
            album_track: {
                title: "Tracks",
                sell_album: "Sales by Album",
                amounts: "Amounts",
                sales_top5: "TOP5",
                sales_track: "Sales by Track",
                album_track_sales_detail: {
                    title: "Sales Breakdown",
                    all: "ALL",
                    sales_history_column: [
                        "Title",
                        "Tracks",
                        "Streaming",
                        "Download",
                        "Other",
                        "Total Amount"
                    ],
                    sales_history_sub_column: [
                        "Count",
                        "Amount",
                        "Count",
                        "Amount",
                        "Count",
                        "Amount"
                    ]
                }
            },
            artist: {
                title: "Artist",
                revenue_artist: "Sales by Artist",
                sales_top5: "TOP5",
                artist_detail: {
                    title: "Sales Breakdown",
                    sales_history_column: [
                        "Artist",
                        "Streaming",
                        "Download",
                        "Other",
                        "Total Amount"
                    ],
                    sales_history_sub_column: [
                        "Count",
                        "Amount",
                        "Count",
                        "Amount",
                        "Count",
                        "Amount"
                    ]
                }
            },
            musicStore: {
                title: "Music Store",
                revenue_musicStore: "Sales by Music Store",
                sales_top5: "TOP5",
                musicStore_sales_detail: {
                    title: "Sales Breakdown",
                    sales_history_column: [
                        "Music Store",
                        "Streaming",
                        "Download",
                        "Other",
                        "Total Amount"
                    ],
                    sales_history_sub_column: [
                        "Count",
                        "Amount",
                        "Count",
                        "Amount",
                        "Count",
                        "Amount"
                    ]
                }
            },
            territory: {
                title: "Country",
                revenue_territory: "Sales by Country",
                sales_top5: "TOP5",
                territory_sales_detail: {
                    title: "Sales Breakdown",
                    sales_history_column: [
                        "Country",
                        "Streaming",
                        "Download",
                        "Other",
                        "Total Amount"
                    ],
                    sales_history_sub_column: [
                        "Count",
                        "Amount",
                        "Count",
                        "Amount",
                        "Count",
                        "Amount"
                    ]
                }
            },
            excel: "Excel Download",
            search: {
                term: "Period",
                musicStore: "Music Store",
                quick_date: {
                    month: "This month",
                    month_3: "3 months",
                    month_6: "6 months",
                    year_1: "1 year"
                },
                territory: "Sales Country",
                salesType: "TypeOfSales",
                searchType: "Search Type",
                searchBtn: "Search",
            }
        },
        msg: {
            title: "Follow up!",
            summary: {
                search: {
                    keyword: "Please enter a keyword"
                }
            },
            max_month: "The maximum search duration is 12 months. Please change the period."
        },
        props: {
            sales_trend_filter: 
                {
                    // amounts:"정산금액",
                    // ST_Counts: "스트리밍건 수",
                    // DL_Counts: "다운로드건 수"
                    amounts:"Amount",
                    counts: "Count",
                },
            default_filter: 
                {
                    amounts:"Amounts"
                }
            ,
            each: {
                30: "Show 30 entries",
                50: "Show 50 entries",
                100: "Show 100 entries",
            },
            search_filter: {
                term: {
                    settle:"Report Month",
                    service: "Service Month"
                },
                salesType: {
                    all: "All",
                    streaming: "Streaming",
                    download: "Download",
                    other: "Other"
                },
                searchType: {
                    artist: "Artist",
                    album: "Album",
                    track: "Track"
                }
            }
        },
        api:{
            
        }
    },
    login: {
        title:"Login",
        description: '로그인 페이지 입니다.',
        label: {
            email: "Email ID",
            password: "Password",
            pwdSave: "Remember me",
            confirmBtn: "Login",
            signup: "Sign Up"
        },
        msg: {
            pwdMemory: "Forgot your password?",
            account: "Don't have an account yet?",
            email: "Please specify a valid email address",
            password: "Please specify a valid password"
        },
        props: {
            
        },
        api:{
            
        },
        fail: {
            email: "Please specify a valid email address",
            password: "Please specify a valid password",
            login: "Login failed. If this error repeats, please contact us.",
            auth_mail: "Please check your inbox for out Authentication Email."
        }
    },
    logout: {
        description: '로그아웃 페이지 입니다.',
        label: {

        },
        msg: {
            content: '로그아웃 페이지 입니다.'
        },
        props: {
            
        },
        api:{
            
        }
    },
    forgetPassword: {
        title:"Password Reset",
        description: '비밀번호찾기 입니다.',
        label: {
            email: "E-mail ID",
            confirmBtn: "Send",
            resendBtn: 'Resend',
            login: "Back to Login",
            password: "Forgot your password?"
        },
        msg: {
            password: "Please provide the email address that you used when you signed up for your Sound Republica Distribution account."+
            "We will send you an email that will allow you to reset your password.",
            emptyEmail: "Please enter your email address correctly",
            noAccount: "This email address is not registered.",
            success: "We have sent you reset password e-mail.<br>Check your inbox for more information on how to reset your password.",
            sendEmail : "A new reset link mail has been sent.<br>Only links in the most recent mail are valid."
        },
        props: {
            
        },
        api:{
            
        },
        fail: {
            email: "Please enter your email address correctly",
            not_joined: "This email address is not registered"
        }
    },
    resetPassword: {
        title:"Password Reset",
        description: '비밀번호 재설정',
        label: {
            password: "Create New Password",
            resetBtn: "Reset",
            confirmBtn: "Confirm",
            success: "Password Reset Complete"
        },
        msg: {
            password: "Password must consist of at least 8 alphabetic characters, including one Number and Symbol.",
            failPassword: {
                password: "Password Requirements",
                length: "Minimum length of 8 characters",
                characters: "Must include one or more alphabetic characters, numbers, and symbols"
            },
            success: "Your new password has been set. You can now Log In with your new password."
            
        },
        props: {
            
        },
        api:{
            
        },
        fail: {
            password: {
                password: "Password Requirements",
                length: "Minimum character length of 8 characters",
                characters: "Include one or more alphabetic characters, numbers, and symbols"
            }
        }
    },
    signup: {
        title: "Sign Up",
        description: '회원가입 페이지입니다.',
        label: {
            email: "Email ID",
            password: "Password",
            reference: "How did you find us?",
            signUpBtn: "Join",
            Terms: "Terms of Service",
            privacy: "Privacy Policy.",
            confirm: "Confirm"
        },
        msg: {
            applyPrivacyPart1: "I am agreeing to,",
            applyPrivacyPart2: "and I am older than 14 years old.",
            failPrivacy: "Please agree to Terms of Service and Privacy Policy.",
            applyMarketing: "I would like to receive communications, including latest news about products, services, events and promotions. (optional) ",
            account : "Already have an account?",
            login: "Login",
            email_exists: "This email has already been registered.",
            successfully: "Your authentication email has been sent."
        },
        props: {
            reference: {
                search: "Search",
                referral: "Referrral",
                advertisement: "Ads",
                sns: "Social Media",
                other: "Others"
            }
        },
        api:{
            
        },
        fail: {
            email: "Please specify a valid email address",
            password: {
                password: "Password Requirements",
                length: "Minimum character length of 8 characters",
                characters: "Password must consist of at least 8 alphabetic characters, including one Number and Symbol."
            },
            privacy_Policy: "Please agree to Terms of Service and Privacy Policy."

        }
    },
    emailAuth: {
        title: {
            title: "Sign up - Complete",
            success : "Your email has been verified",
            fail: "이메일 인증 실패"
        },
        description: '이메일 인증 여부',
        label: {
            loginBtn: "Login",
            resendEmailBtn: "Resend"
        },
        msg: {
            success : "You can now log in with your new account",
            fail: "Email confirmation failed.<br>Please resend the confirm mail or contact support."
        },
        props: {
        },
        api:{
            
        }
    },
    uncertified: {
        title: "Confirm your email address",
        description: '미인증 이메일 페이지',
        label: {
            email: "E-mail ID",
            resendEmailBtn: "Resend",
            goLogin: "Back to Login"
        },
        msg: {
            guide: "Please verify your email address. Verification email has been sent.",
            guideDescription:"Check your inbox for the confirmation email we sent to you. ",
            resendSuccess: "Confirmation mail has been re-sent to you.<br>Make sure you click the link in the mail to confirm your account",
            vertifyFail: "Please enter your email address correctly",
            sendEmail: "Your email has been sent."
        },
        props: {
        },
        api:{
            
        },
        fail: {
            vertifyFail: "Please enter your email address correctly",
            sendEmail: "Please contact the administrator."
        }
    },
    my : {
        title: "My Info",
        description: '마이페이지',
        label: {
            basicInfo: "Basic Information",
            id: "ID",
            password: "PW",
            siteLanguage: "Language",
            marketing: "Marketing",
            distributionInfo: "Personal Information",
            registrationBtn: "Register",
            saveBtn: "Save",
            payment: "Tax and Payment Info.",
            payment_popup_title: "Tax and Payment Info.",
            withdrawBtn: "Delete Account",
            passwordBtn: "Reset Password",
            birthday: "Date of Birth or Establishment",
        },
        msg: {
            title: "View your personal information for distribution, tax and payment.",
            marketing: "I would like to receive communications, including latest news about products, services, events and promotions. (optional)",
            distribution: "In order to distribute your music, we need the following information.",
            distributionInfo: "In order to change your information after registering, please contact us.",
            notice: "In order to change your information after registering, please contact us.",
            payment: "In order to withdraw royalties, we require the following information",
            siteLanguage: {
                ko: "한국어",
                en: "English"
            },
            activation_guide: "In order to use our service, please fill in all information below."
        },
        props: {
        },
        api:{
            
        }
    },
    distribution: {
        title: "Personal Information",
        description: "유통정보",
        label: {
            nationality: "Nationality",
            mims: "MIMS Name (Korean National Only)",
            mimsBtn: "Join MIMS",
            division: "Individual/ Corporation",
            divisionSelect: {
                individual: "Individual",
                corporation: "Corporation", 
            },
            taxFree:'Tax-free Business',
            name: "Name",
            given_name: "First Name",
            family_name: "Family Name",
            companyName: "Company Name",
            phone: "Phone Number",
            countryCode: "Country Code +",
            currency: {
                title: "Currency",
                money: {
                    usd: "USD",
                    eur: "EUR",
                    krw: "KRW"
                }  
            },
            payment: {
                title: "Payment Method",
                means: {
                    payoneer : "Payoneer",
                    paypal: "PayPal",
                    intl_banktransfer: "Wire Transfer",
                    kr_banktransfer: "Account transfer (Korean Only)"
                }
            },
            payoneer: "payoneer?",
            paypal: "PayPal?",
            country: {
                title: "Country of Residence"
            },
            confirmBtn: "Register",
            cancelBtn: "Cancel"
        },
        msg: {
            nationality: "Select your nationality",
            mims: "Please enter your MIMS Name (Korean National Only)",
            name: "English or Korean only.",
            companyName: "Please enter your company name",
            phone: "Please enter phone number. Please enter without - (hyphen)",
            country: "Please select a country of residence",
            countryTax:"The payment method varies based on your country of residence.",
            distribution: "This information is required for music distribution contract.",
            barcode: "If you have a code, please enter it.",
            distributionInfo: "If you need to change after registering, please contact support.",
            paypal_email: "Please enter your PayPal ID (email)."
        },
        fail: {
            nationality: "Please select your nationality",
            mims: "Please enter your MIMS Name (Korean National Only)",
            division: "please select your type",
            family_name: "Please enter your first name",
            given_name: "Please enter your last name",
            companyName: "Please enter your company name",
            countryPhone: "Please select country number",
            phone: "Please enter phone number. Please enter without - (hyphen)",
            currency: "Please select a currency",
            payment: "Please select a payment method",
            countryCode: "Please select a country of residence",
            paypal_email: "Please enter your PayPal ID(email)."
        },
        props: {
            terms: "약관"
        },
        api:{
            
        },
        tooltip: {
            mims: "Members of the Republic of Korea Nationality must sign up as a producer in MIMS and give the name (company name) of the person who joined the MIMS to issue the code.",
            currency: "For your convenient, all amounts are unified denominated in a currency that you chose at user information.<br>"+
            "Once selected currency cannot change.",
            payment: "The payment method is limited by the amount of money you select.<br>"+
            "[Methods of payment according to optional currency]<br>"+
            "KRW - Account transfer<br>"+
            "USD - PayPal"
        }
    },
    taxInfo: {
        title: "Tax Info.",
        description: "세금정보",
        label: {
            taxpayerCode: "Tax ID No.",
            kr_resident: "Resident Registration Number",
            kr_business: "Business Registration number",
            foreign_tax: "Tax ID No.",
            birthday: "Date of Birth or Establishment",
            month: "Month",
            day: "Day",
            year: "Year",
            address: "Address",
            search: "Search",
            detail: "Details",
            address1: "Address1",
            address2: "Address2",
            district: "District",
            city: "City",
            postalCode: "Postal Code"
        },
        msg: {
            tax: "Please contact support to update information",
            idCard: "Please enter without - (hyphen)",
        },
        fail: {
            idCard: "Please enter code number.",
            birthday: "Please select a date",
            address: "Please enter your residence address",
        },
        props: {
        },
        api:{
            
        }
    },
    paymentInfo: {
        title: "Payment Info.",
        description: "지급정보",
        label: {
            accountHolder:"Account Holder",
            accountNumber: "Account No. ",
            bank:"Bank Name",
            bankCode: "Bank Code",
            countryCode:"Country Code",
            bankAndBranchName: "Bank and Branch Name",
            branchAddress: "Branch Address",
            address1: "Address1",
            address2: "Address2",
            district: "District",
            city: "City",
            postalCode: "Postal Code",
            agree: "I do my duty to pay taxes to the taxing country <br>and agree to delegate tax agency payments to Sound Republica.",
            confirmBtn: "Register",
            cancelBtn: "Cancel"
        },
        msg: {
            payment: "If you want to change your information, please contact support.",
            accountHolder: "Please enter account holder name",
            accountHolderGuide: "The name of the account holder and the rights holder must be the same.",
            accountNumber: "Please enter Account No.",
            bank: "Please select your bank",
            bankCode: "Bank Code, SWIFT, BIC",
            countryCode: "Please select the country of the bank",
            bankAndBranchName: "Bank and Branch Name",
            notice: "I shall be responsible for any and all applicable value-added tax or withholding tax, in the event that any such taxes are applicable."
        },
        fail: {
            accountHolder: "Please enter account holder name",
            accountNumber: "Please enter Account No.",
            bank: "Please select your bank",
            bankCode: "Please enter Bank Code, SWIFT, BIC",
            bankCountryCode: "Please select the country of the bank",
            bankAndBranchName: "Bank Name and Branch Name are missing",
            branchAddress: "Branch Address is missing.",
            agree: "Please agree with the tax statement above."
        },
        props: {
        },
        api:{
            
        }
    },
    management: {
        title: "My Music",
        description: "앨범/아티스트 정보",
        label: {
            album: {
                title: "Single/EP/Album",
                sort: "Sort",
                state: "Status",
                artistList: "Artist",
                addAlbum: "Add",
                explicit: "EXPLICIT",
                temporaryStorage: "Draft",
                registrationDate: "Submit",
                releaseDate: "Release",
                albumCount: "Result",
                delete: "Delete"
            },
            artist: {
                title: "Artist",
                addArtist: "New Artist",
                artistCount: "Result"
            },
            search: "Search",
            moveBtn: "Move"
        },
        msg: {
            payment: "If you need to change after registering, please contact support.",
            accountHolder: "Please enter account holder name",
            accountHolderGuide: "The account holder name of the deposit must be the same as the person of the right or The business owner is the CEO or company name.",
            accountNumber: "Please enter Account No",
            bank: "Please select Bank name",
            bankCode: "Bank Code, SWIFT, BIC",
            countryCode: "Please select the country of the bank.",
            bankAndBranchName: "Bank and Branch Name",
            notice: "I do my duty to pay taxes to the taxing country and agree to delegate tax agency payments to Sound Republica.",
            title: 'Your registered albums and artists.',
            none_artistList: "Please add artist",
            none_albumList: "Nothing registered. Please add an album!",
            none_licensor: "There is currently no distribution information, tax information or payment information.<br>Please use after registering on my page."
            
        },
        fail: {
            accountHolder: "Please enter account holder name",
            accountNumber: "Please enter Account No",
            bank: "Please select Bank name",
            bankCode: "Please enter Bank Code",
            countryCode: "Please select Country Code",
            bankAndBranchName: "Please enter Bank And Branch Name",
            branchAddress: "Please enter Branch Address",
            agree: "I do my duty to pay taxes to the taxing country and agree to delegate tax agency payments to Sound Republica."
        },
        props: {
            sort: {
                album: {
                    created_at: "New Registered",
                    release_date: "Last Released",
                    name: "Alphabetical"
                },
                artist: {
                    display_name: "Alphabetical",
                    created_at: "New Registered"
                }
            },
            search: {
                all: "All"
            }
        },
        api:{
            
        }
    },
    distributionMusic: {
        title: "Music Distribution",
        description: "앨범등록, 음악유통하기",
        label: {
            defaultInfo: "Single/EP/Album - Basic Info.",
            coverImg: "Cover Art",
            albumName: "Title",
            albumName_en: "Translation",
            re_artist: "Main Artist",                  // Representative Artist
            re_artist_select: "Select Artist",                // Select Representative Artist
            genre: "Genre",
            genre_select: "Select Genre",
            barcode: "UPC/EAN",
            barcode_generate: "Generate a UPC/EAN code for me.",
            agency: "Label",
            agency_en: "Translation",
            desired_release_date:"Desired release date",
            none_desired_release_date: "No specific dates to be desired",
            addInfo: {
                title: "Single/EP/Album - Extra Info.",
                title_etc: "",
                album_agency_image: "Label Image",
                credit: "Credit",
                original_release_date: "Original Released Date",
                albumIntrouce: "Introduction",
                albumIntrouce_en: "Translation"
            },
            trackInfo: {
                title: "Track Info.",
                list: "Tracklist"

            },
            musicStore: {
                title: "Release Info.",
                musicStoreView: "Music Store List > ",
                country_release: "Music Stores",
                countryExcept: "Exclude these territories"
            },
            contract: {
                title: "Distribution Agreement"
            },
            rejected: {
                title: "Reason for refusal",
                rejected_date: "Refusal Date",
                complaint_apply: "반려 이의신청하기"
            },
            agree: "Agree",
            confirmBtn: "Submit",
            deleteBtn: "Delete",
            temporaryBtn: "Save",
            cancelBtn: "Cancel",
            moveBtn:"Move",
            closeBtn: "Close"
        },
        msg: {
            title: "Register album and track information for release.",
            coverImg: "Please upload the image.",
            albumName: "We will release your the title you have entered above.",
            albumNameGuide: "Please enter the title.",
            albumNameGuide_en: "Please select a language and enter a title.",
            genre: "You can select up to two genres.",
            barcode: "Enter the issued code if you have it, and leave it blank if you don't have it.",
            barcode_generate: "I do not have such a code and I agree to issue a new one.",
            albumName_en_generate: "I agree to release my album in the original language only.",//사용하지않는 항목
            agency: "Please enter label name",
            agency_en: "Please enter english label name", //사용하지않는 항목
            agency_en_generate: "I agree to release the label name in the original language only.", //사용하지않는 항목
            none_desired_release_date: 
                "Please understand that we are not always able to release on your requested date." // 
            ,
            none_desired_release_date2 : "Leave the “Desired release date” BLANK if you have been in contact with us and received CONFIMRATION on your release date.", // 

            addInfo: {
                credit: "Please enter the information of those who participated in producing the album.",
                creditGuide: "Album Credits",
                original_release_date: "If your album has already been released, please select the original release date.",
                introduce: "",
                description: "",
                intruceGuide: "Please enter an album introduction",
                introuce_en: "Please enter an album introduction in English"
            },
            trackInfo: {
                list: "Select a track and enter the details",
                listGuide: "You can select up to two Title Tracks.<br>In some stores these track will be highlighted."
            },
            musicStore: {
                country_release: "It will be released as a music store for the selected country",
                guide: "Depending on the policies of each music store, some may prohibit to release of your album without notice.<br>"+
                "<br>e.g.) Beatport (Dance, EDM genre only), Highresaudio (High-Quality sound sources only, 24+ bits)"
            },
            contract: {
                guide: "약관내용이 노출됩니다."
            },
            agree: "I certify that I do not infringe on the copyright or the intellectual property of any third party, and agree to the terms and conditions as written in the contract.",
            rejected: "Please submit a ticket at support page to submit comments or additional material on the objection",
            none_licensor: "There is currently no distribution information, tax information or payment information.<br>Please use after registering on my page.",
            completed: "Would you like to apply for distribution?",
            completed_guide: "It is difficult to modify after submission and distribution. If you need to modify it, please contact us by email.",
            save: "saved successfully",
            saving: "Saving ......Please wait.",
            registering: "Registering ...... Please wait."
        },
        fail: {
            album_name: "Please enter the title",
            album_name_en: "Please select a language and enter a title",
            agencyName_en: "Please enter the label",
            artist:"Please select an artist",
            genre: "Please select genre",
            barcode:"If you have an issued code, please enter it. Leave blank if you don't have.",
            track: "Please add track",
            desired_release_date: "Please select a Desired release date",
            agree: "Please agree to the terms and conditions"

        },
        props: {
            trackList: {
                no: "No.",
                track: "Track Title",
                type: "Track Version",
                artist: "Artist Name",
                title: "Main Track"
            },
            musicStore: {
                global: "All Stores",
                korea: "South Korean Stores Only"
            },
            date: "YYYY-MM-DD",
            countryExcept: {
                kr: "South Korea"
            }
        },
        tooltip: {
            title: 
   "If you write several languages side by side in the representative title, please make sure to enter separate the title by each language in the translation field.<br>"+ 
"Each store has a different name supply rule, so the representative name is given to stores that allow multiple languages to be written side by side on the album name.<br>"+
"e.g.)<br>"+ 
"Title : 사운드리퍼블리카(Sound Republica)<br>"+
"Translation :<br>"+
"[Korean] 사운드리퍼블리카<br>"+
"[English] Sound Reublica<br><br>"+

"- Content must be accurate and formatted correctly. Check spelling, grammar and punctuation.<br>"+
"- Content should not contain advertising nor use generic titles.",

            genre: "As every store has their own list of genres, we can not guarantee that your album will be released under the genre you have selected.",
            upc: "UPC(Universal Product Code) is a widely used 12digits barcode for tracking items in stores. EAN(European Article Number) is a widely used 13digits barcode for tracking items in stores.",
            agency: 
            "Can also be referred to as ‘Agency’. In case you are not associated with a label, you can either enter a hypothetical label name by yourself or leave this field blank for your artist name to be displayed.",
            credit: "The credit information you have entered will be displayed at the bottom of the store's album introduction.<br>"+
            "Anyone involved in the production and distribution of this album can be listed.<br>"+
            "e.g., Executive Producer, Artist Manager, Visual Production, Fan Marketing, Artist Development, Financial Management,<br>Music Producer, Creative Director, Promotion, Choreography, Stylist, Hair & Makeup, Photographer, etc.",
            trackList: {
                main_track: "You can select up to two of the most important main tracks in this release. It is used to display the song that is being pushed to the store."
            }
        }
    },
    components: {
        navBar: {
            description : '상단 네비게이션 바',
            label: {
                home_mv: 'Home',
                aboutUs_mv: 'Sound Republica',
                management: "My Music",
                royalties: "Royalties",
                statistics: "Statistics",
                my:"My Info",
                cost_mv: 'Pricing',
                customerService_mv: 'Support',
                distribution: 'Music Distribution',
                login: 'Login',
                logout: 'Logout',
                singup: 'Sign Up',
                contact : 'Contact'
            },
            msg: {

            },
            props: {
                language: {
                    ko: "한국어",
                    en: "English"
                }   
            },
            api:{
                
            }
        },
        footer: {
            description: "Footer",
            label: {
                menu: {
                    company: "Sound Republica",
                    album_register: "Music Distribution",
                    cost: "Pricing",
                    management: "My Music",
                    accounting: "Royalties",
                    statistics: "Statistics",
                    my: "My Info",
                    customerService: "Support"
                },
                address: [
                    ""
                ],
                notify: [
                    ""
                ],
                copyright: "Copyright © Sound Republica, Inc., All Rights Reserved.",
                privacy_policy: "Privacy Policy",
                terms_services: "Terms of Service",
                goTop: "Go Top",
                confirmBtn: "Confirm"
            },
            msg: {
                title: [
                    "Follow Us!",
                    ""
                ],
            }
        },
        vueCarousel: {
            description: "회전 목마형 UI",
            label: {

            },
            msg: {

            },
            props: {

            },
            api:{
                
            }
        },
        panel: {
            title: "팝업페이지",
            description: "Panel",
            panel1: {
                title: "Password Reset",
                description: "비밀번호변경",
                label: {
                    old_password: "Current Password",
                    new_password: "New Password",
                    confirmBtn: "Save",
                    cancelBtn: "Cancel"
                },
                msg: {
                    close: "Close",
                    old_password : "Please enter your current password",
                    new_password: "Please enter a new password",
                    success: "Your password has been changed."
                },
                props: {

                },
                api:{
                    
                },
                fail: {
                    old_password: "Please enter your current password",
                    failPassword: {
                        password: "Password Requirements",
                        length: "Minimum character length of 8 characters",
                        characters: "Include one or more alphabetic characters, numbers, and symbols"
                    }
                }
            },
            panel2: {
                title: "Register Artist",
                description: "아티스트 추가 신규등록",
                label: {
                    title: "Artist - Basic Info.",
                    artistImg: "Artist Profile Image",
                    img: [
                        "Profile Image",
                        "Additional Image 1",
                        "Additional Image 2"
                    ],
                    noneImg: "No Artist Image",
                    name: "Artist Name",
                    enName: "Translation",
                    nationality: "Nationality",
                    type: "Type",
                    addInfo: "Artist - Extra info.",
                    addInfo_etc: "",
                    debutyear: "Debut",
                    introduce: "Biography",
                    introduce_en: "Translation",
                    sns:"URL",
                    confirmBtn: "Save",
                    cancelBtn: "Cancel",
                    add: "Add",
                    delete: "Delete"
                },
                msg: {
                    artistImg_guide: "Recommend Size: 1200 x 1200 pixels (600 pixels or more in width or height)",
                    title: "Unable to modify after artist registration.",
                    input_artist: "Please enter an artist name",
                    input_artist_en: "If you write several languages side by side in the representative name, please make sure to enter separate the title by each language in the translation field. ",
                    img: "Click here or Drag files to upload",
                    introduce: "Please enter an artist introduction",
                    introduce_en: "Please enter an artist introduction",
                    sns: "Please enter a URL",
                    none_sns: "Please enter a URL",
                    none_sns_type: "Please select URL type",
                    not_delete_sns: "No more information to delete",
                    name: "",
                    artist_image: "Please upload the image. If you do not want to register an image, please check the No Artist Image box (edited)",
                    display_name_english_generate: "",
                    artist_exists: "There are already registered artist names."
                },
                props: {
                    sns: {
                        youtube: "YouTube",
                        facebook: "Facebook",
                        twitter: "Twitter",
                        website: "Website",
                        instagram: "Instagram"
                    }
                },
                fail: {
                    artistName_en: "Please select a language and enter the artist name.",
                    artistName: "Please enter artist name",
                    nationality: "Please select artist nationality",
                    type: "Please select artist type",
                    fileUpload: "File upload failed. Please register again after a while."
                },
                api:{
                    
                }
            },
            panel3: {
                title: "Artist Information",
                description: "아티스트 정보",
                label: {
                    nationality: "Nationality",
                    type: "Artist type",
                    debutYear: "Debut",
                    introduce: "Biography",
                    enIntroduce: "Biography_english",
                    sns: "URL",
                    recentlyAlbum: "Released",
                    sumAlbum: "총 앨범수",
                    list_column: {
                        albumName: "Name",
                        tracks: "Tracks",
                        date: "Release date",
                    },
                    more: "전체앨범보기",
                    confirmBtn: "Save",
                    cancelBtn: "Cancel"
                },
                msg: {
                    serviceInfo : "Please submit a ticket at support page to modify the artist information"
                },
                props: {
                },
                api:{
                    
                }
            },
            panel4: {
                title: "Detail",
                description: "앨범 정보",
                label: {
                    albumInfo: "Single/EP/Album Info.",
                    returnYn: "반려여부",
                    releaseDate: "Release Date",
                    releaseArea: "발매지역",
                    upcEanCode: "Identifier-UPC/EAN",
                    coverImg: "Cover Art",
                    albumName: "Title",
                    re_artist: "Artist",
                    releaseCountry: "Music Stores and Territories",
                    agency: "Label",
                    labelImg: "Label Image",
                    albumIntrouce: "Introduction",
                    credits: "Credit",
                    releasedDate : "Original Released Date",
                    genre: "Genre",
                    trackInfo: "Track Info.",
                    trackSum: "Tracks",
                    volumeNo: "번호",
                    trackNo: "트랙번호",
                    titleSong: "타이틀곡",
                    trackFile: "오디오파일",
                    isrc: "ISRC코드",
                    uci: "UPC/EAN",
                    trackName: "트랙명",
                    musicStore: "Release Info.",
                    more: "Music Store List >",
                    country: {
                        global: "All",
                        ko: "Korea"
                    },
                    confirmBtn: "Retract Application",
                    cancelBtn: "Close",
                    track_list_column: [
                        "Vol.",
                        "No.",
                        "Track Title",
                        "Artist"
                    ],
                    explicit: "EXPLICIT",
                    various_artist: "Various Artists",
                    excluded: " excluded"
                },
                msg: {
                    guide: "Some music stores may restrict music releases without notice.",
                    musicStore_title: "It will be released as a music store for the selected country"

                },
                props: {
                    introduce_language: {
                        kr: "한국어",
                        en: "English"
                    },
                    country: {
                        global: "Global",
                        korea: "Korea"
                    }
                },
                api:{
                    
                }
            },
            panel5: {
                description: "고객센터",
                label: {
                    prev: "< 이전 글",
                    next: "다음글 >"
                },
                msg: {
                },
                props: {   
                },
                api:{   
                }
            },
            panel6: {
                title: "1 : 1 문의하기",
                description: "1:1 문의하기",
                label: {
                    type: "문의유형",
                    email: "이메일주소",
                    title: "제목",
                    content: "내용",
                    confirmBtn: "보내기",
                    cancelBtn: "닫기"
                },
                msg: {
                    type: "문의유형을 선택해주세요.",
                    contentTitle: "문의제목을 입력해주세요.",
                    content: " 문의 내용을 입력해주세요."
                },
                props: {   
                },
                api:{   
                }
            },
            panel7: {
                title: "FAQ",
                description: "FAQ",
                label: {
                    cancelBtn: "닫기"
                },
                msg: {
                },
                props: {   
                },
                api:{   
                }
            },
            panel9: {
                title: "TRACK",
                description: "Track 정보",
                label: {
                    add: "Add",
                    delete: "Delete",
                    confirmBtn: "Confirm",
                    cancelBtn: "Cancel",
                    track:{
                        title: "Track - Basic Info.",
                        track: "Track Title",
                        track_en: "Translation",
                        artist: "Artist",
                        artist_select: "Select Artist",
                        genre: "Track Genre",
                        genre_select: "Select Genre",
                        composer: "Composer",
                        isrc: "ISRC",
                        isrcCode_apply: "Apply for Code Issue",
                        uciCode_apply: "Apply for Code Issue",
                        uci: "UCI",
                        explicit: "Parental Warning(EXPLICIT)",
                        more_explicit: "선정성 예시보기"
                    },
                    etc: {
                        title: "Track - Extra Info.",
                        title_ex: "",
                        type: "Track Version",
                        featuring: "Featuring Artist",
                        featuring_en: "Translation",
                        lyricist: "Lyricist",
                        arranger: "Arranger",
                        lyrics: "Lyrics"

                    },
                    audio: {
                        title: "Track - Audio File"
                    }
                },
                msg: {
                    track:{
                        track: "We will release your track with the title you have entered above.",
                        trackName: "Please enter the track title",
                        track_en: "Please select a language and enter a track title",
                        genre: "You can select up to two genres",
                        composer: "Please enter composer name",
                        delete_composer: "No more information to delete",
                        management: "Please enter Publishing house",
                        isrc: "Only if there is an issued code",
                        uci: "Only if there is an issued code",
                        explicit: "Explicit Content",
                        isrcCode_apply: "I do not have such a code and I agree to issue a new one",
                        uciCode_apply: "I do not have such a code and I agree to issue a new one"
                    },
                    etc: {
                        type: "Please select a Track Version",
                        featuring: "In case of multiple artists, separate with a comma (,)",
                        featuring_guide: "In case of multiple artists, separate with a comma (,)",
                        featuring_en: "In case of multiple artists, separate with a comma (,)",
                        lyricist: "Please enter the lyricist name",
                        management: "Publishing house",
                        arranger: "Please enter  the arranger name",
                        lyrics: "Please enter the lyrics"
                    },
                    audio: {
                        title: "Please upload audio files (FLAC or WAV file required)",
                        guide: [
                            "Required File format: FLAC or WAV(min. 44.1khz/16bit))",
                            "Option format: MP3(min. 320kbps)",
                            "File size : 200MB max.",
                        ]
                    }
                },
                props: {
                    column: [
                        "Name",
                        "Bit Rate",
                        "Sample Rate",
                        "Type",
                        "Duration"
                    ],
                    titleRanking: {
                        1: "1st",
                        2: "2nd"
                    },
                    type: {
                        original: "Original",
                        live: "Live",
                        instrumental: "Instrumental",
                        acoustic: "Acoustic",
                        acapella: "Acapella",
                        extended_version: "Extended Version",
                        radio_edit: "Radio_Edit",
                        clean: "Clean",
                        cover: "Cover",
                        remastered: "Remastered",
                        re_recorded: "Re-recorded",
                        remix: "Remix",
                        remake: "Remake",
                        soundtrack: "Soundtrack",
                        bonus_track: "Bonus Track",
                        hidden_track: "Hidden Track",
                        etc: "Etc"
                    }
                },
                api:{   
                },
                tooltip: {
                    track:
                    "Each store has a different name supply rule, so the representative name is given to stores that allow multiple languages to be written side by side on the title. If you write several languages side by side in the representative name, please make sure to separate the titles for each language in translation.<br>"+
                    "e.g.) <br>"+
                    "Title : 사운드리퍼블리카(Sound Republica)<br>"+
                    "Translation :<br>"+
                    "[Korean] 사운드리퍼블리카<br>"+
                    "[English] Sound Republica<br><br>"+

                    "- If a one-track product, the product title and track title need to be identical<br>"+
                    "- Content must be accurate and formatted correctly. Check spelling, grammar and punctuation.<br>"+
                    "- Content should not contain advertising nor use generic titles.",
                                        
                    
                    variousArtist: 
                        "Various Artists cannot be displayed on the track unit during global distribution.<br>"+ 
                        "In the case of stores that prohibit the display of various artists per track, the name of the album planner may be marked instead.",
                    genre: "As every store has their own list of genres, we can not guarantee that your album will be released under the genre you have selected.",
                    isrc: "International Standard Recording Code (ISRC) is a international identification system for sound and music video recordings. <br> If you do not know or do not have codes, then leave this field blank.",
                    explicit: "Please select “Yes”, in case the content of a song contains one or more of the criteria which could be considered offensive or unsuitable for children.<br>"+ 
                    "- Reference to violence, physical, or mental abuse.<br>"+
                    "- Reference to sexualised behaviour.<br>"+
                    "- Discriminatory language.<br>"+
                    "- If there are suggestive or inappropriate words, write them down in the lyrics field below as is. (Do not self censor. Self censored example: F\**\‘k)'<br>",
                    type: "If your track is a Remake or a Remix of an existing track, the music store can request additional documents for confirmation.",
                    featuring_artist: "Featuring Artists will appear as (feat. ABC) right of the track title. ",
                    lyrics: "If your lyrics consists of several languages, please select one representative language.<br>"+
                    "Lyrics must me accurate and formatted correctly. Check spelling, grammar and punctuation."+
                    "The lyrics must match the words heard in the audio.<br>"+
                    "All lyrics lines should be single spaced, and a double space should separate each stanza.<br>"+
                    "Easily identifiable spoken non-words should be transcribed.<br>"+
                    "If there are suggestive or inappropriate words, write them down in the lyrics field below as is. (Do not censor yourself. Self censored example: F\**\'k)",
                    contributor : `Please register at least 1 composer.<br>
                        If you don't have a registered publishing company, leave it blank.`
                },
                fail: {
                    track:{
                        trackName: "Please enter the track title",
                        trackName_en: "Please select language and enter the title",
                        artists: "Please select an artist",
                        genre: "Please select genre",
                        composer: "Please register at least 1 composer.",
                        isrc: "If you do not know or do not have codes, then leave this field blank.'",
                        uci: "If you do not know or do not have codes, then leave this field blank.'",
                        
                    }
                }
            },
            panel12: {
                title: "Main Artist",
                description: "대표아티스트",
                label: {
                    variousArtist: "Various Artists (If there are 5 or more primary artists)",
                    searchBtn: "Search",
                    sort: "Sort",
                    artistRegister: "+ Register Artist",
                    confirmBtn: "Save",
                    cancelBtn: "Cancel",
                    track: "TRACK"
                },
                msg: {
                    search: "Please enter artist name",
                    artistCount: "If there are 13 or more primary artists, Please check “Various Artists” checkbox above."
                },
                props: { 
                    sort: {
                        created_at: "Newest Registered",
                        display_name: "Alphabetical"
                    } 
                },
                api:{   
                }
            },
            panel13: {
                title: "Select Genre",
                description: "장르 선택 관리",
                label: {
                    variousArtist: "Various Artists (If there are 5 or more primary artists)",
                    searchBtn: "Search Genre",
                    sort: "Sort",
                    confirmBtn: "Save",
                    cancelBtn: "Cancel",
                    track: "TRACK"
                },
                msg: {
                    serach: "Please enter genre to search for",
                    genreCount: "You can select up to two genres"
                },
                props: { 
                    sort: {
                        date: "Newest Registered",
                        name: "Alphabetical"
                    } 
                },
                api:{   
                }
            },
            panel14: {
                title: "Music Store List",
                description: "뮤직스토어 리스트",
                label: {
                    pre_title: "Album Info.",
                    confirmBtn: "Close"
                },
                msg: {},
                props: {
                },
                api:{}
            },
            panel15: {
                title: "Main Artist",
                description: "대표아티스트",
                label: {
                    variousArtist: "Various Artists (If there are 5 or more primary artists)",
                    searchBtn: "Search",
                    sort: "Sort",
                    artistRegister: "+ Register Artist",
                    confirmBtn: "Save",
                    cancelBtn: "Cancel"
                },
                msg: {
                    search: "Please enter artist name",
                    artistCount: "If there are 5 or more primary artists, Please check “Various Artists” checkbox above."
                },
                props: { 
                    sort: {
                        created_at: "Newest Registered",
                        display_name: "Alphabetical"
                    } 
                },
                api:{   
                }
            },
            panel16: {
                title: "Select Genre",
                    description: "장르 선택 관리",
                    label: {
                        searchBtn: "Search Genre",
                        confirmBtn: "Save",
                        cancelBtn: "Cancel",
                        all: "All",
                        genreRegister: "+ Register Genre",
                    },
                    msg: {
                        serach: "Please enter genre to search for.",
                        genreCount: "You can select up to two genres" 
                    },
                    props: { 
                    },
                    api:{   
                    }
            },
            panel17: {
                title: "트랙정보",
                    description: "트랙 상세정보",
                    label: {
                        title: "Track Info.",
                        navi: {
                            prev: "Album Info."
                        },
                        explicit: "EXPLICIT",
                        volume: "Volume & Track No.",
                        name: "Track Title",
                        artist: "Artist",
                        genre: "Genre",
                        type: "Track Version",
                        featuring_artist: "Featuring Artist",
                        composer: "Composer",
                        lyricist: "Lyricist",
                        arranger: "Arranger",
                        barcode: "Track Code",
                        lyrics: "Lyrics",
                        fileInfo: "Audio File",
                        fileList_column: [
                            "File Name",
                            "Type",
                            "Duration"
                        ],
                        closeBtn: "Close",
                        various_artist: "Various Artist",
                        uci: "UCI",
                        isrc: "ISRC"
                    },
                    msg: {
                    },
                    props: {
                        language: {
                            kr: "한국어",
                            en: "English"
                        },
                        volume: "VOLUME",
                        track: "TRACK"
                    },
                    api:{   
                    }
            },
            panel18: {
                title: "Withdrawal Request",
                    description: "출금신청하기 POPUP",
                    label: {
                        amount: "Amount of withdrawal (application)",
                        withdrawal_notice: "Withdrawal Notice",
                        applyBtn: "Apply",
                        cancelBtn: "Close",
                        payoneer: "Learn more about Payoneer >",
                        payoneer_connect: "Link Payoneer Account",
                        payoneer_faq: "[Payoneer FAQ] Why isn't my account approved yet? >",
                        confirmBtn: "Confirm",
                    },
                    msg: {
                        withdrawal_notice: [
                            "[Attention] Application Deadline : the last business day of the month.",
                            "Please request a withdrawal and issue an invoice before the last business day of the month.",
                            "Applications after the deadline will be processed within 30 days from the date of request.",
                            "The payment will be made after deducting applicable taxes and fees.",
                        ],
                        ready_payoneer: [
                            "Please link your Payoneer Account to continue your request (One-Time Setup)",
                            "It takes time to approve your Payoneer account."
                        ],
                        waiting_payoneer: [
                            "Your Payoneer account is awaiting approval.",
                            "It typically takes up to 3 business days to approve a Payoneer account."
                        ],
                        payoneer_checking: "Checking payoneer account......"
                    },
                    props: {
                    },
                    api:{   
                    },
                    fail: {
                        fail: "Failed to submit withdrawal request. Insufficient balance.",
                        payoneer_fail: "There was an error connecting to Payoneer.<br>Please connect again after a while.",
                        payoneer_id: "No ID has been issued.<br>Please contact the administrator."
                    }
            },
            panel19: {
                title: "Withdrawal Request Submitted",
                    description: "출금신청완료 POPUP",
                    label: {
                        payment_method: {
                            kr_banktransfer: {
                                tax_bill: "[Required] Please issue a tax invoice or bill",
                                tax_bill_more: "How to issue a tax invoice (Korean)",
                                business_registration: "Business License (Korean) PDF",
                                duty_free : "How to issue a cash receipts (Simplified, Duty-free)",
                            },
                            intl_banktransfer: {
                                tax_bill: "[Required] Please issue a tax invoice or bill",
                                business_registration: "Business License (English) PDF",
                            },
                            paypal: {
                                tax_bill: "[Required] Please issue a invoice or bill",
                                paypal_link: "Go to PayPal"
                            }
                        },
                        closeBtn: "Close"
                    },
                    msg: {
                        completed: "Withdrawal Request has been submitted!",
                        guide1: "The payment will be made within 30 days of your request.<br/>It will be paid with the payment information you entered.",
                        guide2: [],
                        payment_method: {
                            kr_banktransfer: {
                                tax_bill: "Email to : tax_kr@soundrepublica.com<br/><br/>"+
                                "Royalties shall be paid within 30 days of your request.<br/><br/>"
                            },
                            intl_banktransfer: {
                                tax_bill: "Please issue a invoice or bill.<br/><br/>"+
                                "Email to : report@soundrepublica.com<br/><br/>"+
                                "Royalties shall be paid within 30 days of your request.<br/><br/>"
                            },
                            paypal: {
                                tax_bill: "Please, log in to PayPal with your PayPal account,"+
                                "and send an invoice with the amount you want to withdraw to yay@soundrepublica.com."+
                                "Royalties shall be paid within 30 days of your request.<br/><br/>"+
                                "Bill to : yay@soundrepublica.com<br/><br/>"
                            }
                        }
                    },
                    props: {
                    },
                    api:{   
                    }
            },
            panel20: {
                title: "How to issue a tax invoice",
                    description: "세금계산서 보기",
                    label: {
                        closeBtn: "Close"
                    },
                    msg: {
                    },
                    props: {
                    },
                    api:{   
                    }
            },
            panel21: {
                title: "",
                description: "고객서비스센터 상세 팝업",
                label: {
                    closeBtn: "Close"
                },
                msg: {},
                props: {},
                api:{}
            }
            
        },
        imageUpload: {
            artist: {
                title: "Artist Profile Image",
                description: "Artist Profile Image",
                label: {
                },
                msg: {
                    uploadGuide: 'Click here or Drag files to upload',
                    limitResolution1 : "Please check the image size",
                    limitResolution2 : "",
                    limitFormat: "Supported Formats: JPG, JPEG, PNG."
                },
                props: {   
                },
                api:{   
                }
            } 
        },
        dragAndDropTable: {
            trackList: {
                title: "VOLUME",
                description: "트랙리스트",
                label: {
                    tableHeader: [
                        "No.",
                        "트랙명",
                        "아티스티명",
                        "파일유형",
                        "타이틀곡",
                        "곡길이",
                    ],
                    no: "No.",
                    trackName: "트랙명",
                    artistName: "아티스티명",
                    fileType: "파일유형",
                    titleSong: "타이틀곡",
                    songLength: "곡길이",
                    register: "등록",
                    title: "Title",
                    wav: "WAV",
                    mp3: "MP3",
                    FLC: "FLC",
                    addTrack: "Add Track",
                    explicit: "EXPLICIT"
                },
                msg: {
                    title: "타이틀곳은 최대 2곡까지 설정하실 수 있습니다.",
                    none_track: "No track information has been entered."
                },
                fail: {
                    data_copy: "복사할 데이터가 없습니다. 데이터를 입력하여 주십시오."
                },
                props: {

                },
                api: {

                }
            }
        },
        tracksUpload: {
            tracks: {
                title: "음원 파일 업로드",
                description: "음원 파일 업로드",
                label: {
                    fileName : "Name",
                    bitrate: "Bit Rate",
                    sampleRate : "Sample Rate",
                    format : "Type",
                    duration: "Duration",
                    confirmBtn: "Confirm"
                },
                msg: {
                    uploadGuide: 'Click here or Drag files to upload',
                    limitSize : "The max file limit is 200MB.",
                    limitFormat: "Please only upload WAV, MP3 or FLAC files.",
                    formatDuplication: "You have already uploaded a file with the same file format.",
                    limitAmount1: "You can only upload up to",
                    limitAmount2: "files.",
                    limitDragCount: "You can only upload one file per format.",
                    guide: [
                        "Required Type: FLAC or WAV(44.1khz/16bit)",
                        "Optional Type: MP3 (min. 320kbps)",
                        "File size : 200MB max." ,
                        "Please double check the quality of your audio file before uploading.<br>If the quality does not meet the required standards, your release might be rejected."
                        ]
                },
                props: {   
                },
                api:{
                }
            } 
        }
    }
}