<template src="./template.html"></template>

<script>

import i18n from '../../../library/i18n' // 다국어지원

export default {
    name: "imageUpload",
    props: ['props','maxSize', 'fileSum', 'audio'],
    data(){
        return {
            testListData : [
                {
                    fileName: "01.너와함께라면(with you).wav",
                    bitrate: "16 bit",
                    sampleRate: "44,100Hz",
                    format: "WAV",
                    duration: "00:00"
                }
            ],
            file: null,
            fileInfoList: this.audio,
            eventName: "FILEUPLOAD",
            error: [],                      // 에러 메세지
            getMessage: null,               // 안내 메세지
            totalTrack : 0,
        }
    },
    created(){
        console.log(this.props)
        console.log(this.audio)
        console.log(this.filesum)
        let audio = this.audio
        if(audio){
            if(audio.length > 0) {
                this.fileInfoList = []
                audio.map(value =>{
                    this.fileInfoList.push(value)
                })
            }
        }
    },
    computed: {
        limitSize(){
            let result = "";
            if(this.maxSize === undefined){
                result = 200;
            }else{
                result = this.maxSize;
            }
            let maxAudioSize = result*1000*1000
            return maxAudioSize;
        },
        limitFileSum(){
            let result = "";
            if(this.fileSum === undefined){
                result = 3;
            }else{
                result = this.fileSum;
            }
            return result;
        },
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const limitFormat = i18n.t('components.tracksUpload.tracks.msg.limitFormat')                 // 파일 포멧
                const limitSize = i18n.t('components.tracksUpload.tracks.msg.limitSize')                     // 파일 용량
                const limitAmount = i18n.t('components.tracksUpload.tracks.msg.limitAmount1') + this.limitFileSum + i18n.t('components.tracksUpload.tracks.msg.limitAmount2')  // 등록 가능한 파일 수 제한
                const formatDuplication = i18n.t('components.tracksUpload.tracks.msg.formatDuplication')     // 포멧 중복
                const limtDragCount = i18n.t('components.tracksUpload.tracks.msg.limitDragCount')            // 파일 multiple 제한( 단일 등록만 가능 )
                const messageCollection = {
                    limitFormat: limitFormat,
                    limitSize: limitSize,
                    limitAmount: formatDuplication,
                    formatDuplication: formatDuplication,
                    limtDragCount: limtDragCount
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        },
        codec(){
            let codec = this.fileInfoList.map((item, index) =>{
                const formatName = item.metadata.codec.toLowerCase()
                if(item.hasOwnProperty("metadata")){
                    switch(formatName){
                        case "mpeg 1 layer 3":
                            return "MP3"
                            break;
                        case "pcm":
                            return "WAV"
                            break;
                        default:
                            return formatName
                            break;
                    }
                }
            })
            return codec
        },
        bitrate(){
            let bitrate = this.fileInfoList.map((item, index) =>{
                if(item.hasOwnProperty("metadata")){
                    if(item.metadata.samplerate){
                        let change = item.metadata.samplerate.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                        return Number(change).toFixed(1)
                    }else{
                        return 0
                    }
                }
            })
            return bitrate
        },
        samplerate(){
            let samplerate = this.fileInfoList.map((item, index) =>{
                if(item.hasOwnProperty("metadata")){
                    if(item.metadata.samplerate){
                        let change = item.metadata.samplerate.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                        return Number(change).toFixed(1)
                    }else{
                        return 0
                    }
                }
            })
            return samplerate
        }
    },
    methods: {
        dragover_handler(event){
            event.preventDefault()
            event.dataTransfer.dropEffect = "none"
        },
        dragUnOver(){
            let el = document.getElementById("filelabel");
            el.style.opacity = 0.8;
            el.style.visibility = "visible";
            el.style.backgroundColor = "#f2f2f2"
            el.getElementsByClassName('sctup_sp')[0].style.color = "#6d6c6c"
        },
        dragOver(){
            let el = document.getElementById("filelabel");
            el.style.opacity = 1;
            el.style.backgroundColor = "#2547c9"
            el.querySelector("p").style.color = "#fff"
        },
        formatCheck(fileName){
            // 파일 포멧 검색 WAV, MP3, FALC
            let reg = new RegExp('WAV|MP3|FLAC','i');
            if(!reg.test(fileName)) {
                this.popupOpen('tracks_guideMessage', 'limitFormat')
                this.dragUnOver();
                this.error.push("formatCheck")
            }
        },
        sizeCheck(fileSize){
            // 파일 용량 검색 200MB 이하
            if(fileSize > this.limitSize) {
                this.popupOpen('tracks_guideMessage','limitSize')
                this.dragUnOver();
                this.error.push("sizeCheck")
            }
        },
        listFileSumCheck(){
            // 업로드된 리스트 확인후 최대 업로드 파일 개수 제한 Default : 3
            let dataLength = this.fileInfoList.length;
            if(dataLength > this.limitFileSum){
                this.popupOpen('dacLay07', 'limitAmount')
                this.dragUnOver();
                this.error.push("listFileSumCheck")
            }
        },
        listFormatCheck(uploadFileName){
            // 업로드된 리스트 확인후 파일 포멧 제한 WAV, MP3, FLAC
            let listLength = this.fileInfoList.length;
            let files = this.fileInfoList;
            let uploadFileFormat = uploadFileName.substring((uploadFileName.length - 3), uploadFileName.length);
          
            if(files.length > this.limitFileSum){
                this.popupOpen('tracks_guideMessage', 'formatDuplication')
                this.dragUnOver();
                this.error.push("listFormatCheck")
            }

            for (let i in files){
                let fileName = files[i].display_name;
                let fileFormat = fileName.substring((fileName.length - 3), fileName.length);
                if(uploadFileFormat === fileFormat){
                    this.popupOpen('tracks_guideMessage', 'formatDuplication')
                    this.error.push("listFormatCheck")
                }
            }
        },
        filesUploadCountCheck(files){
            // 여러개 파일이 Drag 여부
            if(files.length > 1){
                this.popupOpen('tracks_guideMessage','limtDragCount')
                this.error.push("filesUploadCountCheck")
            }
        },
        deleted(event,index){
            let listData = this.fileInfoList;
            let listItem = event.target.closest("tr")
            listItem.classList.add("testCSS");

            // 파일 삭제 API
            let id = listData[index].id
            this.deleteFileAPi(id)
            setTimeout(function(){
                listData.splice(index, 1);
                listItem.classList.remove("testCSS");
            }, 800)
        },
        fileDrop(event){
            // 사용 보류
            this.error = []  // 에러 초기화
            const files = event.dataTransfer.files
            this.file = files[0] // 파일 저장
            const fileName = files[0].name
            const fileSize = files[0].size

            //File Check List
            this.formatCheck(fileName)
           
            this.sizeCheck(fileSize)
           
            this.filesUploadCountCheck(files)
            
            this.listFileSumCheck()
          
            this.listFormatCheck(fileName)
           
            // 로딩바 생성
            const dropbox = this.loading()

            const reader = new FileReader();
            reader.onload = (e) => {
                let audio = new Audio();
            }
    
            if(this.error.length > 0){
                this.loading_close(dropbox)
                this.dragUnOver()
                return false
            }else{
                if (files[0]) {
                    reader.readAsDataURL(files[0])
                    this.uploadfileAPi(this.file, this.eventName, dropbox)
                }
            }
        },
        uploadfileAPi(file, eventName, dropbox){
            this.$store.dispatch(eventName, file)
                .then(result => {
                    this.fileInfoList.push(result[0])

                    //로딩 이미지 삭제
                    this.loading_close(dropbox)
                    this.dragUnOver()
                }).catch(error =>{
                    this.error.push(error)
                })
        },
        deleteFileAPi(id) {
            this.$store.dispatch("FILEDELETE", id)
                .then(result => {
                    console.log('ok')
                }).catch(Error =>{
                    console.log("File Delete Fail ::::", error)
                })
        },
        fileChange(event){
            event.stopPropagation()
            this.error = []  // 에러 초기화
            const files = event.target.files
            this.file = files[0]
            const fileName = files[0].name
            const fileSize = files[0].size
            if(!fileName){
                return false
            }
            //File Check List
            this.formatCheck(fileName)
            this.sizeCheck(fileSize)
            this.filesUploadCountCheck(files)
            this.listFileSumCheck()
            this.listFormatCheck(fileName)

            // 로딩바 생성
            const dropbox = this.loading()

            const reader = new FileReader();
            reader.onload = (e) => {
                let audio = new Audio();
            }

            if(this.error.length > 0){
                this.loading_close(dropbox)
                this.dragUnOver()
                return false
            }else{
                if (files[0]) {
                    reader.readAsDataURL(files[0])
                    this.uploadfileAPi(this.file, this.eventName, dropbox)
                }
            }
        },
        filesDrop_test(event){
            event.stopPropagation();
            const items = event.dataTransfer.items
            const filesData = new Promise((resolve, reject) =>{
                const items = event.dataTransfer.items;
                let files = [];
                if(items){
                    for (let i=0; i < items.length; i++){
                        let file = items[i].getAsFile();
                        files.push(file);
                    }
                }
                resolve(files);
            });

            filesData.then(function(files){
                let resultData = ["01.너와함께라면(with you).mp3"];

                let test =
                files.map(file =>{
                    let fileName = file.name;
                    if(resultData.indexOf(fileName) !== -1){
                        this.popupOpen('dacLay04')
                    }
                });
                
                return false;


            });
        
            for(let file in files.length){
                let fileName = fileData.name;
                let fileSize = fileData.size;

                // 파일 포멧 검색 jpg, png, jpeg
                let reg = new RegExp('WAV|MP3|FLAC','i');
                if(!reg.test(fileName)) {
                    this.popupOpen('dacLay07')
                    this.dragUnOver();
                    return false;
                }

                // 파일 용량 검색 200MB 이하
                if(fileSize > this.limitSize) {
                    this.popupOpen('dacLay02')
                    this.dragUnOver();
                    return false;
                }
            }
            let audiofile = document.getElementById("input-file")
            const reader = new FileReader();
         
            reader.onload = (e) => {
                let audio = new Audio();
            }
    
            if (file) {
                reader.readAsDataURL(file);
            }
        },
        popupOpen(id, title){
            // popup Open
            //let popup = document.getElementById(id)
            //popup.classList.add('type2')
            this.guideMessage = title
            this.alert({msg : this.guideMessage})
            // setTimeout(function(){
            //     popup.firstChild.classList.add('type2')
            // }, 300)
        },
        popupClose(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
              popup.classList.remove('type2')
          }, 300)
        },
        loading(){
            // 로딩바 생성
            const dropbox = document.getElementsByClassName("dropbox")[0] // dropbox Select
            const loader_bg = document.createElement("div")
            const loader = document.createElement("div")

            loader_bg.style.width = "100%"
            loader_bg.style.height = "100%"
            loader_bg.style.zIndex = "2000"
            loader_bg.style.position = "absolute"
            loader_bg.addEventListener("dragover", this.dragover_handler);

            loader.setAttribute("id", "audio_loader")
            loader.classList.add('loader')
            loader.classList.add('type3') 
            loader_bg.append(loader)  
            dropbox.prepend(loader_bg)
            return dropbox
        },
        loading_close(node){
            // 로딩바 생성
            node.firstChild.childNodes[0].classList.add('type4')
            setTimeout(function(){
                node.removeChild(node.firstChild)
            },3000)
        }
    }
}
    
</script>

<style scoped>

.dropbox{
    overflow: visible;
    position: relative; 
    margin: 0 auto;
    background-color:#f1f1f1;   
}

.dropbox > div{
    font-size: 0.85rem;
    word-break: keep-all;   
}

#imgfile{
    opacity: 0;
}

.input-file {
    position: absolute;
    opacity: 0;
    width: 96% !important;
    height: 96% !important;
    top:2%;
    left:2%;
    z-index: 1000;
    margin: 0 auto;
    cursor: pointer;
    color:transparent;
    border: 1px solid #888
}

.previewImage {
    position: absolute;
    width: 15rem;
    height: 15rem;
    top:0;
    left:0;
    z-index: 3;
    padding: 0.5rem;
}

.test{
    background-color:darkorange;
}

.test1{
    display:none;
}

.test:hover~.test1{
    display:block;
}

#tracksList{
    width: 100%;
}

.testCSS {
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-in-out;
    -moz-transition: opacity 0.6s ease-in-out;
    transition: opacity 0.6s ease-in-out;
}


th, td{
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.listCount td:last-Child{
    cursor: pointer;
}

.guide_colorW {
    color: #fff;
}
</style>