<template src="./template.html"></template>

<script>
export default {
    name: 'panel-19',
    props: ['payment_method', 'entity'],  // 지급방법 : eur, usd, krw , 구분 : individual: "개인" , corporation: "사업자( 개인/법인 )"
    data() {
        return {
            pdf_file_ko: {title: 'pdf_file_ko', loc: "https://storage.googleapis.com/srd-static/web-common/Sound_Republica_Inc_Business_License.pdf"},
            pdf_file_en: {title: 'pdf_file_en', loc: "https://storage.googleapis.com/srd-static/web-common/Sound_Republica_BusinessLicense(English).pdf"}
        }
    },
    computed: {
        entity (data){
            return data.$attrs.props.entity
        },
        payment_method_data(data){
            return data.$attrs.props.payment_method
        }
    },
    methods: {
        closePanel(){
            this.$emit('closePanel')
        },
        goPayment() {
            this.$EventBus.$emit("navi", "page_position",'accounting')
            this.$router.push("/accounting")
        },
        
        showPanel20(){
            // 세금계산서 발행방법 보기
            // this.$EventBus.$emit('openPanelSub',{
            //     component: "panel-20",
            // });
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-20",
                props: {
                    //eventName: this.artist_registration_eventName
                }
            },()=>{
            });
           
            // const panel = this.$showPanel({
            //     component: "panel-20",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex"
            // })
        },
        showPanel22(){
            // 세금계산서 발행방법 보기
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-22",
                props: {
                    //eventName: this.artist_registration_eventName
                }
            });
            // const panel = this.$showPanel({
            //     component: "panel-20",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex"
            // })
        }
    }
}
</script>
