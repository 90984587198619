<template src="./template.html"></template>

<script>
export default {
    name: "panel-10",
    data(){
        return {
            id : null,
            emptyType: null,
            // 유통정보
            nationality: null,        // 국적코드
            nationalityInfo: null,    // 국적정보
            mims: null,
            entity: 'individual',   // individual: "개인" , corporation: "사업자( 개인/법인 )"
            family_name: null,
            given_name: null,
            companyName: null,
            phone: null,
            countryPhone: null,
            currency: null,
            payment: 'kr_banktransfer',
            paypal_email: null,        // paypal 이메일
            countryCode: null,         // 국가 코드
            countryInfo: null,         // 국가 정보
            // 세금정보
            error: [],
            distributionView: false,
            paypal:{
                title: 'paypal',
                img: 'https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png',
                description: 'paypal Logo'
            }, 
        }
    },
    computed: {
        
        params() {
            let params= {
                id : '',
                nationality: this.nationality,
                nationalityInfo: this.nationalityInfo,
                division:this.entity,
                family_name: this.family_name,
                given_name: this.given_name,
                phone: this.phone,
                countryPhone: this.countryPhone,
                currency: this.currency,
                payment: this.payment,
                countryCode: this.countryCode,
                countryInfo: this. countryInfo,
                distributionView: this.distributionView,
                entity_identification_type : null
            }
            if(this.currency == 'usd') Object.assign(params, {paypal_email: this.paypal_email})
            if(this.nationality == 'KR') Object.assign(params, {mims: this.mims})
            if(this.entity == 'corporation') Object.assign(params, {companyName: this.companyName})
            if(this.entity == "individual"){ // 개인
                params.entity_identification_type = "kr_resident"
            }else if(this.entity == "corporation"){ //사업자
                params.entity_identification_type = "kr_business"
            } else{
                params.entity_identification_type = "foreign_tax"
            }
            return params
        }
    },
    watch: {
        phone(){
            return this.phone = this.phone.replace(/[^0-9]/g, '');
        },
        currency(){
            switch(this.currency){
                case 'usd':
                    this.payment = 'paypal'
                    break;
                case 'krw':
                    this.payment = 'kr_banktransfer'
                    break;
            }
            return this.currency
        },
        nationality(){
            // 국적
            this.$store.state.country.map(country =>{
                let nationality = this.nationality
                if(country.code == nationality){
                    this.nationalityInfo = country
                }
            })
            return this.nationality
        },
        countryCode(){
            // 거주지 국가
            const countryCode = this.countryCode
            this.$store.state.country.map(country =>{
                if(country.code == countryCode) this.countryInfo = country
                
                if(countryCode){
                    switch(countryCode) {
                        case 'KR':
                            this.currency = 'krw'
                            break;
                        default:
                            this.currency = "usd"
                            break;
                    }
                }
            })
            return this.countryCode
        },
        mims() {
            if(this.paypal_email) {
                if(this.error.indexOf("mims") > -1) {
                    this.error.splice(this.error.indexOf("mims"), 1)
                }
            }
        },
        paypal_email(){
            if(this.paypal_email) {
                if(this.error.indexOf("paypal_email") > -1) {
                    this.error.splice(this.error.indexOf("paypal_email"), 1)
                }
            }
        }
    },
    methods: {
        closePanel(){
            this.$emit('closePanel', {})
        },
        onSubmit(){
            this.error = [] // 에러 초기화
            // 국적 코드
            const nationality = new Promise((resolve, reject) =>{
                    this.goNullCheck(this.emptyType, this.nationality, "EMPTYCHECK", "nationality")
                    setTimeout(() =>{
                        resolve(this.errorImpl("nationality"))
                    }, 100)
                })

            // 국적 정보
            const nationalityInfo = new Promise(resolve =>{
                    this.goNullCheck(this.emptyType, this.nationalityInfo, "EMPTYCHECK", "nationalityInfo")
                    setTimeout(() =>{
                        resolve(this.errorImpl("nationalityInfo"))
                    }, 100)
                })

            // MIMS 가입자명
            const mims = new Promise(resolve =>{
                    if(this.nationality == 'KR'){
                        this.goNullCheck(this.emptyType, this.mims, "EMPTYCHECK", "mims")
                        setTimeout(() =>{
                            resolve(this.errorImpl("mims"))
                        }, 100)
                    }else resolve(false)
                })

            // 개인 /사업자 구분
            const entity = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.entity, "EMPTYCHECK", "entity")
                resolve(this.errorImpl("entity"))
            })

            // 이름 체크
            const given_name = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.given_name, "EMPTYCHECK", "given_name")
                setTimeout(() =>{
                    resolve(this.errorImpl("given_name"))
                }, 100)
            })

            const family_name = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.family_name, "EMPTYCHECK", "family_name")
                setTimeout(() =>{
                    resolve(this.errorImpl("family_name"))
                }, 100)
            })

            // 회사명
            const companyName = new Promise(resolve =>{
                if(this.entity == 'corporation'){
                    this.goNullCheck(this.emptyType, this.companyName, "EMPTYCHECK", "companyName")
                    setTimeout(() =>{
                        resolve(this.errorImpl("companyName"))
                    }, 100)
                }else resolve(false)
            })
  
            // 전화번호
            const countryPhone = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.countryPhone, "EMPTYCHECK", "countryPhone")
                setTimeout(() =>{
                    resolve(this.errorImpl("countryPhone"))
                }, 100)
            })

            const phone = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.phone, "EMPTYCHECK", "phone")
                setTimeout(() =>{
                    resolve(this.errorImpl("phone"))
                }, 100)
            })

            //거주지 국가
            const countryCode = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.countryCode, "EMPTYCHECK", "countryCode")
                setTimeout(() =>{
                    resolve(this.errorImpl("countryCode"))
                }, 100)
            })

            //거주지 국가정보
            const countryInfo = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.countryInfo, "EMPTYCHECK", "countryInfo")
                setTimeout(() =>{
                    resolve(this.errorImpl("countryInfo"))
                }, 100)
            })

            //정산화폐
            const currency = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.currency, "EMPTYCHECK", "currency")
                resolve(this.errorImpl("currency"))
            })

            // 지급방법
            const payment = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.payment, "EMPTYCHECK", "payment")
                resolve(this.errorImpl("payment"))
            })
            // paypal email
            const payapl = new Promise(resolve =>{
                if(this.payment == 'paypal'){
                    this.goNullCheck(this.emptyType, this.paypal_email, "EMPTYCHECK", "paypal_email")
                    resolve(this.errorImpl("payment"))
                }else{
                    resolve(false)
                }
            })
            
            Promise.all([nationality, mims, entity, given_name, family_name, companyName, countryPhone, phone, currency, payment, countryCode])
            .then(values => {
                // Error가 있을 경우 전송 중지
                setTimeout(() => {
                    
                }, 0);
                this.onSubmitImpl(values)
            }).catch(error => {
                   
                })
        },
        onSubmitImpl(values){
            // onSubmit Implement
           
            if(this.error.length  === 0 && values.indexOf(true) <= -1) {
                let params = this.params
                
                this. loadingShow();
                this.$store.dispatch('MYSAVE', params)
                .then((result)=> {
                    this. loadingHide()
                    this.alert({msg:this.iText('등록되었습니다.','regSuccess')})
                    params['id']= result['id']
                    this.closePanel();
                    this.$EventBus.$emit('MY', 'distributionView', params)
                
                }).catch(error => {
                    this. loadingHide()
                    console.log('error',error)
                    console.log("My Page Save dddddddddError ::: :::")
                })
            }else{
                this. loadingHide()
                this.alert({msg:this.iText('입력정보를 확인해주세요.','inputValueCheck')})
            }
        },
        popupOpen(id){
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            setTimeout(function(){
              popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupClose(event){
          // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
              popup.classList.remove('type2')
              this.closePanel()
          }, 300)
        },
        
        goNullCheck (emptyType, params, functionName, message) {
            this.$store.dispatch(functionName, {emptyType, params})
            .then(result => {
                if(result) {
                    if(!(this.error.indexOf(message) > -1)) this.error.push(message)
                }
            })
            .catch(error => {
                console.log("Null check Error :::")
            })
        },
        errorImpl(message){
            if(this.error.indexOf(message) > -1) 
            {return true}
            else return false
        },
        getNumberMask(val) {
            //서버 전송 값에는 '-' 를 제외하고 숫자만 저장
            this.$store.dispatch("ONLYNUMBER", {val})
                .then(result => {
                    this.phone = result
                }).catch(Error =>{
                    console.log("Only Number  Error:::")
                })
        },
        errorMessage(event){
            const id = event.target.id
            const error = this.error.indexOf(id)
            if(error > -1){
                this.error.splice(error, 1);
            }
        },
        select_errorMessage(value) {
            const error = this.error.indexOf(value)
            if(error > -1){
                this.error.splice(error, 1);
            }
        }
    }
}

</script>

<style scoped>
.width183{
    width: 183px;
}
</style>
