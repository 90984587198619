<script>
import { Doughnut, mixins } from "vue-chartjs";

export default {
    extends: Doughnut,
    props: ["chartOption"],
    mixins: [mixins.reactiveProp],
    data(){
        return {
            options: this.chartOption
        }
    },
    mounted() {
        this.options.tooltips = {
            callbacks: {
                label: function(tooltipItem, data){
                    var dataLabel = data.labels[tooltipItem.index];
                    var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();

                    if (Chart.helpers.isArray(dataLabel)) {
                        dataLabel = dataLabel.slice();
                        dataLabel[0] += value;
                    } else {
                        dataLabel += value;
                    }
                    return dataLabel;
                }
            }
        }
        this.renderChart(
        this.chartData, this.options
        )
    }
}

</script>