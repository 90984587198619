<template src="./template.html"></template>

<script>
import i18n from "../i18n"
export default {
    props: ['listCount', 'listType', "uiType", "eventName", "all_check", "filterView", "currentData"],
    data(){
        return {
            artist_click: true,   // 클릭
            infoData: [],
            result: 0,
            count: 0,
            variousArtist: false,
            variousArtistName: "Various Artists",                                   // Various Artist display_name
            arrayResult: [],
            arrayList: [],
            search: null,                                                           // 검색
            value: null,                                                            // 임시
            artist_registration_eventName: "artist_registration_success",
            currentPage: 0,                                                         // 현재 페이지
            sort: 'created_at',                                                     // 정렬
            total: 0,                                                               // 리스트 총 갯수
            totalPage: 0,                                                        // 리스트 총 페이지 수
            filtered: 0,                                                            // filter 총 갯수
            listData: []                                                     // 리스트 데이터
        }
    },
    created () {
         // default 리스트 불러오기

          this.data_search()
    },
    mounted(){
        this.$nextTick(function () {
          
            if(this.currentData.length > 0) {
            this.currentData.map(value =>{
                if(value.display_name == this.variousArtistName) this.variousArtist = true
                else {
                    this.arrayList.push(value)
                    this.infoData.push(value.id)
                }
            })
        }
        // 모든 화면이 렌더링된 후 실행합니다.
            let array = this.currentData
            if(array.length > 0) {
                //artist List 노출
                this.attachNode(array)
                
            }
            // this.$EventBus.$on('update', (param)=>{
            //     console.log('update');
            //     this.data_search()
            // })
        })
    },
    watch: {
        sort(){
            return this.sort
        },
        variousArtist(){
            this.variousArtistCheck()
            return this.variousArtist
        },
        infoData(){
            return this.infoData
        },
        arrayList(){
            this.$EventBus.$emit(this.eventName, this.resultData)
        }
    },
    computed: {
        resultData(){
            let resultData = [] // 초기화
            this.arrayList.forEach(value =>{
                resultData.push(value)
            })
            return resultData
        }
    },
    methods : {
        handleScroll: function() {
            if (this.timeout) clearTimeout(this.timeout)

                this.timeout = setTimeout(function(){
                    // your action
                    this.scrollArea()
                }.bind(this), 250);
        },
        scrollArea(){
            let targetEl = document.getElementById("gridList")
            let a = targetEl.scrollHeight - targetEl.clientHeight
            let b = targetEl.scrollTop;
    
            let dataLength = this.listData.length  // 데이터 량
            let listCount = this.listCount         //리스트 노출 제한
            let result = parseInt(dataLength/listCount)  //나누기 몫
            let remainder = (this.filtered%listCount)   /// 나눈 나머지 몫
            
            this.totalPage = (remainder == 0)?(parseInt(this.filtered/listCount)) : (parseInt(this.filtered/listCount)+1) // 총 리스트 페이지 수
            if((b/a) >= 0.8){
                if(this.currentPage <= this.totalPage) {
                    this.currentPage++
                    this.loadArtistList(this.currentPage)
                }
            }
        },
        listTypeChange(){
            switch(this.listType){
                case "normal":
                    this.listType = "grid";
                    break;
                case "grid":
                    this.listType = "normal";
                    break;
                default: this.listType = "grid";
            }
        },
        variousArtistCheck() {
            this.variousArtistImplement()
            this.variousArtist_search()
        },
        variousArtistImplement() {
            if(this.variousArtist) {
                // 정렬 고정
                const sortKeys = Object.keys(i18n.t('components.panel.panel15.props.sort'))
                sortKeys.map((key,index) =>{
                    let sort = document.getElementById('srsrd0'+ (index+1))
                    sort.setAttribute("disabled", true)
                    sort.disabled = true
                })
            
                this.listData.map(value =>{
                    if(this.infoData.length > 0) this.deleteNode(value.id) // arrayArrange, checkbox, 데이터 삭제 처리
                    
                    if(value.display_name != this.variousArtistName) {
                        // 체크 박스 비활성화
                        let id = document.getElementById('artist_'+ value.id)
                        if(id){
                            id.setAttribute("disabled", true)
                            id.classList.add('style02')
                            id.labels[0].classList.add('disabled')
                            id.checked = false
                        }
                    }
                })

                this.allDeleteNode()

            }else{
                // 정렬 고정해제
                const sortKeys = Object.keys(i18n.t('components.panel.panel15.props.sort'))
                sortKeys.map((key,index) =>{
                    let sort = document.getElementById('srsrd0'+ (index+1))
                    sort.removeAttribute("disabled")
                })

                this.listData.map(value =>{
                    if(value.display_name != this.variousArtistName) {
                        let id = document.getElementById('artist_'+ value.id)
                        id.removeAttribute("disabled")
                        id.classList.remove('style02')
                        id.labels[0].classList.remove('disabled')
                    }
                })
            }
        },
        itemCheck(event){
            let target = event.target
            let arrayCount = this.infoData.length
            let value = Number(target.value)
            if(arrayCount > 3 && target.checked) { // 임시처리 확인 필요
                alert(i18n.t("components.panel.panel15.msg.artistCount"))
                target.checked = false
            }else{
                this.createNode(value)
            }
        },
        createNode(id){
            let presenceCheck = this.arrayList.filter(item =>{
                return item.id == id
            }).length // 해당값 존재 여부 체크 0 : 존재않함, 1 이면 존재함
    
            if(presenceCheck < 1) {
                this.listData.map(item =>{
                    if(item.id == id) {
                        this.arrayList.push(item)
                        // parent Node
                        const parentNode = document.getElementById("arrayArrange")
                        const node = document.createElement("span")
                        node.setAttribute("id", id)
                        node.classList.add("srsprof_spec_etc")
                        node.classList.add("cursorStyle01")
                
                        let name = item.display_name
                        if(name != this.variousArtistName) {
                            let artistNode = document.createTextNode(name +" x")
                            node.appendChild(artistNode)
                        }

                        node.addEventListener("click", function() {
                            if(name != this.variousArtistName) this.deleteNode(id) 
                            /* this.$EventBus.$emit(this.eventName, this.resultData) */
                        }.bind(this), false)

                        parentNode.appendChild(node);
                    }
                })
            }else{
                this.arrayList.map((item, index) =>{
                    if(item.id == id) {
                        this.arrayList.splice(index, 1);
                        const parentId = document.getElementById("arrayArrange")
                        const childId = document.getElementById(id)
                        parentId.removeChild(childId)
                    }
                }) // 해당값 존재 여부 체크 0 : 존재않함, 1 이면 존재함
            }
        },
        deleteNode(id){
            this.arrayList.filter((item, index) =>{
                if(item.id == id){
                    this.arrayList.splice(index, 1)
                    const parentId = document.getElementById("arrayArrange")
                    const childId = document.getElementById(id)
                    parentId.removeChild(childId)
                    return item.id == id
                }
            }) // 해당값 존재 여부 체크 0 : 존재않함, 1 이면 존재함

            this.infoData.filter((item, index) =>{
                if(item == id)
                    this.infoData.splice(index, 1)
                return item.id == id
            }) // 해당값 존재 여부 체크 0 : 존재않함, 1 이면 존재함
        },
        allDeleteNode(){
            this.arrayList = [] // 초기화
            this.infoData = []  // 초기화

            const parent = document.getElementById("arrayArrange")
            let child = parent.lastElementChild
            while(child) {
                parent.removeChild(child)
                child = parent.lastElementChild
            }
        },
        attachNode(listData){
            // parent Node
            const parentNode = document.getElementById("arrayArrange")
            console.log('list',listData)
            listData.map(item =>{
                const node = document.createElement("span")
                node.setAttribute("id", item.id)
                node.classList.add("srsprof_spec_etc")
                node.classList.add("cursorStyle01")
                    
                let name = item.display_name
                if(name != this.variousArtistName) {
                    let artistNode = document.createTextNode(name +" x")
                    node.appendChild(artistNode)
                }

                node.addEventListener("click", function() {
                    if(name != this.variousArtistName) this.deleteNode(item.id) 
                }.bind(this), false)
                parentNode.appendChild(node)
            })
        },
        showPanel2(){
            // const panel = this.$showPanel({
            //     component: "panel-2",
            //     width: "740px",
            //     openOn: 'right',
            //     /* cssClass: "panel-zindex", */
            //     /* keepAlive: true, */
            //     props: {
            //         eventName: this.artist_registration_eventName
            //     }
            // })
            
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-2",
                props: {
                    eventName: 'track_artist_update'
                }
            },()=>{
                let page = 0
                this.listData = []
                this.loadArtistList(page)
                //this.artist_click = true
            });
        },
        dataSearch(id) {
            // 데이터 찾기
                return this.listData.filter(data => {
                return data.id == id
            })[0]  
        },
        sortChange(event) {
            // Sort 옵션 설정
            this.currentPage = 0
            this.listData = []
            if(event.target.id == "srsrd01")
                this.sort = "created_at"
            else if(event.target.id == "srsrd02") this.sort = "display_name"
            this.loadArtistList(this.currentPage)
        },
        loadListAPi(sort, sortorder, page, column) {
            this.$store.dispatch("LOADARTIST", { sort, sortorder, page, column })
                .then(result =>{
                    this.total = result.total  // 총 리스트 업데이트
                    this.filtered = result.filtered // 검색 총 갯수
                    this.listData = result.items
                })
        },
        data_search(){
            this.currentPage = 0
            this.listData = []
            this.loadArtistList(this.currentPage)
        },
        variousArtist_search(){
            event.preventDefault()
            if(this.variousArtist) {
                let column = this.variousArtistName
                return this.$store.dispatch("LOADARTIST", {column})
                .then(result =>{
                    this.arrayList.push(result.items[0])
                })
            }else{
                if(this.arrayList.length > 0) {
                    this.arrayList.filter((item, index) =>{
                        if(item.display_name == this.variousArtistName) this.arrayList.splice(index, 1)
                    })
                }
            }
        },
        artist_register(){ 
                this.showPanel2()
        },
        closePanel(){
            this.$emit('closePanel', {
            });
        },
        loadArtistList(page) { 
            /* event.preventDefault() */
            let sort = this.sort
            page = page? page : 0
            let column = this.search
            let limit = 40            
            // 로딩
            this.loadingShow()
            return this.$store.dispatch("LOADARTIST", {sort, page, column,limit})
            .then(result =>{
                event.preventDefault()
                this.total = result.total  // 총 리스트 업데이트
                this.filtered = result.filtered // 검색 총 갯수
                this.loadingHide()
                if(result.items.length > 0) {
                    let artistData = result.items.map((value, index) => {
                        this.listData.push(value)
                        if(this.variousArtist) {
                            if(value.display_name != this.variousArtistName) {
                                setTimeout(function(){
                                    // 체크 박스 비활성화
                                    const id = document.getElementById('artist_'+ value.id)
                                    id.setAttribute("disabled", true)
                                    id.classList.add('style02')
                                    id.labels[0].classList.add('disabled')
                                    id.checked = false
                                }, 100)
                            }
                        }
                    })
                }
                setTimeout(function(){
                    // document.getElementsByClassName('text_loading')[0].classList.remove('loading_active')
                }, 1000)
            }).catch(error=> {
                this.loadingHide()
            })
        }
    }
}
</script>

<style scoped>
    li {
        list-style-type: none;
    }

    .gridList2 {
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .gridList2::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    .deleteStyle {
        cursor: pointer;
        margin: 0 5px;
    }

    .height600 {
        height: 600px;
    }
</style>