<template>
    <div >
      <router-view/>
      <footerArea></footerArea>
    </div>
  </template>
  
  <script>
  import navigationBar from '@/components/layout/navigationBar.vue'      // Top Navigation Bar
  import footerArea from '@/components/layout/footer/index.vue'          // Footer
  
  export default {
    name: 'App',
    components: {
      navigationBar,
      footerArea
    },
    data(){
      return {
        fullLoading : false,
        modalparam : {},
        modalConfirm : {
          title: null,
          msg :null,
          width:null,
          action : null,
          autofocus : false
        },
        mobileCheck:false
      }
    },
    created(){
      
    },
    mounted() {
      
    
    },
    methods:{
      
    }
    
  }
  
  </script>
