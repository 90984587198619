<template src="./template.html"></template>

<script>
export default {
    name: 'panel-21',
    props: ["message"],
    data() {
        return {
            msgData: null                              // Content 데이터
        }
    },
    created(){
    },
    watch: {
    },
    computed: {
        contentData(data){
            this.msgData  = data.$attrs.props.message
            return this.msgData
        }
    },
    methods: {
        closePanel(){
            this.$emit('closePanel')
        }
    }
}
</script>