<template src="./template.html"></template>

<script>
export default {
    name: 'panel-5',
    props: {
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            pwChangeForm: {
                prevPassword: "",
                newPassword: "",
                keepAlive: false
            }
        }
    },
    methods: {
        closePanel(){
            this.$emit('closePanel', {
                prevPassword: this.pwChangeForm.prevPassword,
                newPassword: this.pwChangeForm.newPassword,
                name: this.name
            });
        }
    }
}
</script>

<style>
#panel-5 {
    padding: 40px;
}
</style>