<template src="./template.html"></template>

<script>
export default {
    name: "panel-15",
    props: ["props","returnName", "currentData"],
    data() {
        return {
            search: null,
            artists: [],
            eventName: "artists_select"
        }
    },
    created(){
        this.artists = this.props.currentData
     
        this.$EventBus.$on(this.eventName, (params) => {
            this.artists = params
        })
        
    },
    computed: {
        gridScrollListOption (){
            return {
                listCount : 20,
                listType: "normal",
                uiType: "checkbox",
                eventName: this.eventName,
                all_check: true,
                filterView: true,
                currentData: this.artists
            }
        }
    },
    methods: {
        onSubmit(){
            this.$EventBus.$emit('trackArtist', this.artists,)
            this.closePanel()
        },
        closePanel(){
            this.$emit('closePanel', {});
        }
    }
}
</script>

<style scoped>
.style02 {
    background-color: blue;
}

.showPanel {
    cursor: pointer;
}
</style>