<template src="./template.html"></template>

<script>
export default {
    props: [],
    data(){
        return {
            representative_artist: [],
            agree: false,                                                   // 약관 동의 여부
            coverImage_result_eventName: "distribution_coverImage",         // 음원 유통 커버 이미지 Key 명
            agencyImage_result_eventName: "distribution_agencyImage",       // 음원 유통 기획사 이미지 Key 명
            eventName: "return_data",                                       // 대표아티스트 이벤트 명
            artist_result_eventName: "representative_artist",               // 대표아티스트 return Key 명
            params : {
                defaultInfo: {                                              // 앨범 기본 정보
                    coverImage: 0,                                          // 앨범 커버 이미지 아이디
                    album_name : null,                                      // 앨범 명
                    album_language: null,                                   // 앨범 명 언어
                    album_name_en: null,                                    // 영문 앨범 명
                    album_name_en_generate: false,                          // 영문 앨범명 존재 여부, 있다면 : false / 없다면 : true
                    artists: [],                                            // 대표 아티스트
                    genre: [],                                              // 대표 장르
                    barcode: null,                                          // UPC/EAN 코드
                    barcode_generate: false,                                // UPC/EAN 코드 신청여부
                    agencyName_language: null,                              // 기획사 명 언어
                    agencyName: null,                                       // 기획사 명
                    agencyName_en: null,                                    // 영문 기획사 명
                    agencyName_en_generate: false,                          // 영문 기획사명 존재 여부, 있다면 : false / 없다면 : true
                    desired_release_date: null                              // 발매 희망일                          
                },
                etc: {
                    agencyImage: 0,                                         // 기획사 이미지 아이디
                    credit: null,                                           // 크레딧
                    original_release_date: null,                            // 기 발매일
                    introduce_language: null,                               // 앨범소개 언어
                    introduce: null,                                        // 앨범 소개
                    introduce_en: null                                      // 앨범 소개 영문       
                },
                track: {
                    trackList: null                                         // 트랙정보
                },
                musicStore: {
                    countryRelease: "global",                               // 발매국가
                    countryExcept: []                                       // 제외 국가
                }
            },     
            deleteBtn_view: false,                                          // 앨범 삭제하기 버튼 노출 여부
            temporaryBtn_view: false,                                       // 임시저장 버튼 타입 변경
            error: [],                                                      // Error
            requireFilter: ["etc", "track"],                                // 대분류 Main_category 필수 제외 항목
            checkboxFilter: [],                                             // checkbox type 중 필수인 항목
            trackParams: [],                                                // 트랙 정보
            desired_release_date: null,                                     // 발매 예정일 원본값
            desired_release_date_datechk: false,                            // 발매 예정일 활성화여부
            original_release_date: 'null',                                  // 기발매일 원본값
            coverImage_file: null,                                          // 커버이미지 File Data
            coverImageInfoData: null,                                       // 커버 이미지 File metadata
            agencyImage_file: null,                                         // 기획사 이미지 File Data
            labelImageInfoData: null,                                       // 기획사 이미지 File metadata
            fileUpload_eventName: "FILEUPLOAD",                             // File Upload Event Name
            trackInfo_result_eventName: "trackInfo_result",                 // 트랙정보 최종 결과 값 받기 위한 Event Name
            albumData: null,                                                // 앨범 정보
            onSubmitType: "post",                                           // 임시저장, 음악 유통 신청하기 전송 타입( post, put )
            getMessage: null,                                               // popup message
            home:{
                title: 'Home',
                img: require('@/assets/images/toplogo.png'),
                description: 'Sound Republica Logo',
                locale: this.$store.state.languageCode
            },                                                              // 로고 이미지
            loader:{
                img: require('@/assets/images/bx_loader.gif'),
                description: 'Loading Image'
            },                                                              // 로딩 이미지
            typingBool: false,                                              // typing
            credit: null,                                                   // 크래딧
            introduce: null,                                                //앨범 소개
            introduce_en: null,                                             // 앨범 소개 영문
            add_albumInfo: false,                                           // 앨범 추가정보 Show
            click: {
                artist_click: true,
                genre_click: true
            }
        }
    },
    mounted(){
        this.$nextTick(function () {
        // 모든 화면이 렌더링된 후 실행합니다.
            // title animation
            setTimeout(function(){
                document.getElementById('distributionMusic').classList.add('action')
            }, 200)

            // 유통정보, 세금정보, 지급정보 미입력시
            const session_userInfo = JSON.parse(localStorage.SR_UserInfo)
            if(session_userInfo.hasOwnProperty('licensor')){
                if(!session_userInfo.licensor) {
                    this.popupOpen('distribution_guide01',"none_licensor")
                }
            }

            this.$EventBus.$on(this.eventName, (params, key) => {
                if(key === this.trackInfo_result_eventName){
                    // 음악 판매하기 트랙정보 값
                    this.trackParams = params
                    if(this.error.indexOf('track') > -1) this.error.splice(this.error.indexOf('track'), 1)
                }else if(key === this.artist_result_eventName) {
                    this.artist_implement(params)
                }else if(key === this.coverImage_result_eventName){
                    // 커버이미지 파일
                    this.coverImage_file = params
                }else if(key === this.agencyImage_result_eventName){
                    // 기획사 이미지 파일
                    this.agencyImage_file = params
                }
            })

            this.$EventBus.$on('representative_genre', (params) => {
                let genres = []
                this.params.defaultInfo.genre = params
            })
        })
    },
    watch: {
        album_language(){
            if(this.album_language) this.errorMessage_type2("album_name")
        },
        album_name(){
            if(this.album_name) this.errorMessage_type2("album_name")
        },
        album_name_en(){
            if(this.album_name_en) this.errorMessage_type2("album_name_en")
        },
        artists() {
            if(this.artists) this.errorMessage_type2("artists")
        },
        genre() {
            if(this.genre) this.errorMessage_type2("genre")
        },
        barcode(){
            if(this.barcode) this.errorMessage_type2("barcode")
        },
        agencyName_language(){
            if(this.agencyName_language) this.errorMessage_type2("agencyName")
        },
        agencyName(){
            if(this.agencyName) this.errorMessage_type2("agencyName")
        },
        agencyName_en(){
            if(this.agencyName_en) this.errorMessage_type2("agencyName_en")
        },
        original_release_date(){
            // 기발매일
            this.dateTimeCrop(this.original_release_date).then(result =>{
                if(result) this.params.etc.original_release_date = result
            })
            return this.original_release_date
        },
        desired_release_date(){
            // 발매예정일
            this.dateTimeCrop(this.desired_release_date).then(result =>{
                if(result) {
                    this.params.defaultInfo.desired_release_date = result
                    if(this.error.indexOf('desired_release_date') > -1) {
                        this.error.splice(this.error.indexOf('desired_release_date'),1)
                    }
                }
            })
            return this.desired_release_date
        },
        credit(){
            // 크레딧
            let param = this.credit
            this.$store.dispatch("TEXTAREAIMPL", param).then(result =>{
                console.log("credit::", result)
                this.params.etc.credit = result
            })
            return this.credit
        },
        introduce(){
            // 크레딧
            let param = this.introduce
            this.$store.dispatch("TEXTAREAIMPL", param).then(result =>{
                this.params.etc.introduce = result
            })
            return this.introduce
        },
        introduce_en(){
            // 크레딧
            let param = this.introduce_en
            this.$store.dispatch("TEXTAREAIMPL", param).then(result =>{
                this.params.etc.introduce_en = result
            })
            return this.introduce_en
        },
    },
    computed: {
        album_language(){
            // 앨범 영문명
            return this.params.defaultInfo.album_language
        },
        album_name(){
            // 앨범 영문명
            return this.params.defaultInfo.album_name
        },
        album_name_en(){
            // 앨범 영문명
            return this.params.defaultInfo.album_name_en
        },
        artists(){
            return this.params.defaultInfo.artists
        },
        genre(){
            return this.params.defaultInfo.genre
        },
        barcode(){
            // UPC/EAN
            return this.params.defaultInfo.barcode
        },
        agencyName(){
            return this.params.defaultInfo.agencyName
        },
        agencyName_en(){
            return this.params.defaultInfo.agencyName_en
        },
        imageUploadOption(){
            return {
                type: "cover",
                eventName: this.eventName,
                returnKey: this.coverImage_result_eventName,
                minWidth: 1400,
                minHeight: 1400,
                imageFileInfoData: this.coverImageInfoData
            }
        },
        imageUploadOption_label(){
            return {
                type: "cover",
                eventName: this.eventName,
                returnKey: this.agencyImage_result_eventName,
                minWidth: 1000,
                minHeight: 1000,
                imageFileInfoData: this.labelImageInfoData
            }
        },
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const none_licensor = this.$i18n.t("distributionMusic.msg.none_licensor")
                const completed = this.$i18n.t("distributionMusic.msg.completed")
                const save = this.$i18n.t("distributionMusic.msg.save")
                const saving = this.$i18n.t('distributionMusic.msg.saving')
                const registering = this.$i18n.t('distributionMusic.msg.registering')
                const none_coverImage = this.$i18n.t('distributionMusic.msg.coverImg')

                const messageCollection = { 
                    none_licensor: none_licensor,
                    completed: completed,
                    save: save,
                    saving: saving,
                    registering: registering,
                    none_coverImage: none_coverImage
                    }
                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        },
        representative_genre(){
            return this.params.defaultInfo.genre
        },
        contract(){
            const SR_UserInfo = localStorage.getItem("SR_UserInfo")
            const currency = JSON.parse(SR_UserInfo).licensor? JSON.parse(SR_UserInfo).licensor.currency: null
            const contract = this.$store.state.contract
            let result = contract.ko
            if(currency) {
                switch(currency.toLowerCase()) {
                    case 'krw':
                        result =  contract.ko
                        break;
                    case 'usd':
                        result = contract.en
                        break;
                    case 'eur':
                        result = contract.en
                    default:
                        break;
                }
            }
            return result
        }
    },
    methods: {
        dragover_handler(event){
            /* event.preventDefault(); */
            // Set the dropEffect to move
            event.dataTransfer.dropEffect = "none"
        },
        onsubmit(){
            this.error = [] // Error 초기화
            return new Promise(resolve =>{
                let emptyCheck = this.emptyCheck(this.params)
                if(!this.agree){
                    this.error.push('agree')
                    resolve(false)
                }else resolve(true)
            }).then(result =>{
                if(result){
                    // File 저장
                    let coverImage_file = this.coverImage_file             // 커버이미지
                    const cover_key = 'coverImage'
                    const agency_key = 'agencyImage'
                    let agencyImage_file = this.agencyImage_file           // 기획사 이미지
                  
                    if(coverImage_file){
                        this.popupOpen01('distribution_guide03',"registering") // loading open
                        if(this.error.indexOf(cover_key) > -1) this.error.splice(this.error.indexOf(cover_key),1)  // cover Image error 삭제
                        const coverImage = new Promise(resolve =>{
                            this.uploadfileAPi(this.coverImage_file)      // 커버 이미지 저장          
                            .then(result => {
                                this.params.defaultInfo[cover_key]  = result[0].id
                                resolve(true)
                            }).catch(error =>{
                                this.error.push(cover_key)
                            })
                        })

                        const agencyImage = new Promise(resolve =>{
                            if(agencyImage_file) {
                                this.uploadfileAPi(agencyImage_file)      // 커버 이미지 저장          
                                .then(result => {
                                    this.params.etc[agency_key]  = result[0].id
                                    resolve(true)
                                }).catch(error =>{
                                    this.error.push(agency_key)
                                })
                            }else resolve(true)
                        })

                        Promise.all([coverImage, agencyImage]).then(values => {
                            console.log("Promise.all :::", values,this.error)
                            if(this.error.length == 0 && values.indexOf(false) == -1) {
                                let state = "registration_complete" // 최종 제출
                                let dataTemplate = this.distributionDataTemplate(this.params, this.trackParams, state)
                                this.distributionSaveApi(dataTemplate, state)
                            }else{
                                console.log("popupClose04 close::",)
                                this.popupClose04("distribution_guide03") // loading close
                            }
                        })
                    }else{
                        this.error.push('coverImage')
                        // [S] popup close
                        console.log("Promise.all fail :::", this.error)
                        /* this.popupClose04("distribution_guide03") // loading close */
                        this.popupOpen("distribution_guide02", 'none_coverImage')
                        /* let popup = document.getElementById('distribution_guide04')
                        popup.firstChild.classList.remove('type2')
                        setTimeout(function(){
                            popup.classList.remove('type2')
                        }, 300) */
                        // [E] popup close
                        /* return false */
                    }
                }
            })
        },
        onsubmit_temporary(){
            // 임시저장
            this.error = [] // Error 초기화
            // File 저장
            let coverImage_file = this.coverImage_file             // 커버이미지
            const cover_key = 'coverImage'
            const agency_key = 'agencyImage'
            let agencyImage_file = this.agencyImage_file           // 기획사 이미지
            this.popupOpen01('distribution_guide03',"saving") // loading open

            if(coverImage_file || agencyImage_file){
                const coverImage = new Promise(resolve =>{
                    if(coverImage_file) {
                        this.uploadfileAPi(this.coverImage_file)      // 커버 이미지 저장          
                        .then(result => {
                            this.params.defaultInfo[cover_key]  = result[0].id
                            resolve(true)
                        }).catch(error =>{
                            this.error.push(cover_key)
                        })
                    }else {resolve(true)}
                })

                const agencyImage = new Promise(resolve =>{
                    if(agencyImage_file) {
                        this.uploadfileAPi(agencyImage_file)      // 커버 이미지 저장          
                            .then(result => {
                                this.params.etc[agency_key]  = result[0].id
                                resolve(true)
                            }).catch(error =>{
                                this.error.push(agency_key)
                            })
                    }else resolve(true)
                })

                Promise.all([coverImage, agencyImage]).then(values => {
                    let state = "temporary" // 임시저장
                    let dataTemplate = this.distributionDataTemplate(this.params, this.trackParams, state)
                    this.distributionSaveApi(dataTemplate, state)
                })
            }else{
                let state = "temporary" // 임시저장
                let dataTemplate = this.distributionDataTemplate(this.params, this.trackParams, state)
                this.distributionSaveApi(dataTemplate, state)
            }
        },
        distributionDataTemplate(params, trackParams, state){
            // 음악 유통하기 저장하기
            // [S] parameter
            let parameter = {}
            parameter.contract = {}
            parameter["state"] = state
            if(params.defaultInfo["coverImage"] > 0) parameter["coverimage_id"] = params.defaultInfo["coverImage"]     // 커버 이미지                    // 커버이미지
            if(params.defaultInfo.genre[0] != undefined) parameter["genre1_id"] = params.defaultInfo.genre[0].id                                     // 장르1 아이디
            
            if(params.defaultInfo.genre[1] != undefined) parameter["genre2_id"] = params.defaultInfo.genre[1].id  // 장르2 아이디
            if(params.defaultInfo["barcode"]) parameter["barcode"] = params.defaultInfo["barcode"]                                     // UPC/EAN  코드
            parameter["barcode_generate"] = params.defaultInfo["barcode_generate"]                   // UPC/EAN  코드 신청여부

            if(params.defaultInfo["desired_release_date"] != null) parameter["desired_release_date"] = params.defaultInfo["desired_release_date"]           // 발매 희망일
            if(params.defaultInfo["album_name"] != null) parameter["name"] = params.defaultInfo["album_name"] // 앨범 이름
            if(params.defaultInfo["album_language"] != null) parameter["name_language"] = params.defaultInfo["album_language"]      // 앨범 이름 언어
            if(params.defaultInfo["album_name_en"] != null) parameter["name_english"] = params.defaultInfo["album_name_en"]      // 앨범 이름 영문
            if(params.defaultInfo["agencyName"] != null) parameter["label"] = params.defaultInfo["agencyName"]               // 기획사 이름
            if(params.defaultInfo["agencyName_language"] != null) parameter["label_language"] = params.defaultInfo["agencyName_language"]     // 기획사 이름 언어
            if(params.defaultInfo["agencyName_en"] != null) parameter["label_english"] = params.defaultInfo["agencyName_en"]          // 기획사 영어 이름

            if(params.etc["agencyImage"]) parameter["labelimage_id"] = params.etc["agencyImage"]         // 라벨이미지
            if(params.etc["introduce"]) parameter["description"] = params.etc["introduce"]        // 앨범 소개
            if(params.etc["introduce_language"]) parameter["description_language"] = params.etc["introduce_language"]    // 앨범 소개 언어
            if(params.etc["introduce_en"]) parameter["description_english"] = params.etc["introduce_en"]    // 앨범 소개 영문
            if(params.etc["original_release_date"]) parameter["original_release_date"] = params.etc["original_release_date"]      // 기발매일
            if(params.etc["credit"]) parameter["credit"] = params.etc["credit"]              // 앨범 크레딧
            parameter["artists"] = []
            params.defaultInfo.artists.map((artist,index) =>{
                parameter["artists"].push({
                    id : artist
                })
            })
         
            parameter.contract["territory"] = params.musicStore["countryRelease"]              // 앨범 발매 국가 선택

            if(params.musicStore["countryRelease"] != 'korea') {
                if(params.musicStore["countryExcept"][0]) parameter.contract["territory_exception"] = params.musicStore["countryExcept"][0]     //발매 제외 국가
            }
            
            // Track 정보
            parameter.tracks = []
            if(trackParams) {
                // delete trackParams[objectobject]
                if(trackParams.length > 0){
                    trackParams.map((param, index) =>{
                        let tracks = {}
                        tracks["genre1_id"] = param.track["genre"][0].id // 장르1 아이디
                        if(param.track["genre"][1]) tracks["genre2_id"] = param.track["genre"][1].id  // 장르2 아이디 */
                        
                        // 트랙 대표 아티스트
                        tracks["artists"] = []
                        param.track["artists"].map((artist,index) =>{
                            tracks["artists"].push({
                                id : artist.id
                            })
                        })

                        if(param.track["isrc"]) tracks["isrc"] = param.track["isrc"]             // ISRC 코드
                        tracks["isrc_generate"] = param.track["track_isrc_generate"]                   // ISRC 코드 신청여부
                        if(param.track["uci"]) tracks["uci"] = param.track["uci"]                // UCI 코드
                        tracks["uci_generate"] = param.track["track_uci_generate"]                     // UCI 코드 신청여부
                        tracks["name"] = param.track["trackName"]                                // track 이름
                        tracks["name_language"] = param.track["track_language"]                  // track 이름 언어
                        if(param.track["trackName_en"]) tracks["name_english"] = param.track["trackName_en"] // track 이름 영어
                        tracks["titletrack"] = param.track['title']                              // 트랙 타이틀 순위 1~2    0: normal

                        if(param.etc["type"]) tracks["type"] = param.etc["type"].toLowerCase()   // track 유형 추가
                        tracks["volume"] = index + 1                                             // track 소속된 볼륨 번호
                        tracks["tracknr"] = index + 1                                            // track Number 

                        if(param.etc["lyrics"]) tracks["lyrics"] = param.etc["lyrics"]                                   // track 가사
                        if(param.etc["lyrics_language"]) tracks["lyrics_language"] = param.etc["lyrics_language"]        // track 가사 언어

                        tracks["explicit"] = param.track["explicit"]                                                                // track 선정성
                        if(param.etc["featuring"]) tracks["featuring_artists"] = param.etc["featuring"]                             // track 피처링 아티스트
                        if(param.etc["featuring_language"]) tracks["featuring_artists_language"] = param.etc["featuring_language"]  // track 피처링 아티스트 언어
                        if(param.etc["featuring_en"]) tracks["featuring_artists_english"] = param.etc["featuring_en"]               // track 피처링 아티스트 영문

                        // track 작곡가, 작사가, 편곡가   composer, lyricist, arranger, producer
                        tracks.contributors = []
                        // 작곡가
                        param.track.composer.map((value, index) =>{
                            const obj = {
                                name: value.name,                              // track 작곡가, 작사가, 편곡가 이름
                                copyright_association: value.copyright_association,      // track 작곡가, 작사가, 편곡가 업체
                                role: 'composer'                         // track 작곡가, 작사가, 편곡가 종류
                            }
                            if(value.id) Object.assign(obj, {id: value.id})
                            tracks.contributors.push(obj)
                        })
                            // 작사가
                        param.etc.lyricist.map((value, index) =>{
                            const obj = {
                                name: value.name,                              // track 작곡가, 작사가, 편곡가 이름
                                copyright_association: value.copyright_association,      // track 작곡가, 작사가, 편곡가 업체
                                role: 'lyricist'                         // track 작곡가, 작사가, 편곡가 종류
                            }
                            if(value.id) Object.assign(obj, {id: value.id})
                            tracks.contributors.push(obj)
                        })
                            //편곡가
                        param.etc.arranger.map((value, index) =>{
                            const obj = {
                                name: value.name,                              // track 작곡가, 작사가, 편곡가 이름
                                copyright_association: value.copyright_association,      // track 작곡가, 작사가, 편곡가 업체
                                role: 'arranger'                         // track 작곡가, 작사가, 편곡가 종류
                            }
                            if(value.id) Object.assign(obj, {id: value.id})
                            tracks.contributors.push()
                        })

                        // 음원 file
                        tracks["files"] = []    // track file 아이디
                        param.audio.map(file =>{
                            tracks["files"].push({
                                id: file.id
                            })
                        })
                        parameter.tracks.push(tracks)
                    })
                }
            }
            
            return parameter
        },
        distributionDataTemplate_reverse(parameter){
            // 음악 유통하기 저장하기
            // [S] params
            // 이미지 관련
            this.params.defaultInfo["coverImage"] = parameter["coverimage_id"]                                // 커버이미지
            if(parameter["labelimage_id"]) this.params.etc["agencyImage"] = parameter["labelimage_id"]        // 라벨이미지

            if(parameter["name"] != null) this.params.defaultInfo["album_name"] = parameter["name"] // 앨범 이름
            if(parameter["name_language"] != null) this.params.defaultInfo["album_language"] = parameter["name_language"]      // 앨범 이름 언어
            if(parameter["name_english"] != null) this.params.defaultInfo["album_name_en"] = parameter["name_english"]         // 앨범 이름 영문

            if(parameter["artists"].length > 0){
                parameter["artists"].map(artist => {
                    this.params.defaultInfo.artists.push(artist.id)
                    this.representative_artist.push(artist)
                })
            }else{
                this.params.defaultInfo.artists = []
            }
            // 대표 장르
            if(parameter["genre1_id"] != undefined) {
                this.loadGenreList(parameter["genre1_id"])
                    .then(result =>{
                        this.params.defaultInfo.genre.push(result.items[0])
                    })
            }  // 장르1 아이디 Track 필수 값
            else{
                this.params.defaultInfo.genre = []
            }

            if(parameter["genre2_id"] != undefined) {
                this.loadGenreList(parameter["genre2_id"])
                    .then(result =>{
                        this.params.defaultInfo.genre.push(result.items[0])
                    })
            }

            this.params.defaultInfo["barcode"] = parameter["barcode"]                                              // UPC/EAN  코드
            this.params.defaultInfo["barcode_generate"] = parameter["barcode_generate"]                            // UPC/EAN  코드 신청여부
            if(parameter["barcode_generate"]) {
                document.getElementById('barcode').classList.add('input_fitem_disabled')
                document.getElementById('barcode').readOnly = true
            }

            if(parameter["label"] != null) this.params.defaultInfo["agencyName"] = parameter["label"]                                // 기획사 이름
            if(parameter["label_language"] != null) this.params.defaultInfo["agencyName_language"] = parameter["label_language"]     // 기획사 이름 언어
            if(parameter["label_english"] != null) this.params.defaultInfo["agencyName_en"] = parameter["label_english"]             // 기획사 영어 이름

            if(parameter["credit"]) {
                this.params.etc["credit"] = parameter["credit"]
                this.credit = parameter["credit"]
            }                                          // 앨범 크레딧

            if(parameter["original_release_date"]) this.original_release_date = parameter["original_release_date"]             // 기발매일

            if(parameter["description"]) {
                this.params.etc["introduce"] = parameter["description"]
                this.introduce = parameter["description"]
            }                               // 앨범 소개
            if(parameter["description_language"]) this.params.etc["introduce_language"] = parameter["description_language"]    // 앨범 소개 언어
            if(parameter["description_english"]) {
                this.introduce_en = parameter["description_english"]
            }            // 앨범 소개 영문

            if(parameter.contract["territory"]) this.params.musicStore["countryRelease"] = parameter.contract["territory"]                        // 앨범 발매 국가 선택
            if(parameter.contract["territory_exception"]) this.params.musicStore["countryExcept"][0] = parameter.contract["territory_exception"]  //발매 제외 국가
            if(parameter["desired_release_date"] != null) this.desired_release_date = parameter["desired_release_date"]                           // 발매 희망일

            // Track 정보
            let trackParams = {}
            trackParams.tracks = []
            parameter.tracks.map((track, index) => {
                let param = {}
                param.track = {}
                param.track["isrc"] = track["isrc"]                                      // ISRC 코드
                param.track["track_isrc_generate"] = track["isrc_generate"]              // ISRC 코드 신청여부
                param.track["uci"] = track["uci"]                                        // UCI 코드
                param.track["track_uci_generate"] = track["uci_generate"]                // UCI 코드 신청여부
                param.track["trackName"] = track["name"]                                 // track 이름
                param.track["track_language"] = track["name_language"]                   // track 이름 언어
                param.track["trackName_en"] = track["name_english"]                      // track 이름 영어
                param.track['title'] = track["titletrack"]                               // 트랙 타이틀 순위 1~2    0: 
                param.track["explicit"] = track["explicit"]                              // track 선정성

                // 트랙 아티스트
                param.track.artists = []
                param.representative_artist = ""
                if(track["artists"].length > 0){
                    track["artists"].map(artist => {
                        param.track.artists.push(artist)
                        param.representative_artist =param.representative_artist + artist.display_name +", "
                    })

                    let charCheck = param.representative_artist.lastIndexOf(", ") 
                        if(charCheck > -1){
                            param.representative_artist = param.representative_artist.substring(0, param.representative_artist.length-2)
                        }
                }else{
                    param.track.artists = []
                }
    
                // 트랙 장르
                param.track.genre = []
                if(track["genre1_id"]) {
                    this.loadGenreList(track["genre1_id"])
                        .then(result =>{
                            param.track.genre.push(result.items[0])
                        })
                }  // 장르1 아이디 Track 필수 값

                if(track["genre2_id"]) {
                     this.loadGenreList(track["genre2_id"])
                        .then(result =>{
                            param.track.genre.push(result.items[0])
                        })
                     // 장르2 아이디
                }
              
                param.etc = {}
                param.etc["type"] = track["type_etc"]                                    // track 유형 추가
                param.etc["lyrics"] = track["lyrics"]                                    // track 가사
                param.etc["lyrics_language"] = track["lyrics_language"]                  // track 가사 언어
                param.etc["featuring"] = track["featuring_artists"]                      // track 피처링 아티스트
                param.etc["featuring_language"] = track["featuring_artists_language"]    // track 피처링 아티스트 언어
                param.etc["featuring_en"] = track["featuring_artists_english"]           // track 피처링 아티스트 영문

                // track 작곡가, 작사가, 편곡가   composer, lyricist, arranger, producer
                param.track.composer = []
                param.etc.lyricist = []
                param.etc.arranger = []
                // 작곡가
                track.contributors.map((value, index) =>{
                    if(value.role == 'composer') {
                        // 작곡가
                        let composer = {}
                        composer['id'] = value.id
                        composer['name'] = value.name
                        composer['copyright_association'] = value.copyright_association
                        /* composer[value.name] = value.copyright_association */
                        param.track.composer.push(composer)
                    }else if(value.role == 'lyricist') {
                        // 작사가
                        let lyricist = {}
                        lyricist['id'] = value.id
                        lyricist['name'] = value.name
                        lyricist['copyright_association'] = value.copyright_association
                        /* lyricist[value.name] = value.copyright_association */
                        param.etc.lyricist.push(lyricist)
                    }else if(value.role == 'arranger') {
                        // 편곡가
                        let arranger = {}
                        arranger["id"] = value.id
                        arranger['name'] = value.name
                        arranger['copyright_association'] = value.copyright_association
                        /* arranger[value.name] = value.copyright_association */
                        param.etc.arranger.push(arranger)
                    }
                })

                // 음원 file
                param.audio = []
                track["files"].map((file, index) =>{
                    param.audio.push(file)
                })
                trackParams.tracks.push(param)
            })

            this.trackParams = trackParams
        },
        distributionSaveApi(params, state){
            // 음악 유통하기 저장하기
            if(this.onSubmitType  == "post"){
                this.$store.dispatch('SAVEALBUM', params)
                    .then(result => {
                        switch(state) {
                            case "registration_complete":
                                this.$EventBus.$emit("navi", "page_position",'album')
                                this.$router.push('/album') // 작동
                                break;
                        }
                    })
                    .catch(error => {
                        this.popupClose04("distribution_guide03") // loading close
                    })
            }else if(this.onSubmitType == "put") {
                let id = this.$route.params.album_id
                this.$store.dispatch('UPDATEALBUM', {id, params})
                    .then(result => {
                        this.popupClose04("distribution_guide03") // loading close
                        switch(state) {
                            case "registration_complete":
                                this.$EventBus.$emit("navi", "page_position",'album')
                                this.$router.push('/album')
                                break;
                        }
                    })
                    .catch(error => {
                        this.popupClose04("distribution_guide03") // loading close
                    })
            }
        },
        emptyCheck(params){
            const main_categoryKeys = Object.keys(params).filter(key =>{
                return this.requireFilter.indexOf(key) === -1
            })  // 메인 카테고리 Keys
            const emptyType = null

            return new Promise(resolve =>{
                main_categoryKeys.map(main_key =>{
                    let sub_categoryKeys  = Object.keys(params[main_key]) // 서브 카테고리 Keys
                    let sub_category = params[main_key]                   // 서브 카테고리
                    
                    // 트랙 정보 존재여부 체크
                    if(this.trackParams.length > 0){
                        if(!this.trackParams[0].track.trackName) {
                            if(!(this.error.indexOf('track') > -1)) this.error.push('track')
                        }
                    }else {
                        if(!(this.error.indexOf('track') > -1)) this.error.push('track')
                    }
                    
                    sub_categoryKeys.map(sub_key =>{
                        let value = sub_category[sub_key]
                        if(this.checkboxFilter.indexOf(sub_key) >-1) {
                            if(!value) {
                                this.error.push(sub_key)
                            }
                        }else{
                            console.log("sub_categoryKeys :::", emptyType, sub_key, value)
                            this.$store.dispatch('EMPTYCHECK', { emptyType: emptyType, params: value })
                            .then((result) => {
                                console.log("sub_categoryKeys01 :::", result,sub_key,value)
                                if(result) {
                                    if(sub_key == 'coverImage') {
                                        //커버 이미지
                                        if(!this.coverImage_file) {
                                            if(this.error.indexOf("coverImage") == -1) {
                                                this.error.push("coverImage")
                                            }
                                        }
                                    }else if(sub_key == 'album_name_en') {
                                        // 영문 앨범명
                                        if(!sub_category['album_name_en']){
                                            if(!sub_category['album_name_en_generate']) {
                                                if(this.error.indexOf("album_name_en") == -1) {
                                                    this.error.push("album_name_en")
                                                }
                                            }
                                        }
                                    }else if(sub_key == 'album_name_en_generate') {
                                        if(!sub_category['album_name_en_generate']){
                                            if(!sub_category['album_name_en']) {
                                                if(this.error.indexOf("album_name_en") == -1) {
                                                    this.error.push("album_name_en")
                                                }
                                            }
                                        }
                                    }else if(sub_key == 'agencyName_en') {
                                        if(!sub_category['agencyName_en']){
                                            if(!sub_category['agencyName_en_generate']) {
                                                if(this.error.indexOf("agencyName_en") == -1) {
                                                    this.error.push("agencyName_en")
                                                }
                                            }
                                        }
                                    }else if(sub_key == 'agencyName_en_generate') {
                                        if(!sub_category['agencyName_en_generate']){
                                            if(!sub_category['agencyName_en']) {
                                                if(this.error.indexOf("agencyName_en") == -1) {
                                                    this.error.push("agencyName_en")
                                                }
                                            }
                                        }
                                    }else if(sub_key == 'barcode') {
                                        if(!sub_category['barcode']){
                                            if(!sub_category['barcode_generate']) {
                                                if(this.error.indexOf("barcode") == -1) {
                                                    this.error.push("barcode")
                                                }
                                            }
                                        }
                                    }else if(sub_key == 'barcode_generate') {
                                        if(!sub_category['barcode_generate']){
                                            if(!sub_category['barcode']) {
                                                if(this.error.indexOf("barcode") == -1) {
                                                    this.error.push("barcode")
                                                }
                                            }
                                        }
                                    }else if(sub_key == "desired_release_date"){
                                        if(!this.desired_release_date_datechk){
                                            if(this.error.indexOf(sub_key) == -1) {
                                                this.error.push(sub_key)
                                            }
                                        }
                                    }else if(sub_key != 'countryExcept'){
                                        if(this.error.indexOf(sub_key) == -1) {
                                            this.error.push(sub_key)
                                        }
                                    }
                                }
                            })
                        }
                    })
                    resolve(this.error.length)
                })
            }).then(result =>{
                console.log("then::", result)
                if(result > 0) return false
                else return true
            })
        },
        loadArtistList(){
            let sort = null
            let sortorder = null
            let page = null
            let column = null

            this.$store.dispatch('LOADARTIST', { sort, sortorder, page, column })
                .then(result =>{
                    let artistData = result.items.map((value, index) =>{
                        let name = value.display_name
                        let id = value.id
                        return {
                            id: id,
                            name: name
                        }
                    })
                    if(this.click.artist_click){
                        this.click.artist_click = !this.click.artist_click
                        this.showPanel15(artistData)
                    }
                }).catch(error =>{
                    this.showPanel2()
                })
         
        },
        loadGenreList(id){
            let sort = null
            let sortorder = null
            let page = null
            let column = {}
            column["id"] = id
            return this.$store.dispatch('LOADGENRE', { sort, sortorder, page, column })
                    .then(result =>{
                        return result
                    })
        },
        showPanel15(){
            // const panel = this.$showPanel({
            //     component: "panel-15",
            //     width: '740px',
            //     openOn: 'right',
            //     /* cssClass: "panel-zindex", */
            //     /* keepAlive: true, */
            //     props: {
            //         returnName: this.eventName,
            //         currentData: this.representative_artist,
            //         returnKey: this.artist_result_eventName
            //     }
            // })

            // panel.promise.then(result =>{
            //     this.click.artist_click = true
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-15",
                props: {
                     returnName: this.eventName,
                    currentData: this.representative_artist,
                    returnKey: this.artist_result_eventName
                }
            },()=>{
                this.click.artist_click = true
            });
        },
        /* showPanel13(genre){
            const panel = this.$showPanel({
                component: "panel-13",
                width: '740px',
                openOn: 'right',
                cssClass: "panel-zindex",
                props: {
                    listData: genre,
                    returnName: "representative_genre",
                    currentData: this.params.defaultInfo.artists
                }
            })
        }, */
        genre_register() {
            if(this.click.genre_click){
                this.click.genre_click = !this.click.genre_click
                this.showPanel16()
            }
        },
        showPanel14(){
            // const panel = this.$showPanel({
            //     component: "panel-14",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-14",
                props: {
                    
                }
            },()=>{
                
            });
        },
        showPanel16(){
            // const panel = this.$showPanel({
            //     component: "panel-16",
            //     width: '740px',
            //     openOn: 'right',
            //     /* cssClass: "panel-zindex", */
            //     /* keepAlive: true, */
            //     props: {
            //         returnName: "representative_genre",
            //         currentData: this.params.defaultInfo.genre
            //     }
            // })

            // panel.promise.then(result =>{
            //     this.click.genre_click = true
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-16",
                props: {
                    returnName: "representative_genre",
                    currentData: this.params.defaultInfo.genre
                }
            },()=>{
                this.click.genre_click = true
            });
        },
        showPanel2(){
            // const panel = this.$showPanel({
            //     component: "panel-2",
            //     width: '740px',
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-2",
                props: {
                }
            },()=>{
                
            });
        },
        sbcPopupTogFunc(event){
            this.add_albumInfo? this.add_albumInfo = false : this.add_albumInfo = true
            // let id = document.getElementById('add_etc')

            // switch(id.style.display){
            //     case "none":
            //         /* id.style.display = "block" */
            //         this.add_albumInfo = true
            //         break;
            //     default :
            //         /* id.style.display = "none"; */
            //         this.add_albumInfo = false
            //         break;
            // }
        },
        rejectedPopupTogFunc(event){
            this.rejections_msg? this.rejections_msg = false : this.rejections_msg = true
      /*       let nextNode = event.target.nextElementSibling
            let parentNextNode = event.target.parentNode.nextElementSibling

            rejections_msg

             switch(nextNode.style.display){
                case "none":
                    nextNode.style.display = "block"
                    break;
                default :
                    nextNode.style.display = "none"
                    break;
            } */
        },
        dateFormat(value){
            return value.split("T")[0]
        },
        codeCheck(event) {
            // 현재 사용 보류중 : checkboxImpl <- 대체 사용중
            // UCI, IRSC Code Check
            let id = document.getElementById(event.target.id + "_code")
            let checkbox = this.params.defaultInfo[event.target.id + '_generate']  // v-model  barcode_generate
            // disabled
            if(checkbox) {
                if(event.target.id === 'datechk'){
                    this.desired_release_date_datechk = true
                }else{
                    id.readOnly = false
                    id.classList.remove('input_fitem_disabled')
                }
            }else {
                if(event.target.id === 'datechk'){
                    this.desired_release_date_datechk = false
                }else{
                    id.readOnly = true
                    id.classList.add('input_fitem_disabled')
                }
            }
        },
        checkboxImpl(event) {
            // UCI, IRSC Code Check
            let idName = event.target.id
            let id = idName.substring(0, event.target.id.lastIndexOf('_generate'))

            let el = document.getElementById(id)
            let checkbox = this.params.defaultInfo[idName]  // v-model  barcode_generate
            this.checkbox_errorMessage(id)
            // disabled
            if(checkbox) {
                if(id === 'desired_datechk'){
                    this.desired_release_date_datechk = true
                }else{
                    el.readOnly = false
                    el.classList.remove('input_fitem_disabled')
                }
            }else {
                if(id === 'desired_datechk'){
                    this.desired_release_date_datechk = false
                }else{
                    el.readOnly = true
                    el.classList.add('input_fitem_disabled')
                }
            }
        },
        dateTimeCrop(param){
            return this.$store.dispatch('DATETIMECROP', param)
                .then((result) => {
                    return result
                })               
        },
        uploadfileAPi(file) {
            // 음악 판매하기 images 파일 업로드
            let eventName = this.fileUpload_eventName
            let returnValue = null   // return 변수
            return this.$store.dispatch(eventName, file)
        },
        artist_implement(params){
            this.representative_artist =[] // 초기화
            params.map(param =>{
                this.params.defaultInfo.artists.push(param.id)
                this.representative_artist.push(param)
            })
        },
        errorMessage(event){
            const id = event.target.id
            const error = this.error.indexOf(id)
            if(error > -1){
                this.error.splice(error, 1);
            }
        },
        errorMessage_type2(value){
            const error = this.error.indexOf(value)
            if(error > -1){
                this.error.splice(error, 1);
            }
        },
        checkbox_errorMessage(value){
            //checkbox 체크시 에러 메세지 삭제 처리

            // 발매 희망일 관련 별도 처리 : 추후 개선 예정
            if(value == 'desired_datechk') 
            value = 'desired_release_date'

            console.log("checkbox_errorMessage:::", value)

            const error = this.error.indexOf(value)
            console.log("checkbox_errorMessage01:::", error)
            if(error > -1){
                console.log("checkbox_errorMessage02:::", this.error)
                this.error.splice(error, 1);
                console.log("checkbox_errorMessage03:::", this.error)
            }       
        },
        popupOpen(id, title){
            // popup Open
            console.log("popupOpen00 ::", id)
            let popup = document.getElementById(id)
            console.log("popupOpen01 ::", popup)
            popup.classList.add('type2')
            console.log("popupOpen02 ::", popup)
            this.guideMessage = title
            console.log("popupOpen03 ::", this.guideMessage)
    
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupOpen01(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            this.guideMessage = title
            /* this.typingBool = true */
            /* this.typing() */
            this.typingImpl()
         
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupClose01(event){
            // licensor popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')

          setTimeout(function(){
             popup.classList.remove('type2')
          }, 300)
          this.$EventBus.$emit("navi", "page_position",'my')
          this.$router.push('/my')
        },
        popupClose02(event){
            // save popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
             popup.classList.remove('type2')
          }, 300)
        },
        popupClose03(event){
            // comleted popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
             popup.classList.remove('type2')
          }, 300)
          this.onsubmit()
        },
        popupClose04(id){
            // comleted popup close
            let popup = document.getElementById(id)
            popup.firstChild.classList.remove('type2')
            /* this.typingBool = false */
            console.log("popupClose04:::",this.typingBool)
            setTimeout(function(){
                popup.classList.remove('type2')
            }, 500)
        },
  /*       typing(){
            console.log("typing:::",this.typingBool)
            if(this.typingBool){
                this.typingImpl()
            }else {
                console.log("typing01:::",this.typingBool)
                clearInterval(this.typingImpl()); //끝나면 반복종료
                console.log("typing02:::",clearInterval(this.typingImpl()))
                return
            }  
        }, */
        typingImpl(){
            let typingIdx = 0
            let typingTxt = this.getMessage.split("") // // 타이핑될 텍스트를 가져온다 한글자씩 자른다.
            document.getElementById("typing").innerText = "" //초기화

            const typing = setInterval(function(){
                if(typingIdx < typingTxt.length){ // 타이핑될 텍스트 길이만큼 반복
                    if(document.getElementById("typing")) document.getElementById("typing").innerText += typingTxt[typingIdx] // 한글자씩 이어준다.
                    typingIdx++
                }else{
                    clearInterval(typing)
                }
            },150); // 반복동작 
        }
    }
}
</script>

<style scoped>
#distributionMusic {
    margin-top: 90px;
}

#trackListTable {
    height: 500px;
    background-color: #f2f2f2;
    padding: 20px;
    overflow: auto;
}

.artistSelect {
    cursor: pointer;
}

/* [S] Title Animation */
.sv_z .bg{
  background-image:url('../../../assets/images/sv_img.png');
  background-repeat:no-repeat;
  background-position:50% 0;
  transform: scale(1.6);
  background-size:cover;
  transition:1.2s;
  display:block;
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
}

.sv_z .sv_copy{
  transition-delay: .5s; 
  transition-duration: .8s;
  transform: translateX(-80px);
  opacity:0;
  filter:alpha(opacity=100);
}

.sv_z .sv_copy2{
  transition-delay: .7s;
  transition-duration: .8s;
  transform: translateX(-100px);
  opacity:0;
  filter:alpha(opacity=100);
}

.action .sv_z .bg{transform: scale(1) !important;}
.action .sv_z .sv_copy{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
.action .sv_z .sv_copy2{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
/* [E] Title Animation */


.fontBold{
    font-weight:bold;
}

.non_list_style:before{
    background:none !important;
}

.artistStyle01{
    height: 40px;
    line-height: 40px;
}

.completed_guide{
    font-size: 16px;
    margin-top: 10px;
    color: #0F73EB;
}

/* Typing */
p.typing-txt{display: none;}
  
.typing {  
    display: inline-block; 
    animation-name: cursor; 
    animation-duration: 0.3s; 
    animation-iteration-count: infinite; 
} 

@keyframes cursor{ 
    0%{border-right: 1px solid #fff} 
    50%{border-right: 1px solid #000} 
    100%{border-right: 1px solid #fff} 
}

/* [S] document Style */
.doc_title{
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -1px;
}

.doc_contents{
    word-break: keep-all;
    margin-bottom: 20px;
}

.cursorPointer{
    cursor: pointer;
}
/* [E] document Style */

/* [v-cloak]::before {
  content: '로딩중...'
}
[v-cloak] > * {
  display:none;
} */

</style>