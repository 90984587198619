<template src="./template.html"></template>

<script>
import draggable from "vuedraggable";

let id = 1;
export default {
  name: "simple",
  display: "Simple",
  order: 0,
  components: {
    draggable
  },
  data() {
    return {
      enabled: true,
      list: [
        { name: "John", id: 0 },
        { name: "Joao", id: 1 },
        { name: "Jean", id: 2 }
      ],
      dragging: false
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  methods: {
    add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
    },
    replace: function() {
      this.list = [{ name: "Edgard", id: id++ }];
    },
    checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    }
  }
};

</script>

<style scoped>
.tableHeader{
    width: 100%;
    border: 1px #ff0000 solid;
    overflow: hidden;
}

.tableHeader li{
    float: left;
    margin-left: 1rem;
    min-width: 14%;
}

.list-group-item{
    background-color: #fff;
    border:1px #ff0000 solid;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>