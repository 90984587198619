<template src="./template.html"></template>

<script>
export default {
    name: 'Login',
    data(){
        return {
            description: '로그인 페이지 입니다..',
            email: '',
            password: '',
            cookieResult: "",
            pwdSave: false,
            exdays : 14,
            cookiePassword : "soundRepublicaPwd",
            error: []             // Error Message
        }
    },
    created(){
        let cookie = this.getCookie(this.cookiePassword)
        if(cookie){
            this.password = cookie
            this.pwdSave = true
        }
    },
    watch: {
        pwdSave(){
            //비밀번호 저장 여부 체크
            if(!this.pwdSave) {
                const cookieName = this.cookiePassword
                this.delCookie(cookieName)
            }
        },
        error(){
            console.log(this.error);
        }
    },
    methods: {
        login(e) {
            const pwdSave = this.pwdSave // 비밀번호 저장 Result
            const email = this.email;
            const password = this.password
            const cookieName = this.cookiePassword
            
            // Get Password Cookie 
            let cookie = this.getCookie(cookieName)
            if(pwdSave){
                this.setCookie(cookieName, password, this.exdays)
            }

            // LOGIN 액션 실행
            if(email === '' || password === ''){
                this.error.push('email')
                if(password===''){
                    this.error.push('password')
                    return false;
                }
                return false;
            }
            
            this.loadingShow()
            this.$store.dispatch('LOGIN', {email, password}).then(userInfo => {
                this. loadingHide()
                this.$store.commit('LOGIN', userInfo)
                this.localeChange(userInfo.language)       // 저장한 언어변경
                // 로그인 성공 EventBus 발생
                let params = localStorage.isAuthenticate
                this.$EventBus.$emit("navi", "login_access",params)
                this.$router.push('/my')
            })
            .catch((message) => {
                this. loadingHide()
                const errorResponseData = message.response.data
                // 51
                if(!errorResponseData){
                    this.error.push('login')
                }else{
                    const errorCode = errorResponseData.code
                    this.error = [];
                    switch(errorCode) {
                        case 50:                                // Email Fail
                            this.error.push('email')
                            break;
                        case 51:
                            this.error.push('auth_email')       // authentication mail check
                            break;
                        case 52:                                // Password Fail
                            this.error.push('password')
                            break;
                        case 102:                               // Password Fail
                            this.error.push('password')
                            break;
                        default:
                            this.error.push('email')
                            this.error.push('password')
                            break;
                    }
                    this.msg = message
                }
            })
        },
        redirect() {
            const search = window.location.href
        
            if (search ==='') {
                this.$router.push('/')
            } else {
                const tokens = search.replace(/^\?/, '').split('&')
                alert(qs, tkn)
                const returnPath = tokens.reduce((qs, tkn) => {
                    alert(qs, tkn)
                    const pair = tkn.split('=')
                    qs[pair[0]] = decodeURIComponent(pair[1])
                    return qs
                }, {})
            this.$router.push(returnPath)
            }
        },
        delCookie(cookieName){
            // 쿠키 삭제
            this.$cookie.delete(cookieName);
        },
        setCookie(cookieName, value, exdays){
            // 쿠키 저장
            /* https://my.soundrepublica.com/ 권리자사이트 */
            this.$cookie.set(cookieName, value,{expires: exdays});
        },
        getCookie(cookieName){
            // 쿠키 가져오기
            const cookieResult = this.$cookie.get(cookieName);
            return cookieResult
        },
        errorMessage(event){
            // Error Message Hide
            const id = event.target.id
            const error = this.error.indexOf(id)
            //arr.splice(arr.indexOf("A"),1);
            while(true){
                let search = this.error.indexOf(id)
                if(search!=-1){
                     this.error.splice(search, 1); // "A"를 찾아서 삭제한다.
                }else{
                    break;
                }
            }
        },
        localeChange(code){
            // 언어 코드 변경
            this.$i18n.locale = code
            this.$store.commit('LANGUAGECODE', code)
        },
        pwd_openBtn(e){
            const target = e.target.previousSibling
            e.target.classList.toggle("active")
            if(target.type == 'password') {
                target.type = 'text'
            }else if(target.type == 'text') {
                target.type = 'password'
            }
        }
    }
}
</script>

<style scoped>
.splayout_z {
    background-image:url('../../../assets/images/bg_login.jpg');
}
</style>

