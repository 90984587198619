<template src="./template2.html"></template>

<script>

import draggable from "vuedraggable";
import i18n from '../../../library/i18n' // 다국어지원

export default {
    name: "transition-example",
    display: "Transitions",
    props: ['eventName', 'returnKey', "listData"],
    order: 7,
    data(){
        return {
            options: {
                dropzoneSelector: 'ul',
                draggableSelector: 'li',
                handlerSelector: null,
                reactivityEnabled: true,
                multipleDropzonesItemsDraggingEnabled: false,
                showDropzoneAreas: true
            },
            list: [],
            drag: false,
            listCount: 0,
            representative_artist: null,                        // 아티스트 이름들
            data01: {
                view: false,                                    // 테스트 v-if
                order: null,                                    // Track 순서
                track: {
                    explicit: null,                             // 선정성 여부 true, false
                    track_language: null,                       // 트랙명 언어종류
                    trackName: null,                            // 노래 제목  String
                    trackName_en: null,                         // 영문 노래제목
                    artists: [],                                // 가수 이름
                    type: null,                                 // 유형 
                    title: 0,                                   // 타이틀곡 여부 0 : 타이틀곡 아님, 1: 1번째 타이틀곡, 2: 2번째 타이틀곡
                    genre: null,                                // 장르
                    composer: [],                               // 작곡가 { 작곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                    isrc: null,                                 // ISRC 코드
                    track_isrc_generate: false,                 // ISRC 코드 신청여부
                    uci: null,                                  // UCI 코드
                    track_uci_generate: false                   // UCI 코드 신청
                },
                etc: {
                   type: null,                                  // 트랙 유형
                   featuring_language: null,                    // 피처링아티스트 이름 언어
                   featuring: null,                             // 피처링 아티스트 이름
                   featuring_en: null,                          // 피처링 아티스트 영문이름
                   lyricist: [],                                // 작사가 { 작사가명 : 저작권관리 업체명}  management: 저작권 관리업체
                   arranger: [],                                // 편곡가 { 편곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                   lyrics_language: null,                       // 노래 가사 언어 종류
                   lyrics : null                                // 노래 가사
                },
                audio: []                                       // 음원파일 정보
            },
            defaultData: {
                view: false,                                    // 테스트 v-if
                order: null,                                    // Track 순서
                track: {
                    explicit: false,                            // 선정성 여부 true, false
                    track_language: null,                       // 트랙명 언어종류
                    trackName: null,                            // 노래 제목  String
                    trackName_en: null,                         // 영문 노래제목
                    artists: [],                                // 가수 이름
                    type: null,                                 // 유형 
                    title: 0,                                   // 타이틀곡 여부 0 : 타이틀곡 아님, 1: 1번째 타이틀곡, 2: 2번째 타이틀곡
                    genre: null,                                // 장르
                    composer: [],                               // 작곡가 { 작곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                    isrc: null,                                 // ISRC 코드
                    track_isrc_generate: false,                 // ISRC 코드 신청여부
                    uci: null,                                  // UCI 코드
                    track_uci_generate: false                   // UCI 코드 신청
                },
                etc: {
                   type: null,                                  // 트랙 유형
                   featuring_language: null,                    // 피처링아티스트 이름 언어
                   featuring: null,                             // 피처링 아티스트 이름
                   featuring_en: null,                          // 피처링 아티스트 영문이름
                   lyricist: [],                                // 작사가 { 작사가명 : 저작권관리 업체명}  management: 저작권 관리업체
                   arranger: [],                                // 편곡가 { 편곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                   lyrics_language: null,                       // 노래 가사 언어 종류
                   lyrics : null                                // 노래 가사,
                },
                audio: []                                       // 음원파일 정보
            },
            defaultData01: {
                view: false,                                    // 테스트 v-if
                order: null,                                    // Track 순서
                track: {
                    explicit: false,                            // 선정성 여부 true, false
                    track_language: null,                       // 트랙명 언어종류
                    trackName: null,                            // 노래 제목  String
                    trackName_en: null,                         // 영문 노래제목
                    artists: [],                                // 가수 이름
                    type: null,                                 // 유형 
                    title: 0,                                   // 타이틀곡 여부 0 : 타이틀곡 아님, 1: 1번째 타이틀곡, 2: 2번째 타이틀곡
                    genre: null,                                // 장르
                    composer: [],                               // 작곡가 { 작곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                    isrc: null,                                 // ISRC 코드
                    track_isrc_generate: false,                 // ISRC 코드 신청여부
                    uci: null,                                  // UCI 코드
                    track_uci_generate: false                   // UCI 코드 신청
                },
                etc: {
                   type: null,                                  // 트랙 유형
                   featuring_language: null,                    // 피처링아티스트 이름 언어
                   featuring: null,                             // 피처링 아티스트 이름
                   featuring_en: null,                          // 피처링 아티스트 영문이름
                   lyricist: [],                                // 작사가 { 작사가명 : 저작권관리 업체명}  management: 저작권 관리업체
                   arranger: [],                                // 편곡가 { 편곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                   lyrics_language: null,                       // 노래 가사 언어 종류
                   lyrics : null                                // 노래 가사
                },
                audio: []                                       // 음원파일 정보
            },
            trackInfo_eventName: "trackInfo",                   // Track 정보
            testResult:false,                                   // 테스트
            error: false,                                       // Error
            getMessage: null,                                   // message
            click: {
                track_click: true
            }
        }
    },
    created() {
        this.$EventBus.$on(this.trackInfo_eventName, (params, trackNumber) => {
            let current_artists = params[trackNumber].track.artists
            if(current_artists.length > 0) {
                this.representative_artist = "" //초기화
                current_artists.map(param =>{
                    this.representative_artist = this.representative_artist + param.display_name +", "
                })
                let charCheck = this.representative_artist.lastIndexOf(", ")
                if(charCheck > -1){
                    this.representative_artist = this.representative_artist.substring(0, this.representative_artist.length-2)
                    params[trackNumber].representative_artist= this.representative_artist
                }
                this.$EventBus.$emit(this.eventName, params, this.returnKey)
                this.$set(this.list, params)
            }  
        })
    },
    components: {
        draggable
    },
    watch: {
        representative_artist(){
            return this.representative_artist
        },
        list(){
            this.$EventBus.$emit(this.eventName, this.list, this.returnKey)
            return this.list
        },
        
        listData(){
            // Track Modify
            if(this.listData.tracks) {
                this.list = this.listData.tracks
                this.listCount = this.list.length
            }
        },
        listCount(){
            return this.listCount
        }
    },
    computed: {
        draggingInfo() {
            return this.dragging ? "under drag" : "";
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        } ,
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const none_track = this.$i18n.t("components.dragAndDropTable.trackList.msg.none_track")
                const data_copy = this.$i18n.t("components.dragAndDropTable.trackList.fail.data_copy")

                const messageCollection = { 
                    none_track: none_track,
                    data_copy: data_copy
                }
                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        }
    },
    methods: {
        insertItem(event,index){
            const list = this.list
            let itemData = list.slice(index, index+1);
            // 데이터 존재 여부 체크
            const params = itemData[0].track.trackName
            const emptyType = null
            this.$store.dispatch('EMPTYCHECK', { emptyType, params})
                .then((result) => {
                    if(!result) {
                        this.list.splice(index+1, 0, this.templateData(itemData[0]));
                        this.list[index+1].track.title = 0 // 타이틀 초기화
                        this.listCount +=1
                    }else {
                        this.error = true
                        this.guideMessage = "data_copy"
                    }
            })
        },
        deleted(event,index){
            let listItem = event.target.closest("ul").closest("li");
            listItem.classList.add("testCSS");
            setTimeout(function(){
                listData.splice(index, 1);
                listItem.classList.remove("testCSS");
            }, 600)
        },
        track_open(trackNumber) {
            if(this.click.track_click){
                this.click.track_click = !this.click.track_click
                this.showPanel9(trackNumber)
            }
        },
        showPanel9(trackNumber){
            this.error = false // 에러 초기화
            // const panel = this.$showPanel({
            //     component: "panel-9",
            //     width: "740px",
            //     openOn: 'right',
            //     props: {
            //         eventName: this.trackInfo_eventName,
            //         trackNumber: trackNumber,
            //         trackData: this.list
            //     }
            // })

            // panel.promise.then(result =>{
            //     this.click.track_click = true
            // })

            this.$EventBus.$emit('openPanel',{
                component: "panel-19",
               props: {
                    eventName: this.trackInfo_eventName,
                    trackNumber: trackNumber,
                    trackData: this.list
                }
            });
            this.click.track_click = true
        },
        add_li() {
            const listCount = this.listCount
            const list = this.list
            const size = list.length
            const index = list.length-1

            if(size === listCount && size === 0) {
                this.list[size] = this.templateData()
                this.listCount += 1
            }else if(size === listCount && size > 0){       
                if(!this.list[index].track.trackName){
                    this.error = true
                    this.guideMessage = "none_track"
                    return false
                }else{
                    this.list[size] = this.templateData()
                    this.listCount += 1
                }
            }
        },
        delete_li(event,index){
            let listItem = event.target.closest("ul").closest("li");
            let list = this.list
            listItem.classList.add("testCSS");
            setTimeout(function(){
                list.splice(index, 1);
                listItem.classList.remove("testCSS");
                
            }, 600)
            this.listCount -=1  
        },
        dragOver(event,item, index){
            let move_data = this.list[index]
        },
        dragEnter(event,item,index){
            let move_data = this.list[index]
        },
        titleChange(index){
            // Track Title 변경
            let targetList = this.list[index]
            let targetTitle = this.list[index].track.title              // target 리스트 Title 값
            let remainderList  = this.list.filter((list,i) =>{
                return index != i
            })
            let first = remainderList.filter((list,i) =>{
                return list.track.title === 1
            })
            let second = remainderList.filter((list,i) =>{
                return list.track.title === 2
            })
            switch(targetTitle){
                case 0 :
                    if(first.length === 0) targetList.track.title = 1
                    else if(first.length === 0 && second.length > 0) thishis.list.list[index].track.title = 2
                    else if(first.length > 0 && second.length === 0) this.list[index].track.title = 2
                   
                    break;
                case 1:
                    if(second.length > 0) {
                        // Second Title -> first Title 변경
                        this.list.map((list, i) =>{
                            if(list.track.title === 2) list.track.title = 1
                        })
                        this.list[index].track.title = 2
                    }
                    this.list[index].track.title = 0
                    break;
                case 2:
                    this.list[index].track.title = 0
                    break;
            }

            this.$set(this.list,index,targetList) 
        },
        templateData(templateData){
            if(templateData){
                return {
                    view: templateData.view,                                        // 테스트 v-if
                    order: templateData.order+1,                                    // Track 순서
                    representative_artist: templateData.representative_artist,      //대표아티스트 이름
                    track: {
                        explicit: templateData.track.explicit,                      // 선정성 여부 true, false
                        track_language: templateData.track.track_language,          // 트랙명 언어종류
                        trackName: templateData.track.trackName,                    // 노래 제목  String
                        trackName_en: templateData.track.trackName_en,              // 영문 노래제목
                        artists: templateData.track.artists,                        // 가수 이름
                        type: templateData.track.type,                              // 유형 
                        title: templateData.track.title,                            // 타이틀곡 여부 0 : 타이틀곡 아님, 1: 1번째 타이틀곡, 2: 2번째 타이틀곡
                        genre: templateData.track.genre,                            // 장르
                        composer: templateData.track.composer,                      // 작곡가 { 작곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                        isrc: templateData.track.isrc,                              // ISRC 코드
                        track_isrc_generate: templateData.track.track_isrc_generate,// ISRC 코드 신청여부
                        uci: templateData.track.uci,                                // UCI 코드
                        track_uci_generate: templateData.track.track_uci_generate   // UCI 코드 신청
                    },
                    etc: {
                        type: templateData.etc.type,                                // 트랙 유형
                        featuring_language: templateData.etc.featuring_language,    // 피처링아티스트 이름 언어
                        featuring: templateData.etc.featuring,                      // 피처링 아티스트 이름
                        featuring_en: templateData.etc.featuring_en,                // 피처링 아티스트 영문이름
                        lyricist: templateData.etc.lyricist,                        // 작사가 { 작사가명 : 저작권관리 업체명}  management: 저작권 관리업체
                        arranger: templateData.etc.arranger,                        // 편곡가 { 편곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                        lyrics_language: templateData.etc.lyrics_language,          // 노래 가사 언어 종류
                        lyrics: templateData.etc.lyrics                             // 노래 가사
                    },
                    audio: []                                                       // 음원파일 정보
                }

            }else{
                return {
                    view: false,                            // 테스트 v-if
                    order: 0,                               // Track 순서
                    representative_artist: null,            //대표아티스트 이름
                    track: {
                        explicit: false,                    // 선정성 여부 true, false
                        track_language: null,               // 트랙명 언어종류
                        trackName: null,                    // 노래 제목  String
                        trackName_en: null,                 // 영문 노래제목
                        artists: [],                        // 가수 이름
                        type: null,                         // 유형 
                        title: 0,                           // 타이틀곡 여부 0 : 타이틀곡 아님, 1: 1번째 타이틀곡, 2: 2번째 타이틀곡
                        genre: [],                          // 장르
                        composer: [],                       // 작곡가 { 작곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                        isrc: null,                         // ISRC 코드
                        track_isrc_generate: false,         // ISRC 코드 신청여부
                        uci: null,                          // UCI 코드
                        track_uci_generate: false           // UCI 코드 신청
                    },
                    etc: {
                        type: null,                          // 트랙 유형
                        featuring_language: null,            // 피처링아티스트 이름 언어
                        featuring: null,                     // 피처링 아티스트 이름
                        featuring_en: null,                  // 피처링 아티스트 영문이름
                        lyricist: [],                        // 작사가 { 작사가명 : 저작권관리 업체명}  management: 저작권 관리업체
                        arranger: [],                        // 편곡가 { 편곡가명 : 저작권관리 업체명}  management: 저작권 관리업체
                        lyrics_language: null,               // 노래 가사 언어 종류
                        lyrics : null                        // 노래 가사
                    },
                    audio: []                                // 음원파일 정보
                }
            }
        }
    }
  
}
</script>

<style scoped>
.imgHandle{
    width: 1.25rem;
    height: 1.25rem;
    background-color:darkmagenta;
    cursor: move;
    margin-right: 0.5rem;
}

.explicitIcon {
    font-size: 4px;
    color: #fff;
    font-weight: bold;
    background-color: crimson;
    border-radius: 8px;
    letter-spacing: -2px;
    padding: 2px 8px;
}

ul{
    padding: 0;
}

ul li {
    list-style-type: none;
}

.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
    cursor: pointer;
}
.list-group-item i {
  cursor: pointer;
}


/* [S] list content */
.list-group-item > ul{
  width: 100%;
  background-color: #fff;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.list-group-item > ul > li{
    float:left;
    height: 4rem;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    text-align:center;
    color:#4e4e4e;
    font-size: 16px;
    line-height: 4rem;
}

.list-group-item > ul > li:nth-child(1) {
    width: 7%;
    cursor: move !important;
}

.list-group-item > ul > li:nth-child(2) {
    width: 4%;
}
.list-group-item > ul > li:nth-child(3) {
    width: 39%;
}
.list-group-item > ul > li:nth-child(4) {
    width: 14%;
}
.list-group-item > ul > li:nth-child(5) {
    width: 14%;
}
.list-group-item > ul > li:nth-child(6) {
    width: 10%;
    text-align:center;
}
.list-group-item > ul > li:nth-child(7) {
    width: 12%;
}

/* explicit 사이즈 제한 */
.explicit_size{
    width: 100px;
}

/* [E] list content */

#addTrack:hover {
    background-color: aqua;
    color: #fff;
    font-weight: bold;
    border: 1px solid green;
}

</style>