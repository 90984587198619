<template src="./template.html"></template>

<script>
export default {
    name: 'panel-8',
    props: {
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            pwChangeForm: {
                prevPassword: "",
                newPassword: "",
                keepAlive: false
            },
            gridScrollListOption : {
                listCount : 50,
                listData: {
                    status: "판매준비완료",
                    tracks: 6+"곡",
                    title: " 서로 이별한 후에",
                    artist: "윤토벤",
                    register: "2019.07.15",
                    release: "2019.08.20"
                }
            }
        }
    },
    methods: {
        closePanel(){
            this.$emit('closePanel', {
                prevPassword: this.pwChangeForm.prevPassword,
                newPassword: this.pwChangeForm.newPassword,
                name: this.name
            });
        }
    }
}
</script>

<style>
#panel-7 {
    padding: 40px;
}
</style>