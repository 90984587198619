<script>
export default {
    data(){
        return {
            email: this.$route.query.email? this.$route.query.email : null,
            token: this.$route.query.token? this.$route.query.token : null
        }
    },
    created(){
        let email = this.email
        let token = this.token
        let params = [ email, token ]
        let type = null

        this.$store.dispatch('EMPTYCHECK', { type, params})
            .then((result) => {
                if(!result) {
                    this.$store.dispatch("VERIFYEMAIL", { email, token}).then(() =>{
                        this.$router.push('/emailAuth')
                    }).catch((message) => {
                        this.$router.push({ path: "/uncertified", query: { email : email }})
                    })
                }else {
                    alert("Email authentication failed ::")
                }
        })
    }

}
</script>