<template src="./template.html"></template>

<script>
export default {
    data(){
        return {
            isAuthenticate: localStorage.isAuthenticate,
            getMessage: null,   // 개인정보취급, 이용약관 popup message
            security : false,
            title : null,
        }
    },
     mounted(){
        // [S] 로그인 여부 체크
        let SR_Token = localStorage.SR_Token
        if(SR_Token){
            if(this.isAuthenticate){
                this.isAuthenticate = true
            }
        }
        // [E] 로그인 여부 체크

        this.$EventBus.$on("navi", (eventName, params) => {
            switch(eventName){
                case 'login_access':
                    this.isAuthenticate = params  // 로그인 성공 EventBus 발생
                    break;
            }
        })
    },
    computed: {
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const locale = this.$i18n.locale
                const privacy = this.$store.state.privacy[locale]
                const terms = this.$store.state.terms[locale]
                const messageCollection = {
                    privacy: privacy,
                    terms: terms
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key => {
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        }
    },
    watch: {
        isAuthenticate(){
            return this.isAuthenticate
        }
    },
    methods: {
        popupOpen(id, title){
            // popup Open
            this.title = title
            this.guideMessage = title
            this.openModal('footerPop')
        },
        popupClose(event){
            
        }
    }
}
</script>

<style scoped>

.overflowStyle {
    scrollbar-track-color: #262626;  /* IE and Edge */
}

.overflowStyle::-webkit-scrollbar {
    width: 4px; 
}

.overflowStyle::-webkit-scrollbar-track{
    background-color: #262626;
}
.overflowStyle::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color:#ff0000;
  border-radius: 0;
}

/* [S] document Style */
.doc_title{
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -1px;
}

.doc_contents{
    word-break: keep-all;
    margin-bottom: 20px;
}
/* [E] document Style */
</style>
