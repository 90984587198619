<template src="./template.html"></template>

<script>
import i18n from '../../../library/i18n' // 다국어지원
import * as mm from 'music-metadata';
var dataUriToBuffer = require('data-uri-to-buffer');
//import dataUriToBuffer from 'data-uri-to-buffer';
import * as mmb from 'music-metadata-browser';


const path = require("path");
export default {
    name: "panel-9",
    props: ['props',"eventName","trackNumber", "trackData"],
    data() {
        return {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('SR_Token')}`,
                'Access-Control-Allow-Origin': '*',
            },
            fileUrl: `${process.env.VUE_APP_API_URL}/file`,
            fileUrl2 : `${process.env.VUE_APP_API_URL}/file`,
            titleOption : [
                {label : 'NO',value :0},
                {label : 'Title-1',value :1},
                {label : 'Title-2',value :2},
                {label : 'Title-3',value :3},
                {label : 'Title-4',value :4},
            ],
            trackTemplate :{
                name : '',
                name_language : null,
                name_english : '',
                name_multilang : {},
                genre1_id :null,
                genre2_id : null,
                
                artists : [],
                files : [],
                dolbyatmos : {},
                dolbyatmos_id : null,
                contributors : [],
                featuring_artists:'',
                featuring_artists_english:'',
                featuring_artists_language:'ko',
                lyrics : '',
                lyrics_language :'ko' ,
                parental_warning : 'not_explicit', //‘explicit’, ‘explicit_content_edited’, ‘not_explicit’, ‘unknown’
                titletrack : null,
                explicit : false,
                tracknr: 1,
                //track_ref: '',
                type : null,// 트랙유형
                alt_type : null,
                uci : '',
                uci_generate :false,
                isrc : '',
                isrc_generate :false,
                volume : 1,
                iswc: '',
            },
            trackName_en_generate : false,
            error : [],
            genreList : [],
            // { value: 'composer', label: '작곡가' },
            // { value: 'lyricist', label: '작사가' },
            // { value: 'arranger', label: '편곡가' },
            composer:[],
            composerName :null,
            composerCompany :null,
            lyricist:[],
            lyricistName : null,
            lyricistCompany : null,
            arranger:[],
            arrangerName: null,
            arrangerCompany: null,

            files : [],
            uploadAuto: true,
            titleStatus : [],
            
            //dolby 
            files5 : [],
            
            contributorsOption : [
                { value: 'composer', label: this.$i18n.t('track.composer') },
                { value: 'lyricist', label: this.$i18n.t('track.lyricist') },
                { value: 'arranger', label: this.$i18n.t('track.arranger') },
                // { value: 'producer', label: '프로듀서' },
                // { value: 'publisher', label: 'Publisher' },
                // { value: 'remixer', label: 'Remixer' },
                // { value: 'conductor', label: 'Conductor' },
                // { value: 'choir', label: 'Choir' },
                // { value: 'orchestra', label: 'Orchestra' },
                // { value: 'translator', label: 'Translator' },
                // { value: 'narrator', label: 'Narrator' },
                // { value: 'studio_musician', label: 'Studio Musician' },
                // { value: 'studio_personnel', label: 'Studio Personnel' },
            ],
            contributorsRole : null,
            contributorsName : null,
            contributorsCompany : null,
            
            trackList : [],
            trackCopy : null,
        }
    },

    created(){
        this.$EventBus.$on('track_artist_update',(data) =>{
            this.$EventBus.$emit('closeSubPanel',data)
            this.showPanel12()
        });
        this.recevieData_Tamplete(this.props.trackData)  // 초기 track Data 저장
        this.trackTemplate = {
            ...this.props.trackData,
        
        }
        console.log('this.props.titleStatus',this.props.titleStatus)
        this.titleStatus = []
        this.trackList = this.props.trackList
        this.titleStatus = [...this.props.titleStatus]

        if(this.props.trackData.titletrack > 0 && this.titleStatus.indexOf(this.props.trackData.titletrack) > -1){
            
            this.titleStatus.splice(this.titleStatus.indexOf(this.props.trackData.titletrack),1)
        }
        
    },
    mounted(){
        this.$nextTick(function () {
        // 모든 화면이 렌더링된 후 실행합니다.
            this.$EventBus.$on('trackArtist', (params) => {
                this.trackTemplate.artists =params
            })
            this.$EventBus.$on('trackGenre', (params) => {
                this.genreList = params
            }) 
            this.fileUrl = `${process.env.VUE_APP_API_URL}/album/${this.$route.params.id}/tracks/${this.trackTemplate.id}/file`
            this.setData();
            this.$EventBus.$on('trackArtistReg', (params) => {
                this.showPanel12()
            })
        })
    },
    watch:{
       
    },
    computed: {
       
    },
  
    methods: {
        setData (){
            let params ={
                id : this.$route.params.id,
                track_id : this.trackTemplate.id,
                params : {   
                }
            }
            this.loadingShow();
            this.$store.dispatch('LOAD_TRACK', params).then(result =>{
                this.trackTemplate = result

                this.genreList = []
                if(this.trackTemplate.genre1_id){
                    this.genreList.push(this.trackTemplate.genre1)
                }
                if(this.trackTemplate.genre2_id){
                    this.genreList.push(this.trackTemplate.genre2)
                }
                
                // let contributors = this.trackTemplate.contributors
                // for(var a in contributors){
                //     if(contributors[a].role == 'composer'){
                //         this.composer.push(contributors[a])
                //     }else if(contributors[a].role == 'lyricist'){
                //         this.lyricist.push(contributors[a])
                //     }else if(contributors[a].role == 'arranger'){
                //         this.arranger.push(contributors[a])
                //     }
                    
                // }
                const arr = Object.freeze([
                    "composer",
                    "lyricist",
                    "arranger",
                    "producer",
                    "publisher",
                    "remixer",
                    "conductor",
                    "choir",
                    "orchestra",
                    "translator",
                    "narrator",
                    "studio_musician",
                    "studio_personnel",
                ])
                this.trackTemplate.contributors.sort(function(a,b){
                    if(arr.indexOf(a.role)  < arr.indexOf(b.role))
                    return -1;
                    if(arr.indexOf(a.role)  > arr.indexOf(b.role))
                    return 0;
                })
                const fileList = this.trackTemplate.files
                

                this.files5 = []
                if(this.trackTemplate.dolbyatmos){
                    this.files5 = [{
                    ...this.trackTemplate.dolbyatmos,
                        response : [{
                            ...this.trackTemplate.dolbyatmos
                        }],
                        progress : 100
                    }]
                }
                for(var a in fileList ){
                    let dd = fileList[a]
                    this.files.push({
                        ...dd,
                        name : dd.display_name,
                        response :fileList[a],
                        progress : '100.00'
                    })
                }
                this.loadingHide()
            }).catch(error =>{
                this.alert({msg:error.response})
                this.closePanel()
                this.loadingHide()
            })
           
        },
        dragover_handler(event){
            event.dataTransfer.dropEffect = "none"
        },
        dragover_handler2(event){
            event.dataTransfer.dropEffect = "none"
        },
        closePanel(){
            this.$emit('closePanel', {});
        },
        add_li(value) {
            // 추후에 Component 화 작업예정
            const count = this[value+"Count"]
            const contributor = this[value]

            if(value == "composer") {
                //임시 처리 추후 수정예정
                if(!contributor[contributor.length-1].name) {
                    alert(i18n.t("components.panel.panel9.msg.track."+ value))
                }else if(!contributor[contributor.length-1].copyright_association){
                    alert(i18n.t("components.panel.panel9.msg.track."+ value))
                }else{
                    this[value].push(this.contributor_template())
                    this[value+"Count"] += 1
                }
            }else{
                if(count > 0) {
                    //임시 처리 추후 수정예정
                    if(!contributor[contributor.length-1].name) {
                        alert(i18n.t("components.panel.panel9.msg.etc."+ value))
                    }else if(!contributor[contributor.length-1].copyright_association){
                        alert(i18n.t("components.panel.panel9.msg.track.management"))
                    }else{
                        this[value].push(this.contributor_template())
                        this[value+"Count"] += 1
                    }
                }else{
                    this[value].push(this.contributor_template())
                    this[value+"Count"] += 1
                }
            }
           
        },
        delete_li(value){
            // 추후에 Component 화 작업예정
            const count = this[value+"Count"]
            const contributor = this[value]
            if(value == 'composer') {
                /* 보류 if (count == 1 && contributor[contributor.length -1].copyright_association && contributor[contributor.length-1].name) { */
                if (count <= 1) {
                    alert(i18n.t("components.panel.panel9.msg.track.delete_"+ value))
                }else {
                    //임시 처리 추후 수정예정
                    contributor.pop()                         
                    this[value+"Count"] -=1
                }
            }else {
                if (count == 0) {
                    alert(i18n.t("components.panel.panel9.msg.etc."+ value))
                }else {
                    //임시 처리 추후 수정예정
                    contributor.pop()                         
                    this[value+"Count"] -=1
                }
            }
        },
        showPanel12(){
            
            // const panel = this.$showPanel({
            //     component: "panel-12",
            //     width: "740px",
            //     openOn: 'right',
            //     props: {
            //         returnName: this.artist_eventName,
            //         trackNumber: this.trackNumber,
            //         currentData: this.representative_artist
            //     }
            // })
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-12",
                props: {
                    returnName: this.artist_eventName,
                    trackNumber: this.trackNumber,
                    currentData: this.trackTemplate.artists
                }
                 
            });
            // panel.promise.then(result =>{
               
            // })
        },
        artist_open() {
            
            //this.loadArtistList()
        },
        genre_open(){
            this.showPanel13()
        },
        showPanel13(){
            // const panel = this.$showPanel({
            //     component: "panel-13",
            //     width: "740px",
            //     openOn: 'right',
            //     props: {
            //         returnName: this.genre_eventName,
            //         trackNumber: this.trackNumber,
            //         currentData: this.genres
            //     }
            // })
        
            // panel.promise.then(result =>{
            //     this.click.genre_click = true
            // })
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-13",
                props: {
                    returnName: 'trackGenre',
                    trackNumber: this.trackNumber,
                    currentData: this.genreList
                }
            });
        },
        EnterOnlyEnglish(value){
            let inputvalue = value
            const re = /[^a-z]/gi
            return inputvalue.replace(re,'')
        },
        sbcPopupTogFunc(event){
            let id = document.getElementById('trackInfo_etc')

            switch(id.style.display){
                case "none":
                    id.style.display = "block"
                    id.parentNode.classList.remove("mBottom20")
                    break;
                default :
                    id.style.display = "none";
                    id.parentNode.classList.add("mBottom20")
                    break;
            }
        },
        loadArtistList(){
         
        },
        loadGenreList(){
            if(this.$store.state.genres.length === 0) {
                let sort = null
                let sortorder = null
                let page = null
                let column = null
              
                this.$store.dispatch('LOADGENRE', { sort, sortorder, page, column })
                    .then(result =>{
                        let genreData = result.items.map((value, index) =>{
                            return value
                        })
                            this.showPanel13()
                    })
            }else {
                let genreData = this.$store.state.genres.map((value, index) =>{
                    return value
                })
                
                this.showPanel13()
            }
        },
        checkboxImpl(event) {
            // 항목 값 unCheck :  checkBox 후처리
            // UCI, IRSC Code Check
            let idName = event.target.id
            let id = idName.substring(0, event.target.id.lastIndexOf('_generate'))
            let el = document.getElementById(id)
            this.checkbox_errorMessage(id)
            let checkbox = this[idName]  // v-model  barcode_generate
        
            // disabled
            if(checkbox) {
                if(id === 'desired_datechk'){
                    this.desired_release_date_datechk = true
                }else{
                    el.readOnly = false
                    el.classList.remove('input_fitem_disabled')
                }
            }else {
                if(id === 'desired_datechk'){
                    this.desired_release_date_datechk = false
                }else{
                    el.readOnly = true
                    el.classList.add('input_fitem_disabled')
                }
            }
        },
        errorMessage(event){
            let id = event.target.id
            switch(id) {
                case 'composer_name':
                case 'composer_management':
                    id = "composer"
                    break;
                default:
                    id= id
                    break;
            }
            const error = this.error.indexOf(id)
            if(error > -1) this.error.splice(error, 1);
            
        },
        checkbox_errorMessage(value){
            //checkbox 체크시 에러 메세지 삭제 처리
            const error = this.error.indexOf(value)
            if(error > -1){
                this.error.splice(error, 1);
            }       
        },
        recevieData_Tamplete(trackData){
            
        },
        popupOpen(id){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            setTimeout(function(){
            popup.firstChild.classList.add('type2')
          }, 300)
        },
        popupClose(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
              popup.classList.remove('type2')
          }, 300)
        },
        async getMetadata(file){
            try {
                return mmb.parseBlob(file, {native: true}).then(metadata => {
                    console.log(`Completed parsing of ${file.name}:`, metadata);
                    return metadata;
                })
            } catch (err) {
                console.error(err);
                return {};
            }
        },
        inputFileUpload3(newFile, oldFile) {
            let t = this
            console.log(newFile, oldFile)
            if(newFile){
                // console.log(newFile);
                if(newFile.file.type.indexOf('audio') < 0 ){
                    this.alert({msg:'오디오(mp3,wav,flac) 파일만 가능합니다.'});
                    this.$refs.upload3.remove(newFile)
                    this.$forceUpdate();
                    return false
                }else{
                    switch(path.extname(newFile.name).toLowerCase()) {
                        case '.mp3':
                        
                            if (newFile && !oldFile) {
                                // add
                                let file = newFile.file
                                let reader = new FileReader();
                                    reader.onload = (e) => {
                                    //var decoded = dataUriToBuffer();
                                    this.getMetadata(newFile.file).then(result =>{
                                        console.log(result)
                                        this.loadingHide()
                                        if ( result.format && result.format.bitrate >= 320000 ) {
                                            this.$refs.upload3.active = true
                                        }else{
                                            this.$refs.upload3.remove(newFile)
                                            this.alert({msg: this.$i18n.t('track.mp3.error')})
                                            return false
                                        }
                                    }).catch(error =>{
                                        this.$refs.upload3.remove(newFile)
                                        this.alert({msg: 'file error'})
                                    })
                                }
                                reader.readAsDataURL(newFile.file)
                            }
                            break;
                        case '.wav':
                            if (newFile && !oldFile) {
                                // add
                                this.$refs.upload3.active = true
                            }
                            break;
                        case '.flac':
                            if (newFile && !oldFile) {
                                // add
                                this.$refs.upload3.active = true
                            }
                            break;            
                    }
                    
                }
            }
            if(newFile){
                if (newFile.active && oldFile.active) {
                // update
                
                
                if(newFile.progress == '100.00' ){
                    
                    this.loadingHide()
                }
                if(newFile.progress == '0.00' ){
                    //this.loadingShow();
                }
            }
            }
           
            
            if (!newFile && oldFile) {
                // remove
                //console.log('remove', oldFile)
            }
          
        },
        inputFilez(newFile, oldFile) {

            if (newFile && oldFile) {
                // update
                if (newFile.active && !oldFile.active) {
                }
                
                if (newFile.progress !== oldFile.progress) {
                    // progress
                }
                if (newFile.error && !oldFile.error) {
                // error
                }
                if (newFile.success && !oldFile.success) {
                // success
                    this.$refs.upload3.update(newFile, { error: 'size' })
                }
            }
            if (!newFile && oldFile) {
                // remove
                if (oldFile.success && oldFile.response.id) {
                // $.ajax({
                //   type: 'DELETE',
                //   url: '/upload/delete?id=' + oldFile.response.id,
                // })
                }
            }
            // Automatically activate upload
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (this.uploadAuto && !this.$refs.upload3.active) {
                    this.$refs.upload3.active = true
                }
            }
        },
        deleteFile(file,index){
            
            let params = {
                id : this.$route.params.id,
                track_id : this.trackTemplate.id,
                file_id : file.response.id
            }
            this.loadingShow()
            this.$store.dispatch('DELETETRACKFILE', params).then(result =>{
                this.files.splice(index,1)
                this.loadingHide()
            }).catch(error =>{
                this.alert({msg:error.response})
                this.loadingHide()
            })
        },
        //트랙 저장
        saveTrack(){
            let params ={
                id : this.$route.params.id,
                track_id : this.trackTemplate.id,
                params : {   
                }
            }
            
            for(var j in this.trackList){
                if( this.trackList[j].id != this.trackTemplate.id && this.trackList[j].name == this.trackTemplate.name &&  this.trackList[j].type == this.trackTemplate.type ){
                    this.alert({msg : this.$i18n.t('track.nameAndType.error')})
                    return false
                }
            }
            if(this.trackTemplate.explicit){
                this.trackTemplate.parental_warning = "explicit"
            }else{
                this.trackTemplate.parental_warning = "not_explicit"
            }

            params.params= this.trackTemplate
            
            for (const key in params.params.name_multilang) {
                if(this.valCheck(params.params.name_multilang[key]) ){
                    delete params.params.name_multilang[key]
                }
            }
            delete params.params.album_id
            delete params.params.genre1
            delete params.params.genre2
            delete params.params.files
            if(this.files5.length > 0){
                this.trackTemplate.dolbyatmos_id = this.files5[0].response[0].id
            }else{
                this.trackTemplate.dolbyatmos_id = null
            }

            if( this.genreList[0]){
                params.params['genre1_id'] = this.genreList[0].id
            }else{
                params.params['genre1_id'] = null
            }
            if( this.genreList[1]){
                params.params['genre2_id'] = this.genreList[1].id
            }else{
                params.params['genre2_id'] = null
            }
            
            let trackArtist = [] 
            for(var i in this.trackTemplate.artists){
                trackArtist.push({id :this.trackTemplate.artists[i].id})
            }
            params.params.artists =  trackArtist
            
            if(this.valCheck(params.params.biography)) delete params.params.biography 
            if(this.valCheck(params.params.biography_english)) delete params.params.biography_english 
            if(this.valCheck(params.params.biography_language)) delete params.params.biography_language 
            if(this.valCheck(params.params.debut_date)) delete params.params.debut_date 
            if(this.valCheck(params.params.name_english)) delete params.params.name_english 
            
            this.loadingShow()
            this.$store.dispatch('UPDATETRACK', params).then(result =>{
                
                this.$emit('closePanel')
                setTimeout(() => {
                    this.$EventBus.$emit('saveTrack')
                }, 0);
                
            }).catch(error =>{
                this.alert({msg:error.response})
                this.loadingHide()
            })
        },
        contributor_templateback(){
            return {
                name: null,
                copyright_association: null
            }
        },
        addContributorback(type){
            if(type == "composer"){
                if(this.valCheck(this.composerName)){
                    this.alert({msg: i18n.t('enter.track.composerName')})
                    return false
                }else{
                    this.composer.push({
                        name : this.composerName,
                        role : 'composer',
                        copyright_association : this.composerCompany,
                        ipi : null
                    })
                    this.composerName = this.composerCompany = null
                }
                
            }else if(type == "lyricist"){
                
                if(this.valCheck(this.lyricistName)){
                    this.alert({msg: i18n.t('enter.track.lyricistName')})
                    return false
                }else{
                    this.lyricist.push({
                        name : this.lyricistName,
                        role : 'lyricist',
                        copyright_association : this.lyricistCompany,
                        ipi : null
                    })
                    this.lyricistName =this.lyricistCompany =null
                }
            }else if(type == "arranger"){
                if(this.valCheck(this.arrangerName)){
                    this.alert({msg: i18n.t('enter.track.arrangerName')})
                    return false
                }else{
                    this.arranger.push({
                        name : this.arrangerName,
                        role : 'arranger',
                        copyright_association : this.arrangerCompany,
                        ipi : null
                    })
                    this.arrangerName =this.arrangerCompany = null
                }
            }
        },
        deleteContributor(type, index){
            // console.log(type,index)
            // if(type == "composer"){
            //     this.composer.splice(index)
            // }else if(type == "lyricist"){
            //     this.lyricist.splice(index)
            // }else if(type == "arranger"){
            //     this.arranger.splice(index)
            // }
        },
        addContributor(){
            if(this.valCheck(this.contributorsRole)){
                this.alert({msg:this.$i18n.t('enter.track.contributorRole')})
                return false
            }
            if(this.valCheck(this.contributorsName)){
                this.alert({msg:this.$i18n.t('enter.track.contributorName')})
                return false
            }
            this.trackTemplate.contributors.push({
                name : this.contributorsName,
                role : this.contributorsRole,
                copyright_association : this.contributorsCompany,
                ipi : this.contributorsIpi
            })
            const arr = Object.freeze([
                "composer",
                "lyricist",
                "arranger",
                "producer",
                "publisher",
                "remixer",
                "conductor",
                "choir",
                "orchestra",
                "translator",
                "narrator",
                "studio_musician",
                "studio_personnel",
            ])
            this.trackTemplate.contributors.sort(function(a,b){
                if(arr.indexOf(a.role)  < arr.indexOf(b.role))
                return -1;
                if(arr.indexOf(a.role)  > arr.indexOf(b.role))
                return 0;
            })
            //this.contributorsRole = null
            this.contributorsName = this.contributorsCompany = null
        },
        removeContributor(index){
            this.trackTemplate.contributors.splice(index,1)
        },
        copyContributor(){
            if(this.valCheck(this.trackCopy)){
                this.alert({msg: this.$i18n.t('track.copy.error.select')})
                return false
            }
            if(this.trackCopy.contributors.length < 1 ){
                this.alert({msg: this.$i18n.t('track.copy.error.length')})
                return false
            }
            
            let contributorsCopy =  this.trackCopy.contributors
            
            for(var i in contributorsCopy){
                this.trackTemplate.contributors.push( {
                    name : contributorsCopy[i].name,
                    role : contributorsCopy[i].role,
                    copyright_association : contributorsCopy[i].copyright_association,
                    ipi : contributorsCopy[i].ipi
                })
            }
            const arr = Object.freeze([
                "composer",
                "lyricist",
                "arranger",
                "producer",
                "publisher",
                "remixer",
                "conductor",
                "choir",
                "orchestra",
                "translator",
                "narrator",
                "studio_musician",
                "studio_personnel",
            ])
            this.trackTemplate.contributors.sort(function(a,b){
                if(arr.indexOf(a.role)  < arr.indexOf(b.role))
                return -1;
                if(arr.indexOf(a.role)  > arr.indexOf(b.role))
                return 0;
            })
        },
        updateLang(data){
            this.trackTemplate.name_multilang = data
            this.$forceUpdate()
        },

        inputFileUpload5(newFile, oldFile) {
      
            if (newFile && !oldFile) {
                // add
                
                if(newFile.type.indexOf('wav') < 0 ){
                    console.log(newFile)    
                    this.$refs.upload5.active = false
                    setTimeout(() => {
                        this.files5 = []
                        this.$refs.upload5.remove(newFile)  
                    }, 0);
                    this.$forceUpdate()
                    this.alert({msg:'오디오(wav) 파일만 가능합니다.'});
                    return prevent()
                }
                
            
                let file = newFile.file
                let reader = new FileReader
                this.loadingShow();
                reader.onload = e => {
                
                const dd = this.getMetadata(file)
                dd.then(result =>{
                    if(result.format.numberOfChannels > 2){
                        this.$refs.upload5.active = true
                    }else{
                    this.alert({msg:'Dolby 음원이 아닙니다.'})
                    this.$refs.upload5.remove(newFile)
                    this.files5 = []
                    this.loadingHide();
                    return false
                    }
                })
                }
                reader.readAsDataURL(file)
            }
            if (newFile && oldFile) {
                // update
                if(Array.isArray(newFile.response)){
                this.file5 ={
                    ...this.file5,
                    ...newFile.response[0]
                }
                }
                if(newFile.progress == '100.00' ){
                this.loadingHide()
                }
                if(newFile.progress == '0.00' ){
                }
            }
            
            if (!newFile && oldFile) {
                // remove
                
            }
        },
        removeDolbyFile(){
            this.loadingShow();
            if(this.trackTemplate.dolbyatmos_id){
                const payload = {
                    album_id : this.$route.params.id,
                    track_id : this.trackTemplate.id,
                }
                this.$store.dispatch("DELETE_DOLBY_FILE", payload)
                .then(result => {
                this.loadingHide();
                 
                this.files5 = []
                
                this.trackTemplate.dolbyatmos_id = null
                }).catch(error=>{
                this.loadingHide();
                switch(error.response.data.code) {
                    default:
                    this.alert({msg:error})
                    break;
                }
                })
            }else{
                const payload = {
                    id : this.files5[0].response[0].id
                }
                this.$store.dispatch("DELETE_ONLY_FILE", payload)
                .then(result => {
                    this.loadingHide();
                    this.files5 = []
                
                    this.trackTemplate.dolbyatmos_id = null
                }).catch(error=>{
                this.loadingHide();
                switch(error.response.data.code) {
                    default:
                    this.alert({msg:error})
                    break;
                }
                })
                this.files5 = []
                
            }
            
        },
    }
}
</script>

<style scoped>
.add {
    cursor: pointer;
}

.mBottom20{
    margin-bottom: 20px !important;
}
.artistStyle01{
    
    line-height: 1;
    margin:10px 5px;
    border: 1px solid #dedede;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 15px;
}

</style>