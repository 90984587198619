<template src="./template.html"></template>

<script>
import i18n from '../../../library/i18n' // 다국어지원

export default {
    name: 'panel-17',
    props: [''],
    data() {
        return {
            active_lang: null,
            genre1_Name: null,
            genre2_Name: null,
            composer: [], // 작곡가
            lyricist: [], // 작사가
            arranger: []  // 편곡가
            
        }
    },

    mounted(){
        
        this.$nextTick(function(){
            //lyrics language
            const lyrics_language = this.trackData.lyrics_language
            this.$store.state.language.map(lang =>{
                if(lang.code){
                    if(lyrics_language){
                        if(lang.code.toLowerCase() == lyrics_language.toLowerCase()){
                            this.active_lang = this.$i18n.locale == 'ko'? item.name : item.en_name
                        }
                    }
                }
            })
            // 작곡가, 작사가, 편곡가
            this.trackData.contributors.map(contributor =>{
                switch(contributor.role) {
                    case 'composer':
                        this.composer.push(contributor)
                        break;
                    case 'lyricist':
                        this.lyricist.push(contributor)
                        break;
                    case 'arranger':
                        this.arranger.push(contributor)
                        break;
                }
            })

         
        })
    },
    computed: {
        codec(){
           
            let codec = this.trackData.files.map((item, index) =>{
                const formatName = item.metadata.format
                if(formatName){
                    return  formatName         
                }
            })
            return codec
        },
        trackData(data){
            return data.$attrs.props.trackData
        }
    },
    methods: {
        closePanel(){
            this.$emit('closePanel', {});
        },
        showPanel14(){
            // const panel = this.$showPanel({
            //     component: "panel-14",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex"
            // })
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-14",
            });
        },
        dateFormat(value){
            return value.split("T")[0]
        },
        tab_language_active(event){
            let parentNode = event.target.parentNode
            if(parentNode.classList != "active") parentNode.classList.add('active')
            
            let selector_id = parentNode.getAttribute( 'id' )
            switch(selector_id){
                case 'lyrics_tabc01':
                    /* 20200331 보류 this.active_lang = 'kr' */
                    document.getElementById('lyrics_tabc02').classList.remove('active')
                    break;
                case 'lyrics_tabc02':
                    /* 20200331 보류 this.sactive_lang = 'en' */
                    document.getElementById('lyrics_tabc01').classList.remove('active')
                    break;
            }   
        },
        getGenre(id) {
            return this.$store.dispatch('GETGENRE', id)
        },
        update(){
            let id = this.listData.id
            let params = {
                state: "suspended"
            }
            this.$store.dispatch("UPDATEALBUM", {id, params}).then(result =>{
                this.closePanel()
            })
        },
        srdPopupCall(){
            var srdslider = null;
            SRD.srdLayerShow('#srdTarget01',function(){
                // 상단 슬라이더
                var $srdslider_list = $(".srdslider_list");
                if($srdslider_list.length){
                    if(srdslider === null){
                        srdslider = $srdslider_list.slick({
                            dots: true,
                            arrows : false,
                            touchMove: true
						});
					}
				}
			})
        },
        
    }
}
</script>

<style scoped>
.agile__dot--current button{
    background-color:#888;
}
</style>