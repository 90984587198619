<template src="./template.html"></template>

<script>
import {en, ko} from "vuejs-datepicker/dist/locale"
import moment from 'moment'

export default {
    name:"panel-11",
    props: ['props'],
   
    data(){
        return {
            ko : ko,
            en : en,
            calenderLang : null,
            // 세금 ----------------------------------------------
            id : null,
            // 주민등록번호    
            idCard: null,
            //주민등록번호 타입
            //entity_identification_type: null,  
            // 생년월일
            birthday: null,
            // [KO] 세금 주소검색
            /* search: null, */
            // [KO] 세금 주소 KO
            /* address: null, */
            // [KO] 세금 상세주소 KO
            /* detail: null, */
            // [EN] [KO] 세금 주소1
            taxAddress1: null,
            // [EN] [KO] 세금 주소2
            taxAddress2: null,
            // [EN] 세금 구2
            taxDistrict: null,
            // [EN] 세금 시
            taxCity: null,
            // [EN] [KO] 세금 우편번호
            taxPostalCode: null,
            // 지급 -----------------------------------------------------
            // [KO] 예금주
            accountHolder: null,
            // [KO] 계좌번호
            accountNumber: null,
            // [KO] 은행
            bank: null,
            // [EN] Bank Code
            bankCode: null,
            // [EN] country Code
            bankCountryCode: null,
            // [EN] country info(name)
            bankCountryInfo: null,
            // [EN] bank And Branch Name
            bankAndBranchName: null,
            // [EN] Address1
            paymentAddress1: null,
            // [EN] Address2
            paymentAddress2: null,
            // [EN] District
            paymentDistrict: null,
            // [EN] City
            paymentCity: null,
            // [EN] paymentPostalCode 
            paymentPostalCode: null,
            // 기타 -------------------------------------------------------
            // 동의
            agree: false,
            fail: {
                idCard: false,
                birthday: false,
                taxAddress: false,
                accountHolder: false,
                accountNumber:false,
                bank: false,
                bankCode: false,
                bankCountryCode: false,
                bankAndBranchName: false,
                branchAddress: false,
                agree: false
            },
            error: [],
            emptyType: null,
            user_nationality: null,      // 접속한 계정 국적정보
            juso: null,                  // 주소 검색
            jusoView: false              // 주소 검색창 노출 여부
        }
    },
    computed: {
        // entity_identification_type(){
        //     this.entity_identification_type = this.props.entity_identification_type;
        // },
        // countryCode(){
        //     this.countryCode = this.props.countryCode;
        // },
        countryCode(){
            return this.props.countryCode
        },
        entity_identification_type(){
            return this.props.entity_identification_type
        },
        params () {
            // 은행 국가 정보
            this.$store.state.country.map(country =>{
              if(country.code.toLowerCase() == this.bankCountryCode){
                this.bankCountryInfo = country
              }
            })

            return {
                // 세금정보
                    id : this.props.id,
                    countryCode : this.props.countryCode,
                    idCard: this.idCard,
                    birthday: this.birthday == ''? null: this.birthday,
                    taxAddress1: this.taxAddress1,
                    taxAddress2: this.taxAddress2,
                    taxDistrict: this.taxDistrict,
                    taxCity: this.taxCity,
                    taxPostalCode: this.taxPostalCode,
                    
                    // 지급정보
                    accountHolder: this.accountHolder,
                    accountNumber: this.accountNumber,
                    bank: this.bank,
                    // 임시 보류
    /*              bankCode: this.bankCode,
                    bankCountryCode: this.bankCountryCode,
                    bankCountryInfo: this.bankCountryInfo,
                    bankAndBranchName: this.bankAndBranchName,
                    paymentAddress1: this.paymentAddress1,
                    paymentAddress2: this.paymentAddress2,
                    paymentDistrict: this.paymentDistrict,
                    paymentCity: this.paymentCity,
                    paymentPostalCode:this.paymentPostalCode, */
                    agree: this.agree
            }
        }
    },
    created(){
        
    },
    mounted(){
        this.$nextTick(function () {
            if(this.$store.getters.languageCode == 'ko'){
                this.calenderLang = this.ko
            }else{
                this.calenderLang = this.en
            }
        })
    },
    watch:{
        birthday(data){
            console.log('dddddd/'+this.fixDate(data))
            this.birthday = this.birthday == ''? null: this.birthday
            return this.fixDate(data)
        },
        idCard(){
            return this.idCard = this.onlyEnNumber(this.idCard)
        },
        taxPostalCode(){
            //return this.taxPostalCode = this.onlyNumber(this.taxPostalCode)
        },
        paymentPostalCode(){
            //return this.paymentPostalCode = this.onlyNumber(this.paymentPostalCode)
        },
        accountNumber() {
            return this.accountNumber = this.onlyNumber(this.accountNumber)
        },
        juso(){
            // 주소 검색
            this.taxAddress1 = this.juso.address
            this.taxCity = this.juso.sigungu
            this.taxDistrict = this.juso.sido
            this.taxPostalCode = this.juso.zonecode
            this.jusoSearch()
        }
    },
    methods: {
        someEvent(data){
            console.log('event -- '+data)
        },
        closePanel(){
            this.$emit('closePanel', {})
        },
        getNumberMask(val) {
            //서버 전송 값에는 '-' 를 제외하고 숫자만 저장
            this.$store.dispatch("ONLYNUMBER", val)
                .then(result => {
                    this.idCard = result
                }).catch(Error =>{
                    console.log("Only Number  Error:::")
                })
            
        },
        onlyNumber(val){
            const re = /[^0-9]/g
            return val.replace(re, '');
        },
        onlyEnNumber(val){
            const re = /[^a-z0-9]/gi
            return val.replace(re, '');
        },
        onSubmit() {
            this.error = []                                  // 에러체 크  초기화
            // 세금 --------------------------------------------------------------------------
            
            // 납세자 번호 등
            const idCard = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.idCard, "EMPTYCHECK", "idCard")
                let dd = this.errorImpl('idCard')
                resolve(dd)
            })

            // 생년월일
            const birthday = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.birthday, "EMPTYCHECK", "birthday")
                resolve(this.errorImpl('birthday'))
            })

            // 거주지 주소
            // 거주지 주소 1
            const taxAddress1 = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.taxAddress1, "EMPTYCHECK", "taxAddress")
                resolve(this.errorImpl('taxAddress'))
            })
            // 거주지 주소 2
            // const taxAddress2 = new Promise(resolve =>{
            //     this.goNullCheck(this.emptyType, this.taxAddress2, "EMPTYCHECK", "taxAddress")
            //     resolve(this.errorImpl('taxAddress'))
            // })
            // 시/ 도
            

            
            // 지급 --------------------------------------------------------------------------

            // 해외 지급정보  임시 보류
            /* const bankCode = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.bankCode, "EMPTYCHECK", "bankCode")
                resolve(this.errorImpl('bankCode'))
            })

            const bankCountryCode = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.bankCountryCode, "EMPTYCHECK", "bankCountryCode")
                resolve(this.errorImpl('bankCountryCode'))
            })

            const bankAndBranchName = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.bankAndBranchName, "EMPTYCHECK", "bankAndBranchName")
                resolve(this.errorImpl('bankAndBranchName'))
            })


            const paymentAddress1 = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.paymentAddress1, "EMPTYCHECK", "branchAddress")
                resolve(this.errorImpl('branchAddress'))
            })

            const paymentAddress2 = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.paymentAddress2, "EMPTYCHECK", "branchAddress")
                resolve(this.errorImpl('branchAddress'))
            })

            const paymentDistrict = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.paymentDistrict, "EMPTYCHECK", "branchAddress")
                resolve(this.errorImpl('branchAddress'))
            })

            const paymentCity = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.paymentCity, "EMPTYCHECK", "branchAddress")
                resolve(this.errorImpl('branchAddress'))
            })

            const paymentPostalCode = new Promise(resolve =>{
                this.goNullCheck(this.emptyType, this.paymentPostalCode, "EMPTYCHECK", "branchAddress")
                resolve(this.errorImpl('branchAddress'))
            }) */

            // 기타 ----------------------------------------------------------------------------
            // agree
            
            
            if(this.props.countryCode == 'KR'){
                // 거주지 코드 : KR
                //한국 지급정보
                const bank = new Promise(resolve =>{
                    this.goNullCheck(this.emptyType, this.bank, "EMPTYCHECK", "bank")
                    resolve(this.errorImpl('bank'))
                })
                 
                const accountHolder = new Promise(resolve =>{
                    this.goNullCheck(this.emptyType, this.accountHolder, "EMPTYCHECK", "accountHolder")
                    resolve(this.errorImpl('accountHolder'))
                })

                const accountNumber = new Promise(resolve =>{
                    this.goNullCheck(this.emptyType, this.accountNumber, "EMPTYCHECK", "accountNumber")
                    resolve(this.errorImpl('accountNumber'))
                })

                // 우편번호
                const taxPostalCode = new Promise(resolve =>{
                    this.goNullCheck(this.emptyType, this.taxPostalCode, "EMPTYCHECK", "taxAddress")
                    resolve(this.errorImpl('taxAddress'))
                })
                const agree = new Promise(resolve =>{
                    if(!this.agree) {
                        this.error.push('totalagr')
                       resolve(true)
                    }else{
                        resolve(false)
                    }
                    
                })
                Promise.all([idCard, birthday, taxAddress1, accountHolder, accountNumber, bank, agree]).then(result =>{
                    // Error가 있을 경우 전송 중지
                    setTimeout(() => {
                    this.onSubmitImpl(result)    
                    }, 0);
                    
                })
            }else{
                const agree = new Promise(resolve =>{
                    if(!this.agree) {
                        this.error.push('totalagr')
                        resolve(true)
                    }else{
                        resolve(false)
                    }
                    
                })
                // 거주지 코드 : Global
                // 임시 보류 Promise.all([idCard, birthday, taxAdderss1, taxAdderss2, taxDistrict, taxCity, taxPostalCode, accountHolder, accountNumber, bankCode, bankCountryCode, bankAndBranchName, paymentAddress1, paymentAddress2, paymentDistrict, paymentCity, paymentPostalCode, agree]).then(result =>{
                Promise.all([idCard, birthday, taxAddress1,  agree]).then(result =>{
                    // Error가 있을 경우 전송 중지
                    setTimeout(() => {
                    this.onSubmitImpl(result)    
                    }, 0);
                })
            }
           
        },
        onSubmitImpl(values){
            // onSubmit Implement\

            if(this.error.length  == 0 && values.indexOf(false) > -1) {
                let params = this.params
                // this.closePanel()    
                console.log('통과')
                params['payment'] = this.props.params.payment
                this. loadingShow();
               
                this.$store.dispatch('PAYMENTUPDATE', params)
                .then((result)=> {
                    this. loadingHide()
                    this.alert({msg:this.iText('등록되었습니다.','regSuccess')})
                    this.closePanel();
                    this.$EventBus.$emit("MY",'accounting', params)

                }).catch(error => {
                    console.log("My Page Save Error ::: :::")
                })
            }else{
                this. loadingHide()
                this.alert({msg:this.iText('입력정보를 확인해주세요.','inputValueCheck')})
            }
        },
        popupOpen(id){
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            setTimeout(function(){
              popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupClose(event){
          // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
              popup.classList.remove('type2')
              this.closePanel()
          }, 300)
        },
        goNullCheck (emptyType, params, functionName, message) {
            this.$store.dispatch(functionName, {emptyType, params})
                .then(result => {
                    if(result) {
                        this.fail[message] = true
                        if(!(this.error.indexOf(message) > -1)) this.error.push(message)
                    }
                })
            .catch(error => {
                console.log("Null check Error :::")
            })
        },
        errorImpl(message){
            if(this.error.indexOf(message) > -1) {
                return false
            }else {
                return true
            }
        },
        errorMessage(event){
 
            const id = event.target.id
            const error = this.error.indexOf(id)
            if(error > -1){
                this.error.splice(error, 1);
            }
        },
        select_errorMessage(value) {
            const error = this.error.indexOf(value)
            if(error > -1){
                this.error.splice(error, 1);
            }
        },
        jusoSearch(){
            let jusoClass = document.getElementById('juso').classList
            jusoClass.toggle("type2")
        }
    }
}
</script>

<style scoped>

.align_left{
    text-align: left;
}

.height50{
    height: 50px;
}

.testCss {
    height:1px;
    transition: height 1.2s;
    padding-top: 2px;
    overflow: hidden;
    
}
.testCss.type2{
    height: 444px;
}
</style>