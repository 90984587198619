<template src="./template_genre.html"></template>

<script>
import i18n from "../i18n"
export default {
    props: ['listCount', 'listType', "uiType", "eventName", "all_check", "filterView", "currentData"],
    data(){
        return {
            infoData: [],
            result: 0,
            count: 0,
            variousArtist: false,
            arrayResult: [],
            arrayList: [],
            search: null,                   // 검색
            filter: null,
            value: null,                    //임시
            listData: [],
            filterData : [],
            total: 0,                       // list total
            totalPage: 0,                   // total page
            currentPage: 0,                 // current page
            loading: false                  // 로딩중....
        }
    },
    created () {
       
        // load List
        this.loadGenreList(this.currentPage)
        if( this.currentData.length > 0) {
            this.currentData.map(value =>{
                this.infoData.push(value.id)
                
            })
        }
    },
    mounted(){
        this.$nextTick(function () {
            // 모든 화면이 렌더링된 후 실행합니다.
            let array = this.currentData
            if(array.length > 0) {
                //artist List 노출
                array.map(value => {
                    this.createNode(value)
                })
            }
        })
    },
    watch: {
        'search'(val){
            const words = this.listData
            
            const result = this.listData.filter(item => {
                let  text = item.name_english.toUpperCase()
                return text.indexOf(val.toUpperCase()) > -1
            });
            this.filterData = result
            //this.$store.commit('init', params) // 장르 Store초기화
        },
        
    },
    computed: {
        viewCount(){
            let listSize = this.listData.length
            let listCount = this.listCount
            if(listSize > listCount) this.count = listCount
            else this.count = listSize
            return this.count
        },
        resultData(){
            let resultData = [] // 초기화
            this.arrayList.forEach(value =>{
                resultData.push(value)
            })
            return resultData
        }

    },
    methods : {
        handleScroll: function() {
            if (this.timeout) clearTimeout(this.timeout); 
                this.timeout = setTimeout(function(){
                // your action
                this.scrollArea()
                }.bind(this), 250);
        },
        scrollArea(){
            let targetEl = document.getElementById("gridList");
            let a = targetEl.scrollHeight - targetEl.clientHeight
            let b = targetEl.scrollTop;
            let dataLength = this.listData.length           // 가져온 데이터 량
            let listCount = this.listCount                  // 리스트 노출 제한
            let remainder = (this.filtered%listCount)          // 나눈 나머지 몫
            this.totalPage = (remainder == 0)?(parseInt(this.filtered/listCount)) : (parseInt(this.filtered/listCount)+1) // 총 리스트 페이지 수

            if((b/a) >= 0.08){
                if(this.currentPage <= this.totalPage) {
                    this.currentPage++
                    this.loadGenreList(this.currentPage)
                }else{
                    return false
                }
            }
        },
        loadGenreList(page , loadType){
            // 장르 리스트 불러오기
            const sort = 'created_at'
            const sortorder = null
            page = page? page: 0
            let column = null
            if(this.search) column = this.search
            // 로딩
            this.loadingShow();
            this.$store.dispatch('LOADGENRE', { sort, sortorder, page, column })
            .then(result =>{
                this.loadingHide();
                this.total = result.total  // 총 리스트 업데이트
                this.filtered = result.filtered
                this.listData = this.filterData = result.items
                // if(result.items.length > 0) {
                //     let genreData = result.items.map((value, index) => this.listData.push(value))
                // }
                    
                // setTimeout(function(){
                //     // document.getElementsByClassName('text_loading')[0].classList.remove('loading_active')
                    // }, 1000)
            })
        },
        searchGenreList(page , loadType){
            // 장르 검색 리스트 블러오기
            let sort = 'created_at'
            let sortorder = null
            page = page? page: 0
            let column = {}
            column["name_english"] = this.search
            this.loadingShow();
            this.$store.dispatch('LOADGENRE', { sort, sortorder, page, column })
                .then(result =>{
                    this.loadingHide();
                    this.total = result.total  // 총 리스트 업데이트
                    this.filtered = result.filtered
                    if(result.items.length > 0) this.listData = result.items
                    else this.listData = []
                })
        },
        listTypeChange(){
            switch(this.listType){
                case "normal":
                    this.listType = "grid";
                    break;
                case "grid":
                    this.listType = "normal";
                    break;
                default: this.listType = "grid";
            }
        },
        variousArtistCheck() {
            this.variousArtist = this.variousArtist ? false : true
            this.variousArtistImplement()
        },
        variousArtistImplement() {
            if(this.variousArtist) {
                this.listData.map(value =>{
                    if(this.arrayList.length > 0) this.deleteNode(value) // arrayArrange, checkbox, 데이터 삭제 처리
                    // 체크 박스 비활성화
                    const id = document.getElementById('item_'+ value.id)
                    id.setAttribute("disabled", true)
                    id.classList.add('style02')
                    id.labels[0].classList.add('disabled')
                })
            }else{
                this.listData.map(value =>{
                    let id = document.getElementById('item_'+ value.id)
                    id.removeAttribute("disabled")
                    id.classList.remove('style02')
                    id.labels[0].classList.remove('disabled')
                })
            }
        },
        itemCheck(event){
            let target = event.target
            let arrayCount = this.infoData.length
            let value = Number(target.value)
            console.log(value)
            
            if(arrayCount > 1 && target.checked) {
                alert(i18n.t("components.panel.panel16.msg.genreCount"))
                target.checked = false
            }else {
               
                let getItem = this.listData.filter(item =>{
                    return item.id == value
                })[0]
                this.createNode(getItem)
            }
            
            this.$EventBus.$emit(this.eventName, this.resultData)
        },
        createNode(value){
            let presenceCheck = this.arrayList.filter(item =>{
                return item.id == value.id
            }).length // 해당값 존재 여부 체크 0 : 존재않함, 1 이면 존재함
            
            if(presenceCheck < 1) {
                this.arrayList.push(value)
                // parent Node
                let list = [75,89 , 88 ,91]
                if(list.indexOf(value.id) > -1){
                    this.alert({msg : this.$i18n.t('genre.msg.more')})
                }
                const node = document.createElement("span");
                node.setAttribute("id", value.id);
                node.classList.add("cursorStyle01")
                node.classList.add("srsprof_spec_etc")
                let name = this.dataSearch(value.id).name_english
                let artistNode = document.createTextNode(name +" x")
                node.appendChild(artistNode)

                node.addEventListener("click", function() { 
                    this.deleteNode(value)
                    this.$EventBus.$emit(this.eventName, this.resultData)
                }.bind(this), false)

                document.getElementById("arrayArrange").appendChild(node);
            }else{
                this.deleteNode(value)
            }

        },
        deleteNode(value){
            let presenceCheck = this.arrayList.filter((item, index) =>{
                if(item.id == value.id)
                    this.arrayList.splice(index, 1)
                return item.id == value.id
            }).length // 해당값 존재 여부 체크 0 : 존재않함, 1 이면 존재함

            let listCheck = this.infoData.filter((item,index) =>{
                if(item == value.id)
                    this.infoData.splice(index, 1)
                return item.id == value.id
            }).length // 해당값 존재 여부 체크 0 : 존재않함, 1 이면 존재함

            if(presenceCheck > 0) {
                //arrange area
                const parentId = document.getElementById("arrayArrange")
                const childId = document.getElementById(value.id)
                parentId.removeChild(childId)
            }
            
            if(listCheck > 0) {
                // 체크 박스 처리
                const checkboxId = document.getElementById('item_'+ value.id)
                if(checkboxId){
                    if(checkboxId.checked){
                        checkboxId.checked = false
                    }
                }
            }
        },
        showPanel2(){
        
            this.$EventBus.$emit('openPanel',{
                component: "panel-2",
                props: {
                    
                }
            });
        },
        dataSearch(id) {
            // 데이터 찾기
            return this.arrayList.filter(data => {
                return data.id == id
            })[0]  
        },
        sortChange(event) {
            // Sort 옵션 설정
            let sort = "created_at"
            let sortorder = "desc"
            let column = this.column
            if(event.target.id === "srsrd01")
                sort = "created_at"
            else if(event.target.id === "srsrd02") sort = "name_english"
            this.loadListAPi(sort, sortorder, page, column)
        },
        loadListAPi(sort, sortorder, page, column) {
            
            this.$store.dispatch("LOADGENRE", { sort, sortorder, page, column })
                .then(result =>{
                    let genreData = result.items.map((value, index) =>{
                    return value
                })
                    
                this.listData = genreData
            })
        },
        data_search(){
            this.searchGenreList(this.currentPage)
        }
    }
}
</script>

<style scoped>
    li {
        list-style-type: none;
    }

    .gridList2 {
    
    }

    .gridList2::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    .deleteStyle {
        cursor: pointer;
        margin: 0 5px;
    }

</style>
