export const SNS_TYPE = Object.freeze([
    { code: 'youtube', text: 'Youtube' },
    { code: 'facebook', text: 'Facebook' },
    { code: 'twitter', text: 'Twitter' },
    { code: 'instagram', text: 'Instagram' },
    { code: 'website', text: 'Website' },
  ])
  
  export default Object.freeze({
    MAX_COMPONENT_ITEM_COUNT: 20,
    MUSIC_STORE_KOREA: 'korea',
    MUSIC_STORE_OTHER: 'other',
    TERRITORY_GLOBAL: 'global',
    TERRITORY_KOREA: 'korea',
    SNS_TYPE
  })
