<template src="./template.html"></template>
 
<script>
import i18n from '@/library/i18n'
// JQuery 사용
import JQuery from 'jquery'
const $ = JQuery
import json from '@/assets/albumList.json'
export default {
  name: 'HelloWorld',
  data () {
    return {
      //{ artist: '후추스',album : '연미정' , image :require('../../assets/images/main/album/123.jpg') },
      album : [
          
        ...json.data
          
      ],
      active : false,
    }
  },
  mounted(){
    this.$nextTick(function () {
      // 모든 화면이 렌더링된 후 실행합니다.
      setTimeout(() => {
        const $mvlow_w = $(".mvlow_w")
        $mvlow_w.addClass("complete")
      }, 0);
    })
  },
  methods: {
    test(){
      window.addEventListener('scroll', function(e) {
        this.mainMotion()
      })
    },
    mainMotion(){
      
     if(!this.active){
      const touchstart = "ontouchstart" in window;
	    let $header = $(".header_wrap"),
      $mvlow_w = $(".mvlow_w"),
      $mclow01_w = $(".mclow01_w"),
      $mclow02_w = $(".mclow02_w"),
      $mclow03_w = $(".mclow03_w"),
      $mcment_w = $(".mcment_w"),
      $mcguide_ment_w = $(".mcguide_ment_w"),
      $mcalbum_copy_w = $(".mcalbum_copy_w"),
      $header_height = 0,
      $mcment_w_pos = 0,
      $mcguide_ment_w_pos = 0,
      $mcalbum_copy_w_pos = 0;

   
	
      $mcment_w_pos = $mcment_w.length ? $mcment_w.offset().top : 0;
      $mcguide_ment_w_pos = $mcguide_ment_w.length ? $mcguide_ment_w.offset().top : 0;
      $mcalbum_copy_w_pos = $mcalbum_copy_w.length ? $mcalbum_copy_w.offset().top : 0;
	
      
      var $scroll = document.documentElement.scrollTop;
      if($scroll>$mcment_w_pos/2){
        $mclow01_w.addClass("complete");
      }
      if($scroll>$mcguide_ment_w_pos/1.4){
        $mclow02_w.addClass("complete");
      }
      if($scroll>$mcment_w_pos/1.3){
        $mclow03_w.addClass("complete");
        this.active = true
      }
     }
      
    },
    moveNext(){
      let $mclow01_w = $(".mvlow_w")
      let $header = $(".header_wrap"),
          $header_height = 0
          console.log(window.innerHeight);
      if($("html").hasClass("chrome")){
          window.scrollTo({
            top:  window.innerHeight   ,
            behavior: 'smooth'
          });
        }else{
          window.scrollTo({
            top:  window.innerHeight   ,
            behavior: 'smooth'
          });
      }
      setTimeout(() => {
        this.mainMotion()
      },300);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '../../assets/css/main.scss';
</style>
<style scoped>
/* 메인전용 CSS */


/* slider */
@import '../../assets/css/slick.css';
@import '../../assets/css/slick-theme.css';


</style>
