
<template>
<div class="bg container" >
    <h1>{{$t('common.error.code404.title')}}</h1>
    <h3>{{$t('common.error.code404.msg')}}</h3>
    <p @click.prevent="back" class="backBtn">{{$t('common.error.code404.button')}}</p>
</div>
</template>

<script>
export default {
    name: "Error",
    data() {
        return {
        }
    },
    beforeRouteLeave (to, from, next) {
    },
    watch: {
    },
    created(){
    },
    mounted() {
        this.$nextTick(function () {
        })
    },
    computed: {
    },
    methods: {
        back(){
            this.$router.back()
        },
    }
}
</script>

<style scoped>
.bg {
    background-color: #000;
    color: #FFF;
    margin: calc(20% - 100px) auto;
    width: 30%;
    padding: 80px 20px;
    font-size: 1.2rem;
    border-radius: 10px;
}

.backBtn {
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    padding: 2px 0;
}

.backBtn:hover {
    background-color: #03fcff;
    color: #333;
}
</style>
