import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.js'
import ko from './ko.js'

Vue.use(VueI18n)

/*
Abbr. 
1.Sound Republica -> sr
2. move -> mv
*/

/*
Message(Label) Key Name Rule
[위치]_[이름]_[행동]
[Location]_[Name]_[Act]
*/
const messages = {
    en:en,
    ko: ko
}

// Create VueI18n instance with options
// 옵션으로 VueI18n 인스턴스 만들기
export default new VueI18n({
    locale: 'ko', 
    messages : Object.freeze(messages)
})

// Create a Vue instance with 'i18n' option
// 'i18n'옵션을 사용하여 Vue 인스턴스 만들기
// new Vue({i18n}).$mount('#app')