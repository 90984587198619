// 국가
export const country = [
    {name:"대한민국", name_en: "SOUTH KOREA", code: "KR"},
    {name:"미국", name_en: "UNITED STATES", code: "US"},
    {name:"영국", name_en: "UNITED KINGDOM", code: "GB"},
    {name:"캐나다", name_en: "CANADA", code: "CA"},
    {name:"네덜란드", name_en: "NETHERLANDS", code: "NL"},
    {name:"호주", name_en: "AUSTRALIA", code: "AU"},
    {name:"독일", name_en: "GERMANY", code: "DE"},
    {name:"이탈리아", name_en: "ITALY", code: "IT"},
    {name:"폴란드", name_en: "POLAND", code: "PL"},
    {name:"루마니아", name_en: "ROMANIA", code: "RO"},
    {name:"국적불명", name_en: "UNKNOWN", code: "ZZ"},
    {name:"안도라", name_en: "ANDORRA", code: "AD"},
    {name:"아랍에미리트", name_en: "UNITED ARAB EMIRATES", code: "AE"},
    {name:"아프가니스탄", name_en: "AFGHANISTAN", code: "AF"},
    {name:"안티구아 바부다", name_en: "ANTIGUA AND BARBUDA", code: "AG"},
    {name:"앙길라", name_en: "ANGUILLA", code: "AI"},
    {name:"알바니아", name_en: "ALBANIA", code: "AL"},
    {name:"아르메니아", name_en: "ARMENIA", code: "AM"},
    {name:"앙골라", name_en: "ANGOLA", code: "AO"},
    {name:"안타티카", name_en: "ANTARCTICA", code: "AQ"},
    {name:"아르헨티나", name_en: "ARGENTINA", code: "AR"},
    {name:"오스트리아", name_en: "AUSTRIA", code: "AT"},
    {name:"아루바", name_en: "ARUBA", code: "AW"},
    {name:"알랜드 군도", name_en: "ALAND ISLANDS", code: "AX"},
    {name:"아제르바이잔", name_en: "AZERBAIJAN", code: "AZ"},
    {name:"보스니아 헤르체고비나", name_en: "BOSNIA HERCEGOVINA", code: "BA"},
    {name:"바베이도스", name_en: "BARBADOS", code: "BB"},
    {name:"방글라데시", name_en: "BANGLADESH", code: "BD"},
    {name:"벨기에", name_en: "BELGIUM", code: "BE"},
    {name:"부르키나파소", name_en: "BURKINA FASO", code: "BF"},
    {name:"불가리아", name_en: "BULGARIA", code: "BG"},
    {name:"바레인", name_en: "BAHRAIN", code: "BH"},
    {name:"브룬디", name_en: "BURUNDI", code: "BI"},
    {name:"베냉", name_en: "BENIN", code: "BJ"},
    {name:"버뮤다", name_en: "BERMUDA", code: "BM"},
    {name:"브루나이", name_en: "BRUNEI DARUSSALAM", code: "BN"},
    {name:"볼리비아", name_en: "BOLIVIA", code: "BO"},
    {name:"보네르", name_en: "BONAIRE", code: "BQ"},
    {name:"브라질", name_en: "BRAZIL", code: "BR"},
    {name:"바하마", name_en: "BAHAMAS", code: "BS"},
    {name:"부탄", name_en: "BHUTAN", code: "BT"},
    {name:"보츠와나", name_en: "BOTSWANA", code: "BW"},
    {name:"벨라루스", name_en: "BELARUS", code: "BY"},
    {name:"벨리즈", name_en: "BELIZE", code: "BZ"},
    {name:"콩고민주공화국", name_en: "Democratic Republic of the Congo", code: "CD"},
    {name:"중앙아프리카공화국", name_en: "CENTRAL AFRICAN REPUBLIC", code: "CF"},
    {name:"콩고", name_en: "CONGO", code: "CG"},
    {name:"스위스", name_en: "SWITZERLAND", code: "CH"},
    {name:"코트디부아르", name_en: "COTE D`IVOIRE", code: "CI"},
    {name:"칠레", name_en: "CHILE", code: "CL"},
    {name:"카메룬", name_en: "CAMEROON", code: "CM"},
    {name:"중국", name_en: "CHINA", code: "CN"},
    {name:"콜롬비아", name_en: "COLOMBIA", code: "CO"},
    {name:"코스타리카", name_en: "COSTA RICA", code: "CR"},
    {name:"쿠바", name_en: "CUBA", code: "CU"},
    {name:"카보베르데", name_en: "CAPE VERDE", code: "CV"},
    {name:"큐라소", name_en: "CURACAO", code: "CW"},
    {name:"사이프러스", name_en: "CYPRUS", code: "CY"},
    {name:"체코공화국", name_en: "CZECH REPUBLIC", code: "CZ"},
    {name:"지부티", name_en: "DJIBOUTI", code: "DJ"},
    {name:"덴마크", name_en: "DENMARK", code: "DK"},
    {name:"도미니카 연방", name_en: "DOMINICA", code: "DM"},
    {name:"도미니카 공화국", name_en: "DOMINICAN REPUBLIC", code: "DO"},
    {name:"알제리", name_en: "ALGERIA", code: "DZ"},
    {name:"에콰도르", name_en: "ECUADOR", code: "EC"},
    {name:"에스토니아", name_en: "ESTONIA", code: "EE"},
    {name:"이집트", name_en: "EGYPT", code: "EG"},
    {name:"서사하라", name_en: "WESTERN SAHARA", code: "EH"},
    {name:"에리트레아", name_en: "ERITREA", code: "ER"},
    {name:"스페인", name_en: "SPAIN", code: "ES"},
    {name:"에티오피아", name_en: "ETHIOPIA", code: "ET"},
    {name:"핀란드", name_en: "FINLAND", code: "FI"},
    {name:"피지", name_en: "FIJI", code: "FJ"},
    {name:"포클랜드섬", name_en: "FALKLAND ISLANDS", code: "FK"},
    {name:"미크로네시아", name_en: "MICRONESIA", code: "FM"},
    {name:"페로 군도", name_en: "FAROE ISLANDS", code: "FO"},
    {name:"프랑스", name_en: "FRANCE", code: "FR"},
    {name:"가봉", name_en: "GABON", code: "GA"},
    {name:"그레나다", name_en: "GRENADA", code: "GD"},
    {name:"조지아", name_en: "GEORGIA", code: "GE"},
    {name:"건지", name_en: "GUERNSEY", code: "GG"},
    {name:"가나", name_en: "GHANA", code: "GH"},
    {name:"지브랄타", name_en: "GIBRALTAR", code: "GI"},
    {name:"그린랜드", name_en: "GREENLAND", code: "GL"},
    {name:"감비아", name_en: "GAMBIA", code: "GM"},
    {name:"기니", name_en: "GUINEA", code: "GN"},
    {name:"과들루프", name_en: "GUADELOUPE", code: "GP"},
    {name:"적도 기니", name_en: "EQUATORIAL GUINEA", code: "GQ"},
    {name:"그리스", name_en: "GREECE", code: "GR"},
    {name:"과테말라", name_en: "GUATEMALA", code: "GT"},
    {name:"괌", name_en: "GUAM", code: "GU"},
    {name:"기네비쏘", name_en: "GUINEA-BISSAU", code: "GW"},
    {name:"가이아나", name_en: "GUYANA", code: "GY"},
    {name:"홍콩", name_en: "HONG KONG", code: "HK"},
    {name:"온두라스", name_en: "HONDURAS", code: "HN"},
    {name:"크로아티아", name_en: "CROATIA", code: "HR"},
    {name:"아이티", name_en: "HAITI", code: "HT"},
    {name:"헝가리", name_en: "HUNGARY", code: "HU"},
    {name:"인도네시아", name_en: "INDONESIA", code: "ID"},
    {name:"아일랜드", name_en: "IRELAND", code: "IE"},
    {name:"이스라엘", name_en: "ISRAEL", code: "IL"},
    {name:"맨섬", name_en: "ISLE OF MAN", code: "IM"},
    {name:"인도", name_en: "INDIA", code: "IN"},
    {name:"이라크", name_en: "IRAQ", code: "IQ"},
    {name:"이란", name_en: "IRAN", code: "IR"},
    {name:"아이슬란드", name_en: "ICELAND", code: "IS"},
    {name:"저지", name_en: "JERSEY", code: "JE"},
    {name:"자메이카", name_en: "JAMAICA", code: "JM"},
    {name:"요르단", name_en: "JORDAN", code: "JO"},
    {name:"일본", name_en: "JAPAN", code: "JP"},
    {name:"케냐", name_en: "KENYA", code: "KE"},
    {name:"키르기즈스탄", name_en: "KYRGYZSTAN", code: "KG"},
    {name:"캄보디아", name_en: "CAMBODIA", code: "KH"},
    {name:"키리바시", name_en: "KIRIBATI", code: "KI"},
    {name:"코모르", name_en: "COMOROS", code: "KM"},
    {name:"북한", name_en: "NORTH KOREA", code: "KP"},
    {name:"코소보", name_en: "KOSOVO", code: "XK"},
    {name:"쿠웨이트", name_en: "KUWAIT", code: "KW"},
    {name:"카자흐스탄", name_en: "KAZAKHSTAN", code: "KZ"},
    {name:"라오스", name_en: "LAOS", code: "LA"},
    {name:"레바논", name_en: "LEBANON", code: "LB"},
    {name:"리히텐슈타인", name_en: "LIECHTENSTEIN", code: "LI"},
    {name:"스리랑카", name_en: "SRI LANKA", code: "LK"},
    {name:"라이베리아", name_en: "LIBERIA", code: "LR"},
    {name:"레소토", name_en: "LESOTHO", code: "LS"},
    {name:"리투아니아", name_en: "LITHUANIA", code: "LT"},
    {name:"룩셈부르크", name_en: "LUXEMBOURG", code: "LU"},
    {name:"라트비아", name_en: "LATVIA", code: "LV"},
    {name:"리비아", name_en: "LIBYAN ARAB JAMAHIRIYA", code: "LY"},
    {name:"모로코", name_en: "MOROCCO", code: "MA"},
    {name:"모나코", name_en: "MONACO", code: "MC"},
    {name:"몰도바", name_en: "MOLDOVA", code: "MD"},
    {name:"마다가스카르", name_en: "MADAGASCAR", code: "MG"},
    {name:"마샬군도", name_en: "MARSHALL ISLANDS", code: "MH"},
    {name:"마케도니아", name_en: "MACEDONIA", code: "MK"},
    {name:"말리", name_en: "MALI", code: "ML"},
    {name:"미얀마", name_en: "MYANMAR", code: "MM"},
    {name:"몽골", name_en: "MONGOLIA", code: "MN"},
    {name:"마카오", name_en: "MACAU", code: "MO"},
    {name:"마르티니크", name_en: "MARTINIQUE", code: "MQ"},
    {name:"모리타니", name_en: "MAURITANIA", code: "MR"},
    {name:"몬트세라트", name_en: "MONTSERRAT", code: "MS"},
    {name:"몰타", name_en: "MALTA", code: "MT"},
    {name:"모리셔스", name_en: "MAURITIUS", code: "MU"},
    {name:"몰디브", name_en: "MALDIVES", code: "MV"},
    {name:"말라위", name_en: "MALAWI", code: "MW"},
    {name:"멕시코", name_en: "MEXICO", code: "MX"},
    {name:"말레이시아", name_en: "MALAYSIA", code: "MY"},
    {name:"모잠비크", name_en: "MOZAMBIQUE", code: "MZ"},
    {name:"나미비아", name_en: "NAMIBIA", code: "NA"},
    {name:"뉴칼레도니아", name_en: "NEW CALEDONIA", code: "NC"},
    {name:"니제르", name_en: "NIGER", code: "NE"},
    {name:"나이지리아", name_en: "NIGERIA", code: "NG"},
    {name:"니카라과", name_en: "NICARAGUA", code: "NI"},
    {name:"노르웨이", name_en: "NORWAY", code: "NO"},
    {name:"네팔", name_en: "NEPAL", code: "NP"},
    {name:"나우루", name_en: "NAURU", code: "NR"},
    {name:"니우에", name_en: "NIUE", code: "NU"},
    {name:"뉴질랜드", name_en: "NEW ZEALAND", code: "NZ"},
    {name:"오만", name_en: "OMAN", code: "OM"},
    {name:"파나마", name_en: "PANAMA", code: "PA"},
    {name:"페루", name_en: "PERU", code: "PE"},
    {name:"파푸아뉴기니", name_en: "PAPUANEWGUINEA", code: "PG"},
    {name:"필리핀", name_en: "PHILIPPINES", code: "PH"},
    {name:"파키스탄", name_en: "PAKISTAN", code: "PK"},
    {name:"핏케언 군도", name_en: "PITCAIRN", code: "PN"},
    {name:"푸에르토리코", name_en: "PUERTO RICO", code: "PR"},
    {name:"팔레스타인", name_en: "PALESTINE", code: "PS"},
    {name:"포르투갈", name_en: "PORTUGAL", code: "PT"},
    {name:"팔라우", name_en: "PALAU", code: "PW"},
    {name:"파라과이", name_en: "PARAGUAY", code: "PY"},
    {name:"카타르", name_en: "QATAR", code: "QA"},
    {name:"리유니언", name_en: "REUNION", code: "RE"},
    {name:"세르비아", name_en: "SERBIA", code: "RS"},
    {name:"러시아", name_en: "RUSSIAN FEDERATION", code: "RU"},
    {name:"르완다", name_en: "RWANDA", code: "RW"},
    {name:"사우디아라비아", name_en: "SAUDI ARABIA", code: "SA"},
    {name:"솔로몬 군도", name_en: "SOLOMON ISLANDS", code: "SB"},
    {name:"이셸", name_en: "SEYCHELLES", code: "SC"},
    {name:"수단", name_en: "SUDAN", code: "SD"},
    {name:"스웨덴", name_en: "SWEDEN", code: "SE"},
    {name:"싱가포르", name_en: "SINGAPORE", code: "SG"},
    {name:"세인트 헬레나", name_en: "SAINT HELENA", code: "SH"},
    {name:"슬로베니아", name_en: "SLOVENIA", code: "SI"},
    {name:"슬로바키아", name_en: "SLOVAKIA", code: "SK"},
    {name:"시에라리온", name_en: "SIERRA LEONE", code: "SL"},
    {name:"산마리노", name_en: "SAN MARINO", code: "SM"},
    {name:"세네갈", name_en: "SENEGAL", code: "SN"},
    {name:"소말리아", name_en: "SOMALIA", code: "SO"},
    {name:"수리남", name_en: "SURINAME", code: "SR"},
    {name:"남수단", name_en: "SOUTH SUDAN", code: "SS"},
    {name:"엘살바도르", name_en: "EL SALVADOR", code: "SV"},
    {name:"시리아", name_en: "SYRIAN ARAB REPUBLIC", code: "SY"},
    {name:"스와질랜드", name_en: "SWAZILAND", code: "SZ"},
    {name:"차드", name_en: "CHAD", code: "TD"},
    {name:"토고", name_en: "TOGO", code: "TG"},
    {name:"태국", name_en: "THAILAND", code: "TH"},
    {name:"타지키스탄", name_en: "TAJIKISTAN", code: "TJ"},
    {name:"토켈라우", name_en: "TOKELAU", code: "TK"},
    {name:"동티모르", name_en: "EAST TIMOR", code: "TL"},
    {name:"투르크메니스탄", name_en: "TURKMENISTAN", code: "TM"},
    {name:"튀니지", name_en: "TUNISIA", code: "TN"},
    {name:"통가", name_en: "TONGA", code: "TO"},
    {name:"터키", name_en: "TURKEY", code: "TR"},
    {name:"트리니다드토바고", name_en: "TRINIDAD AND TOBAGO", code: "TT"},
    {name:"투발루", name_en: "TUVALU", code: "TV"},
    {name:"대만", name_en: "TAIWAN", code: "TW"},
    {name:"탄자니아", name_en: "TANZANIA", code: "TZ"},
    {name:"우크라이나", name_en: "UKRAINE", code: "UA"},
    {name:"우간다", name_en: "UGANDA", code: "UG"},
    {name:"우루과이", name_en: "URUGUAY", code: "UY"},
    {name:"우즈베키스탄", name_en: "UZBEKISTAN", code: "UZ"},
    {name:"바티칸", name_en: "VATICAN CITY STATE", code: "VA"},
    {name:"세인트 빈센트 그레나딘스", name_en: "SAINT VINCENT AND THE GRENADINES", code: "VC"},
    {name:"베네수엘라", name_en: "VENEZUELA", code: "VE"},
    {name:"베트남", name_en: "VIET NAM", code: "VN"},
    {name:"바누아투", name_en: "VANUATU", code: "VU"},
    {name:"사모아", name_en: "SAMOA", code: "WS"},
    {name:"예멘", name_en: "YEMEN", code: "YE"},
    {name:"마요트", name_en: "MAYOTTE", code: "YT"},
    {name:"유고슬라비아", name_en: "YUGOSLAVIA", code: "YU"},
    {name:"남아프리카공화국", name_en: "SOUTH AFRICA", code: "ZA"},
    {name:"잠비아", name_en: "ZAMBIA", code: "ZM"},
    {name:"자이르", name_en: "ZAIRE", code: "ZR"},
    {name:"짐바브웨", name_en: "ZIMBABWE", code: "ZW"},
    {name:"아메리칸 사모아", name_en: "AMERICAN SAMOA", code: "AS"},
    {name:"부베섬", name_en: "BOUVET ISLAND", code: "BV"},
    {name:'영인도 제도', name_en: 'BRITISH INDIAN OCEAN TERRITORY', code: 'IO'},
    {name:'케이맨 제도', name_en: 'CAYMAN ISLANDS', code: 'KY'},
    {name:'크리스마스 섬', name_en: 'CHRISTMAS ISLAND', code: 'CX'},
    {name:'코코스 제도', name_en: 'COCOS ISLANDS', code: 'CC'},
    {name:'쿡 제도', name_en: 'COOK ISLANDS ', code: 'CK'},
    {name:'프랑스령 기아나', name_en: 'FRENCH GUIANA', code: 'GF'},
    {name:'프랑스령 폴리네시아', name_en: 'FRENCH POLYNESIA', code: 'PF'},
    {name:'프랑스령 남방  및 남극 지역', name_en: 'FRENCH SOUTHERN TERRITORIES ', code: 'TF'},
    {name:'허드 맥도널드 제도', name_en: 'HEARD ISLAND AND MCDONALD ISLANDS', code: 'HM'},
    {name:'몬테네그로', name_en: 'MONTENEGRO', code: 'ME'},
    {name:'노퍽섬', name_en: 'NORFOLK ISLAND', code: 'NF'},
    {name:'북마리아나 제도', name_en: 'NORTHERN MARIANA ISLANDS', code: 'MP'},
    {name:'생바르텔레미', name_en: 'SAINT BARTHÉLEMY', code: 'BL'},
    {name:'세인트키츠 네비스', name_en: 'SAINT KITTS AND NEVIS', code: 'KN'},
    {name:'세인트루시아', name_en: 'SAINT LUCIA', code: 'LC'},
    {name:'생마르탱', name_en: 'SAINT MARTIN (FRENCH PART)', code: 'MF'},
    {name:'생피에르  미클롱', name_en: 'SAINT PIERRE AND MIQUELON', code: 'PM'},
    {name:'상투메 프린시페', name_en: 'SAO TOME AND PRINCIPE', code: 'ST'},
    {name:'신트마르턴', name_en: 'SINT MAARTEN (DUTCH PART)', code: 'SX'},
    {name:'사우스조지아 사우스샌드위치 제도', name_en: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS', code: 'GS'},
    {name:'스발마르 얀마옌', name_en: 'SVALBARD', code: 'SJ'},
    {name:'터크스 케이커스 제도', name_en: 'TURKS AND CAICOS ISLANDS ', code: 'TC'},
    {name:'미국령 군소 제도', name_en: 'UNITED STATES MINOR OUTLYING ISLANDS', code: 'UM'},
    {name:'영국령 버진아일랜드', name_en: 'VIRGIN ISLANDS (BRITISH) ', code: 'VG'},
    {name:'미국령 버진아일랜드', name_en: 'VIRGIN ISLANDS (U.S.)', code: 'VI'},
    {name:'왈리스 푸투나', name_en: 'WALLIS AND FUTUNA', code: 'WF'},
]


// 은행
    
export const bank = [
    {name: "KEB하나은행", code: "081"},
    {name: "경남은행", code: "039"},
    {name: "광주은행", code: "034"},
    {name: "국민은행", code: "004"},
    {name: "기업은행", code: "003"},

    {name: "농협은행", code: "011"},
    {name: "대구은행", code: "031"},
    {name: "도이치은행", code: "055"},
    {name: "부산은행", code: "032"},
    {name: "산업은행", code: "002"},

    {name: "저축은행", code: "050"},
    {name: "새마을금고중앙회", code: "045"},
    {name: "신협", code: "048"},
    {name: "신한은행", code: "088"},
    {name: "우리은행", code: "020"},

    {name: "우체국", code: "071"},
    {name: "전북은행", code: "037"},
    {name: "제주은행", code: "035"},
    {name: "카카오뱅크", code: "090"},
    {name: "케이뱅크", code: "089"},
    {name: "토스뱅크", code: "092"},


    {name: "한국씨티은행", code: "027"},
    {name: "BOA", code: "060"},
    {name: "HSBC", code: "054"},
    {name: "제이피모건체이스은행", code: "057"},
    {name: "SC제일은행", code: "023"},

    {name: "산림조합중앙회", code: "064"},
    {name: "중국공상은행", code: "062"},
    {name: "중국은행", code: "063"},
    {name: "중국건설은행", code: "067"},
    {name: "비엔피파리바은행", code: "061"},

    {name: "하나금융투자", code: "270"},
    {name: "교보증권", code: "261"},
    {name: "대신증권", code: "267"},
    {name: "미래에셋대우", code: "238"},
    {name: "DB금융투자", code: "279"},

    {name: "유안타증권", code: "209"},
    {name: "메리츠종합금융증권", code: "287"},
    {name: "부국증권", code: "290"},
    {name: "삼성증권", code: "240"},
    {name: "신영증권", code: "291"},

    {name: "신한금융투자", code: "278"},
    {name: "NH투자증권", code: "247"},
    {name: "유진투자증권", code: "280"},
    {name: "키움증권", code: "264"},
    {name: "하이투자증권", code: "262"},

    {name: "한국투자증권", code: "243"},
    {name: "한화투자증권", code: "269"},
    {name: "KB증권", code: "218"},
    {name: "이베스트투자증권", code: "265"},
    {name: "현대차투자증권", code: "263"},

    {name: "케이프투자증권", code: "292"},
    {name: "SK증권", code: "266"},
    {name: "한국포스증권", code: "294"},
    {name: "케이티비투자증권", code: "227"},
    {name: "BNK투자증권", code: "224"},

    {name: "바로투자증권", code: "288"},
]

export const dialing = [
    {name:"안도라", name_en: "ANDORRA", code: "+376"},
    {name:"아랍에미리트", name_en: "UNITED ARAB EMIRATES", code: "+971"},
    {name:"아프가니스탄", name_en: "AFGHANISTAN", code: "+93"},
    {name:"안티구아 바부다", name_en: "ANTIGUA AND BARBUDA", code: "+1 268"},
    {name:"앙길라", name_en: "ANGUILLA", code: "+1 264"},
    {name:"알바니아", name_en: "ALBANIA", code: "+355"},
    {name:"아르메니아", name_en: "ARMENIA", code: "+374"},
    {name:"앙골라", name_en: "ANGOLA", code: "+244"},
    {name:"안타티카", name_en: "ANTARCTICA", code: "+672"},
    {name:"아르헨티나", name_en: "ARGENTINA", code: "+54"},

    {name:"오스트리아", name_en: "AUSTRIA", code: "+43"},
    {name:"호주", name_en: "AUSTRALIA", code: "+61"},
    {name:"아루바", name_en: "ARUBA", code: "+297"},
    {name:"알랜드 군도", name_en: "ALAND ISLANDS", code: "+358 18"},
    {name:"아제르바이잔", name_en: "AZERBAIJAN", code: "+994"},
    {name:"보스니아 헤르체고비나", name_en: "BOSNIA HERCEGOVINA", code: "+387"},
    {name:"바베이도스", name_en: "BARBADOS", code: "+1 246"},
    {name:"방글라데시", name_en: "BANGLADESH", code: "+880"},
    {name:"벨기에", name_en: "BELGIUM", code: "+32"},
    {name:"부르키나파소", name_en: "BURKINA FASO", code: "+226"},

    {name:"불가리아", name_en: "BULGARIA", code: "+359"},
    {name:"바레인", name_en: "BAHRAIN", code: "+973"},
    {name:"브룬디", name_en: "BURUNDI", code: "+257"},
    {name:"베냉", name_en: "BENIN", code: "+229"},
    {name:"버뮤다", name_en: "BERMUDA", code: "+1 441"},
    {name:"브루나이", name_en: "BRUNEI DARUSSALAM", code: "+673"},
    {name:"볼리비아", name_en: "BOLIVIA", code: "+591"},
    {name:"보네르", name_en: "BONAIRE", code: "+599 7"},
    {name:"브라질", name_en: "BRAZIL", code: "+55"},
    {name:"바하마", name_en: "BAHAMAS", code: "+1 242"},

    {name:"부탄", name_en: "BHUTAN", code: "+975"},
    {name:"보츠와나", name_en: "BOTSWANA", code: "+267"},
    {name:"벨라루스", name_en: "BELARUS", code: "+375"},
    {name:"벨리즈", name_en: "BELIZE", code: "+501"},
    {name:"캐나다", name_en: "CANADA", code: "+1"},
    {name:"콩고민주공화국", name_en: "Democratic Republic of the Congo", code: "+243"},
    {name:"중앙아프리카공화국", name_en: "CENTRAL AFRICAN REPUBLIC", code: "+236"},
    {name:"콩고", name_en: "CONGO", code: "+242"},
    {name:"스위스", name_en: "SWITZERLAND", code: "+41"},
    {name:"코트디부아르", name_en: "COTE D`IVOIRE", code: "+225"},

    {name:"칠레", name_en: "CHILE", code: "+56"},
    {name:"카메룬", name_en: "CAMEROON", code: "+237"},
    {name:"중국", name_en: "CHINA", code: "+86"},
    {name:"콜롬비아", name_en: "COLOMBIA", code: "+57"},
    {name:"코스타리카", name_en: "COSTA RICA", code: "+506"},
    {name:"쿠바", name_en: "CUBA", code: "+53"},
    {name:"카보베르데", name_en: "CAPE VERDE", code: "+238"},
    {name:"큐라소", name_en: "CURACAO", code: "+599 9"},
    {name:"사이프러스", name_en: "CYPRUS", code: "+357"},
    {name:"체코공화국", name_en: "CZECH REPUBLIC", code: "+420"},

    {name:"독일", name_en: "GERMANY", code: "+49"},
    {name:"지부티", name_en: "DJIBOUTI", code: "+253"},
    {name:"덴마크", name_en: "DENMARK", code: "+45"},
    {name:"도미니카 연방", name_en: "DOMINICA", code: "+1 767"},
    {name:"도미니카 공화국", name_en: "DOMINICAN REPUBLIC", code: "+1 809"},
    {name:"알제리", name_en: "ALGERIA", code: "+1 213"},
    {name:"에콰도르", name_en: "ECUADOR", code: "+593"},
    {name:"에스토니아", name_en: "ESTONIA", code: "+372"},
    {name:"이집트", name_en: "EGYPT", code: "+20"},
    {name:"서사하라", name_en: "WESTERN SAHARA", code: "+212"},

    {name:"에리트레아", name_en: "ERITREA", code: "+291"},
    {name:"스페인", name_en: "SPAIN", code: "+34"},
    {name:"에티오피아", name_en: "ETHIOPIA", code: "+251"},
    {name:"핀란드", name_en: "FINLAND", code: "+358"},
    {name:"피지", name_en: "FIJI", code: "+679"},
    {name:"포클랜드섬", name_en: "FALKLAND ISLANDS", code: "+500"},
    {name:"미크로네시아", name_en: "MICRONESIA", code: "+691"},
    {name:"페로 군도", name_en: "FAROE ISLANDS", code: "+298"},
    {name:"프랑스", name_en: "FRANCE", code: "+33"},
    {name:"가봉", name_en: "GABON", code: "+241"},

    {name:"영국", name_en: "UNITED KINGDOM", code: "+44"},
    {name:"그레나다", name_en: "GRENADA", code: "+1 473"},
    {name:"조지아", name_en: "GEORGIA", code: "+995"},
    {name:"건지", name_en: "GUERNSEY", code: "+44 1481"},
    {name:"가나", name_en: "GHANA", code: "+233"},
    {name:"지브랄타", name_en: "GIBRALTAR", code: "+350"},
    {name:"그린랜드", name_en: "GREENLAND", code: "+299"},
    {name:"감비아", name_en: "GAMBIA", code: "+220"},
    {name:"기니", name_en: "GUINEA", code: "+218"},
    {name:"과들루프", name_en: "GUADELOUPE", code: "+590"},

    {name:"적도 기니", name_en: "EQUATORIAL GUINEA", code: "+240"},
    {name:"그리스", name_en: "GREECE", code: "+30"},
    {name:"과테말라", name_en: "GUATEMALA", code: "+502"},
    {name:"괌", name_en: "GUAM", code: "+1 671"},
    {name:"기네비쏘", name_en: "GUINEA-BISSAU", code: "+245"},
    {name:"가이아나", name_en: "GUYANA", code: "+592"},
    {name:"홍콩", name_en: "HONG KONG", code: "+852"},
    {name:"온두라스", name_en: "HONDURAS", code: "+504"},
    {name:"크로아티아", name_en: "CROATIA", code: "+385"},
    {name:"아이티", name_en: "HAITI", code: "+509"},

    {name:"헝가리", name_en: "HUNGARY", code: "+36"},
    {name:"인도네시아", name_en: "INDONESIA", code: "+62"},
    {name:"아일랜드", name_en: "IRELAND", code: "+353"},
    {name:"이스라엘", name_en: "ISRAEL", code: "+972"},
    {name:"맨섬", name_en: "ISLE OF MAN", code: "+421"},
    {name:"인도", name_en: "INDIA", code: "+91"},
    {name:"이라크", name_en: "IRAQ", code: "+964"},
    {name:"이란", name_en: "IRAN", code: "+98"},
    {name:"아이슬란드", name_en: "ICELAND", code: "+354"},
    {name:"이탈리아", name_en: "ITALY", code: "+39"},

    {name:"저지", name_en: "JERSEY", code: "+44 1534"},
    {name:"자메이카", name_en: "JAMAICA", code: "+1 876"},
    {name:"요르단", name_en: "JORDAN", code: "+962"},
    {name:"일본", name_en: "JAPAN", code: "+81"},
    {name:"케냐", name_en: "KENYA", code: "+254"},
    {name:"키르기즈스탄", name_en: "KYRGYZSTAN", code: "+996"},
    {name:"캄보디아", name_en: "CAMBODIA", code: "+855"},
    {name:"키리바시", name_en: "KIRIBATI", code: "+686"},
    {name:"코모르", name_en: "COMOROS", code: "+269"},
    {name:"북한", name_en: "NORTH KOREA", code: "+850"},

    {name:"대한민국", name_en: "SOUTH KOREA", code: "+82"},
    {name:"코소보", name_en: "KOSOVO", code: "+383"},
    {name:"쿠웨이트", name_en: "KUWAIT", code: "+965"},
    {name:"카자흐스탄", name_en: "KAZAKHSTAN", code: "+7"},
    {name:"라오스", name_en: "LAOS", code: "+856"},
    {name:"레바논", name_en: "LEBANON", code: "+961"},
    {name:"리히텐슈타인", name_en: "LIECHTENSTEIN", code: "+423"},
    {name:"스리랑카", name_en: "SRI LANKA", code: "+94"},
    {name:"라이베리아", name_en: "LIBERIA", code: "+231"},
    {name:"레소토", name_en: "LESOTHO", code: "+266"},

    {name:"리투아니아", name_en: "LITHUANIA", code: "+370"},
    {name:"룩셈부르크", name_en: "LUXEMBOURG", code: "+352"},
    {name:"라트비아", name_en: "LATVIA", code: "+371"},
    {name:"리비아", name_en: "LIBYAN ARAB JAMAHIRIYA", code: "+218"},
    {name:"모로코", name_en: "MOROCCO", code: "+212"},
    {name:"모나코", name_en: "MONACO", code: "+377"},
    {name:"몰도바", name_en: "MOLDOVA", code: "+373"},
    {name:"마다가스카르", name_en: "MADAGASCAR", code: "+261"},
    {name:"마샬군도", name_en: "MARSHALL ISLANDS", code: "+692"},
    {name:"마케도니아", name_en: "MACEDONIA", code: "+389"},

    {name:"말리", name_en: "MALI", code: "+223"},
    {name:"미얀마", name_en: "MYANMAR", code: "+95"},
    {name:"몽골", name_en: "MONGOLIA", code: "+976"},
    {name:"마카오", name_en: "MACAU", code: "+853"},
    {name:"마르티니크", name_en: "MARTINIQUE", code: "+596"},
    {name:"모리타니", name_en: "MAURITANIA", code: "+222"},
    {name:"몬트세라트", name_en: "MONTSERRAT", code: "+1 664"},
    {name:"몰타", name_en: "MALTA", code: "+356"},
    {name:"모리셔스", name_en: "MAURITIUS", code: "+230"},

    {name:"몰디브", name_en: "MALDIVES", code: "+960"},
    {name:"말라위", name_en: "MALAWI", code: "+265"},
    {name:"멕시코", name_en: "MEXICO", code: "+52"},
    {name:"말레이시아", name_en: "MALAYSIA", code: "+60"},
    {name:"모잠비크", name_en: "MOZAMBIQUE", code: "+258"},
    {name:"나미비아", name_en: "NAMIBIA", code: "+264"},
    {name:"뉴칼레도니아", name_en: "NEW CALEDONIA", code: "+687"},
    {name:"니제르", name_en: "NIGER", code: "+227"},
    {name:"나이지리아", name_en: "NIGERIA", code: "+234"},
    {name:"니카라과", name_en: "NICARAGUA", code: "+505"},

    {name:"네덜란드", name_en: "NETHERLANDS", code: "+31"},
    {name:"노르웨이", name_en: "NORWAY", code: "+47"},
    {name:"네팔", name_en: "NEPAL", code: "+977"},
    {name:"나우루", name_en: "NAURU", code: "+674"},
    {name:"니우에", name_en: "NIUE", code: "+683"},
    {name:"뉴질랜드", name_en: "NEW ZEALAND", code: "+64"},
    {name:"오만", name_en: "OMAN", code: "+968"},
    {name:"파나마", name_en: "PANAMA", code: "+507"},
    {name:"페루", name_en: "PERU", code: "+51"},
    {name:"파푸아뉴기니", name_en: "PAPUANEWGUINEA", code: "+675"},

    {name:"필리핀", name_en: "PHILIPPINES", code: "+63"},
    {name:"파키스탄", name_en: "PAKISTAN", code: "+92"},
    {name:"폴란드", name_en: "POLAND", code: "+44 1534"},
    {name:"핏케언 군도", name_en: "PITCAIRN", code: "+64"},
    {name:"푸에르토리코", name_en: "PUERTO RICO", code: "+1 787"},
    {name:"팔레스타인", name_en: "PALESTINE", code: "+970"},
    {name:"포르투갈", name_en: "PORTUGAL", code: "+351"},
    {name:"팔라우", name_en: "PALAU", code: "+680"},
    {name:"파라과이", name_en: "PARAGUAY", code: "+595"},
    {name:"카타르", name_en: "QATAR", code: "+974"},

    {name:"리유니언", name_en: "REUNION", code: "+262"},
    {name:"루마니아", name_en: "ROMANIA", code: "+40"},
    {name:"세르비아", name_en: "SERBIA", code: "+381"},
    {name:"러시아", name_en: "RUSSIAN FEDERATION", code: "+7"},
    {name:"르완다", name_en: "RWANDA", code: "+250"},
    {name:"사우디아라비아", name_en: "SAUDI ARABIA", code: "+966"},
    {name:"솔로몬 군도", name_en: "SOLOMON ISLANDS", code: "+677"},
    {name:"이셸", name_en: "SEYCHELLES", code: "+248"},
    {name:"수단", name_en: "SUDAN", code: "+249"},
    {name:"스웨덴", name_en: "SWEDEN", code: "+46"},

    {name:"싱가포르", name_en: "SINGAPORE", code: "+65"},
    {name:"세인트 헬레나", name_en: "SAINT HELENA", code: "+290"},
    {name:"슬로베니아", name_en: "SLOVENIA", code: "+386"},
    {name:"슬로바키아", name_en: "SLOVAKIA", code: "+421"},
    {name:"시에라리온", name_en: "SIERRA LEONE", code: "+232"},
    {name:"산마리노", name_en: "SAN MARINO", code: "+378"},
    {name:"세네갈", name_en: "SENEGAL", code: "+221"},
    {name:"소말리아", name_en: "SOMALIA", code: "+252"},
    {name:"수리남", name_en: "SURINAME", code: "+597"},
    {name:"남수단", name_en: "SOUTH SUDAN", code: "+211"},

    {name:"엘살바도르", name_en: "EL SALVADOR", code: "+503"},
    {name:"시리아", name_en: "SYRIAN ARAB REPUBLIC", code: "+963"},
    {name:"스와질랜드", name_en: "SWAZILAND", code: "+268"},
    {name:"차드", name_en: "CHAD", code: "+235"},
    {name:"토고", name_en: "TOGO", code: "+228"},
    {name:"태국", name_en: "THAILAND", code: "+66"},
    {name:"타지키스탄", name_en: "TAJIKISTAN", code: "+992"},
    {name:"토켈라우", name_en: "TOKELAU", code: "+690"},
    {name:"동티모르", name_en: "EAST TIMOR", code: "+670"},
    {name:"투르크메니스탄", name_en: "TURKMENISTAN", code: "+993"},

    {name:"튀니지", name_en: "TUNISIA", code: "+1 216"},
    {name:"통가", name_en: "TONGA", code: "+676"},
    {name:"터키", name_en: "TURKEY", code: "+90"},
    {name:"트리니다드토바고", name_en: "TRINIDAD AND TOBAGO", code: "+868"},
    {name:"투발루", name_en: "TUVALU", code: "+688"},
    {name:"대만", name_en: "TAIWAN", code: "+886"},
    {name:"탄자니아", name_en: "TANZANIA", code: "+255"},
    {name:"우크라이나", name_en: "UKRAINE", code: "+380"},
    {name:"우간다", name_en: "UGANDA", code: "+256"},
    {name:"미국", name_en: "UNITED STATES", code: "+1"},

    {name:"우루과이", name_en: "URUGUAY", code: "+598"},
    {name:"우즈베키스탄", name_en: "UZBEKISTAN", code: "+998"},
    {name:"바티칸", name_en: "VATICAN CITY STATE", code: "+379"},
    {name:"세인트 빈센트 그레나딘스", name_en: "SAINT VINCENT AND THE GRENADINES", code: "+1 784"},
    {name:"베네수엘라", name_en: "VENEZUELA", code: "+52"},
    {name:"베트남", name_en: "VIET NAM", code: "+84"},
    {name:"바누아투", name_en: "VANUATU", code: "+678"},
    {name:"사모아", name_en: "SAMOA", code: "+685"},
    {name:"예멘", name_en: "YEMEN", code: "+967"},
    {name:"마요트", name_en: "MAYOTTE", code: "+269"},

    {name:"유고슬라비아", name_en: "YUGOSLAVIA", code: "+38"},
    {name:"남아프리카공화국", name_en: "SOUTH AFRICA", code: "+27"},
    {name:"잠비아", name_en: "ZAMBIA", code: "+260"},
    {name:"자이르", name_en: "ZAIRE", code: "+243"},
    {name:"짐바브웨", name_en: "ZIMBABWE", code: "+263"},
    {name:"국적불명", name_en: "UNKNOWN", code: ""}
]

export const language = Object.freeze([
    {"code":"ko","name":"한국어 - KO","en_name":"Korean - KO","original":"한국어"},
    {"code":"en","name":"영어 - EN","en_name":"English - EN","original":"English"},
    {"code":"zh","name":"중국어 - ZH","en_name":"Chinese - ZH","original":"中文"},
    {"code":"hi","name":"힌디어 - HI","en_name":"Hindi - HI","original":"हिन्दी"},
    {"code":"es","name":"스페인어 - ES","en_name":"Spanish - ES","original":"Espanol"},
    {"code":"fr","name":"프랑스어 - FR","en_name":"French - FR","original":"Le français"},
    {"code":"ar","name":"아랍어 - AR","en_name":"Arabic - AR","original":"العربية"},
    {"code":"bn","name":"벵골어 - BN","en_name":"Bengali - BN","original":"বাংলা"},
    {"code":"ru","name":"러시아어 - RU","en_name":"Russian - RU","original":"русский"},
    {"code":"pt","name":"포르투칼어 - PT","en_name":"Portuguese - PT","original":"Português"},
    {"code":"ur","name":"우르두어 - UR","en_name":"Urdu - UR","original":"اُردُو"},
    {"code":"id","name":"인도네시아어 - ID","en_name":"Indonesian - ID","original":"bahasa"},
    {"code":"de","name":"독일어 - DE","en_name":"German - DE","original":"Deutsch"},
    {"code":"ja","name":"일본어 - JA","en_name":"Japanese - JA","original":"日本語"},
    {"code":"mr","name":"마라타어 - MR","en_name":"Marathi - MR","original":"मराठी"},
    {"code":"te","name":"텔루구어 - TE","en_name":"Telugu - TE","original":"తెలుగు"},
    {"code":"tr","name":"터키어 - TR","en_name":"Turkish - TR","original":"Türk"},
    {"code":"ta","name":"타밀어 - TA","en_name":"Tamil - TA","original":"Tamil"},
    {"code":"vi","name":"베트남어 - VI","en_name":"Vietnamese - VI","original":"tiếng Việt"},
    {"code":"nl","name":"네덜란드어 - NL","en_name":"Dutch - NL","original":"Nederlands"},
    {"code":"bg","name":"불가리아어 - BG","en_name":"Bulgarian - BG","original":"български"},
    {"code":"cs","name":"체코어 - CS","en_name":"Czech - CS","original":"Česky"},
    {"code":"da","name":"덴마크어 - DA","en_name":"Danish - DA","original":"Dansk"},
    {"code":"el","name":"그리스어 - EL","en_name":"Greek - EL","original":"Ελληνικά"},
    {"code":"et","name":"에스토니아어 - ET","en_name":"Estonian - ET","original":"Eestlane"},
    {"code":"fi","name":"필란드어 - FI","en_name":"Finnish - FI","original":"Suomen kielen"},
    {"code":"ga","name":"아일랜드어 - GA","en_name":"Irish - GA","original":"Gaeilge"},
    {"code":"he","name":"히브리어 - HE","en_name":"Hebrew - HE","original":"עברית"},
    {"code":"hr","name":"크로아티아어 - HR","en_name":"Croatian - HR","original":"hrvatski"},
    {"code":"hu","name":"헝가리어 - HU","en_name":"Hungarian - HU","original":"magyar"},
    {"code":"it","name":"이탈리아어 - IT","en_name":"Italian - IT","original":"italiano"},
    {"code":"lt","name":"리투아니아어 - LT","en_name":"Lithuanian - LT","original":"Lietuvis"},
    {"code":"lv","name":"라트비아어 - LV","en_name":"Latvian - LV","original":"Latvietis"},
    {"code":"no","name":"노르웨이어 - NO","en_name":"Norwegian - NO","original":"Norsk"},
    {"code":"pl","name":"폴란드어 - PL","en_name":"Polish - PL","original":"Polski"},
    {"code":"ro","name":"루마니아어 - RO","en_name":"Romanian - RO","original":"românesc"},
    {"code":"sk","name":"슬로바키아어 - SK","en_name":"Slovak - SK","original":"slovenský"},
    {"code":"sl","name":"슬로베니아어 - SL","en_name":"Slovenian - SL","original":"Slovensko"},
    {"code":"sr","name":"세르비아어 - SR","en_name":"Serbian - SR","original":"Српски"},
    {"code":"sv","name":"스웨덴어 - SV","en_name":"Swedish - SV","original":"Svenska"},
    {"code":"th","name":"태국어 - TH","en_name":"Thai - TH","original":"ไทย"},
    {"code":"uk","name":"우크라이나어 - UK","en_name":"Ukrainian - UK","original":"Українська"},
    // {"code":"aa","name":"Afar - AA","en_name":"Afar - AA","original":"Afar"},
    // {"code":"ab","name":"Abkhazian - AB","en_name":"Abkhazian - AB","original":"Abkhazian"},
    // {"code":"ae","name":"Avestan - AE","en_name":"Avestan - AE","original":"Avestan"},
    {"code":"af","name":"아프리칸스어 - AF","en_name":"Afrikaans - AF","original":"Afrikaans"},
    // {"code":"ak","name":"Akan - AK","en_name":"Akan - AK","original":"Akan"},
    // {"code":"am","name":"Amharic - AM","en_name":"Amharic - AM","original":"Amharic"},
    // {"code":"an","name":"Aragonese - AN","en_name":"Aragonese - AN","original":"Aragonese"},
    // {"code":"as","name":"Assamese - AS","en_name":"Assamese - AS","original":"Assamese"},
    // {"code":"av","name":"Avaric - AV","en_name":"Avaric - AV","original":"Avaric"},
    // {"code":"ay","name":"Aymara - AY","en_name":"Aymara - AY","original":"Aymara"},
    {"code":"az","name":"아제르바이잔어 - AZ","en_name":"Azerbaijani - AZ","original":"Azerbaijani"},
    {"code":"ba","name":"바슈키르어 - BA","en_name":"Bashkir - BA","original":"Bashkir"},
    {"code":"be","name":"벨라루스어 - BE","en_name":"Belarusian - BE","original":"Belarusian"},
    {"code":"bh","name":"비하리어 - BH","en_name":"Bihari - BH","original":"Bihari "},
    {"code":"bi","name":"비슐라마 - BI","en_name":"Bislama - BI","original":"Bislama"},
    {"code":"bm","name":"밤바라어 - BM","en_name":"Bambara - BM","original":"Bambara"},
    {"code":"bo","name":"티베트어 - BO","en_name":"Tibetan - BO","original":"Tibetan"},
    {"code":"br","name":"브레통어 - BR","en_name":"Breton - BR","original":"Breton"},
    {"code":"bs","name":"보스니아어 - BS","en_name":"Bosnian - BS","original":"Bosnian"},
    {"code":"ca","name":"카탈루냐어 - CA","en_name":"Catalan - CA","original":"Catalan; Valencian"},
    {"code":"ce","name":"체첸어 - CE","en_name":"Chechen - CE","original":"Chechen"},
    {"code":"ch","name":"차모로어 - CH","en_name":"Chamorro - CH","original":"Chamorro"},
    {"code":"co","name":"코르시카어 - CO","en_name":"Corsican - CO","original":"Corsican"},
    {"code":"cr","name":"크리어 - CR","en_name":"Cree - CR","original":"Cree"},
    // {"code":"cu","name":"Old Church Slavonic - CU","en_name":"Old Church Slavonic - CU","original":"Old Church Slavonic"},
    // {"code":"cv","name":"Chuvash - CV","en_name":"Chuvash - CV","original":"Chuvash"},
    // {"code":"cy","name":"Welsh - CY","en_name":"Welsh - CY","original":"Welsh"},
    {"code":"dv","name":"디베히어 - DV","en_name":"Dhivehi - DV","original":"Dhivehi; Maldivian"},
    // {"code":"dz","name":"Dzongkha - DZ","en_name":"Dzongkha - DZ","original":"Dzongkha"},
    // {"code":"ee","name":"Ewe - EE","en_name":"Ewe - EE","original":"Ewe"},
    {"code":"eo","name":"에스페란토 - EO","en_name":"Esperanto - EO","original":"Esperanto"},
    {"code":"eu","name":"바스크어 - EU","en_name":"Basque - EU","original":"Basque"},
    {"code":"fa","name":"페르시아어 - FA","en_name":"Persian - FA","original":"Persian"},
    {"code":"ff","name":"풀라어 - FF","en_name":"Fulah - FF","original":"Fulah"},
    {"code":"fj","name":"피지어 - FJ","en_name":"Fijian - FJ","original":"Fijian"},
    {"code":"fo","name":"페로어 - FO","en_name":"Faroese - FO","original":"Faroese"},
    {"code":"fy","name":"프리지아어 - FY","en_name":"Western Frisian - FY","original":"Western Frisian"},
    {"code":"gd","name":"게일어 - GD","en_name":"Gaelic - GD","original":"Gaelic; Scottish Gaelic"},
    {"code":"gl","name":"갈리시아어 - GL","en_name":"Galician - GL","original":"Galician"},
    {"code":"gn","name":"과라니어 - GN","en_name":"Guarani - GN","original":"Guarani"},
    {"code":"gu","name":"구자라티어 - GU","en_name":"Gujarati - GU","original":"Gujarati"},
    // {"code":"gv","name":"맨어 (맹크스) - GV","en_name":"Manx - GV","original":"Manx"},
    {"code":"ha","name":"하우사어 - HA","en_name":"Hausa - HA","original":"Hausa"},
    // {"code":"ho","name":"Hiri Motu - HO","en_name":"Hiri Motu - HO","original":"Hiri Motu"},
    {"code":"ht","name":"아이티크리올어 - HT","en_name":"Haitian - HT","original":"Haitian; Haitian Creole"},
    {"code":"hy","name":"아르메니아어 - HY","en_name":"Armenian - HY","original":"Armenian"},
    {"code":"hz","name":"헤레로어 - HZ","en_name":"Herero - HZ","original":"Herero"},
    // {"code":"ia","name":"인터링구아 - IA","en_name":"Interlingua - IA","original":"Interlingua"},
    // {"code":"ie","name":"Interlingue; Occidental - IE","en_name":"Interlingue; Occidental - IE","original":"Interlingue; Occidental"},
    // {"code":"ig","name":"Igbo - IG","en_name":"Igbo - IG","original":"Igbo"},
    // {"code":"ii","name":"이어 (쓰촨에서 사용) - II","en_name":"Sichuan Yi - II","original":"Sichuan Yi; Nuosu"},
    {"code":"ik","name":"이누피아크어 - IK","en_name":"Inupiaq - IK","original":"Inupiaq"},
    {"code":"io","name":"이도 - IO","en_name":"Ido - IO","original":"Ido"},
    {"code":"is","name":"아이슬란드어 - IS","en_name":"Icelandic - IS","original":"Icelandic"},
    {"code":"iu","name":"이누이트어 - IU","en_name":"Inuktitut - IU","original":"Inuktitut"},
    {"code":"jv","name":"자바어 - JV","en_name":"Javanese - JV","original":"Javanese"},
    {"code":"ka","name":"그루지야어 - KA","en_name":"Georgian - KA","original":"Georgian"},
    {"code":"kg","name":"콩고어 - KG","en_name":"Kongo - KG","original":"Kongo"},
    {"code":"ki","name":"키쿠유어 - KI","en_name":"Kikuyu - KI","original":"Kikuyu; Gikuyu"},
    {"code":"kj","name":"콰냐마어 - KJ","en_name":"Kuanyama - KJ","original":"Kuanyama; Kwanyama"},
    {"code":"kk","name":"카자흐어 - KK","en_name":"Kazakh - KK","original":"Kazakh"},
    {"code":"kl","name":"그린란드어 - KL","en_name":"Greenlandic - KL","original":"Kalaallisut; Greenlandic"},
    {"code":"km","name":"크메르어 - KM","en_name":"Central Khmer - KM","original":"Central Khmer"},
    {"code":"kn","name":"칸나다어 - KN","en_name":"Kannada - KN","original":"Kannada"},
    {"code":"kr","name":"카누리어 - KR","en_name":"Kanuri - KR","original":"Kanuri"},
    {"code":"ks","name":"카슈미르어 - KS","en_name":"Kashmiri - KS","original":"Kashmiri"},
    {"code":"ku","name":"쿠르드어 - KU","en_name":"Kurdish - KU","original":"Kurdish"},
    // {"code":"kv","name":"페름어 (코미어) - KV","en_name":"Komi - KV","original":"Komi"},
    // {"code":"kw","name":"Cornish - KW","en_name":"Cornish - KW","original":"Cornish"},
    {"code":"ky","name":"키르기스어 - KY","en_name":"Kirghiz - KY","original":"Kirghiz; Kyrgyz"},
    {"code":"la","name":"라틴어 - LA","en_name":"Latin - LA","original":"Latin"},
    {"code":"lb","name":"룩셈부르크어 - LB","en_name":"Luxembourgish - LB","original":"Letzeburgesch"},
    {"code":"lg","name":"간다어 - LG","en_name":"Ganda - LG","original":"Ganda"},
    {"code":"li","name":"림뷔르흐어 - LI","en_name":"Limburgish - LI","original":"Limburgish"},
    {"code":"ln","name":"링갈라어 - LN","en_name":"Lingala - LN","original":"Lingala"},
    {"code":"lo","name":"라오어 - LO","en_name":"Lao - LO","original":"Lao"},
    // {"code":"lu","name":"루바-카탕가어 - LU","en_name":"Luba-Katanga - LU","original":"Luba-Katanga"},
    {"code":"mg","name":"마다가스카르어 - MG","en_name":"Malagasy - MG","original":"Malagasy"},
    // {"code":"mh","name":"Marshallese - MH","en_name":"Marshallese - MH","original":"Marshallese"},
    {"code":"mi","name":"마오리어 - MI","en_name":"Maori - MI","original":"Maori"},
    {"code":"mk","name":"마케도니아어 - MK","en_name":"Macedonian - MK","original":"Macedonian"},
    {"code":"ml","name":"말라얄람어 - ML","en_name":"Malayalam - ML","original":"Malayalam"},
    {"code":"mn","name":"몽골어 - MN","en_name":"Mongolian - MN","original":"Mongolian"},
    {"code":"ms","name":"말레이어 - MS","en_name":"Malay - MS","original":"Malay"},
    {"code":"mt","name":"몰타어 - MT","en_name":"Maltese - MT","original":"Maltese"},
    // {"code":"my","name":"Burmese - MY","en_name":"Burmese - MY","original":"Burmese"},
    {"code":"na","name":"나우루어 - NA","en_name":"Nauru - NA","original":"Nauru"},
    // {"code":"nb","name":"Norwegian Bokmål - NB","en_name":"Norwegian Bokmål - NB","original":"Norwegian Bokmål"},
    // {"code":"nd","name":"North Ndebele - ND","en_name":"North Ndebele - ND","original":"North Ndebele"},
    {"code":"ne","name":"네팔어 - NE","en_name":"Nepali - NE","original":"Nepali"},
    // {"code":"ng","name":"Ndonga - NG","en_name":"Ndonga - NG","original":"Ndonga"},
    // {"code":"nn","name":"Norwegian Nynorsk - NN","en_name":"Norwegian Nynorsk - NN","original":"Norwegian Nynorsk"},
    // {"code":"nr","name":"South Ndebele - NR","en_name":"South Ndebele - NR","original":"South Ndebele"},
    {"code":"nv","name":"나바호어 - NV","en_name":"Navajo - NV","original":"Navajo; Navaho"},
    // {"code":"ny","name":"Chichewa - NY","en_name":"Chichewa - NY","original":"Chichewa; Chewa; Nyanja"},
    // {"code":"oc","name":"프로방스어(남부 프랑스) - OC","en_name":"Occitan - OC","original":"Occitan (post 1500)"},
    {"code":"oj","name":"오지브와어 - OJ","en_name":"Ojibwa - OJ","original":"Ojibwa"},
    // {"code":"om","name":"갈라어 (오로모어) - OM","en_name":"Oromo - OM","original":"Oromo"},
    {"code":"or","name":"오리야어 - OR","en_name":"Oriya - OR","original":"Oriya"},
    {"code":"os","name":"오세트어 - OS","en_name":"Ossetian - OS","original":"Ossetian; Ossetic"},
    {"code":"pa","name":"펀자브어 - PA","en_name":"Panjabi - PA","original":"Panjabi; Punjabi"},
    {"code":"pi","name":"팔리어 - PI","en_name":"Pali - PI","original":"Pali"},
    {"code":"ps","name":"파슈툰어 - PS","en_name":"Pushto; Pashto - PS","original":"Pushto; Pashto"},
    {"code":"qu","name":"케추아어 - QU","en_name":"Quechua - QU","original":"Quechua"},
    {"code":"rm","name":"레토-로만어 - RM","en_name":"Romansh - RM","original":"Romansh"},
    {"code":"rn","name":"룬디어 - RN","en_name":"Rundi - RN","original":"Rundi"},
    {"code":"rw","name":"르완다어 - RW","en_name":"Kinyarwanda - RW","original":"Kinyarwanda"},
    // {"code":"sa","name":"산스크리트(범어) - SA","en_name":"Sanskrit - SA","original":"Sanskrit"},
    {"code":"sc","name":"사르데냐어 - SC","en_name":"Sardinian - SC","original":"Sardinian"},
    {"code":"sd","name":"신드어 - SD","en_name":"Sindhi - SD","original":"Sindhi"},
    {"code":"se","name":"북사미어 - SE","en_name":"Northern Sami - SE","original":"Northern Sami"},
    // {"code":"sg","name":"Sango - SG","en_name":"Sango - SG","original":"Sango"},
    {"code":"si","name":"싱할라어 - SI","en_name":"Sinhala - SI","original":"Sinhala; Sinhalese"},
    {"code":"sm","name":"사모아어 - SM","en_name":"Samoan - SM","original":"Samoan"},
    {"code":"sn","name":"쇼나어 - SN","en_name":"Shona - SN","original":"Shona"},
    {"code":"so","name":"소말리어 - SO","en_name":"Somali - SO","original":"Somali"},
    {"code":"sq","name":"알바니아어 - SQ","en_name":"Albanian - SQ","original":"Albanian"},
    {"code":"ss","name":"스와티어 - SS","en_name":"Swati - SS","original":"Swati"},
    {"code":"st","name":"남소토어 - ST","en_name":"Sotho,Southern - ST","original":"Sotho,Southern"},
    {"code":"su","name":"순다어 - SU","en_name":"Sundanese - SU","original":"Sundanese"},
    {"code":"sw","name":"스와힐리어 - SW","en_name":"Swahili - SW","original":"Swahili"},
    {"code":"tg","name":"타지크어 - TG","en_name":"Tajik - TG","original":"Tajik"},
    {"code":"ti","name":"티그리냐어 - TI","en_name":"Tigrinya - TI","original":"Tigrinya"},
    {"code":"tk","name":"투르크멘어 - TK","en_name":"Turkmen - TK","original":"Turkmen"},
    {"code":"tl","name":"타갈로그 - TL","en_name":"Tagalog - TL","original":"Tagalog"},
    {"code":"tn","name":"츠와나어 - TN","en_name":"Tswana - TN","original":"Tswana"},
    {"code":"to","name":"통가어 - TO","en_name":"Tonga - TO","original":"Tonga"},
    {"code":"ts","name":"총가어 - TS","en_name":"Tsonga - TS","original":"Tsonga"},
    {"code":"tt","name":"타타르어 - TT","en_name":"Tatar - TT","original":"Tatar"},
    {"code":"tw","name":"트위어 - TW","en_name":"Twi - TW","original":"Twi"},
    {"code":"ty","name":"타이티어 - TY","en_name":"Tahitian - TY","original":"Tahitian"},
    {"code":"ug","name":"위구르어 - UG","en_name":"Uighur - UG","original":"Uighur; Uyghur"},
    {"code":"uz","name":"우즈베크어 - UZ","en_name":"Uzbek - UZ","original":"Uzbek"},
    {"code":"ve","name":"벤다어 - VE","en_name":"Venda - VE","original":"Venda"},
    {"code":"vo","name":"볼라퓌크 - VO","en_name":"Volapük - VO","original":"Volapük"},
    {"code":"wa","name":"왈론어 - WA","en_name":"Walloon - WA","original":"Walloon"},
    {"code":"wo","name":"월로프어 - WO","en_name":"Wolof - WO","original":"Wolof"},
    {"code":"xh","name":"코사어 - XH","en_name":"Xhosa - XH","original":"Xhosa"},
    {"code":"yi","name":"이디시어 - YI","en_name":"Yiddish - YI","original":"Yiddish"},
    {"code":"yo","name":"요루바어 - YO","en_name":"Yoruba - YO","original":"Yoruba"},
    // {"code":"za","name":"Zhuang - ZA","en_name":"Zhuang - ZA","original":"Zhuang; Chuang"},
    // {"code":"zu","name":"Zulu - ZU","en_name":"Zulu - ZU","original":"Zulu"}]
]
)


export const notice = [
    {
        languageCode: "ko",
        title: "사운드리퍼블리카 신규 서비스 출시",
        questions: "사운드리퍼블리카 신규 서비스 출시",
        answer: "안녕하세요.<br><br>"+
        "음원유통 및 정산 서비스 Sound Republica Distribution(SRD)이 출시되었습니다.<br><br>"+
        "더 나은 고객 경험을 위해 준비한 새로운 서비스에 여러분의 많은 관심과 사랑 부탁드립니다.<br><br>"+
        "감사합니다."
    },
    {
        languageCode: "ko",
        title: "정보 수정 요청하기 (사운드리퍼블리카 유통음원만)",
        questions: "정보 수정 요청하기 (사운드리퍼블리카 유통음원만)",
        answer:`
        사운드리퍼블리카를 통해 유통한 음원에 대해 수정요청을 신청하는 양식입니다. <br><br>
        유통음원,아티스트,권리자 정보 수정이 필요하면 아래 양식으로 제출해주세요.<br><br>
        정보수정요청하기 :  <a href="https://repb.li/modifymusic" style="border-bottom:1px solid; font-weight:bold; " target="_blank">https://repb.li/modifymusic</a><br><br>
        *접수 후 수정은 불가능합니다.<br><br>
        *발매 후 수정은 1회만 가능합니다.<br><br>
        *이미 발매가 되었다면, 실제 수정반영까지 다소 시일이 소요될 수 있습니다. 수정완료는 별도로 안내드리지 않습니다.<br><br>
        *새로운 음원을 발매 할때, 발매자료를 사전에 꼼꼼히 검토해주세요.<br><br>
        *신규음원 유통문의는 이곳으로 문의주세요.<br>
        신규유통 및 발매일정 문의 : <a href=" https://www.soundrepublica.com/contact" style="border-bottom:1px solid; font-weight:bold; " target="_blank"> https://www.soundrepublica.com/contact</a>
        <br>
        <br>
        `
    },



    {
        languageCode: "en",
        title: "Sound Republica Launches New Service.",
        questions: "Sound Republica Launches New Service.",
        answer: "Hello,<br><br>"+
        "We are excited to announce the release of our new service, Sound Republica Distribution(SRD).<br>"+
        "You can upload your music and get royalty report from our website.<br>"+
        "We believe that SRD will help you enjoy your experience with us even more.<br><br>"+
        "Thank you."
    },
    {
        languageCode: "en",
        title: "Request information correction (Only Sound Republica Distribution Music)",
        questions: "Request information correction (Only Sound Republica Distribution Music)",
        answer:`
        This is a form to request modification of the music distributed through Sound Republica.<br><br>
        If you need to modify music metadata , artist, and licensor information, please submit it in the form below. <br><br>
        Request information correction: <a href="https://repb.li/modifymusic" style="border-bottom:1px solid; font-weight:bold; " target="_blank">https://repb.li/modifymusic</a><br><br>
        *Please fill out the form carefully as we are unable to edit it after submission. <br><br>
        *Post-release modification is only available once. <br><br>
        *If it has already been released, it may take some time for the actual correction to be reflected. We don't inform you of the completion of the modification.<br><br>
        *When releasing a new song, please review the release materials carefully in advance.<br><br>
        *Please contact us for inquiries on the distribution of the new sound source.<br> 
        Inquiries about the new distribution and release schedule:  <a href="https://www.soundrepublica.com/contact" style="border-bottom:1px solid; font-weight:bold; " target="_blank"> https://www.soundrepublica.com/contact</a>
        <br><br>
        `
    },
]

export const faq = [
    {
        languageCode: "ko",
        title: "본인이 직접 뮤직 스토어들에 음원을 공급할수는 없나요?",
        questions: "본인이 직접 뮤직 스토어들에 음원을 공급할수는 없나요?",
        answer: `네. 뮤직스토어들은 음원 유통사를 통한 음원을 공급만 받습니다. <br>
        유통사는 아티스트 또는 레이블을 대신해서 음원을 각 뮤직스토어에 필요한 형태로 바꾸어 공급하는 역할을 합니다. `
    },
    {
        languageCode: "en",
        title: "Can't I supply the music to the music stores myself?",
        questions: "Can't I supply the music to the music stores myself?",
        answer: `
        Yes. Music stores only receive music sources through music distributors. <br>
        The distributor specializes in preparing sound metadata forms for each music store instead of artists or labels.
        `
    },
    {
        languageCode: "ko",
        title: "회원가입하고 싶습니다.",
        questions: "회원가입하고 싶습니다.",
        answer: `
        이 사이트는 권한이 있는 사용자만 사용할 수 있습니다. <br>
        새로 유통을 희망하신다면, 데모곡과 간단한 소개를 메일로 먼저 보내주세요.
        `
    },
    {
        languageCode: "en",
        title: "I'd like to sign up for the site.",
        questions: "I'd like to sign up for the site.",
        answer: `
        This site is only available to authorized people.  <br>
        If you want to distribute it anew, please send me a demo song and a brief introduction by e-mail first.
        `
    },
    
    {
        languageCode: "ko",
        title: "지난달에 발매를 했습니다. 첫 정산은 언제 받아볼 수 있나요?",
        questions: "지난달에 발매를 했습니다. 첫 정산은 언제 받아볼 수 있나요?",
        answer: `
        뮤직스토어마다 정산 주기가 다르지만, 일반적으로 서비스월(음원이 판매된 월) 2-3달 뒤에 정산을 받기 시작합니다. <br>
        예를 들어 앨범을 1월에 발매를 했다면, 3월 정산자료에서 1월 서비스분에 대한 판매 내용을 확인할 수 있고,<br>
         4월 정산자료에서 2월 서비스분에 대한 판매내용을 확인할 수 있습니다.
        `
    },
    {
        languageCode: "en",
        title: "It was released last month. When can I get the first settlement?",
        questions: "It was released last month. When can I get the first settlement?",
        answer: `
        
        Although the settlement cycle varies from music store to store, <br>
        you typically start receiving settlements two to three months after the month of service (the month the music was sold).
        <br> For example, if the album was released in January, you can check the sales details of the January service in the March report data.<br>
         and you can check the sales details of the February service in the April report data.
        `
    },
    {
        languageCode: "ko",
        title: "정산내역은 어떤 주기로 업데이트되나요?",
        questions: "정산내역은 어떤 주기로 업데이트되나요?",
        answer: `
        한 달에 한 번, 매달 20일 경(영업일 기준) 업데이트 됩니다.<br>
        이번달 정산리포트는 일반적으로 2달 전 서비스된 내역에 대한 내용을 담고 있습니다.<br>
        예를 들어, 3월에 업데이트된 리포트는 1월의 서비스 판매 내역을 주로 담고 있습니다. <br>
        정산주기는 스토어마다 달라 특정 정산월에 여러 서비스월에 대한 정산을 지급하는 때도 있습니다.
        `
    },
    {
        languageCode: "en",
        title: "When will the Royalty details be updated?",
        questions: "When will the Royalty details be updated?",
        answer: `
        Once a month, it will be updated around the 20th of each month (on business days).
        `
    },

    {
        languageCode: "ko",
        title: "정산내역을 엑셀로 받아볼 수 있나요?",
        questions: "정산내역을 엑셀로 받아볼 수 있나요?",
        answer: `
       
        상세한 정산내역을 엑셀파일로 받기 위해서는 먼저 [정산/출금] 메뉴로 들어가셔서 [정산] 탭에서 다운받고자 하는 기간 설정 검색 후, <br>
         하단 [정산 상세 내역] 우측에 [엑셀 다운로드] 를 클릭해주세요. <br>
        그 밖에 [통계] 메뉴에서 제공하는 모든 표도 [엑셀 다운로드]가 가능하니 참고 부탁드립니다.
        `
    },
    {
        languageCode: "en",
        title: "I want to get Sales Report Details .xlsx detailed statement.",
        questions: "I want to get Sales Report Details .xlsx detailed statement.",
        answer: `
        Please, go to [Royalties] on your menu, press [Sales] tab, <br>
        and you will be able to find the latest data. Down on that page, you will find the [Report Details]. <br>
        At the right of [Report Details], you will find the [Excel Download].<br>
        Also, you can get statistics excel file at [Statistics] menu.
        `
    },
    {
        languageCode: "ko",
        title: "잔액 확인은 어떻게 할 수 있나요?",
        questions: "잔액 확인은 어떻게 할 수 있나요?",
        answer: `
        [정산/출금]메뉴에서 [출금]탭으로 가시면 출금신청 가능한 잔액을 확인할 수 있습니다.
        `
    },
    {
        languageCode: "en",
        title: "How can I check the balance?",
        questions: "How can I check the balance?",
        answer: `
            You can check the balance you can apply for withdrawal by going to [Payment] tab from [Royalties] menu.
        `
    },

    {
        languageCode: "ko",
        title: "출금을 신청하고 싶습니다.",
        questions: "출금을 신청하고 싶습니다.",
        answer: `
        [정산/출금]메뉴의 [출금]탭에서 출금을 신청할 수 있습니다. <br>
        잔액이 최소 출금신청 가능 금액을 넘어야 가진 있는 잔액 전체에 대해 출금이 신청됩니다. <br>
        특히, 사업자 또는 국외거주자의 경우, 출금신청 후 별도 계산서 발행이 확인되어야 실지급이 진행되고 있으니 유의해주세요. <br>
        (계산서 미발행 시 신청이 반려됩니다.)<br>
        출금신청 및 계산서 발행은 월의 마지막 영업일 전날까지 부탁드립니다.
        `
    },
    {
        languageCode: "en",
        title: "I'd like to request a withdrawal.",
        questions: "I'd like to request a withdrawal.",
        answer: `
        You can apply for withdrawal from the Withdrawal tab of the [Royalties] menu. <br>
        The balance must exceed the minimum withdrawal applicable amount to apply for withdrawal. <br>
        In particular, in the case of business operators or non-Korean residents, <br>
        the actual payment is being made only when the separate bill is confirmed after the withdrawal application. <br>
        (If the bill is not issued, the application will be rejected.)<br>
        Please request a withdrawal and issue an invoice before the last business day of the month.
        `
    },
    
    {
        languageCode: "ko",
        title: "정산금 입금이 되지 않았습니다.",
        questions: "정산금 입금이 되지 않았습니다.",
        answer: `
            출금신청건은 월 지급 마감 이후 신청은 최대 30일 이내로 지급됩니다.
        `
    },
    {
        languageCode: "en",
        title: "The withdrawal application amount has not been deposited.",
        questions: "The withdrawal application amount has not been deposited.",
        answer: `
        Applications after the deadline will be processed within 30 days from the date of request.
        `
    },
    {
        languageCode: "ko",
        title: "트랙별로 판매추이를 볼 수 있나요?",
        questions: "트랙별로 판매추이를 볼 수 있나요?",
        answer: `
        [통계] 메뉴 [앨범/트랙별] 탭에서 선택한 기간 동안 발생한 로그에 대해 앨범별 트랙별 추이를 제공하고 있습니다. <br>
        통계 메뉴에서 표시되는 금액은 "권리자정산금액" 기준입니다.
        `
    },
    {
        languageCode: "en",
        title: "Can I see the sales trend by track?",
        questions: "Can I see the sales trend by track?",
        answer: `
        Provides track sales trend that occurred during the selected period on [Statistics] menu - [Album/Track] tab. <br>
        The amount displayed in the Statistics menu is based on the amount of "Your Share".
        `
    },
    {
        languageCode: "ko",
        title: "정산월과 서비스월의 차이가 뭔가요?",
        questions: "정산월과 서비스월의 차이가 뭔가요?",
        answer: `
            “정산월“은 권리자가 판매된 대금을 정산받는 월입니다.<br>
            “서비스월“은 권리자의 음원이 뮤직스토어에서 판매가 된 월입니다.<br>
            정산내역은 일반적으로 서비스(판매)가 발생한 날로부터 2개월 뒤에 공개됩니다. <br>
            예를 들어, 1월에 뮤직스토어에서 판매된 내역은 3월 정산 내역에서 확인할 수 있습니다.
        `
    },
    {
        languageCode: "en",
        title: "What is the difference between Report Month and Service Month?",
        questions: "What is the difference between Report Month and Service Month?",
        answer: `
        “Service Month” is the month when the sales were generated from the music stores.<br>
        “Report Month” is the month when Sound Republica received the money from the music stores.<br>
        On average, “Report Month” is two months after “Service Month”, but it varies by each music store.<br>
        (For the sales amount generated on the “Service Month” of January, Sound Republica receives the money from the music stores in March, in which case March is the “Report Month”)
        `
    },
    {
        languageCode: "ko",
        title: "유통신청 후 수정 (발매 전 수정) 가능한가요?",
        questions: "유통신청 후 수정 (발매 전 수정) 가능한가요?",
        answer: `
            제출 후 아직 발매일 확정이 진행되지 않았다면, 유통문의 메일을 통해 데이터 수정을 요청할 수 있습니다.<br>
            -수정할 내용을 정리하여 별도 메일 문의를 남겨주세요.<br>
            -담당자가 바로 조회 및 처리 할 수 있도록 권리자와 앨범의 기본정보를 알려주세요.<br>
            -포함 내용 <br>
            수신메일 : music@soundrepublica.com <br>
            메일제목 : [발매 전 정보수정요청] 앨범아티스트명- 앨범명 <br>
            메일 내용 : SRD ID / 권리자명 / 앨범명 / 수정이 필요한 내용<br>
            -신청취소를 하더라도 내용을 다시 수정할수 없기 때문에, 수정요청사항은 꼭 별도 문의부탁드립니다.<br>
            -발매일이 확정된 경우, 발매 후 수정에 해당합니다.
        `
    },
    {
        languageCode: "en",
        title: "Can I modify my album after submission? (pre-release modification)",
        questions: "Can I modify my album after submission? (pre-release modification)",
        answer: `
        If the release date has not yet been confirmed, you can request for your album to be modified.<br>
        - Organize the details of your modification and inquire us by e-mail. <br>
        - Include your licensor information (registered e-mail address) and album name.<br>
        - Please use the following format when writing your e-mail. <br>
            * Send to: music@soundrepublica.com <br>
            * Subject: [Album Modification Before Release] Album Artist Name - Album Name <br>
            * Mention your licensor name, e-mail address as well as your album name.<br>
        - Note: canceling your submission will not allow you to edit your album again. Please contact us for any modifications.<br>
        - If your release date has already been confirmed, please refer to Revision After Release section in our FAQ
        `
    },
    {
        languageCode: "ko",
        title: "발매 후 수정 가능한가요?",
        questions: "발매 후 수정 가능한가요?",
        answer: `
        발매일이 확정된 경우, 데이터 수정은 단 한번만 가능합니다. <br>
        각 뮤직스토어로 전달하기위해 담당자가 데이터 가공을 진행할 수 있기 때문에 , 반드시 별도 수정요청을 진행해주셔야합니다. <br>
        단, 이미 발매된 경우, 스토어의 데이터 반영에는 최소 1주일 이상 시간이 소요될 수 있습니다. <br>
        -수정할 내용을 꼼꼼하게 검토하여 내용을 작성해주세요. <br>
        -담당자가 바로 조회 및 처리할 수 있도록 권리자와 앨범의 기본정보를 알려주세요. <br>
        -포함 내용 <br>
        수신메일 : music@soundrepublica.com <br>
        메일제목 : [발매 후 정보수정요청] 앨범아티스트명- 앨범명 - 발매일 <br>
        메일 내용 : SRD ID / 권리자명 / 앨범명 / 발매일 / 수정이 필요한 내용  <br>
        `
    },
    {
        languageCode: "en",
        title: "Is it possible to revise my album after release?",
        questions: "Is it possible to revise my album after release?",
        answer: `
        After your release date has been confirmed your are allowed only 1 time to revise your data.<br>
        Note: revisions may take up to one week to be reflected in each music store.<br>
        - Organize the details of your modification and inquire us by e-mail. <br>
        - Include your licensor information (registered e-mail address) and album name.<br>
        - Please use the following format when writing your e-mail.<br>
            * Send to: music@soundrepublica.com<br>
            * Subject: [Album Modification After Release] <Album Artist Name> - <Album Name> - <Release Date><br>
            * Mention your licensor name, e-mail address as well as your album name and release date
        `
    },
    // {
    //     languageCode: "ko",
    //     title: "",
    //     questions: "",
    //     answer: `
            
    //     `
    // },
    // {
    //     languageCode: "",
    //     title: "",
    //     questions: "",
    //     answer: `
        
    //     `
    // },
    // {
    //     languageCode: "ko",
    //     title: "[음원유통] 온라인으로 음원을 판매하기 위해 제가 무엇을 준비하면 되나요?",
    //     questions: "[음원유통] 온라인으로 음원을 판매하기 위해<br>제가 무엇을 준비하면 되나요?",
    //     answer: "당신의 음악을 온라인으로 유통하기 위해 다음 자료들이 필요합니다.<br>"+
    //     "앨범과 트랙의 메타데이타<br>"+
    //     "ex) 이름 , 장르, 언어 등의 정보<br>"+
    //     "이미지 파일들 ( 커버아트 이미지 , 아티스트 프로필 이미지 , 레이블로고 이미지 )<br>"+
    //     "오디오 파일들<br><br>"+
    //     "* 모든 자료는 발매 전에 준비되어어야 합니다."
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] What do I need to prepare in order to distribute my music online?",
    //     questions: "[Music Distribution] What do I need to prepare in order<br>to distribute my music online?",
    //     answer: "To sell your music online, you need to prepare the following:<br>"+
    //     "Metadata (Title, Genre, Language, etc.)<br>"+
    //     "Audio Files (WAV or FLAC, MP3)<br>"+
    //     "Image Files ( Cover Art / Artist Picture / Label Logo )<br><br>"+
    //     "*Note: All this information is required so please prepare in advance."
    // },
    // {
    //     languageCode: "ko",
    //     title: "[음원유통] 오디오파일 : 오디오 파일은 어떤 형식이어야 하죠?",
    //     questions: "[음원유통] 오디오파일 : 오디오 파일은 어떤 형식이어야 하죠?",
    //     answer: "모든 음원에는 음원 파일이 필요하며 음원은 다음 형식들에 맞아야 합니다.<br>"+
    //     "파일 형식 :WAV, FLAC, MP3<br>"+
    //     "파일 품질 : 최소 샘플레이트 44.1khz/16bit per sample<br>"+
    //     "파일 크기 : 최대 200MB"
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] Audio Files: In what format should I provide the audio files?",
    //     questions: "[Music Distribution] Audio Files: In what format should I provide the audio files?",
    //     answer: "For every track you should provide at least one audio file.<br>"+
    //     "Accepted file formats:  WAVE (*.wav)  FLAC (*.flac) MP3 (*.mp3)<br>"+
    //     "Min. Audio Quality: 44.1khz sample rate / 16bit per sample<br>"+
    //     "File Size: max. 200MB"
    // },
    // {
    //     languageCode: "ko",
    //     title: "[음원유통] 커버 아트 : 커버 아트(표지)는 어떤 형식이어야 하죠?",
    //     questions: "[음원유통] 커버 아트 : 커버 아트(표지)는<br>어떤 형식이어야 하죠?",
    //     answer: "모든 발매에는 커버아트가 필요합니다.<br><br>커버아트는 다음 형식을 따라야 합니다.<br>"+
    //     "- 파일 형식 : JPG<br>"+
    //     "- 사이즈 : 3000 X 3000 픽셀 권장 (최소 1400픽셀 이상). 완벽한 정사각형(가로세로 비율 1:1)<br>"+
    //     "*300DPI 권장 (최소 72DPI 이상)<br>"+
    //     "- 컬러 모드 : RGB<br>"+
    //     "- 뮤직 스토어들은 커버 아트가 로고,URL, 기타 다른 서비스와 연관성이 있는 어떤 것,<br>또는 저품질의 이미지를 등록 거부합니다. 또한 같은 커버 아트를 재사용하지 마세요.<br><br>"+
    //     "커버 아트 내용에 대한 다른 중요한 기준들은 다음과 같습니다<br>"+
    //     "- 흐릿한 이미지 또는 픽셀 화 된 이미지는 허용되지 않습니다.<br>"+
    //     "- 잘린 이미지, 특히 잘라낸 텍스트는 허용되지 않습니다.<br>"+
    //     "- 회전된 이미지는 허용되지 않습니다.<br>"+
    //     "- 성적 노출이 심한 이미지는 허용되지 않습니다.<br>"+
    //     "- 물리적 릴리즈 에 대한 표시 (예 : \CD\“또는 \“Compact disk\” ) 는 허용되지 않습니다.<br>"+
    //     "- 나치 선전은 허용되지 않습니다."
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] Image Files: In what format should I provide the covert art?",
    //     questions: "[Music Distribution] Image Files:<br>In what format should I provide the covert art?",
    //     answer: "A cover image is required for your release.<br>"+
    //     "- Format: JPG (*.jpg/jpeg)<br>"+
    //     "- Size: 3000 X 3000 pixels with a perfect square ratio (1:1) (Accept 1400 X 1400 pixels and above.)<br>"+
    //     "*Resolution: Recommended at 300 DPI (Accept 72 DPI and above.)<br>"+
    //     "- Color Mode: RGB<br>"+
    //     "* Note: Music stores will REJECT cover art that contains references (logo, url) to other services,<br>"+
    //     "blurry or pixelated images, cut-off images, images with cut-off text, rotated images,<br>"+
    //     "images containing pornographic material, images containing nazi propaganda,<br>"+
    //     "images containing references to physical releases<br>"+
    //     "(ex. \CD\" or \"compact disk\") or poor quality images. Do not reuse the same cover art<br>"
    // },
    // {
    //     languageCode: "ko",
    //     title: "[음원유통] 제가 직접 뮤직 스토어들에 음원을 공급할수 있나요?",
    //     questions: "[음원유통] 제가 직접 뮤직 스토어들에 음원을 공급할수 있나요?",
    //     answer: "아니요<br>디지털 스토어들이나 스트리밍 서비스들은 음원 유통사를 통한 음원을 공급만 받습니다.<br>"+
    //     "우리는 당신의 대리인으로, 당신의 음원을 맡아서 공급해드릴 수 있습니다."
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] Can I distribute my music to digital stores by myself?",
    //     questions: "[Music Distribution] Can I distribute my music to digital stores by myself?",
    //     answer: "No,<br>digital stores and streaming services only work with aggregators and labels.<br>"+
    //     "We are your representatives and will keep track of your music and digital sales."
    // },
    // {
    //     languageCode: "kr",
    //     title: "[음원유통] ISRC코드가 뭔가요?",
    //     questions: "[음원유통] ISRC코드가 뭔가요?",
    //     answer: "ISRC(국제 표준 녹음 코드,International Standard Recording Code)는<br>"+
    //     "녹음 및 비디오 녹음을 위한 국제 식별 시스템입니다.<br><br>"+
    //     "코드가 없거나 모르는 경우 비워 두십시오. 저희가 발급해드릴 수 있습니다."
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] What is a ISRC code?",
    //     questions: "[Music Distribution] What is a ISRC code?",
    //     answer: "International Standard Recording Code (ISRC) is a international identification system for sound and music video recordings.<br>"+
    //     "If you don’t know or don’t have codes,<br>"+
    //     "Sound Republica can issue the required codes."
    // },
    // {
    //     title: "[Music Distribution] What is a UCI code?",
    //     questions: "[Music Distribution] What is a UCI code?",
    //     answer: "Universal Content Identifier (UCI) is the national standard identification system in Korea<br>"+
    //     "to identify music contents. <br><br>"+
    //     "If South Korea nationality, MIMS member name is required to issue the code."
    // },
    // {
    //     languageCode: "ko",
    //     title: "[음원유통] UPC 코드가 뭔가요?",
    //     questions: "[음원유통] UPC 코드가 뭔가요?",
    //     answer: "UPC(Universal Product Code, 범용 상품 부호)는 <br>"+
    //     "매장에서 제품을 추적하기 위한 12 자리 바코드로 널리 사용되고 있는 코드입니다.<br><br>"+
    //     "코드가 없거나 모르는 경우 비워 두십시오.<br>저희가 발급해드리겠습니다."
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] What is a UPC code?",
    //     questions: "[Music Distribution] What is a UPC code?",
    //     answer: "Universal Product Code (UPC) is a widely used 12digits barcode for tracking items in stores.<br>"+
    //     "If you don’t know or don’t have codes,<br><br>"+
    //     "Sound Republica can issue the required codes.<br>"
    // },
    // {
    //     languageCode: "ko",
    //     title: "[음원유통] 어떤 국가에 음원을 발매할 수 있나요?",
    //     questions: "[음원유통] 어떤 국가에 음원을 발매할 수 있나요?",
    //     answer: "전 세계에 발매할 수 있습니다.<br>"+
    //     "또한 당신은 특정한 지역이나 스토어를 선택하여 발매할 수 있습니다."
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] In which countries can I release my music?",
    //     questions: "[Music Distribution] In which countries can I release my music?",
    //     answer: "You can release your music world wide!<br>"+
    //     "You also have the option to select specific territories and/or music stores."
    // },
    // {
    //     languageCode: "ko",
    //     title: "[음원유통] 발매하고자 하는 음원 가사에 선정적이거나 부적절한 표현(Explicit) 이 있어도 되나요?",
    //     questions: "[음원유통] 발매하고자 하는 <br>"+
    //     "음원 가사에 선정적이거나 부적절한 표현(Explicit) 이 있어도 되나요?",
    //     answer: "네.<br>그러나 선정적이거나 부적절한 표현(Explicit)이 있는 경우, <br>"+
    //     "업로드 시 반드시 explicit 여부를 체크해주셔야 합니다.<br>"+
    //     "그러면 각 스토어들에 explicit이 체크되어 발매될 것입니다."
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] Can I have explicit lyrics in my release?",
    //     questions: "[Music Distribution] Can I have explicit lyrics in my release?<br>",
    //     answer: "Yes,<br>"+
    //     "however, you must mark your track as 'explicit'. <br>"+
    //     "It will be tagged as an explicit release in the online stores."
    // },
    // {
    //     languageCode: "ko",
    //     title: "[음원유통] 원하는 장르가 목록에 있지 않습니다. 어떻게 해야하죠?",
    //     questions: "[음원유통] 원하는 장르가 목록에 있지 않습니다. <br>어떻게 해야하죠?",
    //     answer: "내가 원하는 발매장르가 없는 경우, 가장 가까운 장르를 선택해주세요.<br><br>"+
    //     "스토어마다 가지고 있는 장르 목록이 달라 원하는 장르가<br>"+
    //     "정확하게 없을 수 있습니다."
    // },
    // {
    //     languageCode: "en",
    //     title: "[Music Distribution] The genre that I am looking for is not available. What do I do?",
    //     questions: "[Music Distribution] The genre that I am looking for is not available.<br>What do I do?",
    //     answer: "Please select the closest alternative genre.<br><br>"+
    //     "Note that genres are not the same in every music store.<br>"+
    //     "Concider setting the iTunes genres to ensure a smooth delivery process."
    // },
    // {
    //     languageCode:"ko",
    //     title: "[음원유통] Beatport(비트포트)에 음악을 유통하고 싶어요.",
    //     questions: "[음원유통] Beatport(비트포트)에 음악을 유통하고 싶어요.",
    //     answer: "Beatport(비트포트)는  <br>"+
    //     "Electronic(일렉트로닉) 또는 Dance(댄스) 장르만 취급하는 뮤직 스토어 입니다. <br>"+
    //     "만약 당신의 음악이 이 두 장르가 아닐 경우,<br>"+
    //     "유통을 거절당할 수 있습니다. <br>"+
    //     "또 처음 Beatport(비트포트)에 음원을 유통하는 경우, <br>레이블 이미지와 레이블명 정보가 필요합니다."
    // },
    // {
    //     languageCode:"en",
    //     title: "[Music Distribution] I want to distribute my music on Beatport.",
    //     questions: "[Music Distribution] I want to distribute my music on Beatport.",
    //     answer: "Beatport only accepts tracks in the Electronic and Dance genre.<br>"+
    //     "If this is your first release on Beatport,<br>"+
    //     "you will need to register your label.<br>"+
    //     "Please provide us with your Label Name and Label Logo Image."
    // }
]
  

// 이용약관
export const terms = {
    ko: {
        chapter_1:{
            title: "",
            contents: "사운드리퍼블리카에 방문하신 것을 환영합니다. 다음은 귀하가 본 사이트와 “사운드리퍼블리카 서비스”(아래에서 정의됨)를 이용함에 있어 적용되는 이용약관(이하 “본 약관” 또는 “본 이용약관”)입니다. 본 약관의 내용을 확인하고 이에 동의한다는 체크박스에 표시한 후 본 사이트의 회원으로 가입하실 경우 귀하는 본 약관에 동의하는 것으로 간주되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기 바랍니다.<br><br>"+ 
            "본 약관은 대한민국 내에서 “사운드리퍼블리카 서비스”를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.  사운드리퍼블리카(본 계약에서 사운드리퍼블리카의 라이선스와 양수인도 “저희”, “사운드리퍼블리카”, “회사”로 표현됨)가 해외 권리자 등과의 계약 체결을 통하여 해당 국가에서의 서비스가 가능한 경우 이 약관은 해당 국가 내에서 서비스를 이용하고자 하는 모든 회원에 대하여도 그 효력을 발생합니다. 영문 약관은 해외에서 본 서비스를 이용하는 회원의 편의를 위하여 제공되며, 한글 약관이 영문 약관에 우선합니다.<br><br>"+
            "본 약관의 내용은 사운드리퍼블리카 홈페이지에 게시하거나 기타의 방법으로 공시하고, 본 사이트 및 사운드리퍼블리카 서비스의 이용을 희망하는 자(이하 “이용희망자”)가 이에 동의하고 서비스에 가입함으로써 효력이 발생합니다. 사운드리퍼블리카는 이용희망자가 본 약관에 동의하기에 앞서 약관의 내용을 쉽게 이해하여 착오 없이 거래할 수 있도록 하여 이용희망자의 확인을 구합니다.<br><br>"+
            "귀하는 본 약관을 읽고 동의하신 후 회원 가입을 신청하실 수 있으며, 사운드리퍼블리카는 이에 대한 승낙을 통해 회원 가입 절차를 완료하고 귀하에게 서비스 이용 계정을 부여합니다. 계정이란 회원이 “사운드리퍼블리카 서비스”에 로그인한 이후 이용하는 각종 서비스 이용 이력을 회원 별로 관리하기 위해 설정한 회원 식별 단위를 말합니다.<br><br>"+
            "사운드리퍼블리카는 필요하다고 인정하는 경우, '약관의 규제에 관한 법률', '정보통신망 이용촉진 및 정보보호에 관한 법률', '콘텐츠산업 진흥법', '전자상거래 등에서의 소비자보호에 관한 법률' 등 관련 법령을 위반하지 않는 범위에서 이 약관을 변경할 수 있으며, 사운드리퍼블리카가 약관을 변경하는 경우에는 적용일자 및 변경사유를 명시하여 그 적용일자 7일 전부터 본 사이트에 공지하고, 회원에게 불리한 약관의 변경의 경우 개정 내용을 회원이 알기 쉽게 표시하여 그 적용일자 14일 전부터 공지하며 회원에게 개별 통지합니다. 회원의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 경우, 회원이 등록한 연락처로 공지를 하였음에도 2회 이상 반송된 경우 이 약관에 의한 공지를 함으로써 개별 통지한 것으로 간주합니다. 회원이 변경된 약관에 동의하지 않는 경우, 회원은 본인의 회원등록을 취소(회원탈퇴)할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이내에 거부의사를 표시하지 아니하고 서비스를 계속 사용하는 경우에는 약관 변경에 대한 동의가 있는 것으로 간주됩니다.<br><br>"+
            "본 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다. 단, 이 약관의 일부 조항은 회원이 탈퇴 후에도 유효하게 적용될 수 있습니다.<br><br>"
        },
        chapter_2:{
            title: "1.'Sound Republica Service” “사운드리퍼블리카 서비스'",
            contents: "사운드리퍼블리카는 본 사이트를 통하여 인터넷을 기반으로 하는 다양한 종류의 서비스와 상품(이하 모든 서비스를 총칭하여 “사운드리퍼블리카 서비스”, “서비스” 또는 “상품”)을 제공합니다. 사운드리퍼블리카 이용자들은 본 사이트를 통하여 스스로 이용하거나 판매하기 위한 “상품”을 제작하고 판매할 수도 있습니다. 사운드리퍼블리카의 서비스를 이용하여 엄선된 디지털 스토어와 서비스에 음원을 유통하고자 하는 아티스트 또는 레이블 이용자들은 “사운드리퍼블리카 유통 계약”을 체결해야 합니다."
        },
        chapter_3:{
            title: "2.본 사이트와 '사운드리퍼블리카 서비스'의 이용",
            contents: "(a) 이용자격<br>"+
            "사운드리퍼블리카는 적용되는 법률에 의거하여 합법적으로 계약을 체결할 수 있는 당사자만을 대상으로 “사운드리퍼블리카 서비스”를 제공합니다. 귀하가 만 18세 이하지만 최소 만14세 이상이라면, 귀하는 부모님이나 법정대리인의 감독 하에 부모님이나 법정대리인이 본 이용약관에 구속되는 것을 동의하는 경우에 한하여 “사운드리퍼블리카 서비스”를 이용할 수 있습니다. “사운드리퍼블리카 서비스”는 만 14세 미만의 아동을 위한 서비스가 아닙니다.<br><br>"+   
            "(b) 해외 이용자<br>"+
            "(1) 귀하가 대한민국 국적이 아니며, 대한민국 법과는 다른 개인정보 수집, 이용, 공개에 대한 법이나 규정이 적용되는 유럽연합, 아시아, 기타 지역에서 “사운드리퍼블리카 서비스”와 본 사이트에 접속하는 고객이라면, 귀하의 개인정보가 대한민국으로 이전된다는 점과 대한민국은 유럽연합 및 다른 지역들과 동일한 수준의 개인정보보호법이 없다는 점을 주의해주시기 바랍니다.<br><br>"+
            "(2) 유럽연합과 유럽경제지역: “사운드리퍼블리카 서비스”와 본 사이트는 유럽연합이나 유럽경제지역에서 접속하는 만16세 미만의 아동을 위한 서비스가 아닙니다.<br><br>"+ 
            "(c) 이용약관 및 적용되는 법령의 준수<br>"+
            "귀하는 “사운드리퍼블리카 서비스”와 본 사이트를 이용함에 있어 본 이용약관, 본 약관과 관련하여 적용될 수 있는 계약과 정책 및 이에 적용되는 모든 대한민국의 법률, 규정을 준수하여야 합니다.<br><br>"+
            "(d) 본 사이트와 “사운드리퍼블리카 서비스”에 관한 권리 및 이용권한<br><br>"+
            "(1) 사운드리퍼블리카와 라이선서들은, 본 이용약관에 명시된 경우를 제외하고는, 단독으로 그리고 독점적으로 “사운드리퍼블리카 서비스”와 본 사이트상의 모든 지식재산권과 기타 권리, 권한, 이익을 소유합니다. 귀하는 본 이용약관 또는 기타 다른 이유를 근거로 어떠한 권리나 권한, 이익을 취득할 수 없습니다.<br><br>"+
            "(2) 귀하사가 본 이용약관을 준수하는 경우, 회사는 귀하에게 본 사이트와 사운드리퍼블리카 서비스의 이용목적에 부합하는 한도 내에서, 본 사이트 및 “사운드리퍼블리카 서비스”에 대한 접근 및 이용을 허락하는, 제한적이고 취소가능한 자격 및 권한을 부여합니다. 본 자격은 사운드리퍼블리카가 금지한 목적으로 본 사이트에 포함된 정보를 수집하고 이용할 권리, 적법한 권한 없이 “상품”이나 본 사이트를 통하여 이용가능한 제3자의 콘텐츠에 기반한 2차적저작물을 작성할 권리 또는 (페이지 저장을 넘어) 사이트를 다운로드하거나 복제할 권리를 포함하지 않습니다. 귀하가 본 자격의 범위를 넘어서거나 본 이용약관을 위반하는 방법으로 본 사이트를 이용하는 경우, 사운드리퍼블리카는 귀하에게 부여된 이용자격 및 권한을 취소할 수 있습니다.<br><br>"+ 
            "(3) 본 이용약관 제2조 (d)항의 내용은 귀하의 지식재산권에 적용되지 않습니다. 본 사이트에 귀하가 업로드하는 콘텐츠와 관련된 모든 권리들은 본 이용약관의 적용을 받습니다.<br><br>"+ 
            "(e) 제3자 제공 서비스<br>"+
            "'사운드리퍼블리카'는 본 사이트를 통하여 특정 서비스를 제공하기 위해 외부업체를 이용할 수 있습니다. 사운드리퍼블리카는 해당 외부업체나 그 서비스를 감독하거나 통제하지 않으며, 귀하는 사운드리퍼블리카가 귀하의 해당 서비스 이용과 관련하여 어떠한 방식으로든 귀하에 대해 법적 책임을 부담하지 않는다는 점에 동의합니다. 외부업체는 자신의 약관 및 정책이 있을 수 있습니다. 귀하는 외부업체가 제공하는 서비스를 이용하는 경우 본 이용약관뿐만 아니라 해당 업체들의 약관 및 정책도 함께 준수할 의무가 있습니다. 해당 업체들의 약관 및 정책이 사운드리퍼블리카의 이용약관, 계약 및 정책과 충돌하는 경우, 귀하는 사운드리퍼블리카의 이용약관, 계약 및 정책을 준수하여야 합니다.<br><br>"+
            "2.사이트 이용 규칙<br>"+
            "(a) 금지 행위.<br>"+
            "사운드리퍼블리카가 명시적으로 허용한 경우를 제외하고는, 귀하는 다음의 행위를 할 수 없습니다: (i) 바이러스, 소프트웨어나 하드웨어의 기능에 지장을 주거나 손상시키기 위한 프로그램 또는 기술로 “사운드리퍼블리카 서비스”를 방해하는 행위; (ii) “사운드리퍼블리카 서비스”를 제공하기 위해 사용된 기술을 수정하거나, 해당 기술을 토대로 2차적저작물을 만들거나, 리버스 엔지니어링, 디컴파일링 또는 디어셈블링하는 행위; (iii)  인터넷 “검색 엔진”, 웹 카운터 또는 유사 기술의 운영 또는 이용을 제외하고, 본 사이트상의 활동을 모니터링하거나 사이트로부터 웹페이지를 복제하기 위하여 로봇, 스파이더나 다른 장치 또는 프로세스를 이용하는 행위; (iv) “사운드리퍼블리카 서비스”를 이용하여 제3자의 이메일 주소나 기타 정보를 수집하는 행위; (v) 다른 개인이나 단체를 사칭하는 행위; (vi) 사운드리퍼블리카의 이름이나 상호를 포함하는 메타 태그, 검색어, 핵심어 또는 그와 유사한 것을 사용하는 행위; (vii) 다른 이용자가 “사운드리퍼블리카 서비스”를 이용하고 즐기는 것을 방해하는 행위; (viii) 제3자가 본 약관에 의해 금지되는 행위를 하는 것을 돕거나 장려하는 행위<br><br>"+
            "(b) 모니터링.<br>"+
            "사운드리퍼블리카는 본 사이트에서 이루어지는 거래와 커뮤니케이션을 모니터링할 수 있지만, 모니터링에 대한 의무를 부담하는 것은 아닙니다. 사운드리퍼블리카가 회사의 재량에 따라, 귀하가 본 약관의 내용을 위반하였다거나 또는 귀하나 다른 이용자의 거래나 커뮤니케이션이 부적절하였다고 판단하는 경우, 사운드리퍼블리카는, 귀하나 제3자에 대한 어떠한 책임 없이, 해당 거래를 취소하거나 이용자의 접근 또는 이용을 금지하는 조치를 취할 수 있습니다.<br><br>"+
            "(c) 서비스의 변경.<br>"+
            "사운드리퍼블리카는 “사운드리퍼블리카 서비스”를 귀하에 대한 사전 통지 없이 변경할 수 있으며, 그로 인한 법적 책임을 부담하지는 않습니다. 즉 사운드리퍼블리카는 특정 서비스의 지속적 제공을 보장하지 않습니다.<br><br>"+
            "3.제출<br>"+
            "귀하가 질의, 코멘트, 제안, 아이디어, 전자게시판 포스팅, 웹양식으로 제출된 내용, 콘테스트 참가, 커뮤니케이션 또는 기타 정보(이하 “제출물”)를 제출하는 경우, 귀하의 제출물은 귀하의 음원과 음원정보를 홍보하기 위하여 사용되거나, 사운드리퍼블리카가 제공하는 서비스의 운영, 개선, 홍보 및 새로운 서비스 개발의 제한적인 목적으로 사용될 수 있습니다. 귀하는 사운드리퍼블리카가 제출물을 공개적으로 이용할 수 있다는 점을 인지하고, 제출물에 비밀로 유지해야 하는 사항, 민감한 개인정보 등이 포함되지 않도록 하여야 합니다. 귀하가 이러한 사전 조치를 취하지 않음으로써 입게 된 손해는 본인에게 귀속되며, 이에 대하여 사운드리퍼블리카는 법적 책임을 부담하지 않는다는 점을 주의하시기 바랍니다. 귀하는 사운드리퍼블리카가 제출물을 사용하더라도 저작권(저작인격권 포함) 기타 유사 권리에 기반하여 사운드리퍼블리카에 대하여 소송 등 클레임을 제기할 수 없습니다.<br><br>"+
            "4.진술 및 보장<br>"+
            "(a) 상호 진술 및 보장.<br>"+
            "귀하는 사운드리퍼블리카에, 사운드리퍼블리카는 귀하에게 다음의 사항을 진술 및 보장합니다: (i) 귀하와 사운드리퍼블리카는 본 이용약관 및 이용계약을 체결하고 약관과 계약상의 권리 및 의무를 이행할 완전한 능력과 권한이 있습니다, (ii) 귀하와 사운드리퍼블리카가 본 이용약관에 따른 의무를 이행하는 것이 귀하나 사운드리퍼블리카를 구속하는 다른 계약이나 합의서에 대한 위반이 되거나 그와 상충되는 것이 아닙니다, (iii) 본 이용약관은 본 약관을 체결한 당사자들에 대하여 법적으로 유효하고 구속력 있는 의무이며, 그 내용은 강제될 수 있습니다.<br><br>"+ 
            "귀하의 진술 및 보장.<br>"+
            "귀하는 '사운드리퍼블리카 서비스'의 이용과 관련하여, 사운드리퍼블리카에 다음의 사실을 진술 및 보장합니다. (i) 귀하는 제3자의 저작권, 상표권, 특허권, 영업비밀, 프라이버시권, 퍼블리시티권 및 기타 법상 권리를 침해하지 않을 것입니다. (ii) 귀하는 모든 법률, 규정, 규칙 등을 준수할 것입니다. 또한 귀하는 사운드리퍼블리카에 다음의 사실을 진술 및 보장합니다: (i) 귀하가 알고 있는 한도에서, 귀하의 제출물에 관하여 현재 진행 중이거나 임박한 권리 주장, 요구, 소송, 고소 등의 분쟁이 존재하지 않습니다. (ii) 사운드리퍼블리카는  “사운드리퍼블리카 서비스”를 제공함에 있어 발생하는 비용을 제외하고, 귀하의 제출물의 사용과 관련하여 제3자로부터 어떠한 금원의 지급을 요구받지 않을 것입니다; (iii) 귀하의 제출물에 포함된 설명, 공식, 추천 기타 유사한 것에 대한 이용은 제3자를 해하지 않을 것입니다; (iv) 귀하의 제출물들은 소프트웨어나 하드웨어를 손상시키도록 디자인된 바이러스, 기타 프로그램, 기술을 포함하고 있지 않습니다.<br><br>"+
            "5.책임제한에 관한 공지<br>"+
            "(a) 보증의 부인.<br>"+
            "사운드리퍼블리카는 본 사이트와 '사운드리퍼블리카 서비스'를 ‘현재 상태 그대로’ 그리고 ‘이용가능한 대로’의 상태를 기준으로 제공합니다. 사운드리퍼블리카는 본 사이트와 '사운드리퍼블리카 서비스' 및 그 이용에 관하여 다음의 사항을 진술 및 보장하지 않습니다; (i) 본 사이트와 '사운드리퍼블리카 서비스'는 중단되지 않을 것이라는 점, (ii) 부정확한 사항이나 오류가 없을 것이라는 점, (iii) 귀하의 요구를 충족시킬 것이라는 점, (iv) 귀하가 사용하는 컴퓨터 환경설정이나 하드웨어 또는 소프트웨어에서 작동될 것이라는 점. 사운드리퍼블리카는 본 이용약관에 명시적으로 표시된 것을 제외하고는 어떠한 보장도 하지 않으며, 특정 목적 및 상품성에 대한 적합성 그리고 비침해에 대한 보장을 포함하되 이에 한정됨 없이 모든 묵시적 보증을 부인합니다.<br><br>"+ 
            "(b) 손해배상의 제외.<br>"+
            "사운드리퍼블리카의 고의 또는 중대한 과실로 인하여 “사운드리퍼블리카 서비스”의 이용과 관련하여 귀하와 제3자에게 손해가 발생한 경우, 사운드리퍼블리카는 귀하와 제3자에게 실제 발생한 손해만을 배상합니다.<br><br>"+
            "회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 사운드리퍼블리카가 당해 회원 이외의 제3자로부터 손해배상청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 사운드리퍼블리카를 면책하여야 하며, 사운드리퍼블리카가 면책되지 못한 경우 당해 회원은 그로 인하여 사운드리퍼블리카에 발생한 모든 손해를 배상하여야 합니다.<br><br>"+
            "(c) 책임의 제한.<br>"+
            "본 이용약관상 당사자의 진술 및 보장을 위반한 경우 또는 귀하의 본 이용약관상의 배상의무와 관련된 경우를 제외하고는, 당사자의 책임은 어떠한 경우에도 본 이용약관과 관련되어 있지 않습니다.<br><br>"+
            "6.면책<br><br>"+
            "회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다. 사운드리퍼블리카는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 사운드리퍼블리카는 회원이 사이트에 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등 내용에 대하여는 책임을 지지 않습니다. 사운드리퍼블리카는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.<br><br>"+
            "귀하는 사운드리퍼블리카와 그 직원들, 대표자, 대리인, 계열사, 이사, 직원, 매니저, 주주(“당사자들”)들을, 귀하가 본 이용약관을 위반하였다고 주장하는 “당사자들”을 상대로 한 제3자의 청구, 요구, 법적 조치(“클레임”)와 관련하여 발생한 손해, 상실, 비용 (변호사 비용을 포함하되 이에 한정되지 아니함)으로부터 면책하여야 합니다.<br><br>"+ 
            "귀하가 본 섹션의 내용에 따라 사운드리퍼블리카를 면책해야 하는 경우, 사운드리퍼블리카는 귀하의 비용부담으로 이루어지는 소송 또는 중재상 방어, 합의, 클레임 등 분쟁의 해결을 통제할 권리가 있습니다. 귀하는 사운드리퍼블리카의 명시적인 서면 동의 없이는 클레임을 해결하거나 결론지을 수 없습니다.<br><br>"+
            "7.종료<br>"+
            "(a) 종료.<br>"+
            "사운드리퍼블리카는, 전적인 재량적 판단에 따라 귀하가 본 이용약관을 위반하였다고 판단하는 경우, 귀하의 본 사이트 또는 “사운드리퍼블리카 서비스” 이용을 중지하거나 종료시킬 수 있습니다.<br><br>"+
            "(b) 효력의 유지.<br>"+
            "본 이용약관은 사운드리퍼블리카가 사용을 종료하기로 결정하기 전까지는 무기한으로 효력이 유지됩니다.<br><br>"+
            "종료의 효과.<br>"+
            "사운드리퍼블리카가 귀하의 본 사이트 또는 “사운드리퍼블리카 서비스”의 이용을 종료하는 경우, 사운드리퍼블리카는 사운드리퍼블리카의 서버상에 있거나 사운드리퍼블리카가 다른 방법으로 소유하게 된 귀하의 “사운드리퍼블리카 서비스” 이용에 관계된 제출물 또는 기타 자료들을 삭제할 수 있고, 사운드리퍼블리카는 이에 대하여 귀하나 제3자에 대한 법적 책임을 부담하지 않습니다.<br><br>"+
            "8.통지<br>"+
            "본 이용약관에 따라 필요하거나 허용된 모든 통지는 서면으로 이메일을 통하여 상대방에게 전달될 것입니다. 귀하가 사운드리퍼블리카에 통지를 하는 경우에는 다음의 주소를 사용하여야 합니다:<br>"+
            "music@soundrepublica.com<br>"+
            "사운드리퍼블리카는 회원이 사운드리퍼블리카에 제공한 연락처로 회원에게 통지합니다. 모든 통지는, “시스템 에러”나 기타 발송 실패의 통지가 발생하지 않는 경우에는 메시지가 발송된 때로부터 48시간 후에 수령된 것으로 간주됩니다. 다만, 회원이 제공한 연락처로 공지를 하였음에도 2회 이상 반송된 경우에는 마지막 발송일로부터 48시간 후에 통지가 수령된 것으로 간주됩니다. 적용가능한 법이 특정 의사표현을 ‘서면으로’ 하도록 하는 경우, 귀하는 이메일을 통한 의사표현이 본 ‘서면’ 의사표현에 해당한다는 점에 동의합니다.<br><br>"+
            "9.분쟁해결<br>"+
            "(a) 강제적 중재.<br>"+
            "본 내용은 귀하의 권리에 중요한 영향을 미치므로, 주의하여 읽어주시기 바랍니다. 귀하와 사운드리퍼블리카와 각각의 자회사, 계열사, 이해관계 있는 전임자, 후임자 및 허락된 양수인은, 본 약관이나 귀하의 서비스 이용과 관련하여 발생한 모든 분쟁과 청구에 대하여, 후술된 경우를 제외하고는, 중재가 분쟁해결의 전속적 수단임을 받아들입니다.<br><br>"+ 
            "[귀하가 대한민국 국민인 경우]<br><br>"+
            "귀하는 본 이용약관에 동의함으로써, 회원과 사운드리퍼블리카 사이의 본 이용약관 및 사운드리퍼블리카 서비스의 이용과 관련하여 발생하는 모든 분쟁은 대한민국 서울의 대한상사중재원에서 국내중재규칙과 대한민국 법에 따라 중재에 의하여 최종적으로 해결된다는 점에 동의하게 됩니다. 중재판정부는 양당사자들의 합의에 따라 1인으로 구성되며, 중재에 사용될 언어는 한국어입니다.<br><br>"+
            "[귀하가 대한민국이 아닌 기타 국가의 국적을 가진 경우]<br><br>"+
            "귀하는 본 이용약관에 동의함으로써, 회원과 사운드리퍼블리카 사이의 본 이용약관 및 사운드리퍼블리카 서비스의 이용과 관련하여 발생하는 모든 분쟁은 대한민국 서울의 대한상사중재원에서 국제중재규칙과 대한민국 법에 따라 중재에 의하여 최종적으로 해결된다는 점에 동의하게 됩니다. 중재판정부는 양당사자들의 합의에 따라 1인으로 구성하는 것으로 합니다.<br><br>"+
            "(b) 집단소송의 금지.<br>"+
            "귀하는 집단소송 또는 대표소송으로 알려진 절차에서 원고나 집단의 구성원이 되어서는 아니 됩니다. 또한, 귀하는 중재인이 1인 이상의 청구를 통합할 수 없다는 점과 대표소송이나 집단소송 형태의 절차를 주재할 수 없다는 점, 그리고 이 특정 조건이 실행될 수 없는 것으로 밝혀지는 경우 본 강제적 중재 섹션의 모든 규정은 효력이 없다는 점에 동의합니다.<br><br>"+
            "(c) 준거법<br>"+
            "본 이용약관과 귀하의 “사운드리퍼블리카 서비스” 및 본 사이트의 이용은 대한민국법에 의하여 해석되고 규율됩니다.<br><br>"+
            "(d) 상응하는 구제.<br>"+
            "분쟁해결 섹션의 상술한 조항들은 사운드리퍼블리카가 구하는 모든 청구에는 적용되지 않습니다. 귀하는 사운드리퍼블리카나 다른 당사자가 본 계약을 위반하는 경우, 귀하에 발생한 손해가 있다면, 그 손해에 관하여 사운드리퍼블리카에 대한 금지 명령이나 그에 준하는 다른 구제책을 구할 권한이 없으며, 귀하의 유일한 구제책은 금전 손해배상이고, 본 계약에 명시된 책임의 제한의 구속을 받는다는 점을 인정합니다.<br><br>"+
            "(e) 부적절한 청구.<br>"+
            "귀하가 사운드리퍼블리카에 대하여 제기하는 모든 청구는 본 분쟁해결 섹션에 따라 해결되어야 합니다. 본 분쟁해결 섹션에 반하여 제기된 모든 청구는 본 이용약관 및 이용계약을 위반하여 부적절하게 제기된 것으로 간주됩니다.<br><br>"+
            "10.저작권 또는 기타 지식재산권 침해주장의 통지 및 절차<br>"+
            "(a) 사운드리퍼블리카와 회원은 지식재산권을 존중하며, 저작권과 모든 다른 지식재산권을 매우 엄중하게 보호합니다. 본 사이트나 “사운드리퍼블리카 서비스”에서 또는 지식재산권의 침해행위는 용인되지 않을 것입니다.<br><br>"+
            "(b) 사운드리퍼블리카의 지식재산권 정책은 (1) 지식재산권 소유자나 대리인의 통지가 있는 경우, 사운드리퍼블리카가 본 사이트에서 이용가능함에 따라 제3자의 지식재산권을 침해하고 있다고 믿는 콘텐츠를 삭제하는 것과, (2) ‘상습침해자’가 본 사이트에 게시하는 상품이나 제출물을 삭제하는 것입니다. 특정 이용자가 본 서비스에 업로드한 상품이나 제출물로 인하여 사운드리퍼블리카가 2회 이상의 지식재산권 침해 통지를 받게 된 경우, 해당 이용자를 ‘상습침해자’로 간주합니다. 한편, 사운드리퍼블리카는 1회의 지식재산권 침해 주장을 받은 경우 또는 사운드리퍼블리카의 결정에 따라, 이용자에 대하여 불이익조치의 구체적 이유 등을 기재한 통지를 하였음에도 불구하고 이용자가 통지일로부터 3일 내에 불이익조치의 이유를 해소하지 않는 경우, 이용자의 계정이나 본 사이트에 대한 이용허락을 소멸시킬 수 있는 재량권을 보유합니다.<br><br>"+ 
            "(c) 침해주장의 신고 절차<br>"+
            "귀하가 본 사이트나 “사운드리퍼블리카 서비스”를 통하여 제공되는 상품이나 제출물이 귀하가 소유하거나 지배하는 지식재산권을 침해하는 방식으로 사용되고 있다고 판단하는 경우, 즉시 하기의 지정대리인에게 다음의 내용이 포함된 ‘침해사실의 신고’를 보내주시기 바랍니다. 귀하의 통지는 다음의 내용을 포함하고 있어야 합니다.<br>"+
            "1.침해되었다고 주장하는 작품의 소유자를 대신하여 행위할 권한이 있는 자의 물리적 또는 전자적 서명;<br>"+
            "2.침해된 작품 또는 콘텐츠의 정보, 만약 복수의 작품에 대한 침해가 한번의 통지로 이루어질 경우 해당 작품들의 대표 목록;<br>"+
            "3.지식재산권을 침해하고 있거나 침해행위의 대상이 되는, 삭제되거나 서비스 이용이 중단되어야 하는 구체적 콘텐츠의 정보와 사운드리퍼블리카가 해당 콘텐츠를 찾아낼 수 있을 정도의 충분한 정보<br><br>"+
            "4.사운드리퍼블리카가 귀하에게 연락할 수 있을 정도의 충분한 정보란, 예를 들어 귀하에게 연락가능한 주소, 전화번호, 가능한 경우는 이메일 주소를 의미합니다.<br><br>"+
            "5.신고된 방식의 컨텐츠 사용이 저작권자, 정당한 권리자, 대리인, 법에 의하여 허가된 것이 아니라는 점에 대한 귀하의 선의의 진술<br><br>"+
            "6.통지의 내용이 정확하고, 통지의 내용이 사실이 아닐 경우에는 위증죄로 처벌받게 된다는 점을 인지하고 있다는 사실, 그리고 귀하가 침해가 신고된 독점권의 소유자를 대리하여 행위할 권한이 있다는 진술<br><br>"+
            "유효한 침해사실의 통지를 위한 귀하의 의무를 확인하기 위하여, 변호사와 상담할 것을 권해 드립니다.<br><br>"+
            "(d) 이의제기 신청.<br><br>"+
            "사운드리퍼블리카로부터 귀하가 본 사이트 또는 '사운드리퍼블리카 서비스'를 통하여 사용할 수 있도록 한 콘텐츠가 침해사실 통지의 대상이 되었다는 알림을 받게 되는 경우, 귀하는 사운드리퍼블리카에 ‘이의제기 신청’을 할 수 있는 권리가 있습니다. 이의제기 신청이 유효하기 위해서는 서면으로 이루어져야 하고, 다음의 내용을 포함하고 있어야 합니다.<br>"+
            "1.이용자의 물리적 또는 전자적 서명<br>"+
            "2.삭제되거나 이용이 불가능해진 콘텐츠 정보와 콘텐츠의 삭제나 이용불가 조치 전 콘텐츠가 있었던 곳의 정보<br>"+
            "3.이용자가 삭제 또는 이용불가 조치된 콘텐츠가 실수 혹은 조치대상 콘텐츠의 오인으로 인하여 삭제 또는 이용불가 조치 되었다고 믿고 있으며, 사실이 아닐 경우에는 위증죄로 처벌받을 수 있다는 점을 인지하고 있다는 진술<br>"+
            "귀하가 이의제기 신청을 제출하고자 하는 경우, 저작권법에 따른 유효한 통지를 위한 의무를 확인하기 위하여 변호사와 상담할 것을 권해 드립니다.<br><br>"+
            "11.기타<br>"+
            "본 이용약관은 각 당사자와 그 승계인, 인정된 양수인에 대하여 구속력이 있습니다.<br><br>"+
            "귀하는 본 이용약관을 사운드리퍼블리카의 사전 서면 동의 없이 제3자에게 양도할 수 없습니다. 사운드리퍼블리카는 귀하가 본 이용약관에 따라 사운드리퍼블리카에 부여한 모든 권리를 자유롭게 양도할 수 있습니다. 본 이용약관은(본 약관에서 언급된 모든 정책과 계약들을 포함) 해당 주제와 관련된 당사자들의 완전한 이해를 내포하고 있고, 본 주제와 관련된 당사자들의 사전의, 동시적으로 존재하는 계약과 합의에 우선합니다.<br><br>"+
            "당사자가 이용약관에 따른 자신의 권리, 특권 등을 행사하지 않거나 행사를 지연하고 있다 하더라도, 그 권리 등의 포기로 해석되지 않으며, 권리 등의 1회의 또는 부분적 행사가 있더라도, 해당 권리의 추가적 행사나 다른 권리, 권한 등의 행사가 불가능해지는 것은 아닙니다. 귀하와 사운드리퍼블리카는 독립적인 계약자이고, 본 이용약관에 의한 어떠한 대리관계, 동업관계, 합작투자관계, 고용관계가 의도되거나 창설되는 것은 아닙니다.<br><br>"+ 
            "본 이용약관상 어떠한 조항의 무효성 또는 시행불가능성은 다른 조항들의 무효성이나 시행불가능성에 영향을 미치지 못하며, 다른 조항들은 완전한 효력을 발휘합니다.<br><br>"+ 
            "본 이용약관에 사용된 표제는 오직 편의를 위한 것이며, 이용약관의 일부를 구성하는 것이 아니고, 표제로 인하여 특정 조항의 효력이 제한되거나 영향을 받는 것은 아닙니다.<br><br>"+
            "공지 일자 : 2020년 4월 8일<br><br>"+
            "적용 일자 : 2020년 4월 22일"
        }
    },
    en: {
        chapter_1:{
            title: "",
            contents: "Welcome to Sound Republica’s website (the “Site”). The following Terms of Service (“TOS”) contain the terms and conditions that govern your use of the Site and “Sound Republica Service” (or “Service”, as defined below). Use of the Service or registration as a user on the Site after you check the box that you read and confirm the TOS constitutes your agreement to and acceptance of all the TOS.  As such, please take your time to go over the TOS carefully.<br><br>"+
            "The TOS shall be binding on all users of the Service in the Republic of Korea.  Meanwhile, in case Sound Republica (together with any of our licensees and assignees referred in this Agreement as “us” and “we” and “Sound Republica”) provides the Service overseas by entering into an agreement with an overseas right holder of musical recordings, this TOS shall be binding on all users of the Service in such country. The English version of the TOS is for the reference only, provided to overseas users for their convenience, and the Korean TOS shall prevail.<br><br>"+
            "The TOS shall be notified to the user by posting on the Service page of the Site or by other methods, and shall be binding on potential users (“Potential Users”) who wish to utilize the Site and Service when they agree to and join the Service.  Sound Republica seeks the confirmation of Potential Users before they agree to the TOS by enabling them to easily comprehend the contents of the TOS and transact without any misunderstanding.<br><br>"+
            "You will be able to apply for membership after reading and agreeing to the TOS, and Sound Republica will complete the membership application process through consenting to such application and give you an account to use the Service.  An account is a unit to identify between members on each member’s history of use of Service after the member logs in to the Site.<br><br>"+
            "Sound Republica may revise the TOS to the extent it does not violate applicable laws such as “Act on the Regulation of Terms and Conditions”, “Act on Promotion of Utilization of Information and Communications Network”, “Content Industry Promotion Act”, and “Act on the Consumer Protection in Electronic Commerce, Etc.”.  Should Sound Republica revise the TOS, it will make an announcement of the effective date and reason for revision 7 days before such effective date, and in case the TOS revision is detrimental to the member, make an announcement of the revised contents in a manner easily comprehendible by the member and notify each member individually 14 days before the effective date.  It will be deemed individually notified where it is difficult due to a member’s contact details not being registered or changed, or returned 2 times or more when notified to the member’s registered contact details. In case a member does not consent to the revised TOS, the member may cancel her/his membership (withdraw from membership), and in case a member does not express her/his rejection to the revised TOS within 7 days from the effective date of the revised TOS and continues to use the Service, it shall be deemed that there is consent to the revised TOS.<br><br>"+
            "The TOS shall be binding to a member from the date of consent to the TOS to the date of withdrawal from membership.  However, some provisions of this TOS may be valid and binding after a member’s withdrawal of membership."
        },
        chapter_2:{
            title:"1.'Sound Republica Service'",
            contents: "Sound Republica provides a number of Internet-based services and products through the Site (all such services, collectively, the “Sound Republica Service', “Service” and “Products”). Sound Republica users may create individual Products for their own use or sell Products through the Site. Users who are artists or labels who use our service to distribute musical recordings to selected digital stores and services must also enter into Sound Republica Distribution Agreement."
        },
        chapter_3: {
            title: "2.Use of the Site and 'Sound Republica Service'",
            contents: "(a) Eligibility<br>"+
            "Sound Republica will only knowingly provide the 'Sound Republica Service' to parties that can lawfully enter into and form contracts under applicable law. If you are under the age of 18, but at least 14 years of age, you may use the 'Sound Republica Service' only under the supervision of a parent or legal guardian who agrees to be bound by these TOS. The 'Sound Republica Service' is not intended for children under the age of 14.<br><br>"+
            "(b) International Users<br>"+
            "(1) If you are a customer or client accessing the 'Sound Republica Service' and the Site from the European Union, Asia, or any other region with laws or regulations governing personal data collection, use, and disclosure, that differ from Republic of Korea laws, and are not a Korean national, then please note that you are transferring your personal data to Republic of Korea which does not have the same data protection laws as the EU and other regions.<br><br>"+
            "(2) European Union and European Economic Area: The Sound Republica Service’s and Site are not intended for children accessing the Sound Republica Service’s and Site from the European Union or the European Economic Area by children under the age of 16.<br><br>"+
            "(c) Compliance with TOS and Applicable Law.<br>"+ 
            "You must comply with all of the terms and conditions of these TOS, the applicable agreements and policies referred to below, and all applicable laws, regulations and rules of the Republic of Korea when you use the “Sound Republica Service” and the Site.<br><br>"+
            "(d) Your License to Use the Site and the 'Sound Republica Service'.<br>"+
            "(1) Sound Republica and its licensors solely and exclusively own all intellectual property and other right, title and interest in and to the “Sound Republica Service” and Site, except as expressly provided for in these TOS. You will not acquire any right, title or interest therein under these TOS or otherwise.<br><br>"+
            "(2) Sound Republica grants you a limited revocable license to access and use the Site and the “Sound Republica Service” for its intended purposes, subject to your compliance with these TOS. This license does not include the right to collect or use information contained on the Site for purposes prohibited by Sound Republica; create derivative works based on the Products or any third-party-content available via the Site; or download or copy the Site (other than page caching). If you use the Site in a manner that exceeds the scope of this license or breach this TOS, Sound Republica may revoke the license granted to you.<br><br>"+
            "(3) This Section 2(d) does not pertain to your intellectual property rights. Any rights relating to materials that you upload to the Site are covered by the Agreement.<br><br>"+
            "(e) Third-Party Services.<br>"+ 
            "Sound Republica may use third parties to provide certain services accessible through the Site. Sound Republica does not control those third parties or their services, and you agree that Sound Republica will not be liable to you in any way for your use of such services. These third parties may have their own terms of use and other policies. You must comply with such terms and policies as well as these TOS when you use these services. If any such terms or policies conflict with Sound Republica’s TOS, agreements or policies, you must comply with Sound Republica’s TOS, agreements or policies, as applicable."
        },
        chapter_4: {
            title: "3.Rules for Use of the Site",
            contents: "(a) Prohibited Use.<br>"+ 
            "Except as may be expressly permitted by Sound Republica, you may not: (i) interfere with the “Sound Republica Service” by using viruses or any other programs or technology designed to disrupt or damage any software or hardware; (ii) modify, create derivative works from, reverse engineer, decompile or disassemble any technology used to provide the “Sound Republica Service”; (iii) use a robot, spider or other device or process to monitor the activity on or copy pages from the Site, except in the operation or use of an internet “search engine”, hit counters or similar technology; (iv) collect electronic mail addresses or other information from third parties by using the “Sound Republica Service”; (v) impersonate another person or entity; (vi) use any meta tags, search terms, key terms, or the like that contain  Sound Republica’s name or trademarks; (vii) engage in any activity that interferes with another user's ability to use or enjoy the “Sound Republica Service”; or (viii) assist or encourage any third party in engaging in any activity prohibited by these TOS.<br><br>"+
            "(b) Monitoring.<br>"+ 
            "Sound Republica reserves the right, but does not assume the obligation, to monitor transactions and communications that occur through the Site. If Sound Republica determines, in its sole and absolute discretion, that you or another Sound Republica user will breach a term or condition of these TOS or that such transaction or communication is inappropriate, Sound Republica may cancel such transaction or take any other action to restrict access to or the availability of any material that may be considered objectionable, without any liability to you or any third party.<br><br>"+
            "(c) Modification of the Service.<br>"+ 
            "Sound Republica may modify the “Sound Republica Service” at any time with or without notice to you, and will incur no liability for doing so.  That is, Sound Republica does not guarantee the continuation of a specific service."
        },
        chapter_5: {
            title: "4.Submissions",
            contents: "When you submit questions, comments, suggestions, ideas, message board postings, material submitted via web forms, contest entries, communications or any other information ('Submissions”), your Submissions may be used to promote your music and music information, or limited purposes for management, improvement or promotion of Sound Republica Service and development of new Service.  You should be aware that Sound Republica may use your Submissions in a public manner, and ensure that confidential information or sensitive personal information is not included in the Submission.  Any damage incurred due to you not going through such preventative procedures shall be borne solely by you, and Sound Republica shall not be legally responsible.  You may not bring any claim against Sound Republica based on “moral rights” or other similar rights in relation to copyrights arising from Sound Republica’s use of a Submission."
        },
        chapter_6: {
            title: "5.Representations and Warranties",
            contents: "(a) Mutual Representations and Warranties.<br>"+ 
            "You represent and warrant to Sound Republica and Sound Republica represents and warrants to you: (i) that you or it has the full power and authority to enter into and perform under these TOS, (ii) the execution and performance of your or its obligations under these TOS do not constitute a breach of or conflict with any other agreement or arrangement by which you or it is bound, and (iii) these TOS are a legal, valid and binding obligation of the party entering into these TOS, enforceable in accordance with their terms and conditions.<br><br>"+
            "(b) By You.<br>"+
            "You represent and warrant to Sound Republica that, in your use of the “Sound Republica Service”, you: (i) will not infringe the copyright, trademark, patent, trade secret, right of privacy, right of publicity or other legal right of any third party and (ii) will comply with all applicable laws, rules, and regulations. You further represent and warrant to Sound Republica that: (i) there are no claims, demands or any form of litigation pending or, to the best of your knowledge, threatened with respect to any of your Submissions; (ii) Sound Republica will not be required to make any payments to any third party in connection with its use of your Submissions, except for the expenses that Sound Republica incurs in providing the “Sound Republica Service”; (iii) the use of any instructions, formulae, recommendations, or the like contained in your Submissions will not cause injury to any third party; and (iv) your Submissions does not contain viruses or any other programs or technology designed to disrupt or damage any software or hardware."
        },
        chapter_7: {
            title: "6.Disclaimers, Exclusions, and Limitations",
            contents: "(a) DISCLAIMER OF WARRANTIES.<br>"+ 
            "Sound Republica PROVIDES THE SITE AND 'Sound Republica Service' ON AN 'AS IS' AND 'AS AVAILABLE' BASIS. Sound Republica DOES NOT REPRESENT OR WARRANT THAT THE SITE, 'Sound Republica Service' OR ITS USE: (i) WILL BE UNINTERRUPTED, (ii) WILL BE FREE OF INACCURACIES OR ERRORS, (iii) WILL MEET YOUR REQUIREMENTS, OR (iv) WILL OPERATE IN THE CONFIGURATION OR WITH THE HARDWARE OR SOFTWARE YOU USE. Sound Republica MAKES NO WARRANTIES OTHER THAN THOSE MADE EXPRESSLY IN THESE TOS, AND HEREBY DISCLAIMS ANY AND ALL IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY AND NON-INFRINGEMENT.<br><br>"+
            "(b) EXCLUSION OF DAMAGES.<br>"+ 
            "Sound Republica shall only be responsible for actual damages incurred to you and/or a third party from use of Sound Republica Service, in case it is proven due to Sound Republica’s intent or gross negligence.<br><br>"+
            "Sound Republica WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, PUNITIVE OR SPECIAL DAMAGES (INCLUDING DAMAGES RELATING TO LOST PROFITS, LOST DATA OR LOSS OF GOODWILL) ARISING OUT OF, RELATING TO OR CONNECTED WITH THE USE OF The 'Sound Republica Service', BASED ON ANY CAUSE OF ACTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<br><br>"+
            "A member shall indemnify Sound Republica if such member’s violation of law, regulations, or this TOS causes a third party to ask for damages or claim against, or otherwise object to Sound Republica.  If the member cannot indemnify Sound Republica from such liabilities, then such member shall pay all damages incurred to Sound Republica therefrom.<br><br>"+
            "(c) LIMITATION OF LIABILITY.<br>"+ 
            "EXCEPT FOR A BREACH OF A PARTY'S REPRESENTATIONS AND WARRANTIES UNDER THESE TOS OR IN CONNECTION WITH YOUR INDEMNITY OBLIGATIONS UNDER THESE TOS, IN NO EVENT WILL THE LIABILITY OF EITHER PARTY IN CONNECTION WITH THESE TOS." 
        },
        chapter_8: {
            title: "7.Indemnification",
            contents: "Sound Republica shall not be liable for inability to provide Service due to force majeure events or similar causes that makes it legally or physically impossible to provide Service.  Sound Republica shall not be liable for Service failures due to causes attributable to the member.  Sound Republica shall not be liable for member’s losses of expected profit from use of Services or damages from information obtained from the Service.  Sound Republica takes no responsibility for the trustworthiness or correctness of the information, content, facts posted by members. Sound Republica shall have no obligation to intervene in disputes between members or between a member and a third party in relation to the Service, nor have any obligation to pay for such damages.<br><br>"+
            "You will indemnify and hold Sound Republica and its employees, representatives, agents, affiliates, directors, officers, managers and shareholders (the “Parties”) harmless from any damage, loss, or expense (including without limitation, attorneys' fees and costs) incurred in connection with any third-party claim, demand or action (“Claim”) brought against any of the Parties alleging that you have breached any of these TOS. If you have to indemnify Sound Republica under this Section, Sound Republica will have the right to control the defense, settlement, and resolution of any Claim at your sole expense. You may not settle or otherwise resolve any Claim without Sound Republica’s express written permission."
        },
        chapter_9:{
            title: "8.Termination",
            contents: "(a) Termination.<br>"+ 
            "Sound Republica may suspend or terminate your use of the Site or “Sound Republica Service” if it believes, in its sole and absolute discretion that you have breached a term of these TOS.<br><br>"+
            "(b) Survival.<br>"+ 
            "These TOS will survive indefinitely unless and until Sound Republica chooses to terminate them.<br><br>"+
            "(c) Effect of Termination.<br>"+ 
            "If Sound Republica terminates your use of the Site or the “Sound Republica Service”, Sound Republica may delete any Submissions or other materials relating to your use of the “Sound Republica Service” on Sound Republica’s servers or otherwise in its possession and Sound Republica will have no liability to you or any third party for doing so."
        },
        chapter_10: {
            title: "9.Notice",
            contents: "All notices required or permitted to be given under these TOS will be in writing and delivered to the other party by electronic mail. If you give notice to Sound Republica, you must use the following addresses:<br>"+
            "music@soundrepublica.com.<br>"+
            "If Sound Republica provides notice to you, Sound Republica will use the contact information provided by you to Sound Republica. All notices will be deemed received by electronic mail, 48 hours after the message was sent, if no 'system error' or other notice of non-delivery is generated.  Provided that, however, if a member is notified to the contact details such member provided but such notification is sent back 2 times or more, it shall be deemed received 48 hours from the last notification date.  If applicable law requires that a given communication be 'in writing,' you agree that email communication will satisfy this requirement."
        },
        chapter_11: {
            title: "10.Dispute Resolution",
            contents: "(a) Mandatory Arbitration.<br>"+ 
            "Please read this carefully. It affects your rights. YOU AND Sound Republica AND EACH OF OUR RESPECTIVE SUBSIDIARIES, AFFILIATES, PREDECESSORS IN INTEREST, SUCCESSORS, AND PERMITTED ASSIGNS AGREE TO ARBITRATION, AS THE EXCLUSIVE FORM OF DISPUTE RESOLUTION EXCEPT AS PROVIDED FOR BELOW, FOR ALL DISPUTES AND CLAIMS ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF THE SERVICES. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award.  By consenting to this TOS, you and Sound Republica agree that any disputes out of or in connection with this TOS shall be finally settled by arbitration in accordance with the International Arbitration Rules of the Korean Commercial Arbitration Board(provided, however, if you are a Korean national, the Domestic Arbitration Rules of the Korean Commercial Arbitration Board) . The number of arbitrators shall be one.  The seat, or legal place, of arbitral proceedings shall be Seoul, Republic of Korea.  The language to be used in the arbitral proceedings shall be Korean.<br><br>"+
            "(b) No Class Actions.<br>"+ 
            "YOU AND Sound Republica AGREE THAT YOU MAY NOT BRING CLAIMS AGAINST THE OTHER AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. FURTHER, YOU AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OF MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND THAT IF THIS SPECIFIC PROVISO IS FOUND TO BE UNENFORCEABLE, THEN THE ENTIRETY OF THIS MANDATORY ARBITRATION SECTION SHALL BE NULL AND VOID.<br><br>"+
            "(c) Applicable Law.<br>"+ 
            "This Agreement and your use of the Services and the Website shall be construed according to and be governed by laws of Republic of Korea.<br>"+
            "(d) Equitable Relief.<br>"+ 
            "The foregoing provisions of this Dispute Resolution section do not apply to any claim in which Sound Republica seeks equitable relief of any kind. You acknowledge that, in the event of a breach of this Agreement by Sound Republica or any third party, the damage or harm, if any, caused to you will not entitle you to seek injunctive or other equitable relief against Sound Republica, and your only remedy shall be for monetary damages, subject to the limitations of liability set forth in this Agreement.<br><br>"+
            "(e) Improperly Filed Claims.<br>"+ 
            "All claims you bring against Sound Republica must be resolved in accordance with this Dispute Resolution section. All claims filed or brought contrary to this Dispute Resolution section shall be considered improperly filed."
        },
        chapter_12: {
            title: "11.Notice and procedure for making claims of copyright or other intellectual property infringements",
            contents: "(a) Sound Republica respects the intellectual property of others and takes the protection of copyrights and all other intellectual property very seriously, and we ask our users to do the same. Infringing activity will not be tolerated on or through the Site or the 'Sound Republica Service'.<br><br>"+
            "(b) Sound Republica’s intellectual property policy is to (1) remove material that Sound Republica believes in good faith, upon notice from an intellectual property owner or their agent, is infringing the intellectual property of a third party by being made available through the Site, and (2) remove any Products or Submissions posted to the Site by 'repeat infringers.' Sound Republica considers a 'repeat infringer' to be any user that has uploaded Products or Submissions to the Service and for whom Sound Republica has received more than two takedown notices compliant. Sound Republica has discretion, however, to terminate the account or permission to access the Site of any user after receipt of a single notification of claimed infringement or upon Sound Republica’s own determination in case Sound Republica receives one infringement complaint or, in case the member does not resolve the reason for Sound Republica’s penal actions against such member 3 days within the notice explaining the reason for the penal action.<br><br>"+
            "(c) Procedure for Reporting Claimed Infringement.<br>"+ 
            "If you believe that any Productions or Submissions made available on or through the Site or the “Sound Republica Service” have been used or exploited in a manner that infringes an intellectual property right you own or control, then please promptly send a 'Notification of Claimed Infringement' containing the following information to the Designated Agent identified below. Your communication must include substantially the following:<br>"+
            "(1) A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that has/have been allegedly infringed;<br>"+
            "(2) Identification of works or materials being infringed, or, if multiple works are covered by a single notification, a representative list of such works;<br>"+
            "(3) Identification of the specific material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit Sound Republica to locate the material;<br>"+
            "(4) Information reasonably sufficient to permit Sound Republica to contact you, such as an address, telephone number, and, if available, an electronic mail address at which you may be contacted;<br>"+
            "(5) A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and<br>"+
            "(6) A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.<br><br>"+
            "You should consult with your own lawyer to confirm your obligations to provide a valid notice of claimed infringement.<br><br>"+
            "(d) Counter Notification.<br>"+ 
            "If you receive a notification from Sound Republica that material made available by you on or through the Site or the 'Sound Republica Service' has been the subject of a Notification of Claimed Infringement, then you will have the right to provide Sound Republica with what is called a 'Counter Notification.' To be effective, a Counter Notification must be in writing, and include substantially the following information:<br>"+
            "(1) A physical or electronic signature of the subscriber;<br><br>"+
            "(2) Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;<br><br>"+
            "(3) A statement under penalty of perjury that the subscriber has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and<br><br>"+
            "A party submitting a Counter Notification should consult a lawyer to confirm the party’s obligations to provide a valid counter notification under the Copyright Act."
        },
        chapter_13: {
            title: "12.Miscellaneous",
            contents: "These TOS will be binding upon each party hereto and its successors and permitted assigns.<br><br>"+
            "These TOS will not be assignable or transferable by you without the prior written consent of Sound Republica. Sound Republica may freely assign or transfer any rights granted by you to Sound Republica under these TOS. These TOS (including all of the policies and other Agreements described in this TOS, which are hereby incorporated herein by this reference) contain the entire understanding of the parties regarding its subject matter, and supersedes all prior and contemporaneous agreements and understandings between the parties regarding its subject matter.<br><br>"+  
            "No failure or delay by a party in exercising any right, power or privilege under these TOS will operate as a waiver thereof, nor will any single or partial exercise of any right, power or privilege preclude any other or further exercise thereof or the exercise of any other such right, power, or privilege. You and Sound Republica are independent contractors, and no agency, partnership, joint venture, or employee-employer relationship is intended or created by these TOS.<br><br>"+
            "The invalidity or unenforceability of any provision of these TOS will not affect the validity or enforceability of any other provision of these TOS, all of which will remain in full force and effect.<br><br>"+
            "The headings used in this TOS are for convenience only, do not constitute a part of this TOS, and shall not be deemed to limit or affect any of the provisions hereof.<br><br>"+
            "Date of announcement: April 8, 2020<br><br>"+
            "Effective date: April 22, 2020"
        }
    }
}

export const musicStoreList = [
    "MELON",
    "YouTube",
    "Genie Music",
    "FLO",
    "NAVER MUSIC",
    "VIBE",
    "BUGS",
    "Apple Music",
    "Spotify",
    "KAKAO MUSIC",
    "Acfun",
    "Advertising Agency",
    "Akazoo",
    "Aldi Life",
    "ALSONG",
    "Amazon",
    "Amazon Ad-Funded Streaming Service",
    "Amazon Prime",
    "Amazon Unlimited",
    "Anghami",
    "Audiomack",
    "AWA",
    "B612",
    "beat.no",
    "Beatport",
    "Bell 365",
    "Best Friends",
    "Bilibili",
    "Bitel Peru",
    "BMAT",
    "Boom",
    "Boomplay",
    "Buecher",
    "Carrier Billing",
    "CCMLOVE",
    "CCMPIA",
    "Celcom",
    "Changba",
    "China Unicom (Wo Music)",
    "Chunghwa Mobile",
    "CJ Hello Vision",
    "Coolvox",
    "DaPian",
    "Deezer",
    "DiGi",
    "Digicel",
    "Douban Music",
    "Douyin",
    "Dragonfly FM",
    "Dubset Media",
    "Duitang",
    "Echo",
    "Electric Jukebox",
    "Ex Libris",
    "EZWelfare",
    "FETnet",
    "Fizy",
    "Friday Music (Omusic)",
    "Future TV",
    "Gaopin",
    "Gionee",
    "Global Radio",
    "GodPeople",
    "Google Play",
    "Grandpad",
    "Groovers",
    "GTL Music",
    "Heiyo",
    "HighResAudio",
    "Himalayan FM",
    "HMV japan",
    "Hofer Life",
    "Huawei",
    "IAM+",
    "iFeng",
    "iHeartRadio US",
    "iMusica",
    "Incruit",
    "IPTV",
    "iQiyi",
    "iTunes",
    "J Pay",
    "J:COM",
    "Jaxsta",
    "Jaxsta Music",
    "Jeou Tai",
    "Jinshan",
    "Jio Saavn",
    "Joins Prime",
    "JOOX",
    "Juke",
    "Juno Download",
    "Kanjian Music",
    "Kanlixiang",
    "KKBOX",
    "Koala FM",
    "Kolbi Music",
    "KT",
    "KTV",
    "Kuaishou",
    "Kugou",
    "Kuwo",
    "Kuyin Ringtone",
    "Lanren Tingshu",
    "Layabox",
    "Lenovo",
    "Lequ Music",
    "LETV",
    "LGU+",
    "LINE Music",
    "Lizhi FM",
    "Lola",
    "Love Music (China Telecom)",
    "Maowang",
    "Maxis",
    "Meitu",
    "MGTV",
    "Migu Music (China Mobile)",
    "Migu Video (China Mobile)",
    "Milk",
    "MNET",
    "MOMO",
    "Moodagent",
    "MOOV",
    "Mora Qualitas",
    "Movistar Colombia",
    "Movistar Musica Colombia",
    "Movistar Musica Peru",
    "MTN Uganda",
    "MUSIC MATE",
    "MyMelo",
    "MyMusic",
    "Napster",
    "Naver Samsung Smart TV",
    "Naver Search",
    "NEC",
    "NetEase",
    "Odnoklassniki",
    "Olleh Music",
    "ONE STORE",
    "Onkyo",
    "OPPO",
    "Orange Dominican Republic",
    "Pandora",
    "Peloton",
    "PLAY Poland",
    "Playnetwork",
    "Plex",
    "PPTV",
    "Pyro Music",
    "Qianqian Music",
    "Qobuz",
    "QQ Music",
    "Raku",
    "Rakuten",
    "Resso",
    "Rhapsody",
    "ROXI",
    "Samsung Milk Music (China only)",
    "SAMSUNG MUSIC",
    "Santa Toeic",
    "SECURUS",
    "Shazam",
    "Simfy Africa/MTN",
    "Sina Video",
    "Singtel",
    "Sinhan Card",
    "SiriusXM",
    "SKT",
    "Slacker",
    "Sohu",
    "SoundCloud",
    "SoundMachine",
    "Soundtrack Your Brand",
    "Sprint US",
    "Stellar Entertainment",
    "T Star",
    "T STORE",
    "TaiHua",
    "Taiwan Mobile",
    "TDC Play Musik",
    "Telefonica Brazil",
    "Telenor DK",
    "Telmore Affiliate",
    "Tencent Video",
    "TIDAL",
    "TikTok",
    "TIM Italia",
    "TingTing FM",
    "TJ Media",
    "TouchTunes",
    "Toutiao",
    "TrackDrip",
    "Traxsource",
    "Tudou",
    "Tuzhan",
    "U-Mobile",
    "Ultimate Music China Limited",
    "UMA",
    "UTAPASS",
    "Virgin Mobile Colombia",
    "Viva Musica",
    "VIVO hires",
    "VKontakte",
    "Vodafone",
    "Vodafone Zambia",
    "VUE",
    "Walkgame",
    "Wasu TV",
    "WAVVE",
    "We Sing",
    "Weibo Music",
    "Weltbilt",
    "WOM Chile",
    "Wotu",
    "Wynk",
    "Xiami",
    "Xiaomi Music",
    "Xiaoying",
    "Xite",
    "Xiumi",
    "Xiutang",
    "Yandex Music",
    "Yinyue Tai",
    "Yiqixiu",
    "Youku",
    "YouSee (TDC)",
    "Youting",
    "Yunjian",
    "Yunting",
    "Zhuote",
]


// 계약서
export const contract = {
    ko: {
        chapter_1: {
            title: "사운드리퍼블리카 콘텐츠 유통 계약서",
            contents: "본 계약은 계약 체결자인 당신(‘권리사’)과 사운드리퍼블리카(‘유통사’) 간 콘텐츠 유통계약을 체결함에 있어 상호간의 권리, 의무, 기타 제반 사항을 명백히 규정함을 그 목적으로 한다.<br><br>"+
            "‘권리사’가 다른 권리사, 단체 또는 개인을 대리하여 본 계약을 체결하는 경우, ‘권리사’는 본 계약을 체결함으로써 ‘권리사’가 다른 권리사, 단체 또는 개인을 대리할 수 있는 정당한 권리가 있으며 또한 그들이 본 계약의 내용을 성실히 이행할 것임을 ‘유통사’에 보증한다.<br><br>"+
            "‘권리사’는 본 계약에 동의하는 버튼을 클릭함으로써 하기 조항과 조건을 수용한다. 본 계약의 효력은 본 계약에 동의하는 버튼을 클릭하는 날(이하 ‘계약일’이라 한다)부터 발생한다."
        },
        chapter_2: {
            title: "계약의 주요 내용",
            contents:"1. 계약 기간<br>"+
            "- 계약 기간은 계약일로부터 2년으로 하며, 계약 종료 30일 전까지 일방으로부터 서면에 의한 계약 종료 통지가 없을 경우 본 계약과 동일한 조건으로 1년씩 자동 연장된다.<br><br>"+
            "2. 서비스 지역<br>"+
            "- 본 계약에 따른 '유통사'의 '서비스 지역’은 전세계를 기본으로 한다<br>"+
            "- 단, ‘권리사’가 지정하는 ‘음원 서비스 사업자’가 있고 이에 대해 양사간 합의할 경우 그 ‘음원 서비스 사업자’가 서비스하는 지역에 한정해 서비스를 제공한다.<br><br>"+
            "3. 본 계약의 대상<br>"+
            "- 본 계약은 ‘음반’, ‘콘텐츠’ 및 이와 관련된 ‘온라인 상의 권리’를 대상으로 한다.<br><br>" +
            "4. 본 계약상 인정되는 당사자의 주요의무<br>"+
            "- ‘유통사’는 ‘음원 서비스 사업자’가 ‘서비스’를 통하여 실제 지급한 금액의 15%(부가가치세 별도)를 ‘계약 콘텐츠’에 관한 ‘유통수수료’로 지급받기로 하고, ‘유통수수료’를 제외한 나머지 85% (부가가치세 별도)를 ‘인세’로 ‘권리사’에게 지급한다.<br>"+
            `- 단, 본계약에 따른 ‘유통사’의 ‘서비스 지역’이 전세계가 아닌 대한민국에만 한정될 경우, ‘유통사’는 ‘음원 서비스 사업자’가 ‘서비스’를 통하여 실제 지급한 금액의 20%(부가가치세 별도)를 ‘계약 콘텐츠’에 관한 ‘유통수수료’로 지급받기로 하고, ‘유통수수료’를 제외한 나머지 80% (부가가치세 별도)를 ‘인세’로 ‘권리사’에게 지급한다.<br>`+
            "- ‘유통사’와 ‘권리사’ 간 별도의 계약을 체결했을 경우 ‘유통수수료’와 ‘인세’는 상기 비율이 아닌 별도의 계약에 따른다.<br>"+
            "- 단, 첫 ‘인세’는 '권리사'의 '콘텐츠'가 '서비스'된 최초 월 기준 만 2개월 후부터 정산한다.<br>"+
            "- '권리사'가 본 서비스를 통해 출금 요청할 수 있는 최소 인세는 50,000원(KRW) 이며, 미지급 인세가 50,000원 미만일 경우에는 지급을 유보한다."
        },
        chapter_3:{
            title: "용어의 정의",
            contents:
            `1. ‘음반’이란 ‘권리사’가 ‘유통사’에게 제공한 CD, LP, MC, MD 및 이외의 멀티미디어 매체 등 음이 유형물에 고정되어 재생될 수 있도록 제작된 일체의 물체 및 디지털 변환 파일, 영상저작물 등을 말한다.<br><br>`+
            "2. ‘콘텐츠’란 '권리사'가 '유통사'에게 제공한 다음 모든 형태의 데이터를 말한다.<br>"+
            "- ‘권리사’가 기 보유하고 있는 권리 – 저작권, 저작인접권 또는 초상권, 성명권 등<br>"+
            "- '권리사'가 '본 계약'의 계약기간 중 취득하게 될 권리뿐만 아니라, 제3자로부터 이용 허락 또는 처분을 할 수 있는 권한 일체를 허여 받았거나 계약기간 중 받게 될 음원, 음성, 초상, 자켓 이미지, 뮤직비디오, 티저(Teaser)영상, 기타 홍보 및 프로모션 등을 목적으로 '권리사'가 기획, 제작한 일체의 영상 등<br><br>"+
            "3. ‘온라인 상의 권리’란 '음반' 및 '콘텐츠'를 정보통신망, 유선전화, 이동통신, 위성통신 및 기타 이와 유사한 설비나 장치(이하 '정보통신망 등'이라고 한다)를 사용하여 이용하는 것에 관한 일체의 권리를 말한다. <br>"+
            `4  ‘서비스’란 '음반' 및 '콘텐츠'를 정보통신망, 유선전화, 이동통신, 위성통신 및 기타 이와 유사한 설비나 장치를 통하여 사용할 수 있도록 제공하는 일체의 행위를 말한다.<br>`+
            "5. ‘발매’란 온라인 상으로 새로이 제작된 '음반' 및 '콘텐츠'를 유통, 판매하는 행위를 말한다.<br><br>"+
            "6. 음원 서비스 사업자 (또는 뮤직 스토어)는 ‘유통사’와 계약관계가 있으며 '유통사'로부터 '음반' 및 '콘텐츠'를 제공받아 온라인 상으로 일반 대중에게 직접 서비스하는 회사를 말한다. 본 계약 체결 이후 ‘유통사’와 신규로 계약하는 회사 중 '유통사'로부터 '음반' 및 '콘텐츠'를 제공받아 온라인 상으로 일반 대중에게 직접 서비스하는 회사도 포함한다.<br><br>"+
            "7. ‘유통수수료란’ ‘본 계약’의 '음반' 및 '콘텐츠'의 유통 시 그 업무의 대가로 ‘유통사’에게 지불되는 금액을 말한다.<br><br>"+
            "8. ‘인세’란 본 계약'의 '음반' 및 '콘텐츠'의 판매를 통하여 '음원 서비스 사업자'가 '유통사'에게 실제 지급한 매출액 중 '유통사'의 '유통수수료'를 공제한 후 '권리사'에게 지급할 금액을 말한다."
        },
        chapter_4:{
            title: "본 계약의 내용",
            contents:"1. ‘권리사’가 ‘유통사’에 부여하는 권한<br>"+
            "1.1 '권리사'는 '유통사'에게 '콘텐츠'에 대하여 ‘서비스 지역’에 독점적으로 ‘서비스’할 수 있는 유통권을 부여한다.<br>"+
            "1.2 '권리사'는 '계약 기간’ 동안 '유통사'가 ‘콘텐츠’를 판매, 판촉, 홍보하기 위한 목적으로 ‘콘텐츠’의 아티스트 및 가창자의 이름, 사진, 신상자료 및 앨범 아트웍 등을 직접 명명, 사용, 제작하거나 제3자에게 그러한 행위를 허가하여 광고할 수 있도록 하는 권리를 '유통사'에게 부여하며, 이에 대한 적법한 권리가 있음을 '유통사'에 보증한다.<br>"+
            "1.3 ‘권리사'는 그 외 다음 각 항의 업무를 '유통사'에게 위임한다.<br>"+
            "(a) ‘콘텐츠’의 이용허락 업무에 관한 계약 협상 및 계약 체결 대행업무<br>"+
            "(b) ‘콘텐츠’에 대한 침해행위를 알게 된 경우 '권리사'에 대한 통지 및 침해자에 대한 침해중지 통지업무<br>"+
            "(c) ‘콘텐츠’에 관하여 지적재산권 등록 필요 시 등록대행 업무<br>"+
            "(d) ‘콘텐츠’의 서비스로 인한 매출대금 수령업무 및 정산 후 '권리사'에 대한 '인세' 지급 업무<br>"+
            "(e) ‘콘텐츠’의 이용촉진을 위한 홍보 및 마케팅 업무<br>"+
            "(f) ‘콘텐츠’를 순수한 홍보 및 마케팅을 위한 목적으로TV 프로그램, 웹사이트 영상, 팟캐스트, 멀티미디어자료, 기타 방송 영상 등에 사용할 수 있는 권한<br>"+
            "(g) ‘콘텐츠’를 디지털 컴필레이션 앨범 제작에 사용할 수 있는 권한 (단, 디지털 컴필레이션 앨범 제작 전에 ‘권리사’에게 관련 내용을 통지해야 함)<br>"+
            "(h) ‘콘텐츠’가 ‘서비스 지역’의 기준에 의해 사회 통념상 부적절한 또는 미성년자에게 부적절한 내용이 포함돼 있다고 판단될 경우 ‘19금 콘텐츠’ 또는 ‘Explicit Content’ 등 제한 표시를 할 수 있는 권한<br>"+
            "(i) ‘콘텐츠’를 ‘음원 서비스 사업자’의 서비스 공급 방식에 맞추어 가공하여 제공하거나, ‘음원 서비스 사업자’에게 자신의 서비스 공급 방식에 맞게 가공하도록 허락할 수 있는 권한<br><br>"+
            "2. ‘권리사’의 의무<br>"+
            "2.1 ‘권리사’는 ‘유통사’에게 제공하는 ‘콘텐츠’에 대하여 저작권법상 사용 권리가 ‘권리사’에게 있거나 ‘권리사’가 원 권리자와의 합법적인 계약에 의하여 독점적인 ‘콘텐츠’의 사용 권한을 확보하였음을 보증하여야 하며, 이에 따른 모든 권리 관계에 대한 책임을 진다.<br>"+ 
            "2.2 '권리사'는 '콘텐츠'에 대한 지적재산권의 변동상황을 '유통사'에게 즉시 통보하여야 한다.<br>"+
            "2.3 '권리사'는 ‘유통사’가 '콘텐츠'의 권리확인을 위한 증빙자료를 '권리사'에 요구할 경우 '유통사'에 증빙 자료를 즉시 제공해야 한다. 단, 이 경우는 ‘권리사’가 물리적으로 산출 가능한 범위 내의 자료를 말한다.<br>"+
            "2.4 '권리사'는 '유통사'가 '콘텐츠'의 ‘서비스’ 및 정산업무에 필요한 자료를 요청할 경우 즉시 제공해야 한다.<br>"+
            "2.5 전 항의 규정에도 불구하고, ‘권리사’가 ‘유통사’에 제공하는 ‘콘텐츠’의 저작권법 및 기타 지적재산권 상의 제 권리 또는 ‘콘텐츠’를 활용한 서비스와 관련하여 해당 아티스트 또는 제3자와 분쟁이 발생하는 경우, ‘권리사’는 ‘권리사’의 책임과 비용으로 분쟁을 해결해야 하고, ‘유통사’는 면책한다. 또한 분쟁으로 인하여 ‘유통사’가 손해를 입게 되는 경우에는 ‘권리사’가 ‘유통사’의 손해를 배상한다.<br><br>"+ 
            "3. ‘콘텐츠’의 전달<br>"+
            "3.1 ‘권리사’는 ‘서비스’에 필요한 ‘콘텐츠’를 ‘유통사’의 웹사이트에 업로드하여 ‘유통사’에게 전달할 수 있다.<br>"+
            "3.2 ‘권리사’는 ‘서비스’에 필요한 ‘콘텐츠’를 ‘유통사’의 웹사이트에 업로드할 때에 ‘서비스’에 필요한 모든 ‘콘텐츠’를 함께 제공해야 한다.<br>"+
            "3.3 ‘권리사’는 ‘콘텐츠’에 사회 통념상 부적절한 또는 미성년자에게 부적절한 내용이 포함돼 있다고 판단될 경우 '19금 콘텐츠' 또는 'Explicit Content' 등으로 표시하여 ‘유통사’에 제공해야 한다.<br><br>"+
            "4. 저작인접권 등 권리의 소유<br>"+
            "4.1 ‘콘텐츠’에 포함된 저작인접권 등 ‘권리사’가 기 보유한 모든 권리는 ‘권리사’의 소유이고 ‘유통사’의 소유가 아니다<br><br>"+
            "5.음원 서비스 사업자<br>"+
            "5.1 ‘유통사’는 ‘권리사’가 제공한 ‘콘텐츠’를 ‘음원 서비스 사업자’에 전달한다. 이 경우 ‘음원 서비스 사업자’가 ‘콘텐츠’를 실제로 발매하는 데까지는 적게는 수 시간, 많게는 수십일까지 소요될 수 있다.<br>"+ 
            "5.2 ‘음원 서비스 사업자’는 자체 정책에 따라 ‘권리사’가 제공한 ‘콘텐츠’의 일부 또는 전부를 발매하지 않거나 발매 후 취소를 할 수 있다.<br>"+ 
            "5.3 ‘유통사’는 다음과 같은 경우에 ‘콘텐츠’의 일부 또는 전부를 ‘음원 서비스 사업자’에 전달하지 않거나, ‘음원 서비스 사업자’가 발매한 ‘콘텐츠’에 대해 발매 취소를 요청할 수 있다.<br>"+ 
            "(a) ‘콘텐츠’에 대해서 법적인 이슈가 발생한 경우<br>"+
            "(b) ‘콘텐츠’에 대해서 법적인 이슈가 발생할 것으로 판단되는 경우<br>"+
            "(c) ‘콘텐츠’가 ‘음원 서비스 사업자’의 정책에 반할 경우<br>"+
            "(d) 기타 합리적인 사업적 판단 하에 필요성이 제기될 경우<br>"+
            "5.4 종료되거나 ‘음원 서비스 사업자’가 ‘서비스’를 중단하는 경우, ‘콘텐츠’는 더 이상 해당 지역에서 당해 ‘음원 서비스 사업자’를 통해서 ‘서비스’ 되지 않는다.<br><br>"+
            "6.계약 기간<br>"+
            "6.1 본 계약의 효력은 본 계약에 동의하는 버튼을 클릭하는 날(이하 ‘계약일’이라 한다)부터 발생한다.<br>"+ 
            "6.2 계약 기간은 계약일로부터 2년으로 하며, 계약 종료 30일 전까지 일방으로부터 서면에 의한 계약 종료 통지가 없을 경우 본 계약과 동일한 조건으로 1년씩 자동 연장된다.<br><br>"+
            "7.서비스 지역<br>"+
            "7.1 본 계약에 따른 '유통사'의 '서비스 지역’은 전세계를 기본으로 한다.<br>"+
            "7.2 단, ‘권리사’가 지정하는 ‘음원 서비스 사업자’가 있고 이에 대해 양사간 합의할 경우 그 ‘음원 서비스 사업자’가 서비스하는 지역에 한정해 서비스를 제공한다.<br><br>"+
            "8.가격 책정, 유통수수료, 인세, 및 정산<br>"+
            "8.1 가격 책정<br>"+
            "(a) ‘유통사’ 및 ‘음원 서비스 사업자’는 각 ‘음원 서비스 사업자’의 사업모델 및 판매 정책에 맞춰 다음과 같은 사항을 결정할 수 있다.<br>"+
            "- ‘콘텐츠’의 다운로드, 스트리밍, 및 기타 제공 서비스 가격<br>"+
            "- ‘콘텐츠’의 서비스 파일 형식 또는 제공 미디어 등<br>"+
            "8.2 유통수수료 및 인세 지급<br>"+ 
            `(a) '유통사'는 '서비스'를 통하여 '음원 서비스 사업자'로부터 실제 지급받은 금액의 85%(부가가치세<br>별도)를 ‘인세’로 ‘권리사’에게 지급하고, ‘유통사’는 나머지 15%(부가가치세 별도)를 '콘텐츠'에 관한 '유통수수료'로 취득하기로 한다. (본계약에 따른 '유통사'의 '서비스 지역'이 전세계가 아닌 대한민국에만 한정될 경우, '유통사'는 '음원 서비스 사업자'가 '서비스'를 통하여 실제 지급한 금액의 20%(부가가치세 별도)를 '계약 콘텐츠'에 관한 '유통수수료'로 지급받기로 하고, '유통수수료'를 제외한 나머지 80% (부가가치세 별도)를 '인세'로 '권리사'에게 지급한다.) <br> 단, ‘유통사’와 ‘권리사’ 간 별도의 계약을 체결했을 경우 ‘유통수수료’와 ‘인세’는 상기 비율이 아닌 별도의 계약에 따른다.<br>`+
            "(b) ‘콘텐츠’ ‘서비스’ 제공에 관하여, ‘음원 서비스 사업자’가 ‘유통사’와의 계약을 위반해 ‘유통사’에게 지급하지 않은 금원은 전 항의 계산에 포함시키지 않는다.<br>"+
            "8-3 정산"+ 
            "(a) ‘유통사’는 ‘음원 서비스 사업자’로부터 받은 ‘콘텐츠’에 대한 정산 내역을 기록한다.<br>"+ 
            "(b) '유통사'는 ‘콘텐츠’의 '서비스'를 통하여 '음원 서비스 사업자'로부터 실제 지급받은 금액에서 ‘유통수수료’를 제외한 금액을 '권리사'의 인세 계정에 기록한다<br>"+
            "(c) ‘권리사’는 인세 계정에 기록된 금액에 대해 원하는 시기에 지급을 요청할 수 있다. 단, 권리사'가 본 서비스를 통해 ‘유통사’에게 출금 요청할 수 있는 최소 인세는 50,000원(KRW) 이며, 미지급 인세가 50,000원 미만일 경우에는 지급을 유보한다.<br>"+
            "(d) 전 항에도 불구하고 첫 ‘인세’는 '권리사'의 '콘텐츠'가 '서비스'된 최초 월 기준 만 2개월 후부터 정산한다.<br>"+
            "(e) ‘권리사’가 인세 계정에 기록된 금액을 지급받기 위해서는 ‘유통사’에게 명시적으로 미지급 인세의 지급을 요청하여야 하고 ‘권리사’는 필요 시 지급 요청 금액에 대한 세금계산서를 발행해야 한다. 단, '권리사'가 개인(자연인)일 경우는 해당되지 않는다.<br>"+ 
            "(f) ‘유통사’는 ‘권리사’로부터 미지급 인세에 대한 지급 요청 및 필요 서류를 받은 뒤 30일 이내에 계좌 이체로 해당 금액을 지급해야 한다.<br>"+
            "(g) ‘콘텐츠’ ‘서비스’ 제공에 관하여, ‘유통사’가 ‘음원 서비스 사업자’로부터 실제 지급받은 금원 중 원화가 아닌 화폐로 받은 금원은 ‘유통사’가 ‘음원 서비스 사업자’로부터 지급받은 날의 환율과는 무관하게, ‘유통사’가 ‘권리사’에 지급하는 날짜를 기준으로 하나은행에 의해 최초 고시된 환율에 따라 원화로 계산해서 지급한다.<br>"+
            "(h) ‘권리사’의 요청에 의해서 ‘권리사’가 ‘유통사’로부터 인세를 지급받을 은행 계좌를 변경할 수 있다.<br>"+
            "(i) ‘인세’ 지급과 관련된 모든 세금은 ‘권리사’가 부담하는 것으로 한다. ‘인세’ 지급이 이루어지는 나라의 법률에 따라서 모든 ‘인세’ 지급은 원천징수 세금 부과의 대상이 될 수 있다.<br>"+ 
            "(j) ‘인세’지급과 관련하여, ‘권리사’는 ‘유통사’에 ‘권리사’를 대신하여 원천 징수 세금을 납부할 수<br>있는 권한을 부여한다.<br>"+
            "(k) ‘권리사’의 계정이 ‘권리사’ 외 다른 단체, 회사, 개인 등을 대신하여 운영될 경우, ‘권리사’는 대신하고 있는 다른 단체, 회사, 개인 등에게 인세를 지급할 책임이 있다.<br><br>"+ 
            "8-4 감사<br>"+
            "(a) '권리사'는 '유통사'가 제공하는 정산내역의 검증이 필요하다고 판단될 경우 해당 정산내역에 대한 증빙자료를 '유통사'에 요구할 수 있으며, '유통사'는 '권리사'에게 증빙자료를 제공해야 한다. 단, '유통사'가 제공하는 증빙자료는 '유통사'가 '음원 서비스 사업자'로부터 받은 내역에 한한다.<br><br>"+
            "9. 계약의 해지 및 만료<br>"+
            "9.1 다음 각 항의 경우에 '권리사'와 '유통사'는 본 계약을 해제 및 해지할 수 있다.<br>"+
            "(a) 계약 만료 전, 쌍방이 서면으로 합의한 경우<br>"+
            "(b) 일방에 대하여 파산, 회사정리절차, 화의 기타 이에 유사한 절차가 개시 신청된 경우<br>"+
            "(c) 계약에 따른 수익금에 대하여 가압류, 압류, 추심명령, 전부명령 등 채권보전절차가 취해짐으로써 본 계약의 목적을 달성할 수 없다고 판단되는 경우<br>"+
            "(d) '본 계약' 중 '권리사'의 진술 및 보장이 사실이 아님이 밝혀지거나, 하자가 발생한 경우<br>"+
            "(e) '권리사'가 계약 기간 중 '유통사'의 사전동의 없이 '유통사' 이외의 제3자에게 '콘텐츠'의 전부 또는 일부에 관한 유통권(유통권의 일부를 부여한 경우 포함) 및 기타 이에 준하는 권리를 부여한 경우<br>"+
            "(f) ‘권리사’가 ‘음원 서비스 사업자’의 시스템을 남용하거나 불법 행위를 한다고 ‘음원 서비스 사업자’로부터 확정 및 통지를 받은 경우<br>"+
            "(g) 일방의 행위로 인하여 상대방에게 심각한 명예훼손이 발생한 경우<br>"+
            "(h) 일방이 본 계약상의 의무를 위반함으로써 상대방이 본 계약을 유지하기 어렵다고 판단하여 서면으로 시정을 최고하였음에도 최고장을 받고도 30일 이내에 정당한 사유 없이 시정하지 않은 경우<br><br>"+
            "9.2 해제 및 해지 의사 표시는 서면으로 해야 하며, 그 서면 통지가 상대방에게 도달한 때로부터 해제 및 해지의 효력이 발생한다.<br>"+
            "9.3 해제 및 해지는 손해배상의 청구에 영향을 미치지 아니한다.<br>"+
            "9.4 계약이 해제 및 해지되거나 만료될 경우 ‘유통사’는 ‘음원 서비스 사업자’에 해당 ‘콘텐츠’에 대한 서비스를 중단할 것을 요청해야 한다.<br>"+
            "9.5 계약기간이 해지 또는 만료된 후라도 미지급된 인세가 있는 경우 '유통사'는 '권리사'에게 미지급 인세를 지급하여야 한다.<br>"+
            "9.6 계약기간이 해지 또는 만료된 후라도 ‘유통사’는 ‘계약 기간’ 도중 발생한 서비스에 대한 유통수수료 및 인세에 대해서는 ‘음원 서비스 사업자’로부터 지급받을 권리가 있고 이중 인세는 ‘권리사’에게 지급한다.<br><br>"+
            "10. 진술 및 보장, 손해배상<br>"+
            "10.1 '권리사'는 ‘콘텐츠’의 지적재산권(가창자, 연주자의 저작인접권을 보유한 경우 이를 포함) 및 가창 실연권에 대하여 '유통사'에게 ‘서비스 지역’에 독점적 유통권을 부여할 수 있는 정당한 권리가 있으며, ‘유통사’ 및 ‘음원 서비스 사업자’가 ‘콘텐츠’를 서비스함에 있어서 아무런 법률적 하자가 없음을 보증한다.<br>"+
            "10.2 ‘권리사’는 ‘권리사’가 제공하는 ‘콘텐츠’가 타인의 저작권 및 기타 지적재산권, 명예, 프라이버시를 침해하지 않는 것을 포함하여 본 계약의 계약 대상 지역 내의 어떠한 법령에도 위배되지 않음을 보증한다.<br>"+
            "10.3 ‘권리사’는 커버곡이나 리믹스곡 또는 다른 음원의 샘플링을 포함하고 있는 ‘콘텐츠’의 경우 ‘권리사’가 해당 오리지널 음원의 작곡가 등 모든 권리자들로부터 '유통사'가 ‘서비스 지역’에 독점적 유통하는 데에 필요한 모든 권리를 문서로 득했음을 보증한다.<br>"+
            "10.4 ‘권리사’는 ‘콘텐츠’에 대해서 어떠한 유해한 요소 또는 유통에 제약을 줄 수 있는 요소(예를 들면 바이러스, 버그 등)가 포함되어 있지 않음을 보증한다<br>"+
            "10.5 ‘권리사’는 ‘콘텐츠’가 법적 소송, 행정적 규제에 따른 제한 등 ‘유통사’의 완전한 유통권 행사에 제약이 되는 사항이 없음을 보증한다.<br>"+
            "10.6 본 계약과 관련해 ‘권리사’가 제공한 모든 데이터, 정보는 모두 사실이고 정확하며, ‘계약 기간’ 중 관련 내용에 대한 업데이트가 필요할 경우 ‘권리사’는 신속하게 업데이트 할 것임을 보증한다<br>"+
            "10.7 ‘콘텐츠’ ‘서비스’와 관련하여, 본 계약 당사자는 8.2항에서 특정된 급부 의무 이외에는 모두 어떠한 금원 지급 의무도 없다.<br>"+
            "10.8 ‘권리사’가 본 계약 이후 ‘콘텐츠’와 관련하여 매도, 담보 설정, 또는 기타 권한을 제3자에게 부여하는 계약을 체결한다고 하더라도, ‘권리사’가 본 계약에 따라 ‘유통사’에 제공한 ‘콘텐츠’는 그대로 본 계약이 적용된다.<br>"+
            "10.9 '권리사'는 ‘콘텐츠’에 관한 유통권을  ‘서비스 지역’에서 제3자에게 신탁하거나, '본 계약'에 따른 '유통사'의 권리와 양립할 수 없는 계약을 체결한 사실이 없음을 보증하며, 앞으로도 ‘유통사’의 권리를 침해하거나 본 계약의 내용에 반하는 계약을 체결하지 않음을 보증한다.<br>"+
            "10.10 '권리사'는 ‘콘텐츠’에 관한 지적재산권 분쟁이 발생할 경우, 이로 인한 모든 책임을 지고, '유통사' 및 ‘음원 서비스 사업자’를 면책한다.<br>"+
            "10.11 '권리사'와 '유통사'는 어느 일방이 본 계약에 규정된 의무를 이행하지 않아 입은 손해에 대하여 상대방에게 이에 따른 배상을 청구할 수 있다. 단, 불가항력으로 인하여 발생된 손해 및 피해에 대해서는 서로 면책된다.<br>"+
            "10.12 '권리사'와 '유통사'는 정보를 수집, 가공함에 있어서 위법 또는 부당한 방법을 사용해서는 아니 된다. 일방이 상대방에게 제공한 정보와 관련해서 저작권법, 기타 관련법 등에 따른 권리 침해를 이유로 상대방이 국내 및 국외의 제3자와 민형사상 분쟁에 처하게 되는 경우 가해업체는 이로 인하여 피해업체에게 손해가 발생하지 않도록 자신의 책임으로 면책시키며, 면책할 수 없다면 이로 인하여 피해업체가 받은 일체의 손해를 배상한다.<br>"+
            "10.13 계약 해제권 및 해제권의 행사는 손해배상의 청구에 영향을 미치지 아니한다.<br>"+
            "10.14 ‘권리사’는 ‘유통사’가 동반 책임을 질 수 있는 법적 소송에 대해서 ‘유통사’의 서면 동의 없이 합의하면 안된다.<br>"+ 
            "10.15 ‘권리사’의 사유로 ‘유통사’가 금전적 손해를 볼 가능성이 발생할 경우 ‘유통사’는 ‘권리사’에게 지급해야 할 인세 중 손해의 정도에 해당하는 금액만큼을 지급 보류할 수 있는 권한이 있다. ‘유통사’의 지급 보류는 금전적 손해의 가능성이 해결되거나 해결되었다고 판단될 때까지 유지할 수 있다.<br>"+ 
            "10.16 본 계약의 의무 불이행이나 이행지체가 천재지변, 폭동, 전쟁, 소요사태, 정부(지방자치단체 포함)의 규제 등 불가항력적인 사유로 발생한 경우에는 양 당사자 모두 그에 대한 책임을 지지 아니한다.<br><br>"+
            "11. 보증에 대한 면제<br>"+
            "11-1 ‘유통사’는 본 계약에 의해서 다음 사항을 보증하지 않는다.<br>"+
            "(a) ‘권리사’에게 지급하는 인세의 최소 금액 또는 평균 금액<br>"+
            "(b) ‘유통사’의 사업 및 웹사이트의 영속성 또는 무하자<br>"+
            "(c) 그 외 어떠한 내용의 담보<br><br>"+
            "12. 정보공유와 영업비밀 준수의무<br>"+
            "12-1 ‘권리사’와 ‘유통사’는 ‘본 계약’에 따라 취득하거나 발생된 정보를 서로 공유하기로 하며, 그 정보 및 상대방의 영업비밀을 제3자에게 공개 및 누설해서는 안 된다.<br>"+
            "12-2 ‘유통사’는 ‘권리사’가 제공하는 ‘음반’ 및 ‘콘텐츠’를 사전에 이메일 또는 서면 동의 없이 본 계약 이외의 용도로 사업화 또는 외부에 공개할 수 없다.<br><br>"+
            "13. 기타 중요 사항<br>"+
            "13.1 계약의 승계<br>"+
            "(a) 본 계약상의 모든 권리와 의무는 일방 당사자의 합병, 영업양도, 경영위임 등 사유가 발생한 경우에도 일방 당사자의 합병회사, 영업양수인, 경영수임인 등에게 승계되며, ‘권리사’와 ‘유통사’는 그들로 하여금 본 계약 상의 권리와 의무를 승계하는 것에 동의하도록 할 의무를 진다.<br>"+
            "(b) ‘유통사’는 ‘유통사’가 가지고 있는 본 계약상의 권리 및 의무에 대해 ‘권리사’의 서면 동의를 통해 ‘권리사’ 및 ‘유통사’가 아닌 제 3자에게 양도 또는 위임할 수 있다.<br><br>"+
            "13-2 관할 법원<br>"+
            "(a) 본 계약의 분쟁으로 인하여 소가 제기될 경우 관할 법원은 ‘유통사’의 소재지 관할법원으로 하기로 한다.<br>"+
            "13-3 상관례 준용 및 기타사항<br>"+ 
            "(a) 본 계약에서 누락된 사항에 대하여는 관련 법령 및 법규와 일반 상관례를 적용하여 해석하기로 한다<br>"
        }
    },
    en: {
        chapter_1: {
            title: "Sound Republica Distribution Agreement",
            contents: "Sound Republica Distribution Agreement (hereinafter 'this Agreement') is a binding legal agreement between you and Sound Republica, Inc. (hereinafter “Sound Republica” or “we” or “us”) regarding your use of our service to distribute your musical recordings to selected digital stores and services.<br><br>"+
            "If you are entering into this Agreement on behalf of one or more other people, a group, or a company or other entity, then by accepting this Agreement you represent and warrant to Sound Republica that you are duly authorized to do so on behalf of all such persons/entities and to bind them to this Agreement and that Sound Republica is fully entitled to rely on that fact.<br><br>"+
            "By clicking to agree to this Agreement, you are accepting the terms and conditions below. Therefore, please read this Agreement carefully. The 'Effective Date' of this Agreement is the date on which you click to agree to this Agreement.<br><br>"+
            "Please understand that you must own or otherwise have the legal right to reproduce and distribute all of the recordings, artwork and any other material that you intend to distribute in the Territory, including the right to make and distribute digital downloads embodying the musical compositions therein, as necessary. For instance, you cannot deliver to us any cover versions, remixes, or recordings that include samples unless you have all necessary written permission from the owners and from the songwriters of the applicab -  YOUle original recordings."
        },
        chapter_2: {
            title: "Summary",
            contents: "1.Term<br>"+
            "- 2 years and will renew automatically at the end of the contract period unless one of the parties give notice to the other party in writing or email 30 days prior to the termination of the contract.<br><br>"+
            "2.Territory<br>"+
            "- The universe, or more limited territories, to the extent so limited by you during your registration process<br><br>"+
            "3.Recordings<br>"+ 	
            "- All such data, metadata, information, audio files, video files, image files, artworks, and any other materials you provide to us<br><br>"+ 
            "4.Service Type<br>"+	
            "- Digital: Downloading, Streaming, Ringtones, Background Music<br><br>"+
            "5.Digital Stores<br>"+
            "- Your choice of digital stores, streaming services, and other digital services within Sound Republica’s distribution network<br><br>"+ 
            "6.Payment<br>"+
            "- 85% of any and all monies that we actually earn and receive from your selected Digital Stores which are directly attributable to their exploitation of your Recordings net of sales tax<br><br>"+
            `- However, in case Territory is limited to South Korea, 80% of any and all monies that we actually earn and receive from your selected Digital Stores which are directly attributable to their exploitation of your Recordings net of sales tax<br>` +
            "7.Retention Money<br>"+
            "- $100 USD (Payments to you will not be made unless they exceed $100 USD)<br>"
        },
        chapter_3: {
            title: "Agreement",
            contents: "1.Grant of Rights<br>"+
            "1.1 You grant to us the right and license in the Territory, during the Term to:<br>"+
            "(a) Distribute Recordings to Digital Stores for them to sell to their customers by any and all applicable digital (non-physical) formats, configurations, technologies and methods (including, without limitation, permanent downloads, temporary or 'tethered' download, interactive and non-interactive streaming, 'scan and match' services, and 'cloud' services) to any and all capable devices (including, without limitation, to personal and tablet computers and smartphones), in each case as now or hereafter known.<br><br>"+
            "(b) Create and to authorize Digital Stores to create and publicly perform and make available free preview clips of Recordings and the musical compositions embodied therein via streaming format.<br><br>"+
            "(c) Create, distribute and otherwise commercially exploit Recordings in a digital compilation, provided that we notify you prior to creating a digital compilation.<br><br>"+
            "(d) Display and otherwise use your artist(s) and label name and logo and all artwork, song and album titles, all trademarks, service marks, logos and trade names, and all artist, songwriter, producer and mixer names and approved likenesses, each as embodied in metadata within Recordings or otherwise provided by you, on our website, on Digital Stores' sites and services, and in any marketing, advertising or promotional materials for our service or for Digital Stores.<br><br>"+
            "(e) Collect all income from Digital Stores from their exploitation of Recordings (and to collect income after the Term from exploitation of Recordings during the Term).<br><br>"+
            "(f) Notify Digital Stores and other third parties of our rights and relationship per this Agreement, and to include your name and logo in any listing of our licensors.<br><br>"+
            "(g) Use the Recording as part of the public viewing or broadcast of a production (including but not limited to TV shows, videos, web sites, podcasts, multimedia presentations, and films) for promotional purposes.<br><br>"+
            "1.2 You also grant to us and to Digital Stores the right and license in the Territory, during the Term to:<br>"+ 
            "(a) Take all steps desired or required to effect the foregoing rights and to distribute Recordings and materials as contemplated in this Agreement, including without limitation, to store, host, cache, reproduce, convert, edit, serve, transmit and publicly perform such Recordings, and as otherwise required pursuant to our agreements with those Digital Stores, as may be amended from time to time during the Term.<br><br>"+
            "(b) Market, promote, and advertise the Recordings as available for purchase, in any and all media, whether now known or hereafter developed, as we and Digital Stores determine in their discretion.<br><br>"+
            "(c) Indicate if the Recording is deemed to contain explicit content according to the cultural standards of the Territory.<br><br>"+ 
            "1.3 You will be deemed to have approved any artwork, photographs, biographical material or other information or materials that you provide to us.<br><br>"+
            "2.Your Responsibilities<br>"+
            "2.1 You are solely responsible for and must have obtained all necessary rights, licenses, waivers, clearances and permissions throughout the Territory for all Recordings and other materials in order to enable us and Digital Stores to fully exploit all their rights hereunder free of any claims, liens, encumbrances or other restrictions.<br><br>"+
            "2.2 Your delivery of Recordings and any other materials shall be your unqualified warranty and representation to us that you have obtained any and all such rights required and necessary for us and Digital Stores to sell, distribute, publicly perform, promote, and otherwise exploit such Recordings and materials as contemplated under this Agreement.<br><br>"+ 
            "2.3 You should notify us immediately of changes in intellectual property rights concerning your Recordings.<br><br>"+
            "2.4 You should provide evidence immediately if we require you to verify the rights of Recordings. In this case, however, the data is within the range you can physically provide.<br><br>"+
            "2.5 You should provide immediate data when we request the data necessary for the services and accounting process related to your Recordings.<br><br>"+
            "2.6 Notwithstanding the provisions of the preceding paragraph, in the event of a dispute with an artist or a third party in relation to the Recording services, you should settle the dispute at your responsibility and expense, and we shall be exempted. You will also indemnify us for damages in the event of a conflict.<br><br>"+
            "3.Delivery of Materials<br>"+
            "3.1 The Sound Republica service enables you to upload your Recordings to our servers for distribution to your choice of Digital Stores.<br><br>"+
            "3.2 When uploading each Recording, you must provide us with all accompanying data that we or a Digital Store may require (e.g. artist name, album title, each track title, genre), plus any available cover art.<br><br>"+ 
            "3.3 For purposes of this Agreement, all such data, metadata, information, audio files, video files, image files, artworks, and any other materials you provide to us are included in the definition of 'Recordings.'<br><br>"+
            "3.4 You are required to indicate if the Recording contains explicit content, in which case a Digital Store may tag it accordingly.<br><br>"+
            "4.Credit and Copyright Notices & Ownership<br>"+
            "4.1 All masters and all copyright and other rights subsisting in them will be your property.<br>"+ 
            "4.2 We do not take any copyright in any of your music.<br>"+
            "4.3 We shall incorporate a credit in favor of you and include the appropriate copyright notice on each material for any use of promotional material.<br><br>"+
            "5.Digital Stores<br>"+
            "5.1 We will undertake to provide Recordings to Digital Stores as quickly as possible. However, it can take a few hours to a few days to several weeks for a Digital Store to integrate, process and make Recordings available to customers, depending on the particular Digital Store.<br><br>"+ 
            "5.2 Digital Stores may choose not to carry one or more of Recordings at all or to remove Recordings at any time per their policies and practices.<br><br>"+
            "5.3 We may also decline to distribute or may remove from Digital Stores one or more Recordings from any or all Digital Stores if we receive any legal claims regarding that Recording, if we reasonably believe that any legal claims or issues may arise, if a Recording may violate the terms and conditions of any Digital Store, or for any other reason in our business judgment.<br><br>"+ 
            "5.4 If our agreement with any Digital Store expires or terminates, or if that Digital Store ceases to operate entirely or in a particular territory, then Recordings will no longer be available through that Digital Store.><br><br>"+
            "6.Term<br>"+ 
            "6.1 The term of this Agreement and our services hereunder (the 'Term') will begin on the Effective Date and will continue for two (2) years, unless renewed or terminated earlier.<br><br>"+
            "6.2 The Term will renew automatically at the end of the contract period unless one of the parties give notice to the other party in writing or email stating its intention to terminate at least thirty (30) days before the expiration date.<br><br>"+
            "7.Territory<br>"+ 
            "7.1 Our service area is worldwide.<br><br>"+
            "7.2 If there is a music service provider designated by you, and the provider and we come to an agreement, we may admit the provider’s service areas related to the previous designation.<br><br>"+ 
            "8.Pricing, Payments, and Accounting Terms<br>"+
            "8.1 Pricing<br>"+
            "(a) Each Digital Store will determine format or media and other terms on which it offers Recordings to its customers, in its discretion and according to its business model.<br><br>"+
            "(b) Sound Republica or each Digital Store may determine its pricing to each Digital Store’s customers in its discretion and according to each Digital Store’s business model.<br><br>"+
            "8.2 Payments<br>"+
            "(a) We will pay you 85% of any and all monies that we actually earn and receive from your selected Digital Stores, which are directly attributable to their exploitation of your Recordings, net of sales tax. . (In case Territory is limited to South Korea, we will pay you 80% of any and all monies that we actually earn and receive from your selected Digital Stores, which are directly attributable to their exploitation of your Recordings, net of sales tax.)<br><br>"+
            "(b) However, if a separate contract is signed between you and us, the percentage of your payment shall be subject to a separate contract, not the above paragraph (a) ratio.<br><br>"+
            "(c) Distribution fees owed to us by any entity with whom we have entered into a contractual relationship that are not received or collected by us, as a result of the other party’s failure to perform under a contract, are not included within the calculation set forth in this section.<br><br>"+
            "8.3 Accounting Terms<br>"+ 
            "(a) We will keep all the usual proper records through accounting system relating to the distribution, sales and exploitation of Records under this Agreement.<br><br>"+
            "(b) We will post to your state of account 85% of any and all monies that we actually earn and receive from your selected Digital Stores, which are directly attributable to their exploitation of your Recordings, net of sales tax. . (In case Territory is limited to South Korea, we will pay you 80% of any and all monies that we actually earn and receive from your selected Digital Stores, which are directly attributable to their exploitation of your Recordings, net of sales tax.)<br><br>"+
            "(c) Once payment has been credited to your account, you will be able to withdraw at your discretion. However, withdrawal of payments will not be made unless credited payments exceed $100 USD. Until then we hold off on your withdrawal of payment.<br><br>"+
            "(d) Notwithstanding paragraph (a) through (c), the first payment will be credited after 100 days from the date your Recordings started to be serviced.<br><br>"+ 
            "(e) You must affirmatively request withdrawal of the payment from your account and send us invoice statement in order for you to receive the payment.<br><br>"+ 
            "(f) Payment shall be transmitted in US Dollars via bank wire transfer or other means of transfer, whichever you choose, within 30 days of your request.<br><br>"+ 
            "(g) You shall be responsible for any fees associated with the payment, such as money transfer commission, money exchange commission, or any charges related to such withdrawals.<br><br>"+
            "(h) Any sums that we receive in foreign currency other than the US Dollars will be paid after conversion to US Dollars. Regardless of the exchange rate of the day we received from the music service operator, the applicable exchange rate shall be the first reported current spot exchange rate of the KEB Hana Bank at the day of transaction from us to you.<br><br>"+
            "(i) We shall honor any request from you to change bank at any time if necessary.<br><br>"+
            "(j) You shall be responsible for any and all applicable value-added tax or withholding tax (“Taxes”), in the event that any such Taxes are applicable. All payments required to be made to you by Sound Republica under this Agreement shall be inclusive of all Taxes. If any payments made by Sound Republica to you under this Agreement are subject to withholding tax levied by any governmental authority, Sound Republica may withhold the applicable withholding tax from the relevant payment and remit the balance to you."+
            "(k) If your account is maintained on behalf of a group, company, partnership, or anything other than you alone, then you shall be responsible for paying anyone else.<br><br>"+
            "8.4 Audits<br><br>"+
            "(a) You may engage a Certified Public Accountant to examine those books and records directly related to the sale or other licensed uses of the Recordings and materials.<br><br>"+
            "(b) Those inspections shall be at your expense unless a discrepancy in excess of ten percent (10%) is discovered, in which event we shall bear such expense.<br><br>"+
            "9.Termination<br>"+ 
            "9.1 This Agreement can be terminated at any time before the expiration date if agreed by both parties through mutual consent in writing.<br><br>"+
            "9.2 Either party may terminate this Agreement in each event of the following paragraph<br>"+
            "(a) In case of bankruptcy, company dissolution process, and other similar procedures are filed for initiation on either side.<br><br>"+
            "(b) If we reasonably believe that it is impossible to achieve the purpose of this Agreement by taking the procedures for the preservation of bonds, such as seizure, collection command, and assignment orders.<br><br>"+
            "(c) When the your representation and warranties in this Agreement is found to be untrue or a defect has occurred.<br><br>"+
            "(d) When you grant the rights of your Recordings to any third party without prior consent of us during the term of this Agreement (including if part of the rights is granted) and other rights equivalent to it<br><br>"+
            "(e) If we reasonably believe that you or any of your Recordings or other content has violated this Agreement or the terms and conditions of any Digital Store, that you or your Recordings infringe the intellectual property or other rights of any person or entity, if we are told by Digital Stores or reasonably believes that Digital Stores will not accept your content specifically or categorically, or that you are otherwise abusing any Digital Store or engaging in fraudulent or illegal activity.<br><br>"+
            "(f) When a serious libel has occurred against the other party due to one party’s conduct.<br><br>"+
            "(g) When the other party breach this Agreement provided that the breaching party has been given thirty (30) days to remedy the breach and has failed to do so.<br><br>"+
            "9.3 Termination shall be expressed in writing and shall be effective from the time the written notice reaches the other party.<br><br>"+
            "9.4 Termination do not affect claims for damages<br><br>"+
            "9.5 Upon the termination of this Agreement, we agree to pay all remaining monies due to you and to immediately cease and desist from any and all activities involving the distribution, dissemination, broadcast or any other form of transmittal involving the Recordings belonging to, or that contains material belonging to, you.<br><br>"+
            "9.6 After the termination of this Agreement, we will notify all applicable Digital Stores to remove Recordings and will have no further obligation to you other than to account and pay for monies earned during the Term. Customers who downloaded or otherwise accessed Recordings may be able to retain and continue listening to Recordings even after the Term of this Agreement is over.<br><br>"+
            "9.7 After the termination of this Agreement, we have rights to collect all income from Digital Stores from their exploitation of Recordings during the Term and has obligation to pay those monies to you even after the termination of this Agreement.<br><br>"+
            "10. Representations and Warranties; Indemnification<br><br>"+
            "10.1 You represent and warrant to Sound Republica that:<br>"+
            "(a) You own or are otherwise fully vested of the necessary copyrights and other rights in order to make the grant of rights, licenses and permissions herein, and that the exercise of such rights, licenses and permissions by Sound Republica, the Digital Stores, and Sound Republica’s and Digital Store’s respective permitted successors and assigns shall not violate or infringe any applicable law, rule or regulation or the rights of any third party.<br><br>"+
            "(b) You have secured all third-party grants of rights, consents, licenses and permissions necessary, including without limitation mechanical licenses from copyright owners or proprietors, to grant all rights and licenses and otherwise fully perform all obligations hereunder, and shall make all payments and otherwise comply with all of your obligations under this Agreement.<br><br>"+ 
            "(c) Your Recordings and materials do not and will not, and the authorized use thereof by Sound Republica, Digital Stores and their customers shall not, infringe any copyright, trademark or other intellectual property or other rights (including without limitation rights of publicity, privacy or moral rights), of any third party, or violate any applicable treaty, statute, law, order, rule or regulation.<br><br>"+ 
            "(d) The files containing the Recordings and materials that you provide to us do not and will not contain any bugs, viruses, Trojan horses or other defects or harmful elements or digital rights management restrictions.<br><br>"+ 
            "(e) There are no actual or threatened claims, litigation, administrative proceedings or other actions regarding any Recordings or materials or otherwise adverse to full exploitation of all rights and licenses granted hereunder.<br><br>"+ 
            "(f) All data, metadata, and information provided by you under or in connection with this Agreement is and will be true, accurate and complete, and you agree to update the same promptly as necessary during the Term.<br><br>"+ 
            "(g) We will not be responsible for any payments other than those specified in 7.2 above in connection with the Recordings and materials.<br><br>"+ 
            "(h) Any sale, assignment, transfer, mortgage or other grant of rights in or to your interest in any of the Recordings or materials shall be subject to our rights hereunder and the terms and conditions hereof.<br><br>"+ 
            "(i) There is no existing agreement, and you will not enter into any agreement or perform any act, which materially interferes or is inconsistent with the rights granted to us hereunder.<br><br>"+ 
            "10.2 You shall indemnify and hold harmless, and upon Sound Republica’s request, defend Sound Republica and our affiliates, sublicensees (including the Digital Stores and their customers), successors and assigns, and the respective directors, officers, shareholders, members, managers, employees, agents and representatives of the foregoing, from and against any and all claims, suits, proceedings, disputes, controversies, losses, liabilities, damages, costs and expenses (including reasonable attorneys' fees and costs) resulting from:<br>"+
            "(a) A breach or alleged breach of any of your warranties, representations, covenants or obligations under this Agreement.<br><br>"+ 
            "(b) Any claim that the Recordings, materials, data or information provided or authorized by you or on your behalf hereunder or the use thereof by Sound Republica or a Digital Store violates or infringes the rights of another party.<br><br>"+ 
            "(c) Any other act or omission by you or any of your licensors, agents or representatives.<br><br>"+ 
            "10.3 You will promptly reimburse Sound Republica and any other indemnified parties on demand for any amounts subject to indemnification. We shall notify you of any such claim and shall control the defense thereof, though you may participate in such defense at your own expense.<br><br>"+ 
            "10.4 You may not settle any claim for which Sound Republica may be liable without our prior written consent, which we will not withhold unreasonably.<br><br>"+ 
            "10.5 If any facts, claims, proceedings or other circumstances arise that would be subject to indemnification, then Sound Republica, in addition to any other right or remedy, shall have the right to withhold from any payments otherwise due to you an amount reasonably related thereto until the claim, proceeding or circumstance has been finally resolved, settled or fully adjudicated and the judgment satisfied, or that the statute of limitations on such claim has run, or when you have provided reasonable and adequate security for the claim.<br><br>"+
            "11. No Warranties; Limitation of Liability<br>"+
            "11.1 The sites and service are offered and provided to you as-is.<br><br>"+
            "11.2 We makes no guarantees, representations or warranties, express or implied, statutory or otherwise, including without limitation as to the amount of income that may be earned by or payable to you hereunder, or as to the condition, quality, continuity of operation, performance, merchantability or fitness for a particular purpose of our site or service.<br><br>"+
            "11.3 We do not guarantee that access to or use of the sites or service will be continuous, uninterrupted, error-free or secure.<br><br>"+ 
            "11.4 Any and all warranties are expressly disclaimed and excluded.<br><br>"+
            "11.5 To the maximum extent permitted by law, neither Sound Republica nor any of our affiliates, employees, owners, representatives or agents will be liable for any indirect, incidental, special, exemplary, punitive or consequential damages, including without limitation loss of profits, lost sales, loss of data or loss of goodwill, for any acts or omissions of Digital Stores or their customers, or for your use of or access to our sites or service, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and whether or not we have been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed of its essential purpose. In no event will our aggregate liability arising out of or in connection with this Agreement exceed the sums actually due to you in accordance with paragraph 7.2 above.<br><br>"+
            "11.6 The limitations of damages set forth above are fundamental elements of the basis of the Agreement between you and Sound Republica.<br><br>"+
            "12.Confidentiality<br>"+
            "12.1 Both you and Sound Republica shall not disclose any or all information in this Agreement including but not limited to Recordings (including masters), payment amount to you, and your bank information.<br><br>"+
            "12.2 We may not commercialize or disclose the Recordings you provide to the public for any other purpose other than this Agreement without prior email or written consent.<br><br>"+
            "13. Other Important Provisions<br>"+
            "13.1 Binding Effect<br>"+
            "(a) This Agreement will be binding on the assigns, heirs, executors, affiliates, agents, personal representatives, administrators, and successors (whether through merger, operation of law, or otherwise) of each of the parties.<br><br>"+
            "(b) We may assign or delegate to a third party, through your written consent, the rights and obligations under this Agreement which we have.<br><br>"+
            "13.2 Choice of Law<br>"+
            "(a) This Agreement shall be governed and construed in accordance with the laws of the Republic of Korea, without regard to its conflict of laws principles. All claims relating to or arising out of this contract, or the breach thereof, whether sounding in contract, tort or otherwise, shall likewise be governed by the laws of the Republic of Korea.<br><br>"+
            "(b) Any omissions in this Agreement shall be interpreted by applying the relevant laws and regulations and commercial practice.<br>"
        }
    }
}

// 개인정보 취급 정책
export const privacy = {
    ko: {
        chapter_1: {
            title: "개인정보처리방침",
            contents: "주식회사 사운드리퍼블리카(이하 ‘회사’)는 본 개인정보처리방침(이하 ‘본 방침’)을 통하여 본 방침이 게시된 웹사이트에서 제공되거나 수집되는 정보의 처리에 대해 설명합니다. 또한, 타사 웹사이트 또는 플랫폼에 있는 회사의 애플리케이션을 사용함으로써 제공되거나 수집되는 정보의 처리에 대해 설명합니다.<br><br>"+
            "회사는 본 방침이 게시된 웹사이트 및 타사 웹사이트 또는 플랫폼에 있는 회사의 애플리케이션을 사용함으로써 제공되거나 수집되는 정보의 컨트롤러(Controller) 입니다.<br><br>"+
            "회사는 이용자의 개인정보를 중요시하며, 개인정보처리방침을 통하여 회사가 이용자로부터 제공받은 개인정보를 어떠한 용도와 방식으로 이용하고 있으며, 개인정보 보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다."
        },
        chapter_2: {
            title: "1.수집하는 정보 및 수집방법",
            contents: "1-1. 수집하는 개인정보의 항목<br>"+
            "회사가 수집하는 개인정보의 항목은 다음과 같습니다.<br>"+
            "(a) 이용자가 제공하는 정보<br>"+
            "회사는 이용자가 직접 제공하는 정보를 수집할 수 있습니다.<br>"+
            "수집 항목:<br>"+
            "- 가입 정보<br>" + 
            "- 이메일 주소, ID, 가입 경로, 암호화된 동일인 식별정보(CI), 중복가입확인정보(DI) 등<br>"+
            "- 유통 정보<br>" +
            "국적, MIMS가입자명, 권리자명, 회사명, 전화번호, 지급 화폐, 지급방법, 거주지/소재지 국가<br>"+
            "- 정산 정보<br>"+
            "납세자 번호, 주민등록번호, 사업자등록번호, 생년월일, 설립년월일, 거주지 주소, 사업장소재지, 예금주, 계좌번호, 은행 명 (정산 정보는 세금 납부 및 지급 용도로만 활용되며, 다른 목적으로 활용되지 않습니다.)<br>"+
            "- 미성년자인 경우 법정대리인 정보(법정대리인의 이름, 생년월일, CI, DI 등)<br><br>"+
            "(b) 이용자가 서비스를 사용할 때 수집하는 정보<br>"+
            "회사는 이용자가 직접 제공하는 정보 이외에도, 이용자가 회사의 서비스를 사용하는 과정에서 아래와 같은 정보들이 생성되어 정보를 수집할 수 있습니다.<br>"+
            "수집 항목:<br>"+
            "- 기기 정보<br>"+
            "기기 식별자, 운영 체제, 하드웨어 버전, 기기 설정, 브라우저의 유형 및 설정, 웹사이트 및 앱의 사용 정보, 전화번호 등<br>"+
            "- 로그 정보<br>"+
            "IP 주소, 로그 데이터, 이용시간, 이용자가 입력한 검색어, 인터넷 프로토콜 주소, 쿠키 및 웹비콘 등<br>"+
            "- 위치정보<br>"+
            "GPS, 블루투스 또는 와이파이 신호를 통한 구체적인 지리적 위치를 포함한 기기 위치에 대한 정보 등(법으로 허용되는 지역에 한함)<br>"+
            "- 기타 정보<br>"+
            "이용자의 서비스 사용에 있어 선호도, 광고 환경, 방문하는 페이지 등<br>"+
            "1-2. 수집방법<br>"+
            "회사는 이용자의 개인정보를 수집하는 경우 반드시 사전에 이용자에게 동의를 구하고 있으며, 다음과 같은 방법으로 개인정보를 수집합니다.<br>"+
            "(a) 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대하여 동의를 하고 직접 정보를 입력하는 방식<br>"+
            "(b) 제휴 서비스 또는 단체, 협력회사로부터 개인정보를 제공받는 방식<br>"+
            "(c) 고객센터를 통한 상담 과정에서 웹 페이지, 메일, 팩스, 전화 등을 통해 개인정보를 제공받는 방식<br>"+
            "(d) 생성 정보 수집 도구를 이용한 개인정보 수집"
        },
        chapter_3:{
            title: "2. 수집한 정보의 이용",
            contents: "2.1. 회사는 수집한 이용자의 개인정보를 다음과 같은 목적으로 이용합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br>"+
            "(a) 회원 식별/가입의사 확인, 본인/연령확인, 회원관리 등<br>"+
            "(b) 허가 받지 않은 서비스 이용과 부정 이용 등의 탐지 및 방지<br>"+
            "(c) 이용자가 요구하는 서비스 제공에 관한 계약 이행, 요금 결제 및 요금 정산 등<br>"+
            "(d) 기존서비스 개선, 신규서비스 개발 등 회사 사이트 또는 애플리케이션의 기능 또는 정책 변경사항의 알림<br>"+
            "(e) 기타 이용자의 사전 동의에 의한 이용(예를 들어, 마케팅 광고에 활용 등)<br>"+
            "(f) 다른 사용자와 교류할 수 있는 기회 제공<br>"+
            "(g) 회원의 서비스 이용에 대한 통계, 통계학적 특성에 따른 서비스 제공 및 광고 게재<br>"+
            "(h) 홍보성 이벤트 정보 제공 및 참여 기회 제공<br>"+
            "(i) 준거법 또는 법적 의무의 준수<br>"+
            "(j) 세무 처리<br><br>"+
            "2.2. GDPR 적용에 따른 개인정보의 적법한 처리<br>"+
            "회사는 다음 중 어느 하나에 해당하는 경우에 한하여 적법하게 이용자의 개인정보를 처리합니다.<br>"+
            "(a) 이용자가 자신의 개인정보 처리에 동의한 경우<br>"+
            "(b) 이용자가 당사자인 계약의 이행을 위하여 또는 계약 체결 전에 이용자의 요청에 따른 조치를 취하기 위하여 처리가 필요한 경우:<br>"+
            "- 회원관리, 본인확인 등<br>"+
            "- 이용자가 요구하는 서비스 제공에 관한 계약 이행, 요금 결제 및 요금 정산 등<br>"+
            "(c) 회사에게 적용되는 법적 의무의 준수를 위하여 처리가 필요한 경우<br>"+
            "- 관련법, 규정, 법적 절차 및 정부 요청 사항의 준수<br>"+
            "(d) 이용자 또는 다른 자연인의 중대한 이익을 보호하기 위하여 처리가 필요한 경우<br>"+
            "- 이용자 또는 다른 자연인에게 해를 끼칠 수 있는 사기, 악용 사례, 보안 위험, 기술적 문제에 대한 감지, 예방 및 대응<br>"+
            "(e) 공익적 직무의 실행을 위하여 처리가 필요한 경우 또는 회사에게 부여된 공적 권한의 행사에 처리가 필요한 경우<br>"+
            "(f) 회사나 제 3 자가 추구하는 정당한 이익 목적을 위하여 처리가 필요한 경우 (다만, 특히 이용자가 아동인 경우와 같이 개인정보 보호를 요구하는 이용자의 이익이나 기본권과 자유가 해당 이익에 우선하는 경우에는 그러하지 아니함)"
        },
        chapter_4: {
            title: "3.수집한 정보의 공개",
            contents: "3.1. 회사는 다음의 경우를 제외하고 이용자의 개인정보를 제3자에게 공개하지 않습니다.<br>"+
            "(a) 회사의 계열사, 파트너, 서비스 제공업체에 대한 공개<br>"+
            "- 회사를 대신하여 결제 처리, 주문 이행, 제품 배송, 분쟁 해결(결제 및 배송 분쟁 포함) 등 서비스를 회사의 계열사, 파트너, 서비스 제공업체가 수행하는 경우<br>"+
            "(b) 이용자가 사전에 동의하는 경우<br>"+
            "- 이용자의 개인정보를 특정 업체와 공유하여 해당 업체의 제품 및 서비스에 대한 정보를 제공받기로 이용자가 선택하는 경우<br>"+
            "- 이용자의 개인정보를 소셜 네트워킹 사이트와 같은 타사 사이트 또는 플랫폼과 공유하도록 이용자가 선택하는 경우 기타 이용자가 사전에 동의한 경우<br>"+
            "(c) 법률상 필요한 경우<br>"+
            "- 법령상 공개하도록 요구되는 경우<br>"+
            "- 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우"
        },
        chater_5: {
            title: "4.개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항",
            contents: "1.1. 회사는 이용자에게 개별적인 맞춤서비스 제공을 위해 '쿠키(cookies)' 또는 '웹 비콘(web beacons)' 등을 통하여 비개인적인 집합 정보를 수집할 수 있습니다.<br><br>"+
            "쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로, 이용자의 컴퓨터 하드디스크에 저장됩니다.<br><br>"+
            "웹 비콘은 웹사이트 또는 이메일 상에 있는 소량의 코드입니다. 웹 비콘을 사용하여 이용자가 특정 웹이나 이메일 콘텐츠와 상호 작용했는지 여부를 알 수 있습니다.<br><br>"+
            "이러한 기능은 서비스를 평가하고 개선하여 이용자 경험을 맞춤 설정하는 데 유용하게 이용되어, 이용자에게 더 향상된 서비스를 제공합니다.<br><br>"+
            "1.2. 회사가 수집하는 쿠키 및 웹비콘의 사용 및 개인정보 수집 목적은 다음과 같습니다.<br>"+
            "(a) 반드시 필요한 쿠키<br>"+
            "이 쿠키는 이용자가 회사의 웹사이트 기능을 이용하는 데 필수적인 쿠키입니다. 이 쿠키를 허용하지 않으면 주요 서비스가 제공될 수 없습니다. 이 쿠키는 마케팅에 사용하거나 사용자가 인터넷에서 방문한 사이트를 기억하는 데 사용될 수 있는 정보를 수집하지 않습니다.<br>"+
            "(반드시 필요한 쿠키의 예시)<br>"+
            "- 웹 브라우저 세션 동안 다른 페이지를 검색할 때 주문 양식에 입력한 정보를 기억 ∘ 상품 및 체크아웃 페이지인 경우 주문한 서비스를 기억<br>"+
            "- 웹사이트에 로그인 여부를 확인<br>"+
            "- 회사가 웹사이트의 작동 방식을 변경할 때 이용자가 회사 웹사이트의 올바른 서비스에 연결되었는지 확인<br>"+
            "- 서비스의 특정 어플리케이션 또는 특정 서버로 사용자를 연결<br>"+
            "(b) 수행 쿠키<br>"+
            "이 쿠키는 이용자가 가장 자주 방문하는 페이지 정보와 같이 이용자가 회사 웹사이트를 어떻게 이용하고 있는지에 대한 정보를 수집합니다. 이 데이터는 회사 웹사이트를 최적화시키고 이용자가 좀 더 간편하게 웹사이트를 검색할 수 있도록 도와줍니다. 이 쿠키는 이용자가 누구인지에 대한 정보를 수집하지 않습니다. 이 쿠키가 수집하는 모든 정보는 종합적으로 처리되므로 익명성이 보장됩니다.<br>"+
            "(수행 쿠키의 예시)<br>"+
            "- 웹 분석: 웹 사이트를 사용하는 방법에 대한 통계를 제공 ∘ 광고 반응 요금 : 회사의 광고가 주는 효과를 확인<br>"+
            "- 제휴사 추적: 회사 방문자 중 하나가 제휴사의 웹 사이트를 방문한 것에 대해 제휴사에게 익명으로 피드백을 제공<br>"+
            "- 오류 관리: 발생하는 오류를 측정하여 웹 사이트를 개선하는 데 도움 ∘ 디자인 테스트: 회사의 웹 사이트의 다른 디자인을 테스트<br>"+
            "(c) 기능 쿠키<br>" +
            "이 쿠키는 서비스를 제공하거나 방문을 개선하기 위해 설정을 기억하는 데 사용됩니다. 이 쿠키로 수집된 정보는 이용자를 개별적으로 파악하지 않습니다.<br>"+
            "(기능 쿠키의 예시)<br>"+
            "- 레이아웃, 텍스트 크기, 기본 설정, 색상 등과 같이 적용한 설정을 기억<br>"+
            "- 회사의 설문 조사에 고객이 응하는 경우 이를 기억<br><br>"+
            "(d) 대상 쿠키<br>"+
            "이 쿠키는 '좋아요' 버튼 및 '공유' 버튼과 같은 제 3 자가 제공하는 서비스와 연결됩니다. 제 3 자는 이용자가 회사의 웹사이트를 방문한 것을 인지하여 이러한 서비스를 제공합니다.<br>"+
            "(대상 쿠키의 예시)<br>"+
            "- 소셜 네트워크로 연결하여, 해당 소셜 네트워크가 이용자의 방문 정보를 사용하여 나중에 다른 웹사이트에서 이용자를 대상으로 홍보<br>"+
            "- 이용자가 관심이 있을 수 있는 광고를 제시할 수 있도록 이용자 방문 정보를 광고 대행사에 제공<br><br>"+
            "1.3. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. 웹 브라우저 상단의 도구>인터넷 옵션>인터넷 영역에 대한 설정을 통해서 쿠키 저장 허용 여부를 결정할 수 있습니다. 단, 이용자가 쿠키 설치를 거부하였을 경우 회사가 제공하는 일부 서비스에 어려움이 있을 수 있습니다."
        },
        chapter_4: {
            title: "5.GDPR 적용에 따른 '이용자의 권리’",
            contents: "5.1.이용자 또는 법정대리인은 정보의 주체로서 회사의 개인정보 수집, 사용, 공유와 관련하여 다음과 같은 권리를 행사할 수 있습니다.<br>"+
            "(a) 개인정보에 대한 접근권한<br>"+
            "- 이용자 또는 법정대리인은 정보에 접근하고, 관련법에 따라 정보의 수집, 이용, 공유 등의 기록에 대한 확인을 요청할 수 있습니다.<br>"+
            "(b) 개인정보의 정정권<br>"+
            "- 이용자 또는 법정대리인은 부정확하거나 불완전한 정보의 수정을 요청할 수 있습니다.<br>"+
            "(c) 개인정보의 삭제권<br>"+
            "- 이용자 또는 법정대리인은 목적의 달성, 동의의 철회 등에 따른 정보의 삭제를 요청할 수 있습니다.<br>"+
            "(d) 개인정보의 처리 제한권<br>"+
            "- 이용자 또는 법정대리인은 정보의 정확성, 정보 처리의 적법성 등에 대하여 다툼이 있거나 정보의 보존이 필요한 경우 정보 처리 제한을 요구할 수 있습니다.<br>"+
            "(e) 개인정보의 이동권<br>"+
            "- 이용자 또는 법정대리인은 정보의 제공 또는 이송을 요구할 수 있습니다.<br>"+
            "(f) 반대권<br>"+
            "- 이용자 또는 법정대리인은 직접 마케팅을 위한 정보 처리, 정당한 이익 또는 공적 임무 및 직무 권한 행사에 따른 정보 처리, 연구 및 통계 목적의 정보 처리에 대한 중단을 요구할 수 있습니다.<br>"+
            "(g) 프로파일링을 포함한 자동화된 개별 의사결정에 대한 반대권<br>"+
            "- 이용자 또는 법정대리인은 프로파일링을 포함해서 자신에게 법적 효과를 초래하거나 중대한 영향을 미치는 자동화된 정보 처리에 대한 중단을 요구할 수 있습니다. 이를 위하여 회사(또는 개인정보 관리책임자, 대리인)에게 서면, 또는 이메일로 연락하시면 지체 없이 조치하겠습니다. 다만 회사는 법률에 명시된 타당한 사유 또는 그에 상응하는 사유가 있는 경우에만 그러한 요청을 거부할 수 있습니다."
        },
        chapter_5: {
            title: "6.보안",
            contents: "6.1.회사는 이용자의 개인정보에 대한 보안을 매우 중요하게 생각합니다. 회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적, 관리적 조치를 강구하고 있습니다.<br>"+
            "(a) 기술적 조치<br>"+
            "- 비밀정보 암호화 조치<br>"+
            "(b) 관리적 조치<br>"+
            "- 개인정보 보호 책임자 지정, 개인정보처리자 교육"
        },
        chapter_6: {
            title: "7.어린이(만 14세 미만의 자)",
            contents: "7.1.회사의 웹사이트, 제품과 서비스 등은 원칙적으로 성인 또는 만 14세 이상의 자를 대상으로 한 웹 사이트입니다. 회사는 만14세 미만의 이용자의 개인정보를 수집하지 않는 것을 기본 정책으로 합니다. 따라서, 만14세 미만의 이용자인 경우, 회사 계정을 생성하거나 회사의 서비스 이용을 위해 개인정보를 제공하지 않아야 하며, 이를 위반한 경우, 회사계정이나 서비스가 제공되지 않을 수 있습니다."
        },
        chapter_7: {
            title:"8.개인정보처리방침의 변경",
            contents: "회사는 회사의 본 방침을 수시로 수정 내지 변경할 권리를 보유합니다. 회사가 본 방침을 변경하는 경우 웹사이트 공지사항(또는 서면, 이메일 등의 개별공지)을 통하여 공지하며, 관계법에서 요구하는 경우에는 이용자의 동의를 구하게 됩니다."
        },
        chapter_8: {
            title: "9.캘리포니아 거주자에 대한 안내",
            contents: "캘리포니아에 거주하시는 분이라면 특정 권리 사항이 추가될 수 있습니다. 회사는 캘리포니아 온라인 프라이버시 보호법을 준수하기 위해 회원의 개인정보를 보호하기 위해 필요한 예방책을 마련합니다.<br><br>"+
            "이용자는 개인정보가 누출되었을 경우 정보유출 확인을 요청할 수 있습니다. 또한 회사 웹사이트의 모든 이용자는 개인 계정에 접속하여 정보 수정 메뉴를 이용하여 언제든지 정보를 변경할 수 있습니다.<br><br>"+
            "또한 회사는 웹 사이트 방문자를 추적하지 않습니다. 또한 '추적 방지' 신호도 사용하지 않습니다. 회사는 이용자의 동의 없이 광고 서비스를 통해 개인 식별 정보를 수집하고 타사에 제공하지 않습니다."
        },
        chapter_9: {
            title: "10.한국인 거주자에 대한 안내",
            contents: "회사는 대한민국 정보통신망법 및 개인정보보호법이 요구하는 몇 가지 추가 공개 사항을 다음과 같이 안내합니다.<br>"+
            "10.1.수집하는 정보<br>"+
            "회사가 수집하는 개인정보의 항목은 다음과 같습니다.<br>"+
            "(a) 필수 항목<br>"+
            "수집 항목:<br>"+
            "- 가입 정보<br>"+
            "이메일 주소, ID, 가입 경로, 암호화된 동일인 식별정보(CI), 중복가입확인정보(DI) 등<br>"+
            "- 유통 정보<br>"+
            "국적, MIMS가입자명, 권리자명, 회사명, 전화번호, 지급 화폐, 지급방법, 거주지/소재지 국가<br>"+
            "- 정산 정보<br>"+
            "납세자 번호, 주민등록번호, 사업자등록번호, 생년월일, 설립년월일, 거주지 주소, 사업장소재지, 예금주, 계좌번호, 은행 명 (정산 정보는 세금 납부 및 지급 용도로만 활용되며, 다른 목적으로 활용되지 않습니다.)<br>"+
            "- 미성년자인 경우 법정대리인 정보(법정대리인의 이름, 생년월일, CI, DI 등)<br>"+
            "(b) 서비스 이용과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br>"+
            "- 기기 정보(기기 식별자, 운영 체제, 하드웨어 버전, 기기 설정, 전화번호 등)<br>"+
            "- 로그 정보(로그 데이터, 이용시간, 이용자가 입력한 검색어, 인터넷 프로토콜 주소, 쿠키 및 웹비콘 등)<br>"+
            "- 위치정보(GPS, 블루투스 또는 와이파이 신호를 통한 구체적인 지리적 위치를 포함한 기기 위치에 대한 정보 등)<br>"+
            "- 기타 생성 정보<br><br>"+
            "10.2.수집한 개인정보의 위탁<br>"+
            "회사는 서비스 이행을 위해 아래와 같이 개인정보 처리 업무를 외부 전문업체에 위탁하여 처리하고 있습니다. 개인정보 처리 위탁은 개별 서비스 별로 그 이행을 위해 필요한 경우에 한해 각 위탁업체에 대해 이루어집니다.<br><br>"+
            "회사가 개인정보의 처리를 위탁하는 경우에는 개인정보 보호의 안전을 기하기 위하여 개인정보보호 관련 지시 엄수, 개인정보에 대한 비밀유지, 제 3 자 제공의 금지 및 사고시의 책임부담, 위탁기간, 처리 종료 후의 개인정보의 반환 또는 파기 등을 명확히 규정하고, 위탁업체가 개인정보를 안전하게 처리하도록 감독합니다.<br><br>"+
            "수탁업체명&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;수탁업체명&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;위탁 기간<br>"+
            "Google LLC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;서비스 제공을 위한 시스템 개발, 데이터 보관, 보안 서버, 이메일 인증&nbsp;&nbsp;&nbsp;&nbsp;위탁계약 종료 시까지<br>"+
            "Payoneer Inc.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;대한민국 외 권리자 로열티 정산 결제 처리&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;위탁계약 종료 시까지<br><br>"+
            "10.3.개인정보의 제 3 자 제공에 대한 세부사항<br>"+
            "회사는 이용자의 개인정보를 제 3 자에게 공개하거나 제공하지 않습니다.<br>"+
            "10.4.개인정보의 보유 및 이용기간<br>"+
            "회사는 이용자의 개인정보의 수집 및 이용 목적이 달성되는 경우, 개인정보에 대한 법적∙경영적 필요가 해소되는 경우, 또는 이용자가 요청하는 경우, 이용자의 개인정보를 지체 없이 파기합니다. 다만 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 관계 법령에 따라 보관해야 하는 정보는 다음과 같습니다.<br>"+
            "- 계약 또는 청약철회 등에 관련 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)<br>"+
            "- 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)<br>"+
            "- 소비자의 불만 또는 분쟁 처리에 관한 기록: 3 년 (전자상거래 등에서의 소비자보호에 관한 법률)<br>"+
            "- 신용정보의 수집/처리 및 이용 등에 관한 기록: 3 년 (신용정보의 이용 및 보호에 관한 법률)<br>"+
            "- 표시/광고에 관한 기록: 6 개월 (전자상거래 등에서의 소비자보호에 관한 법률)<br>"+
            "- 이용자의 인터넷 등 로그기록/이용자의 접속지 추적 자료: 3 개월 (통신비밀보호법)<br>"+
            "- 그 외의 통신사실 확인자료: 12 개월 (통신비밀보호법)<br><br>"+
            "10.5.개인정보의 파기 절차 및 파기방법<br>"+
            "(a) 회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만 관계법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 보관한 후 파기합니다. 회사는 파기 사유가 발생한 개인정보를 선정하고 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. 이때 별도로 저장 관리되는 개인정보는 법령에 정한 경우가 아니고서는 절대 다른 용도로 이용되지 않습니다. 개인 정보가 수록된 종이 기록은 파쇄 또는 소각하고, 전자 파일에 저장된 개인 정보는 기술적으로 복원이 불가능한 방법을 이용하여 삭제합니다.<br><br>"+
            "10.6.이용자 및 법정 대리인의 권리와 그 행사방법<br>"+
            "(a) 회원 및 법정대리인은 언제든지 등록되어 있는 자신 또는 본인의 개인정보를 열람하거나 수정, 삭제, 처리 정지 등을 요청할 수 있습니다.<br>"+
            "(b) 회원의 개인정보를 열람, 수정하기 위해서는 회사 사이트의 “개인정보변경” 버튼을 클릭하여 본인확인 절차를 거친 후 직접 열람, 수정할 수 있습니다. 가입해지를 위해서는 “회원탈퇴” 버튼을 클릭하여 본인확인 절차를 거친 후 회원 탈퇴도 가능합니다. 그밖에 회사의 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락을 통하여 개인정보 열람, 수정, 삭제, 처리 정지 등을 요청할 수 있습니다.<br>"+
            "(c) (a) 항 및 (b) 항에 따른 권리 행사는 회원의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br>"+
            "(d) 다음과 같은 경우에는 예외적으로 개인 정보의 열람 및 정정, 처리정지요구는 제한될 수 있습니다.<br>"+
            "- 본인 또는 제3자의 생명, 신체, 재산 또는 권익을 현저하게 해할 우려가 있는 경우<br>"+
            "- 당해 서비스 제공자의 업무에 현저한 지장을 미칠 우려가 있는 경우<br>"+
            "- 법령에 위반하는 경우"
        },
        chapter_10: {
            title: "11.기타",
            contents: "11.1.데이터 국외 이전<br>"+
            "회사는 전 세계를 무대로 영업함으로 본 개인정보처리방침에 명시된 목적을 위해 타국에 위치한 회사나 타사에 이용자의 개인정보를 제공할 수 있습니다. 개인정보가 전송, 보유 또는 처리되는 곳에 대해서는 회사가 개인정보 보호를 위한 합당한 조치를 취합니다.<br><br>"+
            "또한, 유럽연합 또는 스위스에서 얻은 개인정보를 사용 또는 공개 시 회사는 유럽연합-미국 간 프라이버시 쉴드(US-EU Privacy Shield) 협약, 스위스-미국 간 프라이버시 쉴드(Swiss-US Privacy Shield) 협약을 준수하거나 유럽연합 집행기관에서 승인한 표준 계약 조항 사용, 또는 적절한 안전장치 보장을 위해 유럽연합 규정 내에서 다른 방안을 강구하거나 이용자의 동의를 구합니다.<br>"+
            "11.2.제 3 자 사이트 및 서비스<br>"+
            "회사의 웹 사이트, 제품, 서비스 등은 제 3 자의 웹사이트, 제품, 서비스 등의 링크를 포함할 수 있습니다. 링크된 제 3 자 사이트의 개인정보처리방침이 회사의 정책과 다룰 수 있습니다. 따라서 이용자들은 링크된 제 3 자 사이트의 개인정보처리방침을 추가적으로 검토하셔야 합니다."
        },
        chapter_11: {
            title: "12.회사 및 개인정보 보호책임자 안내",
            contents: "12.1.본 방침에 대하여 의문 사항이 있거나 회사가 보유한 이용자의 정보를 업데이트 하고자 하는 경우, 아래 연락처로 회사에 연락하시기 바랍니다.<br><br>"+
            "회사명: 주식회사 사운드리퍼블리카<br>"+
            "주소: 서울특별시 중구 남대문로9길 40, 20층(다동, 센터플레이스), 04522<br>"+
            "이메일: yay@soundrepublica.com<br><br>"+
            "회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보보호책임자(DPO)를 지정하고 있습니다.<br><br>"+
            "회사의 개인정보보호책임자(DPO):  김태윤<br>"+
            "주소:  서울특별시 중구 남대문로9길 40, 20층(다동, 센터플레이스), 04522<br>"+
            "이메일: support@soundrepublica.com<br><br>"+
            "12.2.기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.<br>"+
            "개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)<br>"+
            "대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)<br>"+
            "경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)"
        },
        chapter_12: {
            title: "13.개인정보처리방침의 변경 및 사전 고지",
            contents: "본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지하겠습니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다.<br><br>"+
            "공고 일자:  2020년 4월 20일<br>"+
            "시행 일자:  2020년 4월 27일<br><br>"+
            "*현행 버전의 개인정보처리방침은 제13조의 시행일자로부터 다음 버전 시행일 전날까지 적용됩니다. 이전 버전의 내용은 공지사항을 참고해주시기 바랍니다."
        }
    },
    en:{
        chapter_1: {
            title: "Privacy Policy",
            contents: "Through this Policy (the 'Policy'), Sound Republica, Inc. (the 'Company') explains the way of treatment of the information which is provided or collected in the web sites on which this Policy is posted. In addition, the Policy also explains the information which is provided or collected in the course of using the applications of the Company which exist in the websites or platforms of other company.<br><br>"+
            "The Company is the controller of the information provided or collected in the websites on which this Policy is posted and in the course of using the applications of the Company which exist in the websites or platforms of other company.<br><br>"+
            "Through this Policy, the Company regards personal information of the users as important and inform them of the purpose and method of Company's using the personal information provided by the users and the measures taken by the Company for protection of those personal information."
        },
        chapter_2: {
            title: "1.Information to be collected and method of collection",
            contents: "1.1.Personal information items to be collected<br><br>"+
            "Personal information items to be collected by the Company are as follows:<br><br>"+
            "(a) Information provided by the users<br>"+
            "The Company may collect the information directly provided by the users.<br>"+
            "Items:<br>"+
            "- Join Info.<br>"+
            "Email address, ID, join route, encoded identification information (CI), identification information of overlapped membership (DI)<br>"+
            "- Distribution Info.<br>"+
            "Nationality, MIMS member name, name, company name, telephone, currency, payment method, residential country or business establishment location<br>"+
            "- Settlement Info.<br>"+
            "Tax ID No., resident registration number, business license number, date of birth, date of establishment, residence address, business establishment location, depositary stock, bank account number, bank name (Settlement information is used only for tax payment and payment purposes and is not used for other purposes.)<br>"+
            "- For minors, information of legal representatives (name, birth date, CI and DI of legal representatives)<br><br>"+
            "(b) Information collected while the users use services<br>"+
            "Besides of information directly provided by the users, the Company may collect the following information in the course that the users use the service provided by the Company.<br><br>"+
            "Items:<br>"+
            "- Equipment information<br>"+
            "Equipment identifier, operation system, hardware version, equipment set-up, type and set-up of browser, use information of website or application and telephone number<br>"+
            "- Log information<br>"+
            "IP address, log data, use time, search word input by users, internet protocol address, cookie and web beacon<br>"+
            "- Location information<br>"+
            "Information of device location including specific geographical location detected through GPS, Bluetooth or Wi-Fi (limited to the region permissible under the laws)<br>"+
            "- Other information<br>"+
            "Preference, advertisement environment, visited pages regarding service use of users<br><br>"+
            "1.2.Method of collection<br>"+
            "When the Company collects the user’s personal information, it always asks the user’s consent in advance and collects the user’s personal information in the following manner:<br>"+
            "(a) In the process of signing up as a member and using the service, the user agrees to collect personal information and enters the information directly<br>"+
            "(b) The method of receiving personal information from affiliated services or organizations or partner companies<br>"+
            "(c) In the course of consultation through the Customer Center, user’s personal information is provided through web pages, mails, faxes, phone calls, etc.<br>"+
            "(d) Using a tool to collect generated information"
        },
        chapter_3: {
            title: "2.Use of collected information",
            contents: "2.1.The Company uses the collected information of users for the following purposes. The Company agrees that it will obtain a consent from the users, if the Company desires to use the information other than those expressly stated in this Policy.<br><br>"+
            "(a) To Identify members, to confirm their intention to sign up, to verify their identity, age, or to manage members, etc.<br>"+
            "(b) To detect and deter unauthorized or fraudulent use of or abuse of the Service<br>"+
            "(c) Performance of contract, service fee payment and service fee settlement regarding provision of services demanded by the users<br>"+
            "(d) Improvement of existing services and development of new services<br>"+
            "(e) Making notice of function of company sites or applications or matters on policy change<br>"+
            "(f) To help you connect with other users you already know and, with your permission, allow other users to connect with you<br>"+
            "(g) To make statistics on member’s service usage, to provide services and place advertisements based on statistical characteristics<br>"+
            "(h) To provide information on promotional events as well as opportunity to participate<br>"+
            "(i) To comply with applicable laws or legal obligation<br>"+
            "(j) Use of information with prior consent of the users (for example, utilization of marketing advertisement)<br>"+
            "(k) Tax processing<br><br>"+
            "2.2.Lawful processing of personal information under GDPR<br>"+
            "Processing personal information by the Company shall be lawful only if and to the extent that at least one of the following applies:<br>"+
            "(a) A user has given consent to the processing of his or her personal information.<br>"+
            "(b) Processing is necessary for the performance of a contract to which a user is party or in order to take steps at the request of a user prior to entering into a contract:<br>"+
            "- Member management, identification, etc.<br>"+
            "- Performance of a contract in relation to providing the services required by users, payment and settlement of fees, etc.<br>"+
            "(c) Processing is necessary for compliance with a legal obligation to which the Company is subject<br>"+
            "- Compliance with relevant law, regulations, legal proceedings, requests by the government<br>"+
            "(d) Processing is necessary in order to protect the vital interests of users, or other natural persons - Detection of, prevention of, and response to fraud, abuse, security risks, and technical issues that may harm users or other natural persons<br>"+
            "(e) Processing is necessary for the performance of a task carried out in the public interest or in the excise of official authority vested in the Company<br>"+
            "(f) Processing is necessary for the purposes of the legitimate interests pursued by the Company or by a third party (except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child)."
        },
        chapter_4:{
            title: "3.Disclosure of collected information",
            contents: "3.1.Except for the following cases, the Company will not disclose personal information with a 3rd party:<br>"+
            "(a) When the Company disclosing the information with its affiliates, partners and service providers;<br>"+
            "- When the Company's affiliates, partners and service providers carry out services such as bill payment, execution of orders, products delivery and dispute resolution (including disputes on payment and delivery) for and on behalf of the Company<br>"+
            "(b) When the users consent to disclose in advance;<br>"+
            "- When the user selects to be provided by the information of products and services of certain companies by sharing his or her personal information with those companies<br>"+
            "- When the user selects to allow his or her personal information to be shared with the sites or platform of other companies such as social networking sites<br>"+
            "(c) Other cases where the user gives prior consent for sharing his or her personal information • when disclosure is required by the laws:<br>"+
            "- If required to be disclosed by the laws and regulations; or<br>"+
            "- If required to be disclosed by the investigative agencies for detecting crimes in accordance with the procedure and method as prescribed in the laws and regulations"
        },
        chapter_5: {
            title:"4.Concerning the installation, operation and rejection of automatic personal information collecting devices",
            contents:"4.1.The Company may collect collective and impersonal information through 'cookies' or 'web beacons'.<br><br>"+
            "Cookies are very small text files to be sent to the browser of the users by the server used for operation of the websites of the Company and will be stored in hard-disks of the users' computer.<br><br>"+
            "Web beacon is a small quantity of code which exists on the websites and e-mails. By using web beacons, we may know whether a user has interacted with certain webs or the contents of email.<br><br>"+
            "These functions are used for evaluating, improving services and setting-up users' experiences so that much improved services can be provided by the Company to the users<br><br>"+
            "4.2.The items of cookies to be collected by the Company and the purpose of such collection are as follows:<br>"+
            "(a) Strictly necessary cookies<br>"+
            "This cookie is a kind of indispensable cookie for the users to use the functions of website of the Company. Unless the users allow this cookie, key services cannot be provided. This cookie does not collect any information which may be used for marketing or memorizing the sites visited by the users<br>"+
            "(Examples of necessary cookies)"+
            "- Memorize the information entered in an order form while searching other pages during web browser session<br>"+
            "- For the page of products and check-out, memorize ordered services<br>"+
            "- Check whether login is made on website<br>"+
            "- Check whether the users are connected with correct services of the website of the Company while the Company changes the way of operating its website ∘ Connect the users with certain application or server of the services<br>"+
            "(b) Performance cookies<br>"+
            "This cookie collects information how the users use the website of the Company such as the information of the pages which are visited by the users most. This data helps the Company to optimize its website so that the users can search that website more comfortably. This cookie does not collect any information who are the users. Any and all the information collected by this cookie will be processed collectively and the anonymity will be guaranteed.<br>"+
            "(Examples of performance cookies)<br>"+
            "- Web analysis: provide statistical data on the ways of using website<br>"+
            "- Advertisement response fee: check the effect of advertisement of the Company<br>"+
            "- Tracing affiliated companies; one of visitors of the Company provides anonymously feedback to the affiliated companies<br>"+
            "- Management of error: measure an error which may occur so as to give a help for improving website<br>"+
            "- Design testing: test other design of the website of Company<br><br>"+
            "(c) Functionality cookies"+
            "This cookie is used for memorizing the set-ups so that the Company provides services and improves visit of users. Any information collected by this cookie do not identify the users individually.<br>"+
            "(Examples of functionality cookies)<br>"+
            "- Memorize set-ups applied such as layout, text size, basic set-up and colors<br>"+
            "- Memorize when the customer responds to a survey conducted by the Company<br><br>"+
            "(d) Targeting cookies or advertising cookies<br>"+
            "This cookie is connected with the services provided by a 3rd party such as the buttons of 'good' and 'share'. The 3rd party provides these services by recognizing that the users visits the website of the Company.<br>"+
            "(Examples of targeting cookies or advertising cookies)<br>"+
            "- Carry out PR to the users as targets in other websites by connecting through social networks and these networks use the information of users' visit<br>"+
            "- Provide the information of users' visit to ad agencies so that they can suggest an ad which may attract the interest of the users<br><br>"+
            "4.3.Users have the option to install cookies. Therefore, users can set options in users’ web browser to allow all cookies, check each time a cookie is saved, or refuse to save all cookies. Users can decide whether to allow cookies to be saved by using tools at the top of their web browser> Internet Options> Setting for Internet. However, if the user refuses to install cookies, some services provided by the company may have difficulty."
        },
        chapter_6: {
            title:"5.User’s right when applying GDPR",
            contents:"5.1.The users or their legal representatives, as main agents of the information, may exercise the following rights regarding the collection, use and sharing of personal information by the Company:<br>"+
            "(a) The right to access to personal information;<br>"+
            "- The users or their legal representatives may access the information and check the records of the collection, use and sharing of the information under the applicable law.<br>"+
            "(b) The right to rectification;<br>"+
            "- The users or their legal representatives may request to correct inaccurate or incomplete information.<br>"+
            "(c) The right to erasure;<br>"+
            "- The users or their legal representatives may request the deletion of the information after the achievement of their purpose and the withdrawal of their consent.<br>"+
            "(d) The right to restriction of processing;<br>"+
            "- The users or their legal representatives may make temporary suspension of treatment of personal information in case of the disputes over the accuracy of information and the legality of information treatment, or if necessary, to retain the information.<br>"+
            "(e) The right to data portability<br>"+
            "- The users or their legal representatives may request to provide or transfer the information.<br>"+
            "(f) The right to object<br>"+
            "- The users or their legal representatives may suspend the treatment of personal information if the information is used for the purpose of direct marketing, reasonable interests, the exercise of official duties and authority, and research and statistics.<br>"+
            "(g) The right to automated individual decision-making, including profiling<br>"+
            "- The users or their legal representatives may request to cease the automated treatment of personal information, including profiling, which has critical impact or cause legal effect on them.<br><br>"+
            "If, in order to exercise the above rights, you, as a user, use the menu of 'amendment of member information of webpage or contact the Company by sending a document or e-mails to the Company (person in charge of management of personal information or a deputy), the Company will take measures without delay: Provided that the Company may reject the request of you only to the extent that there exists either proper cause as prescribed in the laws or equivalent cause. "
        },
        chapter_7: {
            title: "6.Security",
            contents: "6.1.The Company regards the security of personal information of uses as very important. In order to prevent the loss, theft, leakage, alteration or damage of personal information of the users, the Company takes technical and managerial measures for securing safety as follows: <br>"+
            "(a) Technical measures<br>"+
            "- Take measures of encryption for confidential information<br>"+
            "(b) Managerial measures<br>"+
            "- Appoint a staff responsible for protecting personal information<br>"+
            "- Provide education and training for staffs treating personal information"
        },
        chapter_8: {
            title: "7.Protection of personal information of children",
            contents: "7.1.The website, products and services of the Company are the ones to be provided to adults and person aged 14 or older, in principle. In principle, the Company does not collect any information from the children under 14 or equivalent minimum age as prescribed in the laws in relevant jurisdiction. Therefore, if you are a user under 14 years of age, you should not create a company account or provide personal information to use our services. If you violate it, your company account or service may not be available"
        },
        chapter_9: {
            title: "8.Modification of Privacy Protection Policy",
            contents: "8.1.The Company has the right to amend or modify this Policy from time to time and, in such case, the Company will make a public notice of it through bulletin board of its website (or through individual notice such as written document, e-mail) and obtain consent from the users if required by relevant laws."
        },
        chapter_10: {
            title: "9.Guide for users residing in California",
            contents: "If the user resides in California, certain rights may be given. The Company prepare preventive measures necessary for protecting personal information of members so that the Company can comply with online privacy protection laws of California.<br><br>"+
            "In case of leakage of personal information, a user may request the Company to check the leakage. In addition, all the users in the website of the Company, can modify their information at any time by using the menu for changing information by connecting their personal account.<br><br>"+
            "Moreover, the Company does not trace the visitors of its website nor use any signals for 'tracing prevent'. The Company will not collect and provide any personal identification information through ad services without consent of users."
        },
        chapter_11: {
            title: "10.Guide for users residing in Korea",
            contents: "The Company guides several additional matters to be disclosed as required by the information network laws and personal information protection laws in the Republic of Korea as follows:<br>"+
            "10.1.Information collected<br>"+
            "The items collected by the Company are as follows<br>"+
            "(a) Required information<br>"+
            "Information provided by the users<br>"+
            "The Company may collect the information directly provided by the users.<br>"+
            "- Join Info.<br>"+
            "Email address, ID, join route, encoded identification information (CI), identification information of overlapped membership (DI)<br>"+
            "- Distribution Info.<br>"+
            "Nationality, MIMS member name, name, company name, telephone, currency, payment method, residential country or business establishment location<br>"+
            "- Settlement Info.<br>"+
            "Tax ID No., resident registration number, business license number, date of birth, date of establishment, residence address, business establishment location, depositary stock, bank account number, bank name (settlement information is used only for tax payment and payment purposes and is not used for other purposes.)<br>"+
            "- For minors, information of legal representatives (name, birth date, CI and DI of legal representatives)<br><br>"+
            "(b) Information collected while the users use services<br>"+
            "Besides of information directly provided by the users, the Company may collect information in the course that the users use the service provided by the Company.<br>"+
            "Items<br>"+
            "- Equipment information<br>"+
            "Equipment identifier, operation system, hardware version, equipment set-up, type and set-up of browser, use information of website or application and telephone number<br>"+
            "- Log information<br>"+
            "IP address, log data, use time, search word input by users, internet protocol address, cookie and web beacon<br>"+
            "- Location information<br>"+
            "Information of device location including specific geographical location detected through GPS, Bluetooth or Wi-Fi (limited to the region permissible under the laws)<br>"+
            "- Other information<br>"+
            "Preference, advertisement environment, visited pages regarding service use of users<br><br>"+
            "10.2.Commission for collected personal information<br>"+
            "For carrying out services, the Company commissions external professional companies (subcontractors) to process personal information as follows. This commissioned works for processing personal information is carried out by each subcontractor and service only if necessary, for providing that service.<br><br>"+
            "In commissioning process of personal information, in order to secure safety of personal information, the Company supervises and ensure to expressly state in the agreement with subcontractors so that those subcontractors will safely process personal information by strictly complying with directions regarding personal information protection, keeping personal information secret, not disclosing it to a 3rd party and being liable for accidents and returning or destructing personal information upon termination of the commission or process.<br>"+
            "Subcontractors&nbsp;&nbsp;&nbsp;&nbsp;Providing Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Period of subcontract<br>"+
            "Google LLC&nbsp;&nbsp;&nbsp;&nbsp;System development for service delivery, data archiving,     Until the termination of the<br>"+
            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;security server, email authentication&nbsp;&nbsp;&nbsp;&nbsp;subcontract<br>"+
            "Payoneer Inc.&nbsp;&nbsp;&nbsp;&nbsp;Calculation and payment processing of royalty for&nbsp;&nbsp;&nbsp;&nbsp;Until the termination of the<br>"+
            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;non-South Korean rights holders&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subcontract<br><br>"+
            "10.3.Details of provision of personal information to 3rd party<br>"+
            "The Company does not disclose or provide personal information of the users to a 3rd party.<br><br>"+
            "10.4.Period for retention and use of personal information<br>"+
            "In principle, the Company destructs personal information of users without delay when: the purpose of its collection and use has been achieved; the legal or management needs are satisfied; or users request: Provided that, if it is required to retain the information by relevant laws and regulations, the Company will retain member information for certain period as designated by relevant laws and regulations. The information to be retained as required by relevant laws and regulations are as follows:<br><br>"+
            "- Record regarding contract or withdrawal of subscription: 5 years (The Act on Consumer Protection in Electronic Commerce)<br>"+
            "- Record on payment and supply of goods:5 years (The Act on Consumer Protection in Electronic Commerce)<br>"+
            "- Record on consumer complaint or dispute treatment: 3 years (The Act on Consumer Protection in Electronic Commerce)<br>"+
            "- Record on collection/process, and use of credit information: 3 years (The Act on Use and Protection of Credit Information)<br>"+
            "- Record on sign/advertisement: 6 months (The Act on Consumer Protection in Electronic Commerce)<br>"+
            "- Log record of users such as internet/data detecting the place of user connection: 3 months (The Protection of Communications Secrets Act)<br>"+
            "- Other data for checking communication facts: 12 months (The Protection of Communications Secrets Act)<br><br>"+
            "10.5.Procedure and method of destruction of personal information<br>"+
            "In principle, the Company destructs the information immediately after the purposes of its collection and use have been achieved without delay: Provided that, if any information is to be retained as required by relevant laws and regulations, the Company retain it for the period as required by those laws and regulations before destruction and, in such event, the personal information which is stored and managed separately will never be used for other purposes. The Company destructs: hard copies of personal information by shredding with a pulverizer or incinerating it; and delete personal information stored in the form of electric file by using technological method making that information not restored.<br><br>"+
            "10.6.Rights of users and legal representatives and their methods of exercise<br>"+
            "(a) Members and their legal representatives may, at any time, request to peruse, modify, delete, or suspend processing of their registered personal information.<br>"+
            "(b) Click the “Change Personal Information” button on The Company’s website to go through the identification process and peruse and modify the member’s personal information. To unsubscribe, you can also withdraw from the membership after going through the identification process by clicking the “Leave Members” button. In addition, members and their legal representatives can request The Company’s Data Protection Officer to read, modify, delete, and suspend processing of personal information through written, telephone or e-mail.<br>"+
            "(c) The exercise of rights under paragraph (a) and (b) can be done through the legal representative of the member or through the agent of the delegated person. In such cases, a letter of attorney under attached Form 11 of the Enforcement Rules of the Personal Information Protection Act shall be submitted.<br>"+
            "(d) In the following cases, the access, correction, and suspension of processing of personal information may be limited.<br>"+
            "- If it is feared to significantly harm the life, body, property or rights of the person or a third party<br>"+
            "- If there is a possibility of significant disruption to the service provider’s work<br>"+
            "- In case of violation of the statue"
        },
        chapter_12: {
            title: "11.Others",
            contents: "11.1.Data transfer to other countries<br><br>"+
            "Considering it engages in global businesses, the Company may provide the users' personal information to the companies located in other countries for the purpose as expressly stated in this Policy. For the places where the personal information is transmitted, retained or processed, the Company takes reasonable measures for protecting that personal information.<br><br>"+
            "(If used in the US, additional security measures may be available) In addition, when the personal information obtained from the European Union or Switzerland is used or disclosed, the Company may have to comply with the US-EU Privacy Shield and Swiss-US Privacy Shield, take other measures or obtain consent from users so far as those complies with the regulations of EU so as to use a standardized agreement provision approved by executing organizations of EU or securing proper safe measures.<br><br>"+
            "11.2.3rd party's sites and services<br><br>"+
            "The website, product or service of the Company may include the links to the ones of a 3rd party and the privacy protection policy of the site of 3rd party may be different. Thus, it is required for the users to check additionally that policy of a 3rd party site linked to the site of the Company."
        },
        chapter_13: {
            title: "12.Contact information of Company and DPO",
            contents: "12.1.Please use one of the following methods to contact the Company should you have any queries in respect to this policy or wish to update your information: <br><br>"+
            "Company name: Sound Republica, Inc.<br>"+
            "Address: 40, Namdaemun-ro 9-gil, Jung-gu, Seoul, Republic of Korea, 04522<br>"+
            "E-mail: yay@soundrepublica.com<br><br>"+
            "12.2.The Company designates the following Data Protection Officer (DPO) in order to protect personal information of customers and deal with complaints from customers."+
            "DPO of the Company: TAEYOON KIM<br>"+
            "Address: 40, Namdaemun-ro 9-gil, Jung-gu, Seoul, Republic of Korea, 04522<br>"+
            "E-mail: support@soundrepublica.com"
        },
        chapter_14: {
            title: "13.Notification duty before amendment",
            contents: "13.1.If there is any addition, deletion or amendment of this Privacy Policy, we will notify you at least 7 days before the revision through 'Notice'.<br>"+
            "However, if significant changes are made to the user's rights, such as items of personal information to be collected, changes in the purpose of use, etc., at least 30 days notice shall be given.<br><br>"+
            "Date of announcement: 2020 April 20th<br>"+
            "Date of enforcement: 2020 April 27th<br><br>"+
            "*The current version of the Policy is effective from the enforcement date of Article 13 to the day before the effective date of the next version. Please refer to the Notice in our website to refer to the previous versions of the Policy."
        }
    }
}


 
