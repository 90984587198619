<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["chartOptions"],
  data(){
      return {
          chartsets: this.chartOptions
      }
  },
  mounted() {
    this.renderChart(
        this.chartsets,
        {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: false,
                text: "Top5"
            }
        }
    );
  }
}
</script>