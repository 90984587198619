<template>
  <div class="v-spinner" >
    <div class="v-scale scale1" style=""></div>
    <div class="v-scale v-scale2" style="background-color: rgb(255, 255, 255); height: 35px; width: 4px; margin: 2px; border-radius: 2px; display: inline-block; animation-name: v-scaleStretchDelay; animation-duration: 1s; animation-iteration-count: infinite; animation-timing-function: cubic-bezier(0.2, 0.68, 0.18, 1.08); animation-fill-mode: both; animation-delay: 0.2s;"></div>
    <div class="v-scale v-scale3" style="background-color: rgb(255, 255, 255); height: 35px; width: 4px; margin: 2px; border-radius: 2px; display: inline-block; animation-name: v-scaleStretchDelay; animation-duration: 1s; animation-iteration-count: infinite; animation-timing-function: cubic-bezier(0.2, 0.68, 0.18, 1.08); animation-fill-mode: both; animation-delay: 0.3s;"></div>
    <div class="v-scale v-scale4" style="background-color: rgb(255, 255, 255); height: 35px; width: 4px; margin: 2px; border-radius: 2px; display: inline-block; animation-name: v-scaleStretchDelay; animation-duration: 1s; animation-iteration-count: infinite; animation-timing-function: cubic-bezier(0.2, 0.68, 0.18, 1.08); animation-fill-mode: both; animation-delay: 0.4s;"></div>
    <div class="v-scale v-scale5" style="background-color: rgb(255, 255, 255); height: 35px; width: 4px; margin: 2px; border-radius: 2px; display: inline-block; animation-name: v-scaleStretchDelay; animation-duration: 1s; animation-iteration-count: infinite; animation-timing-function: cubic-bezier(0.2, 0.68, 0.18, 1.08); animation-fill-mode: both; animation-delay: 0.5s;"></div>

  </div>
</template>
<script>
export default {
  name: 'base-pagination',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: value => {
        return [
          'default',
          'primary',
          'danger',
          'success',
          'warning',
          'info',
        ].includes(value)
      },
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 1,
    },
    pagesToDisplay: {
      type: Number,
      default: 10,
    },
  },
  computed: {
 
  },
  methods: {
   
  },
  watch: {
   
  },
}
</script>
<style>
.v-spinner{text-align:center
}@-webkit-keyframes v-scaleStretchDelay{
  0%{-webkit-animation-name:inherit;-webkit-animation-duration:inherit;-webkit-animation-iteration-count:inherit;-webkit-animation-direction:inherit}
  to{-webkit-transform:scaleY(1);transform:scaleY(1)}
  50%{-webkit-transform:scaleY(.4);transform:scaleY(.4)}}
@keyframes v-scaleStretchDelay{
  0%{-webkit-animation-name:inherit;-webkit-animation-duration:inherit;-webkit-animation-iteration-count:inherit;-webkit-animation-direction:inherit}
  to{-webkit-transform:scaleY(1);transform:scaleY(1)}
  50%{-webkit-transform:scaleY(.4);transform:scaleY(.4)}
}
.scale1 {
  background-color: rgb(255, 255, 255); height: 35px; width: 4px; margin: 2px; border-radius: 2px; display: inline-block; animation-name: v-scaleStretchDelay; animation-duration: 1s; animation-iteration-count: infinite; animation-timing-function: cubic-bezier(0.2, 0.68, 0.18, 1.08); animation-fill-mode: both; animation-delay: 0.1s;
}
</style>