<template src="./template.html"></template>

<script>
import i18n from '../../library/i18n' // 다국어지원
import {en, ko} from "vuejs-datepicker/dist/locale"
import moment from 'moment'

export default {
    name: 'Accounting',
    data(){
        return {
            summaryLoading : {
                1:false,
                2:false,
                3:false,
                4:false
            },
            albumLoading :{
                1:false,
                2:false,
                3:false,
            },

            artistLoading :{
                1:false,
                2:false,
                3:false,
            },
            storeLoading :{
                1:false,
                2:false,
                3:false,
            },
            countryLoading :{
                1:false,
                2:false,
                3:false,
            },
            
            description: '정산/출금 페이지 입니다.',
            viewChange: "summary", // View 변환 값: summary , album_track, artist, musicStore, territory
            viewSubChange : "",

            albumTrackView :"albumTrack", // albumTrack , artist
            chartTypeAlbum : 'total', // total , trend
            chartTypeTrack : 'total',
            chartTypeArtist : 'total',

            chartTypeCountry : 'total',
            chartTypeStore : 'total',
            storeCountryView : 'musicstore', // store, country

            search: {
                term: "settlement",                           // settlement_month : 정산월(기본) , service_month: 서비스월
                start_date: null,                                   // 부터 ~
                end_date: null,                                     // ~ 까지
                quick_date: "month"                                 // 이번달, 3개월, 6개월, 1년
            },
            stats_form:{
                date_type: "settle",                      // 정산월:settle, 서비스월:service
                start_month: moment().add(-5, 'M').toDate(), 
                end_month: new Date(),
                monthRange: 6,
                page: 0,
                limit: 30,
            },
            paging:{
                first: 0,
                prev: 0,
                pages:[0],
                next: 0, 
                last:0
            },
            en: en,
            ko: ko,
            currency: 'krw',
            stats_monthly: {
                sum:{
                    st_amount:0
                }
            },
            stats_album: {
                data: [],
                limit : 10,
                total : 1,
                totalPage : 1,
                currentPage : 1
            },
            stats_artist: {
                data: [],
                limit : 10,
                total : 1,
                totalPage : 1,
                currentPage : 1
            },
            stats_store: {
                data: [],
                limit : 10,
                total : 1,
                totalPage : 1,
                currentPage : 1
            },
            stats_country: {
                data: [],
                limit : 10,
                total : 1,
                totalPage : 1,
                currentPage : 1
            },
            trend_filter: 'amounts',
            top10_tab: 'track',
            top10_data:{
                track: [],
                album: [],
                artist: []
            },
            chart: {
                trend_bar_x:{
                    datasets: []
                },
                trend_bar: {
                    data : {},
                    option : { 
                        responsive: true, maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                stacked: true,
                                //categoryPercentage: 0.5,
                                //barPercentage: 1
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        },
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                boxWidth: 80,
                                fontColor: 'black'
                            }
                        }
                    }
                },
                summary_store:{
                    data : {},
                    options: {
                        legend: {
                            display: true,
                            position: "right",
                            labels: {
                                boxWidth: 30,
                                fontColor: 'black',
                                padding: 40
                            }
                        }
                    }
                },
                summary_country:{
                    data : {},
                    options: {
                        legend: {
                            display: true,
                            position: "right",
                            labels: {
                                boxWidth: 30,
                                fontColor: 'black',
                                padding: 40
                            }
                        }
                    }
                },
                album_popular:{
                    bar_x:{
                        type: 'y',
                        datasets: [],
                        options: {}
                    },
                    pie:{
                        data : {},
                        options: {
                            cutoutPercentage: 75,
                            legend: {
                                display: false,
                            }
                        }
                    },
                    line:{
                        data : {},
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            title: {
                                display: false
                            }
                        }
                    }
                },
                track_popular:{
                    bar_x:{
                        data : {},                    },
                    pie:{
                        data : {datasets :[{data:[]}]},
                        options: {
                            cutoutPercentage: 75,
                            legend: {
                                display: false,
                            }
                        }
                    },
                    line:{
                        data : {},
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            title: {
                                display: false
                            }
                        }
                    }
                },
                artist_popular:{
                    bar_x:{
                        data : {},                    },
                    pie:{
                        data : {datasets :[]},
                        options: {
                            cutoutPercentage: 75,
                            legend: {
                                display: false,
                            }
                        }
                    },
                    line:{
                        data : {datasets :[]},
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            title: {
                                display: false
                            }
                        }
                    }
                },
                
                store_popular:{
                    bar_x:{
                        data : {},                    },
                    pie:{
                        data : {datasets :[]},
                        options: {
                            cutoutPercentage: 75,
                            legend: {
                                display: false,
                            }
                        }
                    },
                    line:{
                        data : {datasets :[]},
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            title: {
                                display: false
                            }
                        }
                    }
                },
                
                country_popular:{
                    bar_x:{
                        data : {},                    },
                    pie:{
                        data : {datasets :[]},
                        options: {
                            legend: {
                                display: false,
                            }
                        }
                    },
                    line:{
                        data : {datasets :[]},
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            title: {
                                display: false
                            }
                        }
                    }
                },

            },
            getMessage: null,            // popup message
            countryChart : {}, // map chart

            saleslineList:{
                data: [],
                limit : 10,
                total : 1,
                totalPage : 1,
                currentPage : 1
            },


            album_popularChart: null,
            track_popularChart : null,
            store_popularChart : null,
            country_popularChart : null
        }
        
    },
    beforeRouteLeave (to, from, next) {
        this.$EventBus.$emit("navi", "page_clear",'/')
        next()
    },
    created() {
        setTimeout(function(){
          document.getElementById('calculate').classList.add('action')
        }, 100)
    },
    mounted() {
        this.$nextTick(function () {
          /*   setTimeout(function(){
                document.getElementById('calculate').classList.add('action')
            }, 200) */
            
            this.$EventBus.$emit("navi", "page_position",'statistics')
            
            const session_userInfo = JSON.parse(localStorage.SR_UserInfo);
            if(this.licensorCheck(session_userInfo)){
                
                    this.popupOpen('statistcs_guide',"none_licensor")
                }else this.currency = session_userInfo.licensor.currency 
            
            if (this.viewChange == 'summary'){
                this.submitForm('summary');
            }
            
        })
    },
    watch:{
         
        'saleslineList.currentPage' (){
            this.loadSaleslineList()
        },

        'stats_album.currentPage' (){
            this.loadAlbumList()
        },
        'stats_artist.currentPage'(){
            this.loadArtistList()
        },
        'stats_store.currentPage'(){
            this.loadStoreList()
        },
        'stats_country.currentPage'(){
            this.loadCountryList()
        },
        
        
        "chartTypeCountry"(){
            this.setPopularChart('album_popular', this.country_popularChart)
        },
        "chartTypeStore"(){
            this.setPopularChart('album_popular', this.store_popularChart)
        },
        "chartTypeAlbum"(){
            this.setPopularChart('album_popular', this.album_popularChart)
        },
        "chartTypeTrack"(){
            this.setPopularChart('track_popular', this.track_popularChart)
        }
    },
    computed: {
        getLang(){
            if (this.$store.state.languageCode == 'ko'){
                return this.ko;
            }else{
                return this.en;
            }
        },
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const none_licensor = this.$i18n.t("common.msg.none_licensor")
        
                const messageCollection = {
                    none_licensor: none_licensor
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        },
        
    },
    methods: {
        tab_view_active(viewChange){
            
            if(viewChange == 'summary' || viewChange == 'album'  || viewChange == 'store'){
                if(viewChange!= this.viewChange){
                    this.viewChange = viewChange
                    this.submitForm(viewChange)   
                }
            }else if(viewChange == 'albumTrack' || viewChange == 'artist'){
                if(viewChange != this.albumTrackView){
                    this.albumTrackView = viewChange
                    this.submitForm(viewChange)   
                }
            }else if(viewChange == 'musicstore' || viewChange == 'country'){
                if(viewChange != this.storeCountryView){
                    this.storeCountryView = viewChange
                    this.submitForm(viewChange)   
                } 
            }
            
        },
       
        submitForm(view){
            let start = moment(this.stats_form.start_month).add(11, 'M').format('YYYYMM')
            let end = moment(this.stats_form.end_month).format('YYYYMM')
           
            if(start < end){
                alert(i18n.t("statistics.msg.max_month"))
            }else{
                this.summaryLoading = {
                    1:true,
                    2:true,
                    3:true,
                    4:true
                }
                console.log(view)
                if(view){
                    switch(view){
                        case 'summary':
                            this.loadStatsMonthly();
                            break;
                        case 'album':
                            this.loadAlbumPopular();
                            break;
                        case 'album_track':
                            this.loadAlbumPopular();
                            break;
                        case 'artist':
                            this.loadArtistPopular();
                            break;
                        case 'store':
                            this.loadStorePopular();
                            break;
                        case 'musicstore':
                            this.loadStorePopular();
                            break;
                        case 'country':
                            this.loadCountryPopular();
                            break;
                        default:
                            break;
                    }
                }else{
                    console.log(this.viewChange)
                    if(this.viewChange == 'summary'){
                        this.loadStatsMonthly();
                    }else if(this.viewChange == 'album'){
                        console.log(this.albumTrackView)
                        if(this.albumTrackView == 'albumTrack'){
                            this.loadAlbumPopular();
                        }else{
                            this.loadArtistPopular();
                        }
                    }else if(this.viewChange == 'store'){
                        if(this.storeCountryView == 'store'){
                            this.loadStorePopular();
                        }else{
                            this.loadCountryPopular();
                        }
                    }
                }
                
            }
        },
        setPaging(total){
            //paging 처리
            const limit = this.stats_form.limit
            this.paging.last = parseInt(total / limit)
            let start = parseInt(this.stats_form.page / 10) * 10
            let end = start + 9
            if(end > this.paging.last){
                end = this.paging.last
            }
            let pages = []
            for(var i=start; i<=end; i++){
                pages.push(i);
            }
            this.paging.pages = pages
            this.paging.prev = start - 1
            if(this.paging.prev < 0){
                this.paging.prev = 0
            }
            this.paging.next = end + 1
            if(this.paging.next > this.paging.last){
                this.paging.next = this.paging.last
            }
        },
        goToPage(page){
            if(page == 0 || page != this.stats_form.page){
                this.stats_form.page = page
                switch(this.viewChange){
                    case 'album_track':
                        this.loadAlbumList();
                        break;
                    case 'artist':
                        this.loadArtistList();
                        break;
                    case 'musicStore':
                        this.loadStoreList();
                        break;
                    case 'territory':
                        this.loadCountryList();
                        break;
                    default:
                        break;
                }
            }
        },
        getStatsMonthlyExcel(){
            const search = this.stats_form
            let sort, sortorder
            this.$store.dispatch("GET_STATS_MONTHLY_EXCEL", {sort, sortorder, search})
                .then(result =>{
                    this.getExcelFile(result)
                })
                .catch(error =>{
                    console.log("salesline list Error ::", error)
                })
        },
        //summary
        loadStatsMonthly(){
            const search = this.stats_form
            this.$store.dispatch("LOAD_STATS_MONTHLY", {search})
                .then(result =>{
                    this.stats_monthly = result
                    this.stats_monthly.rItems = []
                    this.stats_monthly.items.forEach((value) => {
                        this.stats_monthly.rItems.unshift(value)
                    })
                    this.chart.trend_bar_x.datasets = [
                        {   
                            //bar: { categoryPercentage: 0.5, barPercentage: 1 },
                            is_all: false,
                            label: i18n.t("statistics.label.summary.sales_trend.graph_item.streaming"),  // 이름
                            lable2: 'streaming',
                            backgroundColor: "#36a2eb",                                                  // Bar Color
                            count : this.getCount(this.stats_monthly.sum.st_count),
                            price : this.getAmount(this.stats_monthly.sum.st_amount),                    
                            amount_rate: this.getRate(this.stats_monthly.sum.st_amount, this.stats_monthly.sum.total_amount),
                            count_rate: this.getRate(this.stats_monthly.sum.st_count, 
                            this.stats_monthly.sum.st_count + this.stats_monthly.sum.dl_count + this.stats_monthly.sum.other_count),
                        },
                        {
                            //bar: { categoryPercentage: 0.5, barPercentage: 1 },
                            is_all: false,
                            label: i18n.t("statistics.label.summary.sales_trend.graph_item.download"),  // 이름
                            lable2: 'download',
                            backgroundColor: "#ff6484",                                                 // Bar Color
                            count : this.getCount(this.stats_monthly.sum.dl_count),
                            price : this.getAmount(this.stats_monthly.sum.dl_amount),                    
                            amount_rate: this.getRate(this.stats_monthly.sum.dl_amount, this.stats_monthly.sum.total_amount),
                            count_rate: this.getRate(this.stats_monthly.sum.dl_count, 
                            this.stats_monthly.sum.st_count + this.stats_monthly.sum.dl_count + this.stats_monthly.sum.other_count),
                        },
                        {
                            is_all: false,
                            label: i18n.t("statistics.label.summary.sales_trend.graph_item.other"),     // 이름
                            lable2: 'other',
                            backgroundColor: "#4cc0c0",                                                 // Bar Color
                            count : this.getCount(this.stats_monthly.sum.other_count),
                            price : this.getAmount(this.stats_monthly.sum.other_amount),                    
                            amount_rate: this.getRate(this.stats_monthly.sum.other_amount, this.stats_monthly.sum.total_amount),
                            count_rate: this.getRate(this.stats_monthly.sum.other_count, 
                            this.stats_monthly.sum.st_count + this.stats_monthly.sum.dl_count + this.stats_monthly.sum.other_count),
                        },
                        {
                            is_all: true,
                            label: i18n.t("statistics.label.summary.sales_trend.graph_item.all"),       // 이름
                            backgroundColor: "#2548ca",                                                 // Bar Color
                            count : (this.stats_monthly.sum.st_count + this.stats_monthly.sum.dl_count + this.stats_monthly.sum.other_count),
                            price : this.getAmount(this.stats_monthly.sum.total_amount),                    
                            amount_rate: 100,
                            count_rate: 100,
                        },
                    ]
                    this.summaryLoading[1] = false;
                    this.summaryLoading[4] = false;
                    this.trendBarData()
                    this.loadStoreSummary()
                    this.loadSaleslineList();
                    //this.updateChart()
                    //this.loadSaleslineList()
                })

                .catch(error =>{
                    console.log("stats monthly Error ::", error)
                })
        },
        trendBarData(){
            let labels = []
            let values = {
                st: [],
                dl: [],
                other: []
            }
            if(this.stats_monthly.items){
                this.stats_monthly.items.forEach((data)=> {
                    labels.push(this.getMonthFormat(data.title))
                    if(this.trend_filter == 'amounts'){
                        values.st.push(this.getAmountVal(data.st_amount))
                        values.dl.push(this.getAmountVal(data.dl_amount))
                        values.other.push(this.getAmountVal(data.other_amount))
                    }else{
                        values.st.push(data.st_count)
                        values.dl.push(data.dl_count)
                        values.other.push(data.other_count)
                    }
                })
            }

            this.chart.trend_bar.data = {
                labels: labels,
                datasets: [
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.streaming"),  // 이름
                        backgroundColor: "#36a2eb",                                                  // Bar Color
                        data: values.st,                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.download"),    // 이름
                        backgroundColor: "#ff6484",                                                   // Bar Color
                        data: values.dl,                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.other"),       // 이름
                        backgroundColor: "#4cc0c0",                                                   // Bar Color
                        data: values.other,                      // 데이터 수치
                    },
                ]
            }
            this.summaryLoading[2] = false;
            //console.log(this.chart.trend_bar.data)
        },
        loadStoreSummary(){
            const search = this.stats_form
            let sort, sortorder, page, limit
            sort = 'total_amount'
            sortorder = 'DESC'
            page = 0
            limit = 5
            this.$store.dispatch("LOAD_STATS_STORE", {sort, sortorder, page, limit, search})
                .then(result =>{
                    let labels = []
                    let values = []
                    if(result.items){
                        result.items.forEach((data)=> {
                            
                            
                            labels.push(data.title)
                            values.push(this.getAmountVal(data.total_amount))
                        })
                    }
                    this.chart.summary_store.data = {
                        type: "y",
                        labels: labels,
                        datasets:[
                            {
                                hoverBorderWidth: 2,
                                borderWidth: 2,
                                weight: 10,
                                backgroundColor: ["#36a2eb", "#ff6484", "#9966ff", "#ff9f40", "#ffce58"],    // Graph Color 
                                data: values,                                                 // Data 
                            }
                        ]
                    }
                    
                    //this.loadCountrySummary()
                })
                .catch(error =>{
                    console.log("stats monthly Error ::", error)
                })
        },
        loadSaleslineList(){
            let start_month = moment(this.stats_form.start_month).startOf('month').format('YYYY-MM-DD')
            let end_month = moment(this.stats_form.end_month).endOf('month').format('YYYY-MM-DD')
            
            if(this.saleslineList.currentPage>1){
                document.getElementById('sales').scrollIntoView(true);
            }
            let payload = {
                sort : "created_at",
                sortorder: 'desc',
                page: this.saleslineList.currentPage-1 ,
                limit: this.saleslineList.limit,
                date_type: this.stats_form.date_type,
                start_month:start_month,
                end_month : end_month
            }
            this.summaryLoading[3] = true;
            this.$store.dispatch("LOAD_SALELINES_LIST", payload)
            .then(result =>{
                this.summaryLoading[3] = false;
                this.loadingHide();
                this.saleslineList.data = result.items
                const remainder = parseInt(result.filtered % this.saleslineList.limit) > 0? 1: 0
                this.saleslineList.totalPage = parseInt(result.filtered/this.saleslineList.limit) + remainder
                this.saleslineList.total = result.total
            })
            .catch(error =>{
                this.summaryLoading[3] = false;
                this.loadingHide();
                console.log("saleslineList list Error ::", error)
            })
        },
        getSaleslineExcel(){
            let start_month = moment(this.stats_form.start_month).startOf('month').format('YYYY-MM-DD')
            let end_month = moment(this.stats_form.end_month).endOf('month').format('YYYY-MM-DD')
            let payload = {
                sort : "created_at",
                sortorder: 'desc',
                date_type: this.stats_form.date_type,
                start_month:start_month,
                end_month : end_month,
                user_language: this.$store.state.languageCode
            }
            this.$EventBus.$emit('fullLoading', true)
            this.$store.dispatch("GET_SALELINES_EXCEL",payload)
                .then(result =>{
                     this.$EventBus.$emit('fullLoading', false)
                     console.log(result)
                    this.getExcelFile(result)
                })
                .catch(error =>{
                    console.log("salesline list Error ::", error)
                })
        },
       
        
        //summary end

        //album&track
        loadAlbumPopular(){
            
            const search = this.stats_form
            const type = 'album'
            this.albumLoading = {
                1:true,
                2:true,
                3:true
            }
            this.loadTrackPopular();
            this.loadAlbumList();
            this.$store.dispatch("LOAD_STATS_POPULAR", {search, type})
                .then(result =>{
                    this.album_popularChart = result
                    this.setPopularChart('album_popular', this.album_popularChart)
                    
                    this.albumLoading[1]=false
                })
                .catch(error =>{
                    this.albumLoading[1]=false
                    console.log("stats monthly Error ::", error)
                })
        },
        loadTrackPopular(){
            const search = this.stats_form
            const type = 'track'
            this.$store.dispatch("LOAD_STATS_POPULAR", {search, type})
            .then(result =>{
                this.track_popularChart = result
                this.setPopularChart('track_popular', result)
                this.albumLoading[2]=false
            })
            .catch(error =>{
                this.albumLoading[2]=false
                console.log("stats monthly Error ::", error)
            })
        },
        setPopularChart(name, result){
            const chart = this.chart

            let pop_datasets = []
            let bg_color = ["#36a2eb", "#ff6484", "#9966ff", "#ff9f40", "#ffce58", "#4bbfbf", "#000000"]
            let line_color = ["rgba(54, 162, 235, 1)", "rgba(255, 100, 132, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)", "rgba(255,206,88, 1)"]
            
            let pie_values = []
            let pie_labels = []
            let line_labels = []
            let line_datas = []
            //라인 라벨 구성
            let label_date = moment(this.stats_form.start_month)
            while(label_date.format('YYYYMM') <= moment(this.stats_form.end_month).format('YYYYMM')){
                line_labels.push(label_date.format('YYYY-MM'))
                label_date = label_date.add(1, 'M')
            }
            if(result.items && result.items.length > 0){
                for(var i=0; i<result.items.length; i++){
                    let item = result.items[i]
                    let data = {
                        is_all: false,
                        label: item.title ? item.title.toUpperCase() : '-',
                        backgroundColor: bg_color[i],
                        price: this.getAmount(item.total_amount),
                        percentage: this.getRate(item.total_amount, result.summary.total)
                    }
                    let index = pie_labels.indexOf(item.title ? item.title.toUpperCase() : '-');
                    if(index > -1 ){
                        pie_values[index] = pie_values[index] + this.getAmountVal(item.total_amount)
                        console.log(pop_datasets[index].price , item.total_amount)
                        pop_datasets[index].price = this.getAmount(this.getAmountVal(pop_datasets[index].price)  + this.getAmountVal(item.total_amount))
                    }else{
                        pie_labels.push(item.title ? item.title.toUpperCase() : '-')
                        pie_values.push(this.getAmountVal(item.total_amount))
                        pop_datasets.push(data)
                        
                        // line data
                        let line_val = []
                        line_labels.forEach((label) =>{
                            line_val.push(0)
                        })
                        if(item.sub_items && item.sub_items.length > 0){
                            item.sub_items.forEach((sub_item) => {
                                const index = line_labels.indexOf(this.getMonthFormat(sub_item.stats_month))
                                line_val[index] = this.getAmountVal(sub_item.total_amount)
                            })
                        }
                        let line_label = item.title ? item.title.toUpperCase() : '-'
                        if(name == 'country_popular'){
                            line_label = this.getCountry(line_label)
                        }
                        line_datas.push({
                            label: line_label, // item name
                            data: line_val,  // item data  Y축
                            backgroundColor: "transparent",
                            borderColor: line_color[i],
                            pointBackgroundColor: "rgba(171, 71, 188, 1)"
                        })
                    }
                }
                //console.log('result.summary',result.summary)
                if(result.summary.others > 0){
                    pop_datasets.push({
                        is_all: false,
                        label: 'Others',
                        backgroundColor: bg_color[result.items.length],
                        price: this.getAmount(result.summary.others),
                        percentage: this.getRate(result.summary.others, result.summary.total)
                    })
                    
                    pie_labels.push('Others')
                    pie_values.push(this.getAmountVal(result.summary.others))
                }
                pop_datasets.push({
                    is_all: false,
                    label: 'Total',
                    backgroundColor: bg_color[6],
                    price: this.getAmount(result.summary.total),
                    percentage: 100
                })
            }else{
                pie_values = []
                pie_labels = ['no data']
            }
            this.chart[name]['bar_x']['datasets'] = pop_datasets
            this.chart[name]['bar_x']['options'] = { legend: false }

            this.chart[name]['pie']['data'] = {
                type: "y",
                datasets:[
                    {
                        hoverBorderWidth: 2,
                        borderWidth: 2,
                        weight: 10,
                        backgroundColor: bg_color,    // Graph Color 
                        data: pie_values,                                                 // Data 
                    }
                ],
                labels : pie_labels
            }
            this.chart[name]['line']['data'] = {
                labels: line_labels,
                datasets: line_datas
            }
            
        },
        loadAlbumList(){
            const search = this.stats_form
            let start_month = moment(this.stats_form.start_month).startOf('month').format('YYYYMM')
            let end_month = moment(this.stats_form.end_month).endOf('month').format('YYYYMM')
            let payload = {
                sort : "total_amount",
                sortorder: 'desc',
                page: this.stats_album.currentPage-1 ,
                limit: this.stats_album.limit,
                date_type: this.stats_form.date_type,
                start_month:start_month,
                end_month : end_month,
                get_sub : false,
                get_track : true
            }
            this.albumLoading[3]=true
            this.$store.dispatch("LOAD_STATS_ALBUM", payload)
            .then(result =>{
                
                
                result.items.forEach((item) => {
                    item.toggle = false
                })
                
                this.albumLoading[3]=false
                
                this.stats_album.data = result.items
                const remainder = parseInt(result.filtered % this.stats_album.limit) > 0? 1: 0
                this.stats_album.totalPage = parseInt(result.filtered/this.stats_album.limit) + remainder
                this.stats_album.total = result.total
            })
            .catch(error =>{
                this.summaryLoading[3] = false;
                this.loadingHide();
                console.log("saleslineList list Error ::", error)
            })
           
           
           
        },
        
        //album&track end 

        //artist
        loadArtistPopular(){
            this.artistLoading = {
                1:true,
                2:true,
                3:true,
            }
            const search = this.stats_form
            const type = 'artist'
            this.$store.dispatch("LOAD_STATS_POPULAR", {search, type})
                .then(result =>{
                    this.setPopularChart('artist_popular', result)
                    this.loadArtistList()
                    this.artistLoading[1] = this.artistLoading[2] = false
                })
                .catch(error =>{
                    this.artistLoading[1] = this.artistLoading[2] = false
                    console.log("stats monthly Error ::", error)
                })
        },
        loadArtistList(){
            
            const search = this.stats_form
            let start_month = moment(this.stats_form.start_month).startOf('month').format('YYYYMM')
            let end_month = moment(this.stats_form.end_month).endOf('month').format('YYYYMM')
            let payload = {
                sort : "total_amount",
                sortorder: 'desc',
                page: this.stats_artist.currentPage-1 ,
                limit: this.stats_artist.limit,
                date_type: this.stats_form.date_type,
                start_month:start_month,
                end_month : end_month,
                get_sub : false,
                get_track : true
            }
            this.artistLoading[3] = true
            this.$store.dispatch("LOAD_STATS_ARTIST", payload)
                .then(result =>{
                    
                    this.stats_artist.data = result.items
                    const remainder = parseInt(result.filtered % this.stats_artist.limit) > 0? 1: 0
                    this.stats_artist.totalPage = parseInt(result.filtered/this.stats_artist.limit) + remainder
                    this.stats_artist.total = result.total

                    this.artistLoading[3] = false
                })
                .catch(error =>{
                    console.log("stats monthly Error ::", error)
                })
        },
        
        //artist edn
        //store 
        loadStorePopular(){
            const search = this.stats_form
            const type = 'store'
            this.storeLoading={ 1:true,2:true}
            this.$store.dispatch("LOAD_STATS_POPULAR", {search, type})
                .then(result =>{
                    this.store_popularChart = result
                    this.setPopularChart('store_popular', result)
                    this.loadStoreList()
                    this.storeLoading[1]= false
                })
                .catch(error =>{
                    console.log("stats monthly Error ::", error)
                })
        },
        loadStoreList(){
            let start_month = moment(this.stats_form.start_month).startOf('month').format('YYYYMM')
            let end_month = moment(this.stats_form.end_month).endOf('month').format('YYYYMM')
            let payload = {
                sort : "total_amount",
                sortorder: 'desc',
                page: this.stats_store.currentPage-1 ,
                limit: this.stats_store.limit,
                date_type: this.stats_form.date_type,
                start_month:start_month,
                end_month : end_month,
                get_sub : false,
                get_track : true
            }
            
            this.storeLoading[2] = true
            this.$store.dispatch("LOAD_STATS_STORE",payload).then(result =>{
                console.log(this.stats_store)
                this.stats_store.data = result.items
                const remainder = parseInt(result.filtered % this.stats_store.limit) > 0? 1: 0
                this.stats_store.totalPage = parseInt(result.filtered/this.stats_store.limit) + remainder
                this.stats_store.total = result.total
                this.storeLoading[2] = false
            })
            .catch(error =>{
                console.log("stats monthly Error ::", error)
            })
        },
        
        //store end 
        //country
        loadCountryPopular(){
            const search = this.stats_form
            const type = 'country'
            this.countryLoading={ 1:true,2:true}
            this.$store.dispatch("LOAD_STATS_POPULAR", {search, type})
                .then(result =>{
                    this.country_popularChart = result
                    this.setPopularChart('country_popular', result)
                    this.loadCountryList()
                    this.countryLoading[1] = false;
                })
                .catch(error =>{
                    console.log("stats monthly Error ::", error)
                })
        },
        loadCountryList(){
            let start_month = moment(this.stats_form.start_month).startOf('month').format('YYYYMM')
            let end_month = moment(this.stats_form.end_month).endOf('month').format('YYYYMM')
            let payload = {
                sort : "total_amount",
                sortorder: 'desc',
                page: this.stats_country.currentPage-1 ,
                limit: this.stats_country.limit,
                date_type: this.stats_form.date_type,
                start_month:start_month,
                end_month : end_month,
            }
            this.countryLoading[2] = true
            this.$store.dispatch("LOAD_STATS_COUNTRY", payload)
                .then(result =>{
                    this.stats_country.data = result.items
                    this.countryChart = {
                        "KR" : 0,
                        "US" : 0
                    }
                    result.items.forEach((data)=> {
                            
                        if(data.total_amount != 0 && data.title != 'Global'){
                            if(this.countryChart[data.title.toUpperCase()]){
                                this.countryChart[data.title.toUpperCase()] = this.getAmountVal(this.countryChart[data.title.toUpperCase()] + data.total_amount )
                            }else{
                                this.countryChart[data.title.toUpperCase()] = this.getAmountVal(data.total_amount)
                            }
                        }
                    })
                    
                    this.countryLoading[2] = false
                })
                .catch(error =>{
                    console.log("stats monthly Error ::", error)
                })
        },
        //country end

        getExcelFile(response){
            let filename = "",
                disposition = response.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/, matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                    filename);
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        },
        getStatsAlbumExcel(){
            const search = this.stats_form
            let sort, sortorder
            this.$store.dispatch("GET_STATS_ALBUM_EXCEL", {sort, sortorder, search})
            .then(result =>{
                this.getExcelFile(result)
            })
            .catch(error =>{
                console.log("salesline list Error ::", error)
            })
        },
        toggleItem(item){
            if(item.toggle){
                item.toggle = false
            }else{
                item.toggle = true
            }
        },
        getStatsArtistExcel(){
            const search = this.stats_form
            let sort, sortorder
            this.$store.dispatch("GET_STATS_ARTIST_EXCEL", {sort, sortorder, search})
                .then(result =>{
                    this.getExcelFile(result)
                })
                .catch(error =>{
                    console.log("salesline list Error ::", error)
                })
        },
        
        getStatsStoreExcel(){
            const search = this.stats_form
            let sort, sortorder
            this.$store.dispatch("GET_STATS_STORE_EXCEL", {sort, sortorder, search})
                .then(result =>{
                    this.getExcelFile(result)
                })
                .catch(error =>{
                    console.log("salesline list Error ::", error)
                })
        },
        
        getStatsCountryExcel(){
            const search = this.stats_form
            let sort, sortorder
            this.$store.dispatch("GET_STATS_COUNTRY_EXCEL", {sort, sortorder, search})
                .then(result =>{
                    this.getExcelFile(result)
                })
                .catch(error =>{
                    console.log("salesline list Error ::", error)
                })
        },
        getMonthFormat(val){
            return val.substring(0, 4) + '-' + val.substring(4, 6)
        },
        
        setSearchMonth(){
            this.stats_form.end_month = new Date()
            this.stats_form.start_month = moment(this.stats_form.end_month).add(1 - this.stats_form.monthRange, 'M').toDate()
        },
        
        getCount(val){
            let value = 0
            if(val){
                value = val
            }
            return value.toLocaleString()
        },
        getAmountVal(val){
            let value = 0
            if(val){
                value = String(val).replace(/,/g, '')
            }
            if(this.currency == 'krw'){
                value = Math.floor(value)
            }else{
                value = Math.floor(value * 100) / 100
            }
            return value
        },
        getAmount(val){
            let value = 0
            if(val){
                value = val
            }
            if(this.currency == 'krw'){
                value = Math.floor(value)
                value = value.toLocaleString('en-US', {minimumFractionDigits: 0})
            }else{
                value = Math.floor(value * 100) / 100
                value = value.toLocaleString('en-US', {minimumFractionDigits: 2})
            }
            return value
        },
        getRate(val, total){
            let value = 0
            if(val && total){
                value = val
            }
            if(!total){
                return 0
            }
            return Math.floor(value * 1000 / total) / 10
        },
        getCurrencyMark(){
            if(this.currency == 'usd'){
                return "$"
            }else if(this.currency == 'eur'){
                return "€"
            }
            return "₩"
        },
        getCountry(code){
            let country_name = ""
            
            this.$store.state.country.map(country =>{
                
                if(country.code.toLowerCase() == code.toLowerCase()){
                    if(this.$store.state.languageCode == 'ko'){
                        country_name = country.name
                    }else{
                        country_name = country.name_en
                    }
                    
                }
            })
            if(!country_name){
                return code
            }
            return country_name
        },
        showPanel18(){
            // 출금 신청하기
            // const panel = this.$showPanel({
            //     component: "panel-18",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-18",
                props: {
                  
                }
            },()=>{
                
            });
        },
        popupOpen(id, title){
            // popup Open
            console.log("popupOpen :::", id)
            let popup = document.getElementById(id)
            console.log("popupOpen01 :::", popup)
            popup.classList.add('type2')
            this.guideMessage = title
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupClose(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
             popup.classList.remove('type2')
          }, 300)
        
          this.$EventBus.$emit("navi", "page_position",'my')
          this.$router.push('/my')
        },



        getServiceType(type) {
            if (type == 'ST'){
                return i18n.t("accounting.props.sales_filter.sales_type.streaming")
            }else if (type == 'DL'){
                return i18n.t("accounting.props.sales_filter.sales_type.download")
            }else{
                return i18n.t("accounting.props.sales_filter.sales_type.other")
            }
        },
    }
}
</script>

<style>

 .static .sv_z .bg{
  background-image:url('../../assets/images/sv_cal.png') ;
  background-repeat:no-repeat;
  background-position:50% 0;
  transform: scale(1.6);
  background-size:cover;
  transition:1.2s;
  display:block;
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
}

.sv_z .sv_copy{
  transition-delay: .1s; 
  transition-duration: .8s;
  transform: translateX(-80px);
  opacity:0;
  filter:alpha(opacity=100);
}

.sv_z .sv_copy2{
  transition-delay: .7s;
  transition-duration: .8s;
  transform: translateX(-100px);
  opacity:0;
  filter:alpha(opacity=100);
}
.action .sv_z .bg{transform: scale(1) !important;}
.action .sv_z .sv_copy{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
.action .sv_z .sv_copy2{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
#bar_x{
    width: 100%;
    min-height: 75px;
  /*   background-color: #000; */
    font-size: 16px;
}

</style>