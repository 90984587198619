
import Vue from 'vue'
import VModal from 'vue-js-modal'
// import 'vue-js-modal/dist/styles.css'
import draggable from 'vuedraggable'
import Datepickers from 'vuejs-datepicker';
import FileUpload from 'vue-upload-component'
import MapChart from './mapChart/App.vue' 
import DatePicker from 'vue2-datepicker';
import MultiLang from "./multi-lang.vue"    
import PaginationCustom from "./pagination.vue"    

import langBox from "./langBox.vue"    
import vSelect from 'vue-select'
// import { ScaleLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import  Spinner  from './spinner.vue'

//import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
// Import Swiper styles
//import 'swiper/swiper-bundle.css'
import SwiperClass, { /* swiper modules... */ } from 'swiper'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Swiper, { Navigation, Scrollbar,FreeMode } from 'swiper';
// import swiper module styles
import 'swiper/css/swiper.css'
//import 'swiper/css/pagination';
// more module style...


export default {
    
    install (Vue) {
        
        Vue.use(VModal, { componentName: 'v-modal', dynamicDefault: { draggable: true, resizable: true }  })
        Vue.use(Datepickers)
        Vue.use(VueAwesomeSwiper)


        Vue.component(DatePicker.name, DatePicker);
        // Vue.component('scale-loader',ScaleLoader);
        Vue.component('scale-loader',Spinner);
        Vue.component('MapChart',MapChart)
        Vue.component('file-upload',FileUpload)
        Vue.component(draggable.name, draggable)
        Vue.component('v-select',vSelect)
        
        //Vue.component('swiper-slide',SwiperSlide)

        // self component
        Vue.component('PaginationCustom',PaginationCustom)
        Vue.component('multiLang', MultiLang)
        Vue.component('langBox', langBox)

        
    },
}
  