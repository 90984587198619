import Vue from 'vue'
import Router from 'vue-router'

//npm 라이브러리 component




import Main from '@/views/main/main.vue'                                    // 메인

// Main Menu
import CustomerService from '@/views/customerService/main'
import Cost from '@/views/cost/main.vue'                                    // 비용                       
import AboutUs from '@/views/aboutUs/main'
import Accounting from '@/views/accounting/main.vue'                        // 정산
import Statistics from '@/views/statistics/main.vue'                        // 통계


// Sub Menu
import Login from '@/views/auth/Login/login'                                      // 로그인
import SignUp from '@/views/auth/signup/signup.vue'                               // 회원가입
import My from '@/views/my/my.vue'                                                // 마이페이지 write
import ForgetPassword from '@/views/auth/forgetPassword/forgetPassword'           // 비밀번호찾기
import ResetPassword from '@/views/auth/resetPassword/resetPassword'              // 비밀번호 재설정
import Uncertified from '@/views/auth/email/uncertified/uncertified'              // 미인증 이메일
import AuthProcessing from '@/views/auth/email/emailAuth/processing'              // 인증 이메일 처리 페이지
import EmailAuth from '@/views/auth/email/emailAuth/emailAuth'                    // 이메일 인증 결과 페이지


import Artist from '@/views/management/artist/index'

import AlbumList from '@/views/management/albumList/index'                        // 앨범 관리 리스트
import AlbumDetail from '@/views/management/albumList/detail'                        // 앨범 관리 리스트
import AlbumApplication from '@/views/management/albumList/application'                        // 앨범 신청
import AlbumApplicationComplete from '@/views/management/albumList/application_complete'                        // 앨범 신청


import Distribution from '@/views/management/distribution/index'                  // 앨범 유통하기
import Distribution_Modify from '@/views/management/distribution/index_modify'    // 앨범 유통하기 수정 페이지


//리뉴얼
import DistributionRenew from '@/views/management/distribution/index_renew'                  // 앨범 유통하기

import Withdraw from '@/views/withdraw/index'                               // 출금하기


// Components
// // layout
// import NavigationBar from '@/components/layout/navigationBar.vue'  // Top Navigation Bar
 import FooterArea from '@/components/layout/footer/index.vue'          // Footer

// Vue.component('navigationBar' , NavigationBar)
// Vue.component("footerArea",FooterArea)


// slide Panel
import PanelBox from '@/components/panelBox/index'
import PanelSubBox from '@/components/panelBox/panelSubBox/index'

import Panel1 from '@/components/panel/panel1/index'      // 비밀번호 변경
import Panel2 from '@/components/panel/panel2/index'      // 아티스트 추가 신규등록
import Panel3 from '@/components/panel/panel3/index'      // 아티스트 정보
import Panel4 from '@/components/panel/panel4/index.vue'  // 앨범정보 완료
import Panel5 from '@/components/panel/panel5/index'      // 고객센터 완료
import Panel6 from '@/components/panel/panel6/index'      // 1:1 문의하기 ????
import Panel7 from '@/components/panel/panel7/index'      // FAQ
import Panel8 from '@/components/panel/panel8/index'      // 대표아티스트 선택
import Panel9 from '@/components/panel/panel9/index'      // 음원 등록 Track Register
import Panel10 from '@/components/panel/panel10/index'    // 유통정보 입력
import Panel11 from '@/components/panel/panel11/index'    // 세금정보 입력
import Panel12 from '@/components/panel/panel12/index'    // Track 용 대표아티스트 관리
import Panel13 from '@/components/panel/panel13/index'    // Track 용 장르 관리
import Panel14 from '@/components/panel/panel14/index'    // 뮤직스토어 리스트
import Panel15 from '@/components/panel/panel15/index'    // Default 대표아티스트 관리
import Panel16 from '@/components/panel/panel16/index'    // Default 대표장르 관리
import Panel17 from '@/components/panel/panel17/index'    // 앨범 트랙별 상세 View
import Panel18 from '@/components/panel/panel18/index'    // 출금금액 신청하기
import Panel19 from '@/components/panel/panel19/index'    // 출금신청 완료 세금계산서 안내 없음: 국내 개인, 해외 개인, 해외 사업자 / 세금계산서 안내 있음: 국내 사업자
import Panel20 from '@/components/panel/panel20/index'    // 세금 계산서 보기
import Panel21 from '@/components/panel/panel21/index'    // 고객센터 FAQ 상세보기
import Panel22 from '@/components/panel/panel22/index'    // 면세사업자 보기

Vue.component('panelBox', PanelBox)
Vue.component('panelSubBox', PanelSubBox)
Vue.component('panel1', Panel1)
Vue.component('panel2', Panel2)
Vue.component('panel3', Panel3)
Vue.component('panel4', Panel4)
Vue.component('panel5', Panel5)
Vue.component('panel6', Panel6)
Vue.component('panel7', Panel7)
Vue.component('panel8', Panel8)
Vue.component('panel9', Panel9)
Vue.component('panel10', Panel10)
Vue.component('panel11', Panel11)
Vue.component('panel12', Panel12)
Vue.component('panel13', Panel13)
Vue.component('panel14', Panel14)
Vue.component('panel15', Panel15)
Vue.component('panel16', Panel16)
Vue.component('panel17', Panel17)
Vue.component('panel18', Panel18)
Vue.component('panel19', Panel19)
Vue.component('panel20', Panel20)
Vue.component('panel21', Panel21)
Vue.component('panel22', Panel22)

//Library
// UX 기능
import ImageUpload from "@/library/dragUpload/dragImageUplaod/index"                          // Drag 이미지 업로드
import ImageUpload_label from "@/library/dragUpload/dragImageUplaod/index_label"             // Drag 이미지 업로드 label용
import ImageUpload_cover from "@/library/dragUpload/dragImageUplaod/index_cover"             // Drag 이미지 업로드 label용
import ImageUpload_artist from "@/library/dragUpload/dragImageUplaod/index_artist"             // Drag 이미지 업로드 label용
import dragSortTable from "@/library/dragSortTable/dragSortTable/index"                       // Drag And Drop SortTable
import dragSortTable2 from "@/library/dragSortTable/dragSortTable2/index"                     // Drag And Drop SortTable2
import gridScrollList from "@/library/scrollListGrid/index.vue"                               // Default Grid Scroll List
import gridScrollList_genre from "@/library/scrollListGrid/genre_index.vue"                  // Default genre Grid Scroll List
import gridScrollList_management from "@/library/scrollListGrid/managementList.vue"           // Management List  Grid Scroll List
import gridScrollList_track from "@/library/track_scrollListGrid/index.vue"                   // Track 용Grid Scroll List
import gridScrollList_track_genre from "@/library/track_scrollListGrid/index_genre.vue"       // Track 용 Genre Grid Scroll List
import tracksUpload from "@/library/dragUpload/dragTracksUpload/index"                        // Drag 음원 업로드
import {Datetime} from 'vue-datetime'                                                         // Vue 달력
import Datepicker from 'vuejs-datepicker'                                                     // 년/월 선택용 
  // Chart
  import BarChart from "@/library/chart/barChart.vue"                                         // Bar Chart
  import Bar_xyChart from "@/library/chart/bar_xyChart.vue"                                   // Bar Standard Only X,Y Chart
  import PieChart from "@/library/chart/pieChart.vue"                                         // Pie Chart
  import LineChart from "@/library/chart/lineChart.vue"                                       // Pie Chart
  import BarChartReact from "@/library/chart/barChartReact.vue"                                         // Bar Chart reactive
  import PieChartReact from "@/library/chart/pieChartReact.vue"                                         // Pie Chart reactive
  import LineChartReact from "@/library/chart/lineChartReact.vue"                                         // Line Chart reactive


Vue.component('imageUpload', ImageUpload)
Vue.component('imageUpload_label', ImageUpload_label)
Vue.component('imageUpload_artist', ImageUpload_artist)
Vue.component('imageUpload_cover', ImageUpload_cover)
Vue.component('dragSortTable', dragSortTable)
Vue.component('dragSortTable2', dragSortTable2)
Vue.component("gridScrollList", gridScrollList)
Vue.component("gridScrollList_genre", gridScrollList_genre)
Vue.component("gridScrollList_management", gridScrollList_management)
Vue.component("gridScrollList_track", gridScrollList_track)
Vue.component("gridScrollList_track_genre", gridScrollList_track_genre)
Vue.component('tracksUpload', tracksUpload)
Vue.component('Datetime', Datetime)
Vue.component('Datepicker', Datepicker)
Vue.component('barChart', BarChart)
Vue.component('bar_xyChart', Bar_xyChart)
Vue.component('pieChart', PieChart)
Vue.component('lineChart', LineChart)
Vue.component('barChartReact', BarChartReact)
Vue.component('pieChartReact', PieChartReact)
Vue.component('lineChartReact', LineChartReact)

// Side


import store from '../store'
// sync(store, Router)

//Library -----------------------------------------------------------------------------------------------------------------
import Cookie from "vue-cookie"               // Cookie
import VTooltip from 'v-tooltip'              // v-tooltip

Vue.use(VTooltip, {
  defaultTrigger: 'hover focus click' 
})
Vue.use(Cookie)

import layoutcss from "@/library/css/layout.vue"
Vue.component('layoutcss', layoutcss)

Vue.use(Router)

// 카카오 주소 검색 기능
import VueDaumPostcode from "vue-daum-postcode"
Vue.use(VueDaumPostcode)

//Error 처리 페이지
import ErrorPage from "@/views/error/404.vue"

//인증 후 이동 가능 페이지
const requireAuth = () => (to, from, next) => {
  const isAuthenticate = localStorage.isAuthenticate
  const SR_Token = localStorage.SR_Token
  if(SR_Token){
    if(from.name) {
      if(isAuthenticate){
        return next()
      }
    }else{
      next();
    }
    
    // this.$EventBus.$emit("navi", "page_position",'my')
    // next('login?returnPath=/my')
  }else{
    next('/login')
  }
  // if(isAuthenticate) return next()
}
import mainLayout from '@/components/layout/container.vue'                                    // 메인

export default new Router({
  mode: "history",
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      description: '로그인 페이지'
    },
    {
      path: '/',
      name: '/',
      component: mainLayout,
      children: [
        {
          path: '/',
          name: '/',
          component: Main
        },
        {
          path: '/home',
          name: 'Main',
          component: Main
        },
        {
          path: '/aboutUs',
          name: 'AboutUs',
          component: AboutUs,
          description: '회사소개 페이지'
        },
        {
          path: '/cost',
          name: 'Cost',
          component: Cost,
          description: '비용 페이지'
        },
        {
          path: '/customerservice',
          name: 'CustomerService',
          component: CustomerService,
          description: '고객센터 페이지'
        },
        {
          path: '/login',
          name: 'Login',
          component: Login,
          description: '로그인 페이지'
        },
        // {
        //   path: '/signup',
        //   name: 'SignUp',
        //   component: SignUp,
        //   description: '회원가입 페이지'
        // },
      
        {
          path: '/withdraw',
          name: 'Withdraw',
          component: Withdraw,
          beforeEnter: requireAuth(),
          description: '출금페이지'
        },
        {
          path: '/my',
          name: 'My',
          component: My,
          beforeEnter: requireAuth(),
          description: '마이페이지'
        },
        {
          path: '/forgetPassword',
          name: "ForgetPassword",
          component: ForgetPassword,
          description: "비밀번호찾기"
        },
        {
          path: '/resetPassword',
          name: "resetPassword",
          component: ResetPassword,
          description: "비밀번호재설정"
        },
        {
          path: '/verify',
          name: "Verify",
          component: AuthProcessing,
          description: "이메일 인증 처리 페이지"
        },
        {
          path: '/uncertified',
          name: "Uncertified",
          component: Uncertified,
          description: "미인증 페이지"
        },
        {
          path: '/emailAuth',
          name: "EmailAuth",
          component: EmailAuth,
          description: "이메일 인증결과 페이지"
        },
        {
          path: '/mims',
          name: "mims",
          beforeEnter(to, from, next) {
            window.location = "http://www.mims.or.kr/"
          }
        },
        {
          path: '/paypal',
          name: "Paypal",
          beforeEnter(to, from, next) {
            window.location = "http://paypal.com"
          }
        },
        {
          path: '/payoneer',
          name: "Payoneer",
          beforeEnter(to, from, next) {
            window.location = "http://payoneer.com"
          }
        },
        {
          path: '/payoneerfaq',
          name: "Payoneer_Faq",
          beforeEnter(to, from, next) {
            window.location = "https://community.payoneer.com/en/discussion/58058/why-isnt-my-account-approved-an-faq"
          }
        },
        
        {
          path: '/artist',
          name: "artist",
          component: Artist,
          beforeEnter: requireAuth(),
          description: "앨범리스트"
        },
        {
          path: '/album',
          name: "Album",
          component: AlbumList,
          beforeEnter: requireAuth(),
          description: "앨범리스트"
        },
        {
          path: '/album/application',
          name: "application",
          component: AlbumApplication,
          beforeEnter: requireAuth(),
          description: "음원발매신청"
        },
        {
          path: '/album/application/complete',
          name: "Application_complete",
          component: AlbumApplicationComplete,
          beforeEnter: requireAuth(),
          description: "음원발매신청완료"
        },
        {
          path: '/album/detail/:id',
          name: "AlbumDetail",
          component: AlbumDetail,
          beforeEnter: requireAuth(),
          description: "앨범상세"
        },
        
        {
          path: '/distribution',
          name: "Distribution",
          component: Distribution,
          beforeEnter: requireAuth(),
          description: "앨범유통하기"
        },
        {
          path: '/distribution_modify',
          name: "Distribution_Modify",
          component: Distribution_Modify,
          beforeEnter: requireAuth(),
          description: "앨범유통하기"
        },
        {
          path: '/distributionrenew/:id',
          name: "Distribution",
          component: DistributionRenew,
          beforeEnter: requireAuth(),
          description: "앨범유통하기"
        },
        {
          path: '/accounting',
          name: "Accounting",
          component: Accounting,
          beforeEnter: requireAuth(),
          description: "정산"
        },
        {
          path: '/statistics',
          name: "Statistics",
          component: Statistics,
          beforeEnter: requireAuth(),
          description: "통계"
        },
        {
          path: '*',
          name: "404NotFound",
          component: ErrorPage,
          beforeEnter(to, from, next) {
          
          }
        }
      ]
    },
     
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  } 
})
