<template>
  <ul class="pagination" :class="paginationClass">
    <li
      class="page-item first-page"
      v-if="showArrows"
      :class="{ disabled: value === 1 }"
    >
      <a href="javascript:void(0)" class="page-link" @click="$emit('input', 1)">
        First
      </a>
    </li>
    <li
      class="page-item prev-page"
      v-if="showArrows"
      :class="{ disabled: value === 1 }"
    >
      <a href="javascript:void(0)" class="page-link" aria-label="Previous" @click="prevPage">
        <i class="tim-icons icon-double-left" aria-hidden="true"></i>
      </a>
    </li>
    <li
      class="page-item"
      v-for="item in range(minPage, maxPage)"
      :key="item"
      :class="{ active: value === item }"
    >
      <a href="javascript:void(0)" class="page-link" @click="changePage(item)">{{ item }}</a>
    </li>

    <li
      v-if="showArrows"
      class="page-item page-pre next-page"
      :class="{ disabled: value === totalPages }"
    >
      <a href="javascript:void(0)" class="page-link" aria-label="Next" @click.prevent="nextPage">
        <i class="tim-icons icon-double-right" aria-hidden="true"></i>
      </a>
    </li>
    <li
      v-if="showArrows"
      class="page-item page-pre last-page"
      :class="{ disabled: value === totalPages }"
    >
      <a href="javascript:void(0)" class="page-link" aria-label="Next" @click.prevent="$emit('input', totalPages)">
        Last
      </a>
    </li>
    
  </ul>
</template>
<script>
export default {
  name: 'base-pagination',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: value => {
        return [
          'default',
          'primary',
          'danger',
          'success',
          'warning',
          'info',
        ].includes(value)
      },
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 1,
    },
    pagesToDisplay: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    paginationClass () {
      return `pagination-${this.type}`
    },
    totalPages () {
      if (this.pageCount > 0) return this.pageCount
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage)
      }
      return 1
    },
    defaultPagesToDisplay () {
      if (this.totalPages > 0 && this.totalPages < this.pagesToDisplay) {
        return this.totalPages
      }
      return this.pagesToDisplay
    },
    minPage () {
      let startPage, endPage;
      if ( this.value % 5 === 0 ) {
        startPage = this.value - 4;
        endPage = this.value;
      } else {
        startPage = Math.floor(this.value * 0.1) * 10 + 1
        endPage = Math.floor(this.value * 0.1) * 10 + 5
      }

      startPage =  this.value - (this.value % this.pagesToDisplay)  + 1
      if(this.value % this.pagesToDisplay == 0){
        startPage = this.value - this.pagesToDisplay + 1
      }
      endPage = Number(startPage) + Number(this.pagesToDisplay)  - 1
      
      return startPage

      // if (this.value >= this.defaultPagesToDisplay) {
      //   const pagesToAdd = Math.floor(this.defaultPagesToDisplay / 2)
      //   const newMaxPage = pagesToAdd //+ this.value
      //   if (newMaxPage > this.totalPages) {
      //     return this.totalPages - this.defaultPagesToDisplay + 1
      //   }
      //   return this.value - pagesToAdd
      // } else {
      //   return 1
      // }
    },
    maxPage () {
      let startPage, endPage;
                      
      startPage =  this.value - (this.value % this.pagesToDisplay)  + 1
      if(this.value % this.pagesToDisplay == 0){
        startPage = this.value - this.pagesToDisplay + 1
      }
      endPage = Number(startPage) + Number(this.pagesToDisplay)  - 1
      if(endPage > this.totalPages){
        endPage = this.totalPages
      }
      return endPage
      // if (this.value >= this.defaultPagesToDisplay) {
      //   console.log(this.defaultPagesToDisplay , this.value)

      //   const pagesToAdd = Math.floor(this.defaultPagesToDisplay / 2)
      //   const newMaxPage = pagesToAdd + this.value
        
      //   if (newMaxPage < this.totalPages) {
      //     return newMaxPage
      //   } else {
      //     return this.totalPages
      //   }
      // } else {
      //   return this.defaultPagesToDisplay
      // }
    },
  },
  methods: {
    range (min, max) {
      const arr = []
      for (let i = min; i <= max; i++) {
        arr.push(i)
      }
      return arr
    },
    changePage (item) {
      this.$emit('input', item)
    },
    nextPage () {
      let nextPage = this.maxPage + 1;
      let prevPage = this.minPage - 1;
      
      if (nextPage > this.totalPages) {
        nextPage = this.maxPage
      }
      if (prevPage < 1) {
        prevPage = this.minPage
      }
      if (this.value < this.totalPages) {
        
        this.$emit('input', Number(nextPage) )
      }
    },
    prevPage () {
      let nextPage = this.maxPage + 1;
      let prevPage = this.minPage - 1;
      if (nextPage > this.totalPages) {
        nextPage = 1
      }
      if (prevPage < 1) {
        prevPage = 1
      }
      if (this.value > 1) {
        this.$emit('input',  Number(prevPage))
      }
    },
  },
  watch: {
    perPage () {
      this.$emit('input', 1)
    },
    total () {
      this.$emit('input', 1)
    },
  },
}
</script>
