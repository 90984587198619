<template src="./template.html"></template>

<script>

import i18n from "../../library/i18n"    // 다국어지원
import {en, ko} from "vuejs-datepicker/dist/locale"
import moment from 'moment'
import axios from 'axios'
import dayjs from 'dayjs'


export default {
    name: 'accounting',
    data(){
        return {
            scrollTop:window.top.scrollY,
            scrollOff: false,
            fullLoading:false,
            loading:true,
            salesLoading : {
                1:false,
                2:false,
                3:false
            },
            paymentLoading : {
                1:false,
                2:false,
                3:false
            },
            viewChange: "withdrawal",                                            // View 변환 값: sales, payment
            musicStoreList: null,                                           // 뮤직 스토어 리스트
            saleline_form:{
                date_type: "settlement_month",                              // 정산월:settle, 서비스월:service
                start_month:new Date(moment().add(0, 'M').startOf('month')), 
                end_month: new Date(moment().endOf('month')),
                musicstore: '',
                country: '',
                service_type: 'all',
                search_type: 'artist',
                keyword: '',
                monthRange: 1,
                page: 0,
                limit: 30,
            },
            startDate: {
                to: null,
                from: new Date(moment().endOf('month'))
            },
            endDate: {
                to: new Date(moment().add(-5, 'M').startOf('month')),
                from: null
            },
            paging:{
                first: 0,
                prev: 0,
                pages:[0],
                next: 0, 
                last:0
            },
            paymentPaging:{
                first: 0,
                prev: 0,
                pages:[0],
                next: 0, 
                last:0
            },
            en: en,
            ko: ko,
            currency: 'krw',
            salesline_list: {
                total: 0,
                filtered: 0,
                items:[]
            },
            salesline_sum: {
                total:{
                    st:{ hitcount:0, user_amount:0, },
                    dl:{ hitcount:0, user_amount:0 },
                    other:{ hitcount:0, user_amount:0 },
                    user: 0
                },
                monthly:[]
            },
            chart: {
                bar : {
                    data : {},
                    option : { 
                        responsive: true, maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                stacked: true,
                                
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        },
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                boxWidth: 80,
                                fontColor: 'black'
                            }
                        }
                    }
                },
                pie: {
                    data: {},
                    options: {
                        legend: {
                            display: true,
                            position: "top",
                            labels: {
                                boxWidth: 30,
                                fontColor: 'black',
                                padding: 10
                            }
                        }
                    }
                }
            },
            transaction:{
                data: [],
                limit : 10,
                total : 1,
                totalPage : 1,
                currentPage : 1
            },
            balance:{
                total_settle: 0, 
                total_withdrawal: 0,
                remain: 0
            },
            withdrawal:{
                data: [],
                limit : 10,
                total : 1,
                totalPage : 1,
                currentPage : 1
            },
            user_info: null,
            getMessage: null,                // popup 메세지
            licensorData : {
                "given_name":null,
                "family_name":null,
                bankaccount : {
                    "id":'',
                    "address_id":null,
                    "payment_method":"kr_banktransfer",
                    "bank_name":null,
                    "bank_branch":null,
                    "account_number":null,
                    "created_at":"2020-09-09T09:44:18.654Z",
                    "updated_at":"2020-09-09T09:44:18.654Z",
                    "name":null,
                    "bank_code":null,
                    "payoneer_payee_id":null,
                    "payoneer_customer_id":null,
                    "payoneer_activation_date":null,
                    "paypal_email":null,
                    "address":null
                }
            },
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$EventBus.$emit("navi", "page_clear",'/')
        next()
    },
    created(){
       
    },
    watch:{
        'saleline_form.start_month' (){
            this.startDate = {
                to: null,
                from: new Date(this.saleline_form.end_month)
            }
            this.endDate= {
                to: new Date(this.saleline_form.start_month),
                from: null
            }
        },
        'saleline_form.end_month' (){
            this.startDate = {
                to: null,
                from: new Date(this.saleline_form.end_month)
            }
            this.endDate= {
                to: new Date(this.saleline_form.start_month),
                from: null
            }
        },
        'withdrawal.currentPage' (){
            this.getWithdrawals()
        },
        'transaction.currentPage' (){
            this.getTransactions()
        }
        
    },
    destroyed(){
        //window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        
        // if(session_userInfo.licensor){
        //     this.updateChart()
        // }
        this.$nextTick(function () {
     /*        setTimeout(function(){
                document.getElementById('calculate').classList.add('action')
            }, 200) */
            const session_userInfo = JSON.parse(localStorage.SR_UserInfo)
            // 유통정보, 세금정보, 지급정보 미입력시
            /* const session_userInfo = JSON.parse(localStorage.SR_UserInfo) */
            if(this.licensorCheck(session_userInfo)){
                
                this.popupOpen('albumList_guide',"none_licensor")
            }else{
                this.$EventBus.$emit("navi", "page_position",'accounting')

                this.$EventBus.$on('withdrawal_complete', (params) => {
                    this.getUserBalance();
                })

                if (this.viewChange == "transaction"){
                        this.loadSaleslineSum();
                    }else if(this.viewChange == "withdrawal"){
                        this.getUserBalance();
                    }

                if(session_userInfo.licensor) {
                    this.currency = session_userInfo.licensor.currency 
                }
            }
            

           
        })
    },
    computed: {
        paymentShow(){
          // 지급 방법
          const obj = this.$i18n.t('distribution.label.payment.means')
          const keys = Object.keys(obj)
          for(let i=0; i < keys.length; i++) {
            if(keys[i] == this.licensorData.bankaccount.payment_method) {
              return obj[keys[i]]
            }
          }
        },
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const none_licensor = this.$i18n.t("management.msg.none_licensor")
                console.log(none_licensor)
                const messageCollection = {
                    none_licensor: none_licensor
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        },
        
    },
    methods: {
        timeCheck(){
            //dayjs.tz.setDefault('America/New_York');
            const eventStartTime = dayjs().date(20).hour(0).minute(0).second(0);
            
            const eventEndTime = dayjs().endOf('month');
            const currentTime = dayjs().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');
            const timeLeft = eventStartTime.diff(currentTime, 'seconds');
            const timeLeft2= eventEndTime.diff(currentTime, 'seconds');
            console.log(currentTime) 
            console.log(eventStartTime.format('YYYY-MM-DD HH:mm:ss'), timeLeft) 
            let text = false
            if(timeLeft < 0){
                text = true
            }else{
                console.log('대기중')
                text = false
            }
            return text
        },

        transactionDown(id){
            this.$EventBus.$emit('fullLoading', true)
            let params = {};
            params["user_language"] = this.$store.state.languageCode
            axios.post(process.env.VUE_APP_API_URL + "/transaction/"+id+"/excel",params,{ responseType: 'blob' })
            .then((data) => {
                
                this.getExcelFile(data)
                this.$EventBus.$emit('fullLoading', false)
            }).catch(error => {
                console.log("File Down Fail :::", error)
            })
        },
            
        resetRange(){
            this.saleline_form.monthRange = 0
            
            
        },

        
        handleScroll (){
            if( window.scrollY <650){
                this.scrollTop =  window.scrollY
                this.scrollOff= true;
            }
            if(this.scrollOff && window.scrollY > 850){
                this.updateChart()
                this.scrollOff=false;
            }
        },
        tab_view_active(viewChange){
            if(viewChange!= this.viewChange){
                this.viewChange = viewChange
                if (this.viewChange == "withdrawal"){
                    this.getUserBalance();
                }else if(this.viewChange == "transaction"){
                    
                    this.getTransactions();
                }
            }
        },
        showPanel18(){
            // 출금 신청하기
            let check = this.timeCheck()
            if(!check){
                if(this.$i18n.locale == 'ko'){
                    this.alert({msg : '출금신청은 매월 20일부터 말일까지만 가능합니다.'})   
                }else{
                    this.alert({msg : 'You can submit a withdrawal request anytime between the 20th and the last day of every month. (KST)'})
                }
                
                return false
            }
            const payload = {
                sort : "created_at",
                sortorder: 'desc',
                page: 0 ,
                limit: 1,
            }
            this.loadingShow();
            this.getUserBalance();
                
            this.$store.dispatch("LOAD_WITHDRAWAL_LIST",payload)
                .then(result =>{
                this.loadingHide();
                let withdrawal_only = result.items
                if( result.items.length > 0){
                    let state = withdrawal_only[0].state
                    if(state !== 'fulfilled' && state !== "rejected"){
                        this.$EventBus.$emit('openPanel',{
                            component: "panel-19",
                            props: {
                                payment_method: this.user_info.licensor['bankaccount']['payment_method'],
                                entity: this.user_info.licensor['entity']
                            }
                        });
                    }else {
                        this.$EventBus.$emit('openPanel',{
                            props: {userInfo: this.user_info},
                            component: "panel-18",
                        });
                    }
                }else{
                    this.$EventBus.$emit('openPanel',{
                        props: {userInfo: this.user_info},
                        component: "panel-18",
                    });
                }
                
            })
            .catch(error =>{
                console.log("withdrawal list Error ::", error)
            })
            
            
            // const panel = this.$showPanel({
            //     component: "panel-18",
            //     width: "740px",
            //     props: {userInfo: this.user_info},
            //     openOn: 'right',
            //     cssClass: "panel-zindex"
            // })
        },
        monthSelect(id){
            let monthPicker = document.getElementById(id)
            monthPicker.click()
        },
      
        getExcelFile(response){
            let filename = "",
                disposition = response.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/, matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                    filename);
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        },
       
        getSumAmount(val){
            let value = 0
            if(val){
                value = val
            }
            //console.log('before:'+value)
            if(this.currency == 'krw'){               
                //value = Math.floor(value * 100) / 100
                value = value? Math.floor(value).toLocaleString() : 0
            }else{
                
                value = Math.floor(value * 100) / 100
                value = value? value.toLocaleString('en-US', {minimumFractionDigits: 2}) : 0
                
            }
            //console.log('after:'+value)
            return value
        },
        getAmount(val){
            let value = 0
            if(val){
                value = val
            }
            if(this.currency == 'krw'){
                //value = Math.floor(value * 100) / 100
                value = value? value.toLocaleString('en-US', {minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0
            }else{
                //value = Math.floor(value * 10000) / 10000
                value = value? value.toLocaleString('en-US', {minimumFractionDigits: 4,maximumFractionDigits: 4}) : 0
            }
            return value
        },
        getCurrencyMark(){
            if(this.currency == 'usd'){
                return "$"
            }else if(this.currency == 'eur'){
                return "€"
            }
            return "₩"
        },
    
       
        getCountry(code){
            let country_name = ""
            this.$store.state.country.map(country =>{
                if(country.code.toLowerCase() == code.toLowerCase()){
                    if(this.$store.state.languageCode == 'ko'){
                        country_name = country.name
                    }else{
                        country_name = country.name_en
                    }
                    
                }
            })
            return country_name
        },
        updateChart(){
            let labels = []
            let values = {
                st: [],
                dl: [],
                other: []
            }
            
            if(this.salesline_sum.monthly){
                this.salesline_sum.monthly.forEach((data)=> {
                    labels.push(data.month)
                    values.st.push(data.st)
                    values.dl.push(data.dl)
                    values.other.push(data.other)
                })
            }
             this.chart.bar.data = {
                labels: labels,
                //labels: ['1', '2', '3'],
                datasets: [
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.streaming"),  // 이름
                        backgroundColor: "#36a2eb",                                                  // Bar Color
                        data: [],                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.download"),    // 이름
                        backgroundColor: "#ff6484",                                                   // Bar Color
                        data: [],                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.other"),       // 이름
                        backgroundColor: "#4cc0c0",                                                   // Bar Color
                        data: [],                      // 데이터 수치
                    },
                ]
            }

            let streamingName = i18n.t("statistics.label.summary.sales_trend.graph_item.streaming")
            let downloadName = i18n.t("statistics.label.summary.sales_trend.graph_item.download")
            let otherName = i18n.t("statistics.label.summary.sales_trend.graph_item.other")
            this.chart.pie.data = {
                type: "y",
                labels: [],
                datasets:[
                    {
                        hoverBorderWidth: 2,
                        borderWidth: 2,
                        weight: 10,
                        backgroundColor: ["#36a2eb", "#ff6484", "#4cc0c0", "#2548ca"],    
                        data: [],                                          
                    }
                ]
            }
            
            setTimeout(() => {
                this.chart.bar.data = {
                    labels: labels,
                //labels: ['1', '2', '3'],
                datasets: [
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.streaming"),  // 이름
                        backgroundColor: "#36a2eb",                                                  // Bar Color
                        data: values.st,                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.download"),    // 이름
                        backgroundColor: "#ff6484",                                                   // Bar Color
                        data: values.dl,                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.other"),       // 이름
                        backgroundColor: "#4cc0c0",                                                   // Bar Color
                        data: values.other,                      // 데이터 수치
                    },
                ]
            }
                this.chart.pie.data = {
                type: "y",
                labels: [streamingName, downloadName, otherName],
                datasets:[
                    {
                        hoverBorderWidth: 2,
                        borderWidth: 2,
                        weight: 10,
                        backgroundColor: ["#36a2eb", "#ff6484", "#4cc0c0", "#2548ca"],    // Graph Color 
                        data: [this.salesline_sum.total.st.user_amount, this.salesline_sum.total.dl.user_amount, this.salesline_sum.total.other.user_amount],                                                 // Data 
                    }
                ]
            }
            }, 200);
           
          
        },
        



        //payment section
        getUserBalance(){
            this.paymentLoading = {
                1:true,
                2:true,
                3:true
            }

            this.$store.dispatch("GET_USER_INFO")
            .then(result =>{
                
                this.user_info = result
                if(result.role != "user" ){
                    this.licensorData = result.licensor
                }
                
                this.balance.total_settle = result.licensor.total_earnings
                this.balance.total_withdrawal = result.licensor.total_withdrawed
                this.balance.remain = result.licensor.balance
                if(!this.balance.total_earnings){
                    this.balance.total_earnings = 0
                }
                if(!this.balance.total_withdrawed){
                    this.balance.total_withdrawed = 0
                }
                if(!this.balance.remain){
                    this.balance.remain = 0
                }
                this.paymentLoading[1]=false
                this.getWithdrawals()
                
            })
            .catch(error =>{
                console.log("get user balance Error ::", error)
            })
        },
        getWithdrawals(){
            const option = {
                sort : "created_at",
                sortorder: 'desc',
                page: this.withdrawal.currentPage-1 ,
                limit: this.withdrawal.limit,
            }
            this.$store.dispatch("LOAD_WITHDRAWAL_LIST",option)
                .then(result =>{
                this.loadingHide();
                this.withdrawal.data = result.items
                const remainder = parseInt(result.filtered % this.withdrawal.limit) > 0? 1: 0
                this.withdrawal.totalPage = parseInt(result.filtered/this.withdrawal.limit) + remainder
                this.withdrawal.total = result.total
                 
                    //this.getTransactions()
            })
            .catch(error =>{
                console.log("withdrawal list Error ::", error)
            })
        },



        getTransactions(){
            
            let payload = {
                sort : "created_at",
                sortorder: 'desc',
                page: this.transaction.currentPage-1 ,
                limit: this.transaction.limit,
            }
            
            this.$store.dispatch("LOAD_TRANSACTION_LIST", payload)
            .then(result =>{
                

                this.loadingHide();
                this.transaction.data = result.items
                const remainder = parseInt(result.filtered % this.transaction.limit) > 0? 1: 0
                this.transaction.totalPage = parseInt(result.filtered/this.transaction.limit) + remainder
                this.transaction.total = result.total
                this.paymentLoading[3] = false
            })
            .catch(error =>{
                this.paymentLoading[3] = false
                console.log("transaction list Error ::", error)
            })
        },
        getTransactionExcel(){
            let sort
            const sortorder = this.transaction.sortorder
            const page = this.transaction.page
            const limit = this.transaction.limit
            const type = this.transaction.filter
            this.$EventBus.$emit('fullLoading', true)
            this.$store.dispatch("GET_TRANSACTION_EXCEL", {sort, sortorder, page, limit, type})
                .then(result =>{
                    this.getExcelFile(result)
                     this.$EventBus.$emit('fullLoading', false)
                })
                .catch(error =>{
                    this.$EventBus.$emit('fullLoading', false)
                    console.log("transaction excel Error ::", error)
                })
        },
        popupOpen(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            this.guideMessage = title
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 100)
        },
        popupClose(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
             popup.classList.remove('type2')
          }, 0)
        
          this.$EventBus.$emit("navi", "page_position",'my')
          this.$router.push('/my')
        },
        popupClose2(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
             popup.classList.remove('type2')
          }, 300)
        }
    }
}
</script>


 