
<template src="./index_renew.html"></template>

<script>
import {en, ko} from "vuejs-datepicker/dist/locale"
import moment from 'moment'
import i18n from '../../../library/i18n'

const path = require("path");
export default {
    props: [],
    data(){
        return {
            leave : false,
            ko : ko,
            en : en,
            calenderLang : null,
            tabStep : 1,
            add_albumInfo: true,                                           // 앨범 추가정보 Show
            click: {
                artist_click: true,
                genre_click: true
            },
            releaseOption : [
                { value: 'single', label: 'Single' },
                { value: 'ep', label: 'EP' },
                { value: 'album', label: 'Album' },
            ],

            //앨범정보
            state : null,
            albumInfo : {
                source: "easyupload",
                name: null,
                name_language: null,
                barcode:null,
                name_english: null,
                name_multilang : {},
                description: null,
                description_language: null,
                description_english: null,
                release_type : null,
                label: null,
                label_english: null,
                label_language: null,
                labelimage: null,
                labelimage_id: null,
                credit : null,
                coverimage_id : null,
                distributor: "Sound Republica, Inc.",
                pline_text: "under license to Sound Republica, Inc.",
                pline_year : null,
                cline_text:  null,
                cline_year: null,
                original_release_date : null,
                has_mv : false, 
            },
            // 앨범 아티스트
            artistList : [],
            //앨범 장르
            genreList :[],
            // 트랙
            tracks  : [
                []
            ],

            //앨범 커버이미지 원본  이미지 변경시 업로드를 다시 하기 위해 저장
            oldAlbumImage : null,
            newAlbumImage : null,
            //기획사 커버 이미지 원본 이미지 변경시 업로드를 다시 하기 위해 저장
            oldLabelImage : null,
            newLabelImage : null,
            
            //요청사항
            licensor_message : null,
            //유통 계약
            distribution : {
                id : null,
                desired_release_date: null,
                contract : {
                    territory : null,
                    territory_exception: null
                },
            },
            desired_release_date_datechk:false,
            
            errorText : "",

            imageUploadOption: {
                type: "cover",
                eventName: 'album_cover',
                minWidth: 1400,
                minHeight: 1400,
                maxWidth:3000,
                maxHeight:3000,
                square: true,
                imageFileInfoData: null
            },
            imageUploadOption_label: {
                type: "cover",
                eventName: 'label_cover',
                minWidth: 1000,
                minHeight: 1000,
                imageFileInfoData: null
            },
            error :[],

            add_albumInfo : true,
            click: {
                artist_click: true,
                genre_click: true
            },
            today : new Date(),
            disabledDates: { //발매희망일
                to: moment(new Date()).add(13, 'day').format('YYYY-MM-DD'), // Disable all dates up to specific date
                from: null, // Disable all dates after specific date
                days: [6, 0], // Disable Saturday's and Sunday's
                //daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
                // dates: [ // Disable an array of dates
                // new Date(2016, 9, 16),
                // new Date(2016, 9, 17),
                // new Date(2016, 9, 18)
                // ],
                // ranges: [{ // Disable dates in given ranges (exclusive).
                // from: new Date(2016, 11, 25),
                // to: new Date(2016, 11, 30)
                // }, {
                // from: new Date(2017, 1, 12),
                // to: new Date(2017, 2, 25)
                // }],
                // a custom function that returns true if the date is disabled
                // this can be used for wiring you own logic to disable a date if none
                // of the above conditions serve your purpose
                // this function should accept a date and return true if is disabled
                // customPredictor: function(date) {
                // // disables the date if it is a multiple of 5
                // if(date.getDate() % 5 == 0){
                //     return true
                // }
                // }
            },
            disabledDates2: { // 기발매일
                to: null, // Disable all dates up to specific date
                from: new Date(), // Disable all dates after specific date
                days: [], // Disable Saturday's and Sunday's
                
            },
            track_volume: 1,
            track_name: null,
            track_name_language:null,
            copyTrack : null,
            trackStatus : [],
            titleStatus : [],
            agree : false,

            rejections : null,
            rejections_msg : true,

            selectVolume : 1,
        }
    },
    created(){
        let today = moment(new Date())
        let date = moment(new Date(today)).add(15, 'day').format('YYYY-MM-DD')
        this.disabledDates.to =  new Date(date)
        window.addEventListener('beforeunload', this.block);
    },
    mounted(){
        
        this.$nextTick(function () {
            if(this.$store.getters.languageCode == 'ko'){
                this.calenderLang = this.ko
            }else{
                this.calenderLang = this.en
            }
            
        // 모든 화면이 렌더링된 후 실행합니다.
            // title animation
              this.callData();

            // setTimeout(function(){
            //     document.getElementById('distributionMusic').classList.add('action')
            // }, 200)
            
            
            
            // 유통정보, 세금정보, 지급정보 미입력시
            const session_userInfo = JSON.parse(localStorage.SR_UserInfo)
            if(this.licensorCheck(session_userInfo)){
                
                    this.popupOpen('distribution_guide01',"none_licensor")
                
            }
            
            this.$EventBus.$on(this.eventName, (params, key) => {
                
                if(key === this.trackInfo_result_eventName){
                    // 음악 판매하기 트랙정보 값
                    this.trackParams = params
                    if(this.error.indexOf('track') > -1) this.error.splice(this.error.indexOf('track'), 1)
                }else if(key === this.artist_result_eventName) {
                    this.artist_implement(params)
                }else if(key === this.coverImage_result_eventName){
                    // 커버이미지 파일
                    this.oldAlbumImage = params
                    this.coverImage_file = params
                }else if(key === this.agencyImage_result_eventName){
                    // 기획사 이미지 파일
                    this.agencyImage_file = params
                }
            })
            
            this.$EventBus.$on('label_cover', (params) => {
                
                if(params == null){
                    this.oldLabelImage = params
                    this.newLabelImage = params
                }else{
                    this.newLabelImage = params
                }
            })
            this.$EventBus.$on('album_cover', (params) => {
                
                this.newAlbumImage = params
                if(params == null){
                    this.oldAlbumImage = params
                    this.newAlbumImage = params
                }else{
                    this.newAlbumImage = params
                }
            })
            this.$EventBus.$on('genre', (params) => {
                this.genreList = params
            })
            this.$EventBus.$on('artist', (params) => {
                this.artistList = params
            })
            this.$EventBus.$on('saveTrack', (params) => {
                this.callData()
            })
            
            
        })
    },
    watch: {
        'albumInfo.label'(e){
            if(this.albumInfo.label){
                this.albumInfo.pline_text = this.albumInfo.label + ', under license to Sound Republica, Inc.'
            }else{
                this.albumInfo.pline_text = 'under license to Sound Republica, Inc.'
            }
        },
        'imageUploadOption'(e){
            
        },
        '$store.getters.languageCode'(){
            if(this.$store.getters.languageCode == 'ko'){
                this.calenderLang = this.ko
            }else{
                this.calenderLang = this.en
            }
        },
        'albumInfo.name_language'(){
            
        }
    },
    computed: {
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const none_licensor = this.$i18n.t("distributionMusic.msg.none_licensor")
                const completed = this.$i18n.t("distributionMusic.msg.completed")
                const save = this.$i18n.t("distributionMusic.msg.save")
                const saving = this.$i18n.t('distributionMusic.msg.saving')
                const registering = this.$i18n.t('distributionMusic.msg.registering')
                const none_coverImage = this.$i18n.t('distributionMusic.msg.coverImg')

                const messageCollection = { 
                    none_licensor: none_licensor,
                    completed: completed,
                    save: save,
                    saving: saving,
                    registering: registering,
                    none_coverImage: none_coverImage
                    }
                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        },
        albumInfoError(){
            let checkList =  [
                'name',
                'label',
            ] 
            
            let errorList = []
             if(this.valCheck(this.distribution.contract.territory)){
                errorList.push('territory')
            }
            // if(this.valCheck(this.distribution.desired_release_date)){
            //     errorList.push('desired_release_date')
            // }
            for(var i in checkList){
                if(this.valCheck(this.albumInfo[checkList[i]])){
                    errorList.push(checkList[i])
                }
            }
            if(!this.coverimage_id && !this.newAlbumImage && !this.oldAlbumImage){
                errorList.push('coverimage_id')
            }
            if(this.genreList.length == 0){
                errorList.push('genre')
            }
            if(this.artistList.length == 0){
                errorList.push('artist')
            }
            return errorList
        },
        distributionError(){
            
            let errorList = []
            
           
            return errorList
        },
        contract(){
            const SR_UserInfo = localStorage.getItem("SR_UserInfo")
            const currency = JSON.parse(SR_UserInfo).licensor? JSON.parse(SR_UserInfo).licensor.currency: null
            const contract = this.$store.state.contract
            let result = contract[this.$i18n.locale]
            // if(currency) {
            //     switch(currency.toLowerCase()) {
            //         case 'krw':
            //             result =  contract.ko
            //             break;
            //         case 'usd':
            //             result = contract.en
            //             break;
            //         case 'eur':
            //             result = contract.en
            //         default:
            //             break;
            //     }
            // }
            return result
        }
    },
    methods: {
        callData(){
            this.loadingShow();
            this.$store.dispatch("LOAD_ALBUM", this.$route.params.id)
            .then(result =>{
                if(result.state == 'temporary' || result.state == 'rejected' ){
                    this.setData (result)
                }else{
                    this.alert({msg:this.iText('accessWarning')})
                    this.leave = true
                    window.removeEventListener('beforeunload', this.block);
                    setTimeout(() => {
                        this.$router.push('/album')    
                    }, 0);
                }
                
                //console.log('this.albumInfo')
                
            }).catch(error =>{
                this.leave = true
                window.removeEventListener('beforeunload', this.block);
                setTimeout(() => {
                    this.$router.push('/album')    
                }, 0);
                
            })
        },
        setData(data){
            let t = data
            this.albumData = t
            this.genreList = []
            this.state = t.state
            this.rejections = t.rejections[0]? t.rejections[0]: null
            if(t.genre1_id){
                this.genreList.push(t.genre1)
            }
            if(t.genre2_id){
                this.genreList.push(t.genre2)
            }

            this.albumInfo = {
                ...this.albumInfo,
                name: t.name,
                name_english: t.name_english,
                name_language: t.name_language,
                name_multilang : t.name_multilang,
                description: t.description,
                description_language: t.description_language,
                description_english: t.description_english,
                release_date : t.release_date,
                release_time : t.release_time,
                original_release_date : this.newDate(t.original_release_date),
                label: t.label,
                label_english: t.label_english,
                label_language: t.label_language,
                labelimage: t.labelimage,
                labelimage_id: t.labelimage_id,
                credit : t.credit,
                barcode : t.barcode,
                release_type : t.release_type,
            }
            
            
            //유통계약 저장
            this.licensor_message = t.licensor_message
            if(t.contract){
                this.distribution.contract = {
                    territory : t.contract.territory,
                    territory_exception: t.contract.territory_exception =='korea' ? true : null,
                }
            }
            
            this.artistList = t.artists
            if(t.coverimage_id){
                this.albumInfo.coverimage_id =t.coverimage_id
                this.oldAlbumImage = t.coverimage
                this.imageUploadOption.imageFileInfoData = t.coverimage
            }else{
                this.albumInfo.coverimage_id = null
                this.oldAlbumImage = null
                this.imageUploadOption.imageFileInfoData = null
            }
            if(t.labelimage_id){
                this.albumInfo.labelimage_id =t.labelimage_id
                this.oldLabelImage = t.labelimage
                this.imageUploadOption_label.imageFileInfoData = t.labelimage
            }else{
                this.albumInfo.labelimage_id =null
                this.oldLabelImage = null
                this.imageUploadOption_label.imageFileInfoData = null
            }
            let trackList = []
            this.trackStatus = [[]] 
            this.titleStatus = []
            const track = []
            const numbering = [];
            for( let i in t.tracks){
                let obj = {
                    ...t.tracks[i]
                }
                this.titleStatus.push(t.tracks[i].titletrack)
                
                
                obj['trackStatus'] = false
                obj['errorList'] = []
                
                obj['contributorsStatus'] = false
                for (let t in obj.contributors){
                    if(obj.contributors[t].role == 'composer'){
                        obj['contributorsStatus'] = true
                    }
                }
                if(!obj.contributorsStatus){
                    obj['errorList'].push('composer')
                }
                if( this.valCheck(obj.genre1_id) ){obj['errorList'].push('genre')}
                if( obj.artists.length < 1 ) { obj['errorList'].push('artist') }
                // if( obj.files.length < 1) { obj['errorList'].push('file') }
                let filseStatus = []
                for( var j in  obj.files ) { 
                    let type = obj.files[j].display_name
                    switch(path.extname(type).toLowerCase()) {
                        case '.mp3':
                            filseStatus.push('mp3')
                            break;
                        case '.wav':
                            filseStatus.push('wav')
                            break;
                        case '.flac':
                            filseStatus.push('flac')
                        break;            
                    }
                }
                
                if( filseStatus.indexOf('flac') < 0 && filseStatus.indexOf('wav') < 0 ){
                    obj['errorList'].push('fileType') 
                }
                if( this.valCheck(obj.type) ){
                    obj['errorList'].push('type') 
                }
                if( obj.contributors.length < 1 || 
                    !obj.contributorsStatus || 
                    this.valCheck(obj.genre1_id) || 
                    obj.artists.length < 1 || 
                    obj.files.length < 1 || 
                    (filseStatus.indexOf('flac') < 0 && filseStatus.indexOf('wav') < 0) || 
                    this.valCheck(obj.type)) 
                {   
                    obj['trackStatus']= false
                    this.trackStatus.push(false)
                }else{
                    obj['trackStatus'] = true
                    this.trackStatus.push(true)
                }
                
                if(trackList[t.tracks[i].volume - 1]){
                    trackList[ Number(t.tracks[i].volume) - 1].push(obj)
                }else{
                    //numbering.push(Number(t.tracks[i].volume) - 1)
                    
                    if(trackList.length < t.tracks[i].volume){
                        let count = t.tracks[i].volume - trackList.length 
                       
                        for(let i = 0;  i < count; i++ ){
                            trackList.push([])
                            console.log(trackList)
                        }
                        trackList[Number(t.tracks[i].volume) - 1].push(obj)
                    }
                    
                }
                
            }
            
            
            this.tracks = trackList
            if(t.contract){
                this.distribution.contract.id = t.contract.id
            }
            
            this.distribution.desired_release_date = this.newDate(t.desired_release_date)
           
            setTimeout(() => {
                
                this.loadingHide();
            }, 0);
        },
        stepBtnMove(index){
            this.stepBtnMoveCheck(index)
            
        },
        stepBtnMoveCheck(step){
            this.errorText= ''
            if(this.tabStep == 1){
                if(this.albumInfoError.length > 0){
                    for(var i in this.albumInfoError){
                        if( i != 0 ) this.errorText += ' / '
                        this.errorText += this.$i18n.t('albumErrorList.'+this.albumInfoError[i])
                    }
                    this.stepConfirm(this.tabStep,step)
                    
                }else{
                    this.nextStep(this.tabStep,step)
                    
                }
            }else if(this.tabStep == 2){
                if(this.titleStatus.indexOf(1) < 0 || this.trackStatus.indexOf(false) > -1){
                    this.errorText = ''
                    if(this.titleStatus.indexOf(1) < 0){
                        this.errorText += this.$i18n.t('error.track.title')
                    }

                    for(var i in this.tracks){
                        this.errorText +=  '<br><br>'+this.$i18n.t('track.vol')+'.'+[Number(i)+1] + ''
                        for(var b in this.tracks[i]){
                            if(this.tracks[i][b].trackStatus == false){ 
                                if(this.errorText != ''){
                                    this.errorText += ' <br>'
                                }
                                this.errorText += ' Track.' + (Number(b) +1) + '('
                                for(var j in this.tracks[i][b].errorList){
                                    if( j != 0 ) this.errorText += ' , '
                                    this.errorText += this.$i18n.t('track.'+this.tracks[i][b].errorList[j])
                                }
                                this.errorText += ')'
                            }
                        }
                    }
                    
                    this.stepConfirm(this.tabStep,step)
                }else if(this.trackStatus == 0){
                    this.errorText = this.$i18n.t('error.trackLength')
                    this.stepConfirm(this.tabStep,step)
                    
                }else{
                    this.nextStep(this.tabStep,step)
                }
            }else if(this.tabStep == 3){
                this.nextStep(this.tabStep,step)
            }
        },
        stepCheck(step){
            //step 1 앨범 기본정보 ,2 트랙 목록 , 3 유통정보
            this.errorText = ''
            if(step == 1){
                if(this.albumInfoError.length > 0){
                    for(var i in this.albumInfoError){
                        if( i != 0 ) this.errorText += ' / '
                        this.errorText += this.$i18n.t('albumErrorList.'+this.albumInfoError[i])
                    }
                    this.stepConfirm(step,(step+1))
                }else{
                    this.nextStep(step,(step+1))
                }
            }else if(step == 2){
                if( this.titleStatus.indexOf(1) < 0 || this.trackStatus.indexOf(false) > -1){
                    this.errorText = ''
                    if(this.titleStatus.indexOf(1) < 0){
                        this.errorText += this.$i18n.t('error.track.title')
                    }

                    for(var i in this.tracks){
                        this.errorText +=  '<br><br>' + this.$i18n.t('track.vol') + '.'+[Number(i)+1] + ''
                        for(var b in this.tracks[i]){
                            if(this.tracks[i][b].trackStatus == false){ 
                                if(this.errorText != ''){
                                    this.errorText += ' <br>'
                                }
                                this.errorText += ' Track.' + (Number(b) +1) + '('
                                for(var j in this.tracks[i][b].errorList){
                                    if( j != 0 ) this.errorText += ' , '
                                    this.errorText += this.$i18n.t('track.'+this.tracks[i][b].errorList[j])
                                }
                                this.errorText += ')'
                            }
                        }
                    }

                    this.stepConfirm(this.tabStep,(step+1))
                }else if(this.trackStatus.length == 0){
                    this.errorText = this.$i18n.t('error.trackLength')
                    this.stepConfirm(this.tabStep,(step+1))
                    
                }else{
                    this.nextStep(this.tabStep,(step+1))
                }
            }else if(step == 3){
                this.nextStep(step,step+1)
                
                // let options = {
                //     id:  this.$route.params.id,
                //     params: {
                //         licensor_message : this.licensor_message,
                //         contract: {
                //             ...this.distribution.contract,
                //         }    
                //     }
                // }
                // if(this.valCheck(options.params.contract.territory_exception)){
                //     delete options.params.contract.territory_exception
                // }else{
                //     options.params.contract.territory_exception = 'korea'
                // }
                // if(this.valCheck(options.params.licensor_message)){
                //     delete options.params.licensor_message
                // }
                // this.saveAlbum(options)    
            }
        },
        nextStep(step,goal){
            
            const options = {
                id:  this.$route.params.id,
                params: {
                    
                }
                
            }
            let param ;
            if(step == 1){
                param = {
                    artists : [],
                    genre1_id : null,
                    genre2_id: null,
                    
                    ...this.albumInfo,
                    
                }
                //param['desired_release_date'] = ((this.distribution.desired_release_date) instanceof Date  ? this.fixDate(this.distribution.desired_release_date) : this.distribution.desired_release_date)
                
                param['contract'] = {
                    ...this.distribution.contract,
                }
                if(this.valCheck(param.contract.territory)){
                    delete param.contract
                }else{
                    if(param.contract.territory== 'korea'){
                        param.contract.territory_exception = null
                    }else{
                        if(this.valCheck(param.contract.territory_exception)){
                            param.contract.territory_exception = null
                        }else{
                            param.contract.territory_exception = 'korea'
                        }
                    }
                    
                }


                for(var i in this.artistList ){
                    param['artists'].push({id:this.artistList[i].id})
                }
                if(this.valCheck(param.original_release_date)){
                    param.original_release_date = null
                }else{
                    param.original_release_date = this.fixDate(param.original_release_date)
                }
                
                param.genre1_id = this.genreList[0] ? this.genreList[0].id  : null
                param.genre2_id = this.genreList[1] ? this.genreList[1].id  : null
                
                param['cline_text'] = this.albumInfo.label
                // return false
                this.loadingShow()
                
                const coverImage = new Promise((resolve) =>{
                    if(this.newAlbumImage) {
                        this.$store.dispatch('FILEUPLOAD', this.newAlbumImage)
                        .then(result => {
                            param["coverimage_id"]  = result[0].id
                            resolve(true)
                        }).catch(error =>{
                            resolve(false)
                            console.log(error)
                        })
                    }else{
                        if(this.oldAlbumImage == null){
                            param["coverimage_id"]  = null
                        }
                        resolve(true)
                    }
                
                })
                const agencyImage = new Promise((resolve)  =>{
                    if(this.newLabelImage) {
                        this.$store.dispatch('FILEUPLOAD', this.newLabelImage)      // 커버 이미지 저장          
                        .then(result => {
                            param["labelimage_id"]  = result[0].id
                            resolve(true)
                        }).catch(error =>{
                            this.error.push(agency_key)
                        })
                    }else {
                        if(this.oldLabelImage ==null){
                            param["labelimage_id"]  = null
                        }
                        resolve(true)
                    }
                })
                Promise.all([coverImage, agencyImage]).then(values => {
                    options.params = param
                    setTimeout(() => {
                        this.saveAlbum(options,step)
                    }, 0);
                    
                })
            }else if(step == 2){
                
                if(this.tracks.length > 0){
                    const arrayCheck = this.tracks.filter(el => el.length != 0 );
                    let array = []
                    console.log(arrayCheck)
                    for(var i in arrayCheck ){
                        for(var b in arrayCheck[i]){
                            var track = {
                                id : arrayCheck[i][b].id,  
                                volume : Number(i)+1,
                                tracknr : Number(b)+1,
                            }
                            array.push(track)
                        }
                    }

                    let params = {
                        tracks : array
                    }
                    options.params = params
                    // if (array.length <= 3) {
                    // params['release_type'] = 'single';
                    // }else if (array.length >= 4 && array.length <= 6){
                    //     params['release_type'] = 'ep';
                    // }else if (array.length >= 7){
                    //     params['release_type'] = 'album';
                    // }
              
                
                }else{
                    
                }
               
                this.saveAlbum(options,step) 
            }else{
                //console.log((this.distribution.desired_release_date) instanceof Date)
                

                options.params['licensor_message'] = this.licensor_message
                //console.log(options.params)
                
                
                if(this.valCheck(options.params.licensor_message)){
                    delete options.params['licensor_message']
                }
                this.saveAlbum(options,step) 
            }
            
           
            //this.saveAlbum(options,step)    
            
            this.tabStep = goal 
        }, 
        // 오류있을경우 확인 컨펌 저장할지 넘길지
        stepConfirm(index,goal){
            this.confirm({
                title : this.iText('album.validationTitle'),
                msg : `
                   ${this.iText('album.validationText')}
                    <p style=" padding:1rem; text-align:left; word-break:keep-all; font-size: 15px; color:#ca3c3c" >
                        ${this.errorText}
                    </p>    
                `,
                autofocus : true,
                width:600,
                action : ()=>{
                    this.nextStep(index,goal)
                }
            })
            //  <div class="popTitle ">
                    //     <strong class="darkWhiteColor">
                    //         {{$t('album.validationTitle')}}
                    //     </strong>
                    // </div>
                    // <div style=" padding:1rem; word-break:keep-all; font-size: 15px;" class="darkWhiteColor">
                    //         <p style="font-size: 16px; color:#000;"  class="textCenter" v-html=""></p>
                    //         <br>
                    //         <p style=" padding:1rem; word-break:keep-all; font-size: 15px; color:#ca3c3c" >
                    //             {{errorText}} 
                    //         </p>
                    // </div>        
        },
        saveAlbum(options ,step){
            this.loadingShow()
            this.closeModal('confirm')
            this.$store.dispatch('UPDATEALBUM', options)
            .then(result =>{
                this.loadingHide()
                
                this.setData(result)
                //this.callData()
                window.scrollTo(0,0)
                
            }).catch(error =>{
                this.loadingHide()
                window.removeEventListener('beforeunload', this.block);
                console.log(error)
                this.alert({msg: 'error'})
            })
        },

        //최종 접수 신청
        applyAlbum(){
            if(this.albumInfoError.length > 0 || this.trackStatus.indexOf(false)  >-1 ||this.trackStatus.length == 0 || this.titleStatus.indexOf(1) < 0 ){
                this.alert({msg : this.$i18n.t('error.applyAlbum') })
                //this.$i18n.t('distribution False')
                
                return false
            }
            // //요청사항
            // licensor_message : null,
            // //유통 계약
            // distribution : {
            //     desired_release_date: null,
            //     contract : {
            //         territory : null,
            //         territory_exception: null
            //     },
            // },
            // if(this.valCheck(this.distribution.contract.territory)){
            //     this.alert({msg : this.$i18n.t('enter.musicStore')})
            //     return false
            // }
            if(this.valCheck(this.agree)){
                this.alert({msg : this.$i18n.t('distributionMusic.fail.agree')})
                return false
            }
            let today = moment(new Date()).format('YYYY-MM-DD')
            let next = moment(new Date(moment(today).add('years',2) )).format('YYYY-MM-DD')
            let options = {
                id:  this.$route.params.id,
                params: {
                    state : 'registration_complete' ,
                    desired_release_date : this.fixDate(this.distribution.desired_release_date),
                    licensor_message : this.licensor_message,
                    contract: {
                        ...this.distribution.contract,
                        start_date: today,
                        end_date : next
                    }    
                }
            }
            options.params['name_multilang'] = this.albumInfo.name_multilang
            for (const key in options.params.name_multilang) {
                if(this.valCheck(options.params['name_multilang'][key]) ){
                    
                    delete options.params['name_multilang'][key]
                }else{
                }
            } 
            if(this.valCheck(options.params.contract.territory_exception)){
                delete options.params.contract.territory_exception
            }else{
                options.params.contract.territory_exception = 'korea'
            }
            if(this.valCheck(options.params.licensor_message)){
                delete options.params.licensor_message
            }
            this.confirm({
                title : this.iText('distribution.submit'),
                msg : `
                    <div style="text-align:left">
                        ${this.iText('distribution.submit.msg')}
                    <div/>
                `,
                
                autofocus : true,
                width:500,
                action : ()=>{
                    this.loadingShow()
                    this.$store.dispatch('UPDATEALBUM', options)
                    .then(result =>{
                        this.leave = true
                        window.removeEventListener('beforeunload', this.block);
                        setTimeout(() => {
                            this.$router.push('/album')    
                        }, 0);
                    }).catch(error =>{
                        this.loadingHide()
                        console.log(error)
                        this.alert({msg: error.response.data.message})
                    })
                }
            })
        },

        dragover_handler(event){
            /* event.preventDefault(); */ 
            // Set the dropEffect to move
            //event.dataTransfer.dropEffect = "none"
        },
        
        loadArtistList(){
            this.showPanel15(artistData)
            let sort = null
            let sortorder = null
            let page = null
            let column = null
            // this.$store.dispatch('LOADARTIST', { sort, sortorder, page, column })
            // .then(result =>{
            //     let artistData = result.items.map((value, index) =>{
            //         let name = value.display_name
            //         let id = value.id
            //         return {
            //             id: id,
            //             name: name
            //         }
            //     })
            //     if(this.click.artist_click){
            //         this.click.artist_click = !this.click.artist_click
                    
            //     }
            // }).catch(error =>{
            //     this.showPanel2()
            // })
        },
        loadGenreList(id){
            let sort = null
            let sortorder = null
            let page = null
            let column = {}
            column["id"] = id
            return this.$store.dispatch('LOADGENRE', { sort, sortorder, page, column })
                .then(result =>{
                    return result
                })
        },
        showPanel15(){
           
            this.$EventBus.$emit('openPanel',{
                component: "panel-15",
                props: {
                    returnName: "artist",
                    currentData: this.artistList
                }
            },()=>{
                this.click.artist_click = true
            });
        },
        /* showPanel13(genre){
            const panel = this.$showPanel({
                component: "panel-13",
                width: '740px',
                openOn: 'right',
                cssClass: "panel-zindex",
                props: {
                    listData: genre,
                    returnName: "representative_genre",
                    currentData: this.params.defaultInfo.artists
                }
            })
        }, */
        genre_register() {
                this.showPanel16()
        },
        showPanel14(){
            // const panel = this.$showPanel({
            //     component: "panel-14",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-14",
                props: {
                   
                }
            },()=>{
                
            });
           
        },
        showPanel16(){
            
            this.$EventBus.$emit('openPanel',{
                component: "panel-16",
                props: {
                    returnName: "genre",
                    currentData: this.genreList
                }
            },()=>{
            });
        },
        showPanel2(){
            // const panel = this.$showPanel({
            //     component: "panel-2",
            //     width: '740px',
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-2",
                props: {
                    trackData : {

                    }
                }
            },()=>{
                
            });
        },
        addTrack(vol){
            // const panel = this.$showPanel({
            //     component: "panel-2",
            //     width: '740px',
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })
            this.track_volume = vol
            this.track_name =  null 
            //this.track_name_language = this.albumInfo.name_language
            this.openModal('track')
            
            // this.$EventBus.$emit('openPanel',{
            //     component: "panel-9",
            //     props: {
            //         data
            //     }
            // },()=>{
                
            // });
        },
        postTrack(){
            let params ={
                id : this.$route.params.id,
                params : {
                    volume : this.track_volume,
                    tracknr : this.tracks[this.selectVolume - 1] ? this.tracks[this.selectVolume-1].length + 1 :  1,
                    parental_warning : 'not_explicit',
                    name_multilang : {
                        "ko" : null,
                        'en' : null,
                    }
                }
            }
            // if(this.valCheck(this.track_name_language)){
            //     this.alert({msg: this.iText("error.track.name_language") })
            //     return false
            // }
            if(this.valCheck(this.track_name) ){
                this.alert({msg: this.iText("error.track.name") })
                return false
            }
            for(var j in this.tracks){
                if(this.tracks[j].name == this.track_name &&  this.tracks[j].type == null ){
                    this.alert({msg : this.$i18n.t('track.nameAndType.error')})
                    return false
                }
            }
            
            let trackArtist = [] 
            for(var i in this.artistList){
                trackArtist.push({id :this.artistList[i].id})
            }
            
            params.params['artists'] = trackArtist
            if( this.genreList[0]){
                params.params['genre1_id'] = this.genreList[0].id
            }
            if( this.genreList[1]){
                params.params['genre2_id'] = this.genreList[1].id
            }
           
            params.params['name'] = this.track_name
            //params.params['name_language'] = this.track_name_language
            //params.params['lyrics_language'] = this.track_name_language
            //params.params['featuring_artists_language'] = this.track_name_language
            
            if(this.tracks.length == 0) {
                params.params['titletrack'] = 1
            }
            
            // if(!this.valCheck(this.copyTrack)){
            //     params.params['artists'] = this.copyTrack.artists
            //     params.params['genre1_id'] = this.copyTrack.genre1_id
            //     params.params['genre2_id'] = this.copyTrack.genre2_id
            //     params.params['contributors'] = this.copyTrack.genre2_id
            //     params.params['featuring_artists'] = this.copyTrack.featuring_artists
            //     params.params['featuring_artists_english'] = this.copyTrack.featuring_artists_english
            //     params.params['featuring_artists_language'] = this.copyTrack.featuring_artists_language
            // }
            this.loadingShow();
            this.$store.dispatch('SAVETRACK', params).then(result =>{
                //this.tracks.push(result)
                this.callData()
                this.editTrack(result)
               
                this.closeModal('track')
            }).catch(error =>{
                this.loadingHide()
            })
        },
        editTrack(data){
            this.$EventBus.$emit('openPanel',{
                component: "panel-9",
                props: {
                    trackData :data,
                    titleStatus : this.titleStatus,
                    trackList : this.tracks
                }
            },()=>{
                
            });

        },
        deleteTrack(data){
            this.confirm({
                title : ` ${this.$i18n.t('confirm')}`,
                msg : `
                    ${this.$i18n.t('confirm.deleteTrack')}
                `,
                autofocus : true,
                width:400,
                action : ()=>{
                    let payload = {
                        id : `${this.$route.params.id}`,
                        track_id : data.id
                    }
                    
                    this.loadingShow()
                    this.$store.dispatch("DELETETRACK", payload)
                    .then(result =>{
                        this.callData()
                    }).catch(error =>{
                        this.loadingHide();
                        switch(error.response.data.code) {
                            default:
                            this.alert({msg:error})
                            break;
                        }
                    })
                }
            })
            
        },
        confirmDelete(){

        },
        block(e){
            window.alert("test")
            e.preventDefault(); 
            e.returnValue = '';
        },

        disabledBeforeTodayAndAfterAWeek(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < new Date(today.getTime() + 14 * 24 * 3600 * 1000) || 6 == date.getDay() ||  0 == date.getDay();
        },
        updateLang(data){
            this.albumInfo.name_multilang = data
            this.$forceUpdate()
        },
        onChangeVolume(vol,num,event){
                console.log(vol,this.tracks);
            if(  vol == 0 && this.tracks[vol].length <2 ){
                this.alert({msg: this.$i18n.locale == 'ko' ? 'Disc.1 에 최소 한곡이 있어야합니다.' :'There must be at least one track in volume.1.' })
                this.$forceUpdate()
                return false
            }else{
                let list = [...this.tracks]


                
                let ddd = list[vol].splice(num)
                ddd[0].volume = Number(event.target.value)
                ddd[0].tracknr = list[Number(event.target.value)-1].length
             
                
                let arr = []
                const newArr = arr.concat(list[(Number(event.target.value) - 1)], ddd);
                
                this.tracks[Number(event.target.value)-1] = newArr
                console.log(this.tracks)
                this.nextStep(2,2)
            }
           
        },
        onChange(vol,num,event){
            
            
            let list = [...this.tracks[vol]]
            const item = list.splice(num, 1)
            
            list.splice((Number(event.target.value) - 1) ,0 ,item[0])
            
            for(let i in list){
                
                list[i].tracknr = Number(i) + 1
                
            }
            
            this.tracks[vol] = list
            
            this.nextStep(2,2)
            //this.$forceUpdate()
        },
        addVolume(){
            let check = true
            for(let i in this.tracks){
                if(this.tracks[i].length < 1){
                    check = false
                    this.alert({msg: this.$i18n.locale == 'ko' ? `Disc.${Number(i)+1} 에 최소 한곡이 있어야합니다.` :`There must be at least one track in volume.${Number(i)+1} .` })
                    this.$forceUpdate()
                    return false
                }    
            }
            if(check){
                this.tracks.push([]) 
            }else{
                
            }
        },
        
    },
    beforeDestroy(e) {
        window.removeEventListener('beforeunload', this.block);
    },
    beforeRouteLeave (to, from, next) { 
        
        
        if( to.name !='Login'){
            next(false)
            if(!this.leave){
                this.confirm({
                    title : ` ${this.$i18n.t('confirm')}`,
                    msg : `
                        ${this.$i18n.t('confirm.leavePage')}
                    `,
                    autofocus : true,
                    width:400,
                    action : ()=>{
                        next()
                    }
                })
                
            }else{
                next()
            }
        }else{
            next()
        }
    }

}
</script>

<style scoped>
#distributionMusic {
    margin-top: 90px;
}

#trackListTable {
    
    padding: 0px;
    overflow: auto;
    
}

.artistSelect {
    cursor: pointer;
}

/* [S] Title Animation */
.sv_z .bg{
  background-image:url('../../../assets/images/sv_img.png');
  background-repeat:no-repeat;
  background-position:50% 0;
  transform: scale(1.6);
  background-size:cover;
  transition:1.2s;
  display:block;
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
}

.sv_z .sv_copy{
  transition-delay: .5s; 
  transition-duration: .8s;
  transform: translateX(-80px);
  opacity:0;
  filter:alpha(opacity=100);
}

.sv_z .sv_copy2{
  transition-delay: .7s;
  transition-duration: .8s;
  transform: translateX(-100px);
  opacity:0;
  filter:alpha(opacity=100);
}

.action .sv_z .bg{transform: scale(1) !important;}
.action .sv_z .sv_copy{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
.action .sv_z .sv_copy2{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
/* [E] Title Animation */


.fontBold{
    font-weight:bold;
}

.non_list_style:before{
    background:none !important;
}

.artistStyle01{
    
    line-height: 1;
    margin:10px 5px 0px;
    border: 1px solid #dedede;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 14px;
}

.completed_guide{
    font-size: 16px;
    margin-top: 10px;
    color: #0F73EB;
}

/* Typing */
p.typing-txt{display: none;}
  
.typing {  
    display: inline-block; 
    animation-name: cursor; 
    animation-duration: 0.3s; 
    animation-iteration-count: infinite; 
} 

@keyframes cursor{ 
    0%{border-right: 1px solid #fff} 
    50%{border-right: 1px solid #000} 
    100%{border-right: 1px solid #fff} 
}

/* [S] document Style */
.doc_title{
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -1px;
}

.doc_contents{
    word-break: keep-all;
    margin-bottom: 20px;
}

.cursorPointer{
    cursor: pointer;
}
/* [E] document Style */

/* [v-cloak]::before {
  content: '로딩중...'
}
[v-cloak] > * {
  display:none;
} */

</style>