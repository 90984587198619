<template src="./template.html">
</template>

<script>
// JQuery 사용
import JQuery from 'jquery'
const $ = JQuery

export default {
    name: 'Cost',
    data(){
        return {
            description: ' 비용 페이지 입니다.'
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$EventBus.$emit("navi", "page_clear",'/')
        next()
    },
    mounted(){
        this.$nextTick(function () {
            // 모든 화면이 렌더링된 후 실행합니다.
            this.$EventBus.$emit("navi", "page_position",'cost')
            
            const $feelow01_w = $(".feelow01_w")
            setTimeout(function(){
                $feelow01_w.addClass("complete");
            },100);
        })
    },
    methods : {
        scrFeeAction(touchstart, $feelow01_w, $feelow02_w, $feelow02_w_pos, $feelow03_w, $feelow03_w_pos, $feelow04_w, $feelow04_w_pos){
            $feelow02_w_pos = $feelow02_w.length ? $feelow02_w.offset().top : 0
		    $feelow03_w_pos = $feelow03_w.length ? $feelow03_w.offset().top : 0
            $feelow04_w_pos = $feelow04_w.length ? $feelow04_w.offset().top : 0
		    $(window).on("scroll",function(){
			    if(touchstart) return
                
                let $scroll = $(this).scrollTop()
                if($feelow02_w_pos/1.4 < $scroll) $feelow02_w.addClass("complete")
                if($feelow03_w_pos/1.4 < $scroll) $feelow03_w.addClass("complete")
                if($feelow04_w_pos/1.2 < $scroll) $feelow04_w.addClass("complete")
            });
            
            if(touchstart){
                if($(window).width()<$(window).height()){
                    $feelow01_w.css({"min-height" : ""})
                }
                $feelow02_w.addClass("complete")
                $feelow03_w.addClass("complete")
                $feelow04_w.addClass("complete")
            }
        },
        maxHeightAuto($feemc_mvpbox_p03){

            $feemc_mvpbox_p03.each(function(){
                $feemv03_array.push($(this).height())
            })
            $feemc_mvpbox_p03.css({"height" : Math.max.apply(null,$feemv03_array)})
        },
        feeMotion() {
            const touchstart = "ontouchstart" in window;
	        let $header = $(".header_wrap"),
            $feelow01_w = $(".feelow01_w"),
            $feelow02_w = $(".feelow02_w"),
            $feelow03_w = $(".feelow03_w"),
            $feelow04_w = $(".feelow04_w"),
            $feelow02_w_pos = 0,
            $feelow03_w_pos = 0,
            $feelow04_w_pos = 0,
            $feemc_mvpbox_p03 = $(".feemc_mvpbox_p03"),
            $feemv03_array = [],
            $header_height = 0;
	
	        $header_height = $header.length ? $header.height() : 0;
	
            this.scrFeeAction(touchstart, $feelow01_w, $feelow02_w, $feelow02_w_pos, $feelow03_w, $feelow03_w_pos, $feelow04_w, $feelow04_w_pos)
     
            setTimeout(function(){
                $feelow01_w.addClass("complete");
            },100);
	
	        if(touchstart){
                if($(window).width()<$(window).height()){
                    $feelow01_w.css({"min-height" : ""});
                }
            }
        }
	}
}
</script>

<style scoped>
/* 메인전용 CSS */
@import '../../assets/css/fee.css';

/* slider */
@import '../../assets/css/slick.css';
@import '../../assets/css/slick-theme.css';

#cost {
    margin-top: 90px;
}

.algin_center {
    text-align: center;
}
</style>