<template src="./template.html"></template>

<script>
export default {
    data() {
        return {
            component:'',
            props:{
                width:'auto'
            },
            display : false
        }
    },
    methods: {
        closePanel(){
            this.component ='',
            this.display = !this.display;
        },
        dimClose (){
            let list = ['panel-2', 'panel-9']
            if( list.indexOf(this.component) < 0 ){
                this.component ='',
                this.display = !this.display;
                //document.getElementsByTagName("BODY")[0].classList.remove('panelOn')
            }
        },
        beforeEnter: function (el) {
            el.style.transition = 'all .2s'
            //el.style.right = '-740px'
        },
        enter: function (el, done) {
        },
        leave: function (el, done) {
        }
    },
    created (){
            this.$EventBus.$on('openPanelSub', (params) => {
                this.display = true
                if(params){
                    this.component = params.component
                    this.props = params.props
                }
                
            })
            this.$EventBus.$on('closePanel', (params) => {
                this.display = false
            })
            this.$EventBus.$on('closeSubPanel', (params) => {
                this.display = false
            })
    },
    mounted(){
        // this.$EventBus.$on('closePanel', (params) => {
        //     console.log('asdf asdf');
        //     this.closePanel();
        // })
       
    },
    destroyed (){
        //document.getElementsByTagName("BODY")[0].classList.remove('panelOn')
    }
}

</script>