import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios' // API 호출용 axios 패키지를 import한다.
import moment from 'moment'
import app from '@/main.js'
import i18n from "@/library/i18n"
Vue.use(Vuex)

axios.interceptors.response.use(
    function (response) {
        // 응답 데이터를 가공
        // ...
        app.loadingHide();
        return response;
    },
    function (error) {
        
        app.loadingHide();
        if(error.response.status == 401){
            console.log(error.response.status)
            localStorage.removeItem('SR_Token')
            app.$router.push('/login')
        }else{
            return Promise.reject(error);
        }
        // 오류 응답을 처리
        // ...
        
    }
);


const qs = require('qs')

const debug = process.env.NODE_ENV !== 'production'

//API 호출시 header 옵션 추가
const enhanceAccessToeken = () => {
    let ValidKey = localStorage.hasOwnProperty('SR_Token')
    if (ValidKey) {
        const accessToken = localStorage.SR_Token
        if (!accessToken) return
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.SR_Token
    }
}



enhanceAccessToeken()

import { country, bank, dialing, language, musicStoreList, notice, faq, privacy, terms, contract } from "./data.js"
import { SNS_TYPE } from './const.js'
export default new Vuex.Store({
    strict: false,
    state: {
        SR_Token: "null",
        SR_UserInfo: null,
        isAuthenticate: false,
        country: country.sort((a, b) => {
            const nameA = a.name_en.toLowerCase()
            const nameB = b.name_en.toLowerCase()
            if (nameA < nameB) {
                return -1
            }

            if (nameA > nameB) {
                return 1
            }
            return 0
        }),
        bank: bank,
        dialing: dialing.sort((a, b) => {
            const nameA = a.code.replace('+', '').trim()
            const nameB = b.code.replace('+', '').trim()

            if(a.name_en.toLowerCase() == "south korea") return -1
            if(b.name_en.toLowerCase() == "south korea") return 1
            
            if(a.name_en.toLowerCase() == "united states") return -1
            if(b.name_en.toLowerCase() == "united states") return 1
            
            if(a.name_en.toLowerCase() == "united kingdom") return -1
            if(b.name_en.toLowerCase() == "united kingdom") return 1

            if(a.name_en.toLowerCase() == "unknown") return 1
            if(b.name_en.toLowerCase() == "unknown") return 1

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        }),
        language: language,
        musicStoreList: musicStoreList,
        customerService: {
            notice: notice,
            faq: faq
        },
        contract: contract,             // 계약서
        privacy: privacy,               // 개인정보취급정책
        terms: terms,                   // 이용약관

        artists: [],                    // 아티스트
        genres: [],                     // 장르
        albumData: null,                // 앨범 리스트
        artistData: null,               // 아티스트 리스트
        countryCode: null,              // 접속된 국가 코드
        languageCode: null,             // 언어코드 설정
        default_track: null,            // 해당 트랙 초기값 저장
        sns_type: SNS_TYPE
    },
    mutations: {
        LOGIN(state, userInfo) {
            if (userInfo.token) {
                state.isAuthenticate = true // 로그인 됨
                state.SR_Token = userInfo.token
                state.SR_UserInfo = userInfo
                // 로컬 스토리지에 저장
                localStorage.setItem(this.newTodoItem, this.newTodoItem);
                localStorage.SR_Token = userInfo.token
                localStorage.setItem("SR_UserInfo", JSON.stringify(userInfo))
                localStorage.isAuthenticate = true
                enhanceAccessToeken()
            }
        },
        LOGOUT(state) {
            state.SR_Token = null
            state.SR_UserInfo = null
            // 로컬 스토리지에 삭제
            delete localStorage.SR_Token          // Token Info
            delete localStorage.SR_UserInfo       // User Info
            delete localStorage.isAuthenticate    // Login Check
            delete localStorage.SR_languageCode   // Access Country Code
            state.isAuthenticate = false          // Logout
        },
        TRANSFERDATA(state, resultData) {
            //사용 안함 test용
            state.distributionView = true
            state.my.distribution.transferData = resultData
        },
        ARTISTS(state, artists) {
            // 등록된 아티스트 리스트
            state.artists = artists
        },
        GENRES(state, genre) {
            // 등록된 장르 리스트
            if (genre.length > 0) {
                genre.map(value => {
                    state.genres.push(value)
                })
            }
        },
        init(state, params) {
            // state 초기화
            state[params.name] = params.type
        },
        ALBUMDATA(state, albumData) {
            state.albumData = albumData
        },
        ARTISTDATA(state, artistData) {
            state.artistData = artistData
        },
        COUNTRYCODE(state, code) {
            state.countryCode = code
        },
        LANGUAGECODE(state, code) {
            state.languageCode = code.toLowerCase()
            // 로컬 스토리지에 저장
            localStorage.SR_languageCode = code.toLowerCase()
        },
        DEFAULT_TRACK(state, track) {
            state.default_track = track
        },
    },
    getters: {
        isLoggedIn(state) {
            // 로그인 여부 true, false
            return state.isAuthenticate
        },
        languageCode(state) {
            // 사이트 언어코드 확인
            return state.languageCode
        },
        countryCode(state) {
            // 국가 코드 정렬
            let languageCode = state.languageCode
            let countryName = null

            switch (languageCode) {
                case "ko":
                    countryName = "name"
                    break;
                default:
                    countryName = "name_en"
                    break;
            }

            return state.country.sort((a, b) => {
                const nameA = a[countryName].toLowerCase()
                const nameB = b[countryName].toLowerCase()

                switch (languageCode) {
                    case "ko":
                        if(nameA == "대한민국") return -1
                        if(nameB == "대한민국") return 1
                        
                        if(nameA == "미국") return -1
                        if(nameB == "미국") return 1
                        
                        if(nameA == "영국") return -1
                        if(nameB == "영국") return 1
                        
                        if(nameA == "국적불명") return 1
                        if(nameA == "국적불명") return 1
                        break;
                    default:
                        if(nameA == "south korea") return -1
                        if(nameB == "south korea") return 1
                        
                        if(nameA == "united states") return -1
                        if(nameB == "united states") return 1
                        
                        if(nameA == "united kingdom") return -1
                        if(nameB == "united kingdom") return 1

                        if(nameA == "unknown") return 1
                        if(nameB == "unknown") return 1
                        break;
                }

                if (nameA < nameB) return -1
                if (nameA > nameB) return 1

                return 0
            })
        }
    },
    actions: {
        LOGIN({ commit }, { email, password }) {
            console.log(process.env)
            return axios.post(process.env.VUE_APP_API_URL + '/auth/login', {
                email, password
            })
                .then(({ data }) => {
                    return data
                })
        },
        LOGOUT({ commit }) {
            // HTTP 요청 헤더값 제거
            axios.defaults.headers.common['Authorization'] = undefined
            this.commit('LOGOUT')
        },
        SIGNUP({ commit }, params) {
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            };

            return axios.post(process.env.VUE_APP_API_URL + '/auth/signup', params)
                .then(({ data }) => {
                    commit('LOGIN', data)
                })
        },
        SETCOOKIE(cookieName, value, exdays) {
            // 쿠키 저장 작동 안됨
            let date = new Date;
            date.setDate(date.getDate() + 21);
            this.$cookie.set(cookieName, value, { expires: exdays });
        },
        DELETECOOKIE(cookieName) {
            // 쿠키 삭제 작동 안됨
            this.$cookie.delete(cookieName);
        },
        GETCOOKIE(cookieName) {
            // 쿠키 가져오기 작동 안됨
            const cookieResult = Cookie.$cookie.get(cookieName);
            return cookieResult
        },
        VALIDEMAIL({ commit }, email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        VALIDPASSWORD({ commit }, password) {
            const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&+_~=<>.,-^])[A-Za-z\d$@$!%*#?&+_~=<>.,-^]{8,50}$/; //최소 8 자, 최소 하나의 문자, 하나의 숫자 및 하나의 특수 문자 :
            if (!password) {
                return false
            } else if (password.length < 8) {
                return false
            } else if (!re.test(password)) {
                return false
            }
            return true
        },
        RESENDEMAIL({ commit }, email) {
            // Password 재설정 링크 이메일 
            return axios.get(process.env.VUE_APP_API_URL + '/auth/resend-verification', {
                params: {
                    email: email
                }
            })
                .then(({ data }) => console.log('RESETPASSWORD Success!'))
        },
        RESETPASSWORDEMAIL({ commit }, email) {
            // Password 재설정 링크 이메일 
            return axios.get(process.env.VUE_APP_API_URL + '/auth/reset-password', {
                params: {
                    email: email
                }
            })
                .then(({ data }) => console.log('RESETPASSWORD Success!'))
        },
        RESETPASSWORD({ commit }, { email, token, password }) {
            return axios.post(process.env.VUE_APP_API_URL + '/auth/reset-password-verify', { email, token, password })
                .then(({ data }) => console.log('RESETPASSWORD Success!'))
        },
        VERIFYEMAIL({ commit }, { email, token }) {
            // 이메일 인증 확인
            return axios.post(process.env.VUE_APP_API_URL + '/auth/verify', { email, token })
                .then(({ data }) => console.log('RESETPASSWORD Success!'))
                .catch(error=> console.log("VERIFYEMAIL Error:::", error))
        },
        EMPTYCHECK({ commit }, { emptyType, params }) {
            // Null 체크:  is 일경우 true 아니면 false
            // value는 단일 값 OR Array 일경우 isArray
            if(params){
                console.log(params)
                params = String(params).trim() 
            }
            
            let isArray = Array.isArray(params)
            let result = []
            if (isArray) {
                if (params.length == 0) result.push(true)
                else {
                    for (let i in params) {
                        if (params[i] === emptyType) result.push(true)
                        else result.push(false)
                    }
                }
            } else {
                if (!params) result.push(true)
                else result.push(false)
            }

            if (result.indexOf(true) > -1) {
                return true
            }   // 값이 null 일때
            else {
                return false
            }

        },
        ONLYNUMBER({ commit }, val) {
            const re = /[^0-9]/g
            return val.replace(re, '');
        },
        ONLYENGLISH({ commit }, val) {
            // 영문만 입력
            let inputvalue = val
            const re = /[^a-z]/gi
            return inputvalue.replace(re, '')
        },
        MYSAVE({ commit }, val) {
            // My 페이지 저장
            let sr_userInfo = JSON.parse(localStorage.getItem("SR_UserInfo"))  //저장된 아이디 값

            let today = new Date();
            let params = {
                
                nationality: val.
                nationality,     // 국적
                entity: val.division,// 개인/사업자 구분
                currency: val.currency,// 정산 화폐
                given_name: val.given_name,// 성
                family_name: val.family_name,// 이름
                phone_countrycode: val.countryPhone,  // 국번
                phone: val.phone, // phone
                entity_identification_type: val.entity_identification_type,   // 주민등록번호 타입           
                entity_identification: val.idCard,  // 주민등록번호
                entity_date: moment(val.birthday).format('YYYY-MM-DD'), // 생년월일
                termsofuse: true,
                termsofuse_timestamp: today,
                
                entity_address: {
                    country: val.countryCode,
                    address_line: val.taxAddress1,
                    address_line2: val.taxAddress2,
                    district: val.taxDistrict,
                    city: val.taxCity,
                    postal_code: val.taxPostalCode
                }
            }
            // 국적관련
            if (val.nationality != '' ||val.nationality.toLowerCase() == "kr") {
                if(val.mims) Object.assign(params, { mims_account: val.mims })   // mims 가입자명
            }

            // 사업자 일경우
            if (val.division == 'corporation') {
                Object.assign(params, { company: val.companyName })   // 회사명
            }

            // 은행 국가관련
            if (val.countryCode != '' &&  val.countryCode.toLowerCase() == 'kr') {
                const bank_obj = {
                    bankaccount: {
                        /*  보류 address: {
                                country: val.countryCode,
                                address_line: val.paymentAddress1,
                                address_line2: val.paymentAddress2,
                                district: val.paymentDistrict,
                                city: val.paymentCity,
                                postal_code: val.paymentPostalCode,
                        }, */
                        payment_method: val.payment,    // 지급방법 1. kr_banktransfer 2. intl_banktransfer 3. payoneer
                        bank_name: val.bank ? val.bank.name : null,
                        bank_code: val.bank ? val.bank.code : null, 
                        /*  20200313 보류
                            bank_branch: val.bankAndBranchName, */
                        account_number: val.accountNumber ? val.accountNumber : null,
                        name: val.accountHolder ? val.accountHolder : null
                    }
                }
                //if(val.name&&val.bank_name && val.account_number){
                    Object.assign(params, bank_obj) // 은행 정보
                //}
                

            } else {
                const bank_obj = {
                    bankaccount: {
                        payment_method: val.payment    // 지급방법 1. kr_banktransfer 2. intl_banktransfer 3. payoneer
                    }
                }

                if(val.payment == 'paypal') Object.assign(bank_obj.bankaccount, {paypal_email: val.paypal_email})
                Object.assign(params, bank_obj) // 은행 정보
            }

            let defaultUserInfo = {
                marketing: val.marketing,
                language: val.language
            }
            
            return axios.put((process.env.VUE_APP_API_URL + '/licensor/'+sr_userInfo.licensor.id), params)
            .then(({ data }) => {
                console.log(data)
                // default
                let session_userInfo = JSON.parse(localStorage.SR_UserInfo)
               
                // licensor
                Object.assign(session_userInfo, { licensor: data })
                localStorage.setItem("SR_UserInfo", JSON.stringify(session_userInfo))
                // 음악유통하기 이동 여부
                return data
            })
        },

        MYDATA({ commit }, val) {
            return axios.get((process.env.VUE_APP_API_URL + `/user/${val}`))
            .then(({ data }) => {
                console.log(data)
                
                return data
            })
        },
        PAYMENTUPDATE({ commit }, val) {
            let sr_userInfo = JSON.parse(localStorage.getItem("SR_UserInfo"))  //저장된 아이디 값

            let today = new Date();
            let params = {
               
                nationality: val.
                nationality,     // 국적
                entity: val.division,// 개인/사업자 구분
                currency: val.currency,// 정산 화폐
                given_name: val.given_name,// 성
                family_name: val.family_name,// 이름
                phone_countrycode: val.countryPhone,  // 국번
                phone: val.phone, // phone
                entity_identification_type: val.entity_identification_type,   // 주민등록번호 타입           
                entity_identification: val.idCard,  // 주민등록번호
                entity_date: moment(val.birthday).format('YYYY-MM-DD'), // 생년월일
                termsofuse: true,
                termsofuse_timestamp: today,
                
                entity_address: {
                    country: val.countryCode,
                    address_line: val.taxAddress1,
                    address_line2: val.taxAddress2,
                    district: val.taxDistrict,
                    city: val.taxCity,
                    postal_code: val.taxPostalCode
                }
            }
            // 국적관련
            if (val.nationality != '' ||val.nationality.toLowerCase() == "kr") {
                if(val.mims) Object.assign(params, { mims_account: val.mims })   // mims 가입자명
            }

            // 사업자 일경우
            if (val.division == 'corporation') {
                Object.assign(params, { company: val.companyName })   // 회사명
            }

            // 은행 국가관련
            if (val.countryCode != '' &&  val.countryCode.toLowerCase() == 'kr') {
                const bank_obj = {
                    bankaccount: {
                        /*  보류 address: {
                                country: val.countryCode,
                                address_line: val.paymentAddress1,
                                address_line2: val.paymentAddress2,
                                district: val.paymentDistrict,
                                city: val.paymentCity,
                                postal_code: val.paymentPostalCode,
                        }, */
                        payment_method: val.payment,    // 지급방법 1. kr_banktransfer 2. intl_banktransfer 3. payoneer
                        bank_name: val.bank ? val.bank.name : null,
                        bank_code: val.bank ? val.bank.code : null, 
                        /*  20200313 보류
                            bank_branch: val.bankAndBranchName, */
                        account_number: val.accountNumber ? val.accountNumber : null,
                        name: val.accountHolder ? val.accountHolder : null
                    }
                }
                //if(val.name&&val.bank_name && val.account_number){
                    Object.assign(params, bank_obj) // 은행 정보
                //}
                

            } else {

            }

            let defaultUserInfo = {
                marketing: val.marketing,
                language: val.language
            }
            params['id'] = val.id
            return axios.put((process.env.VUE_APP_API_URL + '/licensor/'+params.id), params)
            .then(({ data }) => {
                console.log(data)
                // default
                let session_userInfo = JSON.parse(localStorage.SR_UserInfo)
                session_userInfo.marketing = defaultUserInfo.marketing
                session_userInfo.language = defaultUserInfo.language
                // licensor
                Object.assign(session_userInfo, { licensor: data })
                localStorage.setItem("SR_UserInfo", JSON.stringify(session_userInfo))
                // 음악유통하기 이동 여부
                return data
            })
        },
        MYUPDATA({ commit }, val) {
            // My 페이지 저장
            let sr_userInfo = JSON.parse(localStorage.getItem("SR_UserInfo"))  //저장된 아이디 값
            let param = {
                ...val
            }
            return axios.put((process.env.VUE_APP_API_URL + '/user/' + sr_userInfo.id), param)
                .then(({ data }) => {
                let session_userInfo = JSON.parse(localStorage.SR_UserInfo)
                // session_userInfo.marketing = defaultUserInfo.marketing
                // session_userInfo.language = defaultUserInfo.language
                return data
            })
        },
        ARTIST_IMAGE1_UPLOAD({ commit }, file) {
            // 현재 사용 안함 아티스트 이미지 1
            let id = JSON.parse(localStorage.getItem("SR_UserInfo")).id
            const formData = new FormData()
            formData.append('file', file, file.name)

            return axios.post(process.env.VUE_APP_API_URL + '/artist/' + id + '/profile', formData, {
                headers: {
                    'content-Type': 'multipart/form-data'
                }
            })
                .then(({ data }) => {
                    return data
                })
                .catch(error => {
                    console.log("File Upload Fail :::", error)
                })
        },
        ARTIST_IMAGE2_UPLOAD({ commit }, file) {
            // 현재 사용 안함 아티스트 이미지 1
            let id = JSON.parse(localStorage.getItem("SR_UserInfo")).id

            const formData = new FormData()
            formData.append('file', file, file.name)

            return axios.post(process.env.VUE_APP_API_URL + '/artist/' + id + '/image1', formData, {
                headers: {
                    'content-Type': 'multipart/form-data'
                }
            })
                .then(({ data }) => {
                    return data
                })
                .catch(error => {
                    console.log("File Upload Fail :::", error)
                })
        },
        ARTIST_IMAGE3_UPLOAD({ commit }, file) {
            // 현재 사용 안함 아티스트 이미지 1
            let id = JSON.parse(localStorage.getItem("SR_UserInfo")).id

            const formData = new FormData()
            formData.append('file', file, file.name)

            return axios.post(process.env.VUE_APP_API_URL + '/artist/' + id + '/image2', formData, {
                headers: {
                    'content-Type': 'multipart/form-data'
                }
            })
                .then(({ data }) => {
                    return data
                })
                .catch(error => {
                    console.log("File Upload Fail :::", error)
                })
        },
        FILEUPLOAD({ commit }, file) {
            // 파일 업로드 Audio 파일 포함
            const formData = new FormData()
            formData.append('file', file, file.name)
            return axios.post(process.env.VUE_APP_API_URL + '/file', formData, {
                headers: {
                    'content-Type': 'multipart/form-data'
                }
            })
                .then(({ data }) => {
                    return data
                })
                .catch(error => {
                    console.log("File Upload Fail :::", error)
                })
        },
        FILEDELETE({ commit }, id) {
            return axios.delete(process.env.VUE_APP_API_URL + '/file/' + id)
                .then(({ data }) => console.log('File Delete Success!'))
                .catch(error => {
                    console.log("FIle Delete Fail :::", error)
                })
        },
        GETFILE({ commit }, id) {
            return axios.get(process.env.VUE_APP_API_URL + '/file/' + id)
                .then(({ data }) => {
                    return data
                })
                .catch(error => {
                    console.log("Get File Fail :::", error)
                })
        },
        DELETE_ONLY_FILE({ commit }, payload) {
            return axios.get(process.env.VUE_APP_API_URL + '/file/' + payload.id)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                console.log("Get File Fail :::", error)
            })
        },
        PASSWORDCHANGE({ commit }, pwChangeForm) {
            let userInfo = JSON.parse(localStorage.getItem('SR_UserInfo'))
            return axios.put((process.env.VUE_APP_API_URL + '/user/' + userInfo.id + '/password'), pwChangeForm)
                .then(({ data }) => {
                })
        },
        LOADARTIST({ commit }, {
            sort, sortorder, page, column, limit
        }) {

            if (!sort) sort = "created_at"
            if (!sortorder) sortorder = "ASC"
            if(sort == "created_at"){sortorder = "DESC"}
            if (!page) page = 0
            if (!limit) limit = 20
            if (!column) {
                // 등록된 아티스트 리스트
                return axios.get(process.env.VUE_APP_API_URL + "/artist", {
                    params: {
                        sort: sort,             // 최신 등록 순 updated_at , 이름순 display_name
                        sortorder: sortorder,   // DESC , ASC 순서
                        page: page,             // 페이지
                        limit: limit            // 제한 갯수
                    }
                })
                    .then(({ data }) => {
                        commit('ARTISTS', data.items)
                        return data
                    })
            } else {
                // 등록된 아티스트 리스트
                return axios.get(process.env.VUE_APP_API_URL + "/artist", {
                    params: {
                        sort: sort,            // 최신 등록 순 updated_at , 이름순 display_name
                        sortorder: sortorder,  // DESC , ASC 순서
                        page: page,            // 페이지
                        display_name: column,   // 검색용
                        limit: limit            // 제한 갯수
                    }
                })
                    .then(({ data }) => {
                        return data
                    })
            }
        },
        LOADGENRE({ commit }, {
            sort, sortorder, page, column
        }) {
            if (!sort) sort = "created_at"
            if (!sortorder) sortorder = "DESC"
            if (!page) page = 0
            if (!column) {
                // 등록된 아티스트 리스트
                return axios.get(process.env.VUE_APP_API_URL + "/genre", {
                    params: {
                       
                    }
                })
                    .then(({ data }) => {
                        commit('GENRES', data.items)
                        return data
                    })
            } else {
                let params = {
                    sort: sort,            // 최신 등록 순 updated_at , 이름순 display_name
                    sortorder: sortorder,  // DESC , ASC 순서
                    page: page            // 페이지
                }
                Object.assign(params, column)

                // 등록된 아티스트 리스트
                return axios.get(process.env.VUE_APP_API_URL + "/genre",
                    { params }
                )
                    .then(({ data }) => {
                        commit('GENRES', data.items)
                        return data
                    })
            }
        },
        GETGENRE({ commit }, id) {
            // 아티스트 정보가져오기
            return axios.get(process.env.VUE_APP_API_URL + "/genre/" + id)
                .then(({ data }) => {
                    return data
                })
        },
        LOAD_ALBUM({ commit }, id) {
            // 단일 앨범 정보 가져오기
            let album_id = id
            // 등록된 앨범 리스트
            return axios.get(process.env.VUE_APP_API_URL + "/album/" + album_id)
                .then(({ data }) => {
                return data
            })
        },
        LOAD_ALBUM_LINK({ commit }, id) {
            // 단일 앨범 정보 가져오기
            let album_id = id
            // 등록된 앨범 리스트
            return axios.get(process.env.VUE_APP_API_URL + "/album/" + album_id + '/link')
                .then(({ data }) => {
                return data
            })
        },
        LOAD_ALBUMLIST({ commit }, payload) {
            enhanceAccessToeken()
            // 등록된 앨범 리스트
            return axios.get(process.env.VUE_APP_API_URL + "/album", { params : payload,
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: "repeat"})
                } 
             })
                .then(({ data }) => {
                    commit('ALBUMDATA', data)
                    return data
                })
        },
        POST_ALBUM({ commit }, data) {
            // 앨범 삭제
            return axios.post(process.env.VUE_APP_API_URL + "/album/",data).then(({ data }) => {
                return data
            })
        },
        DELETE_ALBUM({ commit }, id) {
            // 앨범 삭제
            return axios.delete(process.env.VUE_APP_API_URL + "/album/" + id)
                .then(({ data }) => {
                    console.log("Album Delete Success")
                }).catch(error => {
                    console.log("Album Delete Fail:::", error)
                })
        },
        LOAD_ARTISTLIST({ commit }, payload) {
           
                // 등록된 앨범 리스트
                return axios.get(process.env.VUE_APP_API_URL + "/artist", {
                    params: {
                       ...payload,
                       is_various_artists: false,
                    }
                })
                    .then(({ data }) => {
                        commit('ARTISTDATA', data)
                        return data
                    })
           
        },
        ALL_ARTISTLIST({ commit }, {
            sort, sortorder
        }) {
            if (!sort) sort = "created_at"
            if (!sortorder) sortorder = "DESC"

            // 등록된 앨범 리스트
            return axios.get(process.env.VUE_APP_API_URL + "/artist", {
                params: {
                    sort: sort,             // 최신 등록 순 updated_at , 이름순 display_name
                    sortorder: sortorder    // DESC , ASC 순서
                }
            })
                .then(({ data }) => {
                    commit('ARTISTDATA', data)
                    return data
                })

        },
        LOAD_ARTIST_ALBUM({ commit }, id) {
            // 해당 아티스트 앨범 조회
            if (id) {
                return axios.get(process.env.VUE_APP_API_URL + "/artist/" + id + "/albums")
                    .then(({ data }) => {
                        return data
                    })
            }
        },
        SAVEALBUM({ commit }, params) {
            return axios.post(process.env.VUE_APP_API_URL + "/album", params)
                .then(({ data }) => {
                    return data
                })
        },
        UPDATEALBUM({ commit }, { id, params }) {
            return axios.put(process.env.VUE_APP_API_URL + "/album/" + id, params)
                .then(({ data }) => {
                    return data
                })
        },
        DISTRIBUTION_CANCEL({ commit }, id) {
            // 앨범 유통 취소 요청
            return axios.put(process.env.VUE_APP_API_URL + "/album/" + id + "/cancel")
                .then(({ data }) => {
                    return data
                })
        },
        LOAD_TRACK({ commit }, data) {
            return axios.get(process.env.VUE_APP_API_URL + "/album/" + data.id + "/tracks/"+ data.track_id )
            .then(({ data }) => {
                return data
            })
        },
        SAVETRACK({ commit }, data) {
            return axios.post(process.env.VUE_APP_API_URL + "/album/" +data.id + "/tracks", data.params)
            .then(({ data }) => {
                return data
            })
        },
       
        UPDATETRACK({ commit }, data) {
            // 트랙 업데이트
            return axios.put(process.env.VUE_APP_API_URL + "/album/" + data.id + "/tracks/"+data.track_id, data.params)
            .then(({ data }) => {
                return data
            })
        },
        DELETETRACK({ commit }, data) {
            // 트랙 삭제
            return axios.delete(process.env.VUE_APP_API_URL + "/album/" + data.id + "/tracks/"+ data.track_id )
            .then(({ data }) => {
                return data
            })
        },
        DELETETRACKFILE ({ commit }, data) {
            //  트랙 파일 삭제
            return axios.delete(process.env.VUE_APP_API_URL + "/album/" + data.id + "/tracks/"+ data.track_id+"/file/"+data.file_id )
            .then(({ data }) => {
                return data
            })
        },
        DELETE_DOLBY_FILE({ commit }, payload) {
            //  트랙 파일 삭제
            return axios.delete(process.env.VUE_APP_API_URL + `/album/${payload.album_id}/tracks/${payload.track_id}/dolbyatmos` )
            .then(({ data }) => {
                return data
            })
        }, 
        ARTIST_REGISTER({ commit }, params) {
            // 아티스트 신규 등록
            return axios.post(process.env.VUE_APP_API_URL + "/artist", params)
        },
        DATETIMECROP({ commit }, param) {
            if (param.indexOf('T') > -1) {
                let text_array = param.split("T")
                return text_array[0]
            }
        },
        ISNUMBER({ commit }, param) {
            // Number 여부 체크
            param += ''; // 문자열로 변환
            param = param.replace(/^\s*|\s*$/g, ''); // 좌우 공백 제거
            if (param == '' || isNaN(param)) return false;
            return true;
        },
        COUNTRYCODE({ commit }) {
            // 접속된 국가 코드 찾기
            delete axios.defaults.headers.common['Authorization']
            return axios.get('https://extreme-ip-lookup.com/json')
                .then(res => {
                    commit("COUNTRYCODE", res.data.countryCode)
                    enhanceAccessToeken()
                    return res.data.countryCode
                })
        },
        REQUEST_WITHDRAWAL({ commit }, { amount }) {
            // 출금신청하기
            return axios.post(process.env.VUE_APP_API_URL + "/withdrawal/", { amount })
                .then(({ data }) => {
                    return data
                })
        },
        ISOBJECTEMPTY({ commit }, param) {
            // Check Object Empty Value
            let keys = Object.keys(param)
            let length = keys.length
            if (length > 0)
                return false
            else return true
        },
        PAGE_POSITION({ commit }, param) {
            localStorage.setItem("SR_page_position", param)
        },
        // method by jay
        // 정산 > summary 데이터 요청 
        LOAD_SALELINES_SUM({ commit }, {
            search
        }) {
            enhanceAccessToeken()
            let params = {
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYY-MM-DD')
                params["end_month"] = moment(search.end_month).format('YYYY-MM-DD')
            }
            if (search.musicstore) {
                params["musicstore"] = search.musicstore
            }
            if (search.country) {
                params["country"] = search.country.toLowerCase()
            }
            if (search.service_type == "streaming") {
                params["service_type"] = "ST"
            } else if (search.service_type == "download") {
                params["service_type"] = "DL"
            } else if (search.service_type == "other") {
                params["service_type"] = "OTHER"
            }
            if (search.keyword) {
                params["search_type"] = search.search_type
                params["keyword"] = search.keyword
            }

            return axios.get(process.env.VUE_APP_API_URL + "/salesline/sum", { params })
                .then(({ data }) => {
                    return data
                })

        },
        // 정산 상세 내역 
        LOAD_SALELINES_LIST({ commit }, payload) {
            enhanceAccessToeken()
            let params = {
                ...payload
            }   
            return axios.get(process.env.VUE_APP_API_URL + "/salesline", { params })
            .then(({ data }) => {
                return data
            })

        },
        // 정산 상세 내역 엑셀 
        GET_SALELINES_EXCEL({ commit }, payload) {
            enhanceAccessToeken()
            // if (!sort) sort = "created_at"
            // if (!sortorder) sortorder = "DESC"
            let params = {
                ...payload
            }   // parameter Default

            // if (search.start_month || search.end_month) {
            //     params["date_type"] = search.date_type
            //     params["start_month"] = moment(search.start_month).format('YYYY-MM-DD')
            //     params["end_month"] = moment(search.end_month).format('YYYY-MM-DD')
            // }
            // if (search.musicstore) {
            //     params["musicstore"] = search.musicstore
            // }
            // if (search.country) {
            //     params["country"] = search.country.toLowerCase()
            // }
            // if (search.service_type == "streaming") {
            //     params["service_type"] = "ST"
            // } else if (search.service_type == "download") {
            //     params["service_type"] = "DL"
            // } else if (search.service_type == "other") {
            //     params["service_type"] = "OTHER"
            // }
            // if (search.keyword) {
            //     params["search_type"] = search.search_type
            //     params["keyword"] = search.keyword
            // }
            // params["user_language"] = this.state.languageCode

            return axios.post(process.env.VUE_APP_API_URL + "/salesline/excel", params, { responseType: 'blob' })
                .then((data) => {
                return data
            })
        },
        // 통계 > 월별 판매 내역 엑셀 
        GET_STATS_MONTHLY_EXCEL({ commit }, {
            sort, sortorder, search
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "stats_month"
            if (!sortorder) sortorder = "DESC"
            let params = {
                sort: sort,             // 최신 등록 순 updated_at , 이름순 display_name
                sortorder: sortorder   // DESC , ASC 순서
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYYMM')
                params["end_month"] = moment(search.end_month).format('YYYYMM')
            }
            params["user_language"] = this.state.languageCode

            return axios.post(process.env.VUE_APP_API_URL + "/salesline/stats-monthly/excel", params, { responseType: 'blob' })
                .then((data) => {
                    return data
                })
        },
        // 통계 > 월별 판매 내역  
        LOAD_STATS_MONTHLY({ commit }, {
            sort, sortorder, page, limit, search
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "title"
            if (!sortorder) sortorder = "ASC"
            if (!page) page = 0
            if (!limit) limit = 12
            let params = {
                sort: sort,
                sortorder: sortorder,
                page: page,
                limit: limit
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYYMM')
                params["end_month"] = moment(search.end_month).format('YYYYMM')
            }

            return axios.get(process.env.VUE_APP_API_URL + "/salesline/stats-monthly", { params })
                .then(({ data }) => {
                    return data
                })

        },
        // 통계 > 뮤직스토어 판매 상세 엑셀 
        GET_STATS_STORE_EXCEL({ commit }, {
            sort, sortorder, search
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "total_amount"
            if (!sortorder) sortorder = "DESC"
            let params = {
                sort: sort,             // 최신 등록 순 updated_at , 이름순 display_name
                sortorder: sortorder    // DESC , ASC 순서
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYYMM')
                params["end_month"] = moment(search.end_month).format('YYYYMM')
            }
            params["user_language"] = this.state.languageCode

            return axios.post(process.env.VUE_APP_API_URL + "/salesline/stats-store/excel", params, { responseType: 'blob' })
                .then((data) => {
                    return data
                })
        },
        // 통계 > 뮤직스토어 판매 상세  
        LOAD_STATS_STORE({ commit }, payload) {
            enhanceAccessToeken()
            let params = {
                ...payload
            }   // parameter Default
            return axios.get(process.env.VUE_APP_API_URL + "/salesline/stats-store", { params })
                .then(({ data }) => {
                    return data
                })

        },
        // 통계 > 국가별 판매 상세 엑셀 
        GET_STATS_COUNTRY_EXCEL({ commit }, {
            sort, sortorder, search
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "total_amount"
            if (!sortorder) sortorder = "DESC"
            let params = {
                sort: sort,             // 최신 등록 순 updated_at , 이름순 display_name
                sortorder: sortorder    // DESC , ASC 순서
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYYMM')
                params["end_month"] = moment(search.end_month).format('YYYYMM')
            }
            params["user_language"] = this.state.languageCode

            return axios.post(process.env.VUE_APP_API_URL + "/salesline/stats-country/excel", params, { responseType: 'blob' })
                .then((data) => {
                    return data
                })
        },
        // 통계 > 국가별 판매 상세 
        LOAD_STATS_COUNTRY({ commit }, payload) {
            enhanceAccessToeken()
           
            let params = {
                ...payload
            }   // parameter Default

            return axios.get(process.env.VUE_APP_API_URL + "/salesline/stats-country", { params })
            .then(({ data }) => {
                return data
            })

        },
        // 통계 > 트랙별 판매 상세
        LOAD_STATS_TRACK({ commit }, {
            sort, sortorder, page, limit, search, get_sub
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "total_amount"
            if (!sortorder) sortorder = "DESC"
            if (!page) page = 0
            if (!limit) limit = 20
            let params = {
                sort: sort,             // 최신 등록 순 updated_at , 이름순 display_name
                sortorder: sortorder,   // DESC , ASC 순서
                page: page,             // 페이지
                limit: limit            // LIst item loading 갯수 제한
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYYMM')
                params["end_month"] = moment(search.end_month).format('YYYYMM')
            }
            if (get_sub) {
                params["get_sub"] = true
            }

            return axios.get(process.env.VUE_APP_API_URL + "/salesline/stats-track", { params })
                .then(({ data }) => {
                    return data
                })

        },
        // 통계 > 앨범/트랙별 판매 상세 엑셀
        GET_STATS_ALBUM_EXCEL({ commit }, {
            sort, sortorder, search
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "total_amount"
            if (!sortorder) sortorder = "DESC"
            let params = {
                sort: sort,             // 최신 등록 순 updated_at , 이름순 display_name
                sortorder: sortorder,   // DESC , ASC 순서
                get_track: true
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYYMM')
                params["end_month"] = moment(search.end_month).format('YYYYMM')
            }
            params["user_language"] = this.state.languageCode

            return axios.post(process.env.VUE_APP_API_URL + "/salesline/stats-album/excel", params, { responseType: 'blob' })
                .then((data) => {
                    return data
                })
        },
        // 통계 > 앨범/트랙별 판매 상세
        LOAD_STATS_ALBUM({ commit }, payload) {
            enhanceAccessToeken()
            
            let params = {
                ...payload
            }
            return axios.get(process.env.VUE_APP_API_URL + "/salesline/stats-album", { params })
                .then(({ data }) => {
                    return data
                })

        },
        // 통계 > 아티스트별 판매 상세 엑셀
        GET_STATS_ARTIST_EXCEL({ commit }, {
            sort, sortorder, search
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "total_amount"
            if (!sortorder) sortorder = "DESC"
            let params = {
                sort: sort,             // 최신 등록 순 updated_at , 이름순 display_name
                sortorder: sortorder   // DESC , ASC 순서
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYYMM')
                params["end_month"] = moment(search.end_month).format('YYYYMM')
            }
            params["user_language"] = this.state.languageCode

            return axios.post(process.env.VUE_APP_API_URL + "/salesline/stats-artist/excel", params, { responseType: 'blob' })
                .then((data) => {
                    return data
                })
        },
        // 통계 > 아티스트별 판매 상세 
        LOAD_STATS_ARTIST({ commit }, payload) {
            enhanceAccessToeken()
          
            let params = {
              ...payload
            }   // parameter Default
 
            return axios.get(process.env.VUE_APP_API_URL + "/salesline/stats-artist", { params })
                .then(({ data }) => {
                    return data
                })

        },
        // 통계 > 판매 top5 데이터
        LOAD_STATS_POPULAR({ commit }, {
            search, type
        }) {
            enhanceAccessToeken()
            let params = {
            }   // parameter Default

            if (search.start_month || search.end_month) {
                params["date_type"] = search.date_type
                params["start_month"] = moment(search.start_month).format('YYYYMM')
                params["end_month"] = moment(search.end_month).format('YYYYMM')
            }

            return axios.get(process.env.VUE_APP_API_URL + "/salesline/stats-" + type + "/popular", { params })
                .then(({ data }) => {
                    return data
                })

        },
        //현재 user 정보 가져오기
        GET_USER_INFO({ commit }) {
            let userInfo = JSON.parse(localStorage.getItem('SR_UserInfo'))
            return axios.get(process.env.VUE_APP_API_URL + '/user/' + userInfo.id)
                .then(({ data }) => {
                    return data
                })
        },
        // 최근 출금신청 내역  
        LOAD_WITHDRAWAL_LIST({ commit }, payload) {
            enhanceAccessToeken()
            const sort = "created_at"
            const sortorder = "DESC"
            const page = 0
            const limit = 10
            let params = {
                ...payload
            }
            let userInfo = JSON.parse(localStorage.getItem('SR_UserInfo'))

            return axios.get(process.env.VUE_APP_API_URL + '/user/' + userInfo.id + '/withdrawal', { params })
                .then(({ data }) => {
                    return data
                })
        },
        // 정산금 변동 내역 
        LOAD_TRANSACTION_LIST({ commit }, {
            sort, sortorder, page, limit, type
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "id"
            if (!sortorder) sortorder = "DESC"
            if (!page) page = 0
            if (!limit) limit = 30
            let params = {
                sort: sort,
                sortorder: sortorder,
                page: page,
                limit: limit
            }
            if (type != 'all') params.type = type
            
            const userInfo = JSON.parse(localStorage.getItem('SR_UserInfo'))

            return axios.get(process.env.VUE_APP_API_URL + '/user/' + userInfo.id + '/transaction', { params })
                .then(({ data }) => {
                    return data
                })
        },
        // 정산금 변동 내역 엑셀
        GET_TRANSACTION_EXCEL({ commit }, {
            sort, sortorder, page, limit, type
        }) {
            enhanceAccessToeken()
            if (!sort) sort = "id"
            if (!sortorder) sortorder = "DESC"
            if (!page) page = 0
            if (!limit) limit = 0
            let params = {
                sort: sort,
                sortorder: sortorder,
                page: page,
                limit: limit
            }
            if (type != 'all') {
                params.type = type
            }
            params["user_language"] = this.state.languageCode
            let userInfo = JSON.parse(localStorage.getItem('SR_UserInfo'))

            return axios.post(process.env.VUE_APP_API_URL + '/user/' + userInfo.id + '/transaction/excel', params, { responseType: 'blob' })
                .then((data) => {
                    return data
                })
        },
        // method by jay end
        TEXTAREAIMPL({ commit }, param) {
            let str = param
            //str = str.replace(/(?:\r\n|\r|\n)/g, '<br/>')
            return str
        },
        PAYONEER_REGISTRATION_LINK({ commit }, param) {
            Object.assign(param, { language_id: i18n.locale == 'ko'? 15 : 1 })
        
            return axios.post(process.env.VUE_APP_API_URL+"/payoneer/registration-link", param)
                .then(({ data }) => {
                    return data
                })
        },
        PAYONEER_STATUS({commit}, param) {
            return axios.get(process.env.VUE_APP_API_URL+"/payoneer/status", {
                params: {payee_id: param.payee_id}
            })
            .then(({data}) => {
                return data
            })
        },

        
        APPLICATION_POST({ commit }, param) {
            return axios.post(process.env.VUE_APP_API_URL + '/application/my', param)
            .then(({ data }) => {
                return data
            })
        },
        APPLICATION_DATE({commit}, param) {
            return axios.get(process.env.VUE_APP_API_URL+"/calendar/month/unavailable", {
                params: param
            })
            .then(({data}) => {
                return data
            })
        }
        
    }
})