<template>
    <div class="container">
        <div class="subcont_w">
            <div class="subcont_in">
                <div class="sbc_sec_item">
                    <!-- sbc_tit_low -->
                    <div class="sbc_tit_low">
                        <div class="sbc_tit_tb">
                            <div class="sbc_tit_td">
                                <h3 class="sbc_tit"><span class="sbc_tit_tx">{{$t('apply.title')}}</span></h3>
                            </div>
                            
                        </div>
                        <!-- <button type="button" class="btn_sbctog"><span class="hdtext">열기/닫기</span></button> -->
                    </div>
                    <!-- // sbc_tit_low -->
                    <!-- sbc_cont_low -->
                    <!-- <div id="add_etc" class="sbc_cont_low" style="display:none;"> -->
                    <div   class="sbc_cont_low" >
                        <div class="sbc_cont_low_in">
                            
                            <ul class="stc_list">
                                <li>
                                    <div class="sct_tit_w">
                                        <p class="sct_tit w100per">{{$t('apply.desired_date')}}<span class="redpoint"> *</span></p>
                                       
                                    </div>
                                    <div class="sct_cont_w">
                                        <date-picker :placeholder="$t('distributionMusic.props.date')" v-model="dataTemplate.desired_release_date" @open="monthData()"  @calendar-change="monthData"  :disabled-date="disabledBeforeTodayAndAfterAWeek" :editable="false" :clearable="true"></date-picker>

                                        <div style="display: inline-block; margin-top: 10px; ">
                                            <span style="font-size: 15px; margin-left: 10px; vertical-align: middle;" v-if="$i18n.locale=='ko' ">시간</span>
                                            <span style="font-size: 15px; margin-left: 10px; vertical-align: middle;" v-else>Time</span>
                                            <span style="font-size: 15px; margin-left: 10px; display: inline-block;">
                                                <input type="radio" name="nat" :id="'time1'" class="" value="12:00:00" v-model="dataTemplate.desired_release_time">
                                                <label for="time1" class="  ico01"><span class="noritem_lab_insp">12:00</span></label>
                                            </span>
                                            <span style="font-size: 15px; margin-left: 10px; display: inline-block;">
                                                <input type="radio" name="nat" :id="'time2'" class="" value="18:00:00" v-model="dataTemplate.desired_release_time">
                                                <label for="time2" class="  ico01"><span class="noritem_lab_insp">18:00</span></label>
                                            </span>
                                        </div>
                                    </div>
                                    <p class="sct_tit_sub mt10"  v-if="$i18n.locale=='ko'" style="font-size: 15px; line-height: 1.6; word-break: keep-all;">
                                        - 신청하신 발매 희망일 및 시간은 담당자 확인 후 변경될 수 있습니다. <br>
                                        - 원활한 음원 발매 준비를 위하여, 신청 당일로부터 14일(2주) 까지는 발매 희망일로 지정하실 수 없습니다. <br>
                                        - 발매일(시)의 변경이 필요할 경우, 사전에 신청자의 이메일 또는 유선상으로 변경 가능한 발매일(시)에 대한 안내를 드립니다.
                                    </p>
                                    <p class="sct_tit_sub mt10"  v-else> 
                                        - The requested release date and time are subject to change after confirmation by Sound Republica.<br>
                                        - In order to prepare for the smooth release of your music, you cannot specify the desired release date until 14 days (2 weeks) from the date of application. <br>
                                        - If the release date needs to be changed, we will notify the applicant via email or phone in advance of the possible release date.
                                    </p>
                                    <!-- <p class="sct_tit_sub mt10" v-html="$t('apply.desired_date.msg')"></p> -->
                                </li>
                                <li>
                                    <div class="sct_tit_w">
                                        <p class="sct_tit mr10 w100per">{{$t('apply.ready_date')}} <span class="redpoint"> *</span></p>
                                        
                                    </div>
                                    <div class="sct_cont_w">
                                        <date-picker :placeholder="$t('distributionMusic.props.date')" v-model="dataTemplate.ready_date"    :custom-formatter="fixDate"  :editable="false" :clearable="true" :disabled-date="dataDay"></date-picker>
                                        <p class="sct_tit_sub mt10"  v-if="$i18n.locale=='ko'" style="font-size: 15px; line-height: 1.6; word-break: keep-all;">
                                            발매 자료 완성일은 요청하신 발매 희망일에 발매가 불가능할 경우 발매일을 제안하기 위해 참고하는 용도로만 사용하고 있으며, <br>
                                            실제 발매 자료 업로드는 발매일로부터 최소 2주 전까지 완료되어야 합니다.
                                        </p>
                                        <p class="sct_tit_sub mt10"  v-else> 
                                            The "release materials completion date" is only used as a guide to suggest a release date if the requested release date is not available. <br>
                                            The actual release materials must be uploaded two weeks prior to the release date.
                                        </p>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="sct_tit_w">
                                        <p class="sct_tit mr10 w100per">{{$t('apply.album_title')}} <span class="redpoint"> *</span></p>
                                        <div class="sct_cont_w">
                                            <div class="input_fitem_w">
                                                <input :placeholder="$t('apply.album_title.msg')" type="text" class="input_fitem"  id="barcode" v-model="dataTemplate.album_title" >
                                            </div>
                                        </div>
                                    </div>
                                    
                                </li>

                                <li>
                                    <div class="sct_tit_w">
                                        <p class="sct_tit mr10 w100per">{{$t('apply.album_artist')}} <span class="redpoint"> *</span></p>
                                        <div class="sct_cont_w">
                                            <div class="">
                                                <textarea :placeholder="$t('apply.album_artist.msg')" style="width:100%; font-size: 15px;" v-model="dataTemplate.album_artist" name="" id="" cols="30" rows="5" maxlength="2000"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="sct_tit_w">
                                        <p class="sct_tit mr10 w100per">{{$t('apply.url_artist')}} </p>
                                        <div class="sct_cont_w">
                                            <textarea :placeholder="$t('apply.url_artist.msg')" style="width:100%; font-size: 15px;" v-model="dataTemplate.url_artist" name="" id="" cols="30" rows="5" maxlength="2000"></textarea>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="sct_tit_w">
                                        <p class="sct_tit mr10 w100per">{{$t('apply.url_file')}} <span class="redpoint"> *</span></p>
                                        
                                    </div>
                                    <div class="sct_cont_w">
                                        <div class="input_fitem_w">
                                            <input :placeholder="$t('apply.url_file.msg')" type="text" class="input_fitem"  id="barcode" v-model="dataTemplate.url_demo" >
                                        </div>
                                        <p class="sct_tit_sub mt10 redpoint"  v-if="$i18n.locale=='ko'" style="font-size: 15px; line-height: 1.6; word-break: keep-all;">
                                            <svg data-v-7250af77="" viewBox="0 0 64 64" id="icon-danger" xmlns="http://www.w3.org/2000/svg" style="width: 20px; vertical-align: sub; fill: #cc3232;"><path data-v-7250af77="" d="M31.942 50.885a2.942 2.942 0 1 1 0-5.885 2.942 2.942 0 0 1 0 5.885zm-.322-11.252a1.62 1.62 0 0 1-1.62-1.62V22.62a1.62 1.62 0 0 1 3.24 0v15.393a1.62 1.62 0 0 1-1.62 1.62zM30.69 3.272a1.5 1.5 0 0 1 2.622 0l30 54A1.5 1.5 0 0 1 62 59.5H2a1.5 1.5 0 0 1-1.311-2.228l30-54zM32 7.089L4.55 56.5h54.9L32 7.089z"></path></svg>
                                            음원발매 신청서에 음원(or 데모)를 첨부하지 않으실 경우 발매 검토 대상에서 제외됩니다.
                                        </p>
                                        <p class="sct_tit_sub mt10 redpoint"  v-else> 
                                            <svg data-v-7250af77="" viewBox="0 0 64 64" id="icon-danger" xmlns="http://www.w3.org/2000/svg" style="width: 20px; vertical-align: sub; fill: #cc3232;"><path data-v-7250af77="" d="M31.942 50.885a2.942 2.942 0 1 1 0-5.885 2.942 2.942 0 0 1 0 5.885zm-.322-11.252a1.62 1.62 0 0 1-1.62-1.62V22.62a1.62 1.62 0 0 1 3.24 0v15.393a1.62 1.62 0 0 1-1.62 1.62zM30.69 3.272a1.5 1.5 0 0 1 2.622 0l30 54A1.5 1.5 0 0 1 62 59.5H2a1.5 1.5 0 0 1-1.311-2.228l30-54zM32 7.089L4.55 56.5h54.9L32 7.089z"></path></svg>
                                            In order for your application to be considered for release, please attach a recording (or demo) to this form.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="sct_tit_w">
                                        <p class="sct_tit mr10 ">{{$t('apply.has_mv')}} <span class="redpoint"> *</span></p>
                                        <div style="display: inline-block; margin-top:10px;">
                                            <div class="squbox_item " >
                                                <input type="radio" class="squbox_rdchk noritem" name="while" id="while01" :value="true" v-model="dataTemplate.has_mv"  >
                                                <label for="while01" class="squbox_lab">Yes</label>
                                            </div>
                                            <div class="squbox_item" >
                                                <input type="radio" class="squbox_rdchk noritem" name="while" id="while02" :value="false" v-model="dataTemplate.has_mv"  >
                                                <label for="while02" class="squbox_lab">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </li>
                                
                                <li>
                                    <div class="sct_tit_w">
                                        <p class="sct_tit" style="max-width: 100%;">{{$t('apply.remark')}}</p>
                                        
                                    </div>
                                    <div class="sct_cont_w">
                                        <textarea :placeholder="$t('apply.remark.msg')" style="width:100%; font-size: 15px;" v-model="dataTemplate.remark" name="" id="" cols="30" rows="5" maxlength="2000"></textarea>
                                    </div>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                    <!-- // sbc_cont_low -->
                    <div class="fsm_line"></div>
                    <!-- btn_fsm_w -->
                    <div class="btn_fsm_w">
                        <div class="btn_fsm_fl">
                            <router-link to="/album"><button type="button" class="btn_bsm"><span class="btn_bsm_in">{{$t('cancel')}}</span></button></router-link>
                            <!-- 20200320 삭제 버튼 보류 <button v-if="deleteBtn_view" type="button" class="btn_bsm"><span class="btn_bsm_in">{{$t('distributionMusic.label.deleteBtn')}}</span></button> -->
                            <!-- <button v-if="temporaryBtn_view" type="button" class="btn_bsm type2" @click="update_temporary"><span class="btn_bsm_in">{{$t('distributionMusic.label.temporaryBtn')}}업데이트</span></button> -->
                            <!-- 20200617 임시저장문제로 임시 보류<button type="button" class="btn_bsm type2" @click="onsubmit_temporary"><span class="btn_bsm_in">{{$t('distributionMusic.label.temporaryBtn')}}</span></button> -->
                        </div>
                        <div class="btn_fsm_fr">
                            <a href="javascript:void(0)" class="btn_bsm type3" @click="saveData">  <span class="btn_bsm_in">{{$t('apply.apply')}}</span></a>
                        </div>
                    </div>
                    <!-- // btn_fsm_w -->
                </div>
            </div>
            
        </div>
        
        
    </div>
</template>

<script>
import moment from 'moment'


export default {
    data() {
        return {
           
            dataTemplate: {
                "desired_release_date": null,
                "desired_release_time": "12:00:00",
                "album_title": null,
                "album_artist": null,

                "url_artist": null,
                "url_demo": null,
                "has_mv": true,
                
                "remark": "",
                "ready_date" : null,
            },
            disabledDate: []
        }
    },
     
    watch: {
         
    },
    created(){
       
    },
    mounted() {
        this.$nextTick(function () {
             
            const today = new Date();
            this.monthData(today)
            // 유통정보, 세금정보, 지급정보 미입력시
            const session_userInfo = JSON.parse(localStorage.SR_UserInfo)
            if(session_userInfo.hasOwnProperty('licensor')){
                if(!session_userInfo.licensor) {
                    this.popupOpen('albumList_guide',"none_licensor")
                }
            }
            
        })
    },
     
    methods: {
        
        disabledBeforeTodayAndAfterAWeek(data) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            let dateform = moment(new Date(data)).format('YYYY-MM-DD')
           
            if(data < new Date(today.getTime() + 14 * 24 * 3600 * 1000) ){
                return true
            }else if( this.disabledDate.indexOf(dateform) > -1){
                return true
               
            }else {
                return false
            }
            
        },
        dataDay(data){
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if(data < new Date(today.getTime() ) ){
                return true
            } else {
                return false
            }
        },
        monthData(data){
            let date1 =  new Date();
            if(data){
                date1 = data
            }else{
                if(this.dataTemplate.desired_release_date){
                    date1 = this.dataTemplate.desired_release_date
                }
            }
            console.log(date1)
            
            let date = moment(new Date(date1)).format('YYYY-MM-01')
            let from_date = moment(date).startOf('week').format('YYYY-MM-DD');
            let payload = {
                start :from_date
            }
            this.$store.dispatch("APPLICATION_DATE", payload)
            .then(result =>{
               this.disabledDate = result
            }).catch(error =>{
            })
             
        },
        saveData(){
            let payload ={
                ...this.dataTemplate
            }
            payload.desired_release_date = this.fixDate(payload.desired_release_date)
            payload.ready_date = this.fixDate(payload.ready_date)
            let check = ['desired_release_date','ready_date','album_title','album_artist','url_demo']
            
            for(let key in check){
                
                if(this.valCheck(payload[check[key]])){
                    
                    this.alert({msg: this.$t('apply.error.enter')})
                    return false
                }
            }
            
            this.confirm({
                title : ` ${this.$i18n.t('confirm')}`,
                msg : `
                    ${this.$i18n.locale == 'ko' ? '음원발매 신청서를 제출 하시겠습니까?' : 'Would you like to submit an application?'}
                `,
                autofocus : true,
                width:400,
                action : ()=>{
                    this.loadingShow()
                    this.$store.dispatch("APPLICATION_POST", payload)
                    .then(result =>{
                        this.loadingHide()
                        this.$router.push('/album/application/complete')
                    }).catch(error =>{
                    })
                }
            })
            
        },
    }
}
</script>   