<template src="./template.html">
</template>

<script>

export default {
    name: 'customerSerivce',
    data(){
        return {
            description: ' 고객센터 페이지 입니다.',
            viewChange: "notice",           // notice : 공지사항 ,  qna: 1대1 문의하기, faq: 자주 묻는 질문
            message:  {
                question : '',
                answer: '',
            }
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$EventBus.$emit("navi", "page_clear",'/')
        next()
    },
    mounted() {
      this.$nextTick(function () {
        // 모든 화면이 렌더링된 후 실행합니다.
       
      })
    },
    methods: {
        tab_language_active(event){
            let target = event.target
            if(target.closest("#asTab01")) {
                let parentNode = target.closest("#asTab01")
                if(parentNode != "active") parentNode.classList.add('active')
                this.viewChange = "notice"
                document.getElementById('asTab02').classList.remove('active')
                document.getElementById('asTab03').classList.remove('active')
            }else if(target.closest("#asTab02")) {
                let parentNode = target.closest("#asTab02")
                if(parentNode != "active") parentNode.classList.add('active')
                this.viewChange = "qna"
                document.getElementById('asTab01').classList.remove('active')
                document.getElementById('asTab03').classList.remove('active')
            }else if(target.closest("#asTab03")) {
                let parentNode = target.closest("#asTab03")
                if(parentNode != "active") parentNode.classList.add('active')
                this.viewChange = "faq"
                document.getElementById('asTab01').classList.remove('active')
                document.getElementById('asTab02').classList.remove('active')
            }
        },
        detailView(type,index){
            const customerService = this.$store.state.customerService[this.viewChange]
            let message = customerService[index]
            this.message = message
            this.$forceUpdate()
            this.openModal(type);
        },
        showPanel21(message) {
            // const panel = this.$showPanel({
            //     component: "panel-21",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {
            //         message: message
            //     }
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-21",
                props: {
                     message: message
                }
            },()=>{
               
            });
        }
    }
}
</script>


<style scoped>

.tab_active{
    cursor: pointer;
}

/* [S] Title Animation */
.customer .sv_z .bg{
  background-image:url('../../assets/images/sv_cus.png');
  background-repeat:no-repeat;
  background-position:50% 0;
  transform: scale(1.6);
  background-size:cover;
  transition:1.2s;
  display:block;
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
}

.sv_z .sv_copy{
  transition-delay: .1s; 
  transition-duration: .8s;
  transform: translateX(-80px);
  opacity:0;
  filter:alpha(opacity=100);
}

.sv_z .sv_copy2{
  transition-delay: .7s;
  transition-duration: .8s;
  transform: translateX(-100px);
  opacity:0;
  filter:alpha(opacity=100);
}

.action .sv_z .bg{transform: scale(1) !important;}
.action .sv_z .sv_copy{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
.action .sv_z .sv_copy2{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
/* [E] Title Animation */
</style>