<template src="./managementList.html"></template>

<script>
import i18n from "../i18n"
export default {
    /*   props: ['listCount', 'listData', 'listType', "uiType", "all", "filterView", "viewChange", "eventName"],
        */props: {
        listCount: {
            type: Number,
            required: false,
            default: 0
        },
        listData: {
            type: Object,
            required: false,
            default: {
                items: []
            }
        },
        listType: {
            type: String,
            required: false,
            default: "normal"
        },
        uiType: {
            type: String,
            required: false,
            default: "checkbox"
        },
        all: {
            type: Boolean,
            required: false,
            default: false
        },
        filterView: {
            type: Boolean,
            required: false,
            default: false
        },
        viewChange: {
            type: String,
            required: false,
            default: "album",
        },
        eventName: {
            type: String,
            required: false,
            default: "default"
        }
    },
    data() {
        return {
            infoData: [],
            result: 0,
            count: 0,
            variousArtist: false,
            arrayResult: [],
            arrayList: [],
            search: null,                   // 검색
            filter: null,
            filtered: 0,                    // 검색된 앨범 수
            search_artistList: [],          // 아티스트 리스트
            artist: null,                   // 아티스트 검색어
            search_condition: {
                limit: 40,                  // 리스트 노출 갯수 제한
                state: null,                // flt
                sort: 'created_at',
                sortorder: "DESC"          
            },
            totalPage: 0,
            total: 0,
            currentPage: 0,
            infoDataList: [],
            albumName : null,
            album_name_language : null
        }
    },
    created() {
        if(this.viewChange == 'album') this.allArtistList()
    },
    watch:{
        listData() {
            if(this.listData.items) {
                this.infoDataList = this.listData.items
                this.filtered = this.listData.filtered
                this.total = this.listData.total
                this.currentPage = 1
            }
        },
        infoDataList() {
            if(this.viewChange == 'album') {
                if(this.infoDataList) {
                    if(this.infoDataList.length > 0) {
                        this.infoDataList.map(item =>{
                            // 상태
                            let states = i18n.t("common.props.state")
                            let states_keys = Object.keys(i18n.t("common.props.state"))
                            states_keys.map(key =>{
                                if(item.state == key) item['state_expression'] = states[key]  
                            })

                            let state_translation = item.state
                            // 업데이트 일
                            let updata_firstTime = item.created_at.split("T")[0]
                            item['updated_at_expression'] = updata_firstTime
                            // 발매일
                            if(item.release_date) {
                                let release_firstTime = item.release_date.split("T")[0]
                                item['release_date_expression'] = release_firstTime
                            }
                        })
                    }
                }
            }
            return this.infoDataList
        },
        sort() {
            const sort = this.search_condition.sort
            switch(sort) {
                case 'name':case 'display_name':this.search_condition.sortorder = 'ASC';break;
                default:this.search_condition.sortorder = 'DESC';break;
            }
        },
        viewChange() {
            switch(this.viewChange) {
                case 'artist':this.search_condition.sort = "display_name";break;
                case 'album':this.search_condition.sort = "created_at";break;
                default:break;
            }
        }
    },
    computed: {
        viewCount() {
            let listSize = this.listData.items.length
            let listCount = this.listCount
            if(listSize > listCount) this.count = listCount
            else this.count = listSize
        },
        i18n_message() {
            return this.$t('management')
        },
        sort() {
            return this.search_condition.sort
        }
    },
    methods : {
        handleScroll: function() {
            if (this.timeout) clearTimeout(this.timeout); 
                this.timeout = setTimeout(function() {
                    
                    if(this.infoDataList.length > 8){
                        this.scrollArea()
                    }
                }.bind(this), 150)
        },
        scrollArea() {
            let targetEl = document.documentElement
            let a = targetEl.scrollHeight - targetEl.clientHeight
            let b = targetEl.scrollTop;

            let dataLength = this.listData.items.length     // 데이터 량
            let listCount = this.listCount                  //리스트 노출 제한
            let result = parseInt(dataLength/listCount)     //나누기 몫
            let remainder = (this.filtered%listCount)       // 나눈 나머지 몫
            this.totalPage = (remainder == 0)?(parseInt(this.filtered/listCount)) : (parseInt(this.filtered/listCount)+1) // 총 리스트 페이지 수
            
            if((b/a) >= 0.6) {
                if(this.currentPage <= this.totalPage) {
                    this.currentPage++
                    this.listFilter()
                    console.log(this.currentPage)
                }
            }
        },
        listTypeChange() {
            switch(this.listType) {
                case "normal":
                    this.listType = "grid";
                    break;
                case "grid":
                    this.listType = "normal";
                    break;
                default: this.listType = "grid";
            }
        },
        showPanel2() {
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-2",
                props: {
                    eventName: this.artist_registration_eventName
                }
            },()=>{
                this.allArtistList()
                this.$EventBus.$emit(this.eventName, {eventName: "artist_load"})
            });
            // const panel = this.$showPanel({
            //     component: "panel-2",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })

            // panel.promise.then(result => {
            //     if(result) {
            //         this.allArtistList()
            //         this.$EventBus.$emit(this.eventName, {eventName: "artist_load"})
            //     }
            // })
        },
        showPanel3(listData) {
            // const panel = this.$showPanel({
            //     component: "panel-3",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {
            //         listData: listData,
            //         eventName: this.eventName
            //     }
            // });
            // console.log(listData);
            this.$EventBus.$emit('openPanel',{
                component: "panel-3",
                props: {
                    listData: listData,
                    eventName: this.eventName
                }
            });
        },
        showPanel4(listData) {
            // const panel = this.$showPanel({
            //     component: "panel-4",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {
            //         listData: listData,
            //         eventName: this.eventName
            //     }
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-4",
                props: {
                    listData: listData,
                    eventName: this.eventName
                }
            },()=>{
                
            });
        },
        allArtistList() {
            let sort = null // artist name 처리
            let sortorder = null
            this.loadingShow();
            this.$store.dispatch('ALL_ARTISTLIST', { sort, sortorder})
            
                .then(result =>{
                    this.loadingHide();
                    this.viewChange == 'artist'
                    this.search_artistList = result.items
                }).catch(error =>{
                    this.loadingHide();
                    console.log("management error :::", error)
                })
            
        },
        list_search() {
            this.infoDataList = [] // 초기화
            this.currentPage = 0
            this.listFilter()
        },
        debounceSearch(val) {
            if(search_timeout) clearTimeout(search_timeout);
            var that=this;
            search_timeout = setTimeout(function() {
                that.xxxx = val; 
            }, 400);
        },
        listFilter() {
            let sort = this.search_condition.sort
            let sortorder= this.search_condition.sortorder
            let page = this.currentPage
            let limit = this.search_condition.limit
            let column = ''

            // 로딩
            let loader = document.getElementById('text_loading')
            if(loader) document.getElementById('text_loading').classList.add('loading_active')

            if(this.viewChange === 'album') {
                console.log(this.search_condition.state)
                let state = this.search_condition.state  // 상태
                let artist_id = this.artist // 아티스트 아이디
                if(state) column = "state=" + state
                this.loadingShow()
                this.$store.dispatch("LOAD_ALBUMLIST", {sort, sortorder, page, limit, column, artist_id})
                    .then((result) =>{
                        this.loadingHide()
                        this.total = result.total
                        this.filtered = result.filtered
                        if(result.items) {
                            if(result.items.length > 0) {
                                result.items.map((value, index) => {
                                    this.infoDataList.push(value)
                                })
                            }
                        }

                        // setTimeout(function() {
                        //     document.getElementsByClassName('text_loading')[0].classList.remove('loading_active')
                        // }, 1000)
                    })
                    .catch(error =>{
                        this.loadingHide()
                        console.log("앨범 리스트 불러오기 Error ::", error)
                    })
            }else if(this.viewChange === 'artist') {
                 this.loadingShow()
                this.$store.dispatch("LOAD_ARTISTLIST", {sort, sortorder, page, limit, column})
                    .then(result =>{
                        this.loadingHide()
                        this.total = result.total
                        this.filtered = result.filtered
                        if(result.items) {
                            if(result.items.length > 0) {
                                result.items.map((value, index) => {
                                    this.infoDataList.push(value)
                                })
                            }
                        }

                        // setTimeout(function() {
                        //     document.getElementsByClassName('text_loading')[0].classList.remove('loading_active')
                        // }, 1000)
                    })
                    .catch(error =>{
                        this.loadingHide()
                        console.log("앨범 리스트 불러오기 Error ::", error)
                    })
            }
        },
        deleteAlbum (id) {
            if(id) {
                this.confirm({
                    title : this.iText('이 앨범을 삭제하시겠습니까?','confirm'),
                    msg : this.iText('이 앨범을 삭제하시겠습니까?','confirm.deleteAlbum'),
                    autofocus : true,
                    width:400,
                    action : ()=>{
                        this.loadingShow()
                        this.$store.dispatch("DELETE_ALBUM", id)
                        .then(result => {
                            this.$EventBus.$emit(this.eventName, {eventName: "album_delete"})
                        })
                    }
                })
                
                
                
            } else{
                console.log("There is no ID value.")
            }
        },
        addAlbum(){
            
            // if(this.valCheck(this.album_name_language)){
            //     this.alert({msg:this.iText('언어를 선택해주세요.','enter.language')})
            //     return false
            // }
            if(this.valCheck(this.albumName)){
                this.alert({msg:this.iText('앨범명을 입력해주세요.','enter.album.name')})
                return false
            }
            let obj={
                name : this.albumName,
                name_language : this.album_name_language,
                description_language : this.album_name_language,
                label_language : this.album_name_language,
                name_multilang : {
                    "ko" : null,
                    'en' : null,
                }
            }
            this.$store.dispatch("POST_ALBUM", obj).then(result => {
                this.$router.push('/distributionrenew/'+ result.id)
                this.$EventBus.$emit(this.eventName, {eventName: "album_post"})
            })
        }
    }
}
</script>

<style scoped>
    li {
        list-style-type: none;
    }

    .gridList {
        width: 860px;
        height: 300px;
        overflow: auto;
        border:1px solid darkmagenta;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .gridList1 > div{
        width: 200px;
        height: 250px;
        border: 1px dotted cadetblue;
        margin: 0;
    }

    .gridList2 {
        width: 100%;
        overflow: auto;
        display: flex;
        justify-content: space-around;
        /* justify-content: start; */
        flex-wrap: wrap;
    }

    .gridList2 > div{
        width: 100%;
        height: 50px;
        border: 1px dotted cadetblue;
        margin: 0;
    }

    .gridList2::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    .deleteStyle {
        cursor: pointer;
        margin: 0 5px;
    }

    .img_sizeLimited {
        width: 274px;
        height: 274px;
        object-fit: cover;
    }

</style>