export default {
    'confirm' : '확인',
    'cancel' : '취소',
    'save' : '저장',
    'select' : '선택',
    'next' : '다음',
    'prev' : '이전',
    'add' : '추가',
    'delete' : '삭제',
    'commingSoon' : '준비중입니다.',
    'accessWarning':'접근 권한이 없습니다.',
    'accessWrong' : "잘못된 접근입니다.",//
    'english' : '영문',
    'language':'언어',
    'remarks': '요청 및 특이사항',//Remarks
    'remarksText' : `
    유통 담당자에게 전하고 싶은 말이 있다면 남겨주세요.
    특히, 정확한 매칭을 위해 아티스트,피처링,작곡가,작사가,편곡가 등 참여자 Melon Artist URL 을 남겨주시면 좋습니다.
    ex) 아이유 https://www.melon.com/artist/timeline.htm?artistId=261143
    `,//If there is anything else we need to know regarding your release, please enter your message here.
    'status' : '상태',
    'name' : '이름', //Name
    'type' : '유형',//Type
    'releaseLink' :'발매 링크',//Release Link 
    'releases' : '앨범',  //Releases
    'tracks' : '트랙', // Tracks
    'artists' : '아티스트', //Artists
    'salesTrend' : '월별 추이', // Monthly Trend,
    'salesTotal' : '판매 비율', // Sales Ratio
    'download':'다운로드',//
    'streaming':'스트리밍',//
    'other':'기타',//

    'musicstore': '뮤직스토어',//Music Store
    'country' : '국가',//

    'menu.album' : '앨범',// Released
    'menu.artist' : '아티스트',// Artist
    'menu.withdraw' : '출금',// Withdraw

    
    'albumName' : '앨범명',// Album 
    'artistName': '아티스트명',// Artist
    'distribution.distribution':'유통하기',//
    'distribution.albumInfo':'앨범정보',//
    'distribution.trackInfo':'트랙정보',//
    'distribution.submit' : '유통 신청하기',//Distribution
    'distribution.submit.msg' : `
        1. 제출하신 발매 자료는 입력하신 내용 그대로 발매 됩니다.<br/><br/>

        2. 오타, 누락 내용이 없도록 꼼꼼하게 확인 부탁드립니다. <br/><br/>

        3. 일부 특수 문자, 기호, 외국어 등은 플랫폼 정책이나 시스템에 따라 제대로 표기가 안될 수 있습니다. <br/>
        <br/>
        <span class='redpoint'>*</span> 제출 후에는 자료 추가 혹은 수정이 불가능 합니다.<br/> <br/>

        <p style="text-align:center;">정말 제출하시겠습니까?</p>
    `,
     
    'distribution.cancel' : '유통신청 취소하기',//Distribution cancel
    'distribution.cancel.msg' : '정말로 취소하시겠습니까? <br/> 접수를 취소하더라도 작성하신 내용을 다시 수정할 수 없습니다. 또한 취소 이후 관리자가 신청내역을 볼 수 없게 됩니다. (내용 수정 희망시, 메일문의 해주세요.)', //The application is canceled, and even if it is canceled, the contents cannot be modified.  <br/>  If there are any corrections, please request corrections through the administrator inquiry.

    // 앨범
    'album.name' : '대표앨범명',
    'album.add' : '앨범 추가',
    'album.name.msg' : '일부 스토어는 언어값이 있는 명칭으로만 서비스됩니다. 언어별로 번역을 꼭 기재해주세요.',//It will be released under the name of the representative album you wrote, and overseas stores will be released under the name of the English album.
    'album.name.msg2' : "입력한 내용이 대표앨범명으로 스토어에 표기됩니다.",// Please enter the (main) title of your album here.
    'album.validationTitle' : '유효성 검사 오류',// Validation Errors
    'album.validationText' : '유통신청 시 꼭 필요한 항목들 중 입력되지 않은 항목이 있습니다. <br> 저장 후 나중에 수정하시겠습니까?',//Some of the essential items for your application have not been entered. Will you fix it later?
    'album.fixnow' : '지금 수정',
    'album.fixlater' : '저장 후 넘어가기',// Yes, I will fix it later.
    'album.distributor' :'유통사' ,//Distributor
    'album.releaseType' : '앨범유형',//releaseType
    'album.releaseDate' : '발매일시',//Release Date
    'album.has_mv' :"MV 여부",//Music Video

    //트랙
    'track.title' : '대표곡',
    'track.titleStar1' : 'Title-1',
    'track.titleStar2' : 'Title-2',
    'track.titleStar3' : 'Title-3',
    'track.titleStar4' : 'Title-4',
    
    'track.noTitle' : '대표곡 선택필요',//Non Title
    'track.name' : '트랙명',
    'track.add' : '트랙 추가',//Register a track
    'track.name' : '트랙명',
    'track.artist' : '아티스트',// artist
    'track.contributor' : '참여자',//Contributor
    'track.contributer.name':'이름', // name
    'track.contributer.company':'저작권관리사', // Publishing Company
    'track.composer' : '작곡가',//composer
    'track.lyricist' : '작사가',//lyricist
    'track.arranger' : '편곡가',//arranger
    'track.genre' : '장르',
    'track.file' : '음원파일',
    'track.fileType' : 'FLAC or WAV',
    "track.type" : '트랙 버전',
    "track.copy" :'메타데이터 복사', //Copy data from
    "track.select" :"트랙 선택", // select track
    "track.contributor.copy" : '참여자 가져오기',//'Copy contributor'
    "track.copy.msg" : '선택한 트랙의 메타데이터를 복사하여 트랙을 생성합니다. (복사대상 : 트랙아티스트/트랙장르/참여자/피처링아티스트)', //Creates a duplicate of the selected track (Track Artist/Track Genre/Contributor/Featuring Artist)
    "track.copy.error.select" : '트랙을 선택해주세요.',//Please select a track.
    "track.copy.error.length" : '선택하신 트랙에는 참여자가 없습니다.',//The selected track has no contributor.
    "track.mp3.error" : "320kbps이상인 MP3파일을 올려주세요.", //Please upload the MP3 file that is over 320kbps.
    "track.nameAndType.error" : '이미 동일한 트랙명과 버전을 가진 트랙이 있습니다.', //There is already a track with the same track name and version
    "track.volume" : 'Disc',
    "track.vol" : "Disc",

    
    contributorsOption:{
        composer : '작곡가',//composer
        lyricist : '작사가',//lyricist
        arranger : '편곡가',//arranger  
    },
    // 아티스트
    'artistType.solo_female': '여성 솔로',
    'artistType.solo_male': '남성 솔로',
    'artistType.group_female': '여성 그룹',
    'artistType.group_male': '남성 그룹',
    'artistType.group_combined': '혼성 그룹',
    
    
    'genre.msg.more' : '인디, K-Pop 장르 선택 시, 세부장르를 하나 더 선택해주세요',//If you choose indie and K-Pop genres, please choose one more detailed genre.

    'account.period.tooltip' : `
        “정산월“은 권리자가 판매된 대금을 정산받는 월입니다.<br>
        “서비스월“은 권리자의 음원이 뮤직스토어에서 판매가 된 월입니다.<br>
        정산내역은 일반적으로 서비스(판매)가 발생한 날로부터 2개월 뒤에 공개됩니다. <br>
        예를 들어, 1월에 뮤직스토어에서 판매된 내역은 3월 정산 내역에서 확인할 수 있습니다.
    `,

    
    //문구 
    'pwChangeError1' : '현재 비밀번호를 확인해주세요',
    'pwChangeError2' : '새 비밀번호를 확인해주세요',
    'inputValueCheck' : '입력정보를 확인해주세요.',//Please check the input information.
    'regSuccess' : '등록되었습니다.',//You're registered.
    
    'error.track.name'  : '트랙명을 입력해주세요.',// Please enter the track name.
    'error.track.name_language'  : '언어를 선택하고 내용을 입력해주세요.',//Please select the track name translation.
    'error.applyAlbum' : '앨범정보 또는 트랙정보를 확인해주세요.',//Please check the album information or track information.
    'error.trackLength' : '1개 이상의 트랙이 반드시 필요합니다.', // At least 1 track is required.
    'error.track.title' : '첫번째 대표곡을 선택해주세요.', // no track title
    'error.language' : '이미 등록된 언어입니다.', //The language is already registered.
    'error.already.data' :'이미 등록된 정보 입니다.', // This data is already registered.
    'enter.languagename' : '언어를 선택하고 내용을 입력해주세요.' , // Please enter your language and name.
    'enter.require' : '미입력된 필수항목이 있습니다.',//There are required items that have not been entered. 
    'enter.album.name' : '앨범명을 입력해주세요.',//Please enter the album title
    'enter.language' : '언어를 선택해주세요.',
    'enter.track.name' : '트랙명을 입력해주세요.',
    'enter.track.composerName':'작곡가명을 입력해주세요.',//Please enter the composer name.
    'enter.track.lyricistName':'작사가명을 입력해주세요.',//Please enter the lyricist name.
    'enter.track.arrangerName':'편곡가명을 입력해주세요.',//Please enter the arranger name.
    'enter.track.contributorRole': '참여자 구분을 선택해주세요.',//
    'enter.track.contributorName': '참여자 이름을 입력해주세요.',//
    'enter.track.contributorOne' : '작곡가 1 명 이상 등록 해주세요.',//Please register at least 1 composer.
    'enter.musicStore':'뮤직스토어를 선택해주세요.',//Please select a music store
    
    //신규 정리 필요
    'confirm.deleteAlbum' : '이 앨범을 삭제하시겠습니까?',//Are you sure you want to delete this album?
    'confirm.deleteTrack' : '선택하신 트랙을 삭제하시겠습니까?', //Are you sure you want to delete this track?
    'confirm.leavePage' : "이 페이지에서 나가시겠습니까? \n 변경사항이 저장되지 않을 수 있습니다.",
    
    //
    'tooltip.uci': `UCI(Universal Content Identifier)는 음악콘텐츠를 식별하기 위한 한국의 국가표준식별코드입니다.한국 제작자의 경우,  MIMS 가입자명(권리자정보) 알려주셔야 코드 발급이 가능합니다.`,
    'tooltip.original_release_date' : `과거에 이미 발매한 앨범인 경우에만 기발매일을 입력해주세요. <br/> 이 앨범이 처음 발매하는 것이라면 공란으로 두세요. `,
    'tooltip.releaseType' : `Single :1~3 Track , 각 트랙의 재생시간 10분 이내 <br/> EP : 4~6 Track <br/> Album :  7 Track + <br/> *트랙 3개 이하이나, 1개 이상의 곡이 10분 이상, 총 재생시간이 30분 이내인 경우 : EP <br/> *트랙 6개 이하이나, 총 재생시간이 30분 이상인 경우 :  Album <br/>미선택 시, 트랙 수에 따라 선택됩니다. <br/>`,
    'tooltip.sns': 'Youtube, Facebook,Twitter, Instagram,Website 등 사람들과 소통하는 아티스트 채널을 알려주세요.', //
    'tooltip.indetifier' : `
        아티스트 ID 는 어디에서 확인하나요? <br/>
        각 플랫폼 아티스트 상세페이지 URL에서 마지막 / 뒤의 값이 플랫폼의 Artist ID입니다. <br/>
        *올바른 아티스스트 페이지인지 확인해주세요!특히 동명의 아티스트 많은 경우 주의. <br/>
        [Apple] https://music.apple.com/us/browse 에서 아티스트를 검색하고 아티스트 상세페이지의 URL을 복사하세요.<br/>
        <img style="max-style:100%; margin:20px 0 ;" src="https://storage.googleapis.com/srd-static/web-common/tooltip/Artist_ID_apple.jpg" /><br/>
        [Melon] https://www.melon.com/ 에서 아티스트를 검색하고 아티스트 상세페이지의 URL을 복사하세요.<br/>
        <img style="max-style:100%; margin:20px 0 ;" src="https://storage.googleapis.com/srd-static/web-common/tooltip/Artist_ID_melon.jpg" /><br/>
        [Spotify]http://open.spotify.com 에서 아티스트를 검색하고 아티스트 상세페이지의 URL을 복사하세요. <br/>
        <img style="max-style:100%; margin:20px 0 ;" src="https://storage.googleapis.com/srd-static/web-common/tooltip/Artist_ID_spotify.jpg" /><br/>
        메인아티스트, 피처링아티스트, 참여자 등 발매 이력이 있는 경우 각 플랫폼에 아티스트 페이지가 생성되어 있습니다.<br/>
        생성된 플랫폼의 Artist Identifier를 미리 전달해주시면, 신규발매시 매칭작업이 수월해집니다.<br/>
        아티스트 ID가 없는 경우 신규 아티스트로 생성되거나 동명의 다른 아티스트에 매칭될 가능성이 높아집니다.
    `,
    'tooltip.trackTitle' : '모든 발매에는 대표곡이 필요합니다. 1개 트랙짜리 싱글 앨범이어도 꼭 대표곡(Title-1)을 선택해주세요.',//At least one representative title song(Title-1) must be selected for the release of a specific music store.
    'tooltip.artistName' : `
        대표명에 병기를 하시는 경우, 언어별 아티스트명을 꼭 분리해서 작성해주세요. <br/>
        예시) <br/>
        대표명 : 사운드리퍼블리카(Sound Republica) <br/>
        번역 : <br/>
        [한국어] 사운드리퍼블리카 <br/>
        [영어] Sound Reublica <br/>
        - 이름은 정확하고 올바른 형식이어야 합니다. 맞춤법 문법 및 구두점을 확인해주세요.<br>
    `,//
    'mobile' : '이 사이트는 모바일에 최적화된 화면을 제공하지 않습니다. <br> PC에서의 접속을 권장합니다.',


    'password.linkError' : '만료된 링크입니다. 최신 메일에서 비밀번호재설정해주세요.', //This link has expired. Please reset the password in the latest mail.
    
    'marketing.msg.change' : '마케팅 수신 동의가 변경되었습니다.', // Your marketing settings have been updated. (편집됨
    
    albumErrorList : {

        'territory': "뮤직스토어",
        'name':"앨범명",
        'name_language':"앨범명 언어",
        'artist':"앨범아티스트",
        'genre':"앨범장르",
        'coverimage_id':"앨범커버이미지",
        'label':"기획사",
        'label_language':"기획사 언어",
    },
    
    // 정산
    'withdrawal.balance': `잔액`, // Balance
    'withdrawal.transaction': `거래내역`, //transaction history
    'withdrawal.minimum' : '최소 출금 금액',//minimum withdrawal amount
    'withdrawal.limit' : '진행중이 출금신청건이 있을경우 추가 신청이 불가능합니다.',//If there is a withdrawal request in progress, additional requests cannot be made.
    
    'artist.idetifier_msg':`Artist ID를 입력해주세요.`,// Please enter your Artist ID.
    
    'apply.title' : '음원 발매 신청',//
    'apply.apply' : '제출',// apply
    'apply.desired_date' : '발매 희망일',// Desired release date
    'apply.desired_date.msg' : `
    신청하신 발매 희망일 및 시간은 담당자 확인 후 변경될 수 있습니다. <br>
    발매일(시)의 변경이 필요할 경우, 사전에 신청자의 이메일 또는 유선상으로 변경 가능한 발매일(시)에 대한 안내를 드립니다.
    `,//

    'apply.ready_date' : '발매 자료 완성일',//Completion date of related materials
    'apply.album_title' : '발매앨범명',// Title
    'apply.album_title.msg' : '발매 앨범명을 입력해주세요.',// Please enter title
    'apply.album_artist' : '참여 아티스트',//Artist
    'apply.album_artist.msg' : `메인 아티스트와 피처링 아티스트를 구분해서 입력해주세요.(참여 아티스트가 다수일 경우 콤마(,)로 구분해주세요.) \n ex : 아티스트 A, 아티스트 B, (피처링) 아티스트C, 아티스트D`,//Artist
    'apply.url_artist' : '참여 아티스트의 스트리밍 사이트 URL',//
    'apply.url_artist.msg' : 'Melon / VIBE / FLO / Bugs / Spotify 등에 공개된 아티스트 페이지 링크를 입력해주세요.  \n ex : https://www.melon.com/artist/timeline.htm?artistId=261143',//
    'apply.url_file' : '발매 앨범 관련 첨부파일 링크',//
    'apply.url_file.msg' : '데모 및 기타 첨부파일의 URL(Google Drive, Dropbox, etc...)을 입력해주세요.',//
    'apply.has_mv' : 'MV 포함 여부',// Music Video
    'apply.remark' : '문의 및 요청사항',//
    'apply.remark.msg' : '발매관련 문의사항 및 기타 요청사항이 있다면 입력해주세요.',//
    'apply.error.enter' : '필수값을 모두 입력해주세요.',
    'apply.complete' : `
    음원발매 신청이 정상적으로 접수되었습니다. <br>
    담당자 확인 후 신청서에 입력하신 이메일로 회신 드리도록 하겠습니다.<br>
    *검토 및 결과 회신에는 약 3~5영업일이 소요될 수 있습니다.`,

    common: {
        popup: {
            message: {
                complete: "",
                save: "성공적으로 저장되었습니다"
            }
        },
        label: {
        },
        msg: {
            imgDrag: "마우스로 드래그하거나 클릭하여 추가해주세요.",
            coverImgRule: [
                "파일 형식: JPG    컬러 모드: RGB",
                "이미지 규격: 1400x1400px ~ 3000x3000px / 완벽한 1:1 비율 / 72dpi 이상<br><br>",
                "각종 로고, URL, 기타 다른 서비스 상호와 관련 이미지가 들어가 있거나 저품질, 재사용이 의심되는 커버는<br>뮤직스토어에서 등록 거부될 가능성이 있습니다.",
                "그 밖에 등록 거부될 가능성이 있는 이미지 예시:",
                "-흐릿한 이미지 또는 픽셀 화 된 이미지",
                "-잘린 이미지, 특히 잘라낸 텍스트",
                "-회전된 이미지  -성적 노출이 심한 이미지",
                "-물리적 발매 에 대한 표시 (예 : “CD”또는 “Compact disc” )",
                "-나치 선전, 욱일기"
            ],
            agencyImgRule: [
                "Beatport 발매 시에만 사용됩니다.",
                "파일 형식: JPG",
                "이미지 규격: 최소 1000px 이상 / 72dpi 이상"
            ],
            loading: "로딩중 ......",
            none_licensor: "등록된 유통정보, 세금정보, 지급정보가 없습니다.<br>먼저 권리자정보를 등록해주세요."
        },
        props: {
            all: "전체",
            language: {
                ko: "한국어",
                en: "English",         // 영어
                ja: "日本語",           // 일본어
                es: "Espanol",         // 스페인어
                de: "Deutsch",         // 독일어
                nl: "Nederlands",      // 네덜란드어
                zhCn: "简体中文",       // 중국어 (간체)
                zhTw: "繁體中文"        // 중국어 (번체)
            },
            nationality : {
                kr: "대한민국",
                us: "United States",    // 미국
                jp: "日本",             // 일본
                es: "España",           // 스페인
                de: "Deutschland",      // 독일
                nl: "Nederland",        // 네덜란드
                cn: "中国"              // 중국
            },
            artist: {
                type: {
                    solo_male: "남성솔로",
                    solo_female: "여성솔로",
                    group_male: "남성그룹",
                    group_female: "여성그룹",
                    group_combined: "혼성그룹"
                }
            },
            state: {
                // temporary, registration_complete, inspection_complete, distribution_complete, release_complete, suspended
                
                temporary: "임시저장",
                registration_complete: "신청완료",
                rejected: "반려",
                registration_canceled: "유통취소",
                inspection_complete: "유통준비중",
                suspended: "유통중단",
                distribution_complete: "유통완료",
                release_complete: "발매완료",
                /* 보류 distribution_complete: "유통완료", */
                // 발매완료 문구는 권리자가 알 필요성이 없어서 유통완료 표기만 사용 
            },
            musicStoreList: [
                "MELON",
                "YouTube",
                "Genie Music",
                "FLO",
                "NAVER MUSIC",
                "VIBE",
                "BUGS",
                "Apple Music",
                "Spotify",
                "KAKAO MUSIC",
                "Acfun",
                "Advertising Agency",
                "Akazoo",
                "Aldi Life",
                "ALSONG",
                "Amazon",
                "Amazon Ad-Funded Streaming Service",
                "Amazon Prime",
                "Amazon Unlimited",
                "Anghami",
                "Audiomack",
                "AWA",
                "B612",
                "beat.no",
                "Beatport",
                "Bell 365",
                "Best Friends",
                "Bilibili",
                "Bitel Peru",
                "BMAT",
                "Boom",
                "Boomplay",
                "Buecher",
                "Carrier Billing",
                "CCMLOVE",
                "CCMPIA",
                "Celcom",
                "Changba",
                "China Unicom (Wo Music)",
                "Chunghwa Mobile",
                "CJ Hello Vision",
                "Coolvox",
                "DaPian",
                "Deezer",
                "DiGi",
                "Digicel",
                "Douban Music",
                "Douyin",
                "Dragonfly FM",
                "Dubset Media",
                "Duitang",
                "Echo",
                "Electric Jukebox",
                "Ex Libris",
                "EZWelfare",
                "FETnet",
                "Fizy",
                "Friday Music (Omusic)",
                "Future TV",
                "Gaopin",
                "Gionee",
                "Global Radio",
                "GodPeople",
                "Google Play",
                "Grandpad",
                "Groovers",
                "GTL Music",
                "Heiyo",
                "HighResAudio",
                "Himalayan FM",
                "HMV japan",
                "Hofer Life",
                "Huawei",
                "IAM+",
                "iFeng",
                "iHeartRadio US",
                "iMusica",
                "Incruit",
                "IPTV",
                "iQiyi",
                "iTunes",
                "J Pay",
                "J:COM",
                "Jaxsta",
                "Jaxsta Music",
                "Jeou Tai",
                "Jinshan",
                "Jio Saavn",
                "Joins Prime",
                "JOOX",
                "Juke",
                "Juno Download",
                "Kanjian Music",
                "Kanlixiang",
                "KKBOX",
                "Koala FM",
                "Kolbi Music",
                "KT",
                "KTV",
                "Kuaishou",
                "Kugou",
                "Kuwo",
                "Kuyin Ringtone",
                "Lanren Tingshu",
                "Layabox",
                "Lenovo",
                "Lequ Music",
                "LETV",
                "LGU+",
                "LINE Music",
                "Lizhi FM",
                "Lola",
                "Love Music (China Telecom)",
                "Maowang",
                "Maxis",
                "Meitu",
                "MGTV",
                "Migu Music (China Mobile)",
                "Migu Video (China Mobile)",
                "Milk",
                "MNET",
                "MOMO",
                "Moodagent",
                "MOOV",
                "Mora Qualitas",
                "Movistar Colombia",
                "Movistar Musica Colombia",
                "Movistar Musica Peru",
                "MTN Uganda",
                "MUSIC MATE",
                "MyMelo",
                "MyMusic",
                "Napster",
                "Naver Samsung Smart TV",
                "Naver Search",
                "NEC",
                "NetEase",
                "Odnoklassniki",
                "Olleh Music",
                "ONE STORE",
                "Onkyo",
                "OPPO",
                "Orange Dominican Republic",
                "Pandora",
                "Peloton",
                "PLAY Poland",
                "Playnetwork",
                "Plex",
                "PPTV",
                "Pyro Music",
                "Qianqian Music",
                "Qobuz",
                "QQ Music",
                "Raku",
                "Rakuten",
                "Resso",
                "Rhapsody",
                "ROXI",
                "Samsung Milk Music (China only)",
                "SAMSUNG MUSIC",
                "Santa Toeic",
                "SECURUS",
                "Shazam",
                "Simfy Africa/MTN",
                "Sina Video",
                "Singtel",
                "Sinhan Card",
                "SiriusXM",
                "SKT",
                "Slacker",
                "Sohu",
                "SoundCloud",
                "SoundMachine",
                "Soundtrack Your Brand",
                "Sprint US",
                "Stellar Entertainment",
                "T Star",
                "T STORE",
                "TaiHua",
                "Taiwan Mobile",
                "TDC Play Musik",
                "Telefonica Brazil",
                "Telenor DK",
                "Telmore Affiliate",
                "Tencent Video",
                "TIDAL",
                "TikTok",
                "TIM Italia",
                "TingTing FM",
                "TJ Media",
                "TouchTunes",
                "Toutiao",
                "TrackDrip",
                "Traxsource",
                "Tudou",
                "Tuzhan",
                "U-Mobile",
                "Ultimate Music China Limited",
                "UMA",
                "UTAPASS",
                "Virgin Mobile Colombia",
                "Viva Musica",
                "VIVO hires",
                "VKontakte",
                "Vodafone",
                "Vodafone Zambia",
                "VUE",
                "Walkgame",
                "Wasu TV",
                "WAVVE",
                "We Sing",
                "Weibo Music",
                "Weltbilt",
                "WOM Chile",
                "Wotu",
                "Wynk",
                "Xiami",
                "Xiaomi Music",
                "Xiaoying",
                "Xite",
                "Xiumi",
                "Xiutang",
                "Yandex Music",
                "Yinyue Tai",
                "Yiqixiu",
                "Youku",
                "YouSee (TDC)",
                "Youting",
                "Yunjian",
                "Yunting",
                "Zhuote",
            ],
            sns: {
                instagram: "Instagram",
                facebook: "Facebook",
                youtube: "YouTube",
                tiktok: "TikTok",
                weibo: "Weibo",
                website: "Website",
                blog: "Blog",
                
            }
        },
        pageNavi: {
            first: "처음",
            prev: "이전",
            last: "마지막",
            next: "다음"
        },
        error: {
            code404: {
                title: "404",
                msg: "요청하신 페이지를 찾을 수 없습니다.",
                button: "이전 페이지 이동"
            }
        },
        no_result:'검색된 결과가 없습니다.'
    },
    mainView: {
        description: "Home 페이지",
        label: {
            footer: {
                introduce: "회사소개",
                albumRegister: "앨범등록",
                cost: "비용",
                albumArtist: "앨범/아티스트관리",
                calcAndWithdraw: "정산/출금",
                statistics: "통계",
                my: "마이페이지",
                serviceCenter: "고객센터"
            },
            main_content: {
                block1: {
                }
            }
        },
        msg: {
            main_content: {
                block_1: {
                    position_1: "클릭 한 번으로",
                    position_2: "국내외 200여개 뮤직스토어",
                    position_3: '에',
                    position_4: '내 음악을 유통',
                    position_5: '해보세요!',
                    position_6: [
                        {content1: "GLOBAL", content2: "국내외 음원유통"},
                        {content1: "200+", content2: "제휴 뮤직스토어수"},
                        {content1: "FREE", content2: "등록비 무료"},
                        {content1: "15%", content2: "최저수준 수수료"},
                        {content1: "EASY", content2: "편리한 정산보고서"},

                    ],
                    position_7: '음악유통하기',
                },
                block_2: {
                    position_1: "내 음악을 전세계에 유통하고 싶으세요?",
                    position_2: "국내외 200여개 뮤직스토어 제휴",
                    position_3: '내 음악을 한번만 등록하세요! 멜론/지니/벅스/스포티파이/애플뮤직/유튜브뮤직 등',
                    position_4: '전세계 200여개 뮤직스토어에 여러분의 음악을 유통할 수 있습니다.',
                    position_5: '이전',
                    position_6: '다음',
                },
                block_3: {
                    position_1: "믿고 맡길 음원유통사를 찾으세요?",
                    position_2: "무료로 음원등록하고",
                    position_3: '전세계 유통현황을 한눈에',
                    position_4: '무료 음원등록',
                    position_5: '발매음원 수익의 85%를 가져가세요!',
                    position_6: '음악등록에 어떠한 비용도 필요하지 않습니다.',
                    position_7: "편리하고 정확한 정산",
                    position_8: "음원정산내역을 투명하게 공개하고",
                    position_9: '아티스트에게 유용한 통계지표를 제공합니다.',
                    position_10: '맞춤형 마케팅 지원',
                    position_11: '마케팅전문가가 음원을 선별하여',
                    position_12: '아티스트별 맞춤형 마케팅 플랜을 제안합니다.',
                },
                block_4: {
                    position_1: "사운드리퍼블리카가 전세계에 유통 중인",
                    position_2: "주요 앨범 발매리스트",
                    position_3: '1,000,000개 이상의 트랙이 사운드리퍼블리카를 통해 국내외로 유통되고 있습니다.',
                    position_4: '사운드리퍼블리카와 함께 당신의 앨범도 유통해보세요.',
                    position_5: "이전",

                    position_6: 'J-BLACK',
                    position_7: '넘사벽 인간',
                    position_8: "MoMo",
                    position_9: "안녕, 모모 OST Part. 7",
                    position_10: "Raphan",

                    position_11: "Me&amp;U",
                    position_12: "MoMo",
                    position_13: "안녕, 모모 OST Part. 5",
                    position_14: "YUZION",
                    position_15: "Look At Me!! Remix",
                    position_16: "미도",
                    position_17: "Sink",
                    position_18: "MoMo",
                    position_19: "안녕, 모모 OST Part. 4",
                    position_20: "Scarlet",
                    position_21: "Fishes",
                    position_22: "이 사이트는 구글 크롬 브라우저에 최적화되어 있습니다.(https://www.google.com/chrome/)",
                },
                block_5: {
                    position_1: "Rare Americans",
                    position_2: "Rare Americans",
                    position_3: 'MoMo',
                    position_4: '안녕, 모모 OST Part. 3',
                    position_5: 'ba.',
                    position_6: 'H8',
                    position_7: "MoMo",
                    position_8: "안녕, 모모 OST Part. 2",
                    position_9: "러브엑스테레오",
                    position_10: "37X",
                    position_11: "Rachael Sage",
                    position_12: "Bravery's On Fire",
                    position_13: "Jim Wolf",
                    position_14: "First Kiss",
                    position_15: "Gabe Klavun",
                    position_16: "La Mejor Bessador",
                },
                block_6: {
                    position_1: "LUST",
                    position_2: "Naga-naga",
                    position_3: 'The Majestic G',
                    position_4: 'Wild Runner',
                    position_5: 'Gabe Klavun',
                    position_6: "Don't Ever Call Me",
                    position_7: "Wolfman",
                    position_8: "Mad Woman",
                    position_9: "illvibe",
                    position_10: "Moneytree",
                    position_11: "ISSORED",
                    position_12: "Broke",
                    position_13: "BULGOGIDISCO",
                    position_14: "가을이 왔어",
                    position_15: "Gabe Klavun",
                    position_16: "About To Kiss Another Girl",
                },
                block_7: {
                    position_1: "illvibe",
                    position_2: "Can You Hear Me",
                    position_3: 'MUSHVENOM',
                    position_4: '왜 이리 시끄러운 것이냐',
                    position_5: 'Raphan',
                    position_6: "Season of",
                    position_7: "윤토벤",
                    position_8: "서툰 이별을 하려해",
                    position_9: "Legacy Keys",
                    position_10: "Bass Drop",
                    position_11: "Aris",
                    position_12: "Streets of Paris",
                    position_13: "JAHR",
                    position_14: "Similar",
                    position_15: "Semi Nocturnal",
                    position_16: "Can't get you back",
                },
                block_8: {
                    position_1: "LUKA 120",
                    position_2: "Bayethe",
                    position_3: 'ROYAL',
                    position_4: '가능태공간2',
                    position_5: 'MOONKOCK',
                    position_6: "Bloom",
                    position_7: "H a lot",
                    position_8: "H a coustic",
                    position_9: "Kacey Musgraves",
                    position_10: "Acoustic Remixed",
                    position_11: "ENOi",
                    position_12: "Bloom",
                    position_13: "KELLA",
                    position_14: "Trainwreck",
                    position_15: "좋아서하는 밴드",
                    position_16: "들숲날숨 vol.9 도도",
                },
                block_9: {
                    position_1: "다방",
                    position_2: "복학생",
                    position_3: '만쥬한봉지',
                    position_4: '들숲날숨 vol.7 stop',
                    position_5: 'Onyxstar',
                    position_6: "I Hate This Job",
                    position_7: "러브엑스테레오",
                    position_8: "Zero One",
                    position_9: "Core.Low",
                    position_10: "Netflix&amp;Chillin'",
                    position_11: "The Barberetttes",
                    position_12: "Santa is busy",
                    position_13: "Wildberry",
                    position_14: "Youniverse",
                    position_15: "Manda",
                    position_16: "Keep Dancing",
                },
                block_10: {
                    position_1: "JAHR",
                    position_2: "Magic",
                    position_3: 'Jim Wolf',
                    position_4: 'Strange, Weird, And Romantic (Part 1)',
                    position_5: 'Buzzard Buzzard Buzzard',
                    position_6: "Love Forever",
                    position_7: "Top Sigrid",
                    position_8: "Don`t lie to me",
                    position_9: "Remy",
                    position_10: "Water",
                    position_11: "CLAZZIC",
                    position_12: "Debbusy &amp; Christmas",
                    position_13: "ENOi",
                    position_14: "For RAYS, Realize All Your Star",
                    position_15: "LEO",
                    position_16: "Manhattan",
                    position_17: "다음"
                }
            }
        },
        props: {
        
        },
        api:{

        }
    },
    aboutUs: {
        description: '회사소개 페이지',
        label: {

        },
        msg:{
            content: '회사소개 페이지 입니다'
        },
        props: {
            
        },
        api:{

        }
    },
    cost: {
        description: "비용 페이지",
        label: {
        },
        msg: {
            block_1: {
                position_1: "사운드리퍼블리카는",
                position_2: "합리적인 유통비용을 제시합니다.",
                position_3: '등록비용',
                position_4: '₩ 0',
                position_5: '사운드리퍼블리카를 통해 국내외 뮤직스토어에 등록하는 어떠한 비용도 발생하지 않습니다.',
                position_6: "자세히 보기",
                position_7: '로열티 수익',
                position_8: '85%',
                position_9: '15%의 최저수준 유통수수료율로 나머지 85%의 로열티는 모두 권리자에게 지급됩니다.',
                position_10: '자세히 보기',
                position_11: '시간비용',
                position_12: '절감↓',
                position_13: '많은 뮤직스토어에게 한 번에 유통하고, 한 번에 정산금을 확인하며 시간을 절약해보세요.',
                position_14: '자세히 보기'
            },
            block_2: {
                position_1: "내 음악을 전세계에 무료로 유통할 수 있습니다.",
                position_2: "국내외 200여개 뮤직스토어",
                position_3: '음원등록비용이 0원!',
                position_4: '유통사에 지불해야하는',
                position_5: '음원등록비용이 부담스러우신가요?',
                position_6: '이미 제작에 많은 비용이 투입되어',
                position_7: '유통을 위한 등록비용이 부담스러우셨다면',
                position_8: '등록비용이 무료인 곳에서 함께 유통을 시작해보세요.',
                position_9: '사운드리퍼블리카에서는',
                position_10: '별도의 등록비용이 없습니다.',
                position_11: '이곳에서는 국내외 뮤직스토어들에',
                position_12: '무료에 유통 등록을 진행할 수 있습니다.',
                position_13: '사운드리퍼블리카에 당신의 소중한 음원을 맡겨주세요.',
                position_14: '등록비용, 비교해보시고 선택하세요!',
                position_15: 'A사',
                position_16: 'B사',
                position_17: 'C사',
                position_18: '$9,99/년',
                position_19: '$19,99/년',
                position_20: '$19,99/년',
                position_21: 'FREE',
                position_22: '최종업데이트 : 2021.11.25',
                position_23: '유통수수료 대신 등록비용이 있는 타 글로벌 유통 서비스의 싱글앨범 1개 글로벌 유통 등록비용에 기준함'
            },
            block_3: {
                position_1: "유통수수료를 낮추고 또 낮추었습니다.",
                position_2: "권리자의 소중한 음악에",
                position_3: '합당한 수익을 지급하는 데 앞장섭니다.',
                position_4: '권리자는 발생한 정산금의',
                position_5: '대부분을 받을 수 있습니다.',
                position_6: '사운드리퍼블리카는 뮤직스토어에서 발생하는 정산금의 85%를 권리자에게 지급합니다.',
                position_7: "정산금의 일부가",
                position_8: "유통수수료로 차감됩니다.",
                position_9: '음원성적이 좋을수록 저희도 성장하는 win-win구조로, 차감되는 유통수수료는 서비스 품질 개선에 사용됩니다.'
            },
            block_4: {
                position_1: "음원 유통은 저희에게 맡기세요.",
                position_2: "이제 유통 고민은 덜고",
                position_3: '음원 제작 및 홍보에만 집중하세요!',
                position_4: '유통 및 정산에 들어가는 시간을 절약하고,',
                position_5: "음원 제작 및 홍보에 집중할 수있습니다.",
                position_6: '전 세계 뮤직스토어에 하나하나 음원 자료와 데이터를 등록하는 일은 쉽지 않은 일입니다. 한 번 등록으로 전세계 유통은 저희가 도와드리겠습니다.',
                position_7: '하나로 통합된 화폐와 서비스구분으로',
                position_8: "보기쉬운 정산내역을 제공합니다.",
                position_9: "여러 뮤직스토어의 각각 다른 정산 보고서들을 통합하는 일은 꽤나 번거롭습니다. 편리하고 투명한 통합 정산리포트를 제공하여 꼭 필요한 항목만 쉽게 볼 수 있도록 도와드립니다."
            },
            block_5: {
                position_1: "사운드리퍼블리카와 함께",
                position_2: "음악 유통을 시작해보세요!",
                position_3: '음악유통하기'
            }
        },
        props: {
        
        },
        api:{

        }
    },
    customerService: {
        title: "고객센터",
        description: '고객센터 페이지',
        label: {
            tab_menu: [
                "공지사항",
                "1:1문의",
                "FAQ"
            ],
            notice: {
                title: "공지사항"
            },
            qna: {
                title: "1 : 1 문의"
            },
            faq: {
                title: "FAQ",
                sub_menu: {
                    faq : "자주 묻는 질문",
                    distribution: "음원유통",
                    accounting: "정산/지급",
                    statistics: "통계",
                    other: "기타"
                }
            }
        },
        msg:{
            title: "국내외 뮤직 스토어에 내 음악을 유통해 보세요!",
            qna: {
                message: [
                    "어떻게 도와드릴까요?",
                    "국내 및 해외 음원 유통 등 궁금하신 점은 아래 메일로 문의주세요.",
                    "music@soundrepublica.com"
                ]
            }
        },
        props: {
            
        },
        api:{
            
        }
    },
    selling: {
        description: '음악판매하기 페이지',
        label: {

        },
        msg:{
            content: '음악판매하기 페이지 입니다'
        },
        props: {
            
        },
        api:{
            
        }
    },
    accounting: {
        title:"정산/출금",
        description: '정산/출금 페이지입니다.',
        label: {
            sales: {
                title: "정산",
                tab_title: "정산",
                search: {
                    term: "기간",
                    musicStore: "뮤직스토어",
                    month: "이번달",
                    months_3: "3개월",
                    months_6: "6개월",
                    territory: "판매국가",
                    salesType: "판매구분",
                    searchType: "검색구분",
                    searchBtn: "검색",
                },
                streaming: "스트리밍",
                download: "다운로드",
                other: "기타",
                owner_settlement_amount: "권리자 정산금액",
                case: "건",
                sales_segment: "판매구분별 정산금액",
                sales_history: "정산상세내역",
                excel: "엑셀다운"

            },
            payment: {
                title: "출금",
                tab_title: "출금",
                cumulative_earnings: "누적수입금액",
                cumulative_payment: "누적출금금액",
                balance: "잔액",
                apply_withdrawal: "출금신청하기",
                recent_application_history: "출금신청내역",
                withdrawal: "출금신청",
                withdrawal_complete: "출금완료",
                rejected: "반려",
                excel: "엑셀다운",
                payment_history: "정산금 내역",
                sort: "정렬",
                view: "보기"
            },
            moveBtn: "이동"
        },
        msg: {
            title: "국내외 뮤직 스토어에 내 음악을 판매해 보세요!",
            sales: {
                distribution_fee_exclusion: "유통수수료 제외금액",
                search: {
                    keyword: "검색어를 입력해주세요."
                }
            },
            payment: {
                payment_history: "출금시 세금관련 내용은 고객센터로 문의해주세요.",
                alert_insufficient_1: "출금을 신청하려면 최소 ",
                alert_insufficient_2: " 이상이 필요합니다.<br/>잔액을 확인해주세요.",
                alert_pending: "진행중인 출금신청이 있습니다. 이 지급이 완료된 후에 새롭게 신청해주세요."
            }
        },
        props: {
            reference_amount: {
                krw: "&#8361;50,000",
                usd: "$100",
                eur: "€100"
            },
            sales_history_column: [
                "정산일자",
                "서비스시작일",
                "트랙/아티스트",
                // "곡명",
                // "앨범명",
                "스토어",
                "국가",
                "구분",
                "수량",
                '정산금액',
                "유통<br>수수료",
                "권리자<br>정산금액"
            ],
            recent_application_history: [
                "신청날짜",
                "W-No",
                "출금신청금액",
                "진행상황",
            ],
            payment_history: [
                "처리일",
                "T-No",
                "구분",
                "금액",
                "잔액"
            ],
            each: {
                30:  "30개씩보기",
                50:  "50개씩보기",
                100:  "100개씩보기",
            },
            sortorder: {
                desc: "처리일(내림차순)",
                asc: "처리일(오름차순)"
            },
            filterType: {
                all:"전체",
                advance_payment: "선급금",
                withdrawal: "출금",
                settlement: "정산금",
                admin_payment: "관리자 지급",
                admin_deduction: "관리자 차감"
            },
            sales_filter: {
                term: {
                    settlement:"정산일자",
                    service: "서비스시작일"
                },
                sales_type: {
                    all: "전체",
                    streaming: "스트리밍",
                    download: "다운로드",
                    other: "기타"
                },
                searchType: {
                    artist: "아티스트명",
                    album: "앨범명",
                    track: "트랙명"
                }
            },
        },
        api:{
            
        }
    },
    statistics: {
        title:"통계",
        description: '통계페이지입니다.',
        label: {
            summary: {
                title: "요약",
                monthly_sales_details: {
                    title: "월별 판매 상세",
                    sales_history_column: [
                        "정산월",
                        "서비스월",
                        "스트리밍",
                        "다운로드",
                        "기타",
                        "합계금액"
                    ],
                    sales_history_sub_column: [
                        "건수",
                        "금액",
                        "건수",
                        "금액",
                        "건수",
                        "금액"
                    ]
                },      
                sales_trend: {
                    title: "판매추이",
                    graph_item: {
                        streaming: "스트리밍",
                        download: "다운로드",
                        other: "기타",
                        all: "전체"
                    }
                },
                musicStoreTop5: {
                    title: "뮤직스토어 TOP5"
                },
                more: "자세히보기",
                sales_territory_Top5: {
                    title: "판매국가 TOP5"
                },
                sales_Top10: {
                    title: "음원판매 TOP10",
                    filter: {
                        track: "트랙",
                        album: "앨범",
                        artist: "아티스트"
                    },
                    list_column: {
                        no: "No.",
                        title:{
                            track: {
                                main: "트랙명",
                                sub: "아티스트명"
                            },
                            album: {
                                main: "앨범명",
                                sub: "아티스트명"
                            },
                            artist: {
                                main: "아티스트명",
                                sub: "대표트랙"
                            }
                        },
                        streaming: "스트리밍",
                        download: "다운로드",
                        other: "기타",
                        total: "합계"
                    }
                },
            },
            album_track: {
                title: "앨범/트랙별",
                sell_album: "앨범별 판매",
                amounts: "정산금액",
                sales_top5: "판매 TOP5",
                sales_track: "트랙별 판매",
                album_track_sales_detail: {
                    title: "앨범/트랙별 판매 상세",
                    all: "전체",
                    sales_history_column: [
                        "앨범",
                        "트랙",
                        "스트리밍",
                        "다운로드",
                        "기타",
                        "합계금액"
                    ],
                    sales_history_sub_column: [
                        "건수",
                        "금액",
                        "건수",
                        "금액",
                        "건수",
                        "금액"
                    ]
                }
            },
            artist: {
                title: "아티스트별",
                revenue_artist: "아티스트별 수익",
                sales_top5: "판매 TOP5",
                artist_detail: {
                    title: "아티스트별 상세",
                    sales_history_column: [
                        "아티스트",
                        "스트리밍",
                        "다운로드",
                        "기타",
                        "합계금액"
                    ],
                    sales_history_sub_column: [
                        "건수",
                        "금액",
                        "건수",
                        "금액",
                        "건수",
                        "금액"
                    ]
                }
            },
            musicStore: {
                title: "뮤직스토어",
                revenue_musicStore: "뮤직스토어별 수익",
                sales_top5: "판매 TOP5",
                musicStore_sales_detail: {
                    title: "뮤직스토어별 판매 상세",
                    sales_history_column: [
                        "뮤직스토어",
                        "스트리밍",
                        "다운로드",
                        "기타",
                        "합계금액"
                    ],
                    sales_history_sub_column: [
                        "건수",
                        "금액",
                        "건수",
                        "금액",
                        "건수",
                        "금액"
                    ]
                }
            },
            territory: {
                title: "국가별",
                revenue_territory: "판매국가별 수익",
                sales_top5: "판매 TOP5",
                territory_sales_detail: {
                    title: "국가별 판매 상세",
                    sales_history_column: [
                        "판매국가",
                        "스트리밍",
                        "다운로드",
                        "기타",
                        "합계금액"
                    ],
                    sales_history_sub_column: [
                        "건수",
                        "금액",
                        "건수",
                        "금액",
                        "건수",
                        "금액"
                    ]
                }
            },
            excel: "엑셀다운",
            search: {
                term: "기간",
                musicStore: "뮤직 스토어",
                quick_date: {
                    month: "이번달",
                    month_3: "3개월",
                    month_6: "6개월",
                    year_1: "1년"
                },
                territory: "판매국가",
                salesType: "판매구분",
                searchType: "검색구분",
                searchBtn: "검색",
            }
        },
        msg: {
            title: "국내외 뮤직 스토어에 내 음악을 판매해 보세요!",
            summary: {
                search: {
                    keyword: "검색어를 입력해주세요."
                }
            },
            max_month: "최대 12개월까지 조회 가능합니다."
        },
        props: {
            sales_trend_filter: 
                {
                    amounts:"정산금액",
                    counts: "건 수",
                },
            default_filter: 
                {
                    amounts:"정산금액"
                }
            ,
            each: {
                30:  "30개씩보기",
                50:  "50개씩보기",
                100:  "100개씩보기",
            },
            search_filter: {
                term: {
                    settle:"정산월",
                    service: "서비스월"
                },
                salesType: {
                    all: "전체",
                    streaming: "스트리밍",
                    download: "다운로드",
                    other: "기타"
                },
                searchType: {
                    artist: "아티스트명",
                    album: "앨범명",
                    track: "트랙명"
                }
            }
        },
        api:{
            
        }
    },
    login: {
        title:"로그인",
        description: '로그인 페이지 입니다.',
        label: {
            email: "이메일주소",
            password: "비밀번호",
            pwdSave: "비밀번호저장",
            confirmBtn: "로그인하기",
            signup: "회원가입"
        },
        msg: {
            pwdMemory: "비밀번호가 생각나지 않으세요?",
            account: "사운드리퍼블리카 계정이 없으세요?",
            email: "이메일 주소를 정확히 입력해주세요",
            password: "비밀번호를 정확히 입력해주세요."
        },
        props: {
            
        },
        api:{
            
        },
        fail: {
            email: "이메일 주소를 정확히 입력해주세요.",
            password: "비밀번호를 정확히 입력해주세요.",
            login: "로그인에 실패했습니다. 이 오류가 반복되면 네트워크 관리자에게 문의하세요.",
            auth_mail: "이메일로 발송된 인증메일을 확인해주세요."

        }
    },
    logout: {
        description: '로그아웃 페이지 입니다.',
        label: {

        },
        msg: {
            content: '로그아웃 페이지 입니다.'
        },
        props: {
            
        },
        api:{
            
        }
    },
    forgetPassword: {
        title:"비밀번호찾기",
        description: '비밀번호찾기 입니다.',
        label: {
            email: "이메일주소",
            confirmBtn: "발송하기",
            resendBtn: '재발송하기',
            login: "로그인으로 돌아가기",
            password: "비밀번호가 생각나지 않으세요?"
        },
        msg: {
            password: "가입하신 이메일주소를 입력하시면, <br>비밀번호 재설정 링크를 보내드립니다.",
            emptyEmail: "이메일주소를 정확히 입력해주세요.",
            noAccount: "가입되지 않은 이메일주소입니다.",
            success: "아래의 이메일주소로<br>비밀번호 재설정 링크가 발송되었습니다.",
            sendEmail : "새로운 재설정링크 메일이 발송되었습니다.<br>가장 최근에 온 메일의 링크만 유효합니다."
        },
        props: {
            
        },
        api:{
            
        },
        fail: {
            email: "이메일 주소를 정확히 입력해주세요.",
            not_joined: "가입되지 않은 이메일주소입니다."
        }
    },
    resetPassword: {
        title:"비밀번호 재설정",
        description: '비밀번호 재설정',
        label: {
            password: "새로운 비밀번호를 입력해주세요.",
            resetBtn: "재설정하기",
            confirmBtn: "확인",
            success: "비밀번호 재설정 완료"
        },
        msg: {
            password: "영문자, 숫자, 기호 포함 8자 이상",
            failPassword: [
                "비밀번호 요구사항",
                "문자 길이 최소 8자",
                "하나이상의 영문자, 숫자, 기호 포함"
            ],
            success: "비밀번호가 재설정되었습니다. 새로운 비밀번호로 로그인해주세요."
        },
        props: {
            
        },
        api:{
            
        },
        fail: {
            password: {
                password: "비밀번호 요구사항",
                length: "문자 길이 최소 8자",
                characters: "하나이상의 영문자, 숫자, 기호 포함"
            }
        }
    },
    signup: {
        title: "회원가입",
        description: '회원가입 페이지입니다.',
        label: {
            email: "이메일 주소",
            password: "비밀번호 (영문자, 숫자, 기호 포함 8자 이상)",
            reference: "가입경로(선택)",
            signUpBtn: "가입하기",
            Terms: "이용약관",
            privacy: "개인정보수집 및 이용",
            confirm: "확인"
        },
        msg: {
            applyPrivacyPart1: "본인은 만 14세 이상이며,",
            applyPrivacyPart2: "의 내용을 확인하였으며, 동의합니다.",
            failPrivacy: "이용약관, 개인정보수집 및 이용에 동의해주세요.",
            applyMarketing: "[선택] 이메일로 사운리퍼블리카의 공지사항 및 다양한 혜택안내를 받겠습니다.",
            account : "이미 사운드리퍼블리카 계정이 있으세요?",
            login: "로그인",
            email_exists: "이미 등록된 이메일입니다.",
            successfully: "등록하신 이메일로 인증메일이 발송되었습니다."
        },
        props: {
            reference: {
                search: "검색",
                referral: "지인소개",
                advertisement: "광고",
                sns: "SNS",
                other: "기타",
                blog: "블로그",
                instargram:"인스타그램",
            }
        },
        api:{
            
        },
        fail: {
            email: "이메일 주소를 정확히 입력해주세요.",
            password: {
                password: "비밀번호 요구사항",
                length: "문자 길이 최소 8자",
                characters: "하나이상의 영문자, 숫자, 기호 포함"
            },
            privacy_Policy: "이용약관, 개인정보수집 및 이용에 동의해주세요."
        }
    },
    emailAuth: {
        title: {
            title: "회원가입완료",
            success : "이메일 인증 성공",
            fail: "이메일 인증 실패"
        },
        description: '이메일 인증 여부',
        label: {
            loginBtn: "로그인하기",
            resendEmailBtn: "인증메일 재발송하기"
        },
        msg: {
            success : "이메일 인증을 성공하였습니다.<br>로그인하려면 아래 버튼을 클릭해주세요",
            fail: "이메일 인증에 실패하였습니다.<br>인증메일 재발송 또는 고객센터에 문의해주세요"
        },
        props: {
        },
        api:{
            
        }
    },
    uncertified: {
        title: "미인증 이메일",
        description: '미인증 이메일 페이지',
        label: {
            email: "이메일 주소",
            resendEmailBtn: "인증메일 재발송하기",
            goLogin: "로그인으로 돌아가기",
            closeBtn: "확인"
        },
        msg: {
            guide: "가입하신 이메일주소를 인증해주세요!",
            guideDescription:"가입하신 이메일주소로 보내드린 인증메일에서 인증을 진행해주세요.",
            resendSuccess: "인증메일을 재발송하였습니다. 인증메일 확인 후 인증을 진행해주세요.",
            sendEmail: "이메일이 발송 되었습니다."
        },
        props: {
        },
        api:{
            
        },
        fail: {
            vertifyFail: "이메일 형식이 맞지 않습니다.",
            sendEmail: "관리자에게 문의하여 주십시오."
        }
    },
    my : {
        title: "마이페이지",
        description: '마이페이지',
        label: {
            basicInfo: "기본정보",
            id: "아이디",
            password: "비밀번호",
            siteLanguage: "사이트 언어",
            marketing: "마케팅 수신 동의",
            distributionInfo: "유통정보",
            registrationBtn: "등록하기",
            saveBtn: "저장하기",
            payment: "세금 및 지급정보",
            payment_popup_title: "세금 및 지급정보",
            withdrawBtn: "탈퇴하기",
            passwordBtn: "변경하기",
            birthday: "생년월일/설립연월일",
        },
        msg: {
            title: "유통 및 세금 지급을 위한 권리자 정보를 제출하고 확인할 수 있습니다.",
            marketing: "이메일로 사운드퍼블리카의 공지사항 및 다양한 혜택안내를 받겠습니다.",
            distribution: "이 정보는 음원유통신청을 위해 필요합니다.",
            distributionInfo: "변경이 필요하실 경우 고객센터로 문의주세요.",
            notice: `한 번 저장된 유통정보/세금정보/지급정보는 내용수정이 불가능합니다.  저장한 내용에 변경이 필요하실 경우 고객센터로 문의주세요.`,
            payment: "이 정보는 출금신청을 위해 필요합니다. 이 전 정보를 입력 완료하셔야 작성하실 수 있습니다.",
            siteLanguage: {
                ko: "한국어",
                en: "English"
            },
            activation_guide: "한 번에 두 가지 정보(유통정보, 세금 및 지급정보) 모두 작성하여 제출해주셔야 음원유통신청을 시작할 수 있습니다."
        },
        props: {
        },
        api:{
            
        }
    },
    distribution: {
        title: "유통정보",
        description: "유통정보",
        label: {
            nationality: "국적",
            mims: "MIMS 가입자명",
            mimsBtn: "MIMS 가입하기",
            division: "개인/사업자 구분",
            divisionSelect: {
                individual: "개인",
                corporation: "사업자 (개인/법인)", 
            },
            taxFree:'면세사업자',
            name: "이름",
            given_name: "이름",
            family_name: "성",
            companyName: "회사명",
            phone: "전화번호",
            countryCode: "국가번호 +",
            currency: {
                title: "정산화폐",
                money: {
                    usd: "미국 USD",
                    eur: "유로 EURO",
                    krw: "대한민국 KRW"
                }  
            },
            payment: {
                title: "지급방법",
                means: {
                    payoneer : "Payoneer",
                    paypal: "PayPal",
                    intl_banktransfer: "계좌이체(해외)",
                    kr_banktransfer: "계좌이체 (Korean Only)"
                }
            },
            payoneer: "Payoneer 란?",
            paypal: "PayPal 이란?",
            country: {
                title: "거주지국가"
            },
            confirmBtn: "등록",
            cancelBtn: "취소"
        },
        msg: {
            nationality: "국적을 선택하여 주세요",
            mims: "MIMS가입 후 가입자명을 입력해주세요.",
            name: "영문 또는 한글만 입력 가능합니다. 사업자일 경우 대표자명을 입력해주세요.",
            companyName: "회사명을 입력해주세요.",
            phone: "전화번호를 입력해주세요",
            country: "거주중인 국가를 선택해주세요.",
            countryTax:" 선택한 국가에 따라 세율 적용 및 세금 처리가 진행됩니다.",
            distribution: "이 정보는 음원유통신청을 위해 필요합니다.",
            barcode: "코드가 있을 경우, 입력해주세요.",
            distributionInfo: "변경이 필요하실 경우 고객센터로 문의주세요.",
            paypal_email: "PayPal ID(이메일)를 입력해주세요."
        },
        fail: {
            nationality: "국적을 선택하여 주세요",
            mims: "MIMS가입 후 가입자명을 입력해주세요.",
            division: "개인/사업자 구분을 선택해주세요.",
            family_name: "이름을 입력해주세요",
            given_name: "성을 입력해주세요.",
            companyName: "회사명을 입력해주세요",
            countryPhone: "국가번호를 선택해주세요.",
            phone: "전화번호를 입력해주세요",
            currency: "정산화폐를 선택해주세요.",
            payment: "지급방법을 선택해주세요.",
            countryCode: "거주지 국가를 선택해주세요.",
            paypal_email: "PayPal ID(이메일)를 입력해주세요."
        },
        props: {
            countryPhone: [
                '+82',
                '+34'
            ],
            countryCode: {
                kr: "대한민국",
                us: "UNITED STATES",
                es: "SPAIN"
            },
            terms: "약관"
        },
        api:{
            
        },
        tooltip: {
            mims: "대한민국 국적의 권리자는 MIMS에 제작자로 가입하여, 가입하신 분 성함(기업명)을 알려주셔야 원활한 코드 발급 진행이 가능합니다.",
            currency: "편리한 리포트 확인을 위해 선택하신 한 가지 통화로만 정보를 제공합니다.<br>"+ 
            "뮤직스토어에서 지급 받은 화폐는 정산 화폐로 환전되어 보여집니다.<br>환율은 환전시점이 적용되며, 정산화폐에 따라 지급 방법이 제한될 수 있습니다.",
            payment: "선택하신 정산화폐에 따라 지급 방법이 제한됩니다.<br>"+
            "[선택 화폐에 따른 지급 방법]<br>"+
            "KRW - 계좌이체<br>"+
            "USD - PayPal"
        }
    },
    taxInfo: {
        title: "세금정보",
        description: "세금정보",
        label: {
            taxpayerCode: "납세자번호",
            kr_resident: "주민등록번호",
            kr_business: "사업자등록번호",
            foreign_tax: "납세자번호",
            birthday: "생년월일(회사설립일)",
            month: "월",
            day: "일",
            year: "년도",
            address: "거주지주소(사업장소재지)",
            search: "주소검색",
            detail: "상세주소",
            address1: "Address1",
            address2: "Address2",
            district: "District",
            city: "City",
            postalCode: "Postal Code"
        },
        msg: {
            tax: "정보 변경이 필요하실 경우 고객센터로 문의주세요.",
            idCard: "'-' 없이 입력하여 주세요.",
        },
        fail: {
            idCard: "번호를 정확히 입력해주세요.",
            birthday: "날짜를 선택해주세요.",
            address: "거주지주소(사업장소재지)를 정확히 입력해주세요.",
        },
        props: {
        },
        api:{
            
        }
    },
    paymentInfo: {
        title: "지급정보",
        description: "지급정보",
        label: {
            accountHolder:"예금주(수취인)",
            accountNumber: "계좌번호",
            bank:"은행명",
            bankCode: "Bank Code",
            countryCode:"Country Code",
            bankAndBranchName: "Bank and Branch Name",
            branchAddress: "Branch Address",
            address1: "Address1",
            address2: "Address2",
            district: "District",
            city: "City",
            postalCode: "Postal Code",
            agree: "본인은 세금이 발생한 국가의 납세의무에 동의하며, 원천징수, 부가가치세 등 음원 유통으로 인해<br/>발생한 세금납부에 대한 신고 및 납부를 사운드리퍼블리카에 위임합니다.",
            confirmBtn: "등록",
            cancelBtn: "취소"
        },
        msg: {
            payment: "등록 후 변경이 필요하실 경우 고객센터로 문의주세요.",
            accountHolder: "예금주명을 입력해주세요",
            accountHolderGuide: "예금주는 권리자명과 동일해야 합니다.(사업자는 대표자명 또는 회사명)",
            accountNumber: "계좌번호를 정확하게 입력해주세요.",
            bank: "은행명을 선택해주세요.",
            bankCode: "Bank Code, SWIFT, BIC",
            countryCode: "은행의 국가를 선택해주세요.",
            bankAndBranchName: "Bank and Branch Name",
            notice: "원천징수, 부가가치세 등 음원 유통으로 인해 발생한 세금납부에 대한 신고 및 납부를 사운드리퍼블리카에 위임합니다."
        },
        fail: {
            accountHolder: "예금주를 정확히 입력해주세요.",
            accountNumber: "계좌번호를 정확히 입력해주세요.",
            bank: "은행을 선택해주세요.",
            bankCode: "Bank Code를 정확히 입력해주세요.",
            bankCountryCode: "은행의 국가를 선택해주세요.",
            bankAndBranchName: "Bank And Branch Name을 정확히 입력해주세요.",
            branchAddress: "Branch Address를 정확히 입력해주세요.",
            agree: "세금납부 위임에 동의해주세요."
        },
        props: {
            bank: [
                "우리은행",
                "하나은행",
                "신한은행"
            ],
            bankCountryCode: {
                kr: "한국",
                US: "UNITED STATES",
                ES: "SPAIN"
            }
        },
        api:{
            
        }
    },
    management: {
        title: "앨범/아티스트 관리",
        description: "앨범/아티스트 정보",
        label: {
            album: {
                title: "앨범관리",
                sort: "정렬",
                state: "상태",
                artistList: "아티스트명",
                addAlbum: "앨범 추가하기",
                explicit: "EXPLICIT",
                temporaryStorage: "임시저장",
                registrationDate: "제출일",
                releaseDate: "발매일",
                albumCount: "조회 수",
                delete: "삭제"
            },
            artist: {
                title: "아티스트관리",
                addArtist: "아티스트 등록",
                artistCount: "조회 수"
            },
            search: "검색",
            moveBtn: "이동"
        },
        msg: {
            payment: "권리자정보 변경이 필요하실 경우 고객센터로 문의주세요.",
            accountHolder: "예금주명을 입력해주세요",
            accountHolderGuide: "예금주는 권리자명과 동일해야 합니다.(사업자는 대표자명 또는 회사명)",
            accountNumber: "계좌번호를 정확하게 입력해주세요.",
            bank: "은행명을 선택해주세요.",
            bankCode: "Bank Code, SWIFT, BIC",
            countryCode: "은행의 국가를 선택해주세요.",
            bankAndBranchName: "Bank and Branch Name",
            notice: "세금 발생국 납세의무를 다하며, 세금 대리 납부를 사운드리퍼블리카에 위임합니다.",
            title: '등록한 앨범과 아티스트를 확인할 수 있습니다.',
            none_artistList: "등록된 아티스트가 없습니다. 아티스트를 등록해주세요.",
            none_albumList: "등록된 앨범이 없습니다. 앨범을 추가해주세요!",
            none_licensor: "현재 유통정보, 세금정보, 지급정보가 없습니다.<br>마이페이지에서 등록 후 이용 부탁드립니다."
            
        },
        fail: {
            accountHolder: "예금주를 정확히 입력해주세요.",
            accountNumber: "계좌번호를 정확히 입력해주세요.",
            bank: "은행을 선택해주세요.",
            bankCode: "Bank Code를 정확히 입력해주세요.",
            countryCode: "Country Code를 선택해주세요.",
            bankAndBranchName: "Bank And Branch Name을 정확히 입력해주세요.",
            branchAddress: "Branch Address를 정확히 입력해주세요.",
            agree: "세금납부 위임에 동의해주세요."
        },
        props: {
            sort: {
                album: {
                    created_at: "최근 등록순",
                    release_date: "최근 발매순",
                    name: "이름순"
                },
                artist: {
                    display_name: "이름순",
                    created_at: "최근 등록순"
                }
            },
            search: {
                all: "전체"
            }
        },
        api:{
            
        }
    },
    distributionMusic: {
        title: "음악유통하기",
        label: {
            defaultInfo: "앨범 기본정보",
            coverImg: "앨범커버이미지",
            albumName: "대표앨범명",
            albumName_en: "앨범명(번역)",
            re_artist: "대표아티스트",                  // Representative Artist
            re_artist_select: "대표아티스트 선택",       // Select Representative Artist
            genre: "앨범장르",
            genre_select: "장르선택",
            barcode: "UPC/EAN",
            barcode_generate: "코드발급신청", //더이상 수집하지 않는 항목
            agency: "기획사",
            agency_en: "기획사(번역)",//더이상 수집하지 않는 항목
            desired_release_date:"발매희망일",
            none_desired_release_date: "발매희망일 없음", //더이상 수집하지 않는 항목
            addInfo: {
                title: "앨범추가정보",
                title_etc: "(선택사항)",
                album_agency_image: "앨범기획사이미지",
                credit: "크레딧",
                original_release_date: "기발매일",
                albumIntrouce: "앨범소개",
                albumIntrouce_en: "앨범 소개(번역)" //더이상 수집하지 않는 항목
            },
            trackInfo: {
                title: "트랙정보",
                list: "트랙리스트"

            },
            musicStore: {
                title: "발매정보",
                musicStoreView: "뮤직스토어리스트보기 > ",
                country_release: "뮤직스토어",
                countryExcept: "특정국가 뮤직스토어 제외"
            },
            contract: {
                title: "유통계약"
            },
            rejected: {
                title: "반려사유",
                rejected_date: "반려처리일",
                complaint_apply: "반려 이의신청하기" //사용하지 않는 항목
            },
            agree: "동의하기",
            confirmBtn: "음악유통 신청하기",
            deleteBtn: "삭제하기",
            temporaryBtn: "임시저장",
            cancelBtn: "취소",
            moveBtn:"이동",
            closeBtn: "닫기"
        },
        msg: {
            title: "앨범 및 트랙 정보를 등록하고 발매를 신청할 수 있습니다.",  //사용하지 않는 항목
            coverImg: "이미지파일을 등록해주세요.",
            albumName: "작성해주신 대표앨범명으로 발매되며, 일부 스토어는 언어별 앨범명으로 발매됩니다. ",
            albumNameGuide: "앨범명을 입력해주세요.",
            albumNameGuide_en: "언어별로 앨범명을 등록해주세요.",
            genre: "장르는 최대 2개까지 선택하실 수 있습니다.",
            barcode: "발급 받은 코드가 있는 경우 입력하고, 없으면 공란으로 두세요.",
            barcode_generate: "본인은 해당 코드가 없으며, 새로운 코드를 발급하는 것에 동의합니다.", //사용하지 않는 항목
            albumName_en_generate: "영문명 없음.  미입력시, 모든 스토어에 원언어로 발매됩니다.",//사용하지 않는 항목
            agency: "기획사명을 입력해주세요.",
            agency_en: "기획사 번역이 있다면 입력해주세요.", //사용하지 않는 항목
            agency_en_generate: "영문명 없음.  미입력시, 모든 스토어에 원언어로 발매됩니다.", //사용하지 않는 항목
            none_desired_release_date: 
                "특정 발매일을 모든 발매일은 담당자 확인 후 확정됩니다." // Please understand that we are not always able to release on your requested date.
            ,
            none_desired_release_date2 : "담당자와 상의하여 확정한 발매일이 있는 경우, 발매희망일은 공란으로 제출해주세요.", // Leave the “Desired release date” BLANK if you have been in contact with us and received CONFIMRATION on your release date.

            none_licensor: "현재 유통정보, 세금정보, 지급정보가 없습니다.<br>마이페이지에서 등록 후 이용 부탁드립니다.",
            addInfo: {
                album_agency_image: "EDM/DANCE 장르 전문 스토어 Beatport에 유통희망시, 앨범기획사 이미지를 등록해주세요.",
                credit: "앨범제작 참여자들을 입력해주세요.",
                creditGuide: "앨범 Credit을 입력해주세요.", //사용하지 않는 항목
                original_release_date: "이미 발매했던 앨범일 경우 기존 발매일을 입력해주세요.",
                introduce: "", //사용하지 않는 항목
                description: "", //사용하지 않는 항목
                intruceGuide: "앨범소개를 입력해주세요.",
                introuce_en: ""//사용하지 않는 항목
            },
            trackInfo: {
                list: "트랙을 선택하여 상세정보를 입력해주세요.",
                listGuide: "타이틀곡은 볼륨별로 최대 2곡까지 설정할 수 있습니다."
            },
            musicStore: {
                country_release: "선택한 국가의 뮤직스토어에 발매가 진행됩니다.",
                guide: "각 뮤직스토어의 정책에 따라 일부 뮤직스토어에서는 예고없이 음원 발매가 제한될 수 있습니다."+
                "<br>e.g.) Beatport (Dance 또는 EDM 장르만), Highresaudio (24bit 이상 고음질 음원만)"
            },
            contract: {
                guide: "약관내용이 노출됩니다."
            },
            agree: "등록음원에 대한 저작권인접권 소유 및 제 3자의 지적재산권을 침해하지 않음을 확인하며, 약관내용에 동의합니다.",
            rejected: "반려사유를 해결하고 다시 접수해주세요. 메타데이터 기타 추가자료는 메일로 전달주세요.",
            completed: "",//사용하지 않는 항목
            completed_guide: "",//사용하지 않는 항목
            save: "저장되었습니다.",
            saving: "저장 중 ...... 잠시만 기다려 주세요.",
            registering: "등록 중 ...... 잠시만 기다려 주세요."
        },
        fail: {
            album_name: "앨범명을 입력해주세요.",
            album_name_en: "언어를 선택하고 내용을 입력해주세요.",
            agencyName_en: "",//사용하지않는 항목
            artist: "아티스트를 선택해주세요",
            genre: "장르를 선택해주세요",
            barcode: "발급된 코드가 있다면 입력해주세요. 없는 경우 공란으로 두세요.",
            track: "트랙을 추가해주세요.",
            desired_release_date: "발매희망일을 선택해주세요.",
            agree: "약관내용에 동의해주세요."
        },
        props: {
            language: {
                ko: "한국어",
                en: "English"
            },
            label_language: {
                ko: "한국어",
                en: "English"
            },
            introuce_language: {
                ko: "한국어",
                en: "English"
            },
            trackList: {
                no: "No.",
                track: "트랙 명",
                type: "유형",
                artist: "아티스트 명",
                title: "타이틀 곡"
            },
            musicStore: {
                global: "전체뮤직스토어",
                korea: "대한민국 뮤직스토어"
            },
            date: "YYYY-MM-DD",
            countryExcept: {
                kr: "대한민국 (해외 플랫폼 만 발매)"
            }
        },
        tooltip: {
            title: "대표명에 병기를 하시는 경우, 언어별 앨범명을 꼭 분리해서 작성해주세요.<br>"+
            "각 스토어마다 명칭 공급 규칙이 달라서, 앨범명에 병기를 허용하는 스토어들에게는 대표명이 전달됩니다.<br>"+
            "예시) <br>"+
            "대표명 : 사운드리퍼블리카(Sound Republica) <br>"+
            "앨범명(번역) : <br>"+
            "[한국어] 사운드리퍼블리카<br>"+
            "[영어] Sound Republica<br><br>"+
            "- 이름은 정확하고 올바른 형식이어야 합니다. 맞춤법 문법 및 구두점을 확인해주세요.<br>"+
            "- 콘텐츠에는 광고가 포함되거나 너무 일반적인 단어를 제목을 사용하면 발매가 제한될 수 있습니다.<br>",
        
            genre: "뮤직스토어마다 사용하는 장르가 달라, 신청한 장르와 다르게 발매될 수 있습니다.",
            upc: "UPC(Universal Product Code, 범용 상품 부호)는 12자리 바코드, EAN(European Article Number,유럽 상품 번호)는 13자리 바코드로 전 세계적으로 매장에서 상품을 추적하기 위해 널리 사용되고 있는 코드 입니다. 발급받은 코드가 있다면 써주시고 아니라면 공란으로 두세요.",
            agency: "기획사와 같은 개념으로, 실제 소속된 레이블이 아니어도 되고, 가상의 이름을 넣어주셔도 됩니다.<br>"+
            "공란으로 두실 경우, 아티스트 이름으로 표기 됩니다.",
            credit: "크레딧 정보는 앨범소개와 함께 전달됩니다. 이 앨범 제작과 유통에 관련된 모든 사람을 기재할 수 있습니다. <br>"+ 
            "예를 들어, 이그제큐티브 프로듀서, 아티스트 매니저, 비주얼 프로덕션, 팬 마케팅, 아티스트 개발, 재무 관리,<br>음악 프로듀셔, 크리에이티브 디렉터, 홍보 프로모션, 안무, 스타일리스트, 헤어&메이크업, 사진작가 등등",
            trackList: {
                main_track: "타이틀곡은 앨범당 최대 2곡까지 설정하실 수 있습니다."
            }
        }
    },
    components: {
        navBar: {
            description : '상단 네비게이션 바',
            label: {
                home_mv: 'Home',
                aboutUs_mv: 'Sound Republica',
                management: "앨범/아티스트관리",
                royalties: "정산/출금",
                statistics: "통계",
                my:"마이페이지",
                cost_mv: '비용',
                customerService_mv: '고객센터',
                distribution: '음악유통하기',
                login: '로그인',
                logout: '로그아웃',
                singup: '회원가입',
                contact : '유통문의'
            },
            msg: {

            },
            props: {
                language: {
                    ko: "한국어",
                    en: "English"
                }
            },
            api:{
                
            }
            
        },
        footer: {
            description: "Footer",
            label: {
                menu: {
                    company: "회사 소개",
                    album_register: "앨범등록",
                    cost: "비용",
                    management: "앨범/아티스트관리",
                    accounting: "정산/출금",
                    statistics: "통계",
                    my: "마이페이지",
                    customerService: "고객센터"
                },
                address: [
                    "(주) 사운드리퍼블리카",
                    "대표자: 노건식, 김태윤",
                    "주소: 서울특별시 중구 남대문로9길 40, 센터플레이스 20층"
                ],
                notify: [
                    "통신판매업 신고: 제 2019-서울중구-1206 호",
                    "개인정보보호책임자: 김태윤",
                    "사업자등록번호: 280-88-01127"
                ],
                copyright: "Copyright © Sound Republica, Inc., All Rights Reserved.",
                privacy_policy: "개인정보처리방침",
                terms_services: "이용약관",
                goTop: "상단 바로가기",
                confirmBtn: "확인"

            },
            msg: {
                title: [
                    "사운드리퍼블리카 SNS",
                    "를 팔로우하고 새로운 음악소식을 만나보세요!"
                ],
            }
        },
        vueCarousel: {
            description: "회전 목마형 UI",
            label: {

            },
            msg: {

            },
            props: {

            },
            api:{
                
            }
        },
        panel: {
            title: "팝업페이지",
            description: "Panel",
            panel1: {
                title: "비밀번호 변경",
                description: "비밀번호변경",
                label: {
                    old_password: "현재 비밀번호",
                    new_password: "새 비밀번호",
                    confirmBtn: "변경하기",
                    cancelBtn: "취소하기"
                },
                msg: {
                    close: "닫기",
                    old_password : "현재 비밀번호를 입력해주세요.",
                    new_password: "영문자, 숫자, 기호 포함 8자 이상",
                    success: "비밀번호가 변경되었습니다."
                },
                props: {

                },
                api:{
                    
                },
                fail: {
                    old_password: "현재 사용중인 비밀번호를 입력해주세요.",
                    password: {
                        password: "비밀번호 요구사항",
                        length: "문자 길이 최소 8자",
                        characters: "하나이상의 영문자, 숫자, 기호 포함"
                    }
                }
            },
            panel2: {
                title: "아티스트 등록",
                description: "아티스트 추가 신규등록",
                navi: "대표아티스트 > ",
                label: {
                    title: "아티스트 기본정보",
                    artistImg: "아티스트 대표이미지",
                    img: [
                        "대표이미지",
                        "추가이미지1",
                        "추가이미지2"
                    ],
                    guide: "권장 이미지 가이드 보기",
                    noneImg: "이미지 없음",
                    name: "아티스트명",
                    enName: "아티스트명(번역)",
                    nationality: "아티스트 국적",
                    type: "아티스트 유형",
                    addInfo: "아티스트 추가정보",
                    addInfo_etc: "(선택사항)",
                    debutyear: "데뷔년도",
                    introduce: "아티스트 소개",
                    introduce_en: "아티스트 소개(번역)",
                    sns:"URL",
                    confirmBtn: "저장",
                    cancelBtn: "취소",
                    add: "추가",
                    delete: "삭제"
                },
                msg: {
                    artistImg_guide: "이미지 규격: 최소 600px 이상 / 72dpi 이상",
                    title: "작성주의! 아티스트는 정보는 등록 후 수정이 불가능합니다.",
                    input_artist: "아티스트명을 입력해주세요.",
                    input_artist_en: "아티스트명을 한국어 및 영문 언어별로 입력해주세요.",
                    img: "마우스로 드래그하거나 클릭하여 추가해주세요.",
                    introduce: "아티스트 소개를 입력해주세요.",
                    introduce_en: "아티스트 영문소개를 입력해주세요.", //쓰지않는항목
                    sns: "URL을 입력하여 주십시오.",
                    none_sns: "URL을 입력하여 주십시오.",
                    none_sns_type : "유형을 선택해주세요.",
                    not_delete_sns: "삭제할 입력란이 없습니다.",
                    name: "",
                    artist_image: '대표이미지 첨부를 희망하지 않는다면 "이미지 없음"에 체크해주세요.',
                    display_name_english_generate: "",//쓰지않는항목
                    artist_exists: "이미 등록된 아티스트 이름이 있습니다."
                },
                props: {
                    language: {
                        ko: "한국어",
                        en: "English",         // 영어
                        ja: "日本語",           // 일본어
                        es: "Espanol",         // 스페인어
                        de: "Deutsch",         // 독일어
                        nl: "Nederlands",      // 네덜란드어
                        zhCn: "简体中文",       // 중국어 (간체)
                        zhTw: "繁體中文"        // 중국어 (번체)
                    },
                    nationality : {
                        kr: "대한민국",
                        us: "United States",    // 미국
                        jp: "日本",             // 일본
                        es: "España",           // 스페인
                        de: "Deutschland",      // 독일
                        nl: "Nederland",        // 네덜란드
                        cn: "中国"              // 중국
                    },
                    sns: {
                        youtube: "YouTube",
                        facebook: "Facebook",
                        twitter: "Twitter",
                        website: "Website",
                        instagram: "Instagram",
                    },
                    artistType: {
                        solo: "남성솔로"
                    }
                },
                fail: {
                    artistName_en: "언어를 선택하고 아티스트명을 입력해주세요.",
                    artistName: "아티스트명을 정확히 입력해주세요.",
                    nationality: "아티스트 국적을 선택해주세요.",
                    type: "아티스트 유형을 선택해주세요.",
                    fileUpload: "파일 업로드 실패입니다. 잠시 후 에 다시 등록해주십시오."
                },
                api:{
                    
                }
            },
            panel3: {
                title: "아티스트 정보",
                description: "아티스트 정보",
                label: {
                    nationality: "아티스트 국적",
                    type: "아티스트 유형",
                    debutYear: "데뷔년도",
                    introduce: "아티스트 소개",
                    enIntroduce: "영문 아티스트 소개",
                    sns: "URL",
                    recentlyAlbum: "발매 앨범",
                    sumAlbum: "총 앨범수",
                    list_column: {
                        albumName: "앨범 명",
                        tracks: "트랙 수",
                        date: "발매일",
                    },
                    more: "전체앨범보기",
                    confirmBtn: "저장하기",
                    cancelBtn: "취소하기"
                },
                msg: {
                    serviceInfo : "아티스트 정보수정은 고객센터로 문의해주세요."
                },
                props: {
                    language: {
                        ko: "한국어",
                        en: "English",         // 영어
                        ja: "日本語",           // 일본어
                        es: "Espanol",         // 스페인어
                        de: "Deutsch",         // 독일어
                        nl: "Nederlands",      // 네덜란드어
                        zhCn: "简体中文",       // 중국어 (간체)
                        zhTw: "繁體中文"        // 중국어 (번체)
                    }
                },
                api:{
                    
                }
            },
            panel4: {
                title: "앨범 상세정보",
                description: "앨범 정보",
                label: {
                    status: {
                        ready: "발매준비중"
                    },
                    albumInfo: "앨범정보",
                    returnYn: "반려여부",
                    releaseDate: "발매일",
                    releaseArea: "발매지역",
                    upcEanCode: "UPC/EAN 코드",
                    coverImg: "커버이미지",
                    albumName: "대표앨범명",
                    re_artist: "대표아티스트",
                    releaseCountry: "뮤직스토어 및 발매 국가",
                    agency: "앨범기획사",
                    labelImg: "기획사이미지",
                    albumIntrouce: "앨범소개",
                    credits: "크레딧",
                    releasedDate : "기발매일",
                    genre: "앨범장르",

                    trackInfo: "트랙정보",
                    trackSum: "총 트랙수",
                    volumeNo: "Disc 번호",
                    trackNo: "트랙번호",
                    titleSong: "타이틀곡",
                    trackFile: "오디오파일",
                    isrc: "ISRC코드",
                    uci: "UPC/EAN",
                    trackName: "트랙명",
                    musicStore: "발매정보",
                    more: "뮤직스토어 리스트보기 >",
                    confirmBtn: "유통신청 취소하기",
                    cancelBtn: "닫기",
                    track_list_column: [
                        "Vol.",
                        "No.",
                        "트랙명",
                        "아티스트명"
                    ],
                    explicit: "EXPLICIT",
                    various_artist: "Various Artists",
                    excluded: " 제외"
                },
                msg: {
                    guide: "일부 뮤직스토어는 정책에 따라 음원발매가 제한될 수 있습니다.",
                    musicStore_title: "선택한 국가의 뮤직스토어에 발매가 진행됩니다."
                },
                props: {
                    introduce_language: {
                        kr: "한국어",
                        en: "English"
                    },
                    country: {
                        global: "전체국가",
                        korea: "대한민국"
                    }
                },
                api:{
                    
                }
            },
            panel5: {
                description: "고객센터",
                label: {
                    prev: "< 이전 글",
                    next: "다음글 >"
                },
                msg: {
                },
                props: {   
                },
                api:{   
                }
            },
            panel6: {
                title: "1 : 1 문의하기",
                description: "1:1 문의하기",
                label: {
                    type: "문의유형",
                    email: "이메일주소",
                    title: "제목",
                    content: "내용",
                    confirmBtn: "보내기",
                    cancelBtn: "닫기"
                },
                msg: {
                    type: "문의유형을 선택해주세요.",
                    contentTitle: "문의제목을 입력해주세요.",
                    content: " 문의 내용을 입력해주세요."
                },
                props: {   
                },
                api:{   
                }
            },
            panel7: {
                title: "FAQ",
                description: "FAQ",
                label: {
                    cancelBtn: "닫기"
                },
                msg: {
                },
                props: {   
                },
                api:{   
                }
            },
            panel9: {
                title: "TRACK",
                description: "Track 정보",
                label: {
                    add: "추가",
                    delete: "삭제",
                    confirmBtn: "확인",
                    cancelBtn: "취소",
                    track:{
                        title: "트랙기본정보",
                        track: "트랙명",
                        track_en: "트랙명(번역)",
                        artist: "아티스트",
                        artist_select: "아티스트 선택",
                        genre: "트랙 장르",
                        genre_select: "장르선택",
                        composer: "작곡가",
                        isrc: "ISRC",
                        isrcCode_apply: "코드 발급신청",
                        uciCode_apply: "코드 발급신청",
                        uci: "UCI",
                        explicit: "선정성여부(EXPLICIT)",
                        more_explicit: "선정성 예시보기"
                    },
                    etc: {
                        title: "트랙추가정보",
                        title_ex: "(선택사항)",
                        type: "트랙 버전",
                        featuring: "피처링 아티스트",
                        featuring_en: "피처링 아티스트(번역)",
                        lyricist: "작사가",
                        arranger: "편곡가",
                        lyrics: "가사"

                    },
                    audio: {
                        title: "오디오 파일 정보"
                    }
                },
                msg: {
                    track:{
                        track: "대표트랙명으로 발매되며, 일부 스토어는 트랙명(번역)으로 발매됩니다.",
                        trackName: "트랙명을 입력해주세요.",
                        track_en: "언어를 선택하고 트랙명을 입력해주세요.",
                        genre: "장르는 최대 2개까지 선택하실 수 있습니다.",
                        composer: "작곡가명을 입력해주세요.",
                        delete_composer: "더이상 삭제할 작곡가 정보가 없습니다.",
                        management: "저작권관리사명을 입력해주세요.",
                        isrc: "발급된 코드가 있는 경우에만 입력해주세요.",
                        uci: "발급된 코드가 있는 경우에만 입력해주세요",
                        explicit: "선정적인 내용을 포함하고 있습니다.",
                        isrcCode_apply: "본인은 해당 코드가 없으며, 새로운 코드를 발급하는 것에 동의합니다.",
                        uciCode_apply: "본인은 해당 코드가 없으며, 새로운 코드를 발급하는 것에 동의합니다."
                    },
                    etc: {
                        type: "트랙버전을 선택해주세요.",
                        featuring: "여러명일 경우 콤마(,)로 구분하여 입력해주세요.",
                        featuring_guide: "피처링 아티스트를 입력해주세요.(여러명일 때 콤마(,)로 구분)",
                        featuring_en: "영문 피처링 아티스트를 입력해주세요.(여러명일때 콤마(,)로 구분",//더이상 수집하지 않는 항목
                        lyricist: "작사가명을 입력해주세요.",
                        management: "저작권관리사명을 입력해주세요.",
                        arranger: "편곡가명을 입력해주세요.",
                        lyrics: "가사를 입력해주세요."
                    },
                    audio: {
                        title: "음원파일을 추가해주세요.(FLAC 또는 WAV 파일필수)",
                        guide: [
                            "필수: FLAC 또는 WAV(44.1khz/16bit 이상)  ",
                            "선택: MP3(320kbps)",
                            "파일 크기 : 파일당 200MB 이내",
                        ]
                    }
                },
                props: {
                    column: [
                        "파일명",
                        "비트레이트",
                        "샘플레이트",
                        "유형",
                        "곡길이"
                    ],
                    titleRanking: {
                        1: "1st",
                        2: "2nd"
                    },
                    type: {
                        original: "Original",
                        live: "Live",
                        instrumental: "Instrumental",
                        acoustic: "Acoustic",
                        acapella: "Acapella",
                        extended_version: "Extended Version",
                        radio_edit: "Radio_Edit",
                        clean: "Clean",
                        cover: "Cover",
                        remastered: "Remastered",
                        re_recorded: "Re-recorded",
                        remix: "Remix",
                        remake: "Remake",
                        soundtrack: "Soundtrack",
                        bonus_track: "Bonus Track",
                        hidden_track: "Hidden Track",
                        etc: "Etc"
                    }
                },
                api:{   
                },
                tooltip: {
                    track:  
                    "대표명에 언어 병기 하는 경우, 트랙명(번역)에 언어별로 트랙명도 작성해주세요.<br>"+
                    "각 스토어마다 명칭 공급 규칙이 달라서, 앨범명에 병기를 허용하는 스토어들에게는 대표명이 전달됩니다.<br>"+
                    "예시) <br>"+
                    "대표명 : 사운드리퍼블리카(Sound Republica)<br>"+
                    "번역 : <br>"+
                    "[한국어] 사운드리퍼블리카<br>"+
                    "[영어] Sound Reublica<br><br>"+
                    " -  1개짜리 트랙 싱글 앨범을 발매하는 경우, 앨범 이름과 트랙 이름이 동일해야 합니다.<br>"+
                    "- 이름은 정확하고 올바른 형식이어야 합니다. 맞춤법 문법 및 구두점을 확인해주세요.<br>"+
                    "- 콘텐츠에는 광고가 포함되거나 너무 일반적인 단어를 제목을 사용하면 발매가 제한될 수 있습니다.",
                    variousArtist: "트랙단위 Various Artists표기를 금지하는 스토어가 있습니다. 이 경우 Various Artists 로 입력시 앨범기획사명이 대신 표기 될 수 있습니다.",
                    genre: "뮤직스토어마다 사용하는 장르가 달라, 신청한 장르와 다르게 발매될 수 있습니다.",
                    isrc: "ISRC(국제 표준 녹음 코드,International Standard Recording Code)는<br>녹음 및 비디오 녹음을 위한 국제 식별 코드로 정해진 기관에서 발급합니다. <br> 한국 제작자의 경우,  MIMS 가입자명을 유통사에 알려주셔야 코드 발급이 가능합니다. <br/> 발급받은 코드가 있는 경우 입력하고, 없으면 공란으로 두세요.",
                    explicit: "'선정적이거나 부적절한 표현 포함 여부(EXPLICIT)'를 제대로 표시하지 않고 유통하게 될 경우, 법적으로 불이익을 받을 수 있으며 이 때 모든 책임은 권리자에게 있습니다.<br><br>"+
                    "한 곡의 제목 및 가사가 아이들에게 불쾌감을 주거나 적합하지 않다고 여겨질 수 있는 기준들 중 하나 이상을 포함하고 있는 경우 Y. <br>"+ 
                    "-폭력, 신체적 또는 정신적 학대에 대한 언급 <br>"+ 
                    "-성차별적 행동에 대한 언급 <br>"+ 
                    "-차별적인 언어. <br>"+ 
                    "선정적이거나 부적절한 표현이 포함되었다면, 자체 검열 하지 말고 그 표현을 그대로 적어주세요. 이렇게 쓰지 마세요: F'**'k (X)",
                    type: "Remake 및 Remix ver. 트랙 유통시 뮤직스토어에서 추가적인 서류 확인을 요청할 수 있습니다.",
                    featuring_artist: "피처링 아티스트는 스토어에서 트랙 타이틀 옆에 표기됩니다.",
                    lyrics: 
                    "가사는 정확하고 올바른 형식이어야 합니다. 맞춤법, 문법 및  구두점을 확인해주세요.<br>"+
                    "<실시간 가사 싱크를 맞추기 위한 디테일><br>"+
                    "- 가사는 오디오에서 들리는 말과 일치해야 합니다.<br>"+
                    "- 모든 가사는 행마다 한 칸씩 띄고, 절(스탠자)마다 두 칸씩 띄워주세요.<br>"+
                    "- 쉽게 식별할 수 있는 비언어적 표현도 가사로 기록되어야 합니다. e.g.)와아아",
                    contributor : `(필수) 작곡가를 1명 이상 등록해주세요. <br>
                    (주의) 크레딧에 기재한 트랙 참여자를 동일하게 추가해주세요. <br>
                    가입된 저작권관리사가 없으면 공란으로 두세요.`,
                },
                fail: {
                    track: {
                        trackName: "트랙명을 정확히 입력해주세요.",
                        trackName_en: "언어를 선택하고 앨범명을 입력해주세요.",
                        artists: "아티스트를 선택해주세요.",
                        genre: "장르를 선택해주세요",
                        composer: "작곡가정보를  입력해주세요.",
                        isrc: "발급받은 코드가 없다면 공란으로 두세요.",
                        uci: "발급받은 코드가 없다면 공란으로 두세요.."
                    }
                }
            },
            panel12: { //안쓰는패널
                title: "대표 아티스트",
                description: "대표아티스트",
                label: {
                    variousArtist: "Various Artist",
                    searchBtn: "검색",
                    sort: "정렬",
                    artistRegister: "+ 아티스트 등록",
                    confirmBtn: "완료",
                    cancelBtn: "취소",
                    track: "TRACK"
                },
                msg: {
                    search: "검색할 아티스트명을 입력해주세요.",
                    artistCount: ""
                },
                props: { 
                    sort: {
                        created_at: "최근 등록 순",
                        display_name: "이름 순"
                    } 
                },
                api:{   
                }
            },
            panel13: {
                title: "장르 선택",
                description: "장르 선택 관리",
                label: {
                    variousArtist: "Various Artists (아티스트가 5명 이상일 경우 체크해주세요.)",
                    searchBtn: "장르명 검색",
                    sort: "정렬",
                    artistRegister: "+ 아티스트 등록",
                    confirmBtn: "완료",
                    cancelBtn: "취소",
                    track: "TRACK"
                },
                msg: {
                    serach: "검색할 장르명을 입력해주세요.",
                    genreCount: "장르는 최대 2개까지 선택하실 수 있습니다."
                },
                props: { 
                    sort: {
                        date: "최근 등록 순",
                        name: "이름 순"
                    } 
                },
                api:{   
                }
            },
            panel14: {
                title: "뮤직스토어 리스트",
                description: "뮤직스토어 리스트",
                label: {
                    pre_title: "앨범정보",
                    confirmBtn: "닫기"
                },
                msg: {},
                props: {
                },
                api:{}
            },
            panel15: {
                title: "대표아티스트",
                description: "대표아티스트",
                label: {
                    variousArtist: "Various Artists (아티스트가 5명 이상일 경우 체크해주세요.)",
                    searchBtn: "검색",
                    sort: "정렬",
                    artistRegister: "+ 아티스트 등록",
                    confirmBtn: "완료",
                    cancelBtn: "취소"
                },
                msg: {
                    search: "검색할 아티스트명을 입력해주세요.",
                    artistCount: "아티스트는 최대 4명까지 선택하실 수 있습니다. 5명 이상일 경우 상단의 Various Artist를 체크해주세요."
                },
                props: { 
                    sort: {
                        created_at: "최근 등록 순",
                        display_name: "이름 순"
                    } 
                },
                api:{   
                }
            },
            panel16: {
                title: "장르 선택",
                    description: "장르 선택 관리",
                    label: {
                        searchBtn: "검색",
                        confirmBtn: "완료",
                        cancelBtn: "취소",
                        all: "All",
                        genreRegister: "+ 신규 장르 등록",
                    },
                    msg: {
                        serach: "검색할 장르명을 입력해주세요.",
                        genreCount: "장르는 최대 2개까지 선택하실 수 있습니다." 
                    },
                    props: { 
                    },
                    api:{   
                    }
            },
            panel17: {
                title: "트랙정보",
                    description: "트랙 상세정보",
                    label: {
                        title: "트랙기본정보",
                        navi: {
                            prev: "앨범 정보"
                        },
                        explicit: "EXPLICIT",
                        volume: "Disc 및 트랙번호",
                        name: "트랙명",
                        artist: "아티스트",
                        genre: "장르",
                        type: "트랙버전",
                        featuring_artist: "피처링 아티스트",
                        composer: "작곡가 / 저작권관리사",
                        lyricist: "작사가 / 저작권관리사",
                        arranger: "편곡가 / 저작권관리사",
                        barcode: "곡 코드",
                        lyrics: "가사",
                        fileInfo: "오디오파일정보",
                        fileList_column: [
                            "파일명",
                            "유형",
                            "곡길이"
                        ],
                        closeBtn: "닫기",
                        various_artist: "Various Artist",
                        uci: "UCI",
                        isrc: "ISRC"
                    },
                    msg: {
                    },
                    props: {
                        language: {
                            kr: "한국어",
                            en: "English"
                        },
                        volume: "Disc",
                        track: "TRACK"
                    },
                    api:{   
                    }
            },
            panel18: {
                title: "출금신청",
                    description: "출금신청하기 POPUP",
                    label: {
                        amount: "출금신청금액",
                        withdrawal_notice: "출금안내사항",
                        applyBtn: "출금신청하기",
                        cancelBtn: "닫기",
                        payoneer: "Payoneer 더 알아보기 >",
                        payoneer_connect: "Payoneer 계정 연결하기",
                        payoneer_faq: "[Payoneer FAQ] 왜 Payoneer 계정이 승인되지 않죠? >",
                        confirmBtn: "확인",
                    },
                    msg: {
                        withdrawal_notice: [
                            '[주의] 출금신청 접수 마감일 : 월의 마지막 영업일 전일',
                            "출금신청 및 계산서 발행은 월의 마지막 영업일 전일까지 부탁드립니다.",
                            "월 지급 마감이후 신청은 최대 30일 이내로 지급됩니다.",
                            "출금 신청 금액에서 각종 세금 및 수수료가 차감된 금액이 최종 지급됩니다.",
                            `면세사업자로 사업자 구분이 변경되신 경우, 출금신청 전에 담당자에게 정보를 업데이트해주세요.\n (정산담당자 문의메일  : report@soundrepublica.com)`
                        ],
                        ready_payoneer: [
                            "Payoneer 계정을 연결하세요. (최초1회)",
                            "가입 승인에 시간이 소요되며, 승인이 완료되면 출금신청이 가능합니다."
                        ],
                        waiting_payoneer: [
                            "Payoneer 계정 승인 단계가 진행중입니다.",
                            "가입승인에 시간이 통상 3영업일 소요되며, 승인 완료되면 출금신청이 가능합니다."
                        ],
                        payoneer_checking: "Payoneer 계정 체크 중 ......"
                    },
                    props: {
                    },
                    api:{   
                    },
                    fail: {
                        fail: "출금신청 실패. 최소출금금액에 도달하지 못하여 출금을 신청할 수 없습니다.",
                        payoneer_fail: "Payoneer 접속 오류가 발생되었습니다.<br>잠시 후 다시 접속해 주십시오.",
                        payoneer_id: "ID가 발급되지 않았습니다.<br>관리자에게 문의하여 주십시오."
                    }
            },
            panel19: {
                title: "출금신청완료",
                    description: "출금신청완료 POPUP",
                    label: {
                        payment_method: {
                            kr_banktransfer: {
                                tax_bill: "[필수] 사업자 (세금)계산서 발행 안내",
                                tax_bill_more: "세금계산서 발행방법 보기",
                                business_registration: "사업자등록증 PDF",
                                duty_free : "(간이/면세사업자) 사업자 지출증빙 발급 방법",
                                
                            },
                            intl_banktransfer: {
                                tax_bill: "[필수] 인보이스 발행 안내",
                                business_registration: "사업자등록증 (English) PDF",
                            },
                            paypal: {
                                tax_bill: "[필수] 인보이스 발행 안내",
                                paypal_link: "PayPal 바로가기"
                            }
                        },
                        closeBtn: "닫기"
                    },
                    msg: {
                        completed: "출금신청이 접수되었습니다!",
                        guide1: "출금신청금액은 등록된 지급 정보로 신청일로부터 30일 이내 지급될 예정입니다.",
                        guide2: [
                            "출금완료여부는",
                            "정산/출금>출금내역 메뉴",
                            "에서 확인하실 수 있습니다."
                        ],
                        payment_method: {
                            kr_banktransfer: {
                                tax_bill: "사업자 (세금)계산서를 발행해주세요.<br/><br/>"+
                                "발행 이메일 주소 : tax_kr@soundrepublica.com<br/><br/>"+
                                "(세금)계산서 발행이 확인되지 않을 경우, 출금신청은 취소됩니다.<br/><br/>"
                            },
                            intl_banktransfer: {
                                tax_bill: "인보이스를 발행해주세요.<br/><br/>"+
                                "발행 이메일 주소 : report@soundrepublica.com<br/><br/>"+
                                "인보이스 발행이 확인되지 않을 경우, 출금신청은 취소됩니다.<br/><br/>"
                            },
                            paypal: {
                                tax_bill: "페이팔 계정에 로그인하여 아래 메일을 청구대상으로 인보이스를 발행해주세요.<br/>"+
                                "청구 대상 : yay@soundrepublica.com<br/><br/>"
                            }
                        }
                    },
                    props: {
                    },
                    api:{   
                    }
            },
            panel20: {
                title: "세금계산서 발행 방법",
                    description: "세금계산서 보기",
                    label: {
                        closeBtn: "닫기"
                    },
                    msg: {
                       
                    },
                    props: {
                    },
                    api:{   
                    }
            },
            panel21: {
                title: "",
                description: "고객서비스센터 상세 팝업",
                label: {
                    closeBtn: "닫기"
                },
                msg: {},
                props: {},
                api:{}
            }
        },
        imageUpload: {
            artist: {
                title: "아티스트 이미지",
                description: "아티스트 이미지",
                label: {
                },
                msg: {
                    uploadGuide: '마우스로 드래그하거나 클릭하여<br>추가해주세요.',
                    limitResolution1 : "이미지 규격을 준수해주세요. ",
                    limitResolution2 : "",
                    limitFormat: "jpg, png, jpeg 형식만 업로드 가능합니다."
                },
                props: {   
                },
                api:{   
                }
            } 
        },
        dragAndDropTable: {
            trackList: {
                title: "Disc",
                description: "트랙리스트",
                label: {
                    tableHeader: [
                        "No.",
                        "트랙명",
                        "아티스티명",
                        "파일유형",
                        "타이틀곡",
                        "곡길이",
                    ],
                    no: "No.",
                    trackName: "트랙명",
                    artistName: "아티스티명",
                    fileType: "파일유형",
                    titleSong: "타이틀곡",
                    songLength: "곡길이",
                    register: "등록",
                    title: "Title",
                    wav: "WAV",
                    mp3: "MP3",
                    FLC: "FLC",
                    addTrack: "트랙추가",
                    explicit: "EXPLICIT"
                },
                msg: {
                    title: "타이틀곳은 최대 2곡까지 설정하실 수 있습니다.",
                    listTest: [
                        "서툰 이별을 하려해 그래서 슬프고 아프다 이젠 잊자",
                        "이별을 또 하려해",
                        "이별공식 ::::",
                        "white!!!!!",
                        "봄날"
                    ],
                    none_track: "트랙 정보가 입력되지 않았습니다."
                },
                fail: {
                    data_copy: "복사할 데이터가 없습니다. 데이터를 입력하여 주십시오."
                },
                props: {

                },
                api: {

                }
            }
        },
        tracksUpload: {
            tracks: {
                title: "음원 파일 업로드",
                description: "음원 파일 업로드",
                label: {
                    fileName : "파일명",
                    bitrate: "비트레이트",
                    sampleRate : "샘플레이트",
                    format : "유형",
                    duration: "곡길이",
                    confirmBtn: "확인"
                },
                msg: {
                    uploadGuide: '마우스로 드래그하거나 클릭하여 추가해주세요.',
                    limitSize : "최대 파일 크기는 200 MB 이하입니다.",
                    limitFormat: "WAV, MP3, FLAC 파일만 업로드 가능합니다.",
                    formatDuplication: "이미 같은 포멧의 파일이 등록되있습니다.",
                    limitAmount1: "업로드 가능한 음원 파일은 최대",
                    limitAmount2: "개 입니다.",
                    limitDragCount: "파일은 한 개씩 업로드 가능합니다.",
                    guide: [
                        "필수 유형: FLAC 또는 WAV(44.1khz/16bit 이상)",
                        "선택 유형: MP3(320kbps 이상)",
                        "파일크기 : 파일당 200MB 이내",
                        // "High Resolution 발매를 위하여<br>24bit wav 업로드를 추천드립니다."
                        ]
                },
                props: {   
                },
                api:{
                }
            } 
        }
    }
}