<template>
    <!-- header -->
    <div class="header_wrap">
        <div class="hd_pixin_tb_w">
            <div class="hd_pixin_tb">
                <div class="hd_pixin_td hdp01">
                    <h1 class="toplogo">
                        <router-link to="/">
                            <a href="/" class="toplogo_link" @click="clear_active()">
                                <img :src="home.img" alt="SOUND REPUBLICA DISTRIBUTION">
                            </a>
                        </router-link>
                    </h1>
                </div>
                <div class="hd_pixin_td hdp02">
                    <ul class="gnb_list">
                        <!-- 앨범/아티스트관리 --> 
                        <li id="gnb_li01 album"  :class="{page_active: $route.path.indexOf('album') > -1 || $route.path.indexOf('distributionRenew') > -1}">
                            <router-link to="/album">
                                <a href="#" class="gnb_item">{{$t('menu.album')}}</a>
                            </router-link>
                        </li>
                        <!-- 아티스트 -->
                        <li id="gnb_li02 artist" :class="{page_active: $route.path.indexOf('artist') > -1}">
                            <router-link to="/artist">
                                <a href="#" class="gnb_item">{{$t('menu.artist')}}</a>
                            </router-link>
                        </li>
                        <!-- 정산/출금 -->
                        <!-- <li id="gnb_li02">
                            <router-link to="/accounting">
                                <a href="#" class="gnb_item">{{$t('components.navBar.label.royalties')}}</a>
                            </router-link>
                        </li> -->
                        <!-- 통계 -->
                        <li id="gnb_li03 statistics" :class="{page_active: $route.path.indexOf('statistics') > -1}">
                            <router-link to="/statistics">
                                <a href="#" class="gnb_item">{{$t('components.navBar.label.statistics')}}</a>
                            </router-link>
                            
                        </li>
                        <!-- 출금 -->
                        <li id="gnb_li04 withdraw" :class="{page_active: $route.path.indexOf('withdraw') > -1}">
                            <router-link to="/withdraw">
                                <a href="#" class="gnb_item">{{$t('menu.withdraw')}}</a>
                            </router-link>
                        </li>
                        <!-- 마이페이지 -->
                        <li id="gnb_li05 my" v-show="isAuthenticate" :class="{page_active: $route.path.indexOf('my') > -1}">
                            <router-link to="/my">
                                <a href="#" class="gnb_item">{{$t('components.navBar.label.my')}}</a>
                            </router-link>
                        </li>
                        <!-- 비용 -->
                        <!-- <li id="gnb_li05" v-show="!isAuthenticate">
                            <router-link to="/cost">
                                <a href="#" class="gnb_item" >{{$t('components.navBar.label.cost_mv')}}</a>
                            </router-link>
                        </li> -->
                        <!-- 고객센터 -->
                        <li id="gnb_li06 customerservice" :class="{page_active: $route.path.indexOf('customerservice') > -1}">
                            <router-link to="/customerservice">
                                <a href="#" class="gnb_item">{{$t('components.navBar.label.customerService_mv')}}</a>
                            </router-link>
                        </li>

                        <!-- 음악유통하기 -->
                        <!-- 보류 <li id="gnb_li07" class="type2">
                            <router-link to="/distribution">
                                <a href="#" class="gnb_item">{{$t('components.navBar.label.distribution')}}</a>
                            </router-link>
                        </li> --><!-- [191121] type2 추가 -->
                    </ul>
                    <!-- [191121] 추가 -->
                    <span class="gnb_bar"></span>
                    <!-- // [191121] 추가 -->
                </div>
                <div class="hd_pixin_td hdp03">
                    <ul class="util_list">
                        <!-- // 알림 기능 2차 개발시 진행 예정
                        <li>
                            <a href="#" class="util_topnotice">
                                <span class="hdtext">알림</span>
                                <span class="ut_tnlayer">99</span>
                            </a>
                        </li> -->
                        <li v-if="isAuthenticate"><a href="#" class="util_menu" @click="logout">{{$t('components.navBar.label.logout')}}</a></li>
                        <li v-else-if="!isAuthenticate">
                            <router-link to="/login">
                                <a href="#" class="util_logmen"><span class="util_logmen_in">{{$t('components.navBar.label.login')}}</span></a>
                            </router-link>
                        </li>
                        <!-- <li v-if="!isAuthenticate">
                            <router-link to="/signup">
                                <a href="#"><span class="util_logmen_in">{{$t('components.navBar.label.singup')}}</span></a>
                            </router-link>
                        </li> -->
                        <!-- <li><a href="https://www.soundrepublica.com/contact" target="_blank"><span class="util_logmen_in">{{$t('components.navBar.label.contact')}}</span></a></li> -->
                        <li>
                            <div class="util_leng_w">
                                <button type="button" class="util_leng">
                                    <span class="util_leng_in" v-if="$i18n.locale == 'ko'">
                                    한국어
                                    </span>
                                    <span class="util_leng_in" v-else>
                                    English
                                    </span>
                                </button>
                                <div class="util_leng_layer_list_w">
                                    <ul class="util_leng_layer_list">
                                        <li class="cursorStyle01" v-if="key == 'ko'" v-for="(item, key, index) in $t('components.navBar.props.language')" :key="key" ><span class="util_lengtail" :id="'langauge_'+key">{{item}}</span></li>
                                        <li class="cursorStyle01" v-if="key == 'en'" v-for="(item, key, index) in $t('components.navBar.props.language')" :key="key"><span class="util_lengtail" :id="'langauge_'+key">{{item}}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="m_menu_dimm" @click="clickMenu()"  :class="{ on : active == true }"></div>
                <div class="m_menu">
                    <a href="javascript:void(0)" class="btn_menu" @click="clickMenu()"  :class="{ on : active == true }"></a>
                    <div class="m_menuBox" :style="{backgroundColor: colorTest}">
                        <button class="mobileMenuClose" @click="handleResize"></button>
                        <div class="userBox">
                            <div v-if="isAuthenticate && user">
                                <strong class="userEmail">
                                    {{user.email.split('@')[0]}}
                                    <span style="font-size:13px;">@{{user.email.split('@')[1]}}</span>
                                </strong>
                                <button @click="logout">{{$t('components.navBar.label.logout')}}</button>
                            </div>
                            <div v-else>
                                <strong class="userEmail">
                                    <router-link to="/login" style="color:#fff">
                                        <span v-if="$i18n.locale == 'ko'">로그인을 해주세요.</span>
                                        <span v-if="$i18n.locale == 'en'">Please Login</span>
                                     </router-link>
                                </strong>
                               
                            </div>
                        </div>
                        
                        <ul>
                             <!-- 앨범/아티스트관리 --> 
                            <li id="gnb_li01">
                                <router-link to="/album">
                                    {{$t('menu.album')}}
                                </router-link>
                            </li>
                            <!-- 아티스트 -->
                            <li id="gnb_li02">
                                <router-link to="/artist">
                                    {{$t('menu.artist')}}
                                </router-link>
                            </li>
                            <!-- 정산/출금 -->
                            <!-- <li id="gnb_li02">
                                <router-link to="/accounting">
                                    <a href="#" class="gnb_item">{{$t('components.navBar.label.royalties')}}</a>
                                </router-link>
                            </li> -->
                            <!-- 통계 -->
                            <li id="gnb_li03">
                                <router-link to="/statistics">
                                    {{$t('components.navBar.label.statistics')}}
                                </router-link>
                                
                            </li>
                            <!-- 출금 -->
                            <li id="gnb_li02">
                                <router-link to="/withdraw">
                                    {{$t('menu.withdraw')}}
                                </router-link>
                            </li>
                            <!-- 마이페이지 -->
                            <li id="gnb_li04" v-show="isAuthenticate">
                                <router-link to="/my">
                                    {{$t('components.navBar.label.my')}}
                                </router-link>
                            </li>
                            <!-- 비용 -->
                            <!-- <li id="gnb_li05" v-show="!isAuthenticate">
                                <router-link to="/cost">
                                    <a href="#" class="gnb_item" >{{$t('components.navBar.label.cost_mv')}}</a>
                                </router-link>
                            </li> -->
                            <!-- 고객센터 -->
                            <li id="gnb_li06">
                                <router-link to="/customerService">
                                    {{$t('components.navBar.label.customerService_mv')}}
                                </router-link>
                            </li>
                            
                        </ul>
                        <div class="util_leng_w">
                            <div class="mobileLanguage">
                                <ul class="">
                                    <li class="mb20"><span class="util_lengtail">{{$t('language')}}</span></li>
                                    <li class="cursorStyle01"  :class="{on : $i18n.locale == 'ko'}" v-if="key == 'ko'" v-for="(item, key, index) in $t('components.navBar.props.language')" :key="key" ><span class="util_lengtail" :id="'langauge_'+key">{{item}}</span></li>
                                    <li class="cursorStyle01" :class="{on : $i18n.locale == 'en'}" v-if="key == 'en'" v-for="(item, key, index) in $t('components.navBar.props.language')" :key="key"><span class="util_lengtail" :id="'langauge_'+key">{{item}}</span></li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div>
                            <button @click="colorTest = null" style="color:#fff">reset</button>
                            <br>
                            <br>
                            <p>
                                <button class="mr10 ml10" @click="colorTest ='#000'" style="color:#fff">dark</button>
                                <button class="mr10" @click="colorTest ='#0d257f'" style="color:#fff">blue</button>
                            </p>
                        </div>
                         -->
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- // header -->
</template>

<script>
// JQuery 사용
import JQuery from 'jquery'
const $ = JQuery

export default {
    name: 'navigationBar',
    data () {
        return {
            user : null,
            isAuthenticate: localStorage.isAuthenticate, // 로그인 여부
            home: {
                title: 'Home',
                img: require('@/assets/images/toplogo.png'),
                description: 'Sound Republica Logo',
                locale: this.$store.state.languageCode
            },
            lang: null,
            menu_count: 6, // 상단 메뉴 갯수: 0 부터
            active: false,
            colorTest: null,
        }
    },
    mounted(){
        window.addEventListener('resize', this.handleResize);
        this.layoutFunc()
        this.$nextTick(function(){
            // 모든 화면이 렌더링된 후 실행합니다.

            // [S] 로그인 여부 체크
            let SR_Token = localStorage.SR_Token
            if(!SR_Token){
                this.isAuthenticate = false
            }else{
                this.isAuthenticate = true
                this.user = JSON.parse(localStorage.SR_UserInfo)
            }
            // [E] 로그인 여부 체크
            
            // navi animation
           
            
            // current locale
            this.current_locale()

            this.$EventBus.$on("navi", (eventName, params) =>{
                switch(eventName){
                    case 'login_access':
                        this.isAuthenticate = params  // 로그인 성공 EventBus 발생
                        this.locale = this.$store.state.languageCode
                        this.language_selected()
                        break;
                    case 'page_position':
                        
                        let position = this.page_position(params.toLowerCase())
                        //console.log("page_position ::", position)
                      //  this.page_active_type2(position)
                        break;
                    case "page_clear":
                        //this.clear_active()
                        break;
                }
            })


            this.navi_mouseover()
        })
    },
    computed: {
        languageCode(){
            return this.$store.getters.languageCode
        },
        sr_page_position(){
            return localStorage.getItem("SR_page_position")
        }
    },
    beforeDestroy() {
        // console.log("beforeDestroy...");
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        isAuthenticate(){
            return this.isAuthenticate
        },
        '$route'(to,from){
           
            this.active = false
            document.body.style.overflow = "";
            let SR_Token = localStorage.SR_Token
            if(!SR_Token){
                this.isAuthenticate = false
            }else{
                this.isAuthenticate = true
                this.user = JSON.parse(localStorage.SR_UserInfo)
            }

            
        }
    },
    updated() {
        this.navi_mouseover()
    },
    methods: {
        // 로그아웃
        logout() {
          
            this.$store.dispatch('LOGOUT')
            .then(()=> {
                this.isAuthenticate = false
                this.$EventBus.$emit("navi", "page_clear",'/')
                this.$router.push('/login')
            })
            .catch(error => {
                console.log("LOGOUT Fail :::")
            })
        },
        /* 레이아웃 공통호출 */
        layoutFunc(){
            const vm = this
            const $util_leng = $(".util_leng"), $util_lengtail = $(".util_lengtail");

            // 접속 국가 코드 확인 후 언어선택 text 처리
            let languageCode = this.$store.state.languageCode
            let locale = this.$i18n.locale  // 언어코드

           

            // 접속 국가 코드 확인 후 언어선택 text 처리
            if($util_leng.length){
                $util_leng.on("click",function(){
                    const $this = $(this), $t_list = $this.next(".util_leng_layer_list_w")
                     $this.toggleClass("active")
                     if($t_list.length){
                        $t_list.slideToggle()
                    }
                });
            }
            
            if($util_lengtail.length){
                let $store = this.$store   // Store 
                $util_lengtail.on("click",function(e){
                    e.stopPropagation
                    const $this = $(this);
                    $(".util_leng_layer_list > li").removeClass("active");
                    $this.parents("li").addClass("active")
                    $(".util_leng_layer_list_w").slideUp()
                    $util_leng.find(".util_leng_in").text($this.text());
                    $util_leng.removeClass("active");

                    //language Code Change
                    const select_languageCode = $this.attr('id').split('_')[1]
                    vm.localeChange(select_languageCode)
                });
            }
            $(document).on("click",function(e){
                if($(e.target).parents(".util_leng_w").length) return
                $(".util_leng_layer_list_w").slideUp();
                $util_leng.removeClass("active");
            });
            
            const $btntopgo = $(".btntopgo")
            if($btntopgo.length){
                $btntopgo.on("click",function(e){
                    e.preventDefault();
                    $("html,body").stop().animate({"scrollTop":0})
                });
            }
        },
        localeChange(code){
            // 언어 코드 변경
            this.$i18n.locale = code.toLowerCase()
            this.locale = code
            this.$store.commit('LANGUAGECODE', code)
            if(this.isAuthenticate){
                this.languageChange(code)
            }
        },
        navi_mouseover(event) {
            let target = document.getElementsByClassName('page_active')[0]
          
            
            if(target){
                document.getElementsByClassName('gnb_bar')[0].classList.remove('out')
                document.getElementsByClassName('gnb_bar')[0].classList.add('load')
                document.getElementsByClassName('gnb_bar')[0].style.width = target.offsetWidth +'px'
                document.getElementsByClassName('gnb_bar')[0].style.left = (target.offsetLeft)+"px"
                
            }else if( target == undefined){
                
                document.getElementsByClassName('gnb_bar')[0].classList.add('out')
            } 
            //if(targetId =="gnb_li07") document.getElementsByClassName('gnb_bar')[0].classList.add('out')
            
        },
        navi_mouseout(event) {
            let targetId = event.target.parentNode.parentNode.id
            //document.getElementsByClassName('gnb_bar')[0].classList.add('out')
            document.getElementsByClassName('gnb_bar')[0].classList.add('load')
        },
        current_locale(){
            // [S] 접속된 국가 코드 확인
            new Promise(resolve =>{
                let countryCode = this.$store.state.countryCode           // Country Code
                let languageCode = this.$store.state.languageCode         // Store Language Code
                let local_languageCode = localStorage.SR_languageCode   // local language Code
               
                if(!languageCode){
                    if(!local_languageCode){
                        if(!countryCode) {
                            this.$store.dispatch("COUNTRYCODE")
                            .then(result =>{
                                let code = result.toLowerCase()
                                switch(code) {
                                    case 'kr':
                                        this.localeChange("ko")
                                        break;
                                    default :
                                        this.localeChange("en")
                                        break;
                                }
                                resolve('countryCode complete')
                            })
                        }
                    }else{
                        this.localeChange(local_languageCode)
                        resolve('languageCode complete')
                    }
                }else{
                    this.localeChange(languageCode)
                    resolve('store languageCode complete')
                }
            }).then(result =>{
                this.language_selected()
            })
        },
        language_selected(){
            // Show selected language
            const $util_leng = $(".util_leng")
            let locale = this.locale  // 언어코드
            this.$store.state.language.map(lang =>{
                // if(lang.code == locale) {
                //     $util_leng.find(".util_leng_in").text(lang.o_name)
                // }
                
                let parentNode = document.getElementsByClassName('util_leng_layer_list')[0]
                Array.from(parentNode.getElementsByTagName("li")).map(node =>{
                    if(node.classList.contains("active")) node.classList.remove('active')
                    let key = node.childNodes[0].id.split('_')[1]
                    if(key == locale) node.classList.add('active')
                })
            })
        },
        page_active(event){
            const target= event.target
                for(let i=0; i < this.menu_count; i++) {
                    let classCheck = document.getElementById('gnb_li0'+ (i+1))
                     if(classCheck){
                        if(classCheck.classList == 'page_active') classCheck.classList.remove('page_active')
                    }
                }
            // if(target.closest('li').id != 'gnb_li07'){
            //     target.closest('li').classList.add('page_active')
            // }
        },
        page_active_type2(id){
            // let target= document.getElementById(id)
            // if(target.closest('li').id != 'gnb_li07'){
            //     for(let i=0; i < this.menu_count; i++) {
            //         let classCheck = document.getElementById('gnb_li0'+ (i+1))
            //     }
            //     target.closest('li').classList.add('page_active')
            // }else{
            //     this.clear_active()
            // }
        },
        clear_active(){
            for(let i=0; i < this.menu_count; i++) {
                let classCheck = document.getElementById('gnb_li0'+ (i+1))
                if(classCheck){
                    if(classCheck.classList == 'page_active') classCheck.classList.remove('page_active')
                }
            }
        },
        page_position(value){
            
            switch(value){
                
                case 'album':
                    return 'gnb_li01'
                    break;
                case 'artist':
                    return 'gnb_li02'
                    break;
                case 'statistics':
                    return 'gnb_li03'
                    break;
                case 'withdraw':
                    return 'gnb_li04'
                    break;
                case 'my':
                    return 'gnb_li05'
                    break;
                
                case 'customerService':
                    return 'gnb_li06'
                    break;
                case 'distribution':
                    return 'gnb_li07'
                    break;
            }
        },
        handleResize(event) {
           this.active = false
           document.body.style.overflow = "";
        },

        clickMenu(){
            this.active = !this.active
            if(this.active){
                document.body.style.overflow = "hidden";
            }else{
                document.body.style.overflow = "";
            }
            
            
        },
        languageChange(lang){
          this.$store.dispatch('MYUPDATA', {language: lang })
          .then((result)=> {
            this.setData(result)
          }).catch(error => {
              this.loadingHide()
              console.log("My Page Save Error ::: :::")
          })
        },
    }
}

</script>

<style scoped>
.page_active{
    color: #799aff;
}

.page_active a{
    
}
</style>