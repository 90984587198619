
<template>
    <div>
        <ul class="fdin_list ">
            <li class="">
                <v-select v-if="$i18n.locale == 'ko'" style="min-width:140px"
                label='name'
                placeholder="언어" 
                v-model="lang"
                :options="languageOption"
                :reduce="languageOption => languageOption.code" 
                :clearable="false" 
                >
                </v-select>
                <v-select v-if="$i18n.locale == 'en'" style="width:180px"
                label='en_name'
                placeholder="Language" 
                v-model="lang"
                :options="languageOption"
                :reduce="languageOption => languageOption.code" 
                :clearable="false" 
                >
                </v-select>
                 <!-- <select  v-model="lang">
                    <option :value="null">{{$t('language')}}</option>
                    <option v-for="(item, index) in languageOption" :key="item.code" :value="item.code">{{$i18n.locale == 'ko'? item.name : item.en_name}}</option>
                </select> -->
            </li>
            <li style="width:60%; max-width: 250px;">
              <div class="input_fitem_w"><input class="input_fitem" type="text"  v-model="name" :placeholder="title"/></div>
            </li>
            <li> 
                
                <button type="button" class="btn_bsm sztype2 type3 small" style="vertical-align:top;" color="danger" @click="add" ><span class="btn_bsm_in">{{$t('add')}}</span></button>
            </li>
        </ul>
      
        <!-- <ul >
            <li class="vx-row items-center mb-2" v-for="(item, key) in langList">
                <v-select class="mr-2" style="width:130px"
                    name='name'
                    label="name"
                    placeholder="앨범명(번역)" 
                    v-model="key"
                    :options="languageOption"
                    :reduce="languageOption => languageOption.code" 
                    :clearable="false" 
                    disabled/>
                <input @input="update(key, $event)" :value="langList[key]" class="" placeholder="앨범명(번역)"/>
                <button class="ml-3 small" style="vertical-align:top;" color="danger" @click="del" type="gradient">삭제</button>
            </li>
        </ul> -->
        <table  class="trackTable" noDataText="">
            <tr slot="thead">
                <th>{{title}}</th>
                <th style="width:60px"></th>
            </tr>
            <tbody>
                <tr v-for="(item, key) in langList">
                
                    <td >
                        <!-- {{ data[indextr].name }} -->
                       <div class="flex" style=" align-items: center;flex-wrap: wrap;">
                            <v-select class="" v-if="$i18n.locale == 'ko'" style="width:15%; min-width:100px;    height:30px;"
                            name='name'
                            label="name"
                            v-model="key"
                            :options="languageOption"
                            :reduce="languageOption => languageOption.code" 
                            :clearable="false"
                            disabled 
                            >
                            </v-select>
                            <v-select class="" v-else style="width:15%; min-width:100px; height:30px;"
                            name='name'
                            label="en_name"
                            v-model="key"
                            :options="languageOption"
                            :reduce="languageOption => languageOption.code" 
                            :clearable="false"
                            disabled 
                            >
                            </v-select>
                            <div class="input_fitem_w" style=" min-width:200px;  max-width: 600px;width: 80%;"><input type="text" class="input_fitem"  @change="update(key, $event)" :value="langList[key]"/></div>
                       </div>
                       
                    </td>
                    <td >
                        <button type="button" class="btnCircle delete" style="vertical-align:top;" color="danger" @click="del(key)" ></button>
                    </td>
                </tr>
            </tbody>
        </table>  
    </div>
</template>

<script>
import {language} from '../store/data'
export default{
  name: 'multi-lang',
  props: ['langList','title'],
  data () {
      return{
          name : null,
          lang : null,
          languageOption : language,
      }
  },
  watch: {
    'langList'(){
        
    }
  },
  mounted () {
      //console.log('languageOption',language)
  },
  methods:{
      update(name,val){
          console.log(name,val.target.value)
        this.langList[name] = val.target.value
        this.$forceUpdate()
        this.$emit('changeUpdate', this.langList)
      },
      add(){
        
        if(this.lang && this.name){
            if(!this.langList[this.lang] ){
                this.langList[this.lang] = this.name
                this.lang = null    
                this.name = null
                 this.$forceUpdate()
                this.$emit('changeUpdate', this.langList)
            }else{
                //this.noti('이미 등록된 언어 입니다.')    
                this.alert({msg : this.$i18n.t('error.language')})
            }
        }else{
            //this.noti('언어와 이름을 입력하세요.')
            this.alert({msg : this.$i18n.t('enter.languagename')})
        } 
       
      },
      del(data){
        delete this.langList[data]
        this.$forceUpdate()
        this.$emit('changeUpdate', this.langList)
      }

  }
}
</script>