<template src="./template.html"></template>

<script>
export default {
    data(){
        return {
            success: true,
            login: "Login", // 로그인 페이지 이름
            resetPassword: "resetPassword"
        }
    }
}
</script>