<template src="./template.html"></template>

<script>
export default {
    name: "forgetPassword",
    data(){
        return {
            email: null,
            message: [
            ],
            success: false,
            validEmail: false,
            error: []
        }
    },
    computed: {
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const sendEmail = this.$i18n.t("forgetPassword.msg.sendEmail")
                const messageCollection = { 
                    sendEmail: sendEmail
                    }
                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        }
    },
    methods: {
        onSubmit(){
            let email = this.email
            let validEmail = this.validEmail
    
            if(validEmail){
                this.$store.dispatch('RESETPASSWORDEMAIL', email)
                    .then(() => {
                        this.success = true
                    })
                    .catch((errorMessage) =>{
                        // 가입되지 않는 이메일 메세지 노출할것
                        const error = this.error.indexOf('not_joined')
                        if(error === -1){
                            this.error.push('not_joined')   /// 이메일 형식 에러
                        }
                    })
            }
        },
        popupOpen(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            this.guideMessage = title
    
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 300)
        },
        verifyEmail(){
            // 이메일 형식 체크
            let email = this.email
            this.$store.dispatch('VALIDEMAIL', email)
                .then(result =>{
                    if(result) {
                        this.validEmail = true
                        this.errorMessage('email')
                    }else {
                        this.validEmail =false
                        const error = this.error.indexOf('email')
                        if(error === -1){
                            this.error.push('email')   /// 이메일 형식 에러
                        }
                    }
                 })
        },
        errorMessage(id){
            // Error Message Hide
            const error = this.error.indexOf(id)
            if(error > -1){
                this.error.splice(error, 1);
            }
        }
    }
}
</script>