<template src="./template.html"></template>

<script>
import i18n from '../../library/i18n'
import decode from 'jwt-decode';
// JQuery 사용
// import JQuery from 'jquery'
// const $ = JQuery

export default {
    name: "My",
    data() {  
        return {
            role :  null,
            licensor : null,
            bankaccount : {},
            licensorId : null,
            // 이메일 아이디
            email_id: null,
            //마케팅 동의
            marketing: false,
            //사이트 언어
            language: 'ko',                      // ko, en
            // 국적 코드
            nationality: null,
            // 국적 정보(이름)
            nationalityInfo: null,
            // mims
            mims: null,
            // 개인/사업자 구분
            division: null,
            //주민등록번호 타입
            entity_identification_type: null,
            //면세사업자
            entity_tax_exemption : false,
            // 이름
            family_name: null,
            given_name: null,
            // 회사명
            companyName: null,
            // 전화번호
            phone: null,
            countryPhone: null,
            // 정산화폐
            currency: null,
            // 지급방법
            payment: null,
            goPayoneer: "Payoneer",
            goPaypal: "Paypal",
            paypal_email: null,     // Paypal Email
            // 거주지 국가 코드
            // country: null,
            countryCode: null,
            // 거주지 국가 정보
            countryInfo: null,
            //주민등록번호
            idCard: null,
            // birthday
            birthday: null,
            // address
            taxAddress1: null,
            taxAddress2: null,
            taxDistrict: null,
            taxCity: null,
            taxPostalCode: null,

            // 예금주
            accountHolder: null,
            // 계좌번호
            accountNumber: null,
            // bankCode
            bankCode: null,
            //countryCode
            countryCode: null, 
            //Bank CountryCode 은행이 소속된 국가코드
            bankCountryCode: null,
            //Bank CountryCode 은행이 소속된 국가이름
            bankCountryInfo: null,
            // bank and branch name
            bankAndBranchName: null,
            // branch Address
            paymentAddress1: null,
            paymentAddress2: null,
            paymentDistrict: null,
            paymentCity: null,
            paymentPostalCode: null,
            bank: null,
            bankName: null,
            // 유통정보 입력 여부
            distributionView: false,
            // 세금 및 지급 정보 입력 여부
            accounting: false,
            isLicensorInfo: false                // licensor 정보 존재 여부 ( false: 데이터 없음, true: 데이터 있음 )
        }  
    },
    beforeRouteLeave (to, from, next) {
        this.$EventBus.$emit("navi", "page_clear",'/')
        next()
    },
    created() {
        //Get User Info
        
       
    },

    mounted() {
      // this.$EventBus.$emit('openPanel',{
      //     component: "panel-11",
      //     props: {
            
      //     }
      // },()=>{
          
      // });
       

      this.$nextTick(function () {
        // 모든 화면이 렌더링된 후 실행합니다.
        this.myData()
        this.$EventBus.$emit("navi", "page_position",'my')

        // title animation
        setTimeout(function(){
          document.getElementById('my').classList.add('action')
        }, 100)

        this.$EventBus.$on('MY', (name, params) => {
          switch(name) {
            case 'distributionView':
              this.licensorId = params.id
              this.nationality = params.nationality           // 국적 코드
              this.nationalityInfo = params.nationalityInfo   // 국적 정보

              if(params.hasOwnProperty("mims")) this.mims = params.mims
              this.division = params.division
              if(this.division == "individual")  // 개인
                this.entity_identification_type = "kr_resident"
              else if(this.division == "corporation")  //사업자
                this.entity_identification_type = "kr_business"
              else
                this.entity_identification_type = "foreign_tax"
              
              this.family_name = params.family_name
              this.given_name = params.given_name

              if(params.hasOwnProperty("companyName")) this.companyName = params.companyName

              this.phone = params.phone
              this.countryPhone = params.countryPhone
              this.currency = params.currency
              this.payment = params.payment
              if(params.paypal_email) this.paypal_email = params.paypal_email
              this.countryCode = params.countryCode      // 거주지 국가 코드
              this.countryInfo = params.countryInfo      // 거주지 국가 정보
              this.distributionView = true
              // 세금 및 지급 정보 등록버튼 활성화
              // let el = document.getElementById("paymentRegisterBtn")
              // el.classList.remove('opacity03')
              // el.removeAttribute("disabled")
              break;
            case 'accounting':
              const session_userInfo = JSON.parse(localStorage.SR_UserInfo)
              if(session_userInfo.licensor){
                this.licensor = session_userInfo.licensor;
                this.licensorId = session_userInfo.licensor.id
                this.$store.dispatch('ISOBJECTEMPTY', session_userInfo.licensor)
                .then(result => {
                  if(!result) {
                    this.distributionView = true
                    //this.accounting = true
                    this.isLicensorInfo = true
                    const licensor = session_userInfo.licensor
                    if(licensor.bankaccount.account_number != '' && licensor.bankaccount.account_number != null || licensor.entity_identification != null ){
                      this.accounting = true  
                    }
                    this.dataTemplate(licensor)
                  }else {
                    this.distributionView = false
                    this.accounting = false
                  }
                })
              }
              // 세금정보
              this.idCard = params.idCard
              this.dateTimeCrop(params.birthday).then(result =>{
                if(result) {
                  this.birthday = result
                }
              })

              this.taxAddress1 = params.taxAddress1
              this.taxAddress2 = params.taxAddress2
              this.taxDistrict = params.taxDistrict
              this.taxCity = params.taxCity
              this.taxPostalCode = params.taxPostalCode
              
              // 지급정보
              if(this.countryCode == "KR") {
                this.accountHolder = params.accountHolder
                this.accountNumber = params.accountNumber
                this.bankCode = params.bank.code
                this.bankName = params.bank.name
                this.$store.state.bank.map(value =>{
                  if(value.code == this.bankCode){
                    this.bank = value.name
                  }
                })
              }
           
              this.accounting = true
              break;
          }
        })
      })
    },
    computed: {
        params() {
          let params = {
            // 기본정보
              id : this.licensorId,
              language: this.language,
              marketing: this.marketing,
            // 유통정보
              nationality: this.nationality,
              division: this.division,  // 개인/사업자여부
              given_name: this.given_name,
              family_name: this.family_name,
              phone: this.phone,
              countryPhone: this.countryPhone,
              currency: this.currency,
              payment: this.payment,
              countryCode: this.countryCode,
              entity_identification_type: this.entity_identification_type,
              // 세금정보
              idCard: this.idCard,
              birthday: this.birthday,
              taxAddress1: this.taxAddress1,
              taxAddress2: this.taxAddress2,
              taxDistrict: this.taxDistrict,
              taxCity: this.taxCity,
              taxPostalCode: this.taxPostalCode,
          }

          // 추가 유통 정보
          if (this.nationality != '' || this.nationality.toLowerCase() == 'kr') {
            if (this.mims) Object.assign(params, { mims: this.mims })
          }

          if(this.division == 'corporation')
            Object.assign(params, { companyName: this.companyName})

          if(this.payment == 'paypal') {  // paypal
            Object.assign(params, {paypal_email: this.paypal_email})
          }

          if(this.countryCode != ''|| this.countryCode.toLowerCase() == 'kr') {
            const obj = {
              // 지급정보
              accountHolder: this.accountHolder,
              accountNumber: this.accountNumber,
              bank: this.bank,
              bankCode: this.bankCode
            }
            Object.assign(params, obj)
          }else{

          }

          return params
        },
        divisionShow () {
          // 개인/ 사업자 구분
          const obj = this. $i18n.t('distribution.label.divisionSelect')
          const keys = Object.keys(obj)
          for(let i=0; i < keys.length; i++) {
            if(keys[i] == this.division) {
              return obj[keys[i]]
            }
          }
        },
        currencyShow() {
          // 정산 화폐 Show
          const obj = this. $i18n.t('distribution.label.currency.money')
          const keys = Object.keys(obj)
          for(let i=0; i < keys.length; i++) {
            if(keys[i] == this.currency) {
              return obj[keys[i]]
            }
          }
        },
        paymentShow(){
          // 지급 방법
          const obj = this. $i18n.t('distribution.label.payment.means')
          const keys = Object.keys(obj)
          for(let i=0; i < keys.length; i++) {
            if(keys[i] == this.payment) {
              return obj[keys[i]]
            }
          }
        }
    },
    methods: {
        setData(result){
          this.role  = result.role
          this.localeChange(result.language)
          this.loadingHide();
          const store_userInfo = this.$store.state.SR_UserInfo
          const session_userInfo = result
        
          if(!store_userInfo){
            if(session_userInfo) {
              this.email_id = session_userInfo.email         // 이메일 ID
              this.marketing = session_userInfo.marketing    // 마케팅 활용 동의 여부
              this.language = session_userInfo.language ? session_userInfo.language.toLowerCase() :session_userInfo.language    // 사이트 언어 저장
            }
          }else {
            this.email_id = store_userInfo.email
            this.marketing = store_userInfo.marketing
            this.language = store_userInfo.language.toLowerCase()
          }
          if(session_userInfo.hasOwnProperty('licensor')) {
            
            if(session_userInfo.licensor){
              this.licensor = session_userInfo.licensor;
              this.licensorId = session_userInfo.licensor.id
              this.$store.dispatch('ISOBJECTEMPTY', session_userInfo.licensor)
              .then(result => {
                if(!result) {
                  this.distributionView = true
                  //this.accounting = true
                  // let el = document.getElementById("paymentRegisterBtn")
                  // el.classList.remove('opacity03')
                  // el.removeAttribute("disabled")

                  this.isLicensorInfo = true
                  const licensor = session_userInfo.licensor
                  if(licensor.bankaccount.account_number != '' && licensor.bankaccount.account_number != null || licensor.entity_identification != null ){
                    this.accounting = true  
                  }
                  this.dataTemplate(licensor)
                }else {
                  this.distributionView = false
                  this.accounting = false
                }
              })
            }
          } else {
            this.distributionView = false
            this.accounting = false
          }
          this.$forceUpdate()
        },
        myData(){
          let ValidKey = localStorage.hasOwnProperty('SR_Token')
          if (ValidKey) {
              ValidKey = decode(localStorage.SR_Token)
          }else{
            return false
          }
          
          this.loadingShow()
          this.$store.dispatch('MYDATA' ,ValidKey.id )
          .then((result)=> {
            console.log('result',result)
            localStorage.setItem("SR_UserInfo", JSON.stringify(result))
            this.setData(result)
          }).catch(error => {
              console.log("My Page Save Error ::: :::")
          })
        },
        languageChange(lang){
          this.$store.dispatch('MYUPDATA', {language: lang })
          .then((result)=> {
            console.log()
            localStorage.setItem("SR_UserInfo", JSON.stringify(result))
            this.setData(result)
          }).catch(error => {
              this.loadingHide()
              console.log("My Page Save Error ::: :::")
          })
        },
        marketingSave(){
          this.$store.dispatch('MYUPDATA', {marketing: !this.marketing })
          .then((result)=> {
              this.setData(result)
              this.alert({msg:this.iText('marketing.msg.change')})  
          }).catch(error => {
              this.loadingHide()
              console.log("My Page Save Error ::: :::")
          })
          
        },
        onSubmit() {
          // MY Save
          let params = this.params
          this. loadingShow()
          this.$store.dispatch('MYSAVE', params)
          .then((result)=> {
              
              this.myData()
              this.localeChange(params.language)  // 사이트 언어변경
              
              this.alert({msg:this.iText('성공적으로 저장되었습니다','common.popup.message.save')})
          }).catch(error => {
              this.hodeLoading()
              console.log("My Page Save Error ::: :::")
          })

        },
        onUpdate() {
        // MY User Info Update
          let params = this.params
          this. loadingShow()
          this.$store.dispatch('MYUPDATA', params)
          .then(()=> {
            this.localeChange(params.language)  // 사이트 언어변경
            this.myData();
            this.alert({msg:this.iText('성공적으로 저장되었습니다','common.popup.message.save')})
          }).catch(error => {
              console.log("My Page Save Error ::: :::")
          })
        },
        showPanel1(){
            // const panel = this.$showPanel({
            //     component: "panel-1",
            //     width:"740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-1",
                props: {
                  width:'560px'
                }
            },()=>{
                
            });
        },
        showPanel10(){
            // const panel = this.$showPanel({
            //     component: "panel-10",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {}
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-10",
                props: {
                  params : this.params
                }
            },()=>{
                
            });
        },
        showPanel11(){
            // const panel = this.$showPanel({
            //     component: "panel-11",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {
            //       entity_identification_type: this.entity_identification_type,
            //       countryCode: this.countryCode
            //     }
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-11",
                props: { 
                  params : this.params,
                  id : this.licensorId,
                  entity_identification_type: this.division,
                  countryCode: this.countryCode
                }
            },()=>{
                
            });
        },
        dateFormat(value){
          // 날짜 포멧
          return value? value.split("T")[0] : value
        },
        dataTemplate(data){
          // 유통정보
          this.nationality = data.nationality
          // 국가 정보
          this.$store.state.country.map(country =>{
            if(country.code.toLowerCase() == data.nationality.toLowerCase()){
              this.nationalityInfo = country
            }
          })

          if(data.nationality || data.nationality.toLowerCase() == 'kr') this.mims = data.mims_account
          this.division = data.entity
          if(data.nationality && data.nationality.toLowerCase() == 'kr'){
            this.family_name = data.family_name
            this.given_name = data.given_name
          }else{
            this.family_name = data.given_name
            this.given_name = data.family_name
          }
          

          if(data.entity || data.entity.toLowerCase() == 'corporation') this.companyName = data.company
          
          this.countryPhone = data.phone_countrycode
          this.phone = data.phone
          this.currency = data.currency
          if(data.bankaccount) {
            this.payment = data.bankaccount.payment_method
            if(data.bankaccount.payment_method == 'paypal') this.paypal_email = data.bankaccount.paypal_email
          }

          if(data.hasOwnProperty('entity_address')) this.countryCode = data.entity_address.country
            // 거주지 국가 정보
            this.$store.state.country.map(country =>{
              
              if( country.code.toLowerCase() == this.countryCode.toLowerCase()){
                this.countryInfo = country
              }
            })
          //세금정보
          this.entity_identification_type = data.entity_identification_type   // 납세자 번호 타입
          this.idCard = data.entity_identification
          this.birthday = this.dateFormat(data.entity_date)

            // 주소
            if(data.hasOwnProperty('entity_address')) {
              this.taxAddress1 = data.entity_address.address_line
              this.taxAddress2 = data.entity_address.address_line2
              if(data.entity_address ||data.entity_address.country.toLowerCase() != 'kr'){
                if(data.entity_address.hasOwnProperty('district')) this.taxDistrict = data.entity_address.district
                if(data.entity_address.hasOwnProperty('city')) this.taxCity = data.entity_address.city
              }
    
              this.taxPostalCode = data.entity_address.postal_code
            }

          //지급정보
          if( data.hasOwnProperty('bankaccount')) {
            this.bank = data.bankaccount.bank_name == null ? '' : data.bankaccount.bank_code;
            this.bankCode = data.bankaccount.bank_code == null ? '' :data.bankaccount.bank_code ;
            this.accountNumber = data.bankaccount.account_number
            this.accountHolder = data.bankaccount.name  // 예금주
            this.bankaccount = data.bankaccount
          }

         /*  20200313 보류
          this.bankAndBranchName = data.bankaccount.bank_branch
          this.bankCountryCode = data.bankaccount.address.country */
            // 은행 국가 정보
            this.$store.state.country.map(country =>{
              if(country.code.toLowerCase() == this.bankCountryCode){
                this.bankCountryInfo = country
              }
            })

          /* 20200313 보류
          this.paymentAddress1 = data.bankaccount.address.address_line
          this.paymentAddress2 = data.bankaccount.address.address_line2
          if(data.bankaccount.address.hasOwnProperty('district')) this.paymentDistrict = data.bankaccount.address.district
          if(data.bankaccount.address.hasOwnProperty('city')) this.paymentCity = data.bankaccount.address.city
          this.paymentPostalCode = data.bankaccount.address.postal_code */
        },
        localeChange(code){
            // 언어 코드 변경
            this.$i18n.locale = code
            const isAuthenticate = localStorage.isAuthenticate
            // this.$EventBus.$emit("login_access", isAuthenticate)
            this.$store.commit('LANGUAGECODE', code)
            this.$EventBus.$emit("navi", 'login_access', isAuthenticate,)
        },
        popupOpen(id){
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            setTimeout(function(){
              popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupClose(event){
          // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
              popup.classList.remove('type2')
          }, 300)
        },
        dateTimeCrop(param){
            return this.$store.dispatch('DATETIMECROP', param)
                .then((result) => {
                    return result
                })               
        }
    }
}
</script>

<style scoped>
table {
}

#my {
margin-top: 90px;
}
/* [S] Title Animation */
.sv_z .bg{
  background-image:url('../../assets/images/sv_mypage.png');
  background-repeat:no-repeat;
  background-position:50% 0;
  transform: scale(1.6);
  background-size:cover;
  transition:1.2s;
  display:block;
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
}

.sv_z .sv_copy{
  transition-delay: .1s; 
  transition-duration: .8s;
  transform: translateX(-80px);
  opacity:0;
  filter:alpha(opacity=100);
}

.sv_z .sv_copy2{
  transition-delay: .7s;
  transition-duration: .8s;
  transform: translateX(-100px);
  opacity:0;
  filter:alpha(opacity=100);
}

.action .sv_z .bg{transform: scale(1) !important;}
.action .sv_z .sv_copy{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
.action .sv_z .sv_copy2{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
/* [E] Title Animation */

.opacity03{
  opacity: 0.3;
}
</style>
