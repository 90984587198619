<template src="./template.html"></template>

<script>
import i18n from "../../library/i18n"    // 다국어지원
import {en, ko} from "vuejs-datepicker/dist/locale"
import moment from 'moment'
import axios from 'axios'
export default {
    name: 'accounting',
    data(){
        return {
            scrollTop:window.top.scrollY,
            scrollOff: false,
            fullLoading:false,
            loading:true,
            salesLoading : {
                1:false,
                2:false,
                3:false
            },
            paymentLoading : {
                1:false,
                2:false,
                3:false
            },
            description: '정산/출금 페이지 입니다.',
            viewChange: "sales",                                            // View 변환 값: sales, payment
            musicStoreList: null,                                           // 뮤직 스토어 리스트
            saleline_form:{
                date_type: "settlement_month",                              // 정산월:settle, 서비스월:service
                start_month:new Date(moment().add(0, 'M').startOf('month')), 
                end_month: new Date(moment().endOf('month')),
                musicstore: '',
                country: '',
                service_type: 'all',
                search_type: 'artist',
                keyword: '',
                monthRange: 1,
                page: 0,
                limit: 30,
            },
            startDate: {
                to: null,
                from: new Date(moment().endOf('month'))
            },
            endDate: {
                to: new Date(moment().add(-5, 'M').startOf('month')),
                from: null
            },
            paging:{
                first: 0,
                prev: 0,
                pages:[0],
                next: 0, 
                last:0
            },
            paymentPaging:{
                first: 0,
                prev: 0,
                pages:[0],
                next: 0, 
                last:0
            },
            en: en,
            ko: ko,
            currency: 'krw',
            salesline_list: {
                total: 0,
                filtered: 0,
                items:[]
            },
            salesline_sum: {
                total:{
                    st:{ hitcount:0, user_amount:0, },
                    dl:{ hitcount:0, user_amount:0 },
                    other:{ hitcount:0, user_amount:0 },
                    user: 0
                },
                monthly:[]
            },
            chart: {
                bar : {
                    data : {},
                    option : { 
                        responsive: true, maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                stacked: true,
                                
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        },
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                boxWidth: 80,
                                fontColor: 'black'
                            }
                        }
                    }
                },
                pie: {
                    data: {},
                    options: {
                        legend: {
                            display: true,
                            position: "top",
                            labels: {
                                boxWidth: 30,
                                fontColor: 'black',
                                padding: 10
                            }
                        }
                    }
                }
            },
            transaction:{
                filter: 'all',
                page: 0,
                limit: 30,
                sortorder: 'desc',
                result: {
                    total: 0,
                    filtered: 0,
                    items:[]
                }
            },
            balance:{
                total_settle: 0, 
                total_withdrawal: 0,
                remain: 0
            },
            withdrawal:{
                items: [
                    {
                        state: 'requested',
                        created_at: '2020-03-31 10:11:20',
                        fulfillment_timestamp: null,
                        amount_before_tax: 4169673
                    },
                    {
                        state: 'fulfilled',
                        created_at: '2020-02-10 10:11:20',
                        fulfillment_timestamp: '2020-02-20 10:11:20',
                        amount_before_tax: 16048410
                    }
                ]
            },
            user_info: null,
            getMessage: null                // popup 메세지
            
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$EventBus.$emit("navi", "page_clear",'/')
        next()
    },
    created(){
         //window.addEventListener('scroll', this.handleScroll);
        setTimeout(function(){
          document.getElementById('calculate').classList.add('action')
        }, 100)
    },
    watch:{
        'saleline_form.start_month' (){
            this.startDate = {
                to: null,
                from: new Date(this.saleline_form.end_month)
            }
            this.endDate= {
                to: new Date(this.saleline_form.start_month),
                from: null
            }
        },
        'saleline_form.end_month' (){
            this.startDate = {
                to: null,
                from: new Date(this.saleline_form.end_month)
            }
            this.endDate= {
                to: new Date(this.saleline_form.start_month),
                from: null
            }
        }
    },
    destroyed(){
        //window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        const session_userInfo = JSON.parse(localStorage.SR_UserInfo)
        if(session_userInfo.licensor){
            this.updateChart()
        }
        this.$nextTick(function () {
     /*        setTimeout(function(){
                document.getElementById('calculate').classList.add('action')
            }, 200) */

            // 유통정보, 세금정보, 지급정보 미입력시
            /* const session_userInfo = JSON.parse(localStorage.SR_UserInfo) */
            if(this.licensorCheck(session_userInfo)){
                    this.popupOpen('accounting_guide',"none_licensor")
                
            }

            this.$EventBus.$emit("navi", "page_position",'accounting')

            this.$EventBus.$on('withdrawal_complete', (params) => {
                this.getUserBalance();
            })

            if (this.viewChange == "sales"){
                this.loadSaleslineSum();
            }
        
            if(session_userInfo.licensor) {
                this.currency = session_userInfo.licensor.currency 
            }
        })
    },
    computed: {
       
        getLang(){
            if (this.$store.state.languageCode == 'ko'){
                return this.ko;
            }else{
                return this.en;
            }
        },
        getCurrencyClass(){
            if(this.currency == 'usd'){
                return 'src_price_doller'
            }else if(this.currency == 'eur'){
                return 'src_price_euro'
            }
            return 'src_price'
        },
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const none_licensor = this.$i18n.t("management.msg.none_licensor")
        
                const messageCollection = {
                    none_licensor: none_licensor
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        },
        withdrawal_only(){
            if(this.withdrawal.items.length) return new Array(this.withdrawal.items.pop())
            else return this.withdrawal.items
       }
    },
    methods: {
        transactionDown(id){
            this.$EventBus.$emit('fullLoading', true)
            let params = {};
            params["user_language"] = this.$store.state.languageCode
            axios.post(process.env.VUE_APP_API_URL + "/transaction/"+id+"/excel",params,{ responseType: 'blob' })
            .then((data) => {
                
                this.getExcelFile(data)
                this.$EventBus.$emit('fullLoading', false)
            }).catch(error => {
                console.log("File Down Fail :::", error)
            })
        },
            
        resetRange(){
            this.saleline_form.monthRange = 0
            
            
        },
        handleScroll (){
            if( window.scrollY <650){
                this.scrollTop =  window.scrollY
                this.scrollOff= true;
            }
            if(this.scrollOff && window.scrollY > 850){
                this.updateChart()
                this.scrollOff=false;
            }
        },
        tab_view_active(viewChange){
            if(viewChange!= this.viewChange){
                this.viewChange = viewChange
                if (this.viewChange == "sales"){
                    this.loadSaleslineSum();
                }else if(this.viewChange == "payment"){
                    this.getUserBalance();
                }
            }
        },
        showPanel18(){
            // 출금 신청하기
            
            if(this.withdrawal_only.length > 0 ){
                if(this.withdrawal_only[0]){
                    let state = this.withdrawal_only[0].state
                    if(state !== 'fulfilled' && state !== "rejected"){
                        this.$EventBus.$emit('openPanel',{
                            component: "panel-19",
                            props: {
                                payment_method: this.user_info.licensor['bankaccount']['payment_method'],
                                entity: this.user_info.licensor['entity']
                            }
                        });
                    }else {
                        this.$EventBus.$emit('openPanel',{
                            props: {userInfo: this.user_info},
                            component: "panel-18",
                        });
                    }
                }
            }else{
                this.$EventBus.$emit('openPanel',{
                    props: {userInfo: this.user_info},
                    component: "panel-18",
                });
            }
            
            // const panel = this.$showPanel({
            //     component: "panel-18",
            //     width: "740px",
            //     props: {userInfo: this.user_info},
            //     openOn: 'right',
            //     cssClass: "panel-zindex"
            // })
        },
        monthSelect(id){
            let monthPicker = document.getElementById(id)
            monthPicker.click()
        },
        loadSaleslineSum(){
            if(this.salesLoading[1] || this.salesLoading[2] || this.salesLoading[3] ){
                return false
            }
            this.salesLoading ={
                1:true,
                2:true,
                3:true,
            }
            const search = this.saleline_form
            this.saleline_form.page = 0
            this.$store.dispatch("LOAD_SALELINES_SUM", {search})
                .then(result =>{
                    this.salesline_sum = result
                    this.salesLoading[1] = false
                    this.salesLoading[2] = false
                    this.updateChart()
                    this.loadSaleslineList()
                })
                .catch(error =>{
                    this.salesLoading[1] = false
                    this.salesLoading[2] = false
                    console.log("salesline summary Error ::", error)
                })
        },
        getExcelFile(response){
            let filename = "",
                disposition = response.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/, matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                    filename);
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        },
        getSaleslineExcel(){
            const search = this.saleline_form
            let sort, sortorder
            this.$EventBus.$emit('fullLoading', true)
            this.$store.dispatch("GET_SALELINES_EXCEL", {sort, sortorder, search})
                .then(result =>{
                     this.$EventBus.$emit('fullLoading', false)
                     console.log(result)
                    this.getExcelFile(result)
                })
                .catch(error =>{
                    console.log("salesline list Error ::", error)
                })
        },
        loadSaleslineList(){
            const search = this.saleline_form
            let sort, sortorder, page, limit
            page = this.saleline_form.page
            limit = this.saleline_form.limit
            this.salesLoading[3]= true
            this.$store.dispatch("LOAD_SALELINES_LIST", {sort, sortorder, page, limit, search})
                .then(result =>{
                    this.salesline_list = result
                    //paging 처리
                    const limit = this.saleline_form.limit
                    const total = this.salesline_list.total
                    this.paging.last = parseInt(total / limit)
                    let start = parseInt(this.saleline_form.page / 10) * 10
                    let end = start + 9
                    if(end > this.paging.last){
                        end = this.paging.last
                    }
                    let pages = []
                    for(var i=start; i<=end; i++){
                        pages.push(i);
                    }
                    this.paging.pages = pages
                    this.paging.prev = start - 1
                    if(this.paging.prev < 0){
                        this.paging.prev = 0
                    }
                    this.paging.next = end + 1
                    if(this.paging.next > this.paging.last){
                        this.paging.next = this.paging.last
                    }
                    this.salesLoading[3] =false
                })
                .catch(error =>{
                    this.salesLoading[3] =false
                    console.log("salesline list Error ::", error)
                })
        },
        getSumAmount(val){
            let value = 0
            if(val){
                value = val
            }
            //console.log('before:'+value)
                console.log(this.currency);
            if(this.currency == 'krw'){               
                //value = Math.floor(value * 100) / 100
                value = value? Math.floor(value).toLocaleString() : 0
            }else{
                
                value = Math.floor(value * 100) / 100
                value = value? value.toLocaleString('en-US', {minimumFractionDigits: 2}) : 0
                
            }
            //console.log('after:'+value)
            return value
        },
        getAmount(val){
            let value = 0
            if(val){
                value = val
            }
            if(this.currency == 'krw'){
                //value = Math.floor(value * 100) / 100
                value = value? value.toLocaleString('en-US', {minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0
            }else{
                //value = Math.floor(value * 10000) / 10000
                value = value? value.toLocaleString('en-US', {minimumFractionDigits: 4,maximumFractionDigits: 4}) : 0
            }
            return value
        },
        getCurrencyMark(){
            if(this.currency == 'usd'){
                return "$"
            }else if(this.currency == 'eur'){
                return "€"
            }
            return "₩"
        },
        getSalesCase(){
            if(this.$store.state.languageCode == 'ko'){
                return i18n.t("accounting.label.sales.case")
            }
            return ""
        },
        
        goToPage(page){
            if(this.viewChange == 'sales'){
                if(page == 0 || page != this.saleline_form.page){
                    this.saleline_form.page = page
                    this.loadSaleslineList()
                }
            }else if(this.viewChange == 'payment'){
                if(page == 0 || page != this.transaction.page){
                    this.transaction.page = page
                    this.getTransactions()
                }
            }
            
        },
        setSearchMonth(){
            this.saleline_form.end_month = new Date(moment().endOf('month'))
            this.saleline_form.start_month = new Date(moment(this.saleline_form.end_month).add(1 - this.saleline_form.monthRange, 'M').startOf('month'))
        },
        getServiceType(type) {
            if (type == 'ST'){
                return i18n.t("accounting.props.sales_filter.sales_type.streaming")
            }else if (type == 'DL'){
                return i18n.t("accounting.props.sales_filter.sales_type.download")
            }else{
                return i18n.t("accounting.props.sales_filter.sales_type.other")
            }
        },
        getYearMonth(date) {
            return moment(date).format('YYYY.MM');
        },
        getDate(date) {
            return moment(date).format('YYYY.MM.DD');
        },
        getCountry(code){
            let country_name = ""
            this.$store.state.country.map(country =>{
                if(country.code.toLowerCase() == code.toLowerCase()){
                    if(this.$store.state.languageCode == 'ko'){
                        country_name = country.name
                    }else{
                        country_name = country.name_en
                    }
                    
                }
            })
            return country_name
        },
        updateChart(){
            let labels = []
            let values = {
                st: [],
                dl: [],
                other: []
            }
            
            if(this.salesline_sum.monthly){
                this.salesline_sum.monthly.forEach((data)=> {
                    labels.push(data.month)
                    values.st.push(data.st)
                    values.dl.push(data.dl)
                    values.other.push(data.other)
                })
            }
             this.chart.bar.data = {
                labels: labels,
                //labels: ['1', '2', '3'],
                datasets: [
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.streaming"),  // 이름
                        backgroundColor: "#36a2eb",                                                  // Bar Color
                        data: [],                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.download"),    // 이름
                        backgroundColor: "#ff6484",                                                   // Bar Color
                        data: [],                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.other"),       // 이름
                        backgroundColor: "#4cc0c0",                                                   // Bar Color
                        data: [],                      // 데이터 수치
                    },
                ]
            }

            let streamingName = i18n.t("statistics.label.summary.sales_trend.graph_item.streaming")
            let downloadName = i18n.t("statistics.label.summary.sales_trend.graph_item.download")
            let otherName = i18n.t("statistics.label.summary.sales_trend.graph_item.other")
            this.chart.pie.data = {
                type: "y",
                labels: [],
                datasets:[
                    {
                        hoverBorderWidth: 2,
                        borderWidth: 2,
                        weight: 10,
                        backgroundColor: ["#36a2eb", "#ff6484", "#4cc0c0", "#2548ca"],    
                        data: [],                                          
                    }
                ]
            }
            
            setTimeout(() => {
                this.chart.bar.data = {
                    labels: labels,
                //labels: ['1', '2', '3'],
                datasets: [
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.streaming"),  // 이름
                        backgroundColor: "#36a2eb",                                                  // Bar Color
                        data: values.st,                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.download"),    // 이름
                        backgroundColor: "#ff6484",                                                   // Bar Color
                        data: values.dl,                      // 데이터 수치
                    },
                    {
                        label: i18n.t("statistics.label.summary.sales_trend.graph_item.other"),       // 이름
                        backgroundColor: "#4cc0c0",                                                   // Bar Color
                        data: values.other,                      // 데이터 수치
                    },
                ]
            }
                this.chart.pie.data = {
                type: "y",
                labels: [streamingName, downloadName, otherName],
                datasets:[
                    {
                        hoverBorderWidth: 2,
                        borderWidth: 2,
                        weight: 10,
                        backgroundColor: ["#36a2eb", "#ff6484", "#4cc0c0", "#2548ca"],    // Graph Color 
                        data: [this.salesline_sum.total.st.user_amount, this.salesline_sum.total.dl.user_amount, this.salesline_sum.total.other.user_amount],                                                 // Data 
                    }
                ]
            }
            }, 200);
           
          
        },
        getRandomInt () {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5
        },
        reverse_transaction(){
            this.transation.items = this.transation.items.reverse()
        },
        //payment section
        getUserBalance(){
            this.paymentLoading = {
                1:true,
                2:true,
                3:true
            }

            this.$store.dispatch("GET_USER_INFO")
                .then(result =>{
                    
                    this.user_info = result
                    this.balance.total_settle = result.licensor.total_earnings
                    this.balance.total_withdrawal = result.licensor.total_withdrawed
                    this.balance.remain = result.licensor.balance
                    if(!this.balance.total_earnings){
                        this.balance.total_earnings = 0
                    }
                    if(!this.balance.total_withdrawed){
                        this.balance.total_withdrawed = 0
                    }
                    if(!this.balance.remain){
                        this.balance.remain = 0
                    }
                    this.paymentLoading[1]=false
                    this.getWithdrawals()
                    
                })
                .catch(error =>{
                    console.log("get user balance Error ::", error)
                })
        },
        getWithdrawals(){
            const search = this.saleline_form
            let sort, sortorder
            this.$store.dispatch("LOAD_WITHDRAWAL_LIST")
                .then(result =>{
                    this.withdrawal = result
                    this.paymentLoading[2] = false
                    this.getTransactions()
                })
                .catch(error =>{
                    console.log("withdrawal list Error ::", error)
                })
        },
        getTransactions(){
            this.paymentLoading[3] = true
            let sort
            const sortorder = this.transaction.sortorder
            const page = this.transaction.page
            const limit = this.transaction.limit
            const type = this.transaction.filter
            this.$store.dispatch("LOAD_TRANSACTION_LIST", {sort, sortorder, page, limit, type})
                .then(result =>{
                    this.transaction.result = result
                    //paging 처리
                    const limit = this.transaction.limit
                    const total = this.transaction.result.filtered
                    this.paymentPaging.last = parseInt(total / limit)
                    let start = parseInt(this.saleline_form.page / 10) * 10
                    let end = start + 9
                    if(end > this.paymentPaging.last){
                        end = this.paymentPaging.last
                    }
                    let pages = []
                    for(var i=start; i<=end; i++){
                        pages.push(i);
                    }
                    this.paymentPaging.pages = pages
                    this.paymentPaging.prev = start - 1
                    if(this.paymentPaging.prev < 0){
                        this.paymentPaging.prev = 0
                    }
                    this.paymentPaging.next = end + 1
                    if(this.paymentPaging.next > this.paymentPaging.last){
                        this.paymentPaging.next = this.paymentPaging.last
                    }
                    this.paymentLoading[3] = false
                })
                .catch(error =>{
                    this.paymentLoading[3] = false
                    console.log("transaction list Error ::", error)
                })
        },
        getTransactionExcel(){
            let sort
            const sortorder = this.transaction.sortorder
            const page = this.transaction.page
            const limit = this.transaction.limit
            const type = this.transaction.filter
            this.$EventBus.$emit('fullLoading', true)
            this.$store.dispatch("GET_TRANSACTION_EXCEL", {sort, sortorder, page, limit, type})
                .then(result =>{
                    this.getExcelFile(result)
                     this.$EventBus.$emit('fullLoading', false)
                })
                .catch(error =>{
                    this.$EventBus.$emit('fullLoading', false)
                    console.log("transaction excel Error ::", error)
                })
        },
        popupOpen(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            this.guideMessage = title
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 0)
        },
        popupClose(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
             popup.classList.remove('type2')
          }, 0)
        
          this.$EventBus.$emit("navi", "page_position",'my')
          this.$router.push('/my')
        },
        popupClose2(event){
            // popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
             popup.classList.remove('type2')
          }, 300)
        }
    }
}
</script>


<style>

.account .sv_z .bg{
  background-image:url('../../assets/images/sv_img.png') !important;
  background-repeat:no-repeat;
  background-position:50% 0;
  transform: scale(1.6);
  background-size:cover;
  transition:1.2s;
  display:block;
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
}

.sv_z .sv_copy{
  transition-delay: .1s; 
  transition-duration: .8s;
  transform: translateX(-80px);
  opacity:0;
  filter:alpha(opacity=100);
}

.sv_z .sv_copy2{
  transition-delay: .7s;
  transition-duration: .8s;
  transform: translateX(-100px);
  opacity:0;
  filter:alpha(opacity=100);
}
.action .sv_z .bg{transform: scale(1) !important;}
.action .sv_z .sv_copy{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
.action .sv_z .sv_copy2{transform: translateX(0);opacity:1;filter:alpha(opacity=100);}
.width100{
    width: 100%;
}

.width60 {
    width: 60%;
}

.width25 {
    width: 15%;
}



.disabledColor {
    background-color: #888;
}
</style>