<script>
import { Line, mixins } from "vue-chartjs";

export default {
    extends: Line,
    props: ["chartOption"],
    mixins: [mixins.reactiveProp],
    data(){
        return {
            options: this.chartOption
        }
    },
    mounted() {
        this.options.scales = {
            yAxes: [{
                ticks: {
                    callback: function(value, index, values) {
                        return value.toLocaleString()
                    }
                }
            }]
        }
        this.options.tooltips = {
            callbacks: {
                label: function(tooltipItem, chart){
                    var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                    return datasetLabel + ': ' + tooltipItem.yLabel.toLocaleString();
                }
            }
        }
        this.renderChart(
        this.chartData, this.options
        )
    }
}

</script>