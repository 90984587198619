<script>
import { Bar } from "vue-chartjs";

export default {
    props: ["chartOption"],
    data(){
        return {
            label: this.chartOption.label,                      // X 축 항목
            stacked: this.chartOption.stacked,                  // X,Y 축 그래프 스타일 설정 : 쌓임 여부
            datasets: this.chartOption.datasets,                // 그래프 데이터셋 설정
            legend_position: "bottom",                          // legend 위치
            legend: true                                        // legend 존재 여부      
        }
    },
    extends: Bar,
    computed: {
        datasets_pop() {
            let datasets = this.datasets
            return datasets.filter(dataset =>{
                if(!dataset.is_all) return dataset
            })
        }
    },
    mounted() {
        if(this.chartOption.hasOwnProperty('options')){
            if(this.chartOption.options.hasOwnProperty('legend')){
                this.legend = this.chartOption.options.legend
            }
            if(this.chartOption.options.hasOwnProperty('legend_position')){
                this.legend_position = this.chartOption.options.legend_position
            }
        }

        this.renderChart(
        {
            labels: this.label,
            datasets: this.datasets_pop
        },
        { 
            responsive: true, maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    stacked: this.stacked,
                    categoryPercentage: 0.5,
                    barPercentage: 1
                }],
                yAxes: [{
                    stacked: this.stacked
                }]
            },
            legend: {
                display: this.legend,
                position: this.legend_position,
                labels: {
                    boxWidth: 80,
                    fontColor: 'black'
                }
            }
        })
    }
}
</script>
