<template src="./bar_x.html"></template>
<script>

export default {
    props: ["chartOption"],
    name: "Bar_xChart",
    data(){
        return {
            chartType: this.chartOption.type,                   // Chart Style Type  : x형, 
            datasets: this.chartOption.datasets                 // 그래프 데이터셋 설정
        }
    },
    computed: {
        viewType() {
            if(this.chartType) {
                return this.chartType
            } else{
                return 'x'
            }
        }
    }
}
</script>

<style scoped>
#bar_x{
    width: 100%;
    min-height: 75px;
    font-size: 16px;
}
.graph {
    width: 95%;
    padding-right: 5%;
}
.graph th, .graph td{
    text-align: left;
}

.graph_item_name {
    height: 30px;
}

.graph_bar {
    height: 20px;
}

.bar_shape {
    width: 50%;
    height: 10px;
    background-color: #ff0000;
    border-radius: 10px;
}

.graph_sum {
    font-size: 18px;
    font-weight: bold;
}

.graph_bar table{
    width: 100%;
}

.graph li{
    width: 50%;
    display: inline-block;
}

.graph li:last-child{
    width: 100%;
}

</style>
