<template>
    <div   class="container">
        <div class="splayout_body">
            <div class="splayout_z">
                <div class="splayout_tb">
                    <div class="splayout_td">
                        <!-- spbox_w -->
                        <div class="spbox_w">
                            <h1 class="spbox_tit">{{$t('apply.title')}}</h1>
                            <div class="spbox_cont">
                                <div v-if="$i18n.locale=='ko'" style="font-size: 16px; color:#000;">
                                    음원발매 신청이 정상적으로 접수되었습니다. <br>
                                    <br>
                                    담당자 확인 후 신청서에 입력하신 이메일로 회신 드리도록 하겠습니다.<br>
                                    <br>
                                    <span style="font-size: 15px; color: #6a6a6a;">* 검토 및 결과 회신에는 약 3~5영업일이 소요될 수 있습니다.</span>
                                </div>
                                <div v-else style="font-size: 16px; color:#000;">
                                    Your music publishing application has been successfully submitted.
                                    <br>
                                    <br>
                                    After checking with the person in charge, we will reply to the email you entered in the application.
                                    <br>
                                    <br>
                                    <span style="font-size: 15px; color: #6a6a6a;">*It may take about 3-5 business days to review and reply.</span>
                                    
                                </div>
                                <br>
                                <br>
                                <router-link to="/">
                                    <a href="javascript:void(0);" class="btn blue" style="display: block; margin: 0 auto; width: 100%; max-width: 140px;">HOME</a>
                                </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- // spbox_w -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
           
            dataTemplate: {
                "desired_release_date": null,
                "desired_release_time": "12:00:00",
                "album_title": null,
                "album_artist": null,

                "url_artist": null,
                "url_demo": null,
                "has_mv": true,
                
                "remark": "",
                "ready_date" : null,
            },
            disabledDate: []
        }
    },
     
    watch: {
         
    },
    created(){
       
    },
    mounted() {
        this.$nextTick(function () {
             
            const today = new Date();
             
            
        })
    },
     
    methods: {
        
        
    }
}
</script>   