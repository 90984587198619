<template src="./template.html"></template>

<script>
// window.vue2PanelDebug = true;
import i18n from '../../../library/i18n' // 다국어지원

export default {
    name: 'panel-3',
    props: ['listData', 'eventName'],
    data() {
        return {
            introduce_active_lang: 'kr',
            artistData: {}, // 아티스트 앨범 정보
            data : {}
        }
    },
    watch: {
        
    },
    computed: {
     
        artiImage(){
            let profile_image_path = this.artistInfo.profile.remote_path
            let image1_path = this.artistInfo.image1.remote_path
            let image2_path = this.artistInfo.image2.remote_path
        },
        artistInfo(data){
            let props = data.$attrs.props
            if(props.listData.hasOwnProperty('id')){
                let id = props.listData.id
                 
                this.$store.dispatch('LOAD_ARTIST_ALBUM', id)
                .then(result => {
                    result.items.map(item => {
                        if(item.release_date) item.release_date = item.release_date.split("T")[0]
                    })
                    this.artistData = result
                })
                    return props.listData
            }else{
                return props.listData
            }
            
        },
        imageList(){
            const el = ['<li v-if="artistInfo.profile"><div class="srdslider_item" :style="{backgroundImage: '+'url(\'' + this.artistInfo.profile.thumbnail.remote_path + '\')'+'}"></div></li>'
            ,'<li v-if="artistInfo.profile"><div class="srdslider_item" :style="{backgroundImage: '+'url(\'' + this.artistInfo.profile.thumbnail.remote_path + '\')'+'}"></div></li>'
            ]
            return el
        },
        type(){
            const type = i18n.t('common.props.artist.type')
            return type[this.artistInfo.type]
        },
        nationality(){
            const country = this.$store.state.country
            let result = null
            country.map(value => {
                if(this.artistInfo.nationality){
                    if(value.code.toLowerCase() == this.artistInfo.nationality.toLowerCase() ) {
                        switch(i18n.locale.toLowerCase()) {
                            case 'ko':
                                result = value.name
                                break;
                            default:
                                result = value.name_en
                                break;
                        }
                    }    
                }
            })
            return result
        },
        language(data){
            
            let props = data.$attrs.props
            const biography_language = props.listData.biography_language
            let language = null
            this.$store.state.language.map(lang => {
                if(lang.code){
                    if(biography_language){
                        if(lang.code.toLowerCase() == biography_language.toLowerCase()){
                            this.introduce_active_lang = lang.code.toLowerCase()
                            language = this.$i18n.locale == 'ko'? item.name : item.en_name
                        }
                    }
                }
            }) 
            return language
        },
        i18n_message() {
            return this.$t('management')
        },
    },
     
    methods: {
        closePanel(){
            this.$emit('closePanel');
        },
        showPanel14(){
            // const panel = this.$showPanel({
            //     component: "panel-14",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex"
            // })
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-14",
            });
        },
        dateFormat(value){
            return value.split("T")[0]
        },
        tab_language_active(event){
             let parentNode = event.target.parentNode

            if(parentNode.classList != "active") {
                parentNode.classList.add('active')
            }

            let selector_id = parentNode.getAttribute( 'id' )
            switch(selector_id){
                case 'panel3_tab01':
                    this.introduce_active_lang = this.listData.description_language
                    document.getElementById('panel3_tab02').classList.remove('active')
                    break;
                case 'panel3_tab02':
                    this.introduce_active_lang = 'en'
                    document.getElementById('panel3_tab01').classList.remove('active')
                    break;
            }   
        },
        getGenre(id) {
            return this.$store.dispatch('GETGENRE', id)
        },
        update(){
            let id = this.listData.id
            let params = {
                state: "suspended"
            }
            this.$store.dispatch("UPDATEALBUM", {id, params}).then(result => {
                this.closePanel()
            })
        },
        srdPopupCall(){
            var srdslider = null;
            SRD.srdLayerShow('#srdTarget01',function(){
                // 상단 슬라이더
                var $srdslider_list = $(".srdslider_list");
                if($srdslider_list.length){
                    if(srdslider === null){
                        srdslider = $srdslider_list.slick({
                            dots: true,
                            arrows : false,
                            touchMove: true
						});
					}
				}
			})
        },
        album_more(){
            let params = {
                id: this.artistInfo.id,
                eventName : "album_more"
            }
             this.$EventBus.$emit('albumList',params);
            this.closePanel()
            
        },
        goLink(address){
            this.$router.push({path: address, target: "_blank"})
        }
    }
}
</script>

<style scoped>
.agile__dot--current button{
    background-color:#888;
}
</style>