<template src="./template.html"></template>

<script>
// window.vue2PanelDebug = true;
import i18n from '../../../library/i18n' // 다국어지원

export default {
    name: 'panel-4',
    props: ['listData','eventName',''],
    data() {
        return {
            introduce_active_lang: "kr",
            genre1_name: null,
            genre2_name: null
        }
    },
    watch: {
        listData(data){
            return data
        }
    },
    computed: {
        albumInfo(data){
            let props = data.$attrs.props.listData
           
             // 장르1 이름
            if(props.genre1_id) {
                this.genre1_name = props.genre1.name_english
                
            }
            // 장르2 이름
            if(props.genre2_id) {
                this.genre2_name = props.genre2.name_english
            }
            return data.$attrs.props.listData
        },
        language(data){
            let props = data.$attrs.props.listData
            const description_language = data.$attrs.props.listData.description_language
            let language = null
            this.$store.state.language.map(lang =>{
                if(lang.code){
                    if(description_language){
                        if(lang.code.toLowerCase() == description_language.toLowerCase()){
                            this.introduce_active_lang = lang.code.toLowerCase()
                            language = this.$i18n.locale == 'ko'? item.name : item.en_name
                        }
                    }
                }
            }) 
            return language
        }

    },
    methods: {
        getToLowerCase(value){
            return value.toLowerCase()
        },
        closePanel(){
            this.$emit('closePanel', {})
        },
        showPanel14(){
           this.$EventBus.$emit('openPanelSub',{
                component: "panel-14",
               
            });
        },
        dateFormat(value){
            if(!value) {
                return ''
            }
            return value.split("T")[0]
        },
        tab_language_active(event){
            let parentNode = event.target.parentNode
            if(parentNode.classList != "active") parentNode.classList.add('active')
            let selector_id = event.target.parentNode.getAttribute( 'id' )
        
            switch(selector_id){
                case 'panel4_tabc01':
                    this.introduce_active_lang = 'ko'
                    document.getElementById('panel4_tabc02').classList.remove('active')
                    break;
                case 'panel4_tabc02':
                    this.introduce_active_lang = "en"
                    document.getElementById('panel4_tabc01').classList.toggle('active')
                    break;
            }   
        },
        getGenre(id) {
            return this.$store.dispatch('GETGENRE', id)
        },
        update(){
            let id = this.albumInfo.id
            // local 앨범 정보 상태 업데이트반영
            //this.albumInfo.state = "registration_canceled"
            
            this.confirm({
                title : ` ${this.$i18n.t('distribution.cancel')}`,
                msg : `
                    ${this.$i18n.t('distribution.cancel.msg')}
                `,
                autofocus : true,
                width:400,
                action : ()=>{
                    let payload = {
                        id : `${this.$route.params.id}`,
                    }
                    
                    this.loadingShow();
                    this.$store.dispatch("DISTRIBUTION_CANCEL", id).then(result =>{
                        // local 앨범 정보 업데이트반영
                        
                        this.$EventBus.$emit('albumList', {eventName: "album_cancel"})
                        this.closePanel()
                        this.loadingHide();
                    })
                }
            })

            
        },
        showPanel17(trackData){
            // this.$showPanel({
            //     component: "panel-17",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {
            //         trackData: trackData
            //     }
            // })
            this.$EventBus.$emit('openPanelSub',{
                component: "panel-17",
                props: {
                    trackData: trackData
                }
            });
        },
    }
}
</script>

<style scoped>
.width1{
    width: 1px;
}
</style>