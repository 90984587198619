<template src="./template.html"></template>

<script>
export default {
    name: "artistList",
    data() {
        return {
            albb :true,
            sort: null,
            state: null,
            artistList: [],
            viewChange: "album",        // 앨범리스트 -> 앨범추가하기 화면 전환,
            
            total: 0,                   // 전체 앨범 수
            limit: 40,                  // 리스트 제한
            eventName: "artistList",     // EventBus Event Name
            getMessage: null,            // popup message
            infoData: [],
            result: 0,
            count: 0,
            variousArtist: false,
            arrayResult: [],
            arrayList: [],
            search: null,                   // 검색
            filter: null,
            filtered: 0,                    // 검색된 앨범 수
            search_artistList: [],          // 아티스트 리스트
            artist: null,                   // 아티스트 검색어
            search_condition: {
                limit: 40,                  // 리스트 노출 갯수 제한
                state: null,                // flt
                sort: 'created_at',
                sortorder: "DESC"          
            },
            
            albumName : null,
            album_name_language : null,

            artistList : {
                data: [],
                limit : 8,
                total : 1,
                totalPage : 1,
                currentPage : 1
            },

            state : null ,
            keywordType : null,
            keyword : null,

            listType : 'grid',  // thumbnail   B: list 
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$EventBus.$emit("navi", "page_clear",'/')
        next()
    },
  
    created(){
        
        
        // page postion
        if(this.$route.query.page){
            this.artistList.currentPage = Number(this.$route.query.page)
        }
       
        if(this.$route.query.name){
            this.keyword= this.$route.query.name
        }
        
    },
    mounted() {
        this.$nextTick(function () {
           
            // 유통정보, 세금정보, 지급정보 미입력시
            const session_userInfo = JSON.parse(localStorage.SR_UserInfo)
            
            if(this.licensorCheck(session_userInfo)){
                this.popupOpen('albumList_guide',"none_licensor")
            }else{
                this.listSearch()
            }
            

            
        })
    },
    computed: {
        i18n_message() {
            return this.$t('management')
        },
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const none_licensor = this.$i18n.t("management.msg.none_licensor")
        
                const messageCollection = {
                    none_licensor: none_licensor
                }

                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        }
    },
    watch: {
        'artistList.currentPage'(){
            
            this.call()
        },
        'listType'(value){
            localStorage.setItem('listType', value);
            if(value == 'grid'){
                this.artistList.limit = 8
            }else{
                this.artistList.limit = 10
            }
            
            this.call()
        },
        // artistList(){
        //     this.total = this.artistList.total
        // },
        // artistList(){
        //     this.total = this.artistList.total
        // }
    },
    methods: {
        listSearch(){
            this.call();
        },
        call(){
            const option = {
                sort : "created_at",
                sortorder: 'desc',
                page: this.artistList.currentPage-1 ,
                limit:this.listType == 'thumbnail' ? 8 : this.artistList.limit,
                
            }
            let query = {
                
            }
          
            if (!this.valCheck(this.keyword)) {
                option['display_name'] = this.keyword
                query['name'] = this.keyword
                
            }
            if(this.artistList.currentPage != 1){
                query['page'] = this.artistList.currentPage
            }

            this.$router.replace({query: query}).catch((e)=>{});
            this.loadingShow()
            this.$store.dispatch("LOAD_ARTISTLIST", option)
            .then(result =>{
               
                this.loadingHide();
                this.artistList.data = result.items
                const remainder = parseInt(result.filtered % this.artistList.limit) > 0? 1: 0
                this.artistList.totalPage = parseInt(result.filtered/this.artistList.limit) + remainder
                this.artistList.total = result.total
                if(result.items.length < 1 ){
                    this.artistList.currentPage = 1    
                }
                window.scrollTo(0,0)
            }).catch(error =>{
                this.alert({msg : 'API Error <br>' + error.response.message})
                this.loadingHide();
                console.log("앨범 리스트 불러오기 Error ::", error)
            })
        },
        loadArtistList(){
            let sort = "display_name"
            let sortorder= "ASC"
            let page = 0
            let limit = this.limit
            let column = ""
            this.loadingShow();
            this.$store.dispatch("LOAD_ARTISTLIST", {sort, sortorder, page, limit, column})
            .then(result =>{
                this.loadingHide();
                this.artistList = result
                this.total = result.total
            }).catch(error =>{
                this.loadingHide();
                console.log("아티스트 리스트 불러오기 Error ::", error)
            })
        },
        listFilter(artist_id){
            if(this.viewChange === 'album'){
                this.$store.dispatch("artistLIST", {artist_id})
                    .then(result =>{
                        this.artistList = result
                    })
                    .catch(error =>{
                        console.log("앨범 리스트 불러오기 Error ::", error)
                    })
            }
        },
        popupOpen(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            this.guideMessage = title
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupClose(event){
            // popup close
            const parent = event.target.parentNode.parentNode.parentNode
            let popup = document.getElementById(parent.id)
            popup.firstChild.classList.remove('type2')
            setTimeout(function(){
                popup.classList.remove('type2')
            }, 300)
            
            this.$EventBus.$emit("navi", "page_position",'my')
            this.$router.push('/my')
        },
        addAlbum(){
            
            // if(this.valCheck(this.album_name_language)){
            //     this.alert({msg:this.iText('언어를 선택해주세요.','enter.language')})
            //     return false
            // }
            if(this.valCheck(this.albumName)){
                this.alert({msg:this.iText('앨범명을 입력해주세요.','enter.album.name')})
                return false
            }
            let obj={
                name : this.albumName,
                name_language : this.album_name_language,
                description_language : this.album_name_language,
                label_language : this.album_name_language,
                name_multilang : {
                    "ko" : null,
                    'en' : null,
                }
            }
            this.loadingShow()
            this.$store.dispatch("POST_ALBUM", obj).then(result => {
                
                this.$router.push('/distributionrenew/'+ result.id)
            }).catch(error =>{
                this.loadingHide()
            })
        },
        showPanel3(listData) {
            // const panel = this.$showPanel({
            //     component: "panel-3",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {
            //         listData: listData,
            //         eventName: this.eventName
            //     }
            // });
            // console.log(listData);
            
        },
        showPanel2(){
            this.$EventBus.$emit('openPanel',{
                component: "panel-2",
                props: {
                    eventName: 'artistAdd'
                }
            });
            console.log('zzz')
            this.$EventBus.$on('artistAdd', (result)=>{
                console.log(result)
                this.call()
                this.$EventBus.$emit('closePanel', {});
            })
        },
        viewDetail(data){
            this.$EventBus.$emit('openPanel',{
                component: "panel-3",
                props: {
                    listData: data,
                    eventName: this.eventName
                }
            });
        }
    }
}
</script>


<style scoped>

</style>