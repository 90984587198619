<template src="./template.html"></template>

<script>


export default {
    data(){
        return {
            password: null,
            success: false,
            responseEmail: null,
            emailToken: null,
            passwordCheck: false,      // 패스워드 check
            // Email 인증 Parameter
            email: this.$route.query.email? this.$route.query.email : null,
            authToken: this.$route.query.token? this.$route.query.token : null,
            error: [], //Error Message
            passwordView : false,
        }
    },
    methods: {
        onsubmit(){
            let password = this.password? this.password : null
            const email = this.email? this.email : null
            const token = this.authToken? this.authToken : null
            let params = [
                password,
                email,
                token
            ]

            let type = null
            if(this.passwordCheck){
                 let type = null
                this.$store.dispatch('EMPTYCHECK', { type, params})
                    .then((result) => {
                        if(!result) {
                            this.$store.dispatch("RESETPASSWORD", { email, token, password }).then(() =>{
                                this.success = true
                            }).catch((message) => {
                                this.alert({msg: this.$t('password.linkError')})
                                console.log("Refresh Password fail")
                            })
                        }else {
                            alert("Refresh Password Params Empty")
                        }
                    })
            }
        },
        validPassword(){
            let password = this.password
            this.$store.dispatch("VALIDPASSWORD", password).then((result) => {
                let confirmBtn = this.$refs.confirmBtn
                if(result){
                    confirmBtn.classList.remove("btnDisabled")
                    confirmBtn.classList.add("btnActive")
                    confirmBtn.removeAttribute("disabled")
                    this.passwordCheck = true
                    this.errorMessage('password')
                }else{
                    confirmBtn.classList.remove("btnActive")
                    confirmBtn.classList.add("btnDisabled")
                    confirmBtn.setAttribute("disabled", true)
                    this.passwordCheck = false
                    // Error Message Show
                    const error = this.error.indexOf('password')
                    if(error === -1){
                        this.error.push('password')   // password 형식 에러
                    }
                }
            }).catch((message) => console.log("VALIDPASSWORD FAIL :::"))
        },
        goLogin() {
            this.$router.push('/login')
        },
        errorMessage(id){
            // Error Message Hide
            const error = this.error.indexOf(id)
            if(error > -1){
                this.error.splice(error, 1);
            }
        },
        pwd_openBtn(e){
           this.passwordView = !this.passwordView
        }
    }
}
</script>

<style>
.btnActive {
    background-color: aqua;
}

.btnDisabled{
    background-color: #666;
}
</style>