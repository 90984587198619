<template src="./detail.html"></template>

<script>
export default {
    name: "AlbumList",
    data() {
        return {
            
            dataTemplate :{
                coverimage:{
                    thumbnail  : {
                        remote_path : null
                    }
                },
                genre1_id: null,
                genre2_id :null,
            },
            linkList : [
                {name :'MELON', type : 'melon', link : null, edit : false},
                {name :'GENIE', type : 'genie', link : null, edit : false},
                {name :'FLO', type : 'flo', link : null, edit : false},
                {name :'BUGS', type : 'bugs', link : null, edit : false},
                {name :'VIBE', type : 'vibe', link : null, edit : false},
                {name :'SPOTIFY', type : 'spotify', link : null, edit : false},
                {name :'APPLEMUSIC', type : 'applemusic', link : null, edit : false},
                {name :'YOUTUBE', type : 'youtube', link : null, edit : false},
                {name :'DEEZER', type : 'deezer', link : null, edit : false},
                {name :'PANDORA', type : 'pandora', link : null, edit : false},
                {name :'QQMUSIC', type : 'qqmusic', link : null, edit : false},
                {name :'AMAZON', type : 'amazon', link : null, edit : false},
                {name :'TIDAL', type : 'tidal', link : null, edit : false},
            ],
            linkType :[
                {name :'MELON', type : 'melon', link : null, edit : false},
                {name :'GENIE', type : 'genie', link : null, edit : false},
                {name :'FLO', type : 'flo', link : null, edit : false},
                {name :'BUGS', type : 'bugs', link : null, edit : false},
                {name :'VIBE', type : 'vibe', link : null, edit : false},
                {name :'SPOTIFY', type : 'spotify', link : null, edit : false},
                {name :'APPLEMUSIC', type : 'applemusic', link : null, edit : false},
                {name :'YOUTUBE', type : 'youtube', link : null, edit : false},
                {name :'DEEZER', type : 'deezer', link : null, edit : false},
                {name :'PANDORA', type : 'pandora', link : null, edit : false},
                {name :'QQMUSIC', type : 'qqmusic', link : null, edit : false},
                {name :'AMAZON', type : 'amazon', link : null, edit : false},
                {name :'TIDAL', type : 'tidal', link : null, edit : false},
            ]
        }
    },
   
    created(){
        // page postion
       
    },
    mounted() {
        this.$nextTick(function () {
           
            this.callData()
        })
    },
    computed: {
        
    },
    watch: {
        
    },
    methods: {
        callData(){
            
            this.loadingShow()
            this.$store.dispatch("LOAD_ALBUM", this.$route.params.id)
            .then(result =>{
               this.dataTemplate = result
            }).catch(error =>{
                console.log("앨범 리스트 불러오기 Error ::", error)
            })
            
            this.$store.dispatch("LOAD_ALBUM_LINK", this.$route.params.id)
            .then(result =>{
               this.linkList = result.items
            }).catch(error =>{
                console.log("앨범 리스트 불러오기 Error ::", error)
            })
        },
         
        showPanel17(trackData){
            // this.$showPanel({
            //     component: "panel-17",
            //     width: "740px",
            //     openOn: 'right',
            //     cssClass: "panel-zindex",
            //     props: {
            //         trackData: trackData
            //     }
            // })
            this.$EventBus.$emit('openPanel',{
                component: "panel-17",
                props: {
                    trackData: trackData
                }
            });
        },
    }
}
</script>


<style scoped>

</style>