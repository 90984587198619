<script>
import { Bar, mixins } from "vue-chartjs";
import { Chart } from "chart.js";
export default {
    props: ["chartOption"],
    mixins: [mixins.reactiveProp],
    data(){
        return {
            options: this.chartOption
        }
    },
    extends: Bar,
    mounted() {
        Chart.defaults.global.datasets.bar.categoryPercentage = 0.5;
        this.options.scales.yAxes= [{
            stacked: true,
            ticks: {
                callback: function(value, index, values) {
                    return value.toLocaleString()
                }
            }
        }]
        this.options.tooltips = {
            callbacks: {
                label: function(tooltipItem, chart){
                    var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                    return datasetLabel + ': ' + tooltipItem.yLabel.toLocaleString();
                }
            }
        }
        this.renderChart(
        this.chartData, this.options
        )
    }
}
</script>
