<template src="./template.html"></template>

<script>
export default {
    data(){
        return {
            success: false,
            email: null,
            validEmail: false,
            verifyFail: "",
            getMessage: null,
            error:[]
        }
    },
    created(){
        this.email = this.$route.query.email
    },
    computed: {
        guideMessage: {
            get: function () {
                return this.getMessage
            },
            // setter
            set: function (title) {
                // 안내 메세지
                const sendEmail = this.$i18n.t("uncertified.msg.sendEmail")
                const messageCollection = { 
                    sendEmail: sendEmail
                }
                const keys = Object.keys(messageCollection)
                keys.forEach(key =>{
                    if(key == title){
                        this.getMessage = messageCollection[key]
                    }
                })
            } 
        }
    },
    methods: {
        onSubmit(){
            this.error = []
            const email = this.email
            new Promise(resolve =>{
                this.$store.dispatch("VALIDEMAIL", email)
                .then((result) => {
                    if(result) {
                        this.validEmail = true
                    }else{
                        this.validEmail = false
                        this.error.push('validEmail')
                    }
                    resolve(this.validEmail)
                })
            }).then(result =>{
                if(result){
                    this.$store.dispatch('RESENDEMAIL', email).then(()=> {
                        this.success = true
                        this.popupOpen01('uncerified_guide01','sendEmail')
                    }).catch((Error) => {
                        this.error.push('sendEmail')
                    })
                }
            })
        },
        popupOpen01(id, title){
            // popup Open
            let popup = document.getElementById(id)
            popup.classList.add('type2')
            this.guideMessage = title
            setTimeout(function(){
                popup.firstChild.classList.add('type2')
            }, 300)
        },
        popupClose01(event){
            // licensor popup close
          const parent = event.target.parentNode.parentNode.parentNode
          let popup = document.getElementById(parent.id)
          popup.firstChild.classList.remove('type2')
          setTimeout(function(){
             popup.classList.remove('type2')
          }, 300)
        }
    }
}
</script>

<style scoped>
.warn_textp_etc {
    color: #ff1e43 !important;
    font-size: 15px;
    margin-top: 10px;
    line-height: 1.2;
    text-align: center;
}
</style>