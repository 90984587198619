<template>
  <div id="app">
    <navigationBar></navigationBar>
    <router-view/>
    
    
    <panelBox>
      <panelSubBox></panelSubBox>
    </panelBox>

    <div class="dimm_box" v-if="fullLoading">
      <scale-loader :loading="fullLoading" color="#FFF" size="200px"></scale-loader>
      
    </div>

    <v-modal name="alertConfirm" 
      @before-open="setModal2"
      @opened="focusEvent"
      :width="'100%'"
      :maxWidth="modalConfirm.width ? modalConfirm.width : 400"
      height="auto"
      :adaptive="true"
    >
      <div class="onlyDark">
        <div class="modalHeader">
            <div  class="srd_layer_tit_low"><h2  class="srd_layer_tit">{{modalConfirm.title ?  modalConfirm.title : $t('confirm')}}</h2></div>
        </div>
        <div class="modalCont">
          <div  style="padding:2rem 1rem; color:#000; text-align:center; word-break:keep-all; font-size: 16px;"  v-html="modalConfirm.msg">
          </div>
          <div class="btnBox" v-if="modalConfirm.action">
            <button class="cancel" @click="closeModal('alertConfirm')"  type="filled">{{$t('cancel')}}</button>
            <button ref="buttonConfirm"  color="success" class="ml-3" id="confirmOk" @click="modalConfirmAction" type="filled">{{$t('confirm')}}</button>
          </div>
        </div>
      </div>
    </v-modal>
    <v-modal name="alert"
      transition="none"
      @before-open="setModal"
      :width="'100%'"
      :maxWidth="modalparam.width ? modalparam.width : 400"
      height="auto"
      :adaptive="true"
      
    >
      <div class="srd_layer_tit_low"><h2 class="srd_layer_tit">{{$t('confirm')}}</h2><button type="button" @click="closeModal('alert')" class="btn_srdlayer_close"><span class="hdtext">레이어팝업 닫기</span></button></div>
      <div class="dac_cont confirm" v-html="modalparam.msg"></div>
      <div class="dac_btn_low">
          <button type="button" @click="closeModal('alert')" class="btn_dac tclose">{{$t('confirm')}}</button><!-- tclose 클래스가 있어야 레이어 팝업 닫힘 -->
      </div>
    </v-modal>
    <v-modal name="mobileCheck"
      transition="none"
      :width="'100%'"
      :maxWidth="modalparam.width ? modalparam.width : 400"
      height="auto"
      :adaptive="true"
      scrollable
      :clickToClose="false"
      @before-close="beforeClose"
    >
    
      <div class="dac_cont ">
        <p v-html="$t('mobile')"></p>
      </div>
      <div class="dac_btn_low">
          <button type="button" @click="closeModal('mobileCheck')" class="btn_dac tclose">{{$t('confirm')}}</button><!-- tclose 클래스가 있어야 레이어 팝업 닫힘 -->
      </div>
    </v-modal>
    
  </div>
</template>

<script>
import navigationBar from '@/components/layout/navigationBar.vue'      // Top Navigation Bar

export default {
  name: 'App',
  components: {
    navigationBar,
  },
  data(){
    return {
      fullLoading : false,
      modalparam : {},
      modalConfirm : {
        title: null,
        msg :null,
        width:null,
        action : null,
        autofocus : false
      },
      mobileCheck:false
    }
  },
  created(){
    
    this.$EventBus.$on('fullLoading', (params) => {
      this.fullLoading = params
    })
    
  },
  mounted() {
    console.log(navigator.userAgent)
    if(!localStorage.SR_languageCode){
      if(navigator.language.indexOf('ko') < 0){
        this.$store.commit('LANGUAGECODE', 'en')
      }else{
        this.$store.commit('LANGUAGECODE', 'ko')
      }
    }
    
    this.$nextTick(() =>{
      // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      //   // true for mobile device
      //   console.log()
      //   this.mobileCheck = true
      //   this.openModal('mobileCheck')
      // }else{
      //   // false for not mobile device
      //   this.mobileCheck = false
      // }
    })
    
  
  },
  methods:{
    setModal(data){
      this.modalparam = data['params']
    },
    setModal2(data){
      this.modalConfirm = data['params']
    },
    focusEvent(data){
      if(this.$refs.buttonConfirm){
        console.log(this.modalConfirm);
        if(this.modalConfirm && this.modalConfirm['autofocus']){
          this.$refs.buttonConfirm.autofocus = true
          this.$refs.buttonConfirm.focus();
        }
      }
    },
    modalConfirmAction (){
      this.modalConfirm.action()
      this.closeModal('alertConfirm');
    },


    beforeClose (event) {
      // What a gamble... 50% chance to cancel closing
      

      event.cancel()
    }
  }
  
}

</script>
<style lang="scss">
  @import '@/assets/css/style.scss';
  
</style>
<style>


@import 'vue-datetime/dist/vue-datetime.css';
@import 'vue2-datepicker/index.css';
@import "vue-select/src/css/vue-select.css";

</style>
